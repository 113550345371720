import { Injectable } from '@angular/core';

import { RoleCode } from '../../_shared/models/_shared/role-code';
import { RoleName } from '../../_shared/models/_shared/role-name';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  static minRole = {
    owner(roleCode: RoleCode): boolean {
      return roleCode === RoleCode.owner;
    },
    admin(roleCode: RoleCode): boolean {
      return roleCode === RoleCode.owner || roleCode === RoleCode.admin;
    },
    manager(roleCode: RoleCode): boolean {
      return roleCode === RoleCode.owner || roleCode === RoleCode.admin || roleCode === RoleCode.manager;
    },
    managee(roleCode: RoleCode): boolean {
      return (
        roleCode === RoleCode.owner ||
        roleCode === RoleCode.admin ||
        roleCode === RoleCode.manager ||
        roleCode === RoleCode.managee
      );
    },
    lite(roleCode: RoleCode): boolean {
      return (
        roleCode === RoleCode.owner ||
        roleCode === RoleCode.admin ||
        roleCode === RoleCode.manager ||
        roleCode === RoleCode.managee ||
        roleCode === RoleCode.lite
      );
    },
    observer(roleCode: RoleCode): boolean {
      return true;
    },
  };

  static minRoleName = {
    owner(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r.toLocaleLowerCase() === RoleName.owner;
    },
    admin(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r === RoleName.owner || r === RoleName.admin;
    },
    manager(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r === RoleName.owner || r === RoleName.admin || r === RoleName.manager;
    },
    managee(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r === RoleName.owner || r === RoleName.admin || r === RoleName.manager || r === RoleName.managee;
    },
    lite(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return (
        r === RoleName.owner ||
        r === RoleName.admin ||
        r === RoleName.manager ||
        r === RoleName.managee ||
        r === RoleName.lite
      );
    },
    observer(_role: RoleName): boolean {
      return true;
    },
  };

  static roleName(roleCode: RoleCode) {
    switch (roleCode) {
      case RoleCode.owner:
        return 'Owner';
      case RoleCode.admin:
        return 'Admin';
      case RoleCode.manager:
        return 'Manager';
      case RoleCode.lite:
        return 'Lite';
      case RoleCode.managee:
        return 'Managee';
      case RoleCode.observer:
        return 'Observer';
      default:
        return '';
    }
  }

  static setRolePermissionsGroup<T>(roleCode: RoleCode, context) {
    context.isOwner = RoleService.minRole.owner(roleCode);
    context.isAdminOrOwner = RoleService.minRole.admin(roleCode);
    context.isManagerOrAbove = RoleService.minRole.manager(roleCode);
    context.isManageeOrAbove = RoleService.minRole.managee(roleCode);
  }

  static canUserAssignRoleCode({
    userRoleCode,
    otherRoleCode,
  }: {
    userRoleCode: RoleCode;
    otherRoleCode: RoleCode;
  }): boolean {
    if (!userRoleCode || !otherRoleCode) return false;
    return RoleService.minRole.admin(userRoleCode)
      ? parseInt(userRoleCode, 10) <= parseInt(otherRoleCode, 10)
      : parseInt(userRoleCode, 10) < parseInt(otherRoleCode, 10);
  }
}
