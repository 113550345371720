import { Injectable } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

/**
 * Error state matcher for Terra components
 *
 * To be used in places where we want a consistent check for error state
 */

@Injectable({ providedIn: 'root' })
export class TerraErrorStateMatcher {
  isErrorState(control: AbstractControl | null): boolean;
  isErrorState(control: NgControl): boolean;

  // Keeping this simple to start, Material has a lot that they've built up around this and ErrorState
  // We can expand as needed
  isErrorState(control: AbstractControl | NgControl | null): boolean {
    if (control instanceof NgControl) {
      return this.isErrorState(control.control);
    }
    return !!(control && control.invalid && (control.touched || control.dirty));
  }
}
