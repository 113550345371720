import { RoleCode } from './role-code';
import { RoleName } from './role-name';

export const RoleCodeAsName: Readonly<Record<RoleCode, RoleName>> = {
  [RoleCode.owner]: RoleName.owner,
  [RoleCode.admin]: RoleName.admin,
  [RoleCode.manager]: RoleName.manager,
  [RoleCode.managee]: RoleName.managee,
  [RoleCode.observer]: RoleName.observer,
  [RoleCode.lite]: RoleName.lite,
};
