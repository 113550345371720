import { Injectable } from '@angular/core';

import type { IGainsightGlobalContext } from './models/gainsight-global-context';

/**
 * Global variable injected Segment when the Gainsight
 * destination is activated.
 *
 * This is currently only a partial implementation of aptrinsic's api,
 * given identitify/group/track events are forwarded by Segment.
 *
 * To read more about our Gainsight integration, check-out the confluence page:
 * https://traxion.atlassian.net/wiki/spaces/Engineering/pages/2288779311/Gainsight
 *
 * Gainsight Web SDK Reference:
 * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK
 */
// eslint-disable-next-line @typescript-eslint/ban-types
declare let aptrinsic: Function;

@Injectable()
export class GainsightPXService {
  /**
   * Checks if the aptrinsic function has been loaded and added to the window.
   */
  private isPxLoaded(): boolean {
    return 'aptrinsic' in window;
  }

  /**
   * The Gainsight PX Web SDK supports the ability to set a "Global Context"
   * for all user events captured by the Gainsight PX tracking tag.
   *
   * Supports partial updates.
   * // Initial
   * setGlobalContext({ userId: "123", accountStatus: "active" })
   * // patch
   * setGlobalContext({ accountStatus: "inactive" })
   */
  setGlobalContext(ctx: IGainsightGlobalContext): void {
    if (this.isPxLoaded()) {
      aptrinsic('set', 'globalContext', ctx);
    }
  }

  resetSession() {
    if (this.isPxLoaded()) {
      aptrinsic('reset');
    }
  }
}
