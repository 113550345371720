import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, take, tap } from 'rxjs';

import { selectHasPartnerHubAccess } from '../../_state/app-global/current-person/current-person.selectors';

@Injectable({
  providedIn: 'root',
})
export class PartnerHubAccessGuard {
  private hasPartnerHubAccess$ = this.store.select(selectHasPartnerHubAccess);
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.hasPartnerHubAccess$.pipe(
      tap(hasAccess => {
        if (!hasAccess) this.router.navigateByUrl('/home');
      }),
      take(1)
    );
  }
}
