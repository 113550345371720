import { DragAndDropEvent } from '@ninety/todos/_state/_shared/todo-state.shared.actions';
import { TodoStateBase } from '@ninety/todos/_state/_shared/todo-state.shared.model';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

export function createOrdinalUpdate(
  event: DragAndDropEvent,
  { ordinalKey, pageIndex, pageSize, todos }: Pick<TodoStateBase, 'pageSize' | 'pageIndex' | 'todos' | 'ordinalKey'>
): Todo[] {
  let start: number, stop: number;

  if (event.previousIndex != null && event.currentIndex != null) {
    start = Math.min(event.previousIndex, event.currentIndex) + pageIndex * pageSize;
    stop = Math.max(event.previousIndex, event.currentIndex) + pageIndex * pageSize;
  }

  return start == null ? todos : todos.filter(t => t[ordinalKey] >= start && t[ordinalKey] <= stop);
}
