import { InteractionType } from './interaction-type.enum';

export interface Interactions {
  interactons: Interaction[];
  submittedQuestion: boolean;
  latestInteractionId: string;
}

export class Interaction {
  _id?: string;
  companyId?: string;
  personId?: string;
  userId?: string;
  currentPage?: string; //url that interaction took place
  lastUpdated?: Date;
  interactionType: InteractionType;
  description?: string;
  args?: Object;
}

export const initialInteractionsState: Interactions = {
  interactons: [],
  submittedQuestion: false,
  latestInteractionId: '',
};
