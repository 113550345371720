import { emptyProps, props } from '@ngrx/store';

import { TodosContextMenuData } from '@ninety/my-ninety/components/todos-context-menu';
import { CreateTodoResponse } from '@ninety/todos/services/models/create-todo-response';
import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';
import { AttachmentEvent } from '@ninety/ui/legacy/shared/models/_shared/attachment-event';
import { AttachmentMessageType } from '@ninety/ui/legacy/shared/models/_shared/attachment-message-type';
import { CommentDeleteEvent } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoSort } from '@ninety/ui/legacy/shared/models/todos/todo-sort';

/**
 * Emitted whenever a _todo is moved in the list, through drag and drop or the context menus "move to top/bottom". In
 * theory, no _id is needed here - you can just lookup the _todo at previousIndex.
 */
export interface DragAndDropEvent {
  previousIndex: number;
  currentIndex: number;
}

/**
 * Common events between {@link PersonalTodoActions} and {@link TeamTodoActions}.
 */
export const TodoSharedActionEvents = {
  'Add One': props<{ todo: Todo }>(),
  'Add Many': props<{
    todo: Item;
    userIds?: string[];
    createdFrom?: FromLinkedItem;
    addCreatorToFollowersList?: boolean;
  }>(),
  'Add Many Success': props<{ response: CreateTodoResponse; createdFrom?: FromLinkedItem }>(),
  'Add Many Failure': emptyProps(),
  Remove: props<{ id: string }>(),
  'Remove Series': props<{ seriesId: string }>(),
  Set: props<{ todos: Todo[] }>(),
  'Update Local': props<{ id: string; todo: Partial<Todo> }>(),
  Update: props<{ id?: string; todo: Partial<Todo> }>(),
  'Update Success': props<{ todo: Todo; completeStatusChange: boolean }>(),
  'Update Failure': emptyProps(),
  'Update Inline': props<{ id?: string; todo: Partial<Todo> }>(),
  'Update Due Date': props<{ id?: string; todo: Partial<Todo> }>(),
  'Set Completed': props<{ id?: string; todo: Partial<Todo>; eventSource: 'my90' | 'to-do page' | 'meeting' }>(),
  'Update Inline Success': props<{ todo: Todo; completeStatusChange: boolean }>(),
  'Update Due Date Success': props<{ todo: Todo; completeStatusChange: boolean }>(),
  'Delete Confirm': props<{ todo: Todo }>(),
  'Open Delete Todo Dialog': props<{ todo: Todo }>(),
  Delete: props<{ todo: Todo }>(),
  'Delete Success': props<{ id: string }>(),
  'Delete Failure': emptyProps(),
  'Delete Series': props<{ todo: Todo }>(),
  'Delete Series Success': props<{ seriesId: string; todo: Todo }>(),
  'Delete Series Failure': emptyProps(),
  'Pagination Change': props<{ index: number; size: number }>(),
  'Sort By': props<{
    sort: TodoSort;
    broadcast?: boolean;
  }>(),
  'Update Ordinals': props<DragAndDropEvent>(),
  'Update Ordinals Success': emptyProps(),
  'Update Ordinals Failure': emptyProps(),
  'Update Local Ordinals': props<DragAndDropEvent>(),
  'Set Should Broadcast': props<{ broadcast?: boolean }>(),
  Loading: props<{ loading: boolean }>(),
  'Show Integrations': props<{ showIntegrations: boolean }>(),
  'Create Task': props<{ id: string }>(),
  'Create Task Success': props<{ todoId: string; googleTaskId: string }>(),
  'Create Task Failure': emptyProps(),
  'Create Rock': props<TodosContextMenuData>(),
  'Create Todo': props<TodosContextMenuData>(),
  'Make It An Issue': props<TodosContextMenuData>(),
  'Create Headline': props<TodosContextMenuData>(),
  'Delete Comment': props<{ event: CommentDeleteEvent }>(),

  Select: props<{ todo: Todo }>(),
  Deselect: emptyProps(),
  'Set Selected Id': props<{ todoId: string }>(),
  'Clear Selected Id': emptyProps(),

  'Attachment Uploaded': props<{
    event: AttachmentEvent & { meetingMessageType?: AttachmentMessageType };
  }>(),
  'Attachment Removed': props<{
    event: AttachmentEvent & { meetingMessageType?: AttachmentMessageType };
  }>(),
  'Attachment Reordered': props<{ event: Todo & { meetingMessageType: AttachmentMessageType } }>(),
  'Attachment Uploaded Success': props<{
    event: { attachments: Attachment[] };
  }>(),
  Search: props<{ searchText: string }>(),
  'Sync Tasks': emptyProps(),
  'Sync Tasks Success': emptyProps(),
  'Sync Tasks Failure': emptyProps(),

  'Update User': props<{ id?: string; todo: Partial<Todo> }>(),
  'Update User Success': props<{ todo: Todo }>(),
  'Update User Failure': emptyProps(),
};
