import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

/** A simple progress bar component.
 *  Right now, this is just a green bar that fills up to a certain percentage.
 *  Being used in the getting started guide area.  But we can expand as needed.
 * */

@Component({
  selector: 'ninety-progress-bar',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="progress-container">
      <div class="progress-bar" *ngIf="percentage > 0" [ngStyle]="{ 'width.%': percentage }"></div>
    </div>
  `,
  styles: [
    `
      @use 'terra';

      .progress-container {
        height: inherit;
        background: terra.color('text', 'disabled');
        @include terra.radius(small);
      }
      .progress-bar {
        height: 100%;
        background: terra.color('green');
        @include terra.radius(small);
      }
    `,
  ],
})
export class ProgressBarComponent {
  @Input() percentage = 0;
}
