import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

import { TerraIconNames } from '../../models/terra-icons';
import { TerraInputBoolean } from '../../models/terra-input-boolean.models';
import { TerraSizing, TerraSizingAsStrings } from '../../models/terra-sizing.models';

// These types help to extract the icon name and variant from the TerraIconNames
type IconNamesWithoutTypeSuffix<T extends string> = T extends `${infer Prefix}-variant-${TerraIconVariant}`
  ? Prefix
  : never;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type IconVariants<T extends string> = T extends `${infer _Prefix}-variant-${infer Suffix}` ? IconVariants<Suffix> : T;

export type TerraIconName = IconNamesWithoutTypeSuffix<TerraIconNames>;
export type TerraIconVariant = IconVariants<TerraIconNames>;

type Suffix<U extends string, P extends string> = U extends `${P}-variant-${infer S}` ? S : never;

/**
 * The generic type on the class is used to help enforce the variant input
 */
@Component({
  selector: 'terra-icon',
  standalone: true,
  exportAs: 'terraIcon',
  imports: [CommonModule],
  template: '',
  styleUrls: ['./terra-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.terra-icon--rtl-mirror]': 'rtlMirror',
  },
})
export class TerraIconComponent<N extends TerraIconName> implements OnInit {
  private readonly BASE_ICON_CLASS = 'terra-icon';

  // Holds reference to the SVG element for removal
  private _svgIcon?: SVGElement;

  /**
   * Icon name to be displayed (required)
   */
  @Input({ required: true })
  get icon(): N {
    return this._icon;
  }
  set icon(name: N) {
    this._icon = name;
    if (this._svgIcon) {
      this._renderer.removeChild(this._element.nativeElement, this._svgIcon);
      this._generateIcon();
    }
    this._changeDetectorRef.markForCheck();
  }
  private _icon!: N;

  /**
   * Variant of the icon to be displayed, not all icons have all variants
   * @default 'regular'
   */
  @Input()
  get variant(): Suffix<TerraIconNames, N> {
    return this._variant;
  }
  set variant(variant: Suffix<TerraIconNames, N>) {
    this._variant = variant;
    if (this._svgIcon) {
      this._renderer.removeChild(this._element.nativeElement, this._svgIcon);
      this._generateIcon();
    }
    this._changeDetectorRef.markForCheck();
  }
  private _variant: Suffix<TerraIconNames, N> = 'regular' as Suffix<TerraIconNames, N>;

  /**
   * Sets the icon to be mirrored in RTL mode (default is to not mirror in RTL mode)
   * @default false
   */
  @Input()
  get rtlMirror(): boolean {
    return this._rtlMirror;
  }
  set rtlMirror(value: TerraInputBoolean) {
    this._rtlMirror = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _rtlMirror = false;

  /**
   * Sets the size of the icon to be displayed.
   * @default 24
   */
  @Input()
  get size(): TerraSizing {
    return this._size;
  }
  // Broader type to allow for string coercion
  set size(size: TerraSizingAsStrings | TerraSizing) {
    this._size = coerceNumberProperty(size) as TerraSizing;
    if (this._svgIcon) {
      this._renderer.setAttribute(this._svgIcon, 'width', `${this.size}px`);
      this._renderer.setAttribute(this._svgIcon, 'height', `${this.size}px`);
    }
    this._changeDetectorRef.markForCheck();
  }
  private _size: TerraSizing = 24;

  constructor(
    private _renderer: Renderer2,
    private _element: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
    @Attribute('aria-hidden') private _ariaHiddenAttr: string
  ) {
    if (!_ariaHiddenAttr) {
      this._renderer.setAttribute(this._element.nativeElement, 'aria-hidden', 'true');
    }
  }

  ngOnInit() {
    this._generateIcon();
  }

  private _generateIcon() {
    const icon = this._getMatchingIcon(this.icon, this.variant);
    if (icon) {
      const svgElement = this._generateSvgFromString(icon);
      this._svgIcon = svgElement;
    }
  }

  private _generateSvgFromString(svgContent: string): SVGElement {
    const div = this._renderer.createElement('div');
    div.innerHTML = svgContent;
    const svgElement: SVGElement = div.querySelector('svg');
    this._renderer.setAttribute(svgElement, 'width', `${this.size}px`);
    this._renderer.setAttribute(svgElement, 'height', `${this.size}px`);
    this._renderer.addClass(svgElement, this.BASE_ICON_CLASS);
    this._renderer.appendChild(this._element.nativeElement, svgElement);
    return svgElement;
  }

  /**
   * Get the matching icon from the icon set
   */
  private _getMatchingIcon(name: TerraIconName, variant: Suffix<TerraIconNames, N>): string | undefined {
    // Typings had to be made generic here
    // TS compiler was complaining about this being too complex as the number increased over ~510 entries
    const iconName = `${name}-variant-${variant}` as string;
    return (this._document.defaultView?.terraIcons as Record<string, string>)?.[iconName];
  }
}
