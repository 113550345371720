import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/_shared/user';
import { UserRating } from '../models/meetings/user-rating';

@Pipe({
  name: 'validRatings',
})
export class GetValidRatingsPipe implements PipeTransform {
  transform(ratings: UserRating[], users: User[] = []): UserRating[] {
    if (!ratings) return null;

    return ratings.filter(r => users.some(u => u._id === r.userId));
  }
}
