import { EntityState, IdSelector, createEntityAdapter } from '@ngrx/entity';

import { PaginationConstants } from '@ninety/ui/legacy/components/pagination/pagination.component.model';
import { HeadlinesSort } from '@ninety/ui/legacy/shared/models/headlines/headline-sort';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';

export const HeadlineStateKey = 'headlines';

export type HeadlinesFilterBy = {
  teamId: string;
  archived: boolean;
  searchText: string;
  includeDiscussed: boolean;
  inMeetingId: string;
};

export interface HeadlinesStateModel extends EntityState<Headline> {
  selectedId: string;

  loading: boolean;
  totalCount: number;

  filters: HeadlinesFilterBy;

  pagination: { index: number; size: number };
  sort: HeadlinesSort;

  focusOnInlineAdd: boolean;
  listControlsDisabled: boolean;

  error: boolean;
}
const selectId: IdSelector<Headline> = ({ _id }) => _id;
export const headlinesStateAdapter = createEntityAdapter<Headline>({ selectId });
export const defaultPagination = {
  index: 0,
  size: PaginationConstants.DEFAULT_PAGE_SIZES[0],
};
export const defaultSort = { direction: null, field: null };

export const headlinesInitialState = headlinesStateAdapter.getInitialState({
  selectedId: null,
  loading: false,
  totalCount: 0,
  filters: {
    teamId: null,
    archived: false,
    searchText: null,
    includeDiscussed: true,
    inMeetingId: null,
  },
  pagination: defaultPagination,
  sort: defaultSort,

  focusOnInlineAdd: false,
  listControlsDisabled: false,

  error: false,
});
