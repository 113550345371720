import { Pipe, PipeTransform } from '@angular/core';

import { Team } from '../models/_shared/team';

@Pipe({
  name: 'filterOutProjectTeams',
})
export class FilterOutProjectTeamsPipe implements PipeTransform {
  transform(teams: Team[], filterOutProjectTeams = false): Team[] {
    if (!teams || !filterOutProjectTeams) return teams;

    return teams.filter((t: Team) => !t.project);
  }
}
