import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ninety-divider-menu-item',
  template: `<div class="divider-container">
    <span [ngClass]="{ 'divider-tall': isTall, 'divider-short': isTall === false }"></span>
  </div>`,
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./divider-menu-item.component.scss'],
})
export class DividerMenuItemComponent {
  @Input() isTall = false;
}
