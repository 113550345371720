<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="to-dos" />
      <span data-cy="todo-language_card-title">{{ todo.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="todo">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="todo.route + ' Page Name'"
          data-cy="todo-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="todo-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ todo.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="todo.item"
          data-cy="todo-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="todo-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ todo.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="todo.items"
          data-cy="todo-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="todo-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ todo.team }}:</mat-label>
        <input
          #team
          matInput
          formControlName="team"
          required
          [attr.aria-label]="todo.team"
          data-cy="todo-language_team-to-do-input-field" />
        <ninety-reset-btn
          *ngIf="team.value !== resetLanguage?.team"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('team')"
          [disabled]="readonly"
          data-cy="todo-language_team-to-do-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ todo.personal }}:</mat-label>
        <input
          #personal
          matInput
          formControlName="personal"
          required
          [attr.aria-label]="todo.personal"
          data-cy="todo-language_private-to-do-input-field" />
        <ninety-reset-btn
          *ngIf="personal.value !== resetLanguage?.personal"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('personal')"
          [disabled]="readonly"
          data-cy="todo-language_private-to-do-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
