import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';
import { LanguageActions } from '@ninety/ui/legacy/state/app-global/language/language.actions';
import { selectLanguage, selectResetLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

@Component({
  selector: 'ninety-our-language',
  template: ` <div class="container customization">
    <ninety-custom-language
      (save)="save($event)"
      [language]="language$ | ngrxPush"
      [resetLanguage]="resetLanguage$ | ngrxPush" />
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OurLanguageComponent implements OnInit {
  language$ = this.store.select(selectLanguage);
  resetLanguage$ = this.store.select(selectResetLanguage);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(LanguageActions.getResetLanguage());
  }

  save(companyLanguage: CustomLanguage): void {
    this.store.dispatch(LanguageActions.updateCurrentCompanyLanguage({ companyLanguage }));
  }
}
