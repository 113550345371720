import { Injectable } from '@angular/core';
import { CountryCode, getExampleNumber } from 'libphonenumber-js';

import { countryPhoneCodes, examplePhoneNumbers } from '../models/country-codes';
import { Country } from '../models/country.model';

@Injectable()
export class PhoneCountryService {
  public allCountries: Country[] = [];

  constructor() {
    this.populateCountries();
  }

  public getCountry(code: string): Country {
    return (
      this.allCountries.find(c => c.iso2 === code.toLowerCase()) || {
        name: 'UN',
        iso2: 'UN',
        dialCode: '',
        priority: 0,
        areaCodes: undefined,
        flagClass: 'UN',
        placeHolder: '',
      }
    );
  }

  private populateCountries(): void {
    countryPhoneCodes.forEach(c => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        flagClass: c[1].toString().toUpperCase(),
        placeHolder: '',
      };

      if (countryPhoneCodes) {
        country.placeHolder = this.getPhoneNumberPlaceHolder(country.iso2.toUpperCase() as CountryCode);
      }

      this.allCountries.push(country);
    });
  }

  private getPhoneNumberPlaceHolder(countryISOCode: CountryCode): string | undefined {
    const result = getExampleNumber(countryISOCode, examplePhoneNumbers);
    return !!result ? result.number.toString() : undefined;
  }
}
