<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="meetings" />
      <span data-cy="meeting-language_card-title">{{ meeting.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="meeting">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="meeting.route + ' Page Name'"
          data-cy="meeting-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="meeting-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="meeting.item"
          data-cy="meeting-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="meeting-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="meeting.items"
          data-cy="meeting-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="meeting-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.levelTen }} (singular):</mat-label>
        <input
          #levelTen
          matInput
          formControlName="levelTen"
          required
          [attr.aria-label]="meeting.levelTen"
          data-cy="meeting-language_weekly-meeting-singular-input-field" />
        <ninety-reset-btn
          *ngIf="levelTen.value !== resetLanguage?.levelTen"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('levelTen')"
          [disabled]="readonly"
          data-cy="meeting-language_weekly-meeting-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.levelTens }} (plural):</mat-label>
        <input
          #levelTens
          matInput
          formControlName="levelTens"
          required
          [attr.aria-label]="meeting.levelTens"
          data-cy="meeting-language_weekly-meeting-plural-input-field" />
        <ninety-reset-btn
          *ngIf="levelTens.value !== resetLanguage?.levelTens"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('levelTens')"
          [disabled]="readonly"
          data-cy="meeting-language_weekly-meeting-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.quarterly }}:</mat-label>
        <input
          #quarterly
          matInput
          formControlName="quarterly"
          required
          [attr.aria-label]="meeting.quarterly"
          data-cy="meeting-language_quarterly-meeting-input-field" />
        <ninety-reset-btn
          *ngIf="quarterly.value !== resetLanguage?.quarterly"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('quarterly')"
          [disabled]="readonly"
          data-cy="meeting-language_quarterly-meeting-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.quarterlySession }} (singular):</mat-label>
        <input
          #quarterlySession
          matInput
          formControlName="quarterlySession"
          required
          [attr.aria-label]="meeting.quarterlySession"
          data-cy="meeting-language_quarterly-planning-meeting-singular-input-field" />
        <ninety-reset-btn
          *ngIf="quarterlySession.value !== resetLanguage?.quarterlySession"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('quarterlySession')"
          [disabled]="readonly"
          data-cy="meeting-language_quarterly-planning-meeting-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.quarterlySessions }} (plural):</mat-label>
        <input
          #quarterlySessions
          matInput
          formControlName="quarterlySessions"
          required
          [attr.aria-label]="meeting.quarterlySessions"
          data-cy="meeting-language_quarterly-planning-meeting-plural-input-field" />
        <ninety-reset-btn
          *ngIf="quarterlySessions.value !== resetLanguage?.quarterlySessions"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('quarterlySessions')"
          [disabled]="readonly"
          data-cy="meeting-language_quarterly-planning-meeting-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.annual }}:</mat-label>
        <input
          #annual
          matInput
          formControlName="annual"
          required
          [attr.aria-label]="meeting.annual"
          data-cy="meeting-language_annual-meeting-input-field" />
        <ninety-reset-btn
          *ngIf="annual.value !== resetLanguage?.annual"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('annual')"
          [disabled]="readonly"
          data-cy="meeting-language_annual-meeting-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.annualSession }} (singular):</mat-label>
        <input
          #annualSession
          matInput
          formControlName="annualSession"
          required
          [attr.aria-label]="meeting.annualSession"
          data-cy="meeting-language_annual-planning-meeting-singular-input-field" />
        <ninety-reset-btn
          *ngIf="annualSession.value !== resetLanguage?.annualSession"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('annualSession')"
          [disabled]="readonly"
          data-cy="meeting-language_annual-planning-meeting-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.annualSessions }} (plural):</mat-label>
        <input
          #annualSessions
          matInput
          formControlName="annualSessions"
          required
          [attr.aria-label]="meeting.annualSessions"
          data-cy="meeting-language_annual-planning-meeting-plural-input-field" />
        <ninety-reset-btn
          *ngIf="annualSessions.value !== resetLanguage?.annualSessions"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('annualSessions')"
          [disabled]="readonly"
          data-cy="meeting-language_annual-planning-meeting-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.focusDay }}:</mat-label>
        <input
          #focusDay
          matInput
          formControlName="focusDay"
          required
          [attr.aria-label]="meeting.focusDay"
          data-cy="meeting-language_foundation-day-input-field" />
        <ninety-reset-btn
          *ngIf="focusDay.value !== resetLanguage?.focusDay"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('focusDay')"
          [disabled]="readonly"
          data-cy="meeting-language_foundation-day-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.visionBuildingDayOne }}:</mat-label>
        <input
          #visionBuildingDayOne
          matInput
          formControlName="visionBuildingDayOne"
          required
          [attr.aria-label]="meeting.visionBuildingDayOne"
          data-cy="meeting-language_vission-session-input-field" />
        <ninety-reset-btn
          *ngIf="visionBuildingDayOne.value !== resetLanguage?.visionBuildingDayOne"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('visionBuildingDayOne')"
          [disabled]="readonly"
          data-cy="meeting-language_vission-session-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ meeting.visionBuildingDayTwo }}:</mat-label>
        <input
          #visionBuildingDayTwo
          matInput
          formControlName="visionBuildingDayTwo"
          required
          [attr.aria-label]="meeting.visionBuildingDayTwo"
          data-cy="meeting-language_goals-setting-session-input-field" />
        <ninety-reset-btn
          *ngIf="visionBuildingDayTwo.value !== resetLanguage?.visionBuildingDayTwo"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('visionBuildingDayTwo')"
          [disabled]="readonly"
          data-cy="meeting-language_goals-setting-session-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
