import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DataImportStateKey, DataImportStateModel } from '@ninety/data-import/_state/data-import-state.model';
import { DATA_IMPORT_TYPES, DataImportType } from '@ninety/data-import/models/data-import-types';
import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

export const selectDataImportState = createFeatureSelector<DataImportStateModel>(DataImportStateKey);

export const selectDataImportToken = createSelector(selectDataImportState, state => state.userJwt);

export const selectDataImportTemplateKey = createSelector(selectDataImportState, state => state.templateKey);

export const selectDataImportSelectedIcon = createSelector(selectDataImportState, state => state.selectedIcon);

export const selectDataImportTemplateOptions = createSelector(
  selectLanguage,
  selectDataImportTemplateKey,
  (language, templateKey): QuickFilterOption<DataImportType>[] =>
    DATA_IMPORT_TYPES.map(item => ({
      id: item.templateKey,
      displayText: item.label || language[item.languageKey][item.labelKey],
      item,
      icon: { name: item.icon, size: 20 },
      selected: item.templateKey === templateKey,
    }))
);

export const selectDataImportSelectedTeamId = createSelector(selectDataImportState, state => state.selectedTeamId);
