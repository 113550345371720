import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, of, concatMap } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SortingService } from '@ninety/ui/legacy/core/services/sorting.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { Comment } from '@ninety/ui/legacy/shared/models/_shared/comment';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { OrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-update';
import { VoteType } from '@ninety/ui/legacy/shared/models/_shared/vote-type';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { Sorted } from '@ninety/ui/legacy/shared/models/enums/sorted';
import { GetIssuesQueryParams } from '@ninety/ui/legacy/shared/models/issues/get-issues-query-params';
import { IntervalCode } from '@ninety/ui/legacy/shared/models/issues/interval-code';
import { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';
import { IssueListType } from '@ninety/ui/legacy/shared/models/issues/issue-list-type';
import { IssueMessageType } from '@ninety/ui/legacy/shared/models/issues/issue-message-type';
import { IssueOptions } from '@ninety/ui/legacy/shared/models/issues/issue-options.model';
import { IssueResponse } from '@ninety/ui/legacy/shared/models/issues/issue-response';
import { IssuesFromRocksBody } from '@ninety/ui/legacy/shared/models/issues/issues-from-rocks-body';
import { IssueSortField, IssueSortFieldEnum } from '@ninety/ui/legacy/shared/models/issues/issues-sort-field';
import { SendIssueBackEvent } from '@ninety/ui/legacy/shared/models/issues/send-issue-back-event';
import { SendIssueEvent } from '@ninety/ui/legacy/shared/models/issues/send-issue-event';
import { VoteItem } from '@ninety/ui/legacy/shared/models/issues/vote-item';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { ListDropMessage } from '@ninety/ui/legacy/shared/models/meetings/list-drop-message';
import type {
  RealtimeMessage,
  ReceivedRealtimeMessage,
} from '@ninety/ui/legacy/shared/models/meetings/realtime-message';

import { IssuesActions } from '../_state/issues.actions';

export interface IssueSortResponse {
  issues: Issue[];
  sortField: IssueSortField;
  sortDirection: SortDirection;
  sorted: Sorted;
}

@Injectable({
  providedIn: 'root',
})
export class IssueService {
  private v4IssuesUrl = 'api.qa1.90srv.com/Issues';

  showArchived = false;
  isShortTerm = true;
  issues$ = new BehaviorSubject<Issue[]>([]);
  issuesCopy: Issue[] = [];
  issuesSentToOtherTeams$ = new BehaviorSubject<Issue[]>([]);
  issuesSentFromOtherTeams$ = new BehaviorSubject<Issue[]>([]);
  totalIssueCount$ = new BehaviorSubject<number>(0);

  // Event Streams/Hooks
  newIssue$ = new Subject<Issue>();
  newIssueForMetrics$ = new Subject<Issue>();
  updatedIssue$ = new Subject<Issue>();
  dropListIssue$ = new Subject<ListDropMessage>();
  updateIssue$ = new Subject<{ id: string; update: Partial<Issue>; teamId: string; listType: IssueListType }>();
  deleteIssue$ = new Subject<{ id: string; teamId: string; listType: IssueListType; localOnly?: boolean }>();
  archiveChange$ = new Subject<{ id: string; isArchived: boolean; listType: IssueListType }>();
  bulkArchive$ = new Subject<boolean>();
  completionChange$ = new Subject<{ id: string; isCompleted: boolean; listType: IssueListType }>();
  sortChangeLocal$ = new Subject<{
    field: IssueSortField;
    sorted: Sorted;
    listType: IssueListType;
    isShortTerm: boolean;
  }>();
  intervalChange$ = new Subject<{ id: string; intervalCode: IntervalCode; listType: IssueListType }>();
  teamChange$ = new Subject<{ id: string; teamId: string; listType: IssueListType }>();
  issueSentToOtherTeam$ = new Subject<Issue>();
  resetListSorter$ = new Subject<void>();
  messageSubscription = new Subscription();
  focusOnInlineAddIssue$ = new BehaviorSubject<boolean>(false);
  commentUpdated$ = new Subject<void>();

  channelId: string;
  shouldBroadcast = false;

  constructor(
    private http: HttpClient,
    public stateService: StateService,
    private spinnerService: SpinnerService,
    private filterService: FilterService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private sortService: SortingService,
    private channelService: ChannelService,
    private detailService: DetailService<Issue>,
    private store: Store
  ) {
    this.stateService.isShortTerm$.subscribe({ next: isShortTerm => (this.isShortTerm = isShortTerm) });
    this.filterService.showArchived$.subscribe({ next: showArchived => (this.showArchived = showArchived) });

    this.updateIssue$
      .pipe(
        tap(({ id, update, teamId, listType }) => {
          if (update.hasOwnProperty('archived'))
            this.archiveChange$.next({ id, isArchived: update.archived, listType });
          if (update.hasOwnProperty('completed')) {
            this.completionChange$.next({ id, isCompleted: update.completed, listType });
            this.broadcastMessage({
              messageType: IssueMessageType.issue,
              document: Object.assign(this.getLocalById(id), { id }),
              listType,
            }).subscribe();
          }
          if (update.hasOwnProperty('intervalCode'))
            this.intervalChange$.next({ id, intervalCode: update.intervalCode as IntervalCode, listType });
          if (update.hasOwnProperty('teamId')) this.teamChange$.next({ id, teamId: update.teamId, listType });
          if (update.hasOwnProperty('deleted')) {
            this.deleteIssue$.next({ id, teamId, listType });
            this.broadcastMessage({
              messageType: IssueMessageType.delete,
              document: id,
              listType,
            }).subscribe();
          }
        })
      )
      .subscribe();
  }

  get issues() {
    return this.issues$.getValue();
  }

  get issuesSentToOtherTeams() {
    return this.issuesSentToOtherTeams$.value;
  }

  get issuesSentFromOtherTeams() {
    return this.issuesSentFromOtherTeams$.value;
  }

  get currentIntervalCode() {
    return this.isShortTerm ? IntervalCode.shortTerm : IntervalCode.longTerm;
  }

  setIssues(issues: Issue[] = [], updateCopy = true) {
    // updateCopy = false when sorting locally
    if (updateCopy) {
      this.issuesCopy = [...issues];
    }

    this.issues$.next([...issues]);
  }

  setSentIssues(issues: Issue[] = []) {
    this.issuesSentToOtherTeams$.next([...issues]);
  }

  setReceivedIssues(issues: Issue[] = []): void {
    this.issuesSentFromOtherTeams$.next([...issues]);
  }

  clearIssues(): void {
    this.issuesCopy.length = 0;
    this.issues$.next([]);
  }

  getLocalById(id: string): Issue {
    return this.issues.find(issue => issue._id === id);
  }

  getLocalIndexById(id: string): number {
    return this.issues.findIndex(issue => issue._id === id);
  }

  setTopThree(issues: Issue[], offset: number, shouldBroadcast = true): void {
    this.bulkMove(issues, offset);

    if (shouldBroadcast) {
      this.broadcastMessage({
        messageType: IssueMessageType.topThree,
        document: {
          issues,
          offset,
          isShortTerm: this.isShortTerm,
          currentTeamId: this.filterService.selectedTeamId$.value,
        },
      }).subscribe();
    }
  }

  bulkMove(movedIssues: Issue[], offset: number): void {
    const changes = movedIssues.map((item, index) => ({
      _id: item._id,
      previousIndex: this.getLocalIndexById(item._id),
      currentIndex: index,
    }));
    const issues = [...this.issues];

    changes.forEach(change => {
      // Don't use change.previousIndex, it will be invalidated as items shuffle
      moveItemInArray(
        issues,
        issues.findIndex(i => i._id === change._id),
        change.currentIndex
      );
    });

    // Get earliest and latest issue changed in the set to update the minimal amount of issue ordinals
    const range = changes.reduce(
      (_range, change) => {
        const min = Math.min(change.previousIndex, change.currentIndex) + offset;
        const max = Math.max(change.previousIndex, change.currentIndex) + offset;

        _range.start = Math.min(_range.start, min);
        _range.stop = Math.max(_range.stop, max);

        return _range;
      },
      { start: null, stop: null }
    );

    this.updateOrdinals(issues, range.start, range.stop, offset, false, true).subscribe();
  }

  // Don't forget to subscribe
  moveIssue(
    previousIndex: number,
    currentIndex: number,
    offset: number,
    teamId?: string,
    intervalCode?: IntervalCode,
    currentSortField?: IssueSortField,
    currentSort?: SortDirection,
    shouldBroadcast = true
  ): Observable<void> {
    // Exit if user placed item back in initial position
    if (previousIndex === currentIndex) return of();

    const issues = [...this.issues];

    moveItemInArray(issues, previousIndex, currentIndex);

    this.resetListSorter$.next(null);

    if (shouldBroadcast) {
      this.broadcastMessage({
        messageType: IssueMessageType.move,
        document: {
          currentIndex,
          previousIndex,
          offset,
          isShortTerm: this.isShortTerm,
          currentTeamId: this.filterService.selectedTeamId$.value,
        },
      }).subscribe();
    }

    return this.updateOrdinals(
      issues,
      previousIndex,
      currentIndex,
      offset,
      !shouldBroadcast,
      true,
      teamId,
      intervalCode,
      currentSortField,
      currentSort
    );
  }

  /**
   * Sort issues in memory
   *
   * In this method, always call setIssues with the updateCopy set to false to
   * preserve original server sort.
   */
  toggleLocalSortBy(field: IssueSortField, sorted: Sorted): Observable<Issue[]> {
    // If changed to Sorted.false, use original server order stored in issuesCopy
    if (sorted === Sorted.false) {
      this.setIssues(this.issuesCopy, false);

      return of([...this.issues]);
    }

    const issues = [...this.issues];
    let stream: Observable<Issue[]>;

    switch (field) {
      case IssueSortFieldEnum.title:
        stream = this.sortService.sortAlphabetically(issues, field, sorted);
        break;
      case IssueSortFieldEnum.user:
        stream = this.sortService.sortAlphabeticallyByUser(issues, field, sorted);
        break;
      case IssueSortFieldEnum.rating:
        stream = this.sortService.sortByNumber(issues, field, sorted);
        break;
      case IssueSortFieldEnum.archivedDate:
      case IssueSortFieldEnum.createdDate:
      case IssueSortFieldEnum.completedDate:
        stream = this.sortService.sortByDate(issues, field, sorted);
        break;
      case IssueSortFieldEnum.numOfLikes:
        stream = this.sortService.sortByNumber(issues, field, sorted);
        break;
    }

    return stream.pipe(tap(i => this.setIssues(i, false)));
  }

  create(
    issue: Item,
    eventSource: 'regular' | 'inline',
    createdFrom?: FromLinkedItem,
    addCreatorToFollowersList = true
  ): Observable<Issue> {
    this.spinnerService.start();
    return this.http.post<Issue>(this.v4IssuesUrl, { ...issue, from: createdFrom, addCreatorToFollowersList }).pipe(
      tap((newIssue: Issue) => {
        this.store.dispatch(IssuesActions.createIssue({ issue: _cloneDeep(newIssue), eventSource }));

        //NOTE: I could not use newIssue$ as I need to emit every time an issue is added in a meeting.
        //Moving this.newIssue$.next(newIssue) outside the "if" might cause unwanted side effects.
        //Creating a store effect using IssuesActions.createIssue was also considered but out of scope
        this.newIssueForMetrics$.next(newIssue);

        if (
          newIssue.teamId === this.filterService.selectedTeamId$.value &&
          newIssue.intervalCode === this.currentIntervalCode
        ) {
          if (newIssue.archived === this.showArchived) this.addLocal(newIssue, true);
          this.newIssue$.next(newIssue);
          this.broadcastMessage({
            messageType: IssueMessageType.new,
            document: newIssue,
          }).subscribe();
        }

        this.focusOnInlineAddIssue$.next(true);
        this.spinnerService.stop();
      }),
      catchError((e: unknown) => {
        this.focusOnInlineAddIssue$.next(false);
        return this.errorService.notify(
          e,
          `Could not create ${this.stateService.language.issue.item}.  Please try again.`
        );
      })
    );
  }

  getIssue(id: string, primary = true, skipLocal = true): Observable<Issue> {
    if (skipLocal) {
      if (primary) this.spinnerService.start();

      return this.http.get<Issue>(`${this.v4IssuesUrl}/${id}`).pipe(
        tap(() => {
          if (primary) this.spinnerService.stop();
        }),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.issue.item}.  Please try again.`)
        )
      );
    }

    return of([...this.issues, ...this.issuesSentFromOtherTeams, ...this.issuesSentToOtherTeams]).pipe(
      map(issues => issues?.find(i => i._id === id)),
      switchMap(issue => {
        if (!!issue) return of(issue);

        return this.getIssue(id);
      })
    );
  }

  getIssues(opts = new IssueOptions()): Observable<IssueResponse> {
    // TODO: Refactor - convert IssueOptions to query params to remove need to map them
    const params = QueryParamsService.build({
      teamId: opts.teamId,
      intervalCode: opts.longTerm || !this.isShortTerm ? IntervalCode.longTerm : IntervalCode.shortTerm,
      archived: opts.showArchived,
      searchText: opts.searchText?.trim() || '',
      page: opts.page,
      pageSize: opts.pageSize,
      ...(opts.sortField ? { sortField: opts.sortField } : {}),
      ...(opts.sortDirection ? { sortDirection: opts.sortDirection } : {}),
      onlyPublic: opts.onlyPublic,
    });

    return this.http.get<IssueResponse>(`${this.v4IssuesUrl}`, { params }).pipe(
      tap((x: IssueResponse) => this.setIssues(x.items)),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.issue.items}.  Please try again.`)
      )
    );
  }

  getIssuesSentToOtherTeam(
    opts = new IssueOptions(),
    teamId = this.filterService.selectedTeamId$.value
  ): Observable<IssueResponse> {
    if (this.issuesSentToOtherTeams$.value?.length) this.setSentIssues([]);

    const params = QueryParamsService.build({
      teamId,
      intervalCode: opts.longTerm || !this.isShortTerm ? IntervalCode.longTerm : IntervalCode.shortTerm,
    });

    return this.http.get<IssueResponse>(`${this.v4IssuesUrl}/SentTo`, { params }).pipe(
      tap((resp: IssueResponse) => this.setSentIssues(resp.items)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get ${this.stateService.language.issue.items} sent to other team.  Please try again.`
        )
      )
    );
  }

  getIssuesSentFromOtherTeam(
    opts = new IssueOptions(),
    teamId = this.filterService.selectedTeamId$.value
  ): Observable<IssueResponse> {
    if (this.issuesSentFromOtherTeams$.value?.length) this.setReceivedIssues([]);

    const params = QueryParamsService.build({
      workingTeamId: teamId,
      intervalCode: opts.longTerm || !this.isShortTerm ? IntervalCode.longTerm : IntervalCode.shortTerm,
    });

    return this.http.get<IssueResponse>(`${this.v4IssuesUrl}/SentFrom`, { params }).pipe(
      tap((resp: IssueResponse) => this.setReceivedIssues(resp.items)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get ${this.stateService.language.issue.items} sent to other team.  Please try again.`
        )
      )
    );
  }

  archiveAllCompleted(): Observable<number | null> {
    return this.confirmArchiveCompletedDialog().pipe(
      mergeMap((confirmed: boolean) => {
        if (!confirmed) return of(null);

        this.spinnerService.start();
        return this.http
          .patch<{ numOfArchivedIssues: number }>(
            `${this.v4IssuesUrl}/Archive/${this.filterService.selectedTeamId$.value}/Completed`,
            null
          )
          .pipe(
            map(resp => {
              this.spinnerService.stop();
              this.bulkArchive$.next(true);
              return resp.numOfArchivedIssues;
            }),
            catchError((e: unknown) => {
              this.spinnerService.stop();
              return this.errorService.notify(
                e,
                `Could not archive ${this.stateService.language.issue.items}.  Please try again.`
              );
            })
          );
      })
    );
  }

  confirmArchiveCompletedDialog(): Observable<boolean> {
    const confirmDeleteDialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
      data: {
        title: 'Archive Completed?',
        message: 'All completed issues will be archived.',
        confirmButtonText: 'Archive',
      },
    });
    return confirmDeleteDialogRef.afterClosed();
  }

  /**
   * cleanWhenNoId: used to clean temporary issues that don't have an id, eg. added inline
   */
  addLocal(issue: Issue, cleanWhenNoId = false): void {
    if (!cleanWhenNoId) {
      this.setIssues([...this.issues, issue]);
    } else {
      this.setIssues([...this.issues.filter(i => i._id), issue]);
    }
  }

  addLocalSentTo(issue: Issue): void {
    this.setSentIssues([...this.issuesSentToOtherTeams, issue]);
  }

  deleteLocal(id: string): void {
    this.setIssues(this.issues.filter(issue => issue._id !== id));
  }

  deleteLocalReceived(id: string): void {
    this.setReceivedIssues(this.issuesSentFromOtherTeams.filter(issue => issue._id !== id));
  }

  deleteLocalSentTo(id: string): void {
    this.setSentIssues(this.issuesSentToOtherTeams.filter(issue => issue._id !== id));
  }

  updateLocal(id: string, update: Partial<Issue>): void {
    const issues = [...this.issues];
    const index = this.issues?.findIndex(i => i._id === id);

    if (index < 0) {
      console.log(`Issue ${id} does not exist locally`);
      return;
    }

    if (
      update.deleted ||
      (update.hasOwnProperty('teamId') && update.teamId !== this.filterService.currentTeamId) ||
      (update.hasOwnProperty('workingTeamId') && update.workingTeamId !== this.filterService.currentTeamId) ||
      (update.hasOwnProperty('archived') && this.showArchived !== update.archived) ||
      (update.hasOwnProperty('intervalCode') && update.intervalCode !== this.currentIntervalCode)
    ) {
      this.deleteLocal(id);
      return;
    }

    issues[index] = Object.assign({}, issues[index], update);

    this.setIssues(issues);
  }

  updateLocalSentTo(id: string, update: Partial<Issue>): void {
    const issues = [...this.issuesSentToOtherTeams];
    const index = issues?.findIndex(i => i._id === id);

    if (index < 0) {
      console.log(`Issue ${id} does not exist locally`);
      return;
    }

    if (update.deleted) {
      this.deleteLocalSentTo(id);
      return;
    }

    issues[index] = Object.assign({}, issues[index], update);

    this.setSentIssues(issues);
  }

  updateLocalReceived(id: string, update: Partial<Issue>): void {
    const issues = [...this.issuesSentFromOtherTeams];
    const index = issues?.findIndex(i => i._id === id);

    if (index < 0) {
      console.log(`Issue ${id} does not exist locally`);
      return;
    }

    if (update.deleted) {
      this.deleteLocalReceived(id);
      return;
    }

    issues[index] = Object.assign({}, issues[index], update);

    this.setReceivedIssues(issues);
  }

  updateByList(id: string, update: Partial<Issue>, listType: IssueListType): void {
    switch (listType) {
      case IssueListType.received:
        this.updateLocalReceived(id, update);
        break;
      case IssueListType.sent:
        this.updateLocalSentTo(id, update);
        break;
      default:
        this.updateLocal(id, update);
        break;
    }
  }

  updateIssue(id: string, update: Partial<Issue>, teamId: string, listType: IssueListType): Observable<Issue> {
    this.updateByList(id, update, listType);

    this.updateIssue$.next({ id, update, teamId, listType });

    return this.http.patch<Issue>(`${this.v4IssuesUrl}/${id}`, update).pipe(
      tap(_ => {
        if (this.shouldBroadcast && !update?.deleted && !update.completed) {
          let issue: Issue;
          switch (listType) {
            case IssueListType.received:
              issue = this.issuesSentFromOtherTeams.find(i => i._id === id);
              break;
            case IssueListType.sent:
              issue = this.issuesSentToOtherTeams.find(i => i._id === id);
              break;
            default:
              issue = this.issues.find(i => i._id === id);
              break;
          }
          this.broadcastMessage({
            messageType: update.archived === false ? IssueMessageType.unarchive : IssueMessageType.issue,
            listType,
            document: Object.assign({}, issue, update, { _id: id }),
          }).subscribe();
        }
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.issue.item}.  Please try again.`)
      )
    );
  }

  updateComment(id: string, update: Comment) {
    const upd: { text: string; createdDate: string | Date } = update;
    return this.http.patch(`${this.v4IssuesUrl}/Comment/update/${id}`, upd).pipe(
      tap(() => {
        this.mergeIssueComments(id, update);
        if (this.shouldBroadcast) {
          this.broadcastMessage({
            messageType: IssueMessageType.comment,
            document: { issueId: id, comment: { ...update, userId: this.stateService.currentUser._id } },
          }).subscribe();
        }
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.issue.item}.  Please try again.`)
      )
    );
  }

  deleteComment(id: string, update: Comment) {
    const upd: { createdDate: string | Date } = update;
    return this.http.patch(`${this.v4IssuesUrl}/Comment/delete/${id}`, upd).pipe(
      tap(() => {
        this.deleteIssueComments(id, update);
        if (this.shouldBroadcast) {
          this.broadcastMessage({
            messageType: IssueMessageType.commentDelete,
            document: { issueId: id, comment: { ...update, text: '', userId: this.stateService.currentUser._id } },
          }).subscribe();
        }
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.issue.item}.  Please try again.`)
      )
    );
  }

  setCompleted(issue: Issue, listType: IssueListType, eventSource: 'issues page' | 'meeting'): Observable<Issue> {
    const { _id, completed, teamId } = issue;
    this.store.dispatch(IssuesActions.completeIssue({ issue, eventSource }));
    return this.updateIssue(_id, { completed }, teamId, listType);
  }

  setDeleted(id: string, isDeleted: boolean, teamId: string, listType: IssueListType): Observable<Issue> {
    return this.updateIssue(id, { deleted: isDeleted }, teamId, listType);
  }

  setArchived(id: string, archived: boolean, teamId: string, listType: IssueListType): Observable<Issue> {
    return this.updateIssue(
      id,
      {
        archived,
        archivedDate: archived ? new Date() : null,
      },
      teamId,
      listType
    );
  }
  setInterval(id: string, intervalCode: IntervalCode, teamId: string, listType: IssueListType): Observable<Issue> {
    return this.updateIssue(id, { intervalCode }, teamId, listType);
  }

  changeTeams(id: string, teamId: string, listType: IssueListType): Observable<Issue> {
    if (teamId !== this.filterService.currentTeamId) {
      this.deleteLocalSentTo(id);
      this.deleteLocalReceived(id);
    }

    return this.updateIssue(id, { teamId }, teamId, listType);
  }

  voteIssue(issue: Issue, remove?: boolean): Observable<Issue> {
    // Update local immediately for faster UI response
    const l = [...(issue.likes ? issue.likes : [])];
    if (remove) {
      const index = l.indexOf(this.stateService.currentUser._id);
      if (index > -1) l.splice(index, 1);
    } else {
      l.push(this.stateService.currentUser._id);
    }

    const liked = l.indexOf(this.stateService.currentUser._id) > -1;
    this.updateLocal(issue._id, { liked, likes: l, numOfLikes: l.length });

    return this.http
      .patch<Issue>(
        `${this.v4IssuesUrl}/Vote/${issue._id}`,
        {},
        {
          params: remove ? new HttpParams().set('remove', 'true') : null,
        }
      )
      .pipe(
        tap((v: Issue) => {
          // Keep the update on backend response to reflect reality
          this.broadcastMessage({
            messageType: IssueMessageType.vote,
            document: {
              _id: issue._id,
              voteType: remove ? VoteType.negative : VoteType.positive,
              itemType: 'issue',
            },
          }).subscribe();
          this.updateLocal(issue._id, { liked: v.liked, likes: v.likes, numOfLikes: v.numOfLikes });
        }),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.issue.item}.  Please try again.`)
        )
      );
  }

  voteLocal(id: string, userId: string, remove = false): void {
    const index = this.issues?.findIndex(i => i._id === id);
    if (index < 0) {
      console.log(`Issue ${id} does not exist locally`);
      return;
    }
    const issue = this.issues[index];
    let likes = [...(issue.likes || [])];
    if (!remove) {
      if (likes.length > 0) likes.push(userId);
      else likes = [userId];
    } else {
      if (likes.some(u => u === userId)) {
        likes = likes.filter(u => u !== userId);
      }
    }

    this.updateLocal(id, { liked: likes.length > 0, likes, numOfLikes: likes.length });
  }

  updateOrdinals(
    issues: Issue[],
    previousIndex: number,
    currentIndex: number,
    offset: number,
    localOnly: boolean,
    updateCopy = false,
    teamId?: string,
    intervalCode?: IntervalCode,
    sortField?: IssueSortField,
    sortDirection?: SortDirection
  ): Observable<void> {
    const hasNullOrdinals = !issues.every((issue: Issue) => issue.ordinal !== null);

    this.refreshOrdinals(issues, offset);

    this.setIssues(issues, updateCopy);

    if (localOnly || this.stateService.isObserver) return of();

    if (issues?.length > 0) {
      const start = previousIndex != null ? Math.min(previousIndex, currentIndex) + offset : 0;
      const stop = currentIndex != null ? Math.max(previousIndex, currentIndex) + offset : 0;

      // Update all issues if start is null or null ordinals are found. Otherwise only update changed issues.
      const issuesWithChanges = hasNullOrdinals ? issues : issues.filter(i => i.ordinal >= start && i.ordinal <= stop);

      const models: OrdinalUpdate[] = issuesWithChanges.map(
        (iss: Issue, i: number) => new OrdinalUpdate(iss._id, i + start)
      );

      return this.http
        .put<any>(`${this.v4IssuesUrl}/Ordinals`, {
          models,
          teamId: teamId === 'all' ? undefined : teamId,
          intervalCode,
          sort: { field: sortField, direction: sortDirection },
        })
        .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not update order.  Please try again.')));
    } else {
      return of();
    }
  }

  refreshOrdinals(issues: Issue[], offset: number): Issue[] {
    return issues.map((issue, i) => {
      issue.ordinal = i + offset;
      return issue;
    });
  }

  createIssuesFromRocks(rockIds: string[], intervalCode: IntervalCode) {
    this.spinnerService.start();

    const body: IssuesFromRocksBody = {
      intervalCode,
      rockIds,
    };

    this.http.post<string[]>(`${this.v4IssuesUrl}/Rocks`, body).subscribe({
      next: () => this.spinnerService.stop(),
      error: (e: unknown) =>
        this.errorService.notify(
          e,
          `Could not create ${this.stateService.language.issue.items}
         from ${this.stateService.language.rock.items}.  Please try again.`
        ),
    });
  }

  sendIssueToAnotherTeam(event: SendIssueEvent): Observable<Issue> {
    const { issue, originalTeamName, toTeamName, toTeamId, userId } = event;
    const issueCopy = _cloneDeep(issue);

    if (!issueCopy.comments) issueCopy.comments = [];

    if (toTeamId) {
      issueCopy.comments.push(new Comment(userId, `${originalTeamName} sent the issue to ${toTeamName}`));
      issueCopy.workingTeamId = toTeamId;
      issueCopy.workingTeamSentBack = false;
    } else {
      issueCopy.comments.push(new Comment(userId, `Sent back the issue to ${originalTeamName}`));
      issueCopy.workingTeamId = null;
      issueCopy.workingTeamSentBack = true;
    }

    this.issueSentToOtherTeam$.next(issueCopy);

    this.deleteLocal(issueCopy._id);
    this.addLocalSentTo(issueCopy);

    // Update server
    return this.updateIssue(
      issueCopy._id,
      {
        comments: issueCopy.comments,
        workingTeamId: issueCopy.workingTeamId,
        workingTeamSentBack: issueCopy.workingTeamSentBack,
      },
      issueCopy.teamId,
      IssueListType.sent
    ).pipe(
      tap((_: Issue) => {
        this.broadcastMessage({
          messageType: IssueMessageType.sentToOtherTeam,
          document: issue,
        }).subscribe();
      })
    );
  }

  sendBackToOriginalTeam(event: SendIssueBackEvent): Observable<Issue> {
    const { issue, workingTeamName, originalTeamName, userId } = event;

    this.deleteLocalReceived(issue._id);

    // Update server
    return this.updateIssue(
      issue._id,
      {
        comments: [
          ...(issue.comments ?? []),
          new Comment(userId, `${workingTeamName} sent the issue back to ${originalTeamName}`),
        ],
        workingTeamId: null,
        workingTeamSentBack: true,
      },
      issue.teamId,
      IssueListType.received
    ).pipe(
      tap((_: Issue) => {
        this.broadcastMessage({
          messageType: IssueMessageType.sentBack,
          document: issue,
        }).subscribe();
      })
    );
  }

  getUserLikes(userId: string): number {
    let myLikes = 0;
    this.issues.map(issue => (myLikes += issue.likes?.filter(l => l === userId).length || 0));
    return myLikes;
  }

  broadcastMessage(message: RealtimeMessage): Observable<any> {
    if (this.shouldBroadcast) {
      return this.channelService.sendMessage(this.channelId, message);
    } else {
      return EMPTY;
    }
  }

  subscribeToIssueChannel(teamId: string) {
    this.channelId = `issue-${this.stateService.companyId}-${teamId}`;
    this.shouldBroadcast = true;
    this.subscribeToMessages();
  }

  subscribeToMessages() {
    this.messageSubscription = this.channelService.messageReceived$.subscribe({
      next: message => {
        switch (message.messageType) {
          case IssueMessageType.fetch:
            this.handleMessageWithApiGet(message);
            break;
          case IssueMessageType.issue:
          case IssueMessageType.new:
            this.executeIssueMessage(message);
            break;
          case IssueMessageType.delete:
            const id = message.document as string;
            const listType = message.listType;
            switch (listType) {
              case IssueListType.received:
                this.deleteLocalReceived(id);
                break;
              case IssueListType.sent:
                this.deleteLocalSentTo(id);
                break;
              default:
                this.deleteLocal(id);
                break;
            }
            //team not needed when localOnly: true
            this.deleteIssue$.next({ id, teamId: null, listType, localOnly: true });
            break;
          case IssueMessageType.sentBack:
            if (
              message.document.teamId !== this.filterService.selectedTeamId$.value ||
              message.document.intervalCode !== this.currentIntervalCode
            )
              return;

            this.deleteLocalReceived(message.document._id);
            break;
          case IssueMessageType.sentToOtherTeam:
            if (
              message.document.teamId !== this.filterService.selectedTeamId$.value ||
              message.document.intervalCode !== this.currentIntervalCode
            )
              return;

            this.deleteLocal(message.document._id);
            this.addLocalSentTo(message.document);
            break;
          case IssueMessageType.unarchive:
            this.executeUnarchiveIssueMessage(message);
            break;
          case IssueMessageType.vote:
            this.executeVoteMessage(message);
            break;
          case IssueMessageType.sort:
            this.sortChangeLocal$.next({
              field: message.document.field as IssueSortField,
              sorted: message.document.order,
              listType: IssueListType.main,
              isShortTerm: message.document.isShortTerm,
            });
            break;
          case IssueMessageType.move:
            if (
              message.document.currentTeamId !== this.filterService.selectedTeamId$.value ||
              message.document.isShortTerm !== this.isShortTerm
            )
              return;

            const { previousIndex, currentIndex, offset } = message.document;
            this.moveIssue(
              previousIndex,
              currentIndex,
              offset,
              null,
              message.document.isShortTerm ? IntervalCode.shortTerm : IntervalCode.longTerm,
              null,
              null,
              false
            ).subscribe();
            break;
          case IssueMessageType.topThree:
            this.bulkMove(message.document.issues, message.document.offset);
            break;
          case IssueMessageType.comment:
            this.mergeIssueComments(message.document.issueId, message.document.comment);
            break;
          case IssueMessageType.commentDelete:
            this.deleteIssueComments(message.document.issueId, message.document.comment);
            break;
        }
      },
      error: (err: unknown) => console.error(err),
    });
  }

  destroyIssueChannel() {
    this.messageSubscription.unsubscribe();
  }

  executeUnarchiveIssueMessage(message: ReceivedRealtimeMessage) {
    this.getIssue((message.document as Issue)._id, false, true)
      .pipe(tap((issue: Issue) => this.addLocal(issue)))
      .subscribe();
  }

  executeIssueMessage(message: ReceivedRealtimeMessage) {
    const issue = message.document as Issue;

    if (message.messageType === IssueMessageType.issue) {
      switch (message.listType) {
        case IssueListType.received:
          this.updateLocalReceived(issue._id, issue);
          break;
        case IssueListType.sent:
          this.updateLocalSentTo(issue._id, issue);
          break;
        default:
          this.updateLocal(issue._id, issue);
          break;
      }
      this.updatedIssue$.next(issue);
    } else {
      if (issue.intervalCode !== this.currentIntervalCode) return;
      if (issue.teamId !== this.filterService.selectedTeamId$.value) return;
      this.addLocal(issue);
    }
  }

  executeVoteMessage(message: ReceivedRealtimeMessage) {
    let voteItem = message.document as VoteItem;
    voteItem = {
      ...voteItem,
      userId: message.emitterUserId,
    };
    this.voteLocal(voteItem._id, message.emitterUserId, voteItem.voteType === VoteType.negative);
    return;
  }

  handleMessageWithApiGet(message: ReceivedRealtimeMessage): void {
    // Get the required object from API and treat as a pubnub message
    switch (message.originalMessageType) {
      case IssueMessageType.issue:
      case IssueMessageType.new:
        this.getIssue((message.document as Issue)._id, false, true).subscribe({
          next: (issue: Issue) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: issue,
            } as ReceivedRealtimeMessage);
          },
        });
        break;
    }
  }

  broadcastSort(field: IssueSortField, sorted: Sorted) {
    this.broadcastMessage({
      messageType: IssueMessageType.sort,
      document: {
        listType: 'issue',
        field: field,
        order: sorted,
        isShortTerm: this.isShortTerm,
      },
    }).subscribe();
  }

  mergeIssueComments(issueId: string, update: Comment): void {
    this.issues.forEach(issue => {
      if (issue._id === issueId) {
        const index = issue.comments.findIndex(c => c.userId === update.userId && c.createdDate === update.createdDate);
        if (index > -1) {
          issue.comments[index] = Object.assign({}, issue.comments[index], update);
        } else {
          issue.comments.push(update);
        }
        this.commentUpdated$.next(null);
      }
    });
  }

  deleteIssueComments(issueId: string, update: Comment): void {
    this.issues.forEach(issue => {
      if (issue._id === issueId) {
        const index = issue.comments.findIndex(c => c.userId === update.userId && c.createdDate === update.createdDate);
        if (index > -1) {
          issue.comments.splice(index, 1);
        }
        this.commentUpdated$.next(null);
      }
    });
  }

  downloadExcel(params: GetIssuesQueryParams): Observable<ArrayBuffer> {
    this.spinnerService.start();
    const compiledParams = QueryParamsService.build(params, true);
    return this.http
      .get(`${this.v4IssuesUrl}/Excel`, {
        params: compiledParams,
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      })
      .pipe(
        tap(() => this.spinnerService.stop()),
        catchError((e: unknown) =>
          this.errorService.notify(e, `There was a problem creating this Excel file. Please try again.`)
        )
      );
  }
}
