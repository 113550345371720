<div class="confirm-dialog-container">
  <h1 mat-dialog-title data-cy="confirm-dialog_title">{{ data.title }}</h1>

  <ninety-button
    flat
    icon
    class="close-dialog-btn"
    (click)="dialogRef.close()"
    data-cy="timezone-changed-dialog_close-dialog-btn">
    <terra-icon icon="x" />
  </ninety-button>

  <mat-dialog-content>
    <div data-cy="timezone-changed-dialog_message-text">
      <span>We noticed you’re logged in from a new timezone: </span>
      <span class="terra-body-bold">{{ timeZoneForUserWeDisplay }}. </span>
      <span>Would you like to update your Ninety settings with this timezone?</span>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="timezone-actions">
    <terra-checkbox
      data-cy="timezone-changed-dialog_dont-ask-checkbox"
      class="dont-ask-checkbox"
      [(ngModel)]="dontAskAgain"
      >Don't ask again</terra-checkbox
    >
    <ninety-button-row>
      <ninety-button stroked data-cy="timezone-changed-dialog_ignore-button" (click)="ignore()">
        <terra-icon *ngIf="data.cancelButtonIcon" [icon]="data.cancelButtonIcon" />
        {{ data.cancelButtonText }}
      </ninety-button>
      <ninety-button
        filled
        primary
        class="confirm-dialog_ok-button"
        data-cy="timezone-changed-dialog_ok-button"
        (click)="confirm()">
        <terra-icon *ngIf="data.confirmButtonIcon" [icon]="data.confirmButtonIcon" (click)="confirm()" />
        {{ data.confirmButtonText }}
      </ninety-button>
    </ninety-button-row>
  </mat-dialog-actions>
</div>
