import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

export const TodoDetailChildStateKey = 'todo-detail';

export interface TodoDetailState {
  /**
   * Note, the detail service holds onto its own $data property. Just because this value is one thing does not mean that
   * the detail service is using this data. It is forwarded by TodoDetailEffects to the detail service.
   */
  selectedTodo: Todo;
}

export const TODO_DETAIL_INITIAL_STATE: TodoDetailState = {
  selectedTodo: null,
};
