import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ninety-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent {
  @Input() title!: string;
  @Input() subtitle!: string;

  @Output() closed = new EventEmitter<void>();

  constructor() {}

  onClickClose() {
    this.closed.emit();
  }
}
