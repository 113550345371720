import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, tap } from 'rxjs';

import { SpinnerService } from '../../../_core/services/spinner.service';
import { StateService } from '../../../_core/services/state.service';
import { TeamUsers } from '../../../_state/app-entities/teams/teams-state.model';
import { selectTeamUsers } from '../../../_state/app-entities/teams/teams-state.selectors';
import { UserTeamsActions } from '../../../_state/app-entities/users/users-state.actions';
import { selectCurrentUser } from '../../../_state/app-entities/users/users-state.selectors';
import { Team } from '../../models/_shared/team';
import { User } from '../../models/_shared/user';
import { SortByUserNamePipe } from '../../pipes/sort-by-user-name.pipe';

@Component({
  selector: 'ninety-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
})
export class TeamCardComponent implements OnInit, OnChanges {
  @Input() team: Team;

  @Output() closeDetail = new EventEmitter<void>();
  @Output() addUsers = new EventEmitter<User[]>();
  @Output() update = new EventEmitter<Partial<Team>>();
  @Output() removeUser = new EventEmitter<User>();

  canEdit: boolean;
  userIdsOnTeam: string[];
  teamUsers: TeamUsers;
  teamUsers$: Observable<TeamUsers>;

  constructor(
    public stateService: StateService,
    public spinnerService: SpinnerService,
    private sortByUserNamePipe: SortByUserNamePipe,
    private store: Store
  ) {}

  ngOnInit() {
    this.setTeamUsersObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['team']) {
      this.setTeamUsersObserver();
    }
  }

  setTeamUsersObserver() {
    this.teamUsers$ = this.store.select(selectTeamUsers(this.team._id)).pipe(
      tap(teamUsers => {
        this.teamUsers = cloneDeep(teamUsers); // Needed since selector data is marked readonly
        this.setCanEdit();
        this.setSelectedUserIds();
      })
    );
  }

  private setCanEdit(team = this.teamUsers.team): void {
    this.store
      .select(selectCurrentUser)
      .pipe(
        tap(user => {
          const canIgnoreCheck = this.teamUsers.team.private ? user.isOwner : user.isAdminOrOwner;
          const isManagerOrAboveOnTeam = user.isManagerOrAbove && user.teams.map(t => t.teamId).includes(team._id);
          this.canEdit = canIgnoreCheck || isManagerOrAboveOnTeam;
        })
      )
      .subscribe();
  }

  private setSelectedUserIds(): void {
    this.userIdsOnTeam = this.teamUsers.users.map(u => u._id);
  }

  onClickAddUsers(users: User[]): void {
    this.teamUsers.users = this.sortByUserNamePipe.transform([...this.teamUsers.users, ...users]);
    this.store.dispatch(UserTeamsActions.addUsersToTeam({ users, teamId: this.teamUsers.team._id }));
    this.setSelectedUserIds();
  }

  onClickRemoveUser(user: User) {
    this.store.dispatch(UserTeamsActions.removeUserFromTeam({ user: user, teamId: this.teamUsers.team._id }));
    this.setSelectedUserIds();
  }
}
