import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Subscription, filter, tap } from 'rxjs';

import { TerraDividerModule } from '@ninety/terra';
import { SelectOption } from '@ninety/ui/legacy/components/inputs/selects/base/select-option.interface';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { selectRoleSelectData } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

@Component({
  selector: 'ninety-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCommonModule, MatSelectModule, MatTooltipModule, TerraDividerModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleSelectComponent),
      multi: true,
    },
  ],
})
export class RoleSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() outlineMode: boolean;
  @Input() showLabel = false;
  @Input() initialRoleCode: { roleCode: RoleCode; isImplementer?: boolean };
  @Input() roleSelects: RoleSelectOption[];
  @Input() required = false;
  @Input() opened = false;
  @Input() tooltip: string;
  @Input() isDisabled = false;
  @Input() licensesMaxed = false;
  @Output() openedChange = new EventEmitter<boolean>();
  @Output() roleChange = new EventEmitter<RoleSelectOption>();

  private subscriptions = new Subscription();

  roleCode = RoleCode;
  roles: RoleSelectOption[] = [];
  selectedRole: RoleSelectOption = null;
  formControl = new FormControl();
  initialValue: SelectOption = null;

  constructor(private store: Store) {}

  ngOnInit() {
    this.subscriptions.add(
      this.store
        .select(selectRoleSelectData)
        .pipe(
          tap(roles => {
            this.roles = roles;
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.store
        .select(selectCurrentUser)
        .pipe(
          filter(user => user.isManagerOrAbove && !!this.initialRoleCode),
          tap(() => {
            this.setRoles();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.licensesMaxed &&
      !changes.licensesMaxed.firstChange &&
      changes.licensesMaxed.currentValue !== changes.licensesMaxed.previousValue
    ) {
      this.licensesMaxed = changes.licensesMaxed.currentValue;
      this.setRoles();
    }

    if (changes.initialRoleCode && !changes.initialRoleCode.firstChange && changes.initialRoleCode.currentValue) {
      this.setRoles();
    }
  }

  setRoles() {
    if (this.initialRoleCode?.isImplementer) {
      this.selectedRole = this.roles.find(role => role.isImplementer);
    } else if (this.initialRoleCode?.roleCode) {
      this.selectedRole = this.roles.find(
        role => role.roleCode === this.initialRoleCode.roleCode && !role.isImplementer
      );
    }
  }

  setOpened(opened: boolean, keepOpened: false): void {
    this.opened = keepOpened ? opened || this.opened : opened;
    this.openedChange.emit(this.opened);
  }

  public onValueChange(option: RoleSelectOption) {
    this.selectedRole = option;
    this.roleChange.emit(option);
  }
}
