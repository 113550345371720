import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateCustomFormat',
})
export class DateCustomFormatPipe implements PipeTransform {
  transform(value: any, locale = 'default'): string {
    if (!value) return value;

    const date = new Date(value);
    const today = new Date();

    let formattedDate = date.toLocaleString(locale, { month: 'short' }) + ' ' + date.getDate();

    if (date.getFullYear() !== today.getFullYear()) {
      formattedDate += ' ' + date.getFullYear();
    }

    return formattedDate;
  }
}
