/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { ErrorService } from '../../../_core/services/error.service';
import { NotifyService } from '../../../_core/services/notify.service';

import { NotificationActions } from './notification.actions';

@Injectable()
export class NotificationStateEffects {
  notify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.notify),
        tap(action => this.notifyService.notify(action.message, action.duration, action.action))
      ),
    { dispatch: false }
  );

  notifyV2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.notifyV2),
        tap(action => this.notifyService.notifyV2(action.message, action.params))
      ),
    { dispatch: false }
  );

  notifyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.notifyError),
        tap(action => this.errorService.notify(action.error, action.message))
      ),
    { dispatch: false }
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.showError),
        tap(action => this.notifyService.showError(action.message, action.title, action.override))
      ),
    { dispatch: false }
  );

  success$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.success),
        tap(action => this.notifyService.success(action.message, action.title, action.override))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private errorService: ErrorService, private notifyService: NotifyService) {}
}
