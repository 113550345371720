import ObjectID from 'bson-objectid';

/** An Error that comes with a unique id. */
export class ErrorWithId extends Error {
  readonly id = new ObjectID().toHexString();

  constructor(public message: string, public readonly name = 'Error') {
    super(message);
  }
}
