import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { DataLayerEffects } from './_state/data-layer.effects';
import { DataLayerService } from './services/data-layer.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

const providers = [DataLayerService, GoogleAnalyticsService];

const effects = [DataLayerEffects];

@NgModule({
  imports: [EffectsModule.forFeature(effects)],
  providers: [...providers],
})
export class GoogleAnalyticsModule {}
