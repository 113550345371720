<h2 mat-dialog-title><span [innerHTML]="data.header"></span></h2>

<mat-dialog-content>
  <span [innerHTML]="data.body"></span>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <ninety-button-row>
    <ninety-button stroked (click)="dialogRef.close()">Cancel</ninety-button>
    <ninety-button filled primary class="uppercase" (click)="accept()">Go to</ninety-button>
  </ninety-button-row>
</mat-dialog-actions>
