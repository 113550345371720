import { createReducer, on } from '@ngrx/store';
import _cloneDeep from 'lodash/cloneDeep';

import { FeedbackApiActions } from '@ninety/feedback/_api/feedback-api.actions';
import { ConversationContextMenuActions } from '@ninety/feedback/_shared/conversation-context-menu/_state/conversation-context-menu.actions';
import {
  ConversationDetailActions,
  ConversationDetailCommentActions,
  ConversationDetailStoreActions,
} from '@ninety/feedback/_shared/conversation-detail/_state/conversation-detail.actions';
import { FilterServiceActions } from '@ninety/ui/legacy/core/services/_state/filter-service/filter.service.actions';

import { ConversationRealTimeEffectActions } from '../real-time/conversation-real-time.actions';

import {
  ConversationListEffectActions,
  ConversationListFacadeActions,
  ConversationListFilterEffectActions,
} from './conversation-list.actions';
import { ConversationListState, ConversationListTense, initialConversationListState } from './conversation-list.model';

const _getTense = (state: ConversationListState): ConversationListTense =>
  state.filter.showCompletedConversations ? 'pastList' : 'activeList';

export const conversationListReducer = createReducer<ConversationListState>(
  initialConversationListState,

  on(
    ConversationListEffectActions.setConversationType,
    (state, { conversationType, selectedConversationId }): ConversationListState => {
      const newState = { ...state, conversationType, selectedConversationId };
      if (state.conversationType === conversationType) return newState;
      return {
        ...newState,
        sort: { active: 'dueDate', direction: 'desc' },
        pager: {
          ...state.pager,
          pageIndex: 0,
        },
      };
    }
  ),

  // On the first initialization of the ConversationList, show Conversations for the logged-in user.
  // Subsequent initializations should retain the previous filter value.
  on(
    ConversationListFacadeActions.conversationListInit,
    (state, { currentUserId }): ConversationListState => ({
      ...state,
      filter: {
        ...state.filter,
        selectedUserId: state.filter.initFilterWithCurrentUser ? currentUserId : state.filter.selectedUserId,
      },
    })
  ),

  on(
    ConversationDetailActions.attachmentAdded,
    (state, { conversationId, attachment }): ConversationListState => ({
      ...state,
      activeList: {
        ...state.activeList,
        [state.conversationType]: {
          ...state.activeList[state.conversationType],
          items: state.activeList[state.conversationType].items.map(item => {
            if (item._id === conversationId)
              item = {
                ...item,
                attachments: item.attachments ? [...item.attachments, attachment] : [attachment],
              };
            return item;
          }),
        },
      },
    })
  ),

  on(
    ConversationDetailActions.attachmentRemoved,
    (state, { conversationId, attachment }): ConversationListState => ({
      ...state,
      activeList: {
        ...state.activeList,
        [state.conversationType]: {
          ...state.activeList[state.conversationType],
          items: state.activeList[state.conversationType].items.map(item => {
            if (item._id === conversationId)
              item = {
                ...item,
                attachments: item.attachments.filter(a => a._id !== attachment._id),
              };
            return item;
          }),
        },
      },
    })
  ),

  on(ConversationContextMenuActions.conversationDeleted, (state, { conversation }): ConversationListState => {
    const tense = _getTense(state);
    const list = _cloneDeep(state[tense][state.conversationType]);
    const index = list.items.findIndex(item => item._id === conversation._id);
    if (index < 0) return state;
    list.items.splice(index, 1);
    list.totalCount -= 1;
    return {
      ...state,
      [tense]: {
        ...state[tense],
        [state.conversationType]: { ...list },
      },
    };
  }),

  on(ConversationContextMenuActions.conversationStatusUpdated, (state, { conversation }): ConversationListState => {
    const tense = _getTense(state);
    const list = _cloneDeep(state[tense][state.conversationType]);
    const index = list.items.findIndex(item => item._id === conversation._id);
    if (index < 0) return state;
    list.items.splice(index, 1);
    list.totalCount -= 1;
    return {
      ...state,
      [tense]: {
        ...state[tense],
        [state.conversationType]: { ...list },
      },
    };
  }),

  on(FeedbackApiActions.conversationListReceived, (state, { conversations }): ConversationListState => {
    const tense = _getTense(state);
    return {
      ...state,
      [tense]: {
        ...state[tense],
        [state.conversationType]: {
          items: [...conversations.items],
          totalCount: conversations.totalCount,
        },
      },
      filter: {
        ...state.filter,
        initFilterWithCurrentUser: false,
      },
    };
  }),

  on(
    FilterServiceActions.showCompletedConversationsToggled,
    (state): ConversationListState => ({
      ...state,
      selectedConversationId: null,
      filter: {
        ...state.filter,
        showCompletedConversations: !state.filter.showCompletedConversations,
      },
    })
  ),

  on(
    ConversationListFacadeActions.conversationListPagerChanged,
    (state, action): ConversationListState => ({
      ...state,
      pager: {
        ...state.pager,
        pageIndex: action.pageIndex,
        pageSize: action.pageSize,
      },
    })
  ),

  on(
    ConversationListFacadeActions.conversationListSorted,
    (state, sort): ConversationListState => ({
      ...state,
      sort,
    })
  ),

  on(FeedbackApiActions.conversationCreated, (state, action): ConversationListState => {
    const newState: ConversationListState = { ...state };
    const conversations = _cloneDeep(newState.activeList[newState.conversationType].items);

    // Remove conversations from end of old list based on how many new conversations are created.
    // This is to keep the pager in sync
    const remainingConversations =
      conversations.length + action.conversations.length <= state.pager.pageSize
        ? conversations
        : conversations.slice(0, 0 - action.conversations.length);

    newState.activeList = {
      ...newState.activeList,
      [newState.conversationType]: {
        items: [...action.conversations, ...remainingConversations],
        totalCount: state.activeList[newState.conversationType].totalCount + action.conversations.length,
      },
    };

    return newState;
  }),

  on(
    ConversationListEffectActions.detailViewOpened,
    (state, action): ConversationListState => ({
      ...state,
      selectedConversationId: action.selectedConversationId,
    })
  ),

  on(
    ConversationListFacadeActions.conversationDeselected,
    ConversationListEffectActions.conversationDeselected,
    ConversationListFacadeActions.conversationListDestroyed,
    ConversationListEffectActions.detailClosedFromDetailPanel,
    (state): ConversationListState => ({
      ...state,
      selectedConversationId: null,
    })
  ),

  on(
    ConversationListFilterEffectActions.conversationSearchFilterTextUpdated,
    (state: ConversationListState, action): ConversationListState => ({
      ...state,
      selectedConversationId: null,
      filter: {
        ...state.filter,
        searchText: !action.searchText?.trim() ? null : action.searchText,
      },
    })
  ),

  on(
    ConversationListFilterEffectActions.conversationSelectedUserFilterUpdated,
    (state: ConversationListState, action): ConversationListState => ({
      ...state,
      selectedConversationId: null,
      filter: {
        ...state.filter,
        selectedUserId: action.selectedUserId,
      },
    })
  ),

  on(
    ConversationDetailStoreActions.conversationEdited,
    (state, { _id, title, dueDate, comments }): ConversationListState => {
      const tense = _getTense(state);
      return {
        ...state,
        [tense]: {
          ...state[tense],
          [state.conversationType]: {
            ...state[tense][state.conversationType],
            items: state[tense][state.conversationType].items.map(item => {
              if (item._id === _id) item = { ...item, dueDate, title, comments };
              return item;
            }),
          },
        },
      };
    }
  ),

  on(ConversationDetailCommentActions.commentAdded, (state, { conversationId, comment }): ConversationListState => {
    const tense = _getTense(state);
    return {
      ...state,
      [tense]: {
        ...state[tense],
        [state.conversationType]: {
          ...state[tense][state.conversationType],
          items: state[tense][state.conversationType].items.map(item => {
            if (item._id === conversationId)
              item = {
                ...item,
                comments: [...item.comments, comment],
              };
            return item;
          }),
        },
      },
    };
  }),

  on(ConversationDetailCommentActions.commentDeleted, (state, { conversationId, index }): ConversationListState => {
    const tense = _getTense(state);
    return {
      ...state,
      [tense]: {
        ...state[tense],
        [state.conversationType]: {
          ...state[tense][state.conversationType],
          items: state[tense][state.conversationType].items.map(item => {
            if (item._id === conversationId)
              item = {
                ...item,
                comments: item.comments.filter((c, i) => i !== index),
              };
            return item;
          }),
        },
      },
    };
  }),

  on(
    ConversationRealTimeEffectActions.showButton,
    ConversationRealTimeEffectActions.meetingStarted,
    (state, { _id }): ConversationListState => {
      const tense = _getTense(state);
      return {
        ...state,
        [tense]: {
          ...state[tense],
          [state.conversationType]: {
            ...state[tense][state.conversationType],
            items: state[tense][state.conversationType].items.map(item => {
              if (item._id === _id) item = { ...item, showStartMeetingButton: true };
              return item;
            }),
          },
        },
      };
    }
  ),

  on(ConversationRealTimeEffectActions.meetingEnded, (state, { _id }): ConversationListState => {
    const tense = _getTense(state);
    return {
      ...state,
      [tense]: {
        ...state[tense],
        [state.conversationType]: {
          ...state[tense][state.conversationType],
          items: state[tense][state.conversationType].items.map(item => {
            if (item._id === _id) item = { ...item, showStartMeetingButton: false };
            return item;
          }),
        },
      },
    };
  })
);
