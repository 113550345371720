import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { MenuComponent, MenuItemData, MenuSection } from '@ninety/ui/legacy/components/menu/menu.component';
import { DividerMenuItemComponent } from '@ninety/ui/legacy/components/menu-items/divider-menut-item/divider-menu-item.component';
import { MenuItemLargeComponent } from '@ninety/ui/legacy/components/menu-items/large-menu-item/large-menu-item.component';
import { POPOVER_TOKEN, PopoverService } from '@ninety/ui/legacy/components/popover/popover.service';
import { NavMenuIntercomActions } from '@ninety/ui/legacy/state/app-global/intercom/intercom-state.actions';

@Component({
  standalone: true,
  selector: 'ninety-learn-support-menu',
  templateUrl: './learn-support-menu.component.html',
  styleUrls: ['./learn-support-menu.component.scss'],
  imports: [CommonModule, MenuComponent, MenuItemLargeComponent, DividerMenuItemComponent],
})
export class LearnSupportMenuComponent {
  constructor(@Inject(POPOVER_TOKEN) private popoverRef: PopoverService, private store: Store) {}

  dataSource: MenuSection[] = [
    // {
    //   data: [
    //     {
    //       icon: { name: 'rocket-duotone', size: 'xl' },
    //       label: 'Getting Started',
    //       description: 'Get started with resources that help you get the most out of Ninety.',
    //       action: data => this.itemClicked(data),
    //       divider: true,
    //       attr: 'main-sidebar_learn-menu-getting-started__getting-started',
    //     },
    //   ],
    // },
    {
      title: 'LEARNING',
      data: [
        // TODO: will be reinstated once we have BOS specific content
        // {
        //   icon: { name: 'book-bookmark-duotone', size: 'xl' },
        //   label: 'Learning Library',
        //   description: 'Learn concepts, tools, and disciplines for building great companies.',
        //   action: data => this.itemClicked(data),
        //   attr: 'main-sidebar_learn-menu-learning__learning-library',
        // },
        // {
        //   icon: { name: 'newspaper-duotone', size: 'xl' },
        //   label: 'Blogs',
        //   description: 'Read our latest blogs on topics that help you succeed.',
        //   action: data => this.itemClicked(data),
        //   attr: 'main-sidebar_learn-menu-learning__blogs',
        // },
        {
          icon: { name: 'compass', variant: 'duotone', size: 40 },
          label: 'Product Updates',
          description: 'Learn about our latest product updates.',
          action: data => this.itemClicked(data),
          attr: 'main-sidebar_learn-menu-learning__product-updates',
        },
      ],
    },
    {
      title: 'SUPPORT',
      data: [
        {
          icon: { name: 'chat-circle-dots', variant: 'duotone', size: 40 },
          label: 'Contact Support',
          description: 'Real-time chat support. Get answers to your questions right away.',
          action: data => this.itemClicked(data),
          attr: 'main-sidebar_learn-menu-support__contact-Support',
        },
        {
          icon: { name: 'question-circle', variant: 'duotone', size: 40 },
          label: 'Help Center',
          description: 'Solution-driven articles to support your mastery of Ninety.',
          action: data => this.itemClicked(data),
          attr: 'main-sidebar_learn-menu-support__help-center',
        },
        {
          icon: { name: 'headset', variant: 'duotone', size: 40 },
          label: 'Live Training',
          description: 'Sign up for a live training session with our Ninety product experts.',
          action: data => this.itemClicked(data),
          attr: 'main-sidebar_learn-menu-support__live-training',
        },
      ],
    },
  ];

  itemClicked(item: MenuItemData) {
    const protocol = 'https://';
    const base = 'ninety.io/';
    switch (item.label.toLowerCase()) {
      case 'getting started': {
        window.open(`${protocol}${base}onboarding`, '_blank');
        break;
      }
      case 'learning library': {
        window.open(`${protocol}${base}library`, '_blank');
        break;
      }
      case 'blogs': {
        window.open(`${protocol}${base}blog`, '_blank');
        break;
      }
      case 'product updates': {
        window.open(`${protocol}${base}product-updates`, '_blank');
        break;
      }
      case 'contact support': {
        this.store.dispatch(NavMenuIntercomActions.showMessenger());
        break;
      }
      case 'help center': {
        window.open(`${protocol}help.${base}`, '_blank');
        break;
      }
      case 'live training': {
        window.open(`${protocol}${base}resources#live-training`, '_blank');
        break;
      }
    }
    this.popoverRef.close();
  }
}
