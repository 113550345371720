import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { DSColorUtils } from '@ninety/terra';

import { selectCompanyColorBranding } from '../../_state/app-global/company/company-state.selectors';
import { BrandingTheme, hexToRgb, ThemeName } from '../models/branding';

@Directive({
  selector: '[ninetyTheme]',
  standalone: true,
})
export class BrandingThemeDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select(selectCompanyColorBranding)
      .pipe(takeUntil(this.destroy$))
      .subscribe(colors => this.updateTheme(colors));
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

  updateTheme(theme: BrandingTheme) {
    for (const key in theme.properties) {
      if (theme.properties.hasOwnProperty(key)) {
        document.body.style.setProperty(key, hexToRgb(theme.properties[key]));

        if (key === '--primaryAccent') {
          const brandColors = DSColorUtils.generateDynamicBrandColors(theme.properties[key]);
          for (const key in brandColors) {
            document.body.style.setProperty(key, brandColors[key]);
          }
        }
      }
    }
    for (const t in ThemeName) {
      document.body.classList.remove(`${t}-theme`);
    }
    document.body.classList.add(`${theme.name}-theme`);
  }
}
