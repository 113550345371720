export const ResponsibilityChartTopRoute = 'chart';

/** Child route segments for the Org Chart */
export enum ResponsibilityChildRoutes {
  chart = '',
  shared = 'shared',
  withId = ':chartId', // NEVER make this :id - it will conflict in meetings and the org chart will render wrong.
  primary = 'primary',
}

/** Possible return values for the chart pages breadcrumbs */
export type ChartPageReferrer = ResponsibilityChildRoutes.shared | ResponsibilityChildRoutes.primary;

/** Full Org Chart route factory functions */
export namespace ResponsibilityChartFullRoutes {
  export const chart = ResponsibilityChartTopRoute;
  export const shared = `${ResponsibilityChartTopRoute}/${ResponsibilityChildRoutes.shared}`;
  export const primary = `${ResponsibilityChartTopRoute}/${ResponsibilityChildRoutes.primary}`;

  export function chartById(id: string): string {
    return `${ResponsibilityChartTopRoute}/${id}`;
  }

  export function detailRoute(id: string): string {
    return `detail/chart/${id}`;
  }
}

/** Data used by the Org Chart pages to determine which tab is active based on the route */
export interface ResponsibilityChildRouteData {
  tab: ResponsibilityChildRoutes;
  /** Key used by the {@link StateService} to pluck custom language to use as a page title */
  route: string;
}

export const orgChartRouteTitleData: Readonly<Pick<ResponsibilityChildRouteData, 'route'>> = { route: 'acChart' };
export const orgChartSharedRouteData: ResponsibilityChildRouteData = {
  tab: ResponsibilityChildRoutes.shared,
  ...orgChartRouteTitleData,
};
export const orgChartPrimaryRouteData: ResponsibilityChildRouteData = {
  tab: ResponsibilityChildRoutes.chart,
  ...orgChartRouteTitleData,
};
