<h1 mat-dialog-title class="upload-header">Upload Company Logo</h1>
<mat-dialog-actions class="dialog-action">
  <button mat-button (click)="fileInput.click()">Choose File</button>
  <span class="file-name">{{ fileName }}</span>
</mat-dialog-actions>
<form hidden>
  <input type="file" #fileInput accept="image/*" (change)="handleFileChangeEvent($event)" />
</form>
<div class="crop-area">
  <image-cropper
    class="image-cropper"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="false"
    format="png"
    (imageCropped)="imageCropped($event)"></image-cropper>
</div>
<img *ngIf="croppedImage" class="preview" [src]="croppedImage" alt="preview" />
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="onCancel()">Cancel</button>
  <button
    mat-flat-button
    color="accent"
    (click)="onUpload()"
    [disabled]="croppedImage === '' || (spinnerService.primary$ | async)">
    Upload
  </button>
</mat-dialog-actions>
