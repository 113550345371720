import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { ElementResizeAwareModule } from '@ninety/ui/legacy/shared/directives/element-resize-aware/element-resize-aware.module';
import { ActionsForwarder } from '@ninety/ui/storybook/_utils/devtools.utils';

import { GridLayoutStateEffects } from './_state/grid-layout-state.effects';
import { GRID_LAYOUT_STATE_KEY } from './_state/grid-layout-state.model';
import { gridLayoutStateReducers } from './_state/grid-layout-state.reducers';
import { GridItemComponent } from './components/grid-item/grid-item.component';
import { GridLayoutComponent } from './components/grid-layout/grid-layout.component';
import { GridLayoutToolbarButtonsComponent } from './components/grid-layout-toolbar-buttons/grid-layout-toolbar-buttons.component';
import { GridItemTemplateDefaultDirective } from './directives/grid-item-template-default.directive';
import { GridItemTemplateDirective } from './directives/grid-item-template.directive';

@NgModule({
  declarations: [
    GridItemComponent,
    GridLayoutComponent,
    GridLayoutToolbarButtonsComponent,
    GridItemTemplateDirective,
    GridItemTemplateDefaultDirective,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    ElementResizeAwareModule,
    StoreModule.forFeature(GRID_LAYOUT_STATE_KEY, gridLayoutStateReducers),
    EffectsModule.forFeature([GridLayoutStateEffects]),
    TerraIconModule,
  ],
  exports: [
    GridItemComponent,
    GridLayoutComponent,
    GridLayoutToolbarButtonsComponent,
    GridItemTemplateDirective,
    GridItemTemplateDefaultDirective,
  ],
})
export class GridLayoutModule {
  static forTests() {
    return [
      // For some reason, this is not handled by the provideMockStore function?
      StoreModule.forRoot({}),
      EffectsModule.forRoot([ActionsForwarder]),
      GridLayoutModule,
    ];
  }
}
