import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

import * as headlinesSelectors from '@ninety/headlines/_state/headlines/headlines-state.selectors';
import { DragAndDropEvent } from '@ninety/todos/_state/_shared/todo-state.shared.actions';
import { FilterServiceActions } from '@ninety/ui/legacy/core/services/_state/filter-service/filter.service.actions';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { PrintOptions } from '@ninety/ui/legacy/core/services/print.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { AttachmentEvent } from '@ninety/ui/legacy/shared/models/_shared/attachment-event';
import { AttachmentMessageType } from '@ninety/ui/legacy/shared/models/_shared/attachment-message-type';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { HeadlinesSort, HeadlinesSortField } from '@ninety/ui/legacy/shared/models/headlines/headline-sort';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';
import { SortEvent } from '@ninety/ui/legacy/shared/models/sort/sort-event';
import { selectCurrentUserIsManageeOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';

import { HeadlinesStateActions } from './headlines-state.actions';
import { HeadlineStateKey } from './headlines-state.model';
import {
  selectFilterByArchived,
  selectFilterByTeamId,
  selectFocusOnInlineAdd,
  selectHeadlines,
  selectIsSearching,
  selectListControlsDisabled,
  selectPagination,
  selectSelectedId,
  selectSort,
  selectTemplateType,
  selectTotalCount,
} from './headlines-state.selectors';

/** we need an id for NGRX entity, it is dropped before the request is sent, server ignores it as well */
export const INLINE_TEMP_HEADLINE_ID = 'inline-temp-headline-id';

@Injectable()
export class HeadlinesStateFacade {
  public stateService = inject(StateService);
  public filterService = inject(FilterService);
  public localStorageService = inject(LocalStorageService);
  private store = inject(Store);
  private actions = inject(Actions);

  headlines$ = this.store.select(selectHeadlines);
  totalCount$ = this.store.select(selectTotalCount);
  showingArchived$ = this.store.select(selectFilterByArchived);
  isSearching$ = this.store.select(selectIsSearching);
  selectedId$ = this.store.select(selectSelectedId);
  pagination$ = this.store.select(selectPagination);
  sort$ = this.store.select(selectSort);
  inMeetingViewTool$ = this.store.select(headlinesSelectors.selectInMeetingViewToolHeadlines);
  filterByTeamId$ = this.store.select(selectFilterByTeamId);
  readonly canEdit$ = this.store.select(selectCurrentUserIsManageeOrAbove);

  listControlsDisabled$ = this.store.select(selectListControlsDisabled);
  focusOnInlineAdd$ = this.store.select(selectFocusOnInlineAdd);

  templateType$ = this.store.select(selectTemplateType);

  constructor() {
    const pageSize = parseInt(this.localStorageService.get(`${HeadlineStateKey}.pagination.size`));
    const sort = this.localStorageService.getAndParse<HeadlinesSort>(`${HeadlineStateKey}.sort`);
    this.store.dispatch(HeadlinesStateActions.hydrate({ pageSize, sort }));

    this.actions
      .pipe(
        ofType(FilterServiceActions.searchTextUpdated),
        debounceTime(400),
        distinctUntilChanged((prev, curr) => prev.searchText === curr.searchText),
        tap(({ searchText }) => this.search(searchText)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  clearState() {
    this.store.dispatch(HeadlinesStateActions.resetStore());
  }

  teamSelected(teamId: string) {
    this.store.dispatch(HeadlinesStateActions.filterByTeam({ teamId }));
  }

  showArchived(archived: boolean) {
    this.store.dispatch(HeadlinesStateActions.showArchived({ archived }));
  }

  setInMeetingId(meetingId: string) {
    this.store.dispatch(HeadlinesStateActions.setInMeetingId({ meetingId }));
  }

  search(searchText: string) {
    this.store.dispatch(HeadlinesStateActions.search({ searchText }));
  }

  openCreateDialog() {
    this.store.dispatch(HeadlinesStateActions.openCreateDialog());
  }

  clearSelected() {
    this.store.dispatch(HeadlinesStateActions.clearSelected());
  }

  select(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.openInDetailView({ headlineId: headline._id }));
  }

  dropped(event: DragAndDropEvent) {
    this.store.dispatch(HeadlinesStateActions.updateOrdinals(event));
  }

  paginate($event: PageEvent) {
    this.store.dispatch(HeadlinesStateActions.paginationChange({ index: $event.pageIndex, size: $event.pageSize }));
  }

  sortChange($event: SortEvent<HeadlinesSortField>) {
    this.store.dispatch(HeadlinesStateActions.sortChange({ sort: $event }));
  }

  add(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.add({ headline }));
  }

  addLocal(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.addLocal({ headline }));
  }

  update({ id, update: changes }: { id: string; update: Partial<Headline> }) {
    this.store.dispatch(HeadlinesStateActions.update({ update: { id, changes } }));
  }

  updateLocal({ id, update: changes }: { id: string; update: Partial<Headline> }) {
    this.store.dispatch(HeadlinesStateActions.updateLocal({ update: { id, changes } }));
  }

  setArchived(event: { id: string; archived: boolean }) {
    this.store.dispatch(HeadlinesStateActions.setArchived(event));
  }

  setCompleted(event: { id: string; completed: boolean }) {
    this.store.dispatch(HeadlinesStateActions.setCompleted(event));
  }

  delete({ id }: { id: string }) {
    this.store.dispatch(HeadlinesStateActions.delete({ id }));
  }

  deleteLocal({ id }: { id: string }) {
    this.store.dispatch(HeadlinesStateActions.deleteLocal({ id }));
  }

  openInlineCreate() {
    const headline: Headline = {
      _id: INLINE_TEMP_HEADLINE_ID,
      ownedByUserId: this.stateService.currentUser._id,
      ownedByUser: this.stateService.currentUser,
      title: '',
      description: '',
      itemType: ItemType.headline,
      companyId: this.stateService.currentUser.company.companyId,
      isCascadedMessage: false,
      ordinal: -1,
      userOrdinal: -1,
      teamIds: [this.filterService.selectedTeamId$.value],
    };
    this.store.dispatch(HeadlinesStateActions.addInline({ headline }));
  }

  cancelAddInline() {
    this.store.dispatch(HeadlinesStateActions.cancelAddInline());
  }

  saveInline({ headline }: { headline: Headline }) {
    this.store.dispatch(HeadlinesStateActions.saveInline({ headline }));
  }

  cascade(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.cascade({ headline }));
  }

  createIssue(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.createIssue({ headline }));
  }

  createTodo(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.createTodo({ headline }));
  }

  createRock(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.createRock({ headline }));
  }

  createHeadline(headline: Headline) {
    this.store.dispatch(HeadlinesStateActions.createHeadline({ headline }));
  }

  notify(message: string) {
    this.store.dispatch(NotificationActions.notify({ message }));
  }

  printHeadlinesAndCms(printOptions: PrintOptions) {
    this.store.dispatch(HeadlinesStateActions.printHeadlinesAndCms({ printOptions }));
  }

  archiveAllCompleted() {
    this.store.dispatch(HeadlinesStateActions.archiveAllCompleted());
  }

  /** Local Attachment Actions */
  attachmentEvent(event: AttachmentEvent & { meetingMessageType: AttachmentMessageType }) {
    if (event.type === 'upload') {
      this.store.dispatch(HeadlinesStateActions.attachmentUploaded({ event }));
    } else {
      this.store.dispatch(HeadlinesStateActions.attachmentRemoved({ event }));
    }
  }
}
