<mat-accordion [hideToggle]="true">
  <mat-expansion-panel [expanded]="expanded" (opened)="expanded = true" (closed)="expanded = false">
    <mat-expansion-panel-header class="panel-header">
      <mat-panel-title>
        <terra-icon
          icon="caret-up"
          data-cy="meeting-notes_expander-icon"
          class="rotate"
          [ngClass]="{ 'rotate-90': expanded }" />
        <terra-divider vertical="true" class="vertical-divider" />
        <terra-icon class="notes-icon" icon="notepad" />&nbsp;&nbsp;
        <div class="terra-title">{{ stateService.language.meeting.item }} Notes</div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ninety-text-editor
      data-cy="meeting-notes_text-editor"
      *ngIf="meetingService.currentMeeting"
      placeholder="Meeting Notes..."
      [(text)]="meetingService.currentMeeting.notes"
      (textChange)="updateMeetingNotes$.next($event)"
      [readonly]="this.stateService.isObserver"></ninety-text-editor>

    <ninety-attachments
      *ngIf="meetingService.currentMeeting"
      [item]="meetingService.currentMeeting"
      attachmentParentType="Meeting"
      (afterUploadOrRemove)="this.updateMeetingAttachments$.next(null)"></ninety-attachments>
  </mat-expansion-panel>
</mat-accordion>
