import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

import { TerraIconModule, TerraIconName } from '@ninety/terra';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { AvatarUploadDialogComponent } from '@ninety/ui/legacy/shared/components/avatar-upload-dialog/avatar-upload-dialog.component';
// eslint-disable-next-line max-len
import { PersonMetadata } from '@ninety/ui/legacy/shared/models/_shared/person-metadata';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';

interface MenuItem {
  key: string;
  icon: TerraIconName;
  label: string;
  className?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'ninety-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TerraIconModule, CommonModule, MatMenuModule],
})
export class NinetyAvatarUploaderComponent implements OnInit {
  private _metadata;
  menuItems: MenuItem[] = [
    { icon: 'edit', label: 'Change photo', key: 'change' },
    { icon: 'delete', label: 'Delete photo', key: 'delete', className: 'delete-item', isDisabled: false },
  ];
  get metadata() {
    return this._metadata;
  }
  @Input() set metadata(metadata) {
    this._metadata = metadata;
  }
  @Input() userInfo: Partial<User>;

  @Output() updateMetadata = new EventEmitter<Partial<PersonMetadata>>();
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateMenuItems();
  }

  private uploadNewProfilePicture(): void {
    const dialogData = {
      editeePersonMetadataId: this._metadata._id,
      editeeUserId: this.userInfo._id,
    };

    this.dialog
      .open(AvatarUploadDialogComponent, {
        id: 'avatar-upload-dialog',
        data: dialogData,
        maxHeight: '95vh',
      })
      .afterClosed()
      .subscribe({
        next: dialogResponse => {
          if (dialogResponse?.url) {
            this.metadata.picture = { url: dialogResponse.url, count: 1 };
            this.updateMetadata.emit(this.metadata);
            this.updateMenuItems();
            this.changeDetectorRef.detectChanges();
          }
        },
      });
  }

  private removeProfilePicture(): void {
    const metadata = { ...this.metadata };
    metadata.picture.url = null; // Fix for DEV-4641
    delete metadata._id;
    this.spinnerService.spinWhile(this.userService.updatePersonMetadata(metadata)).subscribe(() => {
      this.metadata.picture = { ...metadata.picture };
      this.updateMetadata.emit(this.metadata);
      this.updateMenuItems();
    });
  }

  onMenuItemClick(item: MenuItem): void {
    switch (item.key) {
      case 'change': {
        this.uploadNewProfilePicture();
        break;
      }
      case 'delete': {
        if (this.metadata.picture?.url.length > 0) {
          this.dialog
            .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
              data: {
                title: 'Delete Photo',
                message: 'This will delete your profile photo. Are you sure?',
              },
            })
            .afterClosed()
            .subscribe(confirmed => {
              if (confirmed !== undefined) {
                this.removeProfilePicture();
              }
            });
        }
        break;
      }
    }
  }

  private updateMenuItems(): void {
    this.menuItems.map(item => {
      if (item.key === 'delete') {
        item.isDisabled = this.metadata.picture?.url === null || this.metadata.picture?.url.length === 0 ? true : false;
      }
    });
  }
}
