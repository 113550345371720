import { ActionReducerMap } from '@ngrx/store';

import {
  cascadingMessagesInitialState,
  CascadingMessagesStateModel,
  CascadingMessageStateKey,
} from './cascading-messages/cascading-messages-state.model';
import { CascadingMessagesStateReducer } from './cascading-messages/cascading-messages-state.reducer';
import { headlinesInitialState, HeadlinesStateModel, HeadlineStateKey } from './headlines/headlines-state.model';
import { HeadlinesStateReducer } from './headlines/headlines-state.reducer';

export interface HeadlinesModuleState {
  [HeadlineStateKey]: HeadlinesStateModel;
  [CascadingMessageStateKey]: CascadingMessagesStateModel;
}

export const HeadlinesModuleStateReducer: ActionReducerMap<HeadlinesModuleState> = {
  [HeadlineStateKey]: HeadlinesStateReducer,
  [CascadingMessageStateKey]: CascadingMessagesStateReducer,
};

export const InitialHeadlinesModuleState: HeadlinesModuleState = {
  [HeadlineStateKey]: headlinesInitialState,
  [CascadingMessageStateKey]: cascadingMessagesInitialState,
};
