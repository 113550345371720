import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import Fuse, { IFuseOptions } from 'fuse.js';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatestWith,
  exhaustMap,
  filter,
  map,
  mergeMap,
  of,
  take,
  tap,
} from 'rxjs';

import { AccountabilityChartService } from '@ninety/accountability-chart/services/accountability-chart.service';
import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { FeedbackService } from '@ninety/feedback/_shared/feedback.service';
import { selectShowCompletedConversations } from '@ninety/feedback/_state/conversation-list/conversation-list.selectors';
import { FormalConversationSettingsComponent } from '@ninety/feedback/formal-conversation-settings/formal-conversation-settings.component';
import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { GridLayoutSelectors } from '@ninety/layouts/grid-layout/_state/grid-layout-state.selectors';
import { TeamSearchComponent } from '@ninety/settings/company/teams/search/team-search/team-search.component';
import { ExcelExportType } from '@ninety/ui/legacy/core/services/_state/filter-service/excel-export-types.enum';
import { AuxiliaryRouterOutletService } from '@ninety/ui/legacy/core/services/auxiliary-router-outlet.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { RockStatusService } from '@ninety/ui/legacy/core/services/rock-status.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { ConversationDetailInput } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { DirectoryUserStatus } from '@ninety/ui/legacy/shared/models/directory/directory-user-status';
import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { MasteryFilterStatus } from '@ninety/ui/legacy/shared/models/mastery/mastery-status';
import { SortByNamePipe } from '@ninety/ui/legacy/shared/pipes/sort-by-name.pipe';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import {
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import {
  selectHasAnyHelpfulPermission,
  selectHasHelpfulPermission,
} from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectUserTeams } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';
import { LegacyVTOGridLayoutActions } from '@ninety/vto/services/legacy-vto-grid-layout-actions.service';
import { VtoService } from '@ninety/vto/services/vto.service';

@Component({
  selector: 'ninety-filters-toolbar',
  templateUrl: './filters-toolbar.component.html',
  styleUrls: ['./filters-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersToolbarComponent implements OnInit, OnDestroy {
  @Input() isMeeting?: boolean;

  selectedTeam = this.teamService.teamAll;
  isSlt: boolean;
  subscriptions = new Subscription();
  RoleCode = RoleCode;
  MasteryFilterStatus = MasteryFilterStatus;
  DirectoryUserStatus = DirectoryUserStatus;
  searchTerm$ = new BehaviorSubject<string>('');
  teams$: Observable<(Team & { canEdit: boolean })[]>;
  filteredTeams$: Observable<(Team & { canEdit: boolean })[]> = of([]);
  private fuse: Fuse<Team & { canEdit: boolean }> | undefined;
  private fuseOptions: IFuseOptions<Team & { canEdit: boolean }> = {
    keys: ['name'],
    includeScore: true,
    threshold: 0.3,
  };

  @ViewChild(TeamSearchComponent) teamSearchComponent: TeamSearchComponent;

  showCompletedConversations$ = this.store.select(selectShowCompletedConversations);

  excelDownloadFeatureFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.webExcelDownload));
  excelDownloadBatch2FeatureFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.webExcelDownloadBatch2));

  isEditingMy90Layout$ = this.store.select(GridLayoutSelectors.selectIsLayoutModeEnabled);
  quarterlyQuestionsEnabled = false;

  /**
   * Fix for scenario:
   *
   * When allTeamsOption was true, every call to setTeam was resetting the team filter to teamAll.  This is undesired
   * behavior because this flow happens everytime **_any_** option changes on the filter service, which resulted
   * in the team filter being reset to "all" everytime a filterbar option was toggled.
   *
   * This flag allows us to mark that we've already set setTeam to teamAll and to not reset it to all unless
   * this has been marked as false as well.
   */
  alreadySetTeamAll = false;

  readonly hasDataUploadPermission$ = this.store.select(selectHasHelpfulPermission('dataUpload')).pipe(
    combineLatestWith(
      this.store.select(selectCurrentUserIsAdminOrOwner),
      this.featureFlagFacade.getFlag(FeatureFlagKeys.clientFacingDataUpload)
    ),
    map(([hasDataUploadPermissions, isAdminOrOwner, flag]) => (flag && isAdminOrOwner) || hasDataUploadPermissions)
  );

  readonly isHelpful$ = this.store.select(selectHasAnyHelpfulPermission);
  readonly language$ = this.store.select(selectLanguage);

  ExcelExportType = ExcelExportType;

  constructor(
    public teamService: TeamService,
    public stateService: StateService,
    public filterService: FilterService,
    public rockStatusService: RockStatusService,
    public spinnerService: SpinnerService,
    public userService: UserService,
    public accountabilityChartService: AccountabilityChartService,
    public vtoService: VtoService,
    public helperService: HelperService,
    public gridLayoutActionsService: LegacyVTOGridLayoutActions,
    public feedbackService: FeedbackService,
    public legacyDialog: MatLegacyDialog,
    public detailService: DetailService<ConversationDetailInput>,
    public auxRouterService: AuxiliaryRouterOutletService,
    public activatedRoute: ActivatedRoute,
    private store: Store,
    private featureFlagFacade: FeatureFlagFacade,
    private sortByNamePipe: SortByNamePipe
  ) {}

  ngOnInit() {
    this.teams$ = this.teamService.userTeams$.pipe(
      filter(m => !!m),
      combineLatestWith(
        this.store.select(selectCurrentUserIsAdminOrOwner),
        this.store.select(selectCurrentUserIsManagerOrAbove),
        this.store.select(selectUserTeams)
      ),
      map(([teams, userIsAdminOrOwner, userIsManagerOrAbove, userTeams]) => {
        const userTeamIds = userTeams?.map(t => t?._id) ?? [];
        const teamAndCanEdit = teams.map(team => {
          const canEdit = userIsAdminOrOwner || (userIsManagerOrAbove && userTeamIds.includes(team._id));
          return { ...team, canEdit };
        });
        return this.sortByNamePipe.transform(teamAndCanEdit);
      })
    );

    this.filterTeams();

    this.subscriptions.add(
      this.featureFlagFacade
        .getFlag(FeatureFlagKeys.quarterlyQuestionSettings)
        .pipe(take(1))
        .subscribe(flag => {
          this.quarterlyQuestionsEnabled = flag;
        })
    );

    this.subscriptions.add(
      this.filterService.options$
        // if user has a default team id, we want to use that on login and then keep whatever team they have selected last
        .pipe(
          filter(
            (options, i) => options.allTeamsOption || !this.stateService.currentUser?.settings.defaultTeamId || i > 0
          )
        )
        .subscribe({ next: _ => this.setTeam(this.selectedTeam._id) })
    );

    this.subscriptions.add(
      this.filterService.selectedTeamId$.subscribe({
        next: teamId => {
          this.isSlt = teamId === this.stateService.company.seniorLeadershipTeamId;
          let team;
          if (teamId !== this.teamService.teamAll._id && teamId !== this.teamService.teamNone._id) {
            const teams = this.teamService.userTeams;
            team = teams.find(t => t._id === teamId);
            team = team ? team : teams[0];
          } else if (teamId === this.teamService.teamNone._id) {
            team = this.teamService.teamNone;
          } else {
            team = this.teamService.teamAll;
          }

          if (this.selectedTeam._id !== team._id) {
            this.selectedTeam = team;
            this.filterService.setTeam(team);
          }
        },
      })
    );

    let conversation: Conversation;

    this.subscriptions.add(
      this.detailService.conversationOpenFormalSettingsDialog$
        .pipe(
          tap(c => (conversation = c)),
          exhaustMap(conversation =>
            this.legacyDialog
              .open(FormalConversationSettingsComponent, {
                autoFocus: false,
                data: conversation ? { conversation, quarterlyQuestionsEnabled: this.quarterlyQuestionsEnabled } : null,
              })
              .afterClosed()
          ),
          filter(questions => !!questions),
          tap(questions => {
            if (conversation.type === 'quarterly') {
              conversation.quarterlyQuestions = questions;
            } else {
              conversation.meetingQuestions = questions;
            }
          }),
          mergeMap(questions => this.feedbackService.updateConversationQuestions(conversation._id, questions))
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setTeam(selectedTeamId: string) {
    const teams = this.teamService.userTeams;
    const options = this.filterService.options$.value;
    if (options.allTeamsOption) {
      // Only set teamAll the first time the option is set to true (until it has been set to false again)
      if (!this.alreadySetTeamAll) {
        this.filterService.setTeamId(this.teamService.teamAll._id);
        // Set flag to mark teamAll has already been set
        this.alreadySetTeamAll = true;
      }
    } else {
      // Reset flag when allTeamsOptions is turned off
      this.alreadySetTeamAll = false;

      if (selectedTeamId === this.teamService.teamAll._id) {
        const teamToSelect = this.getLastSelectedTeam();
        this.selectTeam(teamToSelect, false);
      } else {
        const team = teams.find(t => t._id === selectedTeamId);
        this.selectTeam(team ? team : teams[0]);
      }
    }
  }

  private getLastSelectedTeam() {
    const teams = this.teamService.userTeams;
    const user = this.stateService.currentCompanyUser$.value;
    const teamId = user ? user.lastAccessedTeamId : window.sessionStorage.getItem('lastAccessedTeamId') || null;
    const lastTeam = teamId ? teams.find(t => t._id === teamId) : null;
    const teamToSelect = lastTeam || teams[0];

    return teamToSelect;
  }

  // Buttons =================================

  parsePercent(number: number): string {
    return `${Math.round(number * 100)}%`;
  }

  selectTeam(team: Team = this.teamService.teamNone, setLastAccessed = true) {
    this.store.dispatch(FilterBarActions.selectTeam({ team }));
    if (
      setLastAccessed &&
      team._id !== 'all' &&
      team._id !== 'none' &&
      (this.selectedTeam._id !== team._id || this.selectedTeam._id !== team._id)
    ) {
      window.sessionStorage.setItem('lastAccessedTeamId', team._id);
      this.stateService.currentCompanyUser$.value.lastAccessedTeamId = team._id;
      this.userService.update({ lastAccessedTeamId: team._id }).subscribe();
    }
    if (this.selectedTeam._id !== team._id) {
      this.filterService.setTeamId(team._id);
    }
  }

  trackById = <T>(_i: number, item): string => item._id;

  teamsAreSame(option, value): boolean {
    return option._id === value._id;
  }

  onSearchChanged(term: string): void {
    this.searchTerm$.next(term);
    this.filterTeams();
  }

  filterTeams() {
    this.filteredTeams$ = this.searchTerm$.pipe(
      combineLatestWith(this.teams$, this.filterService.options$),
      map(([searchTerm, teams, { allTeamsOption }]) => {
        if (allTeamsOption) {
          const allTeams = { ...this.teamService.teamAll, canEdit: false };
          teams = [allTeams, ...teams];
        } else if (teams.length === 0) {
          // This may be completely unneeded.  Everyone should be on at least one team.
          const noneTeam = { ...this.teamService.teamNone, canEdit: false };
          teams = [noneTeam];
        }

        if (searchTerm.trim() === '') {
          return teams;
        }

        this.fuse = new Fuse(teams, this.fuseOptions);
        const results = this.fuse.search(searchTerm);
        const filteredTeams = results.map(result => result.item);

        const selectedTeamIsNotInResults = !filteredTeams.find(x => this.selectedTeam._id === x._id);
        if (selectedTeamIsNotInResults) {
          // Add selected team back to filtered results so that the mat select
          // does not blank out the currently selected item
          const selectedTeam = teams.find(x => this.selectedTeam._id === x._id);
          if (selectedTeam) {
            filteredTeams.push(selectedTeam);
          }
        }

        return filteredTeams;
      })
    );
  }

  onTeamDropdownOpened(event: boolean) {
    if (event) {
      this.teamSearchComponent.inputWrapper.focusInput();
    }
  }
}
