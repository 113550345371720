export class PhoneNumber {
  _id?: string;
  number: string;
  typeCode: string;

  constructor(number: string, typeCode: string) {
    this.number = number;
    this.typeCode = typeCode;
  }
}
