<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="envelope" />
      <span data-cy="cascading-message-language_card-title">{{ cascadingMessage.items }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="cascadingMessage">
      <mat-form-field appearance="outline">
        <mat-label>{{ cascadingMessage.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="cascadingMessage.item"
          data-cy="cascading-message-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="cascading-message-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ cascadingMessage.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="cascadingMessage.items"
          data-cy="cascading-message-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="cascading-message-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ cascadingMessage.action }} (action):</mat-label>
        <input
          #action
          matInput
          formControlName="action"
          required
          [attr.aria-label]="cascadingMessage.action"
          data-cy="cascading-message-language_action-input-field" />
        <ninety-reset-btn
          *ngIf="action.value !== resetLanguage?.action"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('action')"
          [disabled]="readonly"
          data-cy="cascading-message-language_action-reset-button">
        </ninety-reset-btn>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ cascadingMessage.actionPast }} (action - past tense):</mat-label>
        <input
          #actionPast
          matInput
          formControlName="actionPast"
          required
          [attr.aria-label]="cascadingMessage.actionPast"
          data-cy="cascading-message-language_action-past-input-field" />
        <ninety-reset-btn
          *ngIf="actionPast.value !== resetLanguage?.actionPast"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('actionPast')"
          [disabled]="readonly"
          data-cy="cascading-message-language_action-past-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
