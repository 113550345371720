import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionModule } from '../terra-description';
import { TerraErrorModule } from '../terra-error';
import { TerraFormLabelModule } from '../terra-form-label';

import { TerraFormGroupComponent } from './terra-form-group.component';

@NgModule({
  imports: [CommonModule, TerraFormGroupComponent, TerraFormLabelModule, TerraDescriptionModule, TerraErrorModule],
  exports: [TerraFormGroupComponent, TerraFormLabelModule, TerraErrorModule, TerraDescriptionModule],
})
export class TerraFormGroupModule {}
