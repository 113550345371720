import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, Renderer2, RendererFactory2 } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';

import { UserTutorialType } from '../../_shared/models/_shared/user-tutorial-type';
import { UserPreferencesActions } from '../../_state/app-entities/users/users-state.actions';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
  selector: 'vidyard-widget',
  standalone: true,
  imports: [TerraIconModule, ButtonComponent, MatMenuModule],
  template: `
    <div class="vidyard-video">
      <ninety-button class="more-actions no-hover" black icon filled [matMenuTriggerFor]="hideVideo">
        <terra-icon icon="more"
      /></ninety-button>

      <mat-menu #hideVideo="matMenu" class="template-options-menu">
        <button mat-menu-item (click)="updateUserTutorials()">Don't show this again</button>
      </mat-menu>
      <img
        [id]="videoId"
        style="width: 100%; margin: auto; display: block; max-width: 350px; max-height: 350px;"
        class="vidyard-player-embed"
        src="https://play.vidyard.com/{{ videoId }}.jpg"
        [attr.data-uuid]="videoId"
        data-v="4"
        [attr.data-type]="videoType" />
    </div>
  `,
  styles: [
    `
      .vidyard-video {
        padding-right: 32px;
        padding-left: 32px;
      }
      .more-actions {
        top: 40px;
        float: right;
        z-index: 1000;
        right: 4px;
        visibility: hidden;
        position: relative;
      }
      .vidyard-video:hover .more-actions {
        visibility: visible;
      }
    `,
  ],
})
export class VidyardComponent {
  @Input() videoId: string;
  @Input() videoType = 'inline';
  @Input() maxVideoWidth = 350;
  @Input() maxVideoHeight = 350;
  @Input() tutorialType: UserTutorialType;

  private renderer: Renderer2;
  constructor(private store: Store, @Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', 'https://play.vidyard.com/embed/v4.js');
    this.renderer.setAttribute(script, 'async', 'true');
    this.renderer.appendChild(this.document.body, script);
  }
  updateUserTutorials() {
    this.store.dispatch(UserPreferencesActions.hideUserTutorial({ userTutorialType: this.tutorialType }));
  }
}
