import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { teamsStateActions } from '../teams/teams-state.actions';

import { TeamListModel } from './api/team-list.model';
import { teamListStateActions } from './team-list-state.actions';

@Injectable()
export class TeamListStateEffects {
  constructor(private action$: Actions) {}

  // Temporary until we have a new Team List API
  $getTeamListSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(teamsStateActions.init),
      map(({ teams }) => {
        const teamList: TeamListModel[] = teams.map(t => ({
          _id: t._id,
          name: t.name,
          isProject: t.project,
          isPrivate: t.private,
          isLeadership: false, // ToDo: calculate
          isDeleted: t.deleted,
        }));
        return teamListStateActions.getTeamListSuccess({ teams: teamList });
      })
    )
  );
}
