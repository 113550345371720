import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { StateService } from '../../../_core/services/state.service';
import { TimeService } from '../../../_core/services/time.service';
import { TimeEntry } from '../../models/_shared/time-select';

@Component({
  selector: 'ninety-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
})
export class TimeSelectComponent implements OnInit {
  timeFormControl = new UntypedFormControl();
  selectedTime: TimeEntry;
  defaultSelectedTime: TimeEntry;
  @Output() selectTimeEvent = new EventEmitter<TimeEntry>();

  constructor(public stateService: StateService, public timeService: TimeService) {}

  ngOnInit() {
    this.defaultSelectedTime = this.timeService.timeList[0];
    this.selectedTime = this.defaultSelectedTime;
  }

  selectTime(time: TimeEntry) {
    if (time !== this.selectedTime) {
      this.selectTimeEvent.emit(time);
    }
    this.selectedTime = time;
  }
}
