<div class="mat-form-field-wrapper" [class.outline-mode]="outlineMode">
  <div class="mat-form-field-flex" [class.disabled]="disabled">
    <div class="mat-form-field-infix">
      <div class="button-text" fxLayout="row">
        <button
          id="trigger-menu-button"
          ninetyStopPropagation
          [class.outline-mode]="outlineMode"
          [class.error-bottom]="menuOpened && noSelectedTeams"
          [matMenuTriggerFor]="teamsMenu"
          (click)="setMenuOpened()"
          (menuClosed)="onClose()"
          [disabled]="disabled"
          matTooltipClass="ninety-tooltip"
          matTooltipPosition="left"
          [matTooltip]="selectedTeamsNames"
          [class.muted]="disabled"
          class="team-select-btn">
          <div class="button-text-wrapper">
            <div class="selected-teams" fxFlex="1 0 85%">
              <div *ngIf="!disabled">
                <div *ngIf="filteredSelectedTeams.length > 0; else noSharedTeams" class="team-info">
                  <div class="team-names">
                    {{ selectedTeamsNames }}
                  </div>
                  <div class="number-of-teams" *ngIf="filteredSelectedTeams.length > 1">
                    ({{ filteredSelectedTeams.length }})
                  </div>
                </div>
                <ng-template #noSharedTeams>
                  <div class="subfont-color" [class.red]="required && menuOpened">
                    <ng-container *ngIf="!userIsManager">{{ placeholder || 'Team' }}</ng-container>
                    <ng-container *ngIf="userIsManager">No shared teams</ng-container>
                    <span *ngIf="required"> *</span>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="disabled">{{ disabledText }}</div>
            </div>
            <terra-icon
              icon="caret-down"
              class="trigger-icon subfont-color"
              [class.red]="required && menuOpened && noSelectedTeams" />
          </div>
        </button>
      </div>
      <span class="mat-form-field-label-wrapper" *ngIf="!hideLabel">
        <label
          [class.small-label-wrapper]="!noSelectedTeams || (noSelectedTeams && menuOpen) || menuOpen"
          class="mat-form-field-label mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-should-float mat-form-field-label">
          <span class="small-label" [class.red]="required && menuOpened && noSelectedTeams"
            >Team
            <span
              id="asterisk"
              [class.red]="required && menuOpened && noSelectedTeams"
              [class.asterisk]="menuOpen && !noSelectedTeams"
              class="mat-placeholder-required mat-form-field-required-marker ng-tns-c17-16 ng-star-inserted"
              >*</span
            >
          </span>
        </label>
      </span>
      <mat-menu id="select-team-menu" #teamsMenu="matMenu" yPosition="below" overlapTrigger>
        <div class="search-wrapper" mat-menu-item ninetyStopPropagation>
          <mat-form-field floatLabel="never" class="search-inner-wrapper">
            <input matInput placeholder="Search" type="text" #searchText aria-label="teams search" />
          </mat-form-field>
        </div>
        <div class="team-choices-wrapper">
          <div
            class="team-choices-inner-wrapper"
            attr.data-cy="team-select_{{ team.name }}"
            (click)="selectTeam(team.id)"
            mat-menu-item
            *ngFor="let team of checkboxes | nameSearch : searchText.value; trackBy: trackById"
            ninetyStopPropagation
            fxLayout="row"
            [class.hidden]="!showTeamOptions.includes(team.id)">
            <terra-checkbox ninetyStopPropagation (change)="isSelected($event, team.id)" [ngModel]="team.checked">{{
              team.name
            }}</terra-checkbox>
          </div>
        </div>
        <div
          class="ok-item-wrapper"
          data-cy="team-select_ok_button"
          fxLayout="row"
          fxLayoutAlign="center center"
          mat-menu-item
          ninetyStopPropagation
          (click)="selectionChange.emit(selectedTeams); closeMenu()">
          <p class="ok-item-text">Ok</p>
        </div>
      </mat-menu>
    </div>
  </div>
  <mat-error *ngIf="!disabled && showRequiredError && !!requiredText.trim()" class="error">{{
    requiredText
  }}</mat-error>
</div>
