import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, take } from 'rxjs';

import { TerraIconModule, TerraSizing } from '@ninety/terra';

import { StopPropagationDirective } from '../../_shared/directives/stop-propagation.directive';
import { CustomLanguage } from '../../_shared/models/language/custom-language';
import { DisplayFullNamePipe } from '../../_shared/pipes/display-full-name.pipe';
import { UserInitialsPipe } from '../../_shared/pipes/user-initials.pipe';
import { FeatureFlagFacade } from '../../_state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '../../_state/app-entities/feature-flag/feature-flag-state.model';
@Component({
  selector: 'ninety-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserInitialsPipe, DisplayFullNamePipe],
  standalone: true,
  imports: [TerraIconModule, CommonModule, StopPropagationDirective, MatTooltipModule],
})
export class UserAvatarComponent implements OnInit, OnChanges {
  /**
   * @deprecated full name of the user. [fullUserName]="user?.metadata?.name | displayFullName"
   */
  @Input() fullUserName?: string;
  /**
   * url of the image you wish to use. [pictureURL]="user?.metadata?.picture?.url"
   */
  @Input() pictureURL?: string;
  /**
   * the user's initials. Usually done with userInitials pipe: [userInitials]="user | userInitials"
   */
  @Input() userInitials?: string;
  /**
   * Enable/Disable the hover state of the image
   */
  @Input() isHoverable = true;
  /**
   * tell the avatar to fade out a little (usually used if user is inactive)
   */
  @Input() transparent = false;
  /**
   * @deprecated align the avatar to the left
   */
  @Input() alignLeft?: boolean;
  /**
   * @deprecated show the remove button
   */
  @Input() showRemoveBtn?: boolean;
  /** Required when {@link showRemoveBtn} is set to true */
  @Input() language: CustomLanguage;
  /**
   * @deprecated tooltip to show instead of the user's full name
   */
  @Input() userToolTipOverride?: string;
  /**
   * @deprecated set to false if you wish to add your own event listeners
   */
  @Input() stopPropagation = true;
  /**
   * size of the avatar "xxl" = 118px "xml"=96px, "xl" = 64px, "large" = 36px, 'medium-large'= 32px "medium" = 24px, "small" = 16px
   */
  @Input() size: 'xxl' | 'xml' | 'xl' | 'large' | 'medium-large' | 'medium' | 'small' = 'large';
  /**
   * @deprecated remove button callback function
   */
  @Output() removeUser = new EventEmitter();

  removeBtnDisplay: 'none' | 'inherit' = 'none';
  ICON_SIZES: Record<string, TerraSizing> = {
    xl: 40,
    xml: 40,
    large: 24,
    medium: 20,
    small: 12,
    xxl: 48,
    'medium-large': 24,
  };

  contactCardEnabled$: Observable<boolean>;

  constructor(private featureFlagFacade: FeatureFlagFacade) {
    this.contactCardEnabled$ = this.featureFlagFacade.getFlag(FeatureFlagKeys.enableContactCard);
  }

  ngOnInit() {
    this.setContactCardFeatureFlag();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.size === 'small' && this.userInitials) {
      this.userInitials = this.userInitials.substring(0, 1);
    }
  }

  private setContactCardFeatureFlag() {
    this.contactCardEnabled$.pipe(take(1)).subscribe(isEnabled => {
      if (isEnabled) {
        this.fullUserName = null;
      }
    });
  }
}
