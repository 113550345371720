import { Breakpoints, BreakpointState } from '@angular/cdk/layout';

/**
 * Refers to human-readable viewport breakpoint names defined
 * as supported by the Ninety design system.
 *
 * These names currently correspond to the predefined
 * breakpoint names drawn from the Material Design specification
 *
 * @see ["Material Predefined Breakpoints"](https://material.angular.io/cdk/layout/overview#predefined-breakpoints)
 *
 */
export enum NinetyBreakpointNames {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
  HandsetPortrait = 'HandsetPortrait',
  TabletPortrait = 'TabletPortrait',
  HandsetLandscape = 'HandsetLandscape',
  TabletLandscape = 'TabletLandscape',
}

export interface ViewportState extends BreakpointState {
  currentScreenSize: NinetyBreakpointNames | null;
}

export const initialViewportState: ViewportState = {
  matches: false,
  breakpoints: {},
  currentScreenSize: null,
};

/**
 * Constant that defines the breakpoint-name-to-media-query mapping
 *
 * The breakpoint names and values correspond to the predefined
 * breakpoint names and media queries drawn from the Material Design specification
 *
 * @see ["Material Predefined Breakpoints"](https://material.angular.io/cdk/layout/overview#predefined-breakpoints)
 *
 * The order of properties are important. When resolving the
 * current matching breakpoint and currentScreenSize,
 * the resolver, in reverse property order, returns the first
 * breakpoint where there is a match.
 *
 * @see `ViewportStateEffects`.
 *
 */
export const NinetyBreakpoints: Record<NinetyBreakpointNames, string> = {
  [NinetyBreakpointNames.Small]: Breakpoints.Small,
  [NinetyBreakpointNames.Medium]: Breakpoints.Medium,
  [NinetyBreakpointNames.Large]: Breakpoints.Large,
  [NinetyBreakpointNames.XLarge]: Breakpoints.XLarge,
  [NinetyBreakpointNames.HandsetPortrait]: Breakpoints.HandsetPortrait,
  [NinetyBreakpointNames.TabletPortrait]: Breakpoints.TabletPortrait,
  [NinetyBreakpointNames.HandsetLandscape]: Breakpoints.HandsetLandscape,
  [NinetyBreakpointNames.TabletLandscape]: Breakpoints.TabletLandscape,
};
