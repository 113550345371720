<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="directory" />
      <span data-cy="directory-language_card-title">{{ directory.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="directory">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="directory.route + ' Page Name'"
          data-cy="directory-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="directory-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ directory.owner }}:</mat-label>
        <input
          #owner
          matInput
          formControlName="owner"
          required
          [attr.aria-label]="directory.owner"
          data-cy="directory-language_owner-input-field" />
        <ninety-reset-btn
          *ngIf="owner.value !== resetLanguage?.owner"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('owner')"
          [disabled]="readonly"
          data-cy="directory-language_owner-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ directory.admin }}:</mat-label>
        <input
          #admin
          matInput
          formControlName="admin"
          required
          [attr.aria-label]="directory.admin"
          data-cy="directory-language_admin-input-field" />
        <ninety-reset-btn
          *ngIf="admin.value !== resetLanguage?.admin"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('admin')"
          [disabled]="readonly"
          data-cy="directory-language_admin-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ directory.manager }}:</mat-label>
        <input
          #manager
          matInput
          formControlName="manager"
          required
          [attr.aria-label]="directory.manager"
          data-cy="directory-language_manager-input-field" />
        <ninety-reset-btn
          *ngIf="manager.value !== resetLanguage?.manager"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('manager')"
          [disabled]="readonly"
          data-cy="directory-language_manager-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ directory.managee }}:</mat-label>
        <input
          #managee
          matInput
          formControlName="managee"
          required
          [attr.aria-label]="directory.managee"
          data-cy="directory-language_team-member-input-field" />
        <ninety-reset-btn
          *ngIf="managee.value !== resetLanguage?.managee"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('managee')"
          [disabled]="readonly"
          data-cy="directory-language_team-member-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ directory.observer }}:</mat-label>
        <input
          #observer
          matInput
          formControlName="observer"
          required
          [attr.aria-label]="directory.observer"
          data-cy="directory-language_observer-input-field" />
        <ninety-reset-btn
          *ngIf="observer.value !== resetLanguage?.observer"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('observer')"
          [disabled]="readonly"
          data-cy="directory-language_observer-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ directory.implementer }}:</mat-label>
        <input
          #implementer
          matInput
          formControlName="implementer"
          required
          [attr.aria-label]="directory.implementer"
          data-cy="directory-language_coach-input-field" />
        <ninety-reset-btn
          *ngIf="implementer.value !== resetLanguage?.implementer"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('implementer')"
          [disabled]="readonly"
          data-cy="directory-language_coach-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
