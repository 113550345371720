import { SortDirection } from '../enums/sort-direction';
import { Sorted } from '../enums/sorted';
import { IssueSortFieldEnum } from '../issues/issues-sort-field';

export class SortingTracker {
  [key: string]: Sorted;

  constructor(...keys: string[]) {
    keys.forEach(key => (this[key] = Sorted.false));
  }
}

// ========================================================
// V2

export interface FieldSort<K extends string> {
  field: K | null;
  sorted: Sorted;
  sortDirection: SortDirection;
}

type SortFieldRecord<K extends string> = Partial<Record<K, Sorted>>;

export class SortingTrackerV2<K extends string> {
  private fields: SortFieldRecord<K>;

  constructor(...keys: K[]) {
    this.fields = keys.reduce((_fields, key) => {
      _fields[key] = Sorted.false;

      return _fields;
    }, {} as SortFieldRecord<K>);

    return this;
  }

  has(field: K): boolean {
    return this.fields.hasOwnProperty(field);
  }

  reset() {
    Object.keys(this.fields).forEach(field => (this.fields[field] = Sorted.false));

    return this;
  }

  isSorted(field: K): boolean {
    if (!this.has(field)) return false;

    return this.fields[field] !== Sorted.false;
  }

  setSort(field: K, sorted: Sorted) {
    this.reset();

    this.fields[field] = sorted;

    return this;
  }

  setSortByDirection(field: K, direction: SortDirection) {
    this.reset();

    switch (direction) {
      case SortDirection.ASC:
        this.fields[field] = Sorted.ascending;
        break;
      case SortDirection.DESC:
        this.fields[field] = Sorted.descending;
        break;
      default:
        this.fields[field] = Sorted.false;
        break;
    }

    return this;
  }

  previousSort(field: K): FieldSort<K> {
    if (!this.has(field)) return;

    const currentSort = this.fields[field];

    this.reset();

    switch (currentSort) {
      case Sorted.false:
        this.fields[field] = Sorted.descending;
        break;
      case Sorted.ascending:
        this.fields[field] = Sorted.false;
        break;
      case Sorted.descending:
        this.fields[field] = Sorted.ascending;
        break;
    }

    return this.getFieldSort(field);
  }

  nextSort(field: K): FieldSort<K> {
    const currentSort = this.fields[field] ?? Sorted.false;

    this.reset();

    if (field !== IssueSortFieldEnum.numOfLikes) {
      switch (currentSort) {
        case Sorted.false:
          this.fields[field] = Sorted.ascending;
          break;
        case Sorted.ascending:
          this.fields[field] = Sorted.descending;
          break;
        case Sorted.descending:
          this.fields[field] = Sorted.false;
          break;
      }
    } else {
      switch (currentSort) {
        case Sorted.false:
          this.fields[field] = Sorted.descending;
          break;
        case Sorted.descending:
          this.fields[field] = Sorted.ascending;
          break;
        case Sorted.ascending:
          this.fields[field] = Sorted.false;
          break;
      }
    }

    return this.getFieldSort(field);
  }

  getFieldSort(field: K): FieldSort<K> {
    const sorted = this.getCurrentSort(field);

    return {
      field: field ?? null,
      sorted,
      sortDirection: this.getCurrentSortDirection(field),
    };
  }

  getCurrentSort(field: K): Sorted {
    return this.fields[field] ?? Sorted.false;
  }

  getCurrentSortDirection(field: K): SortDirection {
    if (!this.has(field)) return null;

    switch (this.getCurrentSort(field)) {
      case Sorted.false:
        return null;
      case Sorted.ascending:
        return SortDirection.ASC;
      case Sorted.descending:
        return SortDirection.DESC;
    }
  }

  isAscending(field: K): boolean {
    return this.getCurrentSort(field) === Sorted.ascending;
  }

  isDescending(field: K): boolean {
    return this.getCurrentSort(field) === Sorted.descending;
  }
}
