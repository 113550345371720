import { Component, Input } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../_components/buttons/button/button.component';
import { NinetyTooltipDirective } from '../../../../_directives/ninety-tooltip.directive';

@Component({
  selector: 'ninety-reset-btn',
  template: ` <ninety-button flat icon [disabled]="disabled" ninetyTooltip="Reset to Default">
    <terra-icon icon="reset" />
  </ninety-button>`,
  standalone: true,
  imports: [NinetyTooltipDirective, ButtonComponent, TerraIconModule],
  styles: [
    `
      :host {
        display: inline;
      }
    `,
  ],
})
export class ResetBtnComponent {
  @Input() disabled: boolean;
}
