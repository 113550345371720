import ObjectID from 'bson-objectid';

export class UserTeam {
  teamId: ObjectID;
  ordinal: number;

  constructor(teamId: string) {
    this.teamId = new ObjectID(teamId);
  }
}
