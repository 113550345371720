import { createReducer, on } from '@ngrx/store';

import { teamsStateActions } from './teams-state.actions';
import { teamsInitialState, teamsStateAdapter } from './teams-state.model';

export const TeamsStateReducer = createReducer(
  teamsInitialState,
  on(teamsStateActions.getAllSuccess, teamsStateActions.setAll, teamsStateActions.init, (state, { teams }) =>
    teamsStateAdapter.setAll(teams, { ...state })
  ),
  on(teamsStateActions.addOne, (state, { team }) => teamsStateAdapter.addOne(team, { ...state })),
  on(teamsStateActions.removeOne, (state, { _id }) => teamsStateAdapter.removeOne(_id, { ...state })),
  on(teamsStateActions.updateOne, (state, { _id: id, changes }) =>
    teamsStateAdapter.updateOne({ id, changes }, { ...state })
  ),
  on(teamsStateActions.updateMany, (state, { updates }) => teamsStateAdapter.updateMany(updates, state)),
  on(teamsStateActions.updateCustomAgendas, (state, { teamId, customAgendas }) => {
    let team = state.entities[teamId];
    team = { ...team, settings: { ...team.settings, custom: customAgendas } };
    return teamsStateAdapter.updateOne({ id: teamId, changes: team }, state);
  }),
  on(teamsStateActions.updateAgenda, (state, { teamId, agendaType, agenda }) => {
    let team = state.entities[teamId];
    team = { ...team, settings: { ...team.settings, [agendaType]: agenda } };
    return teamsStateAdapter.updateOne({ id: teamId, changes: team }, state);
  }),
  on(teamsStateActions.deleteCustomAgenda, (state, { teamId, agendaId }) => {
    let team = state.entities[teamId];
    team = { ...team, settings: { ...team.settings, custom: team.settings.custom.filter(a => a._id !== agendaId) } };
    return teamsStateAdapter.updateOne({ id: teamId, changes: team }, state);
  })
);
