import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ResponsibilitiesChartFilterActions = createActionGroup({
  source: 'Responsibilities - Filter',
  events: {
    /** User is requesting the top seat set to a specific seat. */
    'Set Top Seat': props<{ seatId: string }>(),
    /** Updates state to reflect the top seat has been set.  */
    'Update Top Seat in State': props<{ seatId: string }>(),

    'Reset Top Seat': emptyProps(),

    'Set Zoom Scale': props<{ zoomScale: number }>(),
    'Reset Zoom Scale': emptyProps(),
    'Zoom In': emptyProps(),
    'Zoom Out': emptyProps(),

    'Expand All': emptyProps(),
    'Collapse All': emptyProps(),

    Refresh: emptyProps(),
  },
});
