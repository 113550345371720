import { initialTodoStateBase, TodoStateBase } from '@ninety/todos/_state/_shared/todo-state.shared.model';

export interface TeamTodoState extends TodoStateBase {
  teamId: string;
  listControlsDisabled: boolean;
  focusOnInlineAddTodo: boolean;
  userIdFilter: string;
  //show user ab pending todos, when null it will be removed from query params
  userPendingAgreements: null | true;
}

export const initialTeamTodoState: TeamTodoState = {
  teamId: null,
  listControlsDisabled: false,
  focusOnInlineAddTodo: false,
  userIdFilter: null,
  userPendingAgreements: null,
  ...initialTodoStateBase,
};
