/* eslint-disable @ngrx/no-store-subscription */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, tap } from 'rxjs';

import { SpinnerActions } from '../../_state/app-global/spinner/spinner-state.actions';
import * as spinnerSelectors from '../../_state/app-global/spinner/spinner-state.selectors';

export const source = { source: 'SpinnerService' };

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  // These properties are for backwards compatibility
  // Clients should refactor to selecting from the store directly
  // ie. this.store.select(spinnerSelectors.selectPrimary)
  /**
   * @deprecated - use observable or store
   */
  primary = false;

  /**
   * @deprecated - use observable or store
   */
  auxiliary = false;
  primary$ = this.store.select(spinnerSelectors.selectPrimary);
  auxiliary$ = this.store.select(spinnerSelectors.selectAuxiliary);

  constructor(private store: Store) {
    // Subscribe to the store to maintain backward compatibility to the non observable properties
    this.store
      .select(spinnerSelectors.selectPrimary)
      .subscribe(primaryState => setTimeout(() => (this.primary = primaryState)));
    this.store
      .select(spinnerSelectors.selectAuxiliary)
      .subscribe(auxiliaryState => setTimeout(() => (this.auxiliary = auxiliaryState)));
  }

  start(primary = true) {
    primary
      ? this.store.dispatch(SpinnerActions.startPrimary(source))
      : this.store.dispatch(SpinnerActions.startAuxiliary(source));
  }

  stop(primary = true) {
    primary
      ? this.store.dispatch(SpinnerActions.stopPrimary(source))
      : this.store.dispatch(SpinnerActions.stopAuxiliary(source));
  }

  startAuxiliary() {
    this.store.dispatch(SpinnerActions.startAuxiliary(source));
  }

  stopAuxiliary() {
    this.store.dispatch(SpinnerActions.stopAuxiliary(source));
  }

  // Observable wrapper
  spinWhile<T>(obs: Observable<any>): Observable<T> {
    // Schedule for next MacroTask (prevents ExpressChangedAfterCheck error)
    setTimeout(() => {
      this.store.dispatch(SpinnerActions.startPrimary(source));
    }, 0);

    return obs.pipe(
      tap(() => setTimeout(() => this.store.dispatch(SpinnerActions.stopPrimary(source)))),
      catchError((err: unknown) => {
        this.stop();
        throw err;
      })
    );
  }
}
