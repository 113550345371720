import { createActionGroup, props } from '@ngrx/store';

export const dataLayerActions = createActionGroup({
  source: 'Data Layer',
  events: {
    'Track Click': props<{ event: MouseEvent; clickUrl: string }>(),
    'Create Company': props<{ event: SubmitEvent }>(),
    'Track Form Submit': props<{ event: SubmitEvent }>(),
  },
});
