/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { catchError, filter, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { TodoService } from '@ninety/todos/_shared/todo.service';
import {
  TeamTodoActions,
  TeamTodoInlineActions,
  TeamTodoPubnubActions,
} from '@ninety/todos/_state/team/team-todo.actions';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Sorted } from '@ninety/ui/legacy/shared/models/enums/sorted';
import { TodoMessageType } from '@ninety/ui/legacy/shared/models/todos/todo-message-types';
import * as currentUserSelectors from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

import { selectTeamTodoState } from '..';
import { MeetingsStateSelectors } from '../../../pages/meetings/_state/meetings.selectors';
import { TeamTodoSelectors } from '../team/team-todo.selectors';

@Injectable()
export class PubnubTodoEffects {
  constructor(
    private actions$: Actions,
    private todoService: TodoService,
    private stateService: StateService,
    private store: Store,
    private meetingService: MeetingService
  ) {}

  /** Broadcasts a message that todos were added when the local user adds todos via universal create */
  broadcastAddMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.addManySuccess),
      concatLatestFrom(() => this.store.select(TeamTodoSelectors.selectBroadcastTeamEvents)),
      filter(([, shouldBroadcast]) => !!shouldBroadcast),
      switchMap(([{ response }]) =>
        this.todoService
          .broadcastMessage({
            messageType: TodoMessageType.new,
            document: response.todos,
          })
          .pipe(
            map(() => TeamTodoPubnubActions.broadcastAddManySuccess()),
            catchError((error: unknown) => of(TeamTodoPubnubActions.broadcastAddManyFailure({ error })))
          )
      )
    )
  );

  /** Broadcasts a message that todos were added when the local user adds todos via inline create */
  broadcastAddOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoInlineActions.createOneSuccess),
      concatLatestFrom(() => this.store.select(selectTeamTodoState)),
      switchMap(([{ response }]) =>
        this.todoService
          .broadcastMessage({
            messageType: TodoMessageType.new,
            document: [response.todos[0]],
          })
          .pipe(
            map(() => TeamTodoPubnubActions.broadcastAddOneSuccess()),
            catchError((error: unknown) => of(TeamTodoPubnubActions.broadcastAddOneFailure({ error })))
          )
      )
    )
  );

  /** Sends ordinal updates to meeting attendees */
  broadcastMoveItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateOrdinals),
      concatLatestFrom(() => [
        this.store.select(TeamTodoSelectors.selectBroadcastTeamEvents),
        this.store.select(TeamTodoSelectors.selectTeamId),
      ]),
      filter(([, broadcast]) => !!broadcast),
      switchMap(([action, , teamId]) =>
        this.todoService
          .broadcastMessage({
            messageType: TodoMessageType.drop,
            document: {
              listType: 'todo',
              event: { previousIndex: action.previousIndex, currentIndex: action.currentIndex } as CdkDragDrop<any>,
              currentTeamId: teamId,
            },
          })
          .pipe(
            map(() => TeamTodoPubnubActions.updateOrdinalsFromBroadcastSuccess()),
            catchError((error: unknown) => of(TeamTodoPubnubActions.updateOrdinalsFromBroadcastFailure({ error })))
          )
      )
    )
  );

  /** Broadcast updates to meeting attendees as a side effect of a successful update */
  broadcastUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateSuccess, TeamTodoActions.updateInlineSuccess, TeamTodoActions.updateDueDateSuccess),
      concatLatestFrom(() => this.store.select(TeamTodoSelectors.selectBroadcastTeamEvents)),
      //only apply this affect when an update is explicitly marked for broadcasting
      filter(([_, broadcast]) => !!broadcast),
      switchMap(([action]) => {
        const todo = cloneDeep(action.todo);

        //Update the meeting attendees local meeting
        this.todoService.updatedTodo$.next({ todo: todo, fromBroadcast: false });
        //TODO: this probably isn't needed anymore
        todo.dueDate = moment(todo.dueDate).endOf('day').format();

        return this.todoService
          .broadcastMessage({
            messageType: TodoMessageType.todo,
            document: todo,
          })
          .pipe(
            map(() => TeamTodoPubnubActions.updateFromBroadcastSuccess({ todo })),
            catchError((error: unknown) => of(TeamTodoPubnubActions.updateFromBroadcastFailure({ error })))
          );
      })
    )
  );

  /** Broadcasts sort changes to other meeting attendees */
  broadcastSort$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.sortBy),
        concatLatestFrom(() => [
          this.store.select(TeamTodoSelectors.selectTeamSort),
          this.store.select(TeamTodoSelectors.selectTeamId),
          this.store.select(currentUserSelectors.selectCurrentUser),
          this.store.select(MeetingsStateSelectors.selectCurrentMeeting),
        ]),
        filter(
          ([action, , , user, meeting]) =>
            !!action.broadcast && !this.stateService.isObserver && meeting?.presenterUserId === user._id
        ),
        switchMap(([, sort, selectedTeamId]) => {
          let order = Sorted.false;
          if (sort.sortField) {
            order = sort.sortDirection ? Sorted.ascending : Sorted.descending;
          }

          return this.todoService.broadcastMessage({
            messageType: TodoMessageType.sort,
            document: {
              listType: 'todo',
              field: sort.sortField,
              currentTeamId: selectedTeamId,
              sortDirection: sort.sortDirection,
              order: order,
            },
          });
        })
      ),
    { dispatch: false }
  );

  /** Broadcasts the deleted item to meeting attendees */
  broadcastDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.deleteSuccess),
      concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectBroadcastTeamEvents)]),
      filter(([, broadcast]) => !!broadcast),
      switchMap(([{ id }]) =>
        this.todoService
          .broadcastMessage({
            messageType: TodoMessageType.delete,
            document: id,
          })
          .pipe(
            map(() => TeamTodoPubnubActions.broadcastDeleteSuccess()),
            catchError(() => of(TeamTodoPubnubActions.broadcastDeleteFailure()))
          )
      )
    )
  );

  broadcastDeleteSeries$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.deleteSeriesSuccess),
        concatLatestFrom(() => this.store.select(TeamTodoSelectors.selectBroadcastTeamEvents)),
        filter(([_, broadcast]) => !!broadcast),
        tap(([action]) => {
          const todo = cloneDeep(action.todo);

          this.todoService
            .broadcastMessage({
              messageType: TodoMessageType.deleteSeries,
              document: {
                _id: todo._id,
                seriesId: todo.seriesId,
              },
            })
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  toggleArchived$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.toggleArchivedSuccess),
        concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectBroadcastTeamEvents)]),
        filter(([, broadcast]) => !!broadcast),
        mergeMap(([action]) => {
          const messageType = !!action.todo.archived ? TodoMessageType.todo : TodoMessageType.unarchive;
          return this.todoService.broadcastMessage({ messageType, document: action.todo });
        })
      ),
    { dispatch: false }
  );

  /**
   * All attachment Pubnub broadcasts are handled by this single effect that refresh the to-do via API,
   * if the to-do is currently in state
   * */
  attachmentEventReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoPubnubActions.attachmentEventReceived),
      concatLatestFrom(() => this.store.select(TeamTodoSelectors.selectTeamTodos)),
      filter(([{ id }, todos]) => todos.find(t => t._id === id) !== null),
      switchMap(([{ id }]) =>
        this.todoService.getTodoById(id).pipe(
          map(todo => TeamTodoPubnubActions.attachmentEventSuccess({ todo })),
          catchError(() => of(TeamTodoPubnubActions.attachmentEventFailure()))
        )
      )
    )
  );
}
