import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SkeletonComponent } from '../skeleton.component';
import { SkeletonPropsArray } from '../types';

/**
 * Utility component useful for rendering a list of skeletons with **different** themes.
 *
 * If you need a **list of skeletons with the same theme**, use the `SkeletonComponent` directly with a non-one count
 * param.
 */
@Component({
  selector: 'ninety-skeleton-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ninety-skeleton
      *ngFor="let prop of skeletonProps"
      [theme]="prop.theme"
      [count]="prop.count"
      [type]="prop.type"
      [animation]="prop.animation"></ninety-skeleton>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        gap: inherit;
        width: 100%;
        height: 100%;

        ninety-skeleton {
          gap: inherit;
        }

        &.row {
          flex-direction: row;
          ninety-skeleton {
            flex-direction: row;
          }
        }

        &.space-between {
          justify-content: space-between;

          ninety-skeleton {
            flex: 0;
          }
        }
      }
    `,
  ],
  imports: [SkeletonComponent, NgForOf],
})
export class SkeletonListComponent {
  /** A list of SkeletonProps */
  @Input() skeletonProps: SkeletonPropsArray;
}
