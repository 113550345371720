<div class="container">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Personal Information</mat-card-title>
    </mat-card-header>

    <mat-card-content data-cy="personal_profile-container" class="personal-card">
      <ninety-avatar-uploader [metadata]="metadata" [userInfo]="userInfo"></ninety-avatar-uploader>

      <div class="personal-info">
        <div class="fields-dev">
          <div class="field-wrapper">
            <mat-label class="muted">First Name</mat-label>
            <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
              <input
                data-cy="personal_first-name"
                matInput
                [(ngModel)]="metadata.name.first"
                (ngModelChange)="debounceUpdate()"
                placeholder="First Name" />
            </mat-form-field>
          </div>
          <div class="field-wrapper">
            <mat-label class="muted">Last Name</mat-label>
            <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
              <input
                data-cy="personal_last-name"
                matInput
                [(ngModel)]="metadata.name.last"
                (ngModelChange)="debounceUpdate()"
                placeholder="Last Name" />
            </mat-form-field>
          </div>
        </div>
        <div class="fields-dev">
          <div class="field-wrapper">
            <mat-label class="muted">Pronouns (Optional)</mat-label>
            <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
              <input
                data-cy="personal_pronouns_input-field"
                matInput
                [(ngModel)]="metadata.preferredPronouns"
                (ngModelChange)="debounceUpdate()"
                placeholder="Enter your pronouns"
                aria-label="Pronouns (Optional)" />
            </mat-form-field>
          </div>
          <div class="field-wrapper">
            <mat-label class="muted">Date of Birth (Optional)</mat-label>
            <mat-form-field class="outline-field" appearance="outline">
              <input
                data-cy="personal_date-of-birth"
                matInput
                [(ngModel)]="metadata.dateOfBirth"
                (click)="dateOfBirth.open()"
                (ngModelChange)="updateMetadata.emit(this.metadata)"
                [matDatepicker]="dateOfBirth"
                placeholder="Select your date of birth"
                aria-label="Date of Birth (Optional)" />
              <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
              <mat-datepicker #dateOfBirth></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="fields-dev">
          <div class="field-wrapper">
            <mat-label class="muted">Title</mat-label>
            <mat-form-field class="outline-field" appearance="outline">
              <input
                matInput
                maxlength="100"
                [(ngModel)]="userInfo.title"
                (ngModelChange)="updateUser.emit({ title: $event })"
                placeholder="Enter a title..."
                aria-label="Title" />
            </mat-form-field>
            <mat-hint>Your title will only be visible to {{ companyName$ | async }}.</mat-hint>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>
        Biography
        <small class="muted">(For this company only)</small>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ninety-text-editor
        placeholder="Bio..."
        data-cy="personal_biography_container"
        [(text)]="userInfo.bio"
        (textChange)="updateUser.emit({ bio: $event })">
      </ninety-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Address</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <mat-label class="muted">Street</mat-label>
        <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
          <input
            data-cy="personal_address-street_input-field"
            matInput
            [(ngModel)]="metadata.streetAddress"
            (ngModelChange)="debounceUpdate()"
            placeholder="Street" />
        </mat-form-field>
      </div>
      <div class="fields-dev">
        <div class="field-wrapper">
          <mat-label class="muted">City</mat-label>
          <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
            <input
              data-cy="personal_address-city_input-field"
              matInput
              type="email"
              [(ngModel)]="metadata.city"
              (ngModelChange)="debounceUpdate()"
              placeholder="City" />
          </mat-form-field>
        </div>
        <div class="field-wrapper">
          <mat-label class="muted">{{ userCountry?.province_label || 'Region' }}</mat-label>
          <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
            <mat-select
              data-cy="personal_address-region-dropdown-button"
              [(ngModel)]="metadata.province"
              [compareWith]="compareRegions"
              (selectionChange)="updateMetadata.emit(this.metadata)">
              <mat-option
                attr.data-cy="personal_address-region-{{ region.key }}"
                *ngFor="let region of userCountry?.province_codes | keyvalue"
                [value]="{ name: region.key, code: region.value }">
                {{ region.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="fields-dev">
        <div class="field-wrapper">
          <mat-label class="muted">{{ userCountry?.zip_label || 'Postal Code' }}</mat-label>
          <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
            <input
              data-cy="personal_address-postal-code_input-field"
              matInput
              [(ngModel)]="metadata.postalCode"
              (ngModelChange)="debounceUpdate()"
              placeholder="Postal Code" />
          </mat-form-field>
        </div>
        <div class="field-wrapper">
          <mat-label class="muted">Country</mat-label>
          <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
            <mat-select
              data-cy="personal_address-country-dropdown-button"
              [ngModel]="metadata.country"
              [compareWith]="compareCountries"
              (selectionChange)="selectCountry($event.value)">
              <mat-option
                attr.data-cy="personal_address-country-{{ country.name }}"
                *ngFor="let country of Countries"
                [value]="country"
                >{{ country.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Emergency Contact(s)</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-list class="contact-list">
        <mat-list-item
          *ngFor="let contact of metadata.emergencyContacts; let i = index; let first = first; trackBy: trackByIndex"
          [class.first]="first">
          <div class="field-wrapper flex2">
            <mat-label class="muted" *ngIf="first">Name</mat-label>
            <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
              <input
                data-cy="personal_emergency-contacts-name_input-field"
                matInput
                [(ngModel)]="contact.name"
                (ngModelChange)="debounceUpdate()"
                placeholder="Name" />
            </mat-form-field>
          </div>
          <div class="field-wrapper">
            <mat-label class="muted" *ngIf="first">Relationship</mat-label>
            <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
              <input
                data-cy="personal_emergency-contacts-relationship_input-field"
                matInput
                [(ngModel)]="contact.relationship"
                (ngModelChange)="debounceUpdate()"
                placeholder="Relationship" />
            </mat-form-field>
          </div>
          <div class="field-wrapper">
            <mat-label class="muted" *ngIf="first">Phone Number</mat-label>
            <mat-form-field class="outline-field" ninetyStopRipple appearance="outline">
              <input
                data-cy="personal_emergency-contacts-phone-number_input-field"
                matInput
                [(ngModel)]="contact.phoneNumber.number"
                (ngModelChange)="debounceUpdate()"
                placeholder="Phone Number" />
            </mat-form-field>
          </div>
          <ninety-button
            flat
            negative
            data-cy="personal_emergency-contacts_delete-contact-button"
            (click)="deleteEmergencyContact(i)"
            class="delete-btn">
            <terra-icon icon="delete" />
          </ninety-button>
        </mat-list-item>
      </mat-list>
      <ninety-button
        flat
        primary
        data-cy="personal_emergency-contacts_add-contact-button"
        mat-button
        color="accent"
        (click)="addEmergencyContact()">
        <terra-icon icon="add-circle" />
        Add Contact
      </ninety-button>
    </mat-card-content>
  </mat-card>
</div>
