import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subscription, tap } from 'rxjs';

import { ImageDialogComponent } from '@ninety/ui/legacy/shared/components/image-dialog/image-dialog.component';
import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationType } from '@ninety/ui/legacy/shared/models/feedback/conversation-type.enum';
import {
  DefaultConversationQuestion,
  FormalConversationQuestion,
  getDefaultConversationQuestions,
  getDefaultQuarterlyQuestions,
  isConversationQuestion,
} from '@ninety/ui/legacy/shared/models/feedback/formal-conversation-question';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { ConversationSettingsApiService } from '../_api/conversation-settings-api.service';

import { FormalConversationSettingsStore } from './_state/formal-conversation-settings-state.store';

@Component({
  selector: 'ninety-formal-conversation-settings',
  templateUrl: './formal-conversation-settings.component.html',
  providers: [FormalConversationSettingsStore, ConversationSettingsApiService],
  styleUrls: ['./formal-conversation-settings.component.scss'],
})
export class FormalConversationSettingsComponent implements OnInit, OnDestroy {
  formalQuestions: FormalConversationQuestion[];
  title: string;
  somethingHasChanged = false;
  questionsHaveAnswers: boolean;
  questionsAreSame: boolean;
  questionsWereSameAtStart: boolean;
  originalQuestions: FormalConversationQuestion[];
  currentCompanyQuestions: FormalConversationQuestion[];
  canReset: boolean;
  defaultQuestions: FormalConversationQuestion[];
  isQuarterly: boolean = this.data.conversation.type === ConversationType.quarterly;
  annualQuestions$: Observable<DefaultConversationQuestion[]> = this.formalConversationStore.annualQuestions$;
  quarterlyQuestions$: Observable<DefaultConversationQuestion[]> = this.formalConversationStore.quarterlyQuestions$;
  language$: Observable<CustomLanguage> = this.store.select(selectLanguage);

  private subscriptions: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<FormalConversationSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { conversation: Conversation; quarterlyQuestionsEnabled: boolean },
    public formalConversationStore: FormalConversationSettingsStore,
    public store: Store,
    public dialog: MatDialog
  ) {
    this.formalConversationStore.getSettings();
    this.subscriptions.add(
      combineLatest([this.annualQuestions$, this.quarterlyQuestions$, this.language$])
        .pipe(
          tap(([annualQuestions, quarterlyQuestions, language]) => {
            if (this.isQuarterly && this.data.quarterlyQuestionsEnabled) {
              this.title = language.feedback.quarterlyItem;
              this.defaultQuestions = quarterlyQuestions;
            } else {
              this.title = language.feedback.annualItem;
              this.defaultQuestions = annualQuestions;
            }
          })
        )
        .subscribe()
    );

    this.currentCompanyQuestions = this.defaultQuestions.map(q => new FormalConversationQuestion(q.question, q.title));

    if (this.isQuarterly && this.data.quarterlyQuestionsEnabled) {
      this.questionsHaveAnswers =
        data && data.conversation.quarterlyQuestions.some(q => !!q.manageeAnswer.length || !!q.managerAnswer.length);
      this.originalQuestions = getDefaultQuarterlyQuestions();
    } else {
      this.questionsHaveAnswers =
        data && data.conversation.meetingQuestions.some(q => !!q.manageeAnswer.length || !!q.managerAnswer.length);
      this.originalQuestions = getDefaultConversationQuestions();
    }
  }

  ngOnInit() {
    this.setQuestions();
  }

  setQuestions() {
    const questionsData =
      this.isQuarterly && this.data.quarterlyQuestionsEnabled
        ? this.data?.conversation?.quarterlyQuestions
        : this.data?.conversation?.meetingQuestions;

    if (questionsData) {
      this.formalQuestions = [...questionsData];
    } else {
      const companyQuestions = this.defaultQuestions
        ? (this.defaultQuestions || []).map(q => new FormalConversationQuestion(q.question, q.title))
        : this.getDefaultQuestions();
      this.formalQuestions =
        Array.isArray(companyQuestions) && companyQuestions.every(isConversationQuestion)
          ? companyQuestions
          : this.getDefaultQuestions();
    }
    this.checkIfQuestionsAreSame();
    this.canReset = !this.questionsAreSame || this.formalQuestions.length === 0;
    this.questionsWereSameAtStart = this.questionsAreSame;
  }

  checkIfQuestionsAreSame(): void {
    this.questionsAreSame = isEqual(
      this.withoutAnswers(this.formalQuestions),
      this.withoutAnswers(this.data ? this.currentCompanyQuestions || [] : this.originalQuestions || [])
    );
  }

  withoutAnswers(questions: FormalConversationQuestion[]): Partial<DefaultConversationQuestion>[] {
    return questions.map(q => ({
      question: q && q.question,
      title: q && q.title,
    }));
  }

  newQuestion() {
    this.formalQuestions.push(new FormalConversationQuestion(`Question #${this.formalQuestions.length + 1}`));
    this.disableClose();
  }

  removeQuestion(index: number) {
    this.formalQuestions.splice(index, 1);
    this.disableClose();
  }

  canSave(): boolean {
    return this.formalQuestions.every(q => q.question && q.question.length && q.title && q.title.length);
  }

  save() {
    if (this.questionsHaveAnswers) {
      const questionsWithoutAnswers = this.formalQuestions.map(q => ({
        ...q,
        manageeAnswer: '',
        managerAnswer: '',
      }));
      this.dialogRef.close(questionsWithoutAnswers);
    } else this.dialogRef.close(this.somethingHasChanged ? this.formalQuestions : null);
  }

  reset() {
    this.somethingHasChanged = !this.questionsWereSameAtStart || this.formalQuestions.length === 0;
    this.canReset = false;
    this.formalQuestions = this.getDefaultQuestions();
    this.checkIfQuestionsAreSame();
  }

  onChange(q: DefaultConversationQuestion) {
    q.sectionTitleSameAsQuestion = q.title === q.question;
    // elementId and title are the same right now, but may be different in the future
    q.elementId = q.title;
    this.disableClose();
  }

  disableClose() {
    this.somethingHasChanged = true;
    this.canReset = true;
    this.dialogRef.disableClose = true;
  }

  openImageDialog() {
    this.dialog.open(ImageDialogComponent, {
      data: {
        imgSrc: 'assets/images/annual-discussion-modal-info.png',
      },
      panelClass: 'confirm-dialog-container',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getDefaultQuestions(): FormalConversationQuestion[] {
    return this.isQuarterly ? getDefaultQuarterlyQuestions() : getDefaultConversationQuestions();
  }
}
