import { createReducer, on } from '@ngrx/store';

import { CompanyUsersStateActions } from './company-users-state.actions';
import { companyUsersInitialState, companyUsersStateAdapter } from './company-users-state.model';

export const CompanyUsersStateReducer = createReducer(
  companyUsersInitialState,

  on(CompanyUsersStateActions.setAllCompanyUsers, (state, { companyUsers }) =>
    companyUsersStateAdapter.setAll(companyUsers, state)
  ),

  on(CompanyUsersStateActions.appLogout, state => companyUsersStateAdapter.removeAll(state)),

  on(CompanyUsersStateActions.addOne, (state, { entity }) => companyUsersStateAdapter.addOne(entity, state)),
  on(CompanyUsersStateActions.removeOne, (state, { userId }) => companyUsersStateAdapter.removeOne(userId, state))

  // on(CompanyUsersStateActions.deleteCurrentCompanyUser, state =>
  //   companyUsersStateAdapter.removeOne(state.currentCompanyUserId, state)
  // ),
);
