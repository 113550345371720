import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LetModule } from '@ngrx/component';
import { isEqual } from 'lodash';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';

import { TerraCheckboxModule, TerraCounterModule, TerraIconModule, TerraIconName, TerraSizing } from '@ninety/terra';

import { ClickOutsideDirective } from '../../../_shared/directives/click-outside.directive';

import { QuickFilterOption } from './models/quick-filter-item.model';

export type QuickFilterListLocation = 'below'; // TODO expand to include other use cases.

/**
 * @description
 * A Quick Filter is intended to be visible within the filter toolbar of a page.
 * Quick Filters are highly discoverable and can be used to narrow down a list of
 * display items with just a few clicks.
 *
 * @link Storybook: https://storybook.ninety.io/?path=/docs/atoms-inputs-quick-filter--implementation
 *
 * @link Figma: https://www.figma.com/file/D2EBI45AefRYlZGeTGtv7F/Design-System?type=design&node-id=3214%3A30045&mode=design&t=2csxjtAk9NM1876Q-1
 *
 * @Input disabled: boolean
 *
 * @Input multiple: boolean
 *
 * @Input options: QuickFilterOption<T>[]
 *
 * @Input pillIcon: TerraIconName;
 *
 * @Input pillIconSize: TerraSizing;
 *
 * @Input pillTitle: string
 *
 * @Input selectAllText: string default of 'Select All';
 *
 * @Output change: QuickFilterOption<T>[]
 *
 * @example
 * <ninety-quick-filter
 *   [disabled]="false"
 *   [multiple]="true"
 *   [options]="options$ | async"
 *   [pillTitle]="'Thingys'"
 *   [selectAllText]="'Select All Thingys'"
 *   (change)="onChange($event)">
 * </ninety-quick-filter>
 */
@Component({
  selector: `ninety-quick-filter`,
  standalone: true,
  templateUrl: './quick-filter.component.html',
  styleUrls: ['./quick-filter.component.scss'],
  imports: [
    ClickOutsideDirective,
    CommonModule,
    LetModule,
    MatCheckboxModule,
    TerraIconModule,
    TerraCounterModule,
    TerraCheckboxModule,
    FormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickFilterComponent<T> implements ControlValueAccessor, AfterViewInit, OnDestroy {
  private readonly _allSelectedOption: QuickFilterOption<T> = {
    id: '<all>',
    displayText: 'All',
  };
  private readonly _noneSelectedOption: QuickFilterOption<T> = {
    id: '<none>',
    displayText: '',
  };

  private _details: Element;
  private _list: Element;
  private _summary: Element;
  private _disabled = false;
  private _onTouched: () => void;
  private readonly _checkEdges$ = new BehaviorSubject(0);
  private readonly _destroy$ = new Subject<void>();

  // TODO no public subjects
  public readonly allSelected$ = new BehaviorSubject(false);
  public readonly counterText$ = new BehaviorSubject('');
  public readonly options$ = new BehaviorSubject([] as QuickFilterOption<T>[]);
  public readonly selectedOption$ = new BehaviorSubject(this._noneSelectedOption);
  public readonly selectedOptionsLength$ = new BehaviorSubject(0);

  public readonly selectedOptionDisplayText$ = this.selectedOption$.pipe(
    map(o => o?.displayText || ''),
    distinctUntilChanged()
  );
  public readonly optionsLength$ = this.options$.pipe(
    map(o => o?.length || 0),
    distinctUntilChanged()
  );

  @Input() public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  public get disabled(): boolean {
    return this._disabled || !(this.options?.length > 0);
  }

  @Input() public multiple = false;

  @Input() public set options(options: QuickFilterOption<T>[]) {
    this.options$.next(options);
  }
  public get options(): QuickFilterOption<T>[] {
    return this.options$.getValue();
  }

  @Input() public pillIcon: TerraIconName;

  @Input() public pillIconSize: TerraSizing = 20;

  @Input() public pillTitle = '';

  @Input() public selectAllText = 'Select All';

  /**
   * Use this input to fix the location of the list in regards to the pill. When this is set to 'below', this component
   * will not try to position itself above the pill if it is off the bottom of the screen. Instead, it will set a
   * max-height and scroll.
   *
   * TODO expand to include other positions. Maybe remove all auto positioning and force a user to set the position?
   */
  @Input() public listFixedLocation: QuickFilterListLocation | null = null;

  // TODO don't override DOM events
  @Output() public change = new EventEmitter<QuickFilterOption<T>[]>();

  /** On selection change:
   * If multiple is false, emits the selected option's item - T
   * If multiple is true, emits an array of selected options' items - T[]
   *
   * may need to cast in listener with $any
   * e.g. (selectionChange)="handleSelection($any($event))"
   * */
  @Output() public selectionChange = new EventEmitter<T | T[]>();

  public constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    this.options$
      .pipe(
        filter(options => !!options),
        distinctUntilChanged(isEqual),
        takeUntil(this._destroy$)
      )
      .subscribe(options => {
        // TODO use better change process to avoid setTimeout (has to be here to prevent ExpressionChangedAfterItHasBeenCheckedError)
        setTimeout(() => this._resetValues(options));
        this.change.emit(options);
      });

    this._checkEdges$
      .pipe(
        filter(check => !!check),
        distinctUntilChanged(),
        debounceTime(10),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this._updateListStyles());
  }

  public ngOnDestroy(): void {
    this._details = null;
    this._list = null;
    this._summary = null;
    // TODO Nitpick - Use Subscription or takeUntilDestroyed()
    this._destroy$.next();
    this._destroy$.complete();
  }

  public trackById = (_i: number, o: QuickFilterOption<T>) => o.id;

  public writeValue(value: QuickFilterOption<T> | QuickFilterOption<T>[]): void {
    if (!value) this._selectAll(false);
    else if (Array.isArray(value)) {
      if (0 < value.length) value.forEach(v => this._selectOption(v.id, v.selected));
      else this._selectAll(false);
    } else this._selectOption(value.id, value.selected);
  }

  public registerOnChange(fn: (options: QuickFilterOption<T>[]) => void): void {
    this.options$.pipe(takeUntil(this._destroy$)).subscribe(fn);
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onClickOutside(): void {
    this._closeDropdown();
  }

  public onClickPill(): void {
    if (this.disabled) return;
    this._sendCheckEdgesEvent();
  }

  public onSelectAll(): void {
    if (this.disabled) return;
    this._selectAll(!this.allSelected$.getValue());
    if (this._onTouched) this._onTouched();
  }

  public onSingleSelect({ disabled, id, selected }: QuickFilterOption<T>, $event: MouseEvent): void {
    if (this.multiple || this.disabled || disabled) {
      if ($event && !this.multiple) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      return;
    }

    this._selectOption(id, !selected);
    if (this._onTouched) this._onTouched();
  }

  public onMultiSelectChange({ disabled, id, selected }: QuickFilterOption<T>): void {
    if (this.disabled || disabled) return;
    this._selectOption(id, !selected);
    if (this._onTouched) this._onTouched();
  }

  // TODO use @ViewChild
  private get details(): Element {
    if (!this._details) this._details = this.el.nativeElement.querySelector('details');
    return this._details;
  }

  // TODO use @ViewChild
  private get list(): Element {
    if (!this._list) this._list = this.el.nativeElement.querySelector('.options-list');
    return this._list;
  }

  // TODO use @ViewChild
  private get summary(): Element {
    if (!this._summary) this._summary = this.el.nativeElement.querySelector('summary');
    return this._summary;
  }

  private _closeDropdown(): void {
    this.details.removeAttribute('open'); // TODO use NgClass or renderer2
  }

  private _sendCheckEdgesEvent(): void {
    this._updateListStyles(); // whip
    this._checkEdges$.next(this._checkEdges$.getValue() + 1);
  }

  /**
   * Checks if the list is off the right side of the screen and adjusts the transform value to move the list to the left
   * of the pill. Returns the transform value to move the list to the left of the pill or null if no transformation is
   * necessary.
   */
  private _ensureListInViewport(list: DOMRect, pill: DOMRect): string | null {
    // Check width
    const listRightMostXCoordinate = list.x + list.width;
    const x = window.innerWidth >= listRightMostXCoordinate ? null : `-${list.width - pill.width}px`;

    // Check height
    const listBottomMostYCoordinate = list.y + list.height;
    let y = null;
    if (this.listFixedLocation !== 'below' && window.innerHeight < listBottomMostYCoordinate) {
      const pillMargin = 4 * 2;
      const listShadow = 2;
      y = `-${list.height + pill.height + pillMargin + listShadow}px`;
    }

    // Apply
    if (!x && !y) return null;

    let transform = '';
    if (x && y) transform = `transform: translate(${x}, ${y});`;
    else if (y) transform = `transform: translateY(${y});`;
    else if (x) transform = `transform: translateX(${x});`;
    else throw Error(); // Logically unreachable, only here for readability

    return transform;
  }

  /**
   * Checks if the list is off the bottom of the screen and adjusts the max-height of the list to fit on screen. Returns
   * the transform value to move the list above the pill or null if no transformation is necessary. Has no effect if
   * this.listFixedLocation is NOT set to 'below'.
   */
  private _getListMaxHeightStyle(list: DOMRect): string | null {
    const windowHeight = window.innerHeight;
    const enoughRoomToDisplayListUnderPill = windowHeight >= list.y + list.height;
    if (enoughRoomToDisplayListUnderPill || this.listFixedLocation !== 'below') return null;

    const maxHeight = windowHeight - list.y - 50; // 50px buffer to the bottom of the screen
    return `max-height: ${maxHeight}px`;
  }

  /** Checks if the list is inside the viewport and transforms styles if it isn't to keep the list on screen. */
  private _updateListStyles(): void {
    if ([null, undefined].includes(this.details.getAttribute('open'))) {
      // TODO use NgClass or renderer2
      this.list.setAttribute('style', '');
      return;
    }

    const list = this.list.getBoundingClientRect();
    const pill = this.summary.getBoundingClientRect();

    const xTranslation = this._ensureListInViewport(list, pill);
    const maxHeightStyle = this._getListMaxHeightStyle(list);
    const style = [xTranslation, maxHeightStyle].filter(x => !!x).join(';');

    // TODO use NgClass or renderer2
    if (style) this.list.setAttribute('style', style);
  }

  private _setValues(
    othersSelected = 0,
    allSelected = false,
    selectedOption = this._noneSelectedOption,
    selectedLength = 0
  ): void {
    this.counterText$.next(othersSelected > 0 ? `+${othersSelected}` : '');
    this.allSelected$.next(allSelected);
    this.selectedOption$.next(selectedOption);
    this.selectedOptionsLength$.next(selectedLength);
    if (!this.multiple) this._closeDropdown();
    else this._sendCheckEdgesEvent();
  }

  private _resetValues(options: QuickFilterOption<T>[]): void {
    const allLength = options?.length || 0;
    if (0 === allLength) {
      this._setValues();
      return;
    }

    const o = options.reduce(
      (o, option) => {
        if (option.selected) o.selected.push(option);
        else if (option.disabled) o.disabled.push(option);
        return o;
      },
      { selected: [], disabled: [] }
    );

    const selectedLength = o.selected.length;
    if (allLength === selectedLength + o.disabled.length && this.multiple) {
      this._setValues(0, true, this._allSelectedOption, allLength);
      return;
    }

    if (0 === selectedLength) {
      this._setValues();
      return;
    }

    this._setValues(selectedLength - 1, false, o.selected[0], selectedLength);
  }

  private _selectOption(id: string, select: boolean): void {
    this.options = this.options.map(o => {
      let selected = false;
      if (!this.disabled && !o.disabled) {
        if (id === o.id) selected = select;
        else if (this.multiple) selected = o.selected;
        // If the control is in single select mode and the option that is selected was already selected, don't deselect it
        if (id === o.id && o.selected && !this.multiple) {
          selected = true;
          this._closeDropdown();
        }
      }
      return { ...o, selected };
    });

    const selectedItems = this.options.filter(o => o.selected).map(o => o?.item);
    const valueToEmit: T | T[] = this.multiple ? selectedItems : selectedItems[0] ?? null;
    this.selectionChange.emit(valueToEmit);
  }

  private _selectAll(selectAll: boolean): void {
    this.options = this.options.map(o => ({
      ...o,
      selected: !o.disabled && selectAll,
    }));
  }
}
