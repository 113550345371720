import { ChangeDetectorRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { MatTableDataSourcePageEvent } from '@angular/material/table';
import { ComponentStore } from '@ngrx/component-store';
import { merge } from 'lodash';
import { Observable, Subject, switchMap, tap } from 'rxjs';

import { ElementResizeAwareDirective } from '../../_shared/directives/element-resize-aware/element-resize-aware.directive';
import { ElementBreakpointRecord } from '../../_shared/directives/element-resize-aware/element-resize-aware.model';
import { SelectOption } from '../inputs/selects/base/select-option.interface';

import { checkIfNavigatorIsMobile } from './pagination.component';
import {
  PaginationComponentViewModel,
  PaginationConstants,
  PaginationStoreModel,
  PaginationViewMode,
} from './pagination.component.model';

/**
 * When true, the pagination component debounces its VM. This increases the performance of the component by limiting
 * change detection cycles, but makes testing hard as documented by NGRX. Thus, this injection token is provided by
 * default in the root with the value true. It can be overridden during tests with the `providers` property.
 *
 * See [ComponentStore docs](https://ngrx.io/guide/component-store/read#debounce-selectors)
 */
export const PAGINATION_SHOULD_DEBOUNCE_VM = new InjectionToken<boolean>('pagination.shouldDebounceVM', {
  factory: () => true,
  providedIn: 'root',
});

@Injectable()
export class PaginationStore extends ComponentStore<PaginationStoreModel> {
  // Public subject b/e MatPaginator requires it.
  readonly matPageState$ = new Subject<MatTableDataSourcePageEvent>();

  constructor(
    @Inject(PAGINATION_SHOULD_DEBOUNCE_VM) private readonly shouldDebounceSelectors: boolean,
    private cdr: ChangeDetectorRef
  ) {
    super(); // lazy init
  }

  // SELECTORS

  // From Component @Inputs
  readonly disablePageChange$ = this.select(state => state.disablePageChange);
  readonly disablePageSizeSelect$ = this.select(state => state.disablePageSizeSelect);
  readonly length$ = this.select(state => state.length);
  readonly pageIndex$ = this.select(state => state.pageIndex);
  readonly pageSize$ = this.select(state => state.pageSize);
  readonly pageSizeOptions$ = this.select(state => state.pageSizeOptions);
  readonly isMobileOverride$ = this.select(state => state.isMobileOverride);

  // Derived

  readonly mode$ = this.select(state => (state.isMobileOverride ? PaginationViewMode.mobile : state.mode));
  readonly isMobile$ = this.select(this.mode$, mode => mode === PaginationViewMode.mobile);
  readonly isSmall$ = this.select(this.mode$, mode => mode === PaginationViewMode.small);
  readonly isMedium$ = this.select(this.mode$, mode => mode === PaginationViewMode.medium);
  readonly isLarge$ = this.select(this.mode$, mode => mode === PaginationViewMode.large);

  readonly nextText$ = this.select(this.mode$, mode =>
    mode === PaginationViewMode.mobile ? PaginationConstants.NEXT_TEXT_MOBILE : PaginationConstants.NEXT_TEXT
  );
  readonly previousText$ = this.select(this.mode$, mode => {
    switch (mode) {
      case PaginationViewMode.mobile:
        return PaginationConstants.PREVIOUS_TEXT_MOBILE;
      case PaginationViewMode.small:
      case PaginationViewMode.medium:
        return PaginationConstants.PREVIOUS_TEXT_SHORT;
      default:
        return PaginationConstants.PREVIOUS_TEXT_LONG;
    }
  });

  readonly showDescriptiveText$ = this.select(this.isLarge$, isLarge => isLarge);
  readonly showPageSizeSelectWrapper$ = this.select(
    this.isMedium$,
    this.isLarge$,
    (isMedium, isLarge) => isMedium || isLarge
  );

  readonly pageSizeToSelectOption$ = this.select(this.pageSizeOptions$, pageSizeOptions => {
    const initial: Record<number, SelectOption> = {};
    return pageSizeOptions.reduce((acc, cur) => {
      acc[cur] = SelectOption.fromNumber(cur);
      return acc;
    }, initial);
  });

  readonly currentSelectOption$ = this.select(
    this.pageSize$,
    this.pageSizeToSelectOption$,
    (pageSize, record) => record[pageSize]
  );

  readonly allSelectOptions$ = this.select(this.pageSizeToSelectOption$, record => Object.values(record));

  readonly smallestPageSize$ = this.select(this.pageSizeOptions$, this.isMobile$, (pageSizeOptions, smallWidthMode) =>
    smallWidthMode ? PaginationConstants.MOBILE_PAGE_SIZE : pageSizeOptions[0]
  );

  readonly canDecrement$ = this.select(
    state => !state.disablePageChange && state.pageIndex > PaginationConstants.INITIAL_PAGE_INDEX
  );
  readonly canIncrement$ = this.select(
    state => !state.disablePageChange && (state.pageIndex + 1) * state.pageSize < state.length
  );

  // TODO after update to NGRX 15, use less boilerplate version of VM creation
  readonly vm$: Observable<PaginationComponentViewModel> = this.select(
    // Sourced from the component
    this.disablePageChange$,
    this.disablePageSizeSelect$,
    this.length$,
    this.pageIndex$,
    this.pageSize$,
    this.pageSizeOptions$,
    this.isMobileOverride$,
    // Dynamic Selectors
    this.canDecrement$,
    this.canIncrement$,
    this.currentSelectOption$,
    this.allSelectOptions$,
    this.smallestPageSize$,
    this.nextText$,
    this.previousText$,
    this.showDescriptiveText$,
    this.showPageSizeSelectWrapper$,
    this.mode$,
    (
      disablePageChange,
      disablePageSizeSelect,
      length,
      pageIndex,
      pageSize,
      pageSizeOptions,
      isMobileOverride,
      canDecrement,
      canIncrement,
      currentSelectOption,
      allSelectOptions,
      smallestPageSize,
      nextText,
      previousText,
      showDescriptiveText,
      showPageSizeSelectWrapper,
      mode
    ) => ({
      disablePageChange,
      disablePageSizeSelect,
      length,
      pageIndex,
      pageSize,
      pageSizeOptions,
      isMobileOverride,
      canDecrement,
      canIncrement,
      currentSelectOption,
      allSelectOptions,
      smallestPageSize,
      nextText,
      previousText,
      showDescriptiveText,
      showPageSizeSelectWrapper,
      mode,
    }),
    { debounce: this.shouldDebounceSelectors }
  );

  // REDUCERS

  readonly setPageIndex = this.updater(
    (state: PaginationStoreModel, pageIndex: number): PaginationStoreModel => ({
      ...state,
      pageIndex,
    })
  );

  readonly setPageSize = this.updater(
    (state: PaginationStoreModel, pageSize: number): PaginationStoreModel => ({
      ...state,
      pageSize,
      pageIndex: PaginationConstants.INITIAL_PAGE_INDEX,
    })
  );

  readonly setMode = this.updater(
    (state: PaginationStoreModel, mode: PaginationViewMode): PaginationStoreModel => ({
      ...state,
      mode,
    })
  );

  // BUSINESS LOGIC

  /**
   * Configure the initial state - called during NgOnInit.
   */
  initializeState(inputs: Partial<PaginationStoreModel>) {
    const merged: PaginationStoreModel = merge({}, PaginationConstants.DEFAULT_STATE, inputs);
    const configured = this.configureState(merged);

    this.setState(configured);
  }

  /**
   * Configure the state as @Inputs change - called during NgOnChanges.
   */
  setStateOnChanges(inputs: Partial<PaginationStoreModel>) {
    const configured = this.configureState(inputs);
    this.patchState(configured);
  }

  /**
   * Increase the page index by 1.
   *
   * Note, this method does not check canIncrement. The template disables the button if !canIncrement.
   */
  nextPage() {
    const currentPageIndex = this.get().pageIndex;
    this.setPageIndex(currentPageIndex + 1);

    this.emitPageState();
  }

  /**
   * Decrease the page index by 1.
   *
   * Note, this method does not check canDecrement. The template disables the button if !canDecrement.
   */
  previousPage() {
    const currentPageIndex = this.get().pageIndex;
    this.setPageIndex(currentPageIndex - 1);

    this.emitPageState();
  }

  /**
   * React to the {@link SelectComponent} change event by updating the page and resetting the pageIndex to 0.
   */
  reactToSelectChange(selectOption: SelectOption) {
    const pageSize = parseInt(selectOption.value);
    this.setPageSize(pageSize);

    this.emitPageState();
  }

  // EFFECTS

  observeElementWidth = this.effect((resizeAware$: Observable<ElementResizeAwareDirective>) => {
    if (checkIfNavigatorIsMobile()) {
      return resizeAware$.pipe(
        tap(() => {
          this.setMode(PaginationViewMode.mobile);
          this.cdr.detectChanges();
        })
      );
    }

    return resizeAware$.pipe(
      switchMap(resizeAware =>
        resizeAware
          .getManyBreakpointObservers([
            PaginationConstants.SMALL_WIDTH_BREAKPOINT,
            PaginationConstants.MEDIUM_WIDTH_BREAKPOINT,
          ])
          .pipe(
            tap(breakpoints => {
              const mode: PaginationViewMode = this.mapBreakpointsToMode(breakpoints);
              this.setMode(mode);
              // Trigger change detection so NinetyButton recognizes the VM emission
              this.cdr.detectChanges();
            })
          )
      )
    );
  });

  private mapBreakpointsToMode(breakpoints: ElementBreakpointRecord) {
    if (breakpoints[PaginationConstants.SMALL_WIDTH_BREAKPOINT]) return PaginationViewMode.small;
    if (breakpoints[PaginationConstants.MEDIUM_WIDTH_BREAKPOINT]) return PaginationViewMode.medium;
    return PaginationViewMode.large;
  }

  /**
   * Emits a page event as specified by MatTableDataSourcePaginator. Optimized to only emit when a user changes
   * something - does not emit when inputs change.
   */
  private emitPageState() {
    const { length, pageIndex, pageSize } = this.get();
    this.matPageState$.next({
      length,
      pageIndex,
      pageSize,
    });
  }

  /**
   * Configure state when set on init or during changes.
   *
   * Note, the T type approach allows you to pass a partial or complete object and get the same type in the return
   * value.
   */
  private configureState<T extends PaginationStoreModel | Partial<PaginationStoreModel>>(inputs: T): T {
    if (inputs.pageSizeOptions) inputs.pageSizeOptions = inputs.pageSizeOptions.sort((a, b) => a - b);

    return inputs;
  }
}
