<mat-form-field [appearance]="appearance" id="select-yes-no-maybe">
  <mat-label *ngIf="label">
    {{ label }}
  </mat-label>

  <mat-select
    [value]="selectedOption"
    (valueChange)="onValueChange($event)"
    [disabled]="disabled"
    [placeholder]="placeholder"
    class="main-selector">
    <mat-select-trigger>
      <terra-icon
        *ngIf="selectedOption?.icon"
        [icon]="selectedOption.icon"
        [variant]="selectedOption.iconVariant || 'regular'"
        [class]="selectedOption.iconColor"
        class="select-icon" />
      <span [ngClass]="{ 'select-value-with-icon': selectedOption?.icon }"> {{ selectedOption?.text ?? '' }} </span>
    </mat-select-trigger>

    <mat-option
      attr.data-cy="select_option-{{ option.text }}"
      mat-select-panel-wrap
      *ngFor="let option of validOptions"
      [value]="option"
      [disabled]="option?.isItemDisabled"
      [class]="option?.itemCssClass"
      [matTooltip]="option?.tooltip"
      [matTooltipPosition]="option?.tooltipPosition || 'left'"
      matTooltipClass="ninety-tooltip">
      <terra-icon
        *ngIf="option?.icon"
        [icon]="option.icon"
        [variant]="option.iconVariant || 'regular'"
        [class]="option?.iconColor" />
      {{ option.text }}
    </mat-option>
  </mat-select>
</mat-form-field>
