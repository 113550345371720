import { Injectable } from '@angular/core';
import Compressor from 'compressorjs';
import { Observable, Observer, catchError, map, of, switchMap, tap, throwError } from 'rxjs';

import { SnackbarTemplateType } from '../../_shared/models/enums/snackbar-template-type';

import { NotifyService } from './notify.service';

interface ResizeOptions {
  scale?: number;
  maxWidth?: number;
  maxHeight?: number;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  // Try to avoid importing dependencies unrelated to opening files. Things like notifications/alerts should be handled
  // at a higher level to keep this service single responsibility.
  constructor(private notifyService: NotifyService) {
    // TODO: Investigate if we still need this polyfill
    if (/edge|trident|msie/i.test(window.navigator.userAgent)) {
      this.addToBlobPolyfill();
    }
  }

  sanitizeFilename(name: string): string {
    return name.replace(/[^a-z0-9\.]/gi, '_').slice(0, 255);
  }

  /**
   * Note: You cannot call window.open inside an async callback or it will trigger the pop-up blocker
   */
  openTab(url = 'assets/pdf-loading.html'): Observable<{ newTab: Window; closeTab: () => void }> {
    const newTab = window.open(url, '_blank');

    if (!newTab) {
      // Pop-ups are blocked by default on Apple devices
      return throwError(() => new Error('user must enable pop-ups for this site'));
    }
    newTab.addEventListener('close', newTab.close);

    const closeTab = () => newTab.dispatchEvent(new Event('close'));

    return of({ newTab, closeTab });
  }

  /**
   * Receive a url from a http request and redirect inside new tab
   */
  swapTabWithNewContent(request: Observable<string>, loadingUrl?: string): Observable<string> {
    return this.openTab(loadingUrl).pipe(
      switchMap(({ newTab, closeTab }) =>
        request.pipe(
          // Make sure response is a valid URL by using URL constructor
          tap(url => newTab.location.assign(new URL(url).href)),
          catchError((e: unknown) => {
            closeTab();
            return throwError(() => e);
          })
        )
      )
    );
  }

  /**
   * Receive a file blob from an HTTP request and render in a new tab.
   */
  renderToTab(
    request: Observable<Blob>,
    options: { filename: string }
  ): Observable<{ apiResponse: Blob; closeTab: () => void; newTab: Window }> {
    return this.openTab().pipe(
      switchMap(({ newTab, closeTab }) =>
        request.pipe(
          tap((blob: Blob) => this.openBlob(newTab, blob, options.filename)),
          map((blob: Blob) => ({ apiResponse: blob, closeTab, newTab })),
          catchError((e: unknown) => {
            closeTab();
            return throwError(e);
          })
        )
      )
    );
  }

  renderPdf(request: Observable<ArrayBuffer>): Observable<ArrayBuffer> {
    return this.openTab().pipe(
      switchMap(({ newTab, closeTab }) =>
        request.pipe(
          tap((buffer: ArrayBuffer) => {
            const file = new Blob([buffer], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            newTab.location = fileURL;
          }),
          catchError((e: unknown) => {
            closeTab();
            return throwError(e);
          })
        )
      )
    );
  }

  openBlob(openWindow: Window, blob: Blob, filename = 'Ninety PDF'): void {
    const fileUrl = URL.createObjectURL(blob);
    openWindow.location = fileUrl as any as Location;
  }

  compressImage(resizedImage: File): Observable<File> {
    return new Observable((observer: Observer<File>) => {
      new Compressor(resizedImage, {
        quality: 0.6,
        success: (result: File) => {
          observer.next(result);
          observer.complete();
        },
        error: (error: Error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  }

  // Reference: https://stackoverflow.com/a/61827887/12734092
  getMimeType(dataURI: string): string {
    return dataURI.substring(dataURI.indexOf(':') + 1, dataURI.indexOf(';'));
  }

  dataURItoBlob(dataURI: string) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: this.getMimeType(dataURI),
    });
  }

  dataURItoFile(dataURI: string, name: string): File {
    const blob = this.dataURItoBlob(dataURI);
    return new File([blob], this.sanitizeFilename(name), { type: blob.type, lastModified: new Date().getTime() });
  }

  /**
   * Pass scale (0-1) to downsize image by a fixed amount
   * Pass a single dimensions (width or height) to make fit that one dimension (use to enforce a max width or height)
   * Pass both width & height to fit an image to an exact dimension (ex. 300x300)
   *
   * Note: passing scale ignores width & height
   */
  downsizeImage(file: File, resizeOptions: ResizeOptions): Observable<File> {
    if (!resizeOptions.name) resizeOptions.name = this.sanitizeFilename(file.name);
    if (resizeOptions.scale && resizeOptions.scale > 1) resizeOptions.scale = 1;

    const { scale, maxWidth, maxHeight, name } = resizeOptions;

    return new Observable((obs: Observer<File>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        const img = new Image();
        img.onload = () => {
          const el = document.createElement('canvas');

          let ratio = 1;
          if (scale || maxWidth < img.width || maxHeight < img.height) {
            ratio = scale ? scale : Math.min(maxWidth / img.width || 1, maxHeight / img.height || 1);
          }

          const stretch = !!(maxWidth && maxHeight);
          const w = (el.width = stretch ? maxWidth : img.width * ratio);
          const h = (el.height = stretch ? maxHeight : img.height * ratio);
          const ctx = el.getContext('2d');
          ctx.drawImage(img, 0, 0, w, h);
          el.toBlob((blob: Blob) => {
            obs.next(new File([blob], name, { type: file.type, lastModified: Date.now() }));
            obs.complete();
          });
          reader.onerror = obs.error;
        };

        img.src = e.target.result as string;
      };
    });
  }

  //! polyfill suggested by vendor for edge and IE https://www.npmjs.com/package/ngx-image-cropper
  private addToBlobPolyfill(): void {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          const dataURL = this.toDataURL(type, quality).split(',')[1];
          setTimeout(function () {
            const binStr = atob(dataURL),
              len = binStr.length,
              arr = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            callback(new Blob([arr], { type: type || 'image/png' }));
          });
        },
      });
    }
  }

  downloadExcelFile(response: ArrayBuffer, fileName: string) {
    const name = this.sanitizeFilename(fileName);
    const file = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);

    this.notifyService.notifyWithTemplate(SnackbarTemplateType.success, { message: 'File downloaded' }, 1800);
  }
}
