<div class="getting-started-phase" [class.locked]="phase.locked">
  <div class="phase-locked" *ngIf="phase.locked; else phaseImage">
    <terra-icon icon="lock" variant="fill" size="40" />
  </div>
  <ng-template #phaseImage>
    <div class="phase-image" [style.backgroundImage]="'url(' + phase.image_url + ')'">
      <div class="phase-completed" *ngIf="phase.completed">
        <terra-icon icon="check" variant="bold" size="40" />
      </div>
    </div>
  </ng-template>

  <div class="phase-details">
    <h2 class="phase-title">{{ phase.ordinal }}. {{ phase.title }}</h2>

    <ng-container *ngIf="!phase.locked; else nextPhase">
      <p class="phase-description">
        {{ phase.description }}
      </p>

      <div class="steps-completed">
        <ninety-progress-bar *ngIf="!phase.completed" [percentage]="phase.percentageComplete" />
        <div>{{ phase.stepsCompleted }} of {{ phase.steps.length }} steps complete</div>
      </div>
    </ng-container>

    <ng-template #nextPhase>
      <p class="phase-description">Complete '{{ previousPhaseTitle }}' to unlock this phase</p>
    </ng-template>
  </div>
</div>
