import { FormControl, ValidatorFn } from '@angular/forms';
import { CountryCode, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export class PhoneNumberValidators {
  static Valid(countryCode: CountryCode): ValidatorFn {
    return (control: FormControl<string>) => {
      const error = { invalidPhoneNumber: true };
      let numberInstance: PhoneNumber;
      if (control.value) {
        try {
          numberInstance = parsePhoneNumber(control.value, countryCode);
        } catch (e) {
          return error;
        }

        if (numberInstance && !numberInstance.isValid()) {
          if (!control.touched) {
            control.markAsTouched();
          }
          return error;
        }
      }
      return null;
    };
  }
}
