import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoggerConfiguration, LogsInitConfiguration } from '@datadog/browser-logs';

import { DatadogLogger } from './datadog-logger';
import { DatadogLoggerFactory } from './datadog-logger-factory';
import { DatadogLogsService } from './datadog-logs.service';
import { DatadogReservedTagsRequired } from './types';

type RootOptions = {
  name: string;
  globalContext: DatadogReservedTagsRequired & {
    /** Whether the environment is local or in a deployed instance */
    isLocal: boolean;
  };
  initConf: LogsInitConfiguration;
  conf?: LoggerConfiguration;
};

type ChildOptions = Omit<RootOptions, 'initConf' | 'globalContext'>;

@NgModule()
export class DatadogLoggerModule {
  static forRoot(options: RootOptions): ModuleWithProviders<DatadogLoggerModule> {
    return {
      ngModule: DatadogLoggerModule,
      providers: [
        {
          provide: DatadogLogger,
          useFactory: () => DatadogLoggerFactory(options.name, options.conf),
        },
        {
          provide: DatadogLogsService,
          useFactory: () => {
            const service = new DatadogLogsService().init(options.initConf);
            service.setGlobalContext(options.globalContext);

            return service;
          },
        },
      ],
    };
  }

  static forFeature(options: ChildOptions): ModuleWithProviders<DatadogLoggerModule> {
    return {
      ngModule: DatadogLoggerModule,
      providers: [
        {
          provide: DatadogLogger,
          useFactory: () => DatadogLoggerFactory(options.name, options.conf),
        },
      ],
    };
  }
}
