import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Person } from '../../../_shared/models/_shared/person';

export const CurrentPersonStateActions = createActionGroup({
  source: 'Current Person',
  events: {
    'App Init': props<{ person: Person }>(),
    'App Logout': emptyProps(),
    'Login User': props<{ person: Person }>(),
    'Update Person': props<{ person: Person }>(),
    'Template Update Person': props<{ person: Person }>(),
    'Become Partner Update Person': props<{ person: Person }>(),

    // temporary until consolidate with above
    'Update Current Person': props<{ update: Partial<Person> }>(),
    'Update Current Person Success': props<{ update: Partial<Person> }>(),
  },
});
