export enum DetailType {
  assessment = 'assessment',
  cascadedHeadline = 'cascaded-headline',
  cascadingMessage = 'cascading-message',
  chart = 'chart',
  conversation = 'conversation',
  directory = 'directory',
  futureGoal = 'future-goal',
  headline = 'headline',
  issue = 'issue',
  kpi = 'kpi',
  kpiGroup = 'kpi-group',
  learningModule = 'learning-module',
  learningSubject = 'learning-subject',
  learningTopic = 'learning-topic',
  measurable = 'measurable',
  measurableCreate = 'measurable-create',
  meeting = 'meeting',
  milestone = 'milestone',
  milestoneStore = 'milestone-store',
  process = 'process',
  review = 'review',
  rock = 'rock',
  rockStore = 'rock-store',
  seat = 'seat',
  team = 'team',
  todo = 'todo',
}
