import { createSelector } from '@ngrx/store';

import { selectCompanyTeams } from '../app-entities/teams/teams-state.selectors';
import { selectAllUsers, selectCurrentUser } from '../app-entities/users/users-state.selectors';

export const selectUserTeams = createSelector(selectCurrentUser, selectCompanyTeams, (user, teams) => {
  const teamList = teams.filter(team => user.teams.some(ut => ut.teamId === team._id));

  // Admin, Owners, and Implementers can see all non-private teams
  if (user.isAdminOrOwner || user.isImplementer) {
    teamList.push(...teams.filter(team => !team.private && !teamList.includes(team)));
  }

  return teamList;
});

export const selectUsersBySelectedTeam = createSelector(selectAllUsers, users => {
  const selectedTeamId = window.sessionStorage.getItem('lastAccessedTeamId');

  if (!selectedTeamId) {
    return [];
  }

  return users.filter(user => user.teams && user.teams.some(team => team.teamId === selectedTeamId));
});
