import { EventEmitter, inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MeetingAgendaService } from '@ninety/ui/legacy/core/index';
import { MeetingAgendas } from '@ninety/ui/legacy/shared/index';

@Injectable({
  providedIn: 'root',
})
export class AgendaV2Service {
  private agendaService = inject(MeetingAgendaService);

  saveNewCustomAgendaEvent$ = new EventEmitter();
  updateAgendaEvent$ = new EventEmitter();
  deleteCustomAgendaEvent$ = new EventEmitter();
  discardChangesEvent$ = new EventEmitter();

  private validFormEventB$ = new BehaviorSubject<boolean>(false);
  validForm$ = this.validFormEventB$.asObservable();

  private newChangesEventB$ = new BehaviorSubject<boolean>(false);
  newChanges$ = this.newChangesEventB$.asObservable();

  private resetAgendasB$ = new BehaviorSubject<MeetingAgendas>(null);
  resetAgendas$ = this.resetAgendasB$.asObservable();

  triggerSaveNewCustomAgenda() {
    this.saveNewCustomAgendaEvent$.emit();
  }

  triggerUpdateAgenda() {
    this.updateAgendaEvent$.emit();
  }

  triggerDeleteCustomAgenda() {
    this.deleteCustomAgendaEvent$.emit();
  }

  triggerDiscardChanges() {
    this.discardChangesEvent$.emit();
  }

  resetAgenda() {
    this.agendaService.getCurrentCompanyAgendas().subscribe({
      next: agendas => this.resetAgendasB$.next(agendas),
    });
  }

  validForm(isValid: boolean) {
    this.validFormEventB$.next(isValid);
  }

  newChanges(newChanges: boolean) {
    this.newChangesEventB$.next(newChanges);
  }
}
