import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from '@ninety/ui/web/environments';

import { throwIfAlreadyLoaded } from './module-import.guard';

@NgModule({
  declarations: [],
  exports: [],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.NINETY_GOOGLE_RECAPTCHA_SITEKEY,
    },
  ],
  imports: [RecaptchaV3Module],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
