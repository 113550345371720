import { createReducer, on } from '@ngrx/store';

import { ChannelSubscription } from '../../../_core/services/channel.service';

import { RealTimeActions } from './real-time.actions';

export interface RealTimeStateModel {
  channels: Array<ChannelSubscription & { occupants?: string[] }>;
}

export const initialRealTimeState: RealTimeStateModel = {
  channels: [],
};

export const RealTimeReducer = createReducer<RealTimeStateModel>(
  initialRealTimeState,
  on(
    RealTimeActions.addChannel,
    (state, { channelId, withPresence }): RealTimeStateModel => ({
      ...state,
      channels: [...state.channels, { channelId, withPresence }],
    })
  ),
  on(
    RealTimeActions.removeChannel,
    (state, { channelId }): RealTimeStateModel => ({
      ...state,
      channels: state.channels.filter(channel => channel.channelId !== channelId),
    })
  ),
  on(RealTimeActions.resetChannels, (): RealTimeStateModel => initialRealTimeState),
  on(
    RealTimeActions.presenceChanged,
    (state, presence): RealTimeStateModel => ({
      ...state,
      channels: state.channels.map(c => {
        if (c.channelId !== presence.channelId) return c;

        let occupants: string[] = c.occupants ? [...c.occupants] : [];
        if (presence.action === 'join' && !occupants.includes(presence.userId))
          occupants = occupants.concat([presence.userId]);

        if (presence.action === 'leave' || presence.action === 'timeout') {
          occupants = occupants.filter(o => o !== presence.userId);
        }

        return { ...c, occupants };
      }),
    })
  )
);
