import { Component } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ninety-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss'],
})
export class HelpDialogComponent {
  constructor(public dialogRef: MatLegacyDialogRef<HelpDialogComponent>) {}
}
