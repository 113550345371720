import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LinkedItemsEffects } from './_state/linked-items.effects';
import { LinkedItemsStateReducer } from './_state/linked-items.reducer';

/**
 * Configures NGRX for the linked items module.
 *
 * Why separate and not in TodoModule? Need to import it in My90, but don't want the baggage of the TodoModule
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('linked-items', LinkedItemsStateReducer),
    EffectsModule.forFeature([LinkedItemsEffects]),
    MatDialogModule,
  ],
  providers: [],
})
export class LinkedItemsStateModule {}
