import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SurveyLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-survey-language',
  templateUrl: './survey-language.component.html',
  styles: [],
})
export class SurveyLanguageComponent {
  @Input() survey: SurveyLanguage;
  @Input() resetLanguage: SurveyLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof SurveyLanguage>();
}
