import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';

@Component({
  standalone: true,
  imports: [CommonModule, MatDialogModule, ButtonComponent],
  selector: 'ninety-terms-conditions-dialog',
  templateUrl: 'terms-conditions-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsConditionsDialogComponent {
  appName = 'Ninety';
}
