import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable, InjectionToken, Injector } from '@angular/core';
import { merge, take } from 'rxjs';

export const POPOVER_TOKEN = new InjectionToken<PopoverService>('PopoverService');

interface ShowPopoverType<T> {
  content?: ComponentType<T>;
  withPositions?: ConnectedPosition;
  componentInputs?: { [key: string]: any };
  hasBackdrop?: boolean;
}

export interface ShowPopoverResult<T> {
  componentRef: ComponentRef<T>;
  overlayRef: OverlayRef;
}

/** ShowPopoverType with withPositions required */
type RequiredShowPopoverOpts<T> = Required<Pick<ShowPopoverType<T>, 'withPositions'>> & ShowPopoverType<T>;

@Injectable({ providedIn: 'root' })
export class PopoverService {
  private overlayRef: OverlayRef;
  private defaultPositionEndBottomRight: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    offsetX: 16,
    overlayX: 'start',
    overlayY: 'bottom',
  };
  constructor(private overlay: Overlay) {}

  /**
   * Component is the popover component,
   * elementRef is pointing to the popover's parent component ( trigger )
   * @deprecated prefer showWithOpts - better parameter handling, better options
   */
  show<T>(
    elementRef: ElementRef,
    content?: ComponentType<T>,
    withPositions?: ConnectedPosition,
    componentInputs?: { [key: string]: any }
  ): ShowPopoverResult<T> {
    return this.showWithOpts(elementRef, { content, withPositions, componentInputs });
  }

  showWithOpts<T>(elementRef: ElementRef, opts: ShowPopoverType<T> = {}): ShowPopoverResult<T> {
    if (!this.canWeShowPopover()) return null;

    const { content, componentInputs, hasBackdrop } = opts;
    const portalInjector = Injector.create({
      providers: [{ provide: POPOVER_TOKEN, useValue: this }],
    });

    const withPositions = opts?.withPositions ?? this.defaultPositionEndBottomRight;
    this.overlayRef = this.createOverlay(elementRef, { withPositions, hasBackdrop });

    // Need to handle closing menu on backdrop click regardless of hasBackdrop.
    merge(this.overlayRef.backdropClick(), this.overlayRef.outsidePointerEvents())
      .pipe(take(1))
      .subscribe(() => this.close());

    const componentPortal = new ComponentPortal(content, null, portalInjector);
    const componentRef = this.overlayRef.attach(componentPortal);

    if (componentInputs) {
      for (const key in componentInputs) {
        if (componentInputs.hasOwnProperty(key)) {
          componentRef.instance[key] = componentInputs[key];
        }
      }
    }

    return {
      overlayRef: this.overlayRef,
      componentRef: componentRef,
    };
  }

  close(): void {
    this.overlayRef?.detach();
  }

  private canWeShowPopover(): boolean {
    return !this.overlayRef || !this.overlayRef.hasAttached();
  }

  private createOverlay<T>(elementRef: ElementRef, opts: RequiredShowPopoverOpts<T>): OverlayRef {
    return this.overlay.create(this.getOverlayConfig(elementRef, opts));
  }

  private getOverlayConfig<T>(elementRef: ElementRef, opts: RequiredShowPopoverOpts<T>): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withPush(true)
      .withGrowAfterOpen(true)
      .withPositions([opts.withPositions]);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: opts.hasBackdrop ?? true,
      backdropClass: 'popover-backdrop',
      panelClass: 'popover-panel',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
    });

    return overlayConfig;
  }
}
