import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoOrdinalType } from '@ninety/ui/legacy/shared/models/todos/todo-ordinal-type';
import { TodoSortField } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';

/**
 * Common ancestor between between {@link TeamTodoState} and {@link PersonalTodoState}.
 */
export interface TodoStateBase {
  shouldBroadcast: boolean;
  loading: boolean;
  pageIndex: number;
  pageSize: number;
  showArchived: boolean;
  searchText: string;
  sortDirection: SortDirection;
  sortField: TodoSortField; // todo move to enum
  todoCount: number;
  todos: Todo[];
  showIntegrations: boolean;
  error: unknown; // holder for any API failures to support displaying error info to user.
  ordinalKey: TodoOrdinalType;
  selectedTodoId: string;
}

export const initialTodoStateBase: TodoStateBase = {
  shouldBroadcast: false,
  loading: false,
  pageIndex: 0,
  pageSize: 50,
  showArchived: false,
  searchText: null,
  sortDirection: null,
  sortField: null,
  todoCount: 0,
  todos: [],
  showIntegrations: false,
  error: undefined,
  ordinalKey: 'ordinal',
  selectedTodoId: null,
};
