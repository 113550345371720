import { createReducer, on } from '@ngrx/store';

import { HelpfulPermissions } from '../../../_shared/models/_shared/person';

import { HelpfulPermissionsActions } from './helpful-permissions.actions';

export type HelpfulPermissionsStateModel = {
  helpfulPermissions: HelpfulPermissions;
};

export const initialHelpfulPermissionsState: HelpfulPermissionsStateModel = {
  helpfulPermissions: {
    adjustPricing: false,
    companies: false,
    coupons: false,
    dataUpload: false,
    emailSuppressionList: false,
    hardDeleteCompany: false,
    manageHelpfuls: false,
    manualCharge: false,
    users: false,
    partners: false,
    partnersAdmin: false,
  },
};
export const helpfulPermissionsReducer = createReducer<HelpfulPermissionsStateModel>(
  initialHelpfulPermissionsState,
  on(
    HelpfulPermissionsActions.init,
    (_state, { helpfulPermissions }): HelpfulPermissionsStateModel => ({
      helpfulPermissions: new HelpfulPermissions(helpfulPermissions),
    })
  )
);
