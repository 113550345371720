import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorService } from '../services/error.service';

export function SpinnerAndNotifyError<P extends unknown[], T>(
  target: Object,
  _propertyKey: string | symbol,
  descriptor: TypedPropertyDescriptor<(...args: P) => Observable<T>>
) {
  const wrappedFunction = descriptor.value;
  descriptor.value = function (...args: P) {
    const spinnerService = this._spinnerService || this.spinnerService;
    const errorService = this._errorService || this.errorService;
    spinnerService.start();
    const result: Observable<T> = wrappedFunction.apply(this, args);
    return result
      .pipe<T>(tap(() => spinnerService.stop()))
      .pipe<T>(catchError((e: unknown) => (errorService as ErrorService).notify(e)));
  };
}
