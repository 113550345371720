import { Pipe, PipeTransform } from '@angular/core';

import { Team } from '../models/_shared/team';

@Pipe({
  name: 'filterOutTeam',
})
export class FilterOutTeamPipe implements PipeTransform {
  transform(teams: Team[], teamId: string): Team[] {
    if (!teams || !teamId || teams.length < 2) return teams;
    return teams.filter((t: Team) => t._id !== teamId);
  }
}
