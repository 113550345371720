import { CompanyAffiliateResponse } from '../../../_shared/models/company/company-affiliate-response';
import { DomainPartnerConfiguration } from '../../../_shared/models/login/login-domain-partner-configurations';

export interface AnonymousStateModel {
  affiliatePartnerCompany: CompanyAffiliateResponse;
  partnerConfiguration: DomainPartnerConfiguration;
}

export const initialAnonymousState: AnonymousStateModel = {
  affiliatePartnerCompany: null,
  partnerConfiguration: null,
};
