import { createActionGroup, props, emptyProps } from '@ngrx/store';

import { FeatureFlagStateModel } from './feature-flag-state.model';

export const FeatureFlagActions = createActionGroup({
  source: 'Feature Flags',
  events: {
    'Init Launch Darkly': emptyProps(),
    'Close Launch Darkly Connection': emptyProps(),
    'Update Launch Darkly User': emptyProps(),
    'Initial Flags Received': props<{ flags: FeatureFlagStateModel }>(),
    'Process Feature Flag Updates': props<{ flags: FeatureFlagStateModel }>(),
    'Updated Flags Processed': props<{ flags: FeatureFlagStateModel }>(),
    'Update Feature Flag': props<{ key: string; value: boolean }>(),
    'Refresh App': emptyProps(),
  },
});
