import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AgendaV2Component } from '@ninety/meeting/agenda-v2/agenda-v2.component';
import { AgendasV2Component } from '@ninety/meeting/agendas-v2/agendas-v2.component';
import { TeamMeetingAgendasComponent } from '@ninety/meeting/team-meeting-agendas/team-meeting-agendas.component';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';

import { MeetingsPageComponent } from './meetings-page/meetings-page.component';
import { MeetingsV2Component } from './meetings-v2/meetings-v2.component';
import { PastMeetingsComponent } from './past-meetings/past-meetings.component';

const routes: Routes = [
  {
    path: '',
    component: MeetingsV2Component,
    children: [
      {
        path: '',
        component: MeetingsPageComponent,
        pathMatch: 'full',
      },
      {
        path: MeetingRoutes.upcoming,
        component: MeetingsPageComponent,
      },
      {
        path: MeetingRoutes.agendas,
        component: TeamMeetingAgendasComponent,
      },
      {
        path: `${MeetingRoutes.agendasV2}/:id`,
        component: AgendaV2Component,
      },
      {
        path: MeetingRoutes.agendasV2,
        component: AgendasV2Component,
      },

      {
        path: MeetingRoutes.past,
        component: PastMeetingsComponent,
      },
    ],
  },
  { path: '', redirectTo: MeetingRoutes.home, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeetingV2RoutingModule {}
