import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/_shared/user';

@Pipe({
  name: 'getUserName',
})
export class GetUserNamePipe implements PipeTransform {
  transform(userId: string, users: User[] = []): string {
    const user = users.find((u: User) => u._id === userId);
    if (!user) return 'n/a';
    const { first, last } = user?.metadata?.name;
    return first ? `${first.trim()} ${last ? last.trim() : ''}` : 'n/a';
  }
}
