import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * This component is a wrapper for a row of buttons. It is used to provide a consistent layout for buttons
 * that sit side-by side.  It's designed to allow for the following:
 * 1) Prevents buttons from knowing anything about their margin and causing them to be misaligned from the sides
 * 2) Allows for a consistent gap between buttons without having to add css in each use case
 * */
@Component({
  selector: 'ninety-button-row',
  standalone: true,
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    `,
  ],
})
export class ButtonRowComponent {}
