import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TerraIconModule, TerraIconName, TerraIconVariant } from '@ninety/terra';

import { ContactCardStatusColor } from '../../_shared/models/_shared/contact-card-status-model';
import { CardModule } from '../cards/card.module';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

export interface ContactCardInput {
  fullUserName?: string;
  title?: string;
  email?: string;
  pictureURL?: string;
  userInitials?: string;
  transparent?: boolean;
  isCurrentUser?: boolean;
  showStatus?: boolean;
  statusText?: string;
  statusColor?: ContactCardStatusColor;
  statusIcon?: TerraIconName;
  statusIconVariant?: TerraIconVariant;
}

@Component({
  selector: 'ninety-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CardModule, UserAvatarComponent, TerraIconModule],
})
export class ContactCardComponent {
  @Input() contactCardInput: ContactCardInput;
  contactCardStatusColor = ContactCardStatusColor;
}
