import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'terra-description',
  standalone: true,
  exportAs: 'terraDescription',
  imports: [CommonModule],
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraDescriptionComponent {}
