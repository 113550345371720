import { Pipe, PipeTransform } from '@angular/core';

import { Conversation } from '../models/feedback/conversation';

@Pipe({
  name: 'conversationStatusPipe',
})
export class ConversationStatusPipe implements PipeTransform {
  transform(
    isCompleted: boolean,
    isDone: boolean,
    option: 'isOverdue' | 'showDoneChip',
    isMeetingCompleted: boolean,
    dueDate: Date | string
  ): boolean {
    switch (option) {
      case 'isOverdue':
        return !isCompleted && !isDone && !isMeetingCompleted && new Date(dueDate) < new Date();

      case 'showDoneChip':
        return isCompleted || isDone;
    }
  }
}
