import { LoggerConfiguration, datadogLogs } from '@datadog/browser-logs';

import { DATADOG_DEFAULT_LOGGER_CONF } from './datadog-contants';
import { DatadogLogger } from './datadog-logger';

export function DatadogLoggerFactory(
  name: string,
  conf: LoggerConfiguration = DATADOG_DEFAULT_LOGGER_CONF
): DatadogLogger {
  const ddLogger = datadogLogs.createLogger(name, conf);
  const logger = new DatadogLogger(ddLogger);
  return logger;
}
