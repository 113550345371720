export enum ConversationToolType {
  meeting = 'meeting',
  meetingV2 = 'meeting-form',
  data = 'data',
  rocks = 'rocks',
  todos = 'todos',
  issues = 'issues',
  vto = 'vision',
  ac = 'chart',
  process = 'process',
  directory = 'directory',
}
