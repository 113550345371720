import { SignupCompanyRole } from '@ninety/ui/legacy/shared/models/user/signup-company-role.enum';

export const AcceptInviteStateKey = 'accept-invite';

export interface AcceptInviteStateModel {
  isSpinning: boolean;
  registration: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    password: string;
    signupCompanyRole: SignupCompanyRole;
  };
  submitEvent: SubmitEvent;
}

export const initialAcceptInviteState: AcceptInviteStateModel = {
  isSpinning: false,
  registration: {
    firstName: null,
    lastName: null,
    emailAddress: null,
    password: null,
    signupCompanyRole: null,
  },
  submitEvent: null,
};
