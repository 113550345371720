export enum DirectoryUserStatus {
  all = 'all',
  /** user.deleted !== true &&
    user.active !== false &&
    user.roleCode !== '0004' &&
    user.isImplementer !== true &&
    has no helpful permissions
   (can check in store selectHasAnyHelpfulPermission) */
  paid = 'paid-users',
  /** (user.hasBeenInvited || user.personId) &&
    user.deleted !== true &&
    user.active !== false */
  invited = 'invited-users',
  /** !user.personId &&
   *  user.deleted !== true &&
   *  user.active !== false */
  pending = 'pending-users',
  /** !user.hasBeenInvited &&
      !user.personId &&
      user.deleted !== true &&
      user.active !== true */
  notInvited = 'not-invited',
  /** user.deleted === false &&
      user.active === true  && (
      user.roleCode === '0004' ||
      user.isImplementer === true ||
      UserHelper.isHelpful(user) )
       */
  free = 'free-users',
}
