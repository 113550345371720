import { createSelector } from '@ngrx/store';

import { AnonymousStateKey, selectGlobalAppState } from '../index';

export const selectAnonymousState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[AnonymousStateKey] : null
);

export const selectAnonymousPartnerConfiguration = createSelector(
  selectAnonymousState,
  state => state?.partnerConfiguration
);
export const selectAnonymousAffiliatePartner = createSelector(
  selectAnonymousState,
  state => state?.affiliatePartnerCompany
);
