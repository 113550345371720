export const EosReferrerOptions: string[] = [
  'Our EOS® Implementer',
  'A Friend',
  'My Business Coach/Consultant',
  'Searching Online for EOS® Solutions',
  'An Ad',
  'An Article',
  'Social Media',
  'Attended the EOS® Conference',
  'Heard on a Podcast',
  'The EOS® Worldwide Website',
  'EOS® Community (The Pulse)',
  'A friend in the RocketFuel™ Community or Training',
  'I am an Existing Client and this is my Secondary Company',
  'My Pinnacle Business Guide',
  'My Empire Coach',
  'Fireproof',
  'Other',
];

export const NinetyReferrerOptions: string[] = [
  'Referred by a Peer or Friend',
  'My Business Coach/Consultant',
  'Online Search',
  'An Ad',
  'An Article',
  'Social Media',
  'Heard on a Podcast',
  'I am an Existing Client and this is my Secondary Company',
  'Other',
];
