import { createSelector } from '@ngrx/store';

import { InteractionsStateKey, selectGlobalAppState } from '../index';

export const selectInteractionState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[InteractionsStateKey] : null
);

//maybe by date?
export const selectLatestInteractionId = createSelector(selectInteractionState, state => state?.latestInteractionId);
export const selectLatestInteraction = createSelector(selectInteractionState, state =>
  state?.interactons.find(i => i._id === state.latestInteractionId)
);

export const selectSubmittedWhatNextQuestion = createSelector(
  selectInteractionState,
  state => state?.submittedQuestion
);
