import { moveItemInArray } from '@angular/cdk/drag-drop';
import { on } from '@ngrx/store';
import { ActionCreator } from '@ngrx/store/src/models';
import { ReducerTypes } from '@ngrx/store/src/reducer_creator';
import { cloneDeep } from 'lodash';

import { TodoChildStateKeyUnion } from '@ninety/todos/_state';
import { TodoStateBase } from '@ninety/todos/_state/_shared/todo-state.shared.model';
import { PersonalTabTodosActions, PersonalTodoActions } from '@ninety/todos/_state/personal/personal-todo.actions';
import { TeamTodoActions } from '@ninety/todos/_state/team/team-todo.actions';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoGETManyApiResponse } from '@ninety/ui/legacy/shared/models/todos/todo-response';

/**
 * Maps {@link TodoChildStateKeyUnion} to an action group.
 */
const TodoActionMap = {
  personal: PersonalTodoActions,
  team: TeamTodoActions,
};

/**
 * Shared reducer functions of the Todos module.
 *
 * Note, functions should only go here if they need to be called by shared and non-shared reducers.
 */
export namespace SharedReducerFunctions {
  export function removeTodoFromStateById<T extends TodoStateBase>(state: T, { id }): T {
    const index = state.todos.findIndex(t => t._id === id);
    if (index === -1) return state;
    else
      return {
        ...state,
        todos: state.todos.filter((_, i) => i !== index),
        todoCount: state.todoCount - 1,
      };
  }

  export function setLoadingToFalse<T extends TodoStateBase>(state: T): T {
    return { ...state, loading: false };
  }

  export function setErrorOnApiFailure<T extends TodoStateBase>(state, { error }): T {
    const updatedState = SharedReducerFunctions.setLoadingToFalse(state);
    updatedState.error = error;
    return updatedState;
  }

  export function updateTodosOnGETManySuccess<T extends TodoStateBase>(
    state: T,
    action: { response: TodoGETManyApiResponse }
  ): T {
    return {
      ...state,
      todos: action.response.items,
      todoCount: action.response.totalCount,
      loading: false,
    };
  }

  export function mergeTodoUpdateIntoTodo(t: Todo, todoUpdate: Partial<Todo>) {
    if (t._id == todoUpdate._id) {
      return Object.assign({}, t, todoUpdate);
    }
    return t;
  }

  export function mergeUpdatedTodoIntoReferenceInList(currentTodoList: Todo[], todoUpdate: Partial<Todo>) {
    return currentTodoList.map(t => mergeTodoUpdateIntoTodo(t, todoUpdate));
  }
}

/**
 * Constructs reducers common to {@link TeamTodoState} and {@link PersonalTodoState}.
 */
export function constructSharedReducersWithDistinctActions<T extends TodoStateBase>(
  actionKey: TodoChildStateKeyUnion
): ReducerTypes<T, readonly ActionCreator[]>[] {
  const actions = TodoActionMap[actionKey];

  // Note, you must explicitly specify the type here for the ts type magic of NGRX to work
  const arr: ReducerTypes<TodoStateBase, readonly ActionCreator[]>[] = [
    on(
      actions.addOne,
      (state, { todo }): TodoStateBase => ({
        ...state,
        todos: [...state.todos, todo],
        todoCount: state.todoCount + 1,
      })
    ),

    on(actions.update, actions.updateInline, (state): TodoStateBase => ({ ...state })),
    on(actions.updateLocal, (state, { todo: update }): TodoStateBase => {
      const todos = state.todos.map(todoInStore => {
        if (todoInStore._id == update._id) {
          if (update.hasOwnProperty('attachments')) {
            const attachmentsInUpdate = update.attachments;

            // An attachment event with only 1 item can mean two things.
            // 1. The one attachment represents all the attachments (happens on add/delete from detail view)
            // 2. The one attachments represents a new attachment from the create dialog and may be one of many
            //    (happens when you upload multiple attachments from the create dialog)
            if (attachmentsInUpdate?.length === 1) {
              const onlyAttachmentInUpdate = attachmentsInUpdate[0];
              const existingAttachment = todoInStore.attachments.find(a => a._id === onlyAttachmentInUpdate._id);
              const isNew = !existingAttachment;
              const attachments = isNew ? [...todoInStore.attachments, onlyAttachmentInUpdate] : attachmentsInUpdate;

              return Object.assign({}, todoInStore, update, { attachments });
            }

            // If there is more than one attachment or none at all, then the attachments in the update represent all
            // attachments.
            return Object.assign({}, todoInStore, update, { attachments: attachmentsInUpdate ?? [] });
          }
          return Object.assign({}, todoInStore, update);
        }
        return todoInStore;
      });

      return {
        ...state,
        todos: todos,
        loading: false,
      };
    }),
    on(actions.set, (state, { todos }): TodoStateBase => ({ ...state, todos: todos })),
    on(
      actions.paginationChange,
      PersonalTabTodosActions.paginationChange,
      (state, { index, size }): TodoStateBase => ({
        ...state,
        pageIndex: index,
        pageSize: size,
        loading: true,
      })
    ),
    on(
      actions.sortBy,
      PersonalTabTodosActions.sortBy,
      (state, { sort: { field, direction } }): TodoStateBase => ({
        ...state,
        sortDirection: direction,
        sortField: direction != null ? field : null,
      })
    ),
    on(
      actions.updateOrdinals,
      actions.updateLocalOrdinals,
      PersonalTabTodosActions.updateOrdinals,
      (state: TodoStateBase, { previousIndex, currentIndex }): TodoStateBase => {
        const todosCopy = cloneDeep(state.todos);
        moveItemInArray(todosCopy, previousIndex, currentIndex);

        const todos = todosCopy.map((t, i) => ({
          ...t,
          [state.ordinalKey]: i + state.pageIndex * state.pageSize,
        }));

        return {
          ...state,
          todos,
        };
      }
    ),
    on(
      actions.setShouldBroadcast,
      (state, { broadcast }): TodoStateBase => ({
        ...state,
        shouldBroadcast: broadcast,
      })
    ),
    on(actions.remove, actions.deleteSuccess, SharedReducerFunctions.removeTodoFromStateById),
    on(
      actions.updateFailure,
      actions.deleteFailure,
      actions.deleteSeriesFailure,
      actions.updateOrdinalsFailure,
      SharedReducerFunctions.setLoadingToFalse
    ),
    on(
      actions.showIntegrations,
      (state, { showIntegrations }): TodoStateBase => ({
        ...state,
        showIntegrations,
      })
    ),
    on(actions.createTaskSuccess, (state, { todoId, googleTaskId }): TodoStateBase => {
      const todos = state.todos.map(t => {
        if (t._id === todoId) {
          return Object.assign({}, t, { googleTaskId });
        }
        return t;
      });

      return {
        ...state,
        loading: false,
        todos,
      };
    }),
    on(actions.syncTasksFailure, actions.syncTasksSuccess, (state): TodoStateBase => ({ ...state, loading: false })),
    on(
      actions.updateInline,
      (state): TodoStateBase => ({
        ...state,
      })
    ),
    on(
      actions.updateInlineSuccess,
      (state, { todo }): TodoStateBase => ({
        ...state,
        todos: SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(state.todos, todo),
      })
    ),
    on(actions.select, (state, { todo }): TodoStateBase => ({ ...state, selectedTodoId: todo._id })),
    on(actions.deselect, (state): TodoStateBase => ({ ...state, selectedTodoId: null })),

    on(actions.setSelectedId, (state, { todoId }): TodoStateBase => ({ ...state, selectedTodoId: todoId })),
    on(actions.clearSelectedId, (state): TodoStateBase => ({ ...state, selectedTodoId: null })),
    on(
      actions.search,
      (state, { searchText }): TodoStateBase => ({
        ...state,
        searchText: searchText || null,
        loading: true,
        pageIndex: 0,
      })
    ),
  ];

  // This cast is necessary, although unfortunate. We've tried countless combinations of types between the arr, param,
  // and return type all to no avail.
  return arr as unknown as ReducerTypes<T, readonly ActionCreator[]>[];
}
