<h2 mat-dialog-title>Oops!</h2>
<mat-dialog-content id="user-limit-dialog">
  <p>
    You've hit your limit of <b>{{ data.companyUserLimit }} users</b>. Want to invite more teammates?<br />Reach out to
    our support team via the Intercom chat bubble in the bottom right.
  </p>
  <p *ngIf="removeMsg" class="warning">{{ removeMsg }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close class="cancel">Got it</button>
</mat-dialog-actions>
