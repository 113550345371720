import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@ninety/ui/web/environments';

import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(error =>
    console.error(error.message, {
      error,
      env: `${environment.env}:${environment.region}`,
      isLocal: environment.hostedEnvironment === 'localhost',
      service: environment.service,
      version: environment.appVersion,
    })
  );
