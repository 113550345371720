import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TerraIconModule } from '@ninety/terra';

import { SelectBase } from './base/select-base';
import { SelectOption } from './base/select-option.interface';

@Component({
  selector: 'ninety-select',
  templateUrl: './base/select.component.html',
  styleUrls: ['./base/select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  /* eslint-disable-next-line @angular-eslint/no-outputs-metadata-property */ /* Need this because the base class is emitting the event. */
  outputs: ['valueChanged'],
})
export class SelectComponent
  extends SelectBase<SelectOption>
  implements ControlValueAccessor, OnChanges, AfterContentInit
{
  @Input() placeholder: string;
  @Input() label = '';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() initialOptionValue: SelectOption;
  @Input() set isDisabled(value: boolean) {
    this.disabled = value;
  }
  @Input()
  set options(value: SelectOption[]) {
    this.validOptions = value;
  }

  ngAfterContentInit(): void {
    this.writeValue(this.initialOptionValue?.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.initialOptionValue?.firstChange &&
      changes.initialOptionValue?.currentValue !== changes.initialOptionValue?.previousValue
    )
      this.writeValue(this.initialOptionValue.value);
  }
}

@NgModule({
  declarations: [SelectComponent],
  exports: [SelectComponent],
  imports: [CommonModule, MatSelectModule, TerraIconModule, MatTooltipModule],
})
export class SelectModule {}
