import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import type { AccessToken } from '../../_shared/models/auth/access-token';

const KEY = 'ACCESS-TOKEN';

export function tokenGetter(): string | null {
  return sessionStorage.getItem(KEY) ?? localStorage.getItem(KEY);
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private jwtHelperService: JwtHelperService) {}

  decodeToken<T>(token?: string): T {
    return this.jwtHelperService.decodeToken<T>(token);
  }

  getAccessToken(): string | null {
    return tokenGetter();
  }

  getAccessTokenDecoded(): AccessToken {
    return this.jwtHelperService.decodeToken(this.getAccessToken());
  }

  setAccessToken(value: string, useSessionStorage = false) {
    if (!useSessionStorage) localStorage.setItem(KEY, value);
    else sessionStorage.setItem(KEY, value);
  }

  removeAccessToken() {
    localStorage.removeItem(KEY);
    sessionStorage.removeItem(KEY);
  }

  isExpired(token: { exp: number }): boolean {
    return token?.exp * 1000 < Date.now();
  }
}
