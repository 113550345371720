import { createActionGroup, props } from '@ngrx/store';

export const SpinnerActions = createActionGroup({
  source: 'Spinner',
  events: {
    'Start Primary': props<{ source?: string }>(),
    'Stop Primary': props<{ source?: string }>(),
    'Start Auxiliary': props<{ source?: string }>(),
    'Stop Auxiliary': props<{ source?: string }>(),
  },
});
