import { environment } from '@ninety/ui/web/environments';

import {
  createToolbarButtons,
  extraSmallCounts,
  largeCounts,
  mediumCounts,
  smallCounts,
  ToolbarButtonOptions,
  ToolbarButtonOptionsCounts,
} from './froala-toolbar-options';
import { ITextEditorOptions } from './text-editor-options';

export interface ResponsiveFroalaOptions {
  extraSmall: FroalaOptions;
  small: FroalaOptions;
  medium: FroalaOptions;
  large: FroalaOptions;
}

export function getResponsiveOptions(options: FroalaOptions): ResponsiveFroalaOptions {
  return {
    extraSmall: buildResponsiveOptions(options, extraSmallCounts),
    small: buildResponsiveOptions(options, smallCounts),
    medium: buildResponsiveOptions(options, mediumCounts),
    large: buildResponsiveOptions(options, largeCounts),
  };
}

function buildResponsiveOptions(options: FroalaOptions, counts: Partial<ToolbarButtonOptionsCounts>): FroalaOptions {
  return Object.assign({}, options, { toolbarButtons: createToolbarButtons(counts) });
}

/**
 * Documentation:
 * https://froala.com/wysiwyg-editor/docs/options/#spellcheck
 *
 * Someone wrote out types we can reference here:
 * https://github.com/froala/wysiwyg-editor/issues/2369#issuecomment-507637238
 */
export class FroalaOptions {
  attribution = false;
  charCounterCount = false;
  imagePaste = false;
  key = environment.froalaApiKey;
  linkAlwaysBlank = true;
  placeholderText = 'Description...';
  quickInsertEnabled = false;
  spellcheck = true;
  toolbarSticky = false;
  toolbarBottom = false; // Optionally part of DEV-734, but left out due to unexpected CSS affects
  initOnClick = false; // Only show editor when text clicked
  toolbarButtons: ToolbarButtonOptions; // Set by component to responsive options
  heightMax = 300;
  heightMin = 100;

  constructor(opts?: Partial<Omit<FroalaOptions, 'key'>>) {
    if (opts) Object.assign({}, this, opts);
  }
}

export class FroalaTextEditorOptions implements ITextEditorOptions<FroalaOptions> {
  private readonly default = new FroalaOptions();

  constructor(private options: FroalaOptions = new FroalaOptions()) {}

  clone(options?: Partial<FroalaOptions>): ITextEditorOptions<FroalaOptions> {
    return new FroalaTextEditorOptions(Object.assign(this.options, options));
  }

  setPlaceholder(placeholder: string): void {
    this.options.placeholderText = placeholder;
  }

  setCustomOptions(customOptions: FroalaOptions): void {
    this.options = { ...this.options, ...customOptions };
  }

  resetPlaceholder(): void {
    this.options.placeholderText = this.default.placeholderText;
  }

  value(): FroalaOptions {
    return { ...this.options };
  }
}
