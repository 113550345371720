import { SortDirection } from '../enums/sort-direction';

export enum MeetingSortFieldsEnum {
  date = 'date',
  elapsedTime = 'elapsedTime',
  //Other fields not on the Meeting model
  agendaName = 'agendaName', //meeting type or custom name
  presenterName = 'presenterName',
  ratingAverage = 'ratingAverage',
}

export interface MeetingsV2Sort {
  field: MeetingSortFieldsEnum;
  direction: SortDirection;
}
