import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { isEmpty as _isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { ButtonRowComponent } from '../../../_components/buttons/button-row/button-row.component';
import { FeatureFlagKeys } from '../../../_state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../../../_state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectPrimary } from '../../../_state/app-global/spinner/spinner-state.selectors';
import { CompanyLanguage, CustomLanguage } from '../../models/language/custom-language';
import { NinetyValidators } from '../../validators/ninety-validators';

import { LanguageSectionsModule } from './language-sections/language-sections.module';

@Component({
  selector: 'ninety-custom-language',
  templateUrl: './custom-language.component.html',
  styleUrls: ['./custom-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    ButtonRowComponent,
    ButtonComponent,
    TerraIconModule,
    PushPipe,
    NgIf,
    LanguageSectionsModule,
  ],
})
export class CustomLanguageComponent implements OnChanges {
  @Input() language: CustomLanguage;
  @Input() resetLanguage: CustomLanguage;
  @Input() readonly: boolean;

  @Output() save = new EventEmitter<CustomLanguage>();

  languageForm: FormGroup;
  private languageDifferentThanResetLanguage = new BehaviorSubject<boolean>(false);
  showResetBtn$ = this.languageDifferentThanResetLanguage.asObservable();
  spinner$ = this.store.select(selectPrimary);
  surveysEnabled$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.enableAssessments));

  constructor(private store: Store, private fb: FormBuilder) {}

  ngOnChanges({ language, resetLanguage }: SimpleChanges) {
    if (language?.currentValue) {
      this.buildLanguageForm(this.language, this.readonly);
    }

    if (resetLanguage?.currentValue) {
      this.checkIfDifferentFromResetLanguage();
    }
  }

  onSave(): void {
    this.save.emit(this.languageForm.value);
    this.languageForm.markAsPristine();
    this.checkIfDifferentFromResetLanguage();
  }

  cancel(): void {
    this.checkIfDifferentFromResetLanguage()
      ? this.languageForm.reset({}, { emitEvent: false })
      : this.buildLanguageForm(this.language, this.readonly);
    this.checkIfDifferentFromResetLanguage();
  }

  resetAll(): void {
    this.buildLanguageForm(this.resetLanguage, this.readonly);
    this.languageForm.markAsDirty();
    this.languageDifferentThanResetLanguage.next(false);
  }

  resetOne(page: keyof CustomLanguage, prop: string): void {
    const control = this.languageForm.get(page).get(prop);
    const defaultProp = this.resetLanguage[page][prop];
    if (control.value !== defaultProp) {
      control.setValue(defaultProp);
      this.languageForm.markAsDirty();
      this.checkIfDifferentFromResetLanguage();
    }
  }

  buildLanguageForm({ _id, companyId, ...language }: CompanyLanguage, disabled: boolean) {
    const form = {};

    Object.keys(language).forEach(parentKey => {
      const group = {};
      Object.keys(language[parentKey]).forEach(languageKey => {
        group[languageKey] = new FormControl<string>(
          { value: language[parentKey][languageKey], disabled },
          { validators: [Validators.required, NinetyValidators.preventOnlyWhiteSpace], nonNullable: true }
        );
      });
      form[parentKey] = this.fb.group(group);
    });

    this.languageForm = this.fb.group(form);
  }

  private checkIfDifferentFromResetLanguage(): boolean {
    if (this.readonly) return;
    const customLanguage: CustomLanguage = _cloneDeep(this.languageForm.value);
    Object.keys(this.resetLanguage).forEach(k => {
      Object.keys(this.resetLanguage[k]).forEach(p => {
        if (customLanguage[k][p] === this.resetLanguage[k][p]) delete customLanguage[k][p];
      });
      if (_isEmpty(customLanguage[k])) delete customLanguage[k];
    });
    this.languageDifferentThanResetLanguage.next(!_isEmpty(customLanguage));
    return !_isEmpty(customLanguage);
  }
}
