<mat-dialog-content>
  <ng-container *ngrxLet="recentMeetings$ as recentMeetings">
    <ng-container *ngIf="recentMeetings.length > 0">
      <span class="header">Recently run</span>
      <div
        *ngFor="let meeting of recentMeetings"
        class="menu-item"
        (click)="createMeeting(meeting.type, meeting.agendaId)"
        attr.data-cy="create-recent-meeting_{{ meeting.type }}_{{ meeting.agendaId }}">
        <terra-icon icon="calendar" />
        <span class="flex-2">{{ meeting.agendaName }}</span>
        <span class="text-light">{{ meeting.date | dateDistance }}</span>
      </div>
      <terra-divider height="short" /> </ng-container
  ></ng-container>

  <span class="header">All agendas</span>
  <div
    *ngFor="let meeting of defaultMeetings$ | async"
    class="menu-item"
    (click)="createMeeting(meeting.id)"
    attr.data-cy="create-default-meeting_{{ meeting.id }}">
    <terra-icon icon="calendar" />
    <span class="flex-2">{{ meeting.displayText }}</span>
  </div>
  <div
    *ngFor="let meeting of customMeetings$ | async"
    class="menu-item"
    (click)="createMeeting(meetingType.custom, meeting.id)"
    attr.data-cy="create-custom-meeting_{{ meeting.id }}">
    <terra-icon icon="calendar" />
    <span class="flex-2">{{ meeting.displayText }}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngrxLet="showAgendasV2$ as showAgendasV2">
  <ninety-button compact primary (click)="manageAgendas(showAgendasV2)">Manage agendas...</ninety-button>
</mat-dialog-actions>
