import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, combineLatest, switchMap, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DirectoryAddInviteVewModel } from '@ninety/directory/directory-add-invite/directory-add-invite-view-model.interface';
import { BillingV2SeatFacadeService } from '@ninety/directory/legacy-compatibility/billing-v2-seat-facade.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { CompleteBillingOverviewDetailsModel } from '@ninety/ui/legacy/shared/models/billingv2/complete-billing-overview-details.model';
import { DirectoryUserStatus } from '@ninety/ui/legacy/shared/models/directory/directory-user-status';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { UpdateSeatCountResponse } from '@ninety/ui/legacy/shared/models/paddle/paddle-subscription-details';
import { selectCompleteBillingOverview } from '@ninety/ui/legacy/state/app-global/billing/billing-state.selectors';
import { SubscriptionActions } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.actions';

@Injectable()
export class BillingV2DirectoryAddInviteFacadeService {
  private licensePrice$ = new BehaviorSubject<number>(0);
  private paymentFailed$ = new BehaviorSubject<boolean>(false);
  private displayAdditionalLicMsg$ = new BehaviorSubject<boolean>(false);
  private licToBeAdded$ = new BehaviorSubject<number>(0);
  private completeBillingOverviewDetails: CompleteBillingOverviewDetailsModel;
  constructor(
    private userService: UserService,
    private standardUser: BillingV2SeatFacadeService,
    private store: Store
  ) {
    this.standardUser.initDataSource({
      page: 0,
      pageSize: 10,
      sortDirection: SortDirection.ASC,
      userStatus: DirectoryUserStatus.paid,
    });
    this.store
      .select(selectCompleteBillingOverview)
      .pipe(
        tap(completeBillingDetailsOverview => {
          this.completeBillingOverviewDetails = completeBillingDetailsOverview;
        })
      )
      .subscribe();
  }

  public vm = {
    licenseUsed: this.standardUser.vm.licenseUsed,
    licenseQty: this.standardUser.vm.licenseQty,
    licensePrice: this.licensePrice$,
    paymentFailed: this.paymentFailed$,
    displayAdditionalLicMsg: this.displayAdditionalLicMsg$,
    licToBeAdded: this.licToBeAdded$,
    calculateLicensePrice: (licNum: number) => this.calculateLicensePrice(licNum),
    shouldDisplayAdditionalLicMsg: (licNum: number) =>
      this.displayAdditionalLicMsg$.next(this.shouldDisplayAdditionalLicMsg(licNum)),
  } as DirectoryAddInviteVewModel;

  performInvitationWithAutoAddingLicenses(
    inviteUserPayloads: InviteUserPayload[],
    shouldSendInvites: boolean,
    directoryOnly: boolean
  ) {
    return this.performLicenseAutoIncrement(inviteUserPayloads).pipe(
      switchMap(_ => this.performInvitation(inviteUserPayloads, shouldSendInvites, directoryOnly))
    );
  }

  private shouldDisplayAdditionalLicMsg(licNum: number): boolean {
    return this.vm.licenseUsed.value + licNum > this.vm.licenseQty.value;
  }

  private calculateLicensePrice(licNum: number): void {
    this.licensePrice$.next(0);
    this.licToBeAdded$.next(licNum - (this.vm.licenseQty.value - this.vm.licenseUsed.value));
  }

  private performLicenseAutoIncrement(inviteUserPayloads: InviteUserPayload[]): Observable<UpdateSeatCountResponse> {
    const currentNumberOfLicenses = this.getCurrentNumberOfLicenses();
    const allocatedLicenses = this.getAllocatedLicenses();
    const numberOfNewLicensesNeeded = this.calculateNumberOfNeededLicenses(
      this.getNumberOfPaidLicensesFromInviteUsers(inviteUserPayloads),
      currentNumberOfLicenses,
      allocatedLicenses
    );

    const payload = {
      liteSeatCount: allocatedLicenses.lite, // Preserved the previous value
      standardSeatCount: currentNumberOfLicenses + numberOfNewLicensesNeeded,
    };

    if (numberOfNewLicensesNeeded > 0) {
      this.increaseNumberOfLicenses(payload);
    }

    // Keeping methods compatible. Should be refactored in the bigger directory refactor
    return of(null);
  }

  private performInvitation(
    inviteUserPayloads: InviteUserPayload[],
    shouldSendInvites: boolean,
    directoryOnly: boolean
  ): Observable<User[]> {
    return combineLatest(
      inviteUserPayloads.map(payload => this.userService.inviteUser(payload, shouldSendInvites && !directoryOnly))
    );
  }

  /**
   * We need to get the current number of licenses.
   */
  private getCurrentNumberOfLicenses(): number {
    return this.completeBillingOverviewDetails.billingOverviewDetails.seatsPaidFor;
  }

  /**
   * Out of the invited users, check the paid license
   * @param inviteUserPayloads
   * @returns
   */
  private getNumberOfPaidLicensesFromInviteUsers(inviteUserPayloads: InviteUserPayload[]): number {
    if (inviteUserPayloads.length === 0) {
      return 0;
    }
    return inviteUserPayloads.filter(
      (user: InviteUserPayload) =>
        (user.roleCode === RoleCode.owner ||
          user.roleCode === RoleCode.admin ||
          user.roleCode === RoleCode.manager ||
          user.roleCode === RoleCode.managee) &&
        user.isImplementer === false
    ).length;
  }

  //Get number of used licenses
  private getAllocatedLicenses(): { lite: number; regular: number } {
    return {
      lite: 0,
      regular: this.completeBillingOverviewDetails.companyBillingCounts.activeBillingUsers,
    };
  }

  /**
   * Calculates the number of needed licenses base on
   * the number of paid licenses and the number of allocatedLicenses and the number of invited paid users
   * @returns
   */
  private calculateNumberOfNeededLicenses(
    numberOfNewLicenses: number,
    currentNumberOfLicenses: number,
    numberOfAllocatedLicenses: { lite: number; regular: number }
  ): number {
    return numberOfNewLicenses - (currentNumberOfLicenses - numberOfAllocatedLicenses.regular);
  }

  private increaseNumberOfLicenses(payload) {
    this.store.dispatch(SubscriptionActions.autoIncrementSubscriptionQuantity({ quantity: payload.standardSeatCount }));
  }
}
