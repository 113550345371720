import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionModule } from '../forms/terra-description/terra-description.module';

import { TerraSwitchComponent } from './terra-switch.component';

@NgModule({
  imports: [CommonModule, TerraSwitchComponent, TerraDescriptionModule],
  exports: [TerraSwitchComponent, TerraDescriptionModule],
})
export class TerraSwitchModule {}
