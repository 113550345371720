<div class="step" [class.completed]="step.completed">
  <div class="completion-icon-wrapper">
    <div class="completion-icon">
      <terra-icon icon="check" variant="bold" size="16"></terra-icon>
    </div>
  </div>

  <div class="content">
    <div class="header">
      <div class="title">{{ step.title }}</div>
      <ninety-button compact primary class="learn-more-link" (click)="openLearnMoreLink()">
        <terra-icon icon="arrow-square-out" size="20"></terra-icon>
        Learn More
      </ninety-button>
    </div>

    <div class="description">{{ step.description }}</div>

    <div class="how-to-panel" *ngIf="mazChatEnabled" (click)="suggestedPromptClick()">
      <div>{{ step.suggested_prompt }}</div>
      <terra-icon icon="sparkle" variant="fill" size="20"></terra-icon>
    </div>
  </div>
</div>
