import { LoginQueryStringParams } from '@ninety/login/_api/models/login-query-string-parameters.model';
import { IdToken } from '@ninety/ui/legacy/shared/models/_shared/sign-up';

export const LoginSharedStateKey = 'shared';

export interface LoginSharedStateModel {
  isSpinning: boolean;
  isFederated: boolean;
  queryParams: LoginQueryStringParams;
  prefillFields: {
    idToken: IdToken;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export const initialLoginSharedState: LoginSharedStateModel = {
  isSpinning: false,
  queryParams: {
    urlHash: null,
    idToken: null,
    affiliateCode: null,
    partnerKey: null,
    firstName: null,
    lastName: null,
    email: null,
    implementer: null,
    coupon: null,
    noStripe: null,
    partner: null,
    eosi: null,
    testDomain: null,
    error: null,
    errorDescription: null,
    decodedToken: {
      firstName: null,
      lastName: null,
      email: null,
    },
    templateId: null,
  },
  prefillFields: {
    idToken: null,
    firstName: null,
    lastName: null,
    email: null,
  },
  isFederated: false,
};
