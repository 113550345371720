import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { User } from '../../../_shared/models/_shared/user';
import { mongoIdAdapter } from '../../../_shared/ngrx/mongo-id-adapter';
import { SortByNamePipe } from '../../../_shared/pipes/sort-by-name.pipe';

import { UserListModel } from './api/user-list.model';

const selectId = mongoIdAdapter<UserListModel & { _id: string }>();
export const userListStateAdapter = createEntityAdapter<UserListModel>({
  selectId,
  sortComparer: SortByNamePipe.ascending,
});

export interface UserListStateModel extends EntityState<UserListModel> {
  loggedInUser: User | null;
}

export const userListInitialState: UserListStateModel = userListStateAdapter.getInitialState({
  loggedInUser: null,
});
