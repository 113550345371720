import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { LanguageService } from '../../../_core/services/language.service';
import { selectBos, selectCompanyId } from '../../../_state/app-global/company/company-state.selectors';
import { LanguageActions } from '../../../_state/app-global/language/language.actions';
import { NotificationActions } from '../../../_state/app-global/notifications/notification.actions';

@Injectable()
export class LanguageEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly languageService: LanguageService
  ) {}

  /** Company Settings / Our Language */
  updateCurrentCompanyCustomLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.updateCurrentCompanyLanguage),
      concatLatestFrom(() => this.store.select(selectBos)),
      switchMap(([{ companyLanguage }, bos]) =>
        this.languageService.updateCustomLanguage(companyLanguage, bos).pipe(
          map(() => LanguageActions.updateCurrentCompanyLanguageSuccess({ companyLanguage })),
          catchError((error: unknown) => of(LanguageActions.updateCustomLanguageFailure({ error })))
        )
      )
    )
  );

  getResetLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.getResetLanguage),
      concatLatestFrom(() => [this.store.select(selectCompanyId)]),
      switchMap(([_, companyId]) =>
        this.languageService.fetchCompanyResetLanguage(companyId).pipe(
          map(resetLanguage => LanguageActions.setResetLanguage({ resetLanguage })),
          catchError((error: unknown) =>
            of(NotificationActions.notifyError({ error, message: 'Failed to get the reset language' }))
          )
        )
      )
    )
  );
}
