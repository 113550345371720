import { Pipe, PipeTransform } from '@angular/core';

import { Meeting } from '../models/meetings/meeting';
import { MeetingType } from '../models/meetings/meeting-type.enum';

@Pipe({
  name: 'getStartOrContinueMeetingTextPipe',
})
export class GetStartOrContinueMeetingTextPipe implements PipeTransform {
  transform(meeting: Meeting, meetingIsAnnual: boolean, itemName: string): string {
    const a =
      meeting.type === MeetingType.annualDayTwo && !meeting.started
        ? 'Start '
        : meeting.inProgress
        ? 'Join '
        : ' Continue ';
    const b =
      meeting.type === MeetingType.annualDayOne || meeting.type === MeetingType.visionBuildingDayOne ? 'Day 1 ' : '';
    const c =
      meeting.type === MeetingType.annualDayTwo || meeting.type === MeetingType.visionBuildingDayTwo ? 'Day 2 ' : '';
    const d = !meetingIsAnnual ? `${itemName} ` : '';
    return `${a}${b}${c}${d}`;
  }
}
