<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="future-goal" />
      <span data-cy="measurable-language_card-title">{{ measurable.items }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="measurable">
      <mat-form-field appearance="outline">
        <mat-label>{{ measurable.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="measurable.item"
          data-cy="measurable-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="measurable-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ measurable.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="measurable.items"
          data-cy="measurable-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="measurable-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ measurable.goal }} (singular):</mat-label>
        <input
          #goal
          matInput
          formControlName="goal"
          required
          [attr.aria-label]="measurable.goal"
          data-cy="measurable-language_goal-singular-input-field" />
        <ninety-reset-btn
          *ngIf="goal.value !== resetLanguage?.goal"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('goal')"
          [disabled]="readonly"
          data-cy="measurable-language_goal-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ measurable.goals }} (plural):</mat-label>
        <input
          #goals
          matInput
          formControlName="goals"
          required
          [attr.aria-label]="measurable.goals"
          data-cy="measurable-language_goal-plural-input-field" />
        <ninety-reset-btn
          *ngIf="goals.value !== resetLanguage?.goals"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('goals')"
          [disabled]="readonly"
          data-cy="measurable-language_goal-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
