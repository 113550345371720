import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { NinetyLayoutType } from '@ninety/layouts/_state/page/page-state.models';

export const PageStateActions = createActionGroup({
  source: 'PageState',
  events: {
    'Set Layout Type': props<{ layoutType: NinetyLayoutType }>(),
  },
});
