import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ninety-tabs-bar',
  templateUrl: './ninety-tabs-bar.component.html',
  styleUrls: ['./ninety-tabs-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NinetyTabsBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
