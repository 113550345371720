import { EntityState, IdSelector, createEntityAdapter } from '@ngrx/entity';

import { PaginationConstants } from '@ninety/ui/legacy/components/pagination/pagination.component.model';
import { CascadingMessagesSort } from '@ninety/ui/legacy/shared/models/cascading-messages/cascading-message-sort';
import { CascadingMessage } from '@ninety/ui/legacy/shared/models/meetings/cascading-message';

export const CascadingMessageStateKey = 'cascadingMessages';
export type CascadingMessagesFilterBy = {
  teamId: string;
  archived: boolean;
  searchText: string;
  includeDiscussed: boolean;
  inMeetingId: string;
};

export interface CascadingMessagesStateModel extends EntityState<CascadingMessage> {
  selectedId: string;

  loading: boolean;
  totalCount: number;

  filters: CascadingMessagesFilterBy;

  pagination: { index: number; size: number };
  sort: CascadingMessagesSort;

  error: boolean;
}
const selectId: IdSelector<CascadingMessage> = ({ _id }) => _id;
export const cascadingMessagesStateAdapter = createEntityAdapter<CascadingMessage>({ selectId });
export const defaultPagination = {
  index: 0,
  size: PaginationConstants.DEFAULT_PAGE_SIZES[0],
};
export const defaultSort = { direction: null, field: null };
export const cascadingMessagesInitialState = cascadingMessagesStateAdapter.getInitialState({
  selectedId: null,
  loading: false,
  totalCount: 0,

  filters: {
    teamId: null,
    archived: false,
    searchText: null,
    includeDiscussed: true,
    inMeetingId: null,
  },
  pagination: defaultPagination,
  sort: defaultSort,

  error: false,
});
