import { VtoCustomSectionSettings, VtoSectionSettings } from '@ninety/ui/legacy/shared/models/vto/vto-sections';

export interface EnrichmentOptions {
  addMissingSectionsFromSource?: boolean;
  deleteExtraSectionsFromDest?: boolean;
}

/**
 * For every section in destination, merge properties from corresponding section in source. Properties in destination
 * overwrite properties in source.
 *
 * By default, sections in the source but not in the destination are not copied from source > destination. This can be
 * overridden by passing opts.addMissingSectionsFromSource = true.
 *
 * By default, sections existing in dest but not in source are not removed from dest. This can be overwridden by passing
 * opts.deleteExtraSectionsFromDest = true
 *
 * Imagine these arrays:
 *  dest = [B, C]
 *  source = [A, B]
 *
 * By default, The resulting array will contain [B, C], with B enriched by the source.
 * if opts.deleteExtraSectionsFromDest = true, then the resulting array will only contain B, still enriched by source.
 * if opts.addMissingSectionsFromSource = true, then the resulting array will be [A, B, C], with B enriched by source
 * and A as a copy.
 *
 * @param destination
 * @param source
 * @param keyFn
 * @param opts addMissingSections
 */
export function enrichVtoSections(
  destination: VtoSectionSettings[],
  source: VtoSectionSettings[],
  keyFn: (VtoSectionSettings) => string,
  opts?: EnrichmentOptions
): VtoSectionSettings[] {
  const sourceSections = source.map(s => keyFn(s));
  const destSections = destination.map(d => keyFn(d));
  const sectionKeys = new Set([...sourceSections, ...destSections]);

  const mergedSections = [];
  for (const key of sectionKeys) {
    const sourceSection = source.find(s => keyFn(s) === key);
    const destSection = destination.find(d => keyFn(d) === key);

    // Enhance Sections
    if (sourceSection && destSection) {
      const mergedSection = { ...sourceSection, ...destSection };
      mergedSections.push(mergedSection);
      continue;
    }

    // Create new sections from source
    if (sourceSection) {
      // && !destSection
      if (opts?.addMissingSectionsFromSource) {
        const newSection = { ...sourceSection };
        mergedSections.push(newSection);
      }

      continue;
    }
    // !sourceSection must be true

    // Drop sections missing from dest
    if (opts?.deleteExtraSectionsFromDest) continue;

    // Pass through destination section without enhancement
    const copy = { ...destSection };
    mergedSections.push(copy);
  }

  return mergedSections;
}

export function enrichCustomVtoSections(
  destination: VtoCustomSectionSettings[],
  source: VtoCustomSectionSettings[],
  opts?: EnrichmentOptions
): VtoCustomSectionSettings[] {
  return enrichVtoSections(destination, source, (s: VtoSectionSettings) => s._id, opts) as VtoCustomSectionSettings[];
}

export function enrichDefaultVtoSections(
  destination: VtoSectionSettings[],
  source: VtoSectionSettings[],
  opts?: EnrichmentOptions
): VtoSectionSettings[] {
  return enrichVtoSections(destination, source, (s: VtoSectionSettings) => s.section, opts);
}
