import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { DataImportService } from '@ninety/data-import/services/data-import.service';

@Component({
  selector: 'ninety-one-schema-import',
  template: `<ninety-card class="one-schema-card">
    <iframe #oneSchema title="one-schema-iframe"></iframe>
  </ninety-card>`,
  styles: [
    `
      .one-schema-card {
        overflow: hidden;
        height: calc(100vh - 250px);
        position: relative;
        margin: 24px 24px 75px;
        iframe {
          border: none;
        }
      }
    `,
  ],
})
export class OneSchemaImportComponent implements AfterViewInit {
  @ViewChild('oneSchema') iframe?: ElementRef<HTMLIFrameElement>;

  constructor(private dataImportService: DataImportService) {}

  ngAfterViewInit() {
    this.dataImportService.setIframe(this.iframe?.nativeElement);
  }
}
