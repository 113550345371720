export class PageViews {
  issues?: number;
  rocks?: number;
  scorecard?: number;
  vision?: number;
  responsibilitiesChart?: number;
  userSettings?: number;
}

export enum Page {
  issues = 'issues',
  rocks = 'rocks',
  scorecard = 'scorecard',
  vision = 'vision',
  responsibilitiesChart = 'responsibilitiesChart',
  userSettings = 'userSettings',
}
