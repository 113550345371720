import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';

import { BusinessOperatingSystem } from '../../_shared/models/company/business-operating-system.enum';
import { CompanyLanguage, CustomLanguage } from '../../_shared/models/language/custom-language';
import { SpinnerAndCatchError } from '../decorators/spinner-and-catch-error';

import { ErrorService } from './error.service';
import { SpinnerService } from './spinner.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly languageApi = 'api.qa1.90srv.com/Language';

  // used more for partner hub to cache default languages coaches have access to
  private defaultLanguagesByBos: Partial<Record<keyof typeof BusinessOperatingSystem, CustomLanguage>> = {};

  constructor(
    private http: HttpClient,
    private store: Store,
    private stateService: StateService,
    private _spinnerService: SpinnerService,
    private errorService: ErrorService
  ) {}

  @SpinnerAndCatchError
  updateCustomLanguage(language: CompanyLanguage, bos: BusinessOperatingSystem): Observable<void> {
    return this.getBosDefaultLanguage(bos).pipe(
      switchMap(bosDefaultLanguage => {
        const customLanguage = this.filterOutDefaultLanguage(language, bosDefaultLanguage);
        return this.http
          .put<void>(`${this.languageApi}/Company`, customLanguage)
          .pipe(catchError(() => this.errorService.notify(`Could not update custom language.  Please try again.`)));
      })
    );
  }

  @SpinnerAndCatchError
  fetchCompanyResetLanguage(companyId?: string): Observable<CustomLanguage> {
    return this.http.get<CustomLanguage>(`${this.languageApi}/Reset/${companyId}`);
  }

  getBosDefaultLanguage(bos?: BusinessOperatingSystem): Observable<CustomLanguage> {
    // if already fetched bos language, return it
    if (this.defaultLanguagesByBos[bos]) return of(this.defaultLanguagesByBos[bos]);

    // fetch default language for this bos and save it to cache
    return this.http
      .get<CustomLanguage>(`${this.languageApi}/BOS/${bos}`)
      .pipe(tap(dL => (this.defaultLanguagesByBos[bos] = dL)));
  }

  filterOutDefaultLanguage(
    { companyId, ...language }: CompanyLanguage,
    defaultLanguage: CustomLanguage
  ): Partial<CompanyLanguage> {
    // we only save what's different from the default language
    const customLanguage: CompanyLanguage = _cloneDeep(language);
    Object.keys(defaultLanguage).forEach(k => {
      Object.keys(defaultLanguage[k]).forEach(p => {
        if (customLanguage[k][p] === defaultLanguage[k][p]) delete customLanguage[k][p];
      });
      if (_isEmpty(customLanguage[k])) delete customLanguage[k];
    });
    if (companyId) customLanguage.companyId = companyId;
    return customLanguage;
  }
}
