import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { TeamListModel } from './api/team-list.model';

export const teamListStateActions = createActionGroup({
  source: 'Team List',
  events: {
    'Get Team List': emptyProps(),
    'Get Team List Success': props<{ teams: TeamListModel[] }>(),
  },
});
