import { TerraIconName } from '@ninety/terra';

import { CustomLanguage } from '../language/custom-language';
import { MeetingSection } from '../meetings/meeting-section';

// available default sections for custom meetings
export function getCustomMeetingDefaultSections(
  language: CustomLanguage
): Array<MeetingSection & { terraIcon: TerraIconName }> {
  return [
    {
      name: 'Segue',
      isDefault: true,
      terraIcon: 'handshake',
      path: 'segue',
      duration: 300,
      isHidden: false,
      isCustom: false,
      isEditable: true,
      subtitle: '',
      details: '',
    },
    {
      name: language.scorecard.route,
      isDefault: true,
      terraIcon: 'data',
      path: 'data',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: language.rock.route,
      isDefault: true,
      terraIcon: 'rocks',
      path: 'rocks',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: language.headline.items,
      isDefault: true,
      terraIcon: 'headlines',
      path: 'headlines',
      duration: 300,
      isHidden: false,
      isCustom: false,
      isEditable: true,
      subtitle: '',
      details: '',
    },
    {
      name: language.todo.route,
      isDefault: true,
      terraIcon: 'to-dos',
      path: 'todos',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: language.issue.route,
      isDefault: true,
      terraIcon: 'issues',
      path: 'issues',
      duration: 3600,
      isHidden: false,
      isCustom: false,
    },
    {
      name: language.vto.route,
      isDefault: true,
      terraIcon: 'vision',
      path: 'vision',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: `${language.vto.route} - ${language.measurable.goals}`,
      isDefault: true,
      terraIcon: 'vision',
      path: 'vision#goals',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: `${language.vto.route} - ${language.issue.longTerm}`,
      isDefault: true,
      terraIcon: 'vision',
      path: 'vision#long-term',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: `${language.vto.route} - ${language.vto.swot}`,
      isDefault: true,
      terraIcon: 'vision',
      path: 'vision#swot',
      duration: 300,
      isHidden: false,
      isCustom: false,
    },
    {
      name: language.mastery.route,
      isDefault: true,
      terraIcon: 'mastery',
      path: 'mastery',
      duration: 3600,
      isHidden: false,
      isCustom: false,
    },
  ];
}
