import { createActionGroup, props } from '@ngrx/store';

import type { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';

export const RocksActions = createActionGroup({
  source: 'Rocks',
  events: {
    'Create Rock': props<{ rock: Rock }>(),
    'Update Rock Status': props<{ rock: Rock }>(),
  },
});
