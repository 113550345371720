<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="issues" />
      <span data-cy="issue-language_card-title">{{ issue.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="issue">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="issue.route + ' Page Name'"
          data-cy="issue-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="issue-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ issue.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="issue.item"
          data-cy="issue-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="issue-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ issue.item }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="issue.items"
          data-cy="issue-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="issue-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ issue.shortTerm }}:</mat-label>
        <input
          #shortTerm
          matInput
          formControlName="shortTerm"
          required
          [attr.aria-label]="issue.shortTerm"
          data-cy="issue-language_short-term-input-field" />
        <ninety-reset-btn
          *ngIf="shortTerm.value !== resetLanguage?.shortTerm"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('shortTerm')"
          [disabled]="readonly"
          data-cy="issue-language_short-term-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ issue.longTerm }}:</mat-label>
        <input
          #longTerm
          matInput
          formControlName="longTerm"
          required
          [attr.aria-label]="issue.longTerm"
          data-cy="issue-language_long-term-input-field" />
        <ninety-reset-btn
          *ngIf="longTerm.value !== resetLanguage?.longTerm"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('longTerm')"
          [disabled]="readonly"
          data-cy="issue-language_long-term-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
