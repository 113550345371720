import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { VtoSectionKeys, VtoSectionSettings } from '@ninety/ui/legacy/shared/models/vto/vto-sections';

export type SectionFilterFunction = (setting: VtoSectionSettings) => boolean;

/**
 * Functions to filter the sections shown to the user based on BOS/flags.
 *
 * The correct filter can be extracted from the Store. See {@link VtoSelectors}
 */
export namespace SectionFilteringLogic {
  const pinnacleOnlySections: ReadonlyArray<VtoSectionKeys> = [
    'purposeWhyWeExist',
    'coreBusiness',
    'pinnacleTopOfMountain',
    'quarterlyTheme',
    'flywheelDesign',
    'oneLineMarketingPhrase',
    'brandPromises',
  ];

  const sectionsNotInNinety: ReadonlySet<VtoSectionKeys> = new Set([
    'issuesList',
    'coreFocus',
    ...pinnacleOnlySections,
  ]);
  const sectionsNotInEOS: ReadonlySet<VtoSectionKeys> = new Set(['purpose', 'niche', ...pinnacleOnlySections]);
  const sectionsNotInPinnacle: ReadonlySet<VtoSectionKeys> = new Set([
    'issuesList',
    'coreFocus',
    'purpose',
    'niche',
    'marketingStrategy',
    'tenYear',
  ]);

  export const reduceTo: Readonly<Partial<Record<BusinessOperatingSystem, SectionFilterFunction>>> = {
    [BusinessOperatingSystem.ninetyOS]: setting => !sectionsNotInNinety.has(setting.section),
    [BusinessOperatingSystem.eos]: setting => !sectionsNotInEOS.has(setting.section),
    [BusinessOperatingSystem.pinnacle]: setting => !sectionsNotInPinnacle.has(setting.section),
  };

  export function forBos(bos: BusinessOperatingSystem): SectionFilterFunction {
    return reduceTo[bos] ?? reduceTo[BusinessOperatingSystem.ninetyOS];
  }
}
