import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { mergeMap, Observable, of, switchMap } from 'rxjs';

import { LearningActionToolbarActions } from '@ninety/learning-modules/_state/learning-action-toolbar-actions';
import { ComponentCanDeactivate } from '@ninety/ui/legacy/core/guards/pending-changes.guard';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
@Injectable({
  providedIn: 'root',
})
export class LearningModuleGuard {
  constructor(
    public stateService: StateService,
    private featureFlags: FeatureFlagFacade,
    private router: Router,
    private dialog: MatDialog,
    private store: Store
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return of(true);
  }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    return component.canDeactivate().pipe(
      switchMap(canDeactivate => {
        if (canDeactivate) return of(canDeactivate);
        const confirmed = this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            autoFocus: false,
            disableClose: true,
            width: '450px',
            maxWidth: 450,
            data: {
              title: 'Save Changes?',
              message: `There are some unsaved changes. Do you want to save them?`,
              confirmButtonText: 'Save',
              cancelButtonText: "Don't Save",
            },
          })
          .afterClosed()
          .pipe(
            mergeMap((confirmed: boolean) => {
              if (!confirmed) {
                this.store.dispatch(LearningActionToolbarActions.resetToDefault());
              } else {
                this.store.dispatch(LearningActionToolbarActions.saveLearningItem({ navBackAfterSaving: false }));
              }
              return of(true);
            })
          );

        history.pushState(null, '', '');

        return confirmed;
      })
    );
  }
}
