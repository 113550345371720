import { Pipe, PipeTransform } from '@angular/core';

import { FilterPredicate } from '../models/_shared/filter-predicate';

@Pipe({
  name: 'filterReducer',
})
export class FilterReducerPipe implements PipeTransform {
  transform<T>(collection: T[], predicates: FilterPredicate<T>[] = []): T[] {
    if (!predicates.length) {
      return collection;
    }

    return collection.filter(elem => predicates.every(predicate => predicate(elem)));
  }
}
