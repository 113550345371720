<button
  class="terra-icon-option"
  role="option"
  tabindex="-1"
  [disabled]="disabled"
  (click)="_onClick($event)"
  [attr.id]="optionId"
  [attr.aria-label]="ariaLabel"
  [ngClass]="{
    'terra-icon-option--selected': _selected || _highlighted
  }">
  <ng-container *ngIf="_iconTemplate" [ngTemplateOutlet]="_iconTemplate" />
</button>
