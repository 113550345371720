import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { map, tap } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';

import { TopToolbarActions } from './top-toolbar-state.actions';

/** RELATED TO: DEV-6149 & DEV-8210
 *
 * NOTES:
 *     We may want to use this in the near future.
 *
 *     It was intended to be a measure between what we have now and a new detail view.
 *
 *     If we have a new detail view and no longer need this at all, then
 *     everything from the following PR/commit can be removed as dead code.
 *
 * BB PR: https://bitbucket.org/traxion/ng-ninety/pull-requests/7180
 *
 * COMMIT HASH: 0ac9e2eabffb2f0a8ef0c266f96c350ac24d3d2e
 *
 * */
const urlsToHideTopToolbarShadow = [
  '/home',
  '/data',
  '/rocks',
  '/todos',
  '/issues/short-term',
  '/issues/long-term',
  '/meetings/level10',
  '/meetings/quarterly',
  '/meetings/annual',
  '/meetings/other',
  '/meeting/',
  '/headlines',
  '/vision',
  '/1-on-1',
  '/process',
  '/directory',
  '/mastery',
  '/chart',
  '/assessments',
];
const urlsToOverride = ['/rocks/']; // override for deep link

const hideTopToolbarShadow = (url: string) =>
  urlsToHideTopToolbarShadow.some(_url => url.includes(_url) && !urlsToOverride.some(_url => url.includes(_url)));

@Injectable()
export class TopToolbarEffects {
  hideTopToolbarShadow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: RouterNavigatedAction) =>
        hideTopToolbarShadow(action.payload.routerState.url)
          ? TopToolbarActions.hideToolbarShadow()
          : TopToolbarActions.showToolbarShadow()
      )
    )
  );

  /** temporary effect to set the page title */
  setPageTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TopToolbarActions.setPageTitle),
        tap(({ pageTitle }) => this.stateService.pageTitle$.next(pageTitle))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private stateService: StateService) {}
}
