import { Injectable } from '@angular/core';

import { RockStatus } from '../../_shared/models/rocks/rock-status';
import { RockStatusCode } from '../../_shared/models/rocks/rock-status-code';

@Injectable({
  providedIn: 'root',
})
export class RockStatusService {
  offTrack = RockStatusCode.offTrack;
  onTrack = RockStatusCode.onTrack;
  complete = RockStatusCode.complete;

  private '0000': RockStatus = {
    label: 'Off-track',
    code: RockStatusCode.offTrack,
    icon: 'warning-triangle',
    class: 'off-track-icon',
  };
  private '0001': RockStatus = {
    label: 'On-track',
    code: RockStatusCode.onTrack,
    icon: 'thumbs-up',
    class: 'on-track-icon',
  };
  private '0002': RockStatus = {
    label: 'Complete',
    code: RockStatusCode.complete,
    icon: 'check-circle',
    class: 'complete-icon',
  };

  readonly statuses: RockStatus[] = [this['0000'], this['0001'], this['0002']];

  getStatus(status: string): RockStatus {
    return this[status];
  }
}
