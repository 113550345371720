import { differenceInCalendarDays } from 'date-fns';

/**
 * NOTE: This function will return an ABSOLUTE interval.
 * @param startDate
 * @param endDate
 * @returns number of days between the start date and the end date.
 */
export function getDayDiff(startDate: Date, endDate: Date): number {
  return differenceInCalendarDays(endDate, startDate);
}
/**
 * NOTE: This function will return an ABSOLUTE interval.
 * @param toDate
 * @returns number of days between now and the toDate.
 */
export function getDayDiffFromNow(toDate: Date): number {
  return differenceInCalendarDays(toDate, new Date());
}
