import { Pipe, PipeTransform } from '@angular/core';
export interface ItemModel {
  _id: string;
  [key: string]: any;
}

@Pipe({
  name: 'filterById',
})
export class FilterByIdPipe implements PipeTransform {
  transform<T>(array: T[], id: string): T[] {
    if (!array || array.length === 0 || !id || id === 'all') {
      return array;
    }
    return array.filter(item => (item as unknown as ItemModel)._id === id);
  }
}
