export enum IssueMessageType {
  issue = 'issue',
  new = 'new-issue',
  delete = 'remove-issue',
  sentBack = 'issue-sent-back',
  sentToOtherTeam = 'issue-sent-to-other-team',
  unarchive = 'unarchive-issue',
  vote = 'vote-item',
  fetch = 'fetch-object',
  sort = 'list-sort-issue',
  move = 'move-issue',
  topThree = 'top-three',
  comment = 'comment',
  commentDelete = 'comment-delete',
}
