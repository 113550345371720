import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

export type TerraCounterColor = 'light' | 'standard' | 'brand' | 'green' | 'red';

@Component({
  selector: 'terra-counter',
  standalone: true,
  exportAs: 'terraCounter',
  imports: [CommonModule],
  templateUrl: './terra-counter.component.html',
  styleUrls: ['./terra-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.aria-hidden]': 'true',
  },
})
export class TerraCounterComponent implements OnInit {
  /**
   * Reference to the wrapping div element where classes will be added to
   */
  @ViewChild('counter', { static: true }) private _counter!: ElementRef<HTMLElement>;

  /**
   * Color of the counter - light | standard | brand | green | red
   * @default standard
   */
  @Input() get color(): TerraCounterColor {
    return this._color;
  }
  set color(value: TerraCounterColor) {
    this._removeClass('color', this._color);
    this._color = value;
    this._addClass('color', this._color);
    this._changeDetectorRef.markForCheck();
  }
  private _color: TerraCounterColor = 'standard';

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef, private readonly _renderer: Renderer2) {}

  ngOnInit() {
    this._addClass('color', this.color);
  }

  /**
   * Helper function for adding proper styles to the counter
   */
  private _addClass(modifier: string, value: string): void {
    if (this._counter) {
      this._renderer.addClass(this._counter.nativeElement, `terra-counter--${modifier}-${value}`);
    }
  }

  /**
   * Helper function to remove previously added classes from the counter
   */
  private _removeClass(modifier: string, value: string): void {
    if (this._counter) {
      this._renderer.removeClass(this._counter.nativeElement, `terra-counter--${modifier}-${value}`);
    }
  }
}
