import { MazMessage } from '@ninety/getting-started/maz/models/maz-message';

export interface MazState {
  mazChatExpanded: boolean;
  mazMessages: MazMessage[];
  waitingForMazResponse: boolean;
  commonQuestions: string[];
}

export const INITIAL_MAZ_STATE: MazState = {
  mazChatExpanded: false,
  mazMessages: [],
  waitingForMazResponse: false,
  commonQuestions: [],
};
