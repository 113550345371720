import { TodoSortField } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';

/** Properties of Todos that have matching columns in the table. */
export type TodoTableColumn = TodoSortField | 'contextMenu';

/** PersonalTodoTable columns in any context */
export const PERSONAL_TODO_TABLE_COLS: ReadonlyArray<TodoTableColumn> = ['title', 'dueDate', 'contextMenu'];

export const PERSONAL_COMPLETED_TODOS_TAB_TABLE_COLS: ReadonlyArray<TodoTableColumn> = [
  'title',
  'completedDate',
  'dueDate',
  'contextMenu',
];

/** TeamTodosTable columns when used on My90 (owner name is irrelevant there - it's always just your todos) */
export const USERS_TEAM_TODO_TABLE_COLS: ReadonlyArray<TodoTableColumn> = [
  'title',
  'dueDate',
  'teamName',
  'contextMenu',
];

/** TeamTodosTable columns when used on the Todos page */
export const TEAM_TODO_TABLE_COLS: ReadonlyArray<TodoTableColumn> = ['title', 'dueDate', 'ownerName', 'contextMenu'];
