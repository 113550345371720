import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

import { User } from '../models/_shared/user';
import { Seat } from '../models/accountability-chart/seat';

@Pipe({
  name: 'filterUsersByTeam',
})
export class FilterUsersByTeamPipe implements PipeTransform {
  transform(users: (User & { seats?: Seat[] })[], teamId: string): any {
    if (!users || !teamId || teamId === 'all') return users;
    return filter(users, user => {
      const teams = user.teams.map(team => team.teamId);
      return teams.includes(teamId);
    });
  }
}
