import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ninety-color-picker',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  template: ` <div class="color-input-wrapper">
    <h4 class="color-title">{{ colorKey }}</h4>
    <input
      #colorInput
      type="color"
      [value]="color"
      (blur)="colorChange.emit(colorInput.value)"
      [disabled]="disabled"
      attr.data-cy="custom-colors_{{ colorKey }}-input-field" />
  </div>`,
  styles: [
    `
      @use 'terra';
      .color-input-wrapper {
        text-align: center;
      }

      .color-title {
        text-transform: capitalize;
        @include terra.text(body-small);
        color: terra.color('text', 'light');
        margin: 0 0 8px 0;
      }

      input {
        height: 60px;
        width: 60px;
        @include terra.radius(small);
        &::-webkit-color-swatch {
          @include terra.radius(small);
        }
        border: 1px solid terra.color('border');
        box-shadow: terra.elevation(5) !important;
        background-color: terra.color('neutral-shader');
        &:not([disabled]):hover {
          cursor: pointer;
        }
      }
    `,
  ],
})
export class ColorPickerComponent {
  @Input() color: string;
  @Input() colorKey?: 'primary' | 'secondary';
  @Input() disabled: boolean;

  @Output() colorChange = new EventEmitter<string>();
}
