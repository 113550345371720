import { ValidationError } from '../../../_shared/models/errors/validation-error';

/**
 * ZoomScale is a value object that represents an immutable zoom transformation.
 *
 * It can be thought of as a number with utilities to transform it. If the transformations of zoomIn and zoomOut do not
 * fit your use-case, extend this class and provide it to the tree.
 *
 * @see [value object pattern](https://martinfowler.com/bliki/ValueObject.html)
 */
export class ZoomScale {
  public static readonly IDENTITY = new ZoomScale(1, 0.5, 0.1, 0.1);

  constructor(
    /** The current zoom scale */
    public readonly scale: number = ZoomScale.IDENTITY.scale,
    /** The minimum zoom scale */
    public readonly minScale: number = ZoomScale.IDENTITY.minScale,
    /** The increment to zoom in by */
    public readonly zoomInIncrement: number = ZoomScale.IDENTITY.zoomInIncrement,
    /** The increment to zoom out by */
    public readonly zoomOutIncrement: number = ZoomScale.IDENTITY.zoomOutIncrement
  ) {
    if (scale < minScale) {
      throw ValidationError.fromFailedValidation(scale, `scale must be greater than or equal to ${minScale}`);
    }
  }

  /** Zoom in by a specified amount */
  public zoomIn(): ZoomScale {
    return this.withScale(this.scale + this.zoomInIncrement);
  }

  public canZoomIn(): boolean {
    return true;
  }

  /** Zoom out by a specified amount */
  public zoomOut(): ZoomScale {
    return this.canZoomOut() ? this.withScale(this.scale - this.zoomOutIncrement) : this;
  }

  public canZoomOut(): boolean {
    const futureScale = this.scale - this.zoomOutIncrement;
    return futureScale > this.minScale;
  }

  /** Reset the zoom to the default */
  public reset(): ZoomScale {
    return this.withScale(ZoomScale.IDENTITY.scale);
  }

  /** Set the zoom to a specified amount, maintaining other properties */
  public withScale(scale: number): ZoomScale {
    return new ZoomScale(scale, this.minScale, this.zoomInIncrement, this.zoomOutIncrement);
  }

  /** Scale a number by the current scale */
  public scaleValue(number: number): number {
    return number * this.scale;
  }

  /** Transform the scale into a string for use in the transform property */
  public asTransform(): string {
    return `scale(${this.scale})`;
  }

  public toString(): string {
    return `ZoomScale(${this.scale})`;
  }

  public equals(other: ZoomScale): boolean {
    return this.scale === other?.scale;
  }
}
