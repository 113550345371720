export enum StripeSubscriptionStatuses {
  Active = 'active',
  Trialing = 'trialing',
  PastDue = 'past_due',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Unpaid = 'unpaid',
  Canceled = 'canceled',
  Paused = 'paused',
}
