import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameValue',
})
export class NameValuePipe implements PipeTransform {
  transform(obj: object, args?: string[]): NameValueObj[] {
    const nameValuePairsArr = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        nameValuePairsArr.push({ name: key, value: obj[key] });
      }
    }
    return nameValuePairsArr;
  }
}

export interface NameValueObj {
  name: string;
  value: any;
}
