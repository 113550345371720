import { createActionGroup, props } from '@ngrx/store';

import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { Conversation, ListViewConversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { FormalConversationQuestion } from '@ninety/ui/legacy/shared/models/feedback/formal-conversation-question';

export const FeedbackApiActions = createActionGroup({
  source: 'Feedback API Service',
  events: {
    'Conversation List Received': props<{ conversations: PagedResponse<ListViewConversation> }>(),
    'Unable to Retrieve Conversation List': props<{ error: any; customMessage: string }>(),
    'Conversation Created': props<{ conversations: ListViewConversation[]; createTodos: boolean }>(),
    'Unable to Create Conversation': props<{ error: any; customMessage: string }>(),
    'Conversation Status Updated': props<{ conversation: Conversation }>(),
    'Unable to Update Conversation Status': props<{ error: any; customMessage: string }>(),
    'Conversation Deleted': props<{ conversation: Conversation }>(),
    'Unable to Delete Conversation': props<{ error: any; customMessage: string }>(),
    'Conversation Successfully Edited': props<{ conversation: Conversation }>(),
    'Unable to Edit Conversation': props<{ error: any; customMessage: string }>(),
    'Quarterly Questions Succesfully Updated': props<{ quarterlyQuestions: FormalConversationQuestion[] }>(),
    'Unable to Update Quarterly Questions': props<{ error: any; customMessage: string }>(),
  },
});
