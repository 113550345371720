import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraFormLabelComponent } from './terra-form-label.component';

@NgModule({
  imports: [CommonModule, TerraFormLabelComponent],
  exports: [TerraFormLabelComponent],
})
export class TerraFormLabelModule {}
