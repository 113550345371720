import { Injectable } from '@angular/core';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { LicenseUserModel } from '@ninety/ui/legacy/shared/models/_shared/license-user-model';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';

@Injectable()
export class BillingUserMapperService {
  constructor(private stateService: StateService) {}
  fromApi(source: User, rolesToMap: RoleSelectOption[], numberOfOwners: number): LicenseUserModel {
    return {
      _id: source._id,
      fullName: source.metadata?.fullName,
      roleCode: source.roleCode,
      role: rolesToMap.find(
        (role: RoleSelectOption) => role.roleCode === source.roleCode && role.isImplementer === !!source.isImplementer
      ),
      shouldAssignUserRoleBeDisabled:
        (!this.stateService.isOwner && source.roleCode == RoleCode.owner) ||
        (this.stateService.isOwner && source.roleCode === RoleCode.owner && numberOfOwners <= 1) ||
        this.stateService.currentUser._id === source._id,
    };
  }
}
