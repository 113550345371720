<ninety-input-wrapper class="input-wrapper">
  <terra-icon icon="search" class="search-icon"></terra-icon>
  <input #search (input)="onSearchChange(search.value)" type="text" placeholder="Search for teams..." ninetyInput />
  <ninety-button
    data-cy="team-search_clear-search"
    *ngIf="search.value.length > 0"
    flat
    icon
    class="clear-search"
    (click)="search.value = ''; clearSearch()"
    ninetyInputSuffix>
    <terra-icon icon="x" size="20"></terra-icon>
  </ninety-button>
</ninety-input-wrapper>
