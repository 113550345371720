import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, filter, from, map, of, switchMap, tap } from 'rxjs';

import { AuthService } from '../../../_core/services/auth.service';
import { ErrorService } from '../../../_core/services/error.service';
import { CognitoMfaType, IdentityProviderService } from '../../../_core/services/identity-provider.service';
import { NotifyService } from '../../../_core/services/notify.service';
import { SpinnerService } from '../../../_core/services/spinner.service';
import { ErrorMessages } from '../../../_shared/models/enums/errors';
import { ForgotPasswordStatus } from '../../models/enums/forgot-password-status';
import {
  ConfirmIdentityDialogComponent,
  ConfirmIdentityDialogData,
  ConfirmIdentityDialogStatus,
} from '../confirm-identity-dialog/confirm-identity-dialog.component';
import {
  ForgotPasswordSubmitDialogComponent,
  ForgotPasswordSubmitDialogData,
} from '../forgot-password-submit-dialog/forgot-password-submit-dialog.component';
export interface ForgotPasswordDialogData {
  email: string;
  disableEmailEdit?: boolean;
}

@Component({
  selector: 'ninety-forgot-password-dialog',
  templateUrl: 'forgot-password-dialog.component.html',
  styleUrls: ['forgot-password-dialog.component.scss'],
})
export class ForgotPasswordDialogComponent implements OnInit, OnDestroy {
  emailControl: UntypedFormControl;
  ErrorMessages = ErrorMessages;
  disableEmailEdit: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordDialogData,
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent, void>,
    public dialog: MatDialog,
    public router: Router,
    public authService: AuthService,
    public idpService: IdentityProviderService,
    public notifyService: NotifyService,
    public spinnerService: SpinnerService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.disableEmailEdit = this.data.disableEmailEdit || false;
    this.emailControl = new UntypedFormControl({ value: this.data.email, disabled: this.disableEmailEdit }, [
      Validators.required,
      Validators.email,
    ]);
  }

  ngOnDestroy() {
    this.emailControl.reset();
  }

  forgotPassword() {
    const email = this.emailControl.value?.trim();
    this.spinnerService.start();
    this.authService
      .forgotPassword(email)
      .pipe(
        tap(_ => this.spinnerService.stop()),
        filter(result => !!result),
        switchMap(status => {
          switch (status) {
            case ForgotPasswordStatus.FORGOT_PASSWORD:
              return this.openForgotPasswordSubmitDialog(email);
            case ForgotPasswordStatus.NONEXISTENT:
              this.notifyService.showError('Could not find a user for that email, please sign up!', 'Signup Required');
              return from(this.router.navigate(['/sign-up'], { queryParams: { email } }));
            case ForgotPasswordStatus.INVITED:
              this.notifyService.notify('Please check your email for a sign-up invite link', 20000);
              return of(null);
            case ForgotPasswordStatus.VERIFY_IDENTITY:
              return this.openConfirmIdentityDialog(email).pipe(
                switchMap(_ => this.openForgotPasswordSubmitDialog(email))
              );
          }
        })
      )
      .subscribe({
        next: () => {
          this.spinnerService.stop();
          this.dialogRef.close();
        },
        error: (err: unknown) => {
          this.errorService.notify(err, 'Something went wrong. Please try again.');
          this.spinnerService.stop();
        },
      });
  }

  openForgotPasswordSubmitDialog(email: string): Observable<void> {
    return this.dialog
      .open<ForgotPasswordSubmitDialogComponent, ForgotPasswordSubmitDialogData, null>(
        ForgotPasswordSubmitDialogComponent,
        {
          width: '500px',
          disableClose: true,
          data: { email },
        }
      )
      .afterClosed();
  }

  openConfirmIdentityDialog(email: string, isMfa = false): Observable<{ closedFromBtn: true } | string | void> {
    this.spinnerService.stop();
    return this.dialog
      .open<
        ConfirmIdentityDialogComponent,
        ConfirmIdentityDialogData,
        ConfirmIdentityDialogStatus | string | void | { closedFromBtn: true }
      >(ConfirmIdentityDialogComponent, {
        disableClose: true,
        data: {
          email,
          status: isMfa ? ConfirmIdentityDialogStatus.ChallengeMFA : ConfirmIdentityDialogStatus.VerifyEmail,
          showCloseButton: !!isMfa,
        },
      })
      .afterClosed()
      .pipe(
        switchMap(resp =>
          isMfa && (resp as { closedFromBtn: boolean })?.closedFromBtn
            ? this.idpService.setMfaType(CognitoMfaType.NONE).pipe(
                map(() => {
                  throw new Error('MFA not verified');
                })
              )
            : of(resp)
        )
      );
  }
}
