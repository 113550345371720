import { createReducer, on } from '@ngrx/store';

import { PoliciesActions } from './policies.actions';
import { PoliciesStateModel, initialPoliciesState } from './policies.model';

export const policiesStateReducer = createReducer(
  initialPoliciesState,
  on(
    PoliciesActions.privacyPolicyReceived,
    (state, { privacyPolicy }): PoliciesStateModel => ({
      ...state,
      privacyPolicy,
    })
  )
);
