import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormControl, Validators, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import isEmpty from 'lodash/isEmpty';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AuthService } from '../../../_core/services/auth.service';
import { HelperService } from '../../../_core/services/helper.service';
import { NotifyService } from '../../../_core/services/notify.service';
import { SpinnerService } from '../../../_core/services/spinner.service';
import { NinetyValidators } from '../../validators/ninety-validators';

type ForgotPasswordSubmitValidationErrors = {
  missingCode?: true;
  missingNewPassword?: true;
  missingNewConfirmPassword?: true;
  passwordMismatch?: true;
  passwordLength?: true;
  missingLowerAlpha?: true;
  missingNumeric?: true;
  missingSpecial?: true;
  invalidChars?: string;
} & ValidationErrors;

export interface ForgotPasswordSubmitDialogData {
  email: string;
}

@Component({
  selector: 'ninety-forgot-password-submit-dialog',
  templateUrl: './forgot-password-submit-dialog.component.html',
  styleUrls: ['./forgot-password-submit-dialog.component.scss'],
})
export class ForgotPasswordSubmitDialogComponent implements OnDestroy, OnInit {
  subscriptions = new Subscription();

  email: string;
  expiredCode: string;
  isCodeExpired = false;
  codeControl = new UntypedFormControl('', [Validators.required, NinetyValidators.noWhiteSpace]);
  newPasswordControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(HelperService.Regex.validAwsPassword),
  ]);
  confirmNewPasswordControl = new UntypedFormControl('', Validators.required);
  forgotPasswordSubmitGroup = new UntypedFormGroup(
    {
      codeControl: this.codeControl,
      newPasswordControl: this.newPasswordControl,
      confirmNewPasswordControl: this.confirmNewPasswordControl,
    },
    { validators: this.validateForgotPasswordSubmitGroup }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordSubmitDialogData,
    public dialogRef: MatDialogRef<ForgotPasswordSubmitDialogComponent, boolean>,
    public spinnerService: SpinnerService,
    private authService: AuthService,
    private notifyService: NotifyService
  ) {}

  ngOnInit() {
    this.email = this.data.email;

    this.subscriptions.add(
      this.codeControl.valueChanges.pipe(debounceTime(100)).subscribe({
        next: value => this.codeControl.setValue(typeof value === 'string' ? value.trim() : value),
      })
    );
  }

  ngOnDestroy(): void {
    this.email = '';
    this.forgotPasswordSubmitGroup.reset({
      codeControl: '',
      newPasswordControl: '',
      confirmPasswordControl: '',
    });
    this.subscriptions.unsubscribe();
  }

  forgotPasswordSubmit() {
    this.spinnerService.start();
    this.authService
      .forgotPasswordSubmit({
        email: this.email,
        code: this.codeControl.value,
        newPassword: this.newPasswordControl.value,
      })
      .subscribe({
        next: resp => {
          this.spinnerService.stop();
          this.dialogRef.close(true);
          this.notifyService.notify('Password successfully changed!', 5000);
        },
        error: (err: { error?: { errorMessage?: 'ExpiredCodeException' | 'AttemptLimitExceeded' | string } }) => {
          console.error('error thing', err);
          this.spinnerService.stop();
          switch (err?.error?.errorMessage) {
            case 'ExpiredCodeException':
              this.isCodeExpired = true;
              this.expiredCode = this.forgotPasswordSubmitGroup.get('codeControl').value;
              this.notifyService.showError(
                'Please request a new forgot password code, enter your most recent reset code, and try again.',
                'Expired Code'
              );
              break;
            case 'LimitExceededException':
              this.notifyService.showError(
                'Please wait some time before attempting to reset password again.  Enter your most recent reset code.',
                'Attempt Limit Exceeded'
              );
              break;
            default:
              this.notifyService.showError(
                'Failed to set new password. Make sure to enter your most recent reset code and please try again.'
              );
              break;
          }
          console.error(err);
        },
      });
  }

  sendForgotPasswordCode() {
    this.spinnerService.start();
    this.authService.forgotPassword(this.email).subscribe({
      next: resp => {
        this.isCodeExpired = false;
        this.spinnerService.stop();
      },
      error: (err: unknown) => {
        this.notifyService.showError('Could not send forgot password code.');
        this.spinnerService.stop();
      },
    });
  }

  validateForgotPasswordSubmitGroup(
    forgotPasswordSubmitGroup: UntypedFormGroup
  ): null | ForgotPasswordSubmitValidationErrors {
    const code: string = forgotPasswordSubmitGroup.get('codeControl').value;
    const newPassword: string = forgotPasswordSubmitGroup.get('newPasswordControl').value;
    const newConfirmPassword: string = forgotPasswordSubmitGroup.get('confirmNewPasswordControl').value;
    const errors = Object.create(null);

    if (!code) errors.missingCode = true;

    if (newPassword) {
      if (newConfirmPassword && newPassword !== newConfirmPassword) errors.passwordMismatch = true;
      if (newPassword.length < 8) errors.passwordLength = true;
      if (!HelperService.Regex.hasLowerAlpha.test(newPassword)) errors.missingLowerAlpha = true;
      if (!HelperService.Regex.hasNumeric.test(newPassword)) errors.missingNumeric = true;
      if (!HelperService.Regex.hasAwsPasswordSpecialChar.test(newPassword)) errors.missingSpecial = true;
      const invalidCharSet = new Set(newPassword.match(HelperService.Regex.matchAwsInvalidPasswordChar));
      if (invalidCharSet.size > 0) errors.invalidChars = Array.from(invalidCharSet).join('');
    }

    return isEmpty(errors) ? null : errors;
  }
}
