import { Person } from '../_shared/person';
import { BusinessOperatingSystem } from '../company/business-operating-system.enum';

import { PartnerGroup } from './_shared/partner-group';
import { PartnerTeamInfo } from './_shared/partner-team';
import { Stats } from './_shared/stats';
import { NewPartnerData } from './new-partner-form';
import { PartnerType } from './partner-type.enum';

export class Partner {
  partner_key: string; // person id
  first_name: string;
  last_name: string;
  email: string;

  // internal key used for most api's etc.
  key: string;

  // other partner stack properties
  created_at?: number;
  updated_at?: number;
  joined_at?: number;
  group?: PartnerGroup;
  team?: PartnerTeamInfo;
  manager_key?: string;
  meta?: object;
  stats?: Stats;

  // person aggregated for partner page
  person?: Person;

  // for creating partners
  partnerType?: PartnerType;
  group_key?: string;
  bosTypes?: BusinessOperatingSystem[];
  badPartnerKey?: boolean;

  constructor({ group_key, bosTypes, partnerType, ...data }: NewPartnerData) {
    Object.assign(this, data);
    if (group_key) this.group_key = group_key;
    if (bosTypes) this.bosTypes = bosTypes;
    if (partnerType) this.partnerType = partnerType;
  }
}
