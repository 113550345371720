import { createActionGroup, emptyProps } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Authentication',
  events: {
    /**
     * When the user authenticates through the login page
     */
    'Log in': emptyProps(),
    'Log out': emptyProps(),
  },
});
