import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ninety-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss'],
})
export class PrintDialogComponent implements OnInit {
  form: UntypedFormGroup;
  private isLandscapeDefault = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<PrintDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { isLandscapeDefault: boolean }
  ) {
    if (data) {
      this.isLandscapeDefault = data.isLandscapeDefault;
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      isLandscape: [this.isLandscapeDefault, Validators.required],
    });
  }
}
