import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { MeetingsAgendasV2Actions } from '@ninety/pages/meetings/_state/meetings.actions';
import { MeetingsStateSelectors } from '@ninety/pages/meetings/_state/meetings.selectors';
import { TerraIconModule } from '@ninety/terra';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/inputs/quick-filter/quick-filter.component';
import { ActionBarComponent } from '@ninety/ui/legacy/components/toolbars/action-bar/action-bar.component';
import { AddItemButtonComponent, Team } from '@ninety/ui/legacy/shared/index';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';
import { AgendaTableDataV2 } from '@ninety/ui/legacy/shared/models/meetings-v2/agenda-table-data-v2';
import { selectLanguage } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-agendas-v2',
  templateUrl: './agendas-v2.component.html',
  styleUrls: ['./agendas-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ActionBarComponent,
    QuickFilterComponent,
    LetDirective,
    CardModule,
    MatTableModule,
    AddItemButtonComponent,
    TerraIconModule,
  ],
})
export class AgendasV2Component {
  store = inject(Store);
  router = inject(Router);

  agendas$ = this.store.select(MeetingsStateSelectors.selectAgendas);
  teams$ = this.store.select(MeetingsStateSelectors.selectTeamFilterOptions);
  selectedTeam$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);

  language$ = this.store.select(selectLanguage);
  displayedColumns: string[] = ['frontSpacer', 'title', 'type', 'endSpacer'];

  onSelected(agenda: AgendaTableDataV2): void {
    this.router.navigate([`/${MeetingRoutes.home}/${MeetingRoutes.agendasV2}/${agenda.id}`]);
  }

  onTeamSelected(team: Team | Team[]): void {
    this.store.dispatch(MeetingsAgendasV2Actions.selectTeam({ team: team as Team }));
  }

  createAgenda(): void {
    this.router.navigate([`/${MeetingRoutes.home}/${MeetingRoutes.agendasV2}/${MeetingRoutes.newCustomAgenda}`]);
  }
}
