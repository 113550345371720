import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SkeletonAnimation, SkeletonConstants, SkeletonTheme, SkeletonType } from './types';

/**
 * Skeleton loaders are visual placeholders for information while data is
 * still loading. They provide a low fidelity representation of the
 * interface that will be loaded and increase perceived
 * performance.
 *
 * Note: Not all features of ngx-skeleton-loader are implemented at this time.
 * Checkout the Github page for full feature support before implementing anything
 * custom.
 *
 * Reference:
 * https://github.com/willmendesneto/ngx-skeleton-loader
 */
@Component({
  selector: 'ninety-skeleton',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ngx-skeleton-loader
      [animation]="animation ?? SkeletonConstants.INIT_ANIMATION"
      [appearance]="type ?? SkeletonConstants.INIT_TYPE"
      [count]="count ?? SkeletonConstants.INIT_COUNT"
      [theme]="theme"></ngx-skeleton-loader>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
        cursor: progress;

        ngx-skeleton-loader {
          flex: inherit;
          display: inherit;
          flex-direction: inherit;
          gap: inherit;
        }
      }
    `,
  ],
})
export class SkeletonComponent {
  protected readonly SkeletonConstants = SkeletonConstants;

  /** Number of times to repeat this skeleton. */
  @Input() count: number = SkeletonConstants.INIT_COUNT;

  /** {@link SkeletonType} */
  @Input() type: SkeletonType = SkeletonConstants.INIT_TYPE;

  /** {@link SkeletonAnimation} */
  @Input() animation: SkeletonAnimation = SkeletonConstants.INIT_ANIMATION;

  /** {@link SkeletonTheme} */
  @Input() theme?: SkeletonTheme;
}
