import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { filter, map, take, tap } from 'rxjs';

import { TeamService } from '../../../_core/services/team.service';
import { RoleCode } from '../../../_shared/models/_shared/role-code';
import type { Team, TeamWithUsers } from '../../../_shared/models/_shared/team';
import { User } from '../../../_shared/models/_shared/user';
import { SortByNamePipe } from '../../../_shared/pipes/sort-by-name.pipe';
import { selectAllUsers, selectCurrentUser } from '../users/users-state.selectors';

import { teamsStateActions } from './teams-state.actions';

/**
 * These effects are a compatibility layer between the TeamService and the new Team Ngrx state.
 *
 * The ApploadService was removed from the TeamService. This class handles updating the TeamService
 * state from Ngrx actions.
 *
 * Due to the number of explicit dependencies on directly accessing TeamService BehaviorSubjects, we
 * want to avoid ripping them all out at once. There is also no user state in ngrx, which prevents us
 * from composing selectors for the BehaviorSubjects and state maps.
 */
@Injectable()
export class TeamServiceEffects {
  constructor(private actions$: Actions, private teamService: TeamService, private store: Store) {}

  /**
   * For backwards compatibility, update the TeamService. Gradually we'll need to
   * adopt the teams store in the consumers of the TeamService, moving away from
   * BehaviorSubjects.
   *
   * The team service no longer receives initial state from the AppLoadService, it
   * is updated by this effect.
   */
  setCompanyTeams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(teamsStateActions.getAllSuccess, teamsStateActions.setAll, teamsStateActions.init),
        concatLatestFrom(() => this.store.select(selectCurrentUser).pipe(filter(cu => !!cu))),
        map(([action, _]) => ({ teams: action.teams })),
        tap(({ teams }) => this.teamService.setCompanyTeams(cloneDeep(teams)))
      ),
    { dispatch: false }
  );

  /**
   * Create array of teams that the session user belongs to.
   *
   * Every time all company teams are set, filter the list for teams that are on the list of team ids on the
   * session user.
   */
  setUserTeams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(teamsStateActions.setAll, teamsStateActions.getAllSuccess, teamsStateActions.init),
        concatLatestFrom(() => this.store.select(selectCurrentUser).pipe(filter(cu => !!cu))),
        tap(([action, currentUser]) => {
          const companyTeams = cloneDeep(action.teams);
          const isAdminOrAbove = currentUser.roleCode === RoleCode.owner || currentUser.roleCode === RoleCode.admin;
          // This is weird, but userTeamIds is the list of teams the user is explicitly assigned to
          // Also, teams on users don't have ids, need to use team.teamId, not team._id
          const userTeamIds = new Set(currentUser.teams.map(t => t.teamId));
          // Then userTeams is the list a logged in user can view (admin+ can view any non-private team)
          const userTeams = companyTeams.reduce((acc, team) => {
            const isOnTeam = !!userTeamIds.has(team._id);

            if (isOnTeam || (!team.private && isAdminOrAbove)) {
              acc.push({ ...team });
            }

            return acc;
          }, [] as Team[]);

          this.teamService.setSessionUserTeams([...userTeamIds], userTeams);
        })
      ),
    { dispatch: false }
  );

  /**
   * Every time all company teams are set/updated, create a duplicate list of teams with a subfield of all
   * company users that are associated with that team.
   */
  groupUsersOnTeams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(teamsStateActions.setAll, teamsStateActions.getAllSuccess, teamsStateActions.init),
        concatLatestFrom(() => this.store.select(selectAllUsers)),
        map(([action, allUsers]) => {
          // this.teamService.setTeamsWithEmbeddedUsers(allUsers, [...action.teams]);
          const teamsWithAllEmbeddedUsers: TeamWithUsers[] = cloneDeep(action.teams)
            // Need to assert because every property is optional on Team interface
            .sort((a: Team, b: Team) => SortByNamePipe.ascending(a as { name: string }, b as { name: string }))
            .map(team => ({
              ...team,
              // Not sure why teams on a user use Team.teamId instead of Team._id
              users: allUsers.filter(u => u?.teams.some(ut => ut?.teamId === team._id)),
            }));

          this.teamService.teamsWithAllEmbeddedUsers$.next(teamsWithAllEmbeddedUsers);
        })
      ),
    { dispatch: false }
  );

  /**
   * Create list of teams with embedded users, additionally filtering observers off of embedded user list.
   */
  filterObserversOffEmbeddedUsers$ = createEffect(
    () =>
      this.teamService.selectTeamsWithAllEmbeddedUsers().pipe(
        filter(t => !!t),
        tap(teamsWithUsers => {
          let user;
          this.store
            .select(selectCurrentUser)
            .pipe(take(1))
            .subscribe(u => (user = u));

          // Create team list with observer users removed
          const teams = teamsWithUsers.filter(team => !team.private || user.teams.some(ut => ut.teamId === team._id));
          const teamsWithoutObservers = this.teamService.getTeamsWithoutObservers(teams);

          this.teamService.teamsWithEmbeddedUsers$.next(teamsWithoutObservers);
        })
      ),
    { dispatch: false }
  );

  /**
   * Update state that depends on teams with observer users filtered off of team.users
   */
  setTeamsWithUsersByTeamId$ = createEffect(
    () =>
      this.teamService.teamsWithEmbeddedUsers$.pipe(
        filter(t => !!t),
        tap(teamsWithoutObservers => {
          // Create map of team._id -> team
          this.teamService.teamsWithUsersByTeamId = teamsWithoutObservers.reduce((acc, team) => {
            acc[team._id] = team;
            return acc;
          }, {} as Record<string, Team>);

          // Create map of team._id -> users (no observers)
          const teamUsers = teamsWithoutObservers.reduce((acc, t: TeamWithUsers) => {
            acc[t._id] = t.users;
            return acc;
          }, {} as Record<string, User[]>);
          this.teamService.teamUsers$.next(teamUsers);
        })
      ),
    { dispatch: false }
  );
}
