import { createReducer, on } from '@ngrx/store';

import { FeatureFlagActions } from './feature-flag-state.actions';
import { FeatureFlagStateModel, initialFeatureFlagState } from './feature-flag-state.model';

export const featureFlagStateReducer = createReducer(
  initialFeatureFlagState,
  on(
    FeatureFlagActions.initialFlagsReceived,
    FeatureFlagActions.updatedFlagsProcessed,
    (state, { flags }): FeatureFlagStateModel => ({
      ...state,
      ...flags,
    })
  ),
  on(
    FeatureFlagActions.updateFeatureFlag,
    (state, { key, value }): FeatureFlagStateModel => ({
      ...state,
      [key]: value,
    })
  )
);
