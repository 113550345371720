import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../../models/_shared/user';

@Pipe({
  name: 'ownerOnTeam',
})
export class OwnerOnTeamPipe implements PipeTransform {
  transform(teamUsers: Record<string, User[]>, teamId: string, selectedUserIds: string[]): boolean {
    return (
      teamUsers?.[teamId] &&
      selectedUserIds.every((id: string) => !!teamUsers[teamId].find((user: User) => user._id === id))
    );
  }
}
