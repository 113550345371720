import { Directive } from '@angular/core';

@Directive({
  selector: '[terraOptionPrefix]',
  standalone: true,
})
export class TerraOptionPrefixTemplateRefDirective {}

@Directive({
  selector: '[terraOptionSuffix]',
  standalone: true,
})
export class TerraOptionSuffixTemplateRefDirective {}

@Directive({
  selector: '[terraIconOption]',
  standalone: true,
})
export class TerraOptionIconTemplateRefDirective {}
