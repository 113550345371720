<table
  mat-table
  [dataSource]="dataSource"
  [trackBy]="trackById"
  matSort
  [matSortDisableClear]="true"
  [matSortActive]="matSortActive"
  [matSortDirection]="matSortDirection"
  (matSortChange)="onSortChange($event)"
  class="team-meetings-v2">
  <ng-container matColumnDef="frontSpacer">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef class="front-spacer"></td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let meeting">
      {{ meeting.date | todayOrDate : dateFormat }}
      <span>{{ meeting.date | date : 'shortTime' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="agendaName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Agenda</th>
    <td mat-cell *matCellDef="let meeting">
      {{ meeting.agendaName }} <ninety-chip *ngIf="meeting.paused" class="float-right">Suspended</ninety-chip>
    </td>
  </ng-container>

  <ng-container matColumnDef="totalDuration">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Duration</th>
    <td mat-cell *matCellDef="let meeting">
      {{ meeting.totalDuration | secondsToDuration }}
    </td>
  </ng-container>

  <ng-container matColumnDef="presenterName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Presenter</th>
    <td mat-cell *matCellDef="let meeting">
      <ninety-user-avatar
        [userInitials]="meeting.presenterUser | userInitials"
        [pictureURL]="meeting.presenterUser.metadata?.picture?.url"
        [transparent]="!meeting.presenterUser.active"
        [alignLeft]="true" />
    </td>
  </ng-container>
  <ng-container matColumnDef="ratingAverage">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Rating</th>
    <td mat-cell *matCellDef="let meeting">
      {{ meeting.ratingAverage ?? '-' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="endSpacer">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef class="end-spacer"></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [class.selected]="row._id === selectedId"
    id="meeting-row-{{ row._id }}"
    (click)="onSelected(row)"></tr>
</table>
