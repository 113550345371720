/**
 * We only care about a partial group of categories
 *
 * See the full list in the 3rd column in Clearbit's "Offical" Google Doc...
 * https://docs.google.com/spreadsheets/d/1erIdqoy60JwLAnpb91EfoJV5YrXDnbwSaA-aqcBlw48/edit#gid=1561611259
 */
export enum ClearbitIndustry {
  professionalServices = 'Professional Services',
  internetSoftware = 'Internet Software & Services',
  constructionAndEngineering = 'Construction & Engineering',
  capitalMarkets = 'Capital Markets',
  financial = 'Diversified Financial Services',
  healthcare = 'Health Care Providers & Services',
  media = 'Media',
  education = 'Education Services',
  consumerDiscretionary = 'Consumer Discretionary',
  realEstate = 'Real Estate',
  other = 'Other',
}

/**
 * Documentation:
 * https://help.clearbit.com/hc/en-us/articles/115015447087-What-are-the-Employee-Ranges-returned-by-Enrichment-
 */
export enum ClearbitEmployeeRange {
  '1-10' = '1-10',
  '11-50' = '11-50',
  '51-250' = '51-250',
  '251-1K' = '251-1K',
  '1K+' = '1K+',
}

/** Filtered down attributes from API */
export interface ClearbitCompanyAttributes {
  industry: ClearbitIndustry;
  employeesRange: ClearbitEmployeeRange;
}
