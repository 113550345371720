import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, tap } from 'rxjs';

import { Person } from '../../_shared/models/_shared/person';
import type { ForgotPasswordStatus } from '../../_shared/models/enums/forgot-password-status';
import { CurrentPersonStateActions } from '../../_state/app-global/current-person/current-person.actions';
import { CatchErrorAndNotify } from '../decorators/catch-error-and-notify';
import { SpinnerAndNotifyError } from '../decorators/spinner-and-notify-error';

import { ErrorService } from './error.service';
import { SpinnerService } from './spinner.service';
import { StateService } from './state.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private tokenService: TokenService,
    private errorService: ErrorService,
    private _spinnerService: SpinnerService, //Used for decorator
    private store: Store
  ) {}

  @CatchErrorAndNotify
  findPerson(idOrEmail: string): Observable<Person> {
    return this.http.get<Person>(`/api/v4/FindPerson/${idOrEmail}`);
  }

  /**
   * Either updates the current person, or it's partners page related.
   * So need to have partners or partnersAdmin helpfulPermissions on your person to update another person.
   */
  @SpinnerAndNotifyError
  updatePerson(
    update: Partial<Person>,
    personId = this.stateService.currentPerson$.getValue()._id
  ): Observable<Person> {
    return this.updatePersonNoSpinner(update, personId);
  }

  updatePersonNoSpinner(
    update: Partial<Person>,
    personId = this.stateService.currentPerson$.getValue()._id
  ): Observable<Person> {
    const currentPerson = this.stateService.currentPerson$.value;
    const currentPersonId = currentPerson?.personId || this.tokenService.getAccessTokenDecoded()?.personId;
    if (currentPerson && currentPersonId === personId)
      this.store.dispatch(CurrentPersonStateActions.updatePerson({ person: { ...currentPerson, ...update } }));

    return this.http.patch<Person>(`/api/v4/Person/${personId}`, update).pipe(
      tap(fetchedPerson => {
        if (!currentPerson && currentPersonId === fetchedPerson?._id)
          this.store.dispatch(CurrentPersonStateActions.updatePerson({ person: fetchedPerson }));
      }),
      catchError((e: unknown) => this.errorService.notify(e, null, ''))
    );
  }

  forgotPassword(email: string): Observable<ForgotPasswordStatus> {
    return this.http
      .patch<{ status: ForgotPasswordStatus }>('api/v4/Person/Forgot', { email })
      .pipe(map(({ status }) => status));
  }

  forgotPasswordSubmit({
    email,
    code,
    newPassword,
  }: {
    email: string;
    code: string;
    newPassword: string;
  }): Observable<string> {
    return this.http.patch<string>('api/v4/Person/Forgot/Submit', {
      email,
      code,
      newPassword,
    });
  }
}
