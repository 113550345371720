import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { Phase } from '@ninety/getting-started/guide/models/phase';

@Component({
  selector: 'ninety-phase-details',
  templateUrl: './phase-details.component.html',
  styleUrls: ['./phase-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhaseDetailsComponent {
  @Input() phase: Phase;
  @Input() mazChatEnabled: boolean;

  constructor(private store: Store) {}

  close(): void {
    this.store.dispatch(GuideActions.closeGuide());
  }

  goBack(): void {
    this.store.dispatch(GuideActions.closePhaseDetails());
  }
}
