import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionModule } from '../forms/terra-description';
import { TerraCheckboxModule } from '../terra-checkbox';
import { TerraIconModule } from '../terra-icon';

import { TerraIconOptionComponent } from './terra-icon-option.component';
import {
  TerraOptionIconTemplateRefDirective,
  TerraOptionPrefixTemplateRefDirective,
  TerraOptionSuffixTemplateRefDirective,
} from './terra-option-slots.directive';
import { TerraOptionComponent } from './terra-option.component';

@NgModule({
  imports: [
    CommonModule,
    TerraOptionComponent,
    TerraIconOptionComponent,
    TerraIconModule,
    TerraDescriptionModule,
    TerraCheckboxModule,
    TerraOptionPrefixTemplateRefDirective,
    TerraOptionSuffixTemplateRefDirective,
    TerraOptionIconTemplateRefDirective,
  ],
  exports: [
    TerraOptionComponent,
    TerraOptionPrefixTemplateRefDirective,
    TerraIconModule,
    TerraDescriptionModule,
    TerraOptionSuffixTemplateRefDirective,
    TerraIconOptionComponent,
    TerraOptionIconTemplateRefDirective,
  ],
})
export class TerraOptionModule {}
