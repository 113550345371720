<mat-tab-group
  class="sticky-tabs"
  dynamicHeight
  [selectedIndex]="getTabIndexByName(selectedTabName)"
  (selectedIndexChange)="tabChanged(getTabNameByIndex($event))">
  <mat-tab label="Personal" [routerLink]="['.']" [queryParams]="{ tab: 'personal' }">
    <mat-card class="muted">Information entered in this section will be visible across all of your Companies.</mat-card>
    <ninety-personal
      [metadata]="metadata"
      [userInfo]="userInfo"
      (updateMetadata)="updateMetadata($event)"
      (updateUser)="updateUser($event)"></ninety-personal>
  </mat-tab>

  <mat-tab label="Metrics" [routerLink]="['.']" [queryParams]="{ tab: 'metrics' }">
    <mat-card class="muted">Information entered in this section will be visible across all of your Companies.</mat-card>
    <ninety-metrics [metadata]="metadata" (updateMetadata)="updateMetadata($event)"></ninety-metrics>
  </mat-tab>

  <mat-tab label="Contact" [routerLink]="['.']" [queryParams]="{ tab: 'contact' }">
    <mat-card class="muted">Information entered in this section will apply only to the current Company.</mat-card>
    <ninety-contact [userInfo]="userInfo" (updateUser)="updateUser($event)"></ninety-contact>
  </mat-tab>

  <mat-tab label="Social" [routerLink]="['.']" [queryParams]="{ tab: 'social' }">
    <mat-card class="muted">Information entered in this section will be visible across all of your Companies.</mat-card>
    <ninety-social [metadata]="metadata" (updateMetadata)="updateMetadata($event)"></ninety-social>
  </mat-tab>

  <mat-tab disabled labelClass="right-toolbar-tab">
    <ng-template mat-tab-label>
      <ninety-button
        filled
        primary
        (click)="save()"
        aria-label="Save"
        [disabled]="!hasChanges"
        data-cy="profile_save-changes">
        <terra-icon icon="save" />
        Save Changes
      </ninety-button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
