import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PoliciesActions = createActionGroup({
  source: 'Policies',
  events: {
    'Get Privacy Policy': emptyProps(),
    'Privacy Policy Received': props<{ privacyPolicy: string }>(),
    'Display Privacy Policy': emptyProps(),
    'Display Terms of Service': emptyProps(),
  },
});
