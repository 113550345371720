import { AttachmentEvent } from '../_shared/attachment-event';

import { Conversation } from './conversation';

/**
 * A prefix to all Conversation meeting channels in the form
 * of `conversation-meeting-1234` where `1234` is the Conversation ID
 */
export const CONVERSATION_CHANNEL_PREFIX = 'conversation-meeting-';

/** Represents an update to a Conversation form that will be published on a Conversation channel  */
export type RealTimeConversationFormUpdate = {
  conversationId: string;
  /**
   * Name of the FormControl instance bound to a control in the template when having a Conversation meeting.
   * This is used to determine what control gets the value set after form updates occur.

    @see ConversationMeetingComponent
    @see ConversationMeetingFormComponent
   */
  controlName: string;

  /** Value of the FormControl */
  controlValue: any;
};

export enum ConversationMessageType {
  startConversationMeeting = 'start-conversation-meeting',
  endConversationMeeting = 'end-conversation-meeting',

  conversationFormUpdated = 'conversation-form-updated',

  attachmentChanged = 'attachment-changed',

  manageeImHere = 'managee-im-here',
  managerImHere = 'manager-im-here',
}

/** Message that can be published to indicate the Conversation has started. */
export type StartConversationMeetingMessage = {
  messageType: ConversationMessageType.startConversationMeeting;
  document: Required<Pick<Conversation, '_id' | 'managerUserId' | 'manageeUserId' | 'type' | 'bos'>>;
};

/** Message that can be published to indicate the Conversation has ended. */
export type EndConversationMeetingMessage = {
  messageType: ConversationMessageType.endConversationMeeting;
  document: Required<Pick<Conversation, '_id' | 'managerUserId' | 'manageeUserId' | 'type' | 'bos'>>;
};

/** Message that can be published to indicate the managee has loaded the Conversation List view. */
export type ManageeImHereMessage = {
  messageType: ConversationMessageType.manageeImHere;
  document: Pick<Conversation, '_id' | 'managerUserId'>[];
};

/**
 * Message that can be published, in response to receiving a ManageeImHereMessage,
 * to indicate the manager is present in the Conversation meeting.
 */
export type ManagerImHereMessage = {
  messageType: ConversationMessageType.managerImHere;
  document: Pick<Conversation, '_id'>;
};

/** Message that can be published to indicate the manager/managee has updated the value of a form control in a Conversation meeting. */
export type UpdateConversationFormMessage = {
  messageType: ConversationMessageType.conversationFormUpdated;
  document: RealTimeConversationFormUpdate;
};

/** Message that can be published to indicate the manager/managee has updated the Attachments in a Conversation meeting. */
export type UpdateConversationAttachmentMessage = {
  messageType: ConversationMessageType.attachmentChanged;
  document: Omit<AttachmentEvent, 'parent'>;
};

export type ConversationRealTimeMessageMap = {
  [ConversationMessageType.startConversationMeeting]: StartConversationMeetingMessage;
  [ConversationMessageType.endConversationMeeting]: EndConversationMeetingMessage;
  [ConversationMessageType.conversationFormUpdated]: UpdateConversationFormMessage;
  [ConversationMessageType.attachmentChanged]: UpdateConversationAttachmentMessage;
  [ConversationMessageType.manageeImHere]: ManageeImHereMessage;
  [ConversationMessageType.managerImHere]: ManagerImHereMessage;
};

export type ConversationRealTimeMessage = ConversationRealTimeMessageMap[keyof ConversationRealTimeMessageMap];
