import { Phase } from '@ninety/getting-started/guide/models/phase';

export interface GuideState {
  guideOpened: boolean;
  title: string;
  subtitle: string;
  completionPercentage: number;
  phases: Phase[];
  selectedPhase: Phase;
}

export const INITIAL_GUIDE_STATE: GuideState = {
  guideOpened: false,
  title: '',
  subtitle: '',
  completionPercentage: 0,
  phases: [],
  selectedPhase: null,
};
