import { createActionGroup, props } from '@ngrx/store';

import type { ColorBranding, Company } from '../../../_shared/models/company/company';
import type { CompanyModel } from '../../../_shared/models/company/company-model';
import type { CompanySettings } from '../../../_shared/models/company/company-settings';
import type { RegisterCompanyResponse } from '../../../_shared/models/company/register-company-response';
import type { Commitment } from '../../../_shared/models/feedback/commitments';
import { FormalConversationQuestion } from '../../../_shared/models/feedback/formal-conversation-question';
import type { RatingsSettings } from '../../../_shared/models/feedback/one-on-one-settings';

export const CompanyActions = createActionGroup({
  source: 'Company',
  events: {
    Init: props<{ company: Company }>(),
    'Update Default Stripe Payment Method': props<{ paymentMethod: stripe.paymentMethod.PaymentMethod }>(),
    'Register Company': props<{ response: RegisterCompanyResponse }>(),
    // The company dto that was a part of the registration attempt
    'Register Company Fail': props<{ company: CompanyModel; error: Error }>(),
    'Update Theme': props<{ colorBranding: ColorBranding }>(),
    'Handle Company Update': props<{ changes: Partial<Company> }>(),
    'Update Company': props<{ changes: Partial<Company> }>(),
    'Update Company Setting': props<{ settings: Partial<CompanySettings> }>(),
    'Update Logo': props<{ location: string }>(),
    'Update Conversation Settings': props<{
      leadershipCommitments: Commitment[];
      coachingCommitments: Commitment[];
      annualReviewQuestions: FormalConversationQuestion[];
      quarterlyReviewQuestions: FormalConversationQuestion[];
      ratingSettings: RatingsSettings;
    }>(),
    'Updated AB Todos Setting': props<{ agreementBasedTodos: boolean }>(),
  },
});
