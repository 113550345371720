import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MazFeedbackSubmission } from '@ninety/getting-started/maz/models/maz-feedback-submission';
import { ConversationHistory, MazMessage } from '@ninety/getting-started/maz/models/maz-message';
import { MazResponse } from '@ninety/getting-started/maz/models/maz-response';

@Injectable({
  providedIn: 'root',
})
export class MazChatService {
  private readonly api = '/api/maz/v1';

  constructor(private http: HttpClient) {}

  getConversationHistory(): Observable<MazMessage[]> {
    return this.http
      .get<ConversationHistory>(`${this.api}/conversation_history`)
      .pipe(map(({ conversation_history }) => conversation_history));
  }

  sendMazMessage(userInput: string): Observable<MazResponse> {
    return this.http.post(`${this.api}/process_message`, { userInput }, { responseType: 'text' });
  }

  submitFeedback(body: MazFeedbackSubmission): Observable<MazResponse> {
    return this.http.post(`${this.api}/submit_feedback`, body);
  }
}
