import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MainSidebarActions = createActionGroup({
  source: 'Main Sidebar',
  events: {
    Expand: emptyProps(),
    Collapse: emptyProps(),
    Show: emptyProps(),
    Hide: emptyProps(),
  },
});
