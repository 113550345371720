<!-- Editable -->
<mat-form-field *ngIf="editing; else plainText" class="full-width" ninetyStopPropagation>
  <input #titleInput matInput type="text" [formControl]="control" (blur)="save()" (keydown.enter)="save()" />

  <button data-cy="inline-editable-title_save-button" mat-icon-button matSuffix color="accent" (click)="save()">
    <terra-icon icon="check" />
  </button>
</mat-form-field>

<!-- Plain text, mobile, disabled by input -->
<ng-template #plainText>
  <div
    [class.strike-through]="strikethrough"
    class="non-input-inline-title"
    [ngClass]="lineClampXS !== undefined ? 'line-clamp-xs-' + lineClampXS : null"
    [class.line-clamp]="lineClampToTwoLines"
    [matTooltip]="title"
    [matTooltipDisabled]="title?.length < 175 || toolTipDisabled"
    matTooltipPosition="above"
    matTooltipClass="ninety-tooltip">
    {{ title }}
  </div>

  <button
    data-cy="inline-editable-title_edit-button"
    *ngIf="!disabled"
    fxHide.lt-sm
    class="edit-btn"
    matTooltipClass="ninety-tooltip"
    matTooltipPosition="above"
    matTooltip="Edit title"
    ninetyStopPropagation
    (click)="toggleEdit()">
    <terra-icon icon="edit" data-cy="inline-editable-title_edit-icon" />
  </button>
</ng-template>
