import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { TimeService } from '../../../_core/services/time.service';
import { TimezoneEntry } from '../../models/_shared/timezone-select';
import { TimezoneSelectEvent } from '../../models/_shared/timezone-select-event';

@Component({
  selector: 'ninety-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimezoneSelectComponent implements OnInit, OnChanges {
  @Input() shouldAutoSelectTimeZoneFromBrowser: boolean;
  @Input() defaultUserTimezoneName: string;

  @Output() selectTimezoneEvent = new EventEmitter<TimezoneSelectEvent>();
  @Output() triggerSaveForFirstChangeEvent = new EventEmitter<boolean>();

  timezoneList: TimezoneEntry[];
  selectedTimeZone: TimezoneEntry;
  selectedTimeZOneFullUtcOffset: string;

  constructor(private timeService: TimeService) {}

  ngOnInit() {
    this.timezoneList = this.timeService.timezoneList;

    const selectedTimezoneEntry = this.timezoneList.find(
      (entry: TimezoneEntry) => entry.name === this.defaultUserTimezoneName
    );

    if (selectedTimezoneEntry !== undefined) {
      this.selectedTimeZone = selectedTimezoneEntry;
      this.selectedTimeZOneFullUtcOffset = selectedTimezoneEntry.fullUtcOffset;
    }
  }

  onSelectionChange(timezoneName: string) {
    if (this.timeService.timezoneList) {
      const pickedTimeZoneEntry: TimezoneEntry = this.timeService.timezoneList.find(tzl => tzl.name == timezoneName);

      this.selectedTimeZone = pickedTimeZoneEntry;
      this.selectedTimeZOneFullUtcOffset = pickedTimeZoneEntry.fullUtcOffset;

      const timezoneSelectEvent: TimezoneSelectEvent = {
        timezoneEntry: pickedTimeZoneEntry,
        setTimezoneAutomatically: this.shouldAutoSelectTimeZoneFromBrowser,
      };
      this.selectTimezoneEvent.emit(timezoneSelectEvent);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let cantRetrieveBrowserTimeZone = false;
    let timeZoneNameFromBrowser;

    if (this.shouldAutoSelectTimeZoneFromBrowser) {
      try {
        timeZoneNameFromBrowser = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (timeZoneNameFromBrowser == null || timeZoneNameFromBrowser == '') {
          cantRetrieveBrowserTimeZone = true;
        }
      } catch (err) {
        cantRetrieveBrowserTimeZone = true;
      }

      //if we can't get timezone from browser, use GMT as default
      if (cantRetrieveBrowserTimeZone) {
        this.onSelectionChange('GMT');
      } else {
        this.onSelectionChange(timeZoneNameFromBrowser);
      }

      const isFirstChange = changes.shouldAutoSelectTimeZoneFromBrowser?.firstChange || false;

      if (isFirstChange) {
        this.triggerSaveForFirstChangeEvent.emit(true);
      }
    }
  }
}
