export class CustomerBillingInfo {
  // City/District/Suburb/Town/Village.
  city?: string;

  // 2-letter country code.
  country?: string;

  // Address line 1 (Street address/PO Box/Company name).
  line1?: string;

  // Address line 2 (Apartment/Suite/Unit/Building).
  line2?: string;

  // ZIP or postal code.
  postalCode?: string;

  // State/County/Province/Region.
  state?: string;

  // Email address.
  email?: string;
}
