import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleFilter',
})
export class SimpleFilterPipe implements PipeTransform {
  transform(values: string[], input: string): any[] {
    if (!values || !input) return values;
    return values.filter(value => value.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
  }
}
