import { Injectable } from '@angular/core';
import type { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import type { Team } from '../../../_shared/models/_shared/team';

import { teamsStateActions } from './teams-state.actions';
import { selectCompanyTeamById, selectCompanyTeams, selectCompanyTeamsMap } from './teams-state.selectors';

@Injectable({
  providedIn: 'root',
})
export class TeamsStateFacade {
  constructor(private store: Store) {}

  init(teams: Team[]) {
    this.store.dispatch(teamsStateActions.init({ teams }));
  }
  /**
   * @returns a list of all teams in a company
   */
  selectCompanyTeams() {
    return this.store.select(selectCompanyTeams);
  }

  selectCompanyTeamsMap() {
    return this.store.select(selectCompanyTeamsMap);
  }

  selectTeamById(teamId: string) {
    return this.store.select(selectCompanyTeamById(teamId));
  }
  getById(dto: { _id: string }) {
    this.store.dispatch(teamsStateActions.getById(dto));
  }

  getByIdSuccess(team: Team) {
    this.store.dispatch(teamsStateActions.getByIdSuccess(team));
  }

  getByIdFailed(dto: { error: unknown }) {
    this.store.dispatch(teamsStateActions.getByIdFailed(dto));
  }

  getAll() {
    this.store.dispatch(teamsStateActions.getAll());
  }

  getAllSuccess(dto: { teams: Team[] }) {
    this.store.dispatch(teamsStateActions.getAllSuccess(dto));
  }

  getAllFailed(dto: { error: unknown }) {
    this.store.dispatch(teamsStateActions.getAllFailed(dto));
  }

  addOne(dto: { team: Team }) {
    this.store.dispatch(teamsStateActions.addOne(dto));
  }

  removeOne(dto: { _id: string }) {
    this.store.dispatch(teamsStateActions.removeOne(dto));
  }

  updateOne(_id: string, changes: Partial<Team>) {
    this.store.dispatch(teamsStateActions.updateOne({ _id, changes }));
  }

  updateMany(updates: Update<Team>[]) {
    this.store.dispatch(teamsStateActions.updateMany({ updates }));
  }

  setAll(dto: { teams: Team[] }) {
    this.store.dispatch(teamsStateActions.setAll(dto));
  }
}
