import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ResponsibilitiesChartActions } from '@ninety/accountability-chart/_state/chart/actions/responsibility-chart.actions';
import { CurrentChartSeatHolderActions } from '@ninety/accountability-chart/_state/chart/actions/seat-holder.actions';
import { SeatActions } from '@ninety/accountability-chart/_state/chart/actions/seat.actions';
import {
  createInitialCurrentChartModel,
  CurrentChartModel,
  INITIAL_ACC_ZOOM_SCALE,
  seatStateAdapter,
} from '@ninety/accountability-chart/_state/chart/responsibility-chart.model';
import { ResponsibilitiesChartFilterActions } from '@ninety/accountability-chart/_state/filterbar/responsibility-filterbar.actions';
import { SeatHolder } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-holder';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { ErrorWithId } from '@ninety/ui/legacy/shared/models/errors/error-with-id';

import { SharedResponsibilityChartsActions } from '../shared-with-me/shared-responsibility-charts.actions';

export const chartReducers = createReducer<CurrentChartModel>(
  createInitialCurrentChartModel(),
  on(
    ResponsibilitiesChartActions.destroy,
    (state): CurrentChartModel => ({
      ...state,
      chart: null,
    })
  ),
  on(ResponsibilitiesChartActions.fetchSuccess, (state, { response, lastTopSeatId }): CurrentChartModel => {
    const { seats, chart } = response;
    const updatedState = seatStateAdapter.setAll(seats, state);

    const topSeat = seats.find(seat => seat.parentSeatId === null);
    if (!topSeat) throw new ErrorWithId('Top seat not found in API request');

    let topSeatId = topSeat._id;

    // Verify lastTopSeatId hasn't been deleted
    const lastTopSeat = seats.find(seat => seat._id === lastTopSeatId);
    if (lastTopSeat) {
      topSeatId = lastTopSeatId;
    }

    return { ...updatedState, chart, topSeatId };
  }),
  // TODO Remove under DEV-7514
  on(
    ResponsibilitiesChartActions.setV1ChartIdOverride,
    (state, { chartId }): CurrentChartModel => ({
      ...state,
      chart: { ...state.chart, _id: chartId },
    })
  ),
  on(SeatActions.updateOne, (state, { update }): CurrentChartModel => seatStateAdapter.updateOne(update, state)),
  on(SeatActions.updateMany, (state, { updates }): CurrentChartModel => seatStateAdapter.updateMany(updates, state)),
  on(SeatActions.create, (state, { seat }): CurrentChartModel => seatStateAdapter.addOne(seat, state)),
  on(SeatActions.insertOne, (state, { seat }): CurrentChartModel => seatStateAdapter.addOne(seat, state)),
  on(SeatActions.removeMany, (state, { ids }): CurrentChartModel => seatStateAdapter.removeMany(ids, state)),

  /**
   * Remove visionary seat from state & remove it from integrator's parentSeatId
   */
  on(SeatActions.hideVisionarySuccess, (state, { visionarySeatId, integratorSeatId }): CurrentChartModel => {
    const withoutVisionaryState = seatStateAdapter.removeOne(visionarySeatId, state);
    const updatedState = seatStateAdapter.updateOne(
      { id: integratorSeatId, changes: { parentSeatId: null } },
      withoutVisionaryState
    );

    return {
      ...updatedState,
      topSeatId: integratorSeatId,
    };
  }),
  /**
   * Add visionary seat to state & update integrator's parentSeatId
   */
  on(SeatActions.showVisionarySuccess, (state, { apiResponse, integratorSeatId }): CurrentChartModel => {
    const withVisionaryState = seatStateAdapter.addOne(apiResponse.visionarySeat, state);

    const updatedState = seatStateAdapter.updateOne(
      { id: integratorSeatId, changes: { parentSeatId: apiResponse.visionarySeat._id } },
      withVisionaryState
    );

    return {
      ...updatedState,
      topSeatId: apiResponse.visionarySeat._id,
    };
  }),
  on(
    ResponsibilitiesChartFilterActions.zoomIn,
    (state): CurrentChartModel => ({
      ...state,
      zoomScale: state.zoomScale.zoomIn(),
    })
  ),
  on(
    ResponsibilitiesChartFilterActions.zoomOut,
    (state): CurrentChartModel => ({
      ...state,
      zoomScale: state.zoomScale.zoomOut(),
    })
  ),
  on(
    ResponsibilitiesChartFilterActions.setZoomScale,
    (state, { zoomScale }): CurrentChartModel => ({
      ...state,
      zoomScale: state.zoomScale.withScale(zoomScale),
    })
  ),
  on(
    ResponsibilitiesChartFilterActions.resetZoomScale,
    (state): CurrentChartModel => ({
      ...state,
      zoomScale: state.zoomScale.withScale(INITIAL_ACC_ZOOM_SCALE),
    })
  ),
  on(
    CurrentChartSeatHolderActions.createOneSuccess,
    (state, { seatHolder }): CurrentChartModel =>
      modifySeatsSeatHolders(
        state,
        { seatId: seatHolder.seatId, chartId: seatHolder.chartId },
        (seatHolders: SeatHolder[]): SeatHolder[] => [...seatHolders, seatHolder]
      )
  ),
  on(
    CurrentChartSeatHolderActions.removeOneSuccess,
    (state, action): CurrentChartModel =>
      modifySeatsSeatHolders(state, action.params, (seatHolders: SeatHolder[]): SeatHolder[] =>
        seatHolders.filter(sh => sh._id !== action.params._id)
      )
  ),
  on(
    CurrentChartSeatHolderActions.changeSeatHolderUserSuccess,
    (state, action): CurrentChartModel =>
      modifySeatsSeatHolders(state, action.patch, (seatHolders: SeatHolder[]): [SeatHolder] => [
        // Only done on visionary/integrator seats so we can assume there is only one seat holder
        { ...seatHolders[0], userId: action.patch.newUserId },
      ])
  ),
  on(SharedResponsibilityChartsActions.setPrimarySuccess, (state, { newPrimaryChartId }): CurrentChartModel => {
    if (newPrimaryChartId !== state.chart?._id) {
      return state;
    }

    return {
      ...state,
      chart: {
        ...state.chart,
        isPrimaryCompanyChart: true,
      },
    };
  }),
  on(SharedResponsibilityChartsActions.updateMAC, (state, { chart }): CurrentChartModel => {
    if (state.chart?._id !== chart._id) {
      return state;
    }

    return {
      ...state,
      chart: {
        ...state.chart,
        ...chart,
      },
    };
  }),
  /** @deprecated no longer has any active use */
  on(
    ResponsibilitiesChartActions.updateVisibleNodeLength,
    (state, { length }): CurrentChartModel => ({
      ...state,
      visibleSeatCount: length,
    })
  ),
  /** @deprecated no longer has any active use */
  on(
    ResponsibilitiesChartFilterActions.updateTopSeatInState,
    (state, { seatId }): CurrentChartModel => ({
      ...state,
      topSeatId: seatId,
    })
  ),
  /** @deprecated no longer has any active use */
  on(ResponsibilitiesChartFilterActions.resetTopSeat, (state): CurrentChartModel => {
    const seats = seatStateAdapter.getSelectors().selectAll(state);
    const topSeat = seats.find(seat => seat.parentSeatId === null);
    if (!topSeat) throw new ErrorWithId('Cannot find top seat after reset');

    return {
      ...state,
      topSeatId: topSeat._id,
    };
  }),
  on(
    SeatActions.detailsRegisterSelectedSeat,
    (state, { id }): CurrentChartModel => ({
      ...state,
      detailSeatId: id,
      descendantsOfDetailSeat: null,
    })
  ),
  on(
    SeatActions.detailsSetDescendants,
    (state, { childrenIds }): CurrentChartModel => ({
      ...state,
      descendantsOfDetailSeat: childrenIds,
    })
  ),
  on(
    SeatActions.detailsDestroy,
    (state): CurrentChartModel => ({
      ...state,
      descendantsOfDetailSeat: null,
      detailSeatId: null,
    })
  )
);

export function modifySeatsSeatHolders(
  state: CurrentChartModel,
  action: { seatId: string; chartId: string },
  updater: (seatHolders: SeatHolder[]) => SeatHolder[]
): CurrentChartModel {
  if (state.chart?._id !== action.chartId) return state;

  const seat: SeatModel = state.entities[action.seatId];
  if (!seat) throw new Error('Seat not found, but correct chart'); // TODO better error

  const updatedSeatHolders = updater(seat.seatHolders);
  const update: Update<SeatModel> = { id: seat._id, changes: { seatHolders: updatedSeatHolders } };
  return seatStateAdapter.updateOne(update, state);
}
