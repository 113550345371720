import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { merge, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../../models/terra-input-boolean.models';
import { TerraErrorStateMatcher } from '../terra-error-state-matcher';

@Component({
  selector: 'terra-form-group',
  standalone: true,
  exportAs: 'terraFormGroup',
  imports: [CommonModule],
  templateUrl: './terra-form-group.component.html',
  styleUrls: ['./terra-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraFormGroupComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(NgControl) formControls!: QueryList<NgControl>;

  private _destroyed$ = new Subject<void>();

  /**
   * Sets whether the form group shows in compact mode
   * @default false
   */
  @Input() get compact(): boolean {
    return this._compact;
  }
  set compact(value: TerraInputBoolean) {
    this._compact = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _compact = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private readonly _terraErrorStateMatcher: TerraErrorStateMatcher
  ) {}

  protected _isAnyFormControlInvalid(): boolean {
    return this.formControls?.some(control => this._terraErrorStateMatcher.isErrorState(control));
  }

  ngAfterContentInit() {
    const formControlsStatusChanges$ = this.formControls.changes.pipe(
      startWith(this.formControls),

      switchMap(controls => {
        return merge(...controls.map((control: NgControl) => control.statusChanges || of()));
      }),
      tap(() => {
        this._changeDetectorRef.markForCheck();
      })
    );
    merge(formControlsStatusChanges$).pipe(takeUntil(this._destroyed$)).subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
