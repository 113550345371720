import { Pipe, PipeTransform } from '@angular/core';

import { Rock } from '../models/rocks/rock';
import { Todo } from '../models/todos/todo';

@Pipe({
  name: 'filterByTeamId',
})
export class FilterByTeamIdPipe implements PipeTransform {
  transform<T>(list: Array<T>, teamId: string): Array<T> {
    if (!list || !teamId || teamId === 'all') return list;

    return list.filter((object: Todo | Rock) => {
      if ('additionalTeamIds' in (object as Rock)) {
        return [object.teamId, ...object.additionalTeamIds]
          .filter((value, index, self) => self.indexOf(value) === index) // Gets unique ids just in case.
          .find(id => id === teamId);
      }

      return object.teamId === teamId;
    });
  }
}
