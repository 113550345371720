import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AccountStatus, AccountStatusV2 } from '../../../_shared';
import { BusinessOperatingSystem } from '../../../_shared/models/company/business-operating-system.enum';

export interface CompanyUserListModel {
  userId: string;
  companyId: string;
  accountStatus: AccountStatus | AccountStatusV2;
  companyName: string;
  logoUrl: string;
  bos: BusinessOperatingSystem;
}

export type CompanyUsersStateModel = EntityState<CompanyUserListModel>;

export const companyUsersStateAdapter = createEntityAdapter<CompanyUserListModel>({ selectId: c => c.userId });

export const companyUsersInitialState: CompanyUsersStateModel = {
  ...companyUsersStateAdapter.getInitialState(),
};
