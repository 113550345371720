export type SwotType = 'strengths' | 'weaknesses' | 'opportunities' | 'threats' | 'trends';

/**
 * EOS SWOT configuration. Used by all non-Pinnacle BOSes
 */
export const STANDARD_SWOT_TYPES: ReadonlyArray<SwotType> = ['strengths', 'weaknesses', 'opportunities', 'threats'];

/**
 * Pinnacle BOS configuration of SWOT.
 * - Not threats, but trends
 * - No Opportunities
 * - Specific Order
 */
export const PINNACLE_SWOT_TYPES: ReadonlyArray<SwotType> = ['trends', 'weaknesses', 'strengths'];
