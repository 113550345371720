import { createReducer, on } from '@ngrx/store';

import { SpinnerActions } from './spinner-state.actions';
import { initialSpinnerState, SpinnerStateModel } from './spinner-state.models';

export const SpinnerStateReducer = createReducer<SpinnerStateModel>(
  initialSpinnerState,
  on(
    SpinnerActions.startPrimary,
    (state): SpinnerStateModel => ({
      ...state,
      primary: true,
      primaryCount: state.primaryCount + 1,
      primaryStartTime: state.primaryCount == 0 ? new Date().getTime() : state.primaryStartTime,
    })
  ),
  on(
    SpinnerActions.stopPrimary,
    (state): SpinnerStateModel => ({
      ...state,
      primary: false,
      primaryCount: 0,
      auxiliary: false,
      auxiliaryCount: 0,
    })
  ),
  on(
    SpinnerActions.startAuxiliary,
    (state): SpinnerStateModel => ({
      ...state,
      auxiliary: true,
      auxiliaryCount: state.auxiliaryCount + 1,
    })
  ),
  on(
    SpinnerActions.stopAuxiliary,
    (state): SpinnerStateModel => ({
      ...state,
      auxiliary: false,
      auxiliaryCount: 0,
    })
  )
);
