<h1 mat-dialog-title data-cy="account-change-password-dialog_title">Change Password</h1>

<mat-dialog-content>
  <p *ngIf="data.isTempPassword">You've signed in using a temporary password. Please create a new one.</p>

  <form name="accountChangePasswordSubmit" [formGroup]="accountChangePasswordGroup">
    <mat-form-field *ngIf="!data.isTempPassword" class="outline-field" appearance="outline">
      <input
        data-cy="account-change-password-dialog_old-password-input"
        matInput
        autocomplete="password"
        placeholder="Old password"
        type="password"
        class="fs-mask"
        cdkFocusInitial
        [formControl]="oldPasswordControl" />
    </mat-form-field>
    <mat-form-field class="outline-field" appearance="outline">
      <input
        data-cy="account-change-password-dialog_new-password-input"
        matInput
        autocomplete="new-password"
        placeholder="New password"
        type="password"
        class="fs-mask"
        [formControl]="newPasswordControl" />
    </mat-form-field>
    <mat-form-field class="outline-field" appearance="outline">
      <input
        data-cy="account-change-password-dialog_confirm-new-password-input"
        matInput
        autocomplete="new-password"
        placeholder="Confirm new password"
        type="password"
        class="fs-mask"
        [formControl]="confirmNewPasswordControl" />
    </mat-form-field>
    <mat-list>
      <mat-error *ngIf="accountChangePasswordGroup.errors?.passwordMismatch">Password does not match </mat-error>
      <mat-error *ngIf="accountChangePasswordGroup.errors?.passwordLength"
        >Password must be 8 or more characters
      </mat-error>
      <mat-error *ngIf="accountChangePasswordGroup.errors?.missingLowerAlpha"
        >Password needs a lowercase alphabetical character (a-z).
      </mat-error>
      <mat-error *ngIf="accountChangePasswordGroup.errors?.missingNumeric">Password needs a number (0-9)</mat-error>
      <mat-error *ngIf="accountChangePasswordGroup.errors?.missingSpecial"
        >Password needs a special character
      </mat-error>
      <mat-error *ngIf="accountChangePasswordGroup.errors?.invalidChars"
        >Invalid password characters: {{ accountChangePasswordGroup.errors.invalidChars }}
      </mat-error>
    </mat-list>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ninety-button-row>
    <ninety-button
      stroked
      data-cy="account-change-password-dialog_forgot-password-button"
      *ngIf="!data.isTempPassword"
      (click)="openForgotPasswordDialog()">
      Forgot Password?
    </ninety-button>
    <ninety-button
      stroked
      data-cy="account-change-password-dialog_update-password-button"
      color="accent"
      [disabled]="accountChangePasswordGroup.invalid || (spinnerService.primary$ | async)"
      (click)="data.isTempPassword ? changeTempPasswordSubmit() : accountChangePasswordSubmit()">
      <terra-icon icon="lock-open" />
      Update Password
    </ninety-button>
  </ninety-button-row>
</mat-dialog-actions>
