import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ninety-card-title-with-count',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <span class="terra-title">{{ title }}</span>
    <span *ngIf="description" class="terra-title-light" id="card-title-description">{{ description }}</span>
    <terra-counter attr.data-cy="card-title-with-count-{{ count }}" class="card-title-count" *ngIf="!!count">
      {{ count }}
    </terra-counter>
  `,
  // TODO use new color + typography
  styles: [
    `
      @use 'terra';

      :host {
        margin-right: 12px;
        display: flex;
        align-items: center;
      }

      .card-title {
        @include terra.text(title-small);
      }

      #card-title-description {
        color: terra.color('text', 'light');
        margin-left: 6px;
      }

      .card-title-count {
        margin-left: 8px;

        @include terra.text(body-large);
      }
    `,
  ],
})
export class CardTitleWithCountComponent {
  @Input() title = '';
  @Input() description: string;
  @Input() count: number;
}
