import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { catchError, map, switchMap } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { FileDragDropDirective } from '../../../_components/files/file-drag-drop/file-drag-drop.directive';
import { FileService } from '../../../_core/services/file.service';
import { LibraryService } from '../../../_core/services/library.service';
import { NotifyService } from '../../../_core/services/notify.service';
import { UsersStateActions } from '../../../_state/app-entities/users/users-state.actions';
import { SpinnerActions } from '../../../_state/app-global/spinner/spinner-state.actions';
import { AvatarType } from '../../models/library/avatar-type.enum';
import { SignPutResponseDto } from '../../models/library/signed-put-response.dto';

import { AvatarUploadModel } from './models/AvatarUploadModel';

@Component({
  selector: 'ninety-avatar-upload-dialog',
  templateUrl: './avatar-upload-dialog.component.html',
  styleUrls: ['./avatar-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatDialogModule, FileDragDropDirective, ImageCropperModule, TerraIconModule, ButtonComponent],
})
export class AvatarUploadDialogComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  imageChangedEvent: Event;
  imageFile: File;
  croppedImage = '';
  fileName = 'No file chosen';
  shouldShowImageCropper = false;
  private files: { fileName: string; fileSize: number; uploadPercent: number; uploadedBytes: number }[] = [];
  constructor(
    public dialogRef: MatDialogRef<AvatarUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public avatarDialog: AvatarUploadModel,
    private libraryService: LibraryService,
    private notifyService: NotifyService,
    private fileService: FileService,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onCancel(): void {
    this.shouldShowImageCropper = false;
    this.croppedImage = '';
    this.dialogRef.close();
  }

  onUpload(): void {
    this.store.dispatch(SpinnerActions.startPrimary(null));

    // TODO: Replace with avatar service
    // TODO: forkJoin signed url & file processing
    this.libraryService
      .getImageUploadUrl(
        this.avatarDialog.forOtherPerson ? AvatarType.otherPerson : AvatarType.person,
        this.avatarDialog.editeePersonMetadataId
      )
      .pipe(
        switchMap((uploadImageData: SignPutResponseDto) =>
          this.fileService
            .compressImage(this.fileService.dataURItoFile(this.croppedImage, this.fileName))
            .pipe(map((compressedImage: File) => ({ uploadImageData, compressedImage })))
        ),
        switchMap(({ uploadImageData, compressedImage }) =>
          this.fileService
            .downsizeImage(compressedImage, { maxWidth: 300 })
            .pipe(map((image: File) => ({ uploadImageData, image })))
        ),
        switchMap(({ uploadImageData, image }) =>
          this.libraryService.uploadImage(uploadImageData.uploadUrl, image).pipe(map(() => uploadImageData))
        ),
        catchError((error: unknown) => {
          this.store.dispatch(SpinnerActions.stopPrimary(null));
          this.notifyService.showError('An error occurred while uploading image. Please try again.', 'Image Upload');
          throw error;
        })
      )
      .subscribe((data: SignPutResponseDto) => {
        this.store.dispatch(SpinnerActions.stopPrimary(null));

        if (this.avatarDialog.editeeUserId) {
          const editeeUserId = this.avatarDialog.editeeUserId;

          const newAvatarUrl: string = data.location;

          this.store.dispatch(UsersStateActions.setAvatarUrl({ _id: editeeUserId, url: newAvatarUrl }));
        }

        this.dialogRef.close({
          url: data.location,
        });
      });
  }

  onFileSelected(evt: Event) {
    const files = (evt.target as HTMLInputElement).files;
    this.extractFileData(files);
    this.imageChangedEvent = evt;
    this.shouldShowImageCropper = true;
    this.cdr.detectChanges();
  }

  onFileDropped(files: FileList) {
    this.fileDropEl.nativeElement.value = '';
    this.extractFileData(files);
    this.imageFile = files[0];
    this.shouldShowImageCropper = true;
    this.cdr.detectChanges();
  }

  private extractFileData(files: FileList): void {
    this.files = Object.entries(files).map(([_, value]) => ({
      fileName: value.name,
      fileSize: value.size,
      uploadPercent: 0,
      uploadedBytes: 0,
    }));
    const file = this.files[0];
    let fileSize: number;
    if (file && file.fileSize) {
      fileSize = file.fileSize / 1024 ** 2;
    }

    if (fileSize && fileSize > 25) {
      this.notifyService.notify('Uploaded images should be smaller than 25MB.');
    } else {
      this.fileName = this.files[0].fileName;
    }
  }
}
