/**
 * Boolean mapping of available sections
 *
 * When a BOS uses Core Focus as a section, niche and purpose have no meaning.
 * When a BOS does not, the inverse is true - coreFocus has no meaning, while niche and purpose do.
 *
 * TODO this should be a record type so type safety catches this falling out of sync with the sections themselves.
 * TODO there are other uses for a Map<section, boolean> => make this a generic record type
 */
export interface CascadedSections {
  coreValues: boolean;
  tenYear: boolean;
  marketingStrategies: boolean;
  threeYear: boolean;
  oneYear: boolean;
  ninetyDays: boolean;
  coreFocus: boolean;
  niche: boolean;
  purpose: boolean;
  issuesList: boolean;

  // Bos Specific Fields
  // Pinnacle ========
  purposeWhyWeExist?: boolean;
  coreBusiness?: boolean;
  pinnacleTopOfMountain?: boolean;
  quarterlyTheme?: boolean;
  flywheelDesign?: boolean;
  oneLineMarketingPhrase?: boolean;
  //brandPromises?: boolean;
}

export namespace CascadedSections {
  export function cascadedKeys(cascadedSections: CascadedSections): (keyof CascadedSections)[] {
    return Object.keys(cascadedSections).filter(key => cascadedSections[key]) as (keyof CascadedSections)[];
  }

  export function notCascadedKeys(cascadedSections: CascadedSections): (keyof CascadedSections)[] {
    return Object.keys(cascadedSections).filter(key => !cascadedSections[key]) as (keyof CascadedSections)[];
  }

  export function inverseKeys(set: (keyof CascadedSections)[]): (keyof CascadedSections)[] {
    return Object.keys(CascadedSections.allFalse()).filter(
      (key: keyof CascadedSections) => !set.includes(key)
    ) as (keyof CascadedSections)[];
  }

  // only used for determining which section(s) are being edited...
  export function allFalse(): CascadedSections {
    return {
      coreFocus: false,
      coreValues: false,
      marketingStrategies: false,
      niche: false,
      ninetyDays: false,
      oneYear: false,
      purpose: false,
      tenYear: false,
      threeYear: false,
      issuesList: false,
      purposeWhyWeExist: false,
      coreBusiness: false,
      pinnacleTopOfMountain: false,
      quarterlyTheme: false,
      flywheelDesign: false,
      oneLineMarketingPhrase: false,
    };
  }

  export function allPinnacleFalse(): Partial<CascadedSections> & { brandPromises: boolean } {
    return {
      purposeWhyWeExist: false,
      coreBusiness: false,
      pinnacleTopOfMountain: false,
      quarterlyTheme: false,
      flywheelDesign: false,
      oneLineMarketingPhrase: false,
      brandPromises: false,
    };
  }
}
