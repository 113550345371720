<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="vision" />
      <span data-cy="vision-language_card-title">{{ vision.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="vto">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="vision.route + ' Page Name'"
          data-cy="vision-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="vision-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ vision.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="vision.item"
          data-cy="vision-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="vision-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ vision.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="vision.items"
          data-cy="vision-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="vision-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ vision.vision }} (tab):</mat-label>
        <input
          #visionInput
          matInput
          formControlName="vision"
          required
          [attr.aria-label]="vision.vision"
          data-cy="vision-language_tab-input-field" />
        <ninety-reset-btn
          *ngIf="visionInput.value !== resetLanguage?.vision"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('vision')"
          [disabled]="readonly"
          data-cy="vision-language_tab-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ vision.traction }} (tab):</mat-label>
        <input
          #traction
          matInput
          formControlName="traction"
          required
          [attr.aria-label]="vision.traction"
          data-cy="vision-language_goals-input-field" />
        <ninety-reset-btn
          *ngIf="traction.value !== resetLanguage?.traction"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('traction')"
          [disabled]="readonly"
          data-cy="vision-language_goals-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ vision.swot }} (tab):</mat-label>
        <input
          #swot
          matInput
          formControlName="swot"
          required
          [attr.aria-label]="vision.swot"
          data-cy="vision-language_swot-input-field" />
        <ninety-reset-btn
          *ngIf="swot.value !== resetLanguage?.swot"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('swot')"
          [disabled]="readonly"
          data-cy="vision-language_swot-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
