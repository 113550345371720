import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { OfflineService } from '../services/offline.service';
import { DatadogLogger } from '../vendor/datadog';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector, private datadogLogger: DatadogLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((caughtError: unknown) => {
        const err: any = caughtError;
        const { errorMessage = null } = err?.error?.body ? JSON.parse(err.error.body) : {};
        if (err?.error?.errorId)
          this.datadogLogger.error({
            message: 'http_request_error',
            error: err,
            data: {
              url: err?.url,
              requestId: request.headers.get('x-ninety-request-id'),
              errorId: err?.error?.errorId,
            },
          });
        const error = {
          ...err,
          toString: () => `${err.error.message || err.error.errorMessage || errorMessage || err.statusText || ''}`,
        };

        const offlineService = this.injector.get(OfflineService);
        if (!offlineService.isOffline$.value && err?.status === 0) offlineService.check().subscribe();

        return throwError(error);
      })
    );
  }
}
