import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { EmptyListViewModule } from '@ninety/_layouts/components/empty-list-view/empty-list-view.module';
import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/inputs/quick-filter/quick-filter.component';
import { PaginationComponent } from '@ninety/ui/legacy/components/pagination/pagination.component';
import { WidgetSkeletonComponent } from '@ninety/ui/legacy/components/skeletons/compositions/widget-skeleton.component';
import { ActionBarComponent } from '@ninety/ui/legacy/components/toolbars/action-bar/action-bar.component';

import { MeetingsTableComponent } from '../meetings-table/meetings-table.component';

import { ActiveMeetingComponent } from './active-meeting/active-meeting.component';
import { MeetingsPageFacade } from './meetings-page.facade';

@Component({
  selector: 'ninety-meetings',
  standalone: true,
  templateUrl: './meetings-page.component.html',
  styleUrls: ['./meetings-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ActionBarComponent,
    QuickFilterComponent,
    TerraIconModule,
    ButtonComponent,
    LetDirective,
    MeetingsTableComponent,
    WidgetSkeletonComponent,
    CardModule,
    EmptyListViewModule,
    PaginationComponent,
    ActiveMeetingComponent,
  ],
})
export class MeetingsPageComponent {
  facade = inject(MeetingsPageFacade);

  readonly WidgetTemplateType = WidgetTemplateType;

  LOADING_SKELETON_ROWS = 2;
}
