import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { LoginSharedStateActions } from '@ninety/login/_state/shared/login-shared-state.actions';

import { LoginSharedStateModel, initialLoginSharedState } from './login-shared-state.model';

export const loginSharedStateReducer = createReducer<LoginSharedStateModel>(
  initialLoginSharedState,
  on(LoginSharedStateActions.mapLoginQueryParams, (state, { params }): LoginSharedStateModel => {
    const newState: LoginSharedStateModel = cloneDeep(state);

    newState.queryParams = params;
    newState.isFederated = params.urlHash?.length > 0 || params.idToken?.length > 0;
    newState.prefillFields.firstName = params.firstName ?? params.decodedToken.firstName ?? '';
    newState.prefillFields.lastName = params.lastName ?? params.decodedToken.lastName ?? '';
    newState.prefillFields.email = params.email ?? params.decodedToken.email ?? '';
    newState.isSpinning = false;

    return newState;
  })
);
