import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { FilterService } from '../../../_core/services/filter.service';
import { SessionStorageService } from '../../../_core/services/storage.service';
import { TeamsApiService } from '../../../_core/services/team-api.service';
import { appActions } from '../../app.actions';
import { selectCurrentUser } from '../users/users-state.selectors';

import { teamsStateActions } from './teams-state.actions';

@Injectable()
export class TeamsStateEffects {
  constructor(
    private actions$: Actions,
    private teamsApiService: TeamsApiService,
    private sessionStorageService: SessionStorageService,
    private filterService: FilterService,
    private store: Store
  ) {}

  getAllTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(teamsStateActions.getAll),
      switchMap(() => this.teamsApiService.getTeams()),
      map(teams => teamsStateActions.getAllSuccess({ teams })),
      catchError((error: unknown) => of(teamsStateActions.getAllFailed({ error })))
    )
  );

  setInitialTeamOnFilterToolbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(teamsStateActions.init),
      concatLatestFrom(() => this.store.select(selectCurrentUser).pipe(filter(cu => !!cu))),
      map(([action, currentUser]) => ({ teams: action.teams, currentUser })),
      map(({ teams, currentUser }) => {
        // set default team as selected team when logging in, as long as the user still belongs to that team
        const sessionTeamId = this.sessionStorageService.get('lastAccessedTeamId');
        let initialTeamId = sessionTeamId ?? currentUser.settings.defaultTeamId;
        const userStillOnTeam = currentUser.teams.some(t => t.teamId === initialTeamId);
        if (!userStillOnTeam && currentUser?.teams.length > 0) {
          initialTeamId = currentUser.teams[0].teamId;
        }

        const initialTeam = teams.find(team => team._id === initialTeamId);
        if (initialTeam) {
          this.sessionStorageService.set('lastAccessedTeamId', initialTeam._id);
          this.filterService.setTeam(initialTeam);
          this.filterService.setTeamId(initialTeam._id);
        }
        return appActions.noop();
      })
    )
  );
}
