import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ninety-button-group',
  standalone: true,
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      @use 'terra';

      :host {
        display: flex;
        align-items: center;
        margin: 0 4px;
        ::ng-deep {
          ninety-button:not(:last-child) {
            margin-right: 0;
            button {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            &[filled] button {
              border-right: 1px solid terra.color('text-invert');
            }
            &[stroked] button {
              border-right: none !important;
            }
          }
          ninety-button:not(:first-child) {
            margin-left: 0;
            button {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
          ninety-button[icon].dropdown button {
            padding: 0 3px;
          }
        }
      }
    `,
  ],
})
export class ButtonGroupComponent {}
