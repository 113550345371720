import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import {
  ChangeSeatHolderUserProps,
  GlobalSeatHolderIdentifier,
} from '@ninety/accountability-chart/_state/chart/actions/seat-holder.actions';
import { ResponsibilityChartSelectors } from '@ninety/accountability-chart/_state/chart/responsibility-chart.selectors';
import { SeatHolder, SeatHolderCreatePick } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-holder';

@Injectable({
  providedIn: 'root',
})
export class SeatHolderApiService {
  private readonly legacySeatHoldersApi = `/api/v4/SeatHolders`;

  constructor(private http: HttpClient, private store: Store) {}

  getAllSeatHoldersInCompany(): Observable<SeatHolder[]> {
    return this.http.get<SeatHolder[]>(this.legacySeatHoldersApi);
  }

  createSeatHolder(createParams: SeatHolderCreatePick): Observable<SeatHolder> {
    const { chartId, ...dto } = createParams;
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      map(builder => builder.seatHolders().toUrl()),
      switchMap(url => this.http.post<SeatHolder>(url, dto))
    );
  }

  reAssignSeatHolder({ newUserId, _id }: ChangeSeatHolderUserProps): Observable<void> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      map(builder => builder.seatHolders().append(_id).toUrl()),
      switchMap(url => this.http.patch<void>(url, { userId: newUserId }))
    );
  }

  deleteSeatHolder({ _id }: Pick<GlobalSeatHolderIdentifier, '_id'>): Observable<void> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      map(builder => builder.seatHolders().append(_id).toUrl()),
      switchMap(url => this.http.delete<void>(url))
    );
  }
}
