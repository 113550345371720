import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { cloneDeep as _cloneDeep } from 'lodash';
import { switchMap } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { DetailService } from '@ninety/detail-view/_services/detail.service';

import { DetailViewActions } from './detail-view.actions';

const urlsToDisableDetailScrollbar = [
  '/assessments/admin',
  '/assessments/my-assessments',
  '/1-on-1/discussions/quarterly',
  '/1-on-1/discussions/annual',
  '/knowledge',
];

const disableDetailScrollbarForUrl = (url: string) => urlsToDisableDetailScrollbar.some(_url => url.includes(_url));

// TODO move to root state
@Injectable()
export class DetailViewEffects {
  openDetail$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(DetailViewActions.opened),
        switchMap(action => this.detailService.open(_cloneDeep(action.config)))
      ),
    { dispatch: false }
  );

  closeDetail$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(DetailViewActions.close),
        tap(() => this.detailService.close(false).pipe(take(1)).subscribe())
      ),
    { dispatch: false }
  );

  disableScrollbar$ = createEffect(() =>
    this.action$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: RouterNavigatedAction) =>
        disableDetailScrollbarForUrl(action.payload.routerState.url)
          ? DetailViewActions.disableScrollbar()
          : DetailViewActions.enableScrollbar()
      )
    )
  );

  constructor(private action$: Actions, private detailService: DetailService<any>) {}
}
