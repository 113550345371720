import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform<T>(values: Array<T>, property?: any, inPlace = true): Array<T> {
    if (!values || !property) return values;
    if (!inPlace) values = [...values];

    return values.sort((a, b) => {
      if (property === 'dueDate' || property === 'archivedDate')
        return new Date(a[property]).getTime() - new Date(b[property]).getTime();
      return a[property] - b[property];
    });
  }
}
