import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

export function filterForChanges<T>(obj: Partial<T>, copy: T): Partial<T> {
  const update = _cloneDeep(obj);
  Object.keys(update).forEach(k => {
    if (_isEqual(update[k], copy[k])) delete update[k];
  });
  return update;
}
