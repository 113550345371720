import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { AuthActions } from '@ninety/ui/legacy/state/app-global/auth/auth.actions';

import { GainsightPXService } from '../gainsight-px.service';

@Injectable()
export class GainsightPXEffects {
  $resetSessionOnLogout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        tap(() => this.gainsightPXService.resetSession())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private gainsightPXService: GainsightPXService) {}
}
