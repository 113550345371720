import { Pipe, PipeTransform } from '@angular/core';

import { Country } from '../models/country.model';

@Pipe({
  name: 'countrySearch',
  standalone: true,
})
export class CountrySearchPipe implements PipeTransform {
  // country | search:'searchCriteria'
  transform(countries: Country[], searchCriteria?: string): Country[] {
    if (!searchCriteria) {
      return countries;
    }

    return countries.filter(country => country.name.toLowerCase().includes(searchCriteria.toLowerCase()));
  }
}
