export enum MeetingAgendaType {
  weekly = 'weeklyMeetings',
  quarterly = 'quarterlyMeetings',
  annualDayOne = 'annualDayOne',
  annualDayTwo = 'annualDayTwo',
  focusDay = 'focusDay',
  visionBuildingDayOne = 'visionBuildingDayOne',
  visionBuildingDayTwo = 'visionBuildingDayTwo',
  custom = 'custom',
}
