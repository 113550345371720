import { updateEnvironmentBasedOnHost } from './_utils';
import { DevRootEnvironment } from './environment.dev';
import { NinetyFrontEndEnvironment } from './environment.interface';

let qaEnv: NinetyFrontEndEnvironment = {
  ...DevRootEnvironment,

  authenticationFlowType: 'USER_SRP_AUTH',
  cognitoDomain: 'ninety-qa1.auth.us-east-1.amazoncognito.com',
  redirectProtocol: 'https',
  region: 'us-east-1',
  userPoolId: 'us-east-1_fjAGUvbM6',
  userPoolWebClientId: '1u1c7n6hv769li3rpqhef32e1s', // qa1-app-client-v2
};

qaEnv = updateEnvironmentBasedOnHost(qaEnv);

export const environment = qaEnv;
