import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BusinessOperatingSystem } from '../../../_shared/models/company/business-operating-system.enum';
import { CompanyLanguage, CustomLanguage } from '../../../_shared/models/language/custom-language';

export const LanguageActions = createActionGroup({
  source: 'Language',
  events: {
    Init: props<{ companyLanguage: CompanyLanguage; defaultBOSLanguage: CustomLanguage }>(),
    'Get Reset Language': emptyProps(),
    'Set Reset Language': props<{ resetLanguage: CompanyLanguage }>(),

    /** Partner Hub company config (not custom template) */
    'Update Custom Language': props<{ companyLanguage: CompanyLanguage; bos: BusinessOperatingSystem }>(),

    /** Checks if company is same as current company and if so, updates the store */
    'Update Custom Language Success': props<{ companyLanguage: CompanyLanguage }>(),
    'Update Custom Language Failure': props<{ error: unknown }>(),

    /** Company Settings / Our Language */
    'Update Current Company Language': props<{ companyLanguage: CompanyLanguage }>(),
    'Update Current Company Language Success': props<{ companyLanguage: CompanyLanguage }>(),
  },
});
