import { ErrorWithId } from '../models/errors/error-with-id';

export class PathValidationError extends ErrorWithId {
  public static fromFailedPathSegment<T extends PathBuilder>(
    builder: T,
    value: string,
    key = 'segment'
  ): PathValidationError {
    // TODO how does this work with structured logging?
    return new PathValidationError(`Invalid ${key} for path '${builder.toString()} - '${value}'`);
  }

  constructor(message: string, name = 'PathValidationError') {
    super(message, name);
  }
}

export class PathBuilder {
  constructor(protected readonly parts: ReadonlyArray<string | number> = []) {
    this.parts = parts.map((part, index) => this.getValidatedValue(part, `parts[${index}]`));
  }

  /** Append a new part to the path. Must be a truthy, non-empty string, else PathValidationError */
  public append(value: string | number, key = 'segment'): PathBuilder {
    value = this.getValidatedValue(value, key);
    return new PathBuilder([...this.parts, value]);
  }

  /** Turn the builder into a string, optionally with a leading slash */
  public toUrl(withPrefix = true): string {
    const prefix = withPrefix ? '/' : '';
    return `${prefix}${this.toString()}`;
  }

  public toString(): string {
    return this.parts.join('/');
  }

  protected getValidatedValue(value: string | number, key: string): string {
    if (typeof value === 'number') value = value?.toString();

    value = value?.trim();
    if (!value || value.length === 0) throw PathValidationError.fromFailedPathSegment(this, value, key);
    return value;
  }
}

// export class WithId extends PathBuilder {}
