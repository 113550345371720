import { Injectable } from '@angular/core';

import { BreakpointObserverConfig } from '../element-resize-aware.model';

interface IBreakpointObserverConfigFactory {
  getDefault(): BreakpointObserverConfig;

  merge(...configs: Partial<BreakpointObserverConfig>[]): BreakpointObserverConfig;
}

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverConfigFactory implements IBreakpointObserverConfigFactory {
  getDefault(): BreakpointObserverConfig {
    return {
      debounceTime: 50,
      disableDebounce: false,
      emitOnZeroWidth: false,
    };
  }

  merge(...configs: Partial<BreakpointObserverConfig>[]): BreakpointObserverConfig {
    return Object.assign(this.getDefault(), ...configs);
  }
}

@Injectable()
export class BreakpointObserverConfigFactoryForTesting implements IBreakpointObserverConfigFactory {
  getDefault(): BreakpointObserverConfig {
    return {
      debounceTime: 0,
      disableDebounce: true,
      emitOnZeroWidth: false,
    };
  }

  merge(...configs: Partial<BreakpointObserverConfig>[]): BreakpointObserverConfig {
    // Debounce can cause tests and storybook screenshots to fail, so it must always be disabled during testing.
    return Object.assign(this.getDefault(), ...configs, { disableDebounce: true });
  }
}
