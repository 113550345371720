import { createSelector } from '@ngrx/store';

import { FeatureFlagStateKey, selectEntitiesState } from '..';

import { initialFeatureFlagState } from './feature-flag-state.model';

export const selectFeatureFlags = createSelector(selectEntitiesState, appEntities =>
  appEntities ? appEntities[FeatureFlagStateKey] : initialFeatureFlagState
);
export const selectFeatureFlag = (key: string) => createSelector(selectFeatureFlags, flags => flags[key]);
