import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';

import { MaterialMdcModule } from '../../../_angular-material/material-mdc.module';
import { ErrorService } from '../../../_core/services/error.service';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { UpdateSubscriptionQuantityRequestModel } from '../../../_shared/models/billingv2/modify-seats-request';
import { BillingStateActions } from '../../../_state/app-global/billing/billing-state.actions';
import { selectCompanyBillingCounts } from '../../../_state/app-global/billing/billing-state.selectors';
import { SubscriptionActions } from '../../../_state/app-global/company/subscription/subscription-state.actions';
import { selectIsTrialSubscription } from '../../../_state/app-global/company/subscription/subscription-state.selectors';

@Component({
  selector: 'ninety-manage-seats',
  templateUrl: './manage-seats.component.html',
  styleUrls: ['./manage-seats.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatInputModule, FormsModule, CommonModule, MaterialMdcModule, TerraIconModule],
})
export class ManageSeatsComponent implements OnInit, OnDestroy {
  standardSeatCountInput = 0;
  initial: BillingCounts = { activeBillingUsers: 0, assignableSeats: 0, seatsPaidFor: 0 };
  costPeriodDescriptor: 'Monthly' | 'Monthly*' | 'Annual';
  submitText = 'Next';
  isTrialingSubscription: boolean;

  subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ManageSeatsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BillingCounts,
    private errorService: ErrorService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(BillingStateActions.getCompanyBillingCounts());
    this.subscription.add(
      this.store
        .select(selectCompanyBillingCounts)
        .pipe(
          tap(response => {
            this.initial = {
              activeBillingUsers: response.activeBillingUsers,
              assignableSeats: response.assignableSeats,
              seatsPaidFor: response.seatsPaidFor,
            };
            this.standardSeatCountInput = response.seatsPaidFor;
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.store
        .select(selectIsTrialSubscription)
        .pipe(
          tap(isTrialingSubscription => {
            this.isTrialingSubscription = isTrialingSubscription;
          })
        )
        .subscribe()
    );
  }
  submit(): void {
    if (this.standardSeatCountInput < this.initial.activeBillingUsers) {
      this.errorService.notify(null, 'Cannot reduce seat count below active billed users');
      return;
    }

    if (this.isTrialingSubscription) {
      this.processFreeTrialSubscriptionSeatCountUpdate();
    } else {
      this.processActiveSubscriptionQuantityUpdate();
    }
  }

  private processFreeTrialSubscriptionSeatCountUpdate() {
    this.store.dispatch(
      SubscriptionActions.autoIncrementSubscriptionQuantityBillingPage({ quantity: this.standardSeatCountInput })
    );
  }

  private processActiveSubscriptionQuantityUpdate() {
    const request: UpdateSubscriptionQuantityRequestModel = {
      quantity: this.standardSeatCountInput,
    };

    this.store.dispatch(
      SubscriptionActions.requestUpdateSubscriptionQuantityUrl({ updateSubscriptionQuantityRequest: request })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
