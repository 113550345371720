import { TerraIconName } from '@ninety/terra';

import { BusinessOperatingSystem } from '../company/business-operating-system.enum';

import { MasteryUpdated } from './mastery-dialog-data';
import { MasteryMessageType } from './mastery-message-type';

export interface Mastery {
  _id: string;
  title: string;
  description: string;
  terraIcon: TerraIconName;
  type?: string;
  url: string;
  ordinal: number;
  companyId?: string;
  templateId?: string;
  parentId?: string;
  deleted?: boolean;
  archived?: boolean;

  /** todo - clean this up later to work with MasteryWithStatus */
  mastered?: boolean;
}

export interface TeamMasteredMastery {
  masteryId: string;
  masteredAt: Date;
  masteredBy: string;
}
export class MasterySettings {
  enabled: boolean;
  showIcons?: boolean;
  showLinks?: boolean;

  constructor(bos?: BusinessOperatingSystem) {
    Object.assign(this, DEFAULT_MASTERY_SETTINGS[bos || BusinessOperatingSystem.ninetyOS]);
  }
}

export const DEFAULT_MASTERY_SETTINGS = {
  [BusinessOperatingSystem.ninetyOS]: { enabled: true, showIcons: true, showLinks: true },
  [BusinessOperatingSystem.eos]: { enabled: true, showIcons: false, showLinks: false },
  [BusinessOperatingSystem.pinnacle]: { enabled: true, showIcons: false, showLinks: false },
  [BusinessOperatingSystem.fireproof]: { enabled: false, showIcons: true, showLinks: true },
  [BusinessOperatingSystem.empire]: { enabled: false, showIcons: true, showLinks: true },
};

export type MasteryRealTimeMessage =
  | {
      messageType: MasteryMessageType.mastered;
      document: { teamId: string; masteries: TeamMasteredMastery[] };
    }
  | {
      messageType: MasteryMessageType.update;
      document: MasteryUpdated;
    }
  | {
      messageType: MasteryMessageType.delete;
      document: { masteryId: string };
    }
  | {
      messageType: MasteryMessageType.create;
      document: Mastery;
    }
  | {
      messageType: MasteryMessageType.archive;
      document: { masteryId: string; archived: boolean };
    }
  | {
      messageType: MasteryMessageType.refresh;
      document: null;
    };
