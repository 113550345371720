import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { ElementResizeAwareDirective } from './element-resize-aware.directive';

type ParsedBreakpoints = {
  breakpoints: number[];
  maxClass: string;
  inverse: Record<number, string>;
};

/**
 * Adds a class to the element based on its width. Useful for creating responsive designs based on an **elements**
 * width. You can think of it as a way to write container-like CSS for elements before we are comfortable using the
 * container syntax (which we are currently not, due to the lack of broad support in various browsers).
 *
 * General usage would be:
 * 1. Add both directives to an element
 * 2. Apply different styling in the component's CSS based on the parents
 *
 * Example:
 *
 * ```css
 * .small { flex: 1 }
 * .medium { flex: 2 }
 * ```
 */
@Directive({
  selector: '[ninetyAddClassBasedOnWidth]',
  hostDirectives: [ElementResizeAwareDirective],
  standalone: true,
  exportAs: 'ninetyAddClassBasedOnWidth',
})
export class AddClassBasedOnWidthDirective<T extends string> implements OnChanges, AfterViewInit, OnDestroy {
  private subscription: Subscription;
  private previousClass: string;

  private currentClassBS = new ReplaySubject<T>(1);

  public get currentClass$(): Observable<T> {
    return this.currentClassBS.asObservable();
  }

  /**
   * A Record of class names to the breakpoint under which they should be applied. The breakpoint is the maximum width
   * at which the class should be applied. The final entry in the Record is the class to apply when the element is
   * wider than all breakpoints - its value must be 'max'.
   *
   * Example: { 'small': 100, 'medium': 500, 'large': 'max' }
   *
   * Widths    | Class
   * ----------|------
   * 0 - 99    | 'small'
   * 100 - 499 | 'medium'
   * 500+      | 'large'
   */
  @Input() ninetyAddClassBasedOnWidth: Record<T, number | 'max'>;

  constructor(
    private host: ElementRef,
    private renderer: Renderer2,
    private elementResizeAware: ElementResizeAwareDirective
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Skip the first change - need to wait for the element to be rendered
    if (changes.ninetyAddClassBasedOnWidth.firstChange) return;

    // Single input, safe to assume the only thing that changed is the input
    this.configureWidthWatcher();
  }

  ngAfterViewInit(): void {
    this.configureWidthWatcher();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private configureWidthWatcher() {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = new Subscription();

    const { breakpoints, maxClass, inverse } = this.parseBreakpoints();

    this.subscription.add(
      this.elementResizeAware
        .getManyBreakpointObservers(breakpoints)
        .subscribe((breakpoints: Record<number, boolean>) => {
          // Assuming the breakpoints are ordered, find the first breakpoint that is true
          const matchingBreakpoint = Object.entries(breakpoints).find(([_minWidth, matches]) => matches);

          // If it's not found, use the maxClass - if a match was found, lookup the matching breakpoint
          const classToApply: T = matchingBreakpoint ? inverse[matchingBreakpoint[0]] : maxClass;
          this.applyClass(classToApply);
        })
    );
  }

  private parseBreakpoints() {
    const entries = Object.entries(this.ninetyAddClassBasedOnWidth);
    const finalIndex = entries.length - 1;

    return entries.reduce(
      (acc: ParsedBreakpoints, [className, breakPoint]: [T, number | 'max'], index) => {
        // Final case
        if (finalIndex === index) {
          if (breakPoint !== 'max') throw new Error('Last breakpoint must be `max`');

          acc.maxClass = className;
          return acc;
        }

        // Regular case
        if (breakPoint === 'max') throw new Error('Only the final breakpoint can be `max`');

        // breakPoint += 1; // ElementResizeAware uses strictly less than, so we need to increment the breakpoint by 1
        acc.inverse[breakPoint] = className;
        acc.breakpoints.push(breakPoint);

        return acc;
      },
      { breakpoints: [], maxClass: '', inverse: {} }
    );
  }

  private applyClass(classToApply: T) {
    if (this.previousClass) {
      if (this.previousClass === classToApply) return;

      this.renderer.removeClass(this.host.nativeElement, this.previousClass);
    }

    // Class is either new or different
    this.previousClass = classToApply;
    this.currentClassBS.next(classToApply);
    this.renderer.addClass(this.host.nativeElement, classToApply);
  }
}
