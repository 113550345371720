import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { ChannelService } from '../../../_core';
import { selectCurrentUserId } from '../../app-entities/users/users-state.selectors';

import { RealTimeActions } from './real-time.actions';

@Injectable()
export class RealTimeEffects {
  actions$ = inject(Actions);
  channelService = inject(ChannelService);
  store = inject(Store);

  /** Send a message via Pub-nub. Do not use to broadcast messages over 32KB */
  sendMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RealTimeActions.sendMessage),
        concatLatestFrom(() => this.store.select(selectCurrentUserId)),
        tap(([{ channelId, message }, userId]) => this.channelService.publishMessage(userId, channelId, message))
      ),
    { dispatch: false }
  );
}
