import { RoleCode } from '../_shared/role-code';

import { PhoneNumber } from './phone-number';
import { UserTeam } from './user-team';

export class InviteUserPayload {
  active: boolean;
  companyId: string;
  email?: string;
  pictureUrl?: string;
  firstName: string;
  hasBeenInvited: boolean;
  lastName: string;
  phoneNumbers: PhoneNumber[];
  roleCode: RoleCode;
  teams: UserTeam[];
  isImplementer: boolean;
  userId?: string; //if the user already exists

  constructor({
    active,
    companyId,
    email,
    firstName,
    hasBeenInvited,
    lastName,
    phoneNumber,
    roleCode,
    teams,
    isImplementer,
    userId,
    pictureUrl,
  }: {
    active: boolean;
    companyId: string;
    email?: string;
    firstName: string;
    hasBeenInvited: boolean;
    lastName: string;
    phoneNumber?: string;
    roleCode: RoleCode;
    teams: UserTeam[];
    isImplementer: boolean;
    userId?: string; //if the user already exists
    pictureUrl?: string;
  }) {
    this.active = active;
    this.companyId = companyId;
    this.email = email;
    this.firstName = firstName;
    this.hasBeenInvited = hasBeenInvited;
    this.lastName = lastName;
    this.phoneNumbers = phoneNumber ? [new PhoneNumber(phoneNumber, '0000')] : [];
    this.roleCode = roleCode;
    this.teams = teams;
    this.isImplementer = isImplementer;
    this.userId = userId;
    this.pictureUrl = pictureUrl;
  }
}
