import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotEmptyObject',
})
export class IsNotEmptyObjectPipe implements PipeTransform {
  transform(obj: any): unknown {
    return !(obj && Object.keys(obj).length === 0);
  }
}
