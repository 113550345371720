import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy as _uniqBy } from 'lodash';

@Pipe({
  name: 'uniqueBy',
})
export class UniqueByPipe implements PipeTransform {
  transform<T>(list: Array<T>, key: keyof T): Array<T> {
    if (!list || !key) return list;
    return _uniqBy(list, key);
  }
}
