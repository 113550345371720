import { Directive, Input, TemplateRef } from '@angular/core';

import { TemplateMatcher, TemplateMatcherFunction } from '../template-matcher.interface';

export type TemplateIfDirectiveInput = TemplateMatcherFunction | Record<string, any>;

@Directive({
  selector: 'ng-template[ninetyTemplateIf]',
})
export class TemplateIfDirective implements TemplateMatcher {
  constructor(public templateRef: TemplateRef<any>) {}

  @Input() ninetyTemplateIf?: TemplateIfDirectiveInput;

  matches(state: Record<string, any>): boolean {
    if (!this.ninetyTemplateIf) return false;

    return TemplateIfDirective.isTemplateMatcherFunction(this.ninetyTemplateIf)
      ? this.ninetyTemplateIf.call(this, state)
      : this.evaluateStateConditionsFromRecord(state);
  }

  private evaluateStateConditionsFromRecord(state: Record<string, any>) {
    const rejections = Object.entries(this.ninetyTemplateIf)
      .map(([k, valueInMatcher]) => state[k] === valueInMatcher)
      .filter(matchesState => !matchesState);

    return rejections.length === 0;
  }

  private static isTemplateMatcherFunction(input: TemplateIfDirectiveInput): input is TemplateMatcherFunction {
    return typeof input === 'function';
  }
}
