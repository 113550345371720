import { LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { LinkedItems } from '@ninety/ui/legacy/shared/models/linked-items/linked-items-response.model';
import { UnlinkedItemFromRealtime } from '@ninety/ui/legacy/shared/models/linked-items/linked-items.model';

export interface LinkedItemsStateModel {
  _id: string;
  type: LinkedItemTypeEnum;
  loading: boolean;
  linkedItems: LinkedItems;
  unlinkedItem: UnlinkedItemFromRealtime;
  error: boolean;

  channelId: string;
  shouldBroadcast: boolean;
}

export const linkedItemsStateModel: LinkedItemsStateModel = {
  _id: '',
  type: null,
  loading: false,
  linkedItems: null,
  unlinkedItem: null,
  error: false,
  channelId: '',
  shouldBroadcast: false,
};
