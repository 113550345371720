import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayAtTimePipe',
})
/** Returns a day and time formatted like Wednesday, August 30, 2023 at 11:13 AM */
export class DayAtTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(date: Date | string): string {
    return `${this.datePipe.transform(date, 'fullDate')} at ${this.datePipe.transform(date, 'shortTime')}`;
  }
}
