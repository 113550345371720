<ng-container [ngSwitch]="templateType">
  <ng-container *ngSwitchCase="SnackbarTemplateType.adminEmail">
    <div *ngIf="data.payload?.length; then message; else defaultMessage"></div>
    <ng-template #message>
      <div class="adminEmail">
        <div class="message-title">Subscription expired. Please contact an administrator by email:</div>
        <div *ngFor="let adminEmail of data.payload">
          <a href="mailto:{{ adminEmail.email }}"> {{ displayFullName(adminEmail.name) }}</a>
        </div>
      </div>
    </ng-template>
    <ng-template #defaultMessage>
      <div class="adminEmail">Subscription expired. Please contact an administrator.</div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.newMeeting">
    <div *ngIf="data.payload; then message; else defaultMessage"></div>
    <ng-template #message>
      <div class="newMeeting">
        <div class="message-title">
          {{ data.payload.meetingType }} has started for {{ data.payload.team }}.
          <a (click)="navigateToMeeting()">Click here</a> if you want to join.
        </div>
      </div>
    </ng-template>
    <ng-template #defaultMessage>
      <div class="newMeeting">
        <div class="message-title">A new meeting has started. Go to Meetings section if you want to join</div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.newConversationMeeting">
    <ng-template [ngIf]="data.payload" [ngIfElse]="defaultMessage">
      <div class="newMeeting">
        <div class="message-title">
          {{ data.payload.type === ConversationType.annual ? 'An' : 'A' }} {{ data.payload.type | titlecase }}
          {{ data.payload.feedbackCompanyLanguage?.item ?? 'Discussion' }} with {{ data.payload.managerName }} has
          started. <a (click)="navigateToConversationMeeting(data.payload._id, data.payload.bos)">Click here</a> to
          join.
        </div>
      </div>
    </ng-template>
    <ng-template #defaultMessage>
      <div class="newMeeting">
        <div class="message-title">A new Discussion has started.</div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.offline">
    <div (click)="offlineService.check(0).subscribe()" class="adminEmail">
      <div>Offline -- checking{{ data.retrySeconds ? ' in ' + data.retrySeconds + ' seconds' : '' }}...</div>
      <div>Click to check now</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.online">
    <div class="adminEmail">Online</div>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.success">
    <div class="success flex-layout-row" data-cy="snackbar-success">
      <terra-icon icon="check-circle" class="iconSuccess" />
      <div class="message-title">{{ data.payload.message }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.successWithAction">
    <div class="success-action flex-layout-row" data-cy="snackbar-success-with-action">
      <terra-icon icon="check-circle" class="iconSuccess" />
      <div class="message-title">{{ data.payload.message }}</div>
      <div class="link">
        <a [attr.href]="data.payload.link.path ? data.payload.link.path : '#'">{{ data.payload.link.text }}</a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="SnackbarTemplateType.warning">
    <div class="warning flex-layout-row" data-cy="snackbar-warning">
      <terra-icon [icon]="getIconKey()" [variant]="iconVariant" size="20" class="icon-warning" />
      <div class="message-title">{{ data.payload.message }}</div>
      <div class="link">
        <a [attr.href]="data.payload.link.path ? data.payload.link.path : '#'">{{ data.payload.link.text }}</a>
      </div>
    </div>
  </ng-container>
</ng-container>
<div class="dismiss">
  <terra-icon icon="x" (click)="snackBarRef.dismiss()" />
</div>
