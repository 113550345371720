<mat-card class="item-card">
  <mat-card-title class="header">
    <div class="content">
      <ng-content select="[item-card-header]"></ng-content>
    </div>
    <div class="close">
      <button
        (click)="onClickClose()"
        mat-button
        matTooltip="Close"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip"
        aria-label="Close team details">
        <terra-icon icon="x" />
      </button>
    </div>
  </mat-card-title>
  <mat-card-subtitle>
    <ng-content select="[item-card-subtitle]"></ng-content>
  </mat-card-subtitle>

  <mat-card-content class="item-content">
    <ng-content select="[item-card-content]"></ng-content>
  </mat-card-content>

  <mat-card-footer>
    <ng-content select="[item-card-footer]"></ng-content>
  </mat-card-footer>
</mat-card>
