<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="responsibilities" />
      <span data-cy="responsibilities-language_card-title">{{ responsibilities.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="acChart">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="responsibilities.route + ' Page Name'"
          data-cy="responsibilities-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="responsibilities-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.seat }} (singular):</mat-label>
        <input
          #seat
          matInput
          formControlName="seat"
          required
          [attr.aria-label]="responsibilities.seat"
          data-cy="responsibilities-language_seat-singular-input-field" />
        <ninety-reset-btn
          *ngIf="seat.value !== resetLanguage?.seat"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('seat')"
          [disabled]="readonly"
          data-cy="responsibilities-language_seat-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.seats }} (plural):</mat-label>
        <input
          #seats
          matInput
          formControlName="seats"
          required
          [attr.aria-label]="responsibilities.seats"
          data-cy="responsibilities-language_seat-plural-input-field" />
        <ninety-reset-btn
          *ngIf="seats.value !== resetLanguage?.seats"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('seats')"
          [disabled]="readonly"
          data-cy="responsibilities-language_seat-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.seatHolder }} (singular):</mat-label>
        <input
          #seatHolder
          matInput
          formControlName="seatHolder"
          required
          [attr.aria-label]="responsibilities.seatHolder"
          data-cy="responsibilities-language_seat-holder-singular-input-field" />
        <ninety-reset-btn
          *ngIf="seatHolder.value !== resetLanguage?.seatHolder"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('seatHolder')"
          [disabled]="readonly"
          data-cy="responsibilities-language_seat-holder-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.seatHolders }} (plural):</mat-label>
        <input
          #seatHolders
          matInput
          formControlName="seatHolders"
          required
          [attr.aria-label]="responsibilities.seatHolders"
          data-cy="responsibilities-language_seat-holder-plural-input-field" />
        <ninety-reset-btn
          *ngIf="seatHolders.value !== resetLanguage?.seatHolders"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('seatHolders')"
          [disabled]="readonly"
          data-cy="responsibilities-language_seat-holder-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.rolesAndResponsibilities }}:</mat-label>
        <input
          #rolesAndResponsibilities
          matInput
          formControlName="rolesAndResponsibilities"
          required
          [attr.aria-label]="responsibilities.rolesAndResponsibilities"
          data-cy="responsibilities-language_roles-and-responsibilities-input-field" />
        <ninety-reset-btn
          *ngIf="rolesAndResponsibilities.value !== resetLanguage?.rolesAndResponsibilities"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('rolesAndResponsibilities')"
          [disabled]="readonly"
          data-cy="responsibilities-language_roles-and-responsibilities-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.visionary }}:</mat-label>
        <input
          #visionary
          matInput
          formControlName="visionary"
          required
          [attr.aria-label]="responsibilities.visionary"
          data-cy="responsibilities-language_visionary-input-field" />
        <ninety-reset-btn
          *ngIf="visionary.value !== resetLanguage?.visionary"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('visionary')"
          [disabled]="readonly"
          data-cy="responsibilities-language_visionary-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.integrator }}:</mat-label>
        <input
          #integrator
          matInput
          formControlName="integrator"
          required
          [attr.aria-label]="responsibilities.integrator"
          data-cy="responsibilities-language_operator-input-field" />
        <ninety-reset-btn
          *ngIf="integrator.value !== resetLanguage?.integrator"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('integrator')"
          [disabled]="readonly"
          data-cy="responsibilities-language_operator-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.chart }} (singular):</mat-label>
        <input
          #chart
          matInput
          formControlName="chart"
          required
          [attr.aria-label]="responsibilities.chart"
          data-cy="responsibilities-language_chart-singular-input-field" />
        <ninety-reset-btn
          *ngIf="chart.value !== resetLanguage?.chart"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('chart')"
          [disabled]="readonly"
          data-cy="responsibilities-language_chart-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ responsibilities.charts }} (plural):</mat-label>
        <input
          #charts
          matInput
          formControlName="charts"
          required
          [attr.aria-label]="responsibilities.charts"
          data-cy="responsibilities-language_chart-plural-input-field" />
        <ninety-reset-btn
          *ngIf="charts.value !== resetLanguage?.charts"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('charts')"
          [disabled]="readonly"
          data-cy="responsibilities-language_chart-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
