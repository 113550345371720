import { FroalaOptions } from './froala-options';

/**
 * Expand the interface as use-cases occur instead of directly
 * passing library specific key/value pairs.
 *
 * Prefer:
 * options.setMaxChar(count: number)
 *
 * vs.
 * options.set({ maxCharLen: 123 })
 *
 * because if we switch to another editor, then we have to update every place
 * that uses the Froala specific key.
 */
export interface ITextEditorOptions<T> {
  clone(options?: Partial<T>): ITextEditorOptions<T>;
  setPlaceholder(placeholder: string): void;
  setCustomOptions(customOptions: Partial<FroalaOptions>): void;
  resetPlaceholder(): void;
  value(): T;
}

/**
 * Wrapper class that passes details down to the implementation specific
 * class.
 */
export class TextEditorOptions<T> implements ITextEditorOptions<T> {
  constructor(private options: ITextEditorOptions<T>) {}

  clone(options?: Partial<T>): ITextEditorOptions<T> {
    return this.options.clone(options);
  }

  setPlaceholder(placeholder: string): void {
    this.options.setPlaceholder(placeholder);
  }

  setCustomOptions(customOptions: Partial<FroalaOptions>): void {
    this.options.setCustomOptions(customOptions);
  }

  resetPlaceholder(): void {
    this.options.resetPlaceholder();
  }

  value(): T {
    return this.options.value();
  }
}
