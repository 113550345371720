import { SkeletonComponent } from '../skeleton.component';

/**
 * @param line - it will render like a line. This is the same behavior as passing an empty string;
 * @param circle - it will use circle as appearance. Great for avatar skeletons, for example :);
 */
export type SkeletonType = 'line' | 'circle';

/**
 * @param false: it will disable the animation;
 * @param progress - default: it will use it progress as animation;
 * @param pulse: it will use pulse as animation;
 */
export type SkeletonAnimation = 'progress' | 'pulse' | 'false';

/**
 * A CSS style object for custom styling.
 *
 * ```
 * [theme]="{
 *  height: '50px',
 *  'background-color': 'red'
 * }"
 * ```
 */
export type SkeletonTheme = Record<string, string>;

/** A partial {@link SkeletonComponent}, used to pass its inputs around parent/child variants. */
export type SkeletonProps = Partial<SkeletonComponent>;

/** A list of {@link SkeletonProps} */
export type SkeletonPropsArray = SkeletonProps[];

export namespace SkeletonTheme {
  /**
   * To for a box that fills it's parent width and height, pass this style to {@link SkeletonComponent} as the theme.
   * Ensure the parent of the skeleton has style
   */
  export function fillParentWidth(): SkeletonTheme {
    return {
      display: 'flex',
      flex: '1',
      'flex-direction': 'column',

      height: '100%',

      'margin-bottom': '0',
    };
  }
}

export namespace SkeletonConstants {
  export const INIT_COUNT = 1;
  export const INIT_TYPE: SkeletonType = 'line';
  export const INIT_ANIMATION: SkeletonAnimation = 'progress';
}
