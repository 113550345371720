<details [class.disabled]="disabled" (ninetyClickOutside)="onClickOutside()">
  <summary [class.disabled]="disabled" (click)="onClickPill()">
    <div data-cy="quick-filter_options-list-select" class="pill" [class.disabled]="disabled">
      <terra-icon class="pill-icon" *ngIf="pillIcon" [icon]="pillIcon" [size]="pillIconSize" />
      <div
        *ngrxLet="{ text: selectedOptionDisplayText$, counter: counterText$ } as pill"
        class="pill-inner"
        [class.has-content]="pillTitle || pill.text || pill.counter">
        <span class="pill-title" [class.disabled]="disabled" *ngIf="pillTitle">{{ pillTitle }}</span>
        <span class="pill-separator" *ngIf="pillTitle && pill.text">:</span>
        <span class="pill-text" *ngIf="pill.text">{{ pill.text }}</span>
        <terra-counter class="pill-counter" *ngIf="pill.counter">{{ pill.counter }}</terra-counter>
      </div>
      <div class="pill-dropdown-indicator">
        <!-- TODO can we not use the icon here? We could do transitions still and then when we change the icon, this will change too -->
        <svg
          class="pill-dropdown-indicator-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </div>
    </div>
  </summary>
  <div data-cy="quick-filter_options-list" class="options-list" *ngrxLet="optionsLength$ as optionsLength">
    <div *ngIf="multiple && optionsLength > 1" class="option-line-wrapper">
      <div class="option-line select-all">
        <terra-checkbox [ngModel]="allSelected$ | async" (change)="onSelectAll()">{{ selectAllText }}</terra-checkbox>
      </div>
    </div>
    <div
      attr.data-cy="quick-filter_option-{{ option.displayText }}"
      *ngFor="let option of options$ | async; let first = first; trackBy: trackById"
      class="option-line-wrapper"
      [class.single]="!multiple"
      [attr.data-product]="option.displayText === 'Trash' ? 'learning-modules__quick-filter-option-trash' : null"
      (click)="onSingleSelect(option, $event)">
      <div class="option-line" [class.with-border]="first && multiple && optionsLength > 1">
        <terra-checkbox
          *ngIf="multiple; else singleSelect"
          [ngModel]="option.selected"
          [disabled]="option.disabled || disabled"
          (change)="onMultiSelectChange(option)"
          >{{ option.displayText }}</terra-checkbox
        >
        <ng-template #singleSelect>
          <div class="single-option-line" [class.disabled]="option.disabled || disabled">
            <terra-icon *ngIf="option.icon" [icon]="option.icon.name" [size]="option.icon.size" />
            {{ option.displayText }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</details>
