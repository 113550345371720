import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { HubspotActions } from '@ninety/analytics/hubspot/_state/hubspot.actions';
import { LoginSharedStateActions } from '@ninety/login/_state/shared/login-shared-state.actions';
import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { ClearbitEmployeeRange, ClearbitIndustry } from '@ninety/ui/legacy/shared/models/company/clearbit.model';
import { AnonymousLoginStateActions } from '@ninety/ui/legacy/state/app-global/anonymous/anonymous-state.actions';

import { SignupStateActions, SignupTeammatesActions } from './signup-state.actions';
import {
  SignupStateModel,
  initialSignupRegistration,
  initialSignupState,
  initialSignupSteps,
} from './signup-state.model';

export const signupStateReducer = createReducer<SignupStateModel>(
  initialSignupState,

  on(AnonymousLoginStateActions.setPartnerConfiguration, (state, { configuration }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.registration.bos = configuration.bos;
    return newState;
  }),

  on(
    SignupStateActions.startSpinner,
    (state): SignupStateModel => ({
      ...state,
      isSpinning: true,
    })
  ),

  on(
    SignupStateActions.stopSpinner,
    SignupStateActions.stopSpinner,
    (state): SignupStateModel => ({
      ...state,
      isSpinning: false,
    })
  ),

  on(SignupStateActions.checkEmail, (state, { email }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.isSpinning = true;
    newState.api.checkEmail = false;
    newState.api.checkInvites = false;
    newState.registration.email = email;
    newState.steps.welcome.completed = true;

    if (state.registration.bos === BusinessOperatingSystem.ninetyOS) {
      newState.steps.bos.visible = false;
    }
    return newState;
  }),

  on(SignupStateActions.goToStep, (state, { index }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.steps.selectedIndex = index;
    newState.isSpinning = false;
    return newState;
  }),

  on(SignupStateActions.setClearbitDetails, (state, { industry, employees }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.registration.industry = industry as ClearbitIndustry;
    newState.registration.employees = employees as ClearbitEmployeeRange;
    newState.clearBitFound = true;
    return newState;
  }),

  on(SignupStateActions.setPendingCompanyInvites, (state, { companyInvites }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.api.checkInvites = true;
    newState.inviteCompanies = companyInvites;
    newState.steps.invites.visible = newState.inviteCompanies.length > 0;
    newState.isSpinning = false;
    newState.steps.selectedIndex = 1; // This will be company invites or account details
    return newState;
  }),

  on(SignupStateActions.setSignupStatus, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.api.checkEmail = true;
    newState.steps.selectedIndex = 1;
    return newState;
  }),

  on(SignupStateActions.displayInviteStateDialog, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.isSpinning = false;
    return newState;
  }),

  on(SignupStateActions.setAccountDetails, (state, { firstName, lastName, password }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.registration.firstName = firstName;
    newState.registration.lastName = lastName;
    newState.registration.password = password;
    newState.steps.account.completed = true;
    newState.steps.selectedIndex++;
    return newState;
  }),

  on(
    SignupStateActions.setCompanyDetails,
    (state, { companyName, industry, employees, signupCompanyRole, submitEvent }) => {
      const newState: SignupStateModel = cloneDeep(state);
      newState.registration.companyName = companyName;
      newState.registration.industry = industry;
      newState.registration.employees = employees;
      newState.registration.signupCompanyRole = signupCompanyRole;
      newState.signUpEvent = submitEvent;
      newState.steps.company.completed = true;

      if (newState.steps.bos.visible) {
        newState.steps.selectedIndex++;
      }

      return newState;
    }
  ),

  on(SignupStateActions.setCompanyBOS, (state, { bos, howFamiliar, runMeetings }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.registration.bos = bos;
    newState.registration.howFamiliar = howFamiliar;
    newState.registration.runMeetings = runMeetings;
    return newState;
  }),

  on(SignupStateActions.companyInviteStepAnswer, (state, { acceptInvite }) => {
    const newState: SignupStateModel = cloneDeep(state);
    if (!acceptInvite) {
      newState.steps.invites.completed = true;
      newState.steps.selectedIndex++;
    }
    return newState;
  }),

  on(SignupStateActions.signUp, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.isSpinning = true;
    return newState;
  }),

  on(SignupStateActions.createCompany, (state, personIds) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.personIds = personIds;
    return newState;
  }),

  on(SignupStateActions.createCompanyError, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.isSpinning = false;
    return newState;
  }),

  on(SignupStateActions.signUpComplete, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.signUpComplete = true;
    newState.isSpinning = false;

    newState.registration = initialSignupRegistration;
    newState.steps = initialSignupSteps;
    newState.inviteCompanies = [];
    newState.personIds = null;
    newState.api.checkEmail = false;
    newState.api.checkInvites = false;
    return newState;
  }),

  on(SignupTeammatesActions.startAddingTeammates, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.addingTeammates = true;
    return newState;
  }),

  on(SignupTeammatesActions.removeTeammate, (state, { index }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.teammates.splice(index, 1);
    return newState;
  }),

  on(SignupTeammatesActions.saveTeammate, (state, { teammate }) => {
    const newState: SignupStateModel = cloneDeep(state);
    if (!newState.teammates.some(detail => teammate.email === detail.email)) {
      newState.teammates.push(teammate);
    }
    return newState;
  }),

  on(SignupTeammatesActions.updateTeammate, (state, { teammate, index }) => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.teammates[index] = teammate;
    return newState;
  }),

  on(SignupStateActions.navigateToAcceptInvite, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.isSpinning = false;
    return newState;
  }),

  on(SignupTeammatesActions.finishAndInviteTeammates, state => {
    const newState: SignupStateModel = cloneDeep(state);
    newState.isSpinning = true;
    return newState;
  }),

  on(
    LoginSharedStateActions.navigateToApp,
    () =>
      // Completely clear sign up state
      initialSignupState
  )
);
