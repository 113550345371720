import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToDuration',
  standalone: true,
})
export class SecondsToDurationPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const hoursDisplay = hours > 0 ? `${hours} hr` : '';

    const minutes = Math.floor((value % 3600) / 60);
    const minutesDisplay = minutes > 0 ? `${minutes} min` : '';

    if (hours) {
      return `${hoursDisplay} ${minutesDisplay}`;
    }

    if (minutes > 9) {
      return minutesDisplay;
    }

    const seconds = value % 60;
    const secondsDisplay = `${seconds} sec`;

    return `${minutesDisplay} ${secondsDisplay}`;
  }
}
