import { Pipe, PipeTransform } from '@angular/core';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';

@Pipe({
  name: 'itemLabelByType',
})
export class ItemLabelByTypePipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(itemType: ItemType): string {
    switch (itemType) {
      case ItemType.cascadedMessage:
        return this.stateService.language.cascadingMessage.item;
      case ItemType.headline:
        return this.stateService.language.headline.item;
      case ItemType.issue:
        return this.stateService.language.issue.item;
      case ItemType.milestone:
      case ItemType.target:
        return this.stateService.language.milestone.item;
      case ItemType.rock:
        return this.stateService.language.rock.item;
      case ItemType.todo:
        return this.stateService.language.todo.item;
      default:
        return '';
    }
  }
}
