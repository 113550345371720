import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TerraIconModule } from '@ninety/terra';

import { ErrorService } from '../../../_core/services/error.service';
import { HelperService } from '../../../_core/services/helper.service';
import { IdentityProviderService } from '../../../_core/services/identity-provider.service';
import { SpinnerService } from '../../../_core/services/spinner.service';
import type { ICognitoUser, ICognitoAuthErrorResponse } from '../../models/_shared/cognito-user';
import type { CognitoUserStatus } from '../../models/cognito/cognito-user-status.enum';
import { ErrorMessages } from '../../models/enums/errors';

@Component({
  selector: 'ninety-verify-password-dialog',
  templateUrl: './create-temp-cognito-user-dialog.component.html',
  styleUrls: ['./create-temp-cognito-user-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TerraIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class CreateTempCognitoUserDialogComponent implements OnDestroy {
  passwordControl = new UntypedFormControl('', [Validators.required]);
  newPrimaryEmailControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(HelperService.Regex.email),
  ]);
  passwordErrorMessage = ErrorMessages.confirmPassword;
  passedPasswordStep = false;
  passedEmailStep = false;
  spinner = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateTempCognitoUserDialogData,
    public dialogRef: MatDialogRef<CreateTempCognitoUserDialogComponent>,
    public spinnerService: SpinnerService,
    private idpService: IdentityProviderService,
    private errorService: ErrorService
  ) {}

  ngOnDestroy() {
    this.passwordControl.reset();
    this.newPrimaryEmailControl.reset();
  }

  verifyPassword() {
    this.passwordControl.disable();
    this.spinner = true;

    this.idpService.verifyUser(this.data.personPrimaryEmail, this.passwordControl.value).subscribe({
      next: (resp: ICognitoUser) => {
        this.spinner = false;
        if (resp.username?.length) this.passedPasswordStep = true;
      },
      error: (e: ICognitoAuthErrorResponse) => {
        this.spinner = false;
        this.passwordControl.enable();

        if (e.code === 'NotAuthorizedException') {
          this.passwordControl.setErrors({ incorrectPassword: true });
        } else {
          this.errorService.notify(e, 'Error verifying user in CreateTempCognitoUser dialog');
        }
      },
    });
  }

  disableIncorrectPasswordState() {
    if (this.passwordControl.hasError('incorrectPassword')) {
      this.passwordControl.setErrors({ incorrectPassword: null });
      this.passwordControl.updateValueAndValidity();
    }
  }

  sendVerificationEmailToNewEmail() {
    this.newPrimaryEmailControl.disable();
    this.spinner = true;

    this.idpService.createTempCognitoUser(this.newPrimaryEmailControl.value).subscribe({
      next: status => {
        this.spinner = false;
        this.passedEmailStep = true;

        // allow time for user to see update in dialog before closing
        setTimeout(() => {
          this.dialogRef.close({
            newPrimaryEmail: this.newPrimaryEmailControl.value,
            password: this.passwordControl.value,
            status,
          });
        }, 2500);
      },
      error: (e: unknown) => {
        this.spinner = false;
        this.newPrimaryEmailControl.enable();
        this.errorService.notify(e, 'Unable to send verification code to new email');
      },
    });
  }
}

export interface CreateTempCognitoUserDialogData {
  personPrimaryEmail: string;
}

export interface CreateTempCognitoUserDialogResponse {
  newPrimaryEmail: string;
  password: string;
  status: CognitoUserStatus;
}
