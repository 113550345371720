import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SegmentTrackEvent } from '@ninety/analytics/segment/models/segment-track-event.enum';

export const SegmentActions = createActionGroup({
  source: 'Segment Effects',
  events: {
    ready: emptyProps(),
    track: props<{ event: SegmentTrackEvent; params?: object }>(),
  },
});
