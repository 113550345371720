<h3 class="terra-title-small">Add Another User</h3>
<p class="basic-description">Add some basic information about the user.</p>
<form [formGroup]="data.form">
  <mat-form-field class="long-form-field legacy-no-background" appearance="outline">
    <mat-label>First Name</mat-label>
    <input matInput required placeholder="First Name" formControlName="firstName" />
  </mat-form-field>
  <mat-form-field class="long-form-field legacy-no-background" appearance="outline">
    <mat-label>Last Name</mat-label>
    <input matInput required placeholder="Last Name" formControlName="lastName" />
  </mat-form-field>
  <mat-form-field class="long-form-field legacy-no-background" appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput [required]="!data.directoryOnly" placeholder="Email" formControlName="email" />
    <mat-error
      class="error-message"
      *ngIf="
        data.form.get('email').invalid &&
        (data.form.get('emailError').value === emailErrors.invalid ||
          data.form.get('emailError').value === emailErrors.taken)
      "
      >{{ data.form.get('emailError').value }}</mat-error
    >
  </mat-form-field>
  <div class="extra-fields" *ngIf="!data.directoryOnly">
    <fieldset class="outlined-fieldset" [class.error]="teamsRequiredError">
      <legend class="fieldset-legend">Teams *</legend>
      <ninety-teams-select
        [disabled]="data.form.get('teams').disabled"
        [required]="true"
        [(showRequiredError)]="teamsRequiredError"
        requiredText=""
        [userTeams]="data.form.get('teams').value"
        placeholder="Teams"
        [outlineMode]="true"
        [hideLabel]="true"
        (selectionChange)="updateUserTeams($event)"></ninety-teams-select>
    </fieldset>
    <fieldset class="outlined-fieldset space-top" [class.error]="data.form.get('role').invalid && roleSelectOpened">
      <legend class="fieldset-legend">Role *</legend>
      <div class="mat-form-field-wrapper">
        <div class="mat-form-field-flex">
          <div class="mat-form-field-infix">
            <ninety-role-select
              [required]="true"
              [initialRoleCode]="data.form.get('role').value"
              (roleChange)="updateUserRole($event)"
              [(opened)]="roleSelectOpened"></ninety-role-select>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</form>
<div (click)="onClickCheckValidity()" class="button-bar">
  <button class="uppercase bottom-button" (click)="dialogRef.close()" mat-flat-button>Cancel</button>
  <button
    class="uppercase bottom-button bg-color-brand"
    (click)="dialogRef.close(data.form)"
    mat-flat-button
    color="accent"
    [disabled]="data.form.invalid">
    Add
  </button>
</div>
