import { BusinessOperatingSystem } from '../company/business-operating-system.enum';
import { PartnerType } from '../partner-hub/partner-type.enum';

import { PersonMetadata } from './person-metadata';

export interface Person {
  _id: string;
  personId?: string;
  metadata?: PersonMetadata;
  personMetadataId?: string;
  primaryEmail?: string;
  primaryPhoneNumber?: string;
  deleted?: boolean;
  __v?: number;
  isImplementer?: boolean;
  partnerType?: PartnerType;
  bosTypes?: BusinessOperatingSystem[];
  partner_key?: string;
  partner_email?: string;
  hasCreatedCompany?: boolean;
  defaultCustomizationsId?: string;
  referralLinks?: { url: string }[];
  helpfulPermissions?: HelpfulPermissions;
  mazDismissClickTimes?: number[];
}

export class HelpfulPermissions {
  adjustPricing? = false;
  companies? = false;
  coupons? = false;
  dataUpload? = false;
  emailSuppressionList? = false;
  hardDeleteCompany? = false;
  manageHelpfuls? = false;
  manualCharge? = false;
  users? = false;

  /** allows user to apply template to any company they are in along with their referred companies */
  applyTemplates?: boolean;

  /** prevents a partner stack customer from being created when a company is created */
  doNotCreatePartnerStackCustomer?: boolean;

  /** Partners Management Page */
  partners? = false;
  partnersAdmin = false; // ability to update/delete partners/customers

  /** Partner Hub */
  domoAdmin? = false; // allows user to view a specific coach's Domo embed dashboard

  /** Allows user to add/create/update default learn modules */
  learnModuleAdmin?: boolean; // allows user to view learning modules

  constructor(permissions: HelpfulPermissions) {
    Object.assign(this, permissions);
  }
}
