import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { FileService } from '@ninety/ui/legacy/core/services/file.service';
import { LibraryService } from '@ninety/ui/legacy/core/services/library.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { AvatarType } from '@ninety/ui/legacy/shared/models/library/avatar-type.enum';
import { SignPutResponseDto } from '@ninety/ui/legacy/shared/models/library/signed-put-response.dto';
import { CompanyActions } from '@ninety/ui/legacy/state/app-global/company/company-state.actions';

@Component({
  selector: 'ninety-upload-company-logo-dialog',
  templateUrl: './upload-company-logo-dialog.component.html',
  styleUrls: ['./upload-company-logo-dialog.component.scss'],
})
export class UploadCompanyLogoDialogComponent {
  imageChangedEvent: Event;
  croppedImage = '';
  fileName = 'No file chosen';

  constructor(
    public dialogRef: MatDialogRef<UploadCompanyLogoDialogComponent>,
    public spinnerService: SpinnerService,
    public companyService: CompanyService,
    private libraryService: LibraryService,
    private notifyService: NotifyService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: { companyId?: string; templateId?: string },
    private stateService: StateService,
    private store: Store
  ) {}

  handleFileChangeEvent(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    let fileSize: number;

    if (file && file.size) {
      fileSize = file.size / 1024 ** 2;
    }

    if (fileSize && fileSize > 25) {
      this.notifyService.notify('Uploaded images should be smaller than 25MB.');
    } else {
      this.fileName = (event.target as HTMLInputElement).files[0].name;
      this.imageChangedEvent = event;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpload(): void {
    this.spinnerService.start();
    const imageCategory = this.data?.companyId ? AvatarType.company : AvatarType.template;

    this.libraryService
      .getImageUploadUrl(imageCategory, this.data?.companyId || this.data?.templateId)
      .pipe(
        switchMap((uploadImageData: SignPutResponseDto) =>
          this.fileService
            .compressImage(this.fileService.dataURItoFile(this.croppedImage, this.fileName))
            .pipe(map((compressedImage: File) => ({ uploadImageData, compressedImage })))
        ),
        switchMap(({ uploadImageData, compressedImage }) =>
          this.fileService
            .downsizeImage(compressedImage, { maxWidth: 1200 })
            .pipe(map((resizedImage: Blob) => ({ uploadImageData, image: resizedImage })))
        ),
        switchMap(({ uploadImageData, image }) =>
          this.libraryService.uploadImage(uploadImageData.uploadUrl, image).pipe(map(() => uploadImageData))
        ),
        catchError((error: unknown) => {
          this.spinnerService.stop();
          this.notifyService.showError('An error occurred while uploading image. Please try again.', 'Image Upload');

          return throwError(() => error);
        })
      )
      .subscribe({
        next: (response: SignPutResponseDto) => {
          this.spinnerService.stop();
          if (this.stateService.company._id === this.data.companyId) {
            this.store.dispatch(CompanyActions.updateLogo({ location: response.location }));
          }
          this.dialogRef.close(response.location);
        },
      });
  }
}
