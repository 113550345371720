import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';

import { mazChatAnimation } from '@ninety/getting-started/guide/components/getting-started-guide/getting-started-guide.animation';
import { MazActions } from '@ninety/getting-started/maz/_state/maz.actions';
import { MazSelectors } from '@ninety/getting-started/maz/_state/maz.selectors';
import {
  selectCurrentUserId,
  selectUserAvatarInfo,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

@Component({
  selector: 'ninety-maz-chat',
  templateUrl: './maz-chat.component.html',
  styleUrls: ['./maz-chat.component.scss'],
  animations: [mazChatAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MazChatComponent {
  protected readonly isMazChatExpanded$ = this.store.select(MazSelectors.selectIsMazChatExpanded);
  protected readonly mazMessages$ = this.store.select(MazSelectors.selectConversationHistory);
  protected readonly waitingForMazResponse$ = this.store.select(MazSelectors.selectWaitingForMazResponse);

  // handing this to all messages to every message doesn't need to import the store and get the user's picture.url
  protected readonly userAvatarInfo$ = this.store
    .select(selectCurrentUserId)
    .pipe(switchMap(userId => this.store.select(selectUserAvatarInfo(userId))));

  mazInput: string;

  constructor(private store: Store) {}

  toggleChatWindow() {
    this.store.dispatch(MazActions.toggleMazChat());
  }

  sendMessage(): void {
    this.store.dispatch(MazActions.sendMessageToMaz({ userInput: this.mazInput }));
    this.mazInput = null;
  }

  flagMazResponseIncorrect(messageId: string): void {
    this.store.dispatch(MazActions.flagMazResponseIncorrect({ messageId }));
  }
}
