import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ResponsibilitiesLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-responsibilities-language',
  templateUrl: './responsibilities-language.component.html',
  styles: [],
})
export class ResponsibilitiesLanguageComponent {
  @Input() responsibilities: ResponsibilitiesLanguage;
  @Input() resetLanguage: ResponsibilitiesLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof ResponsibilitiesLanguage>();
}
