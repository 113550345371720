import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { GuideInfo } from '@ninety/getting-started/guide/models/guide-info';
import { Phase } from '@ninety/getting-started/guide/models/phase';
import { PageViews } from '@ninety/ui/legacy/shared/models/user/page-views';

export const GuideActions = createActionGroup({
  source: 'Getting Started',
  events: {
    Init: emptyProps(),
    'Toggle Guide': emptyProps(),
    'Close Guide': emptyProps(),
    'Force Open Guide': emptyProps(),
    'Dismiss Guide': emptyProps(),
    'Dismiss Guide Confirmed': emptyProps(),

    'Set Guide Info': props<{ guideInfo: GuideInfo }>(),
    'Set Phases And Completion Percentage': props<{ phases: Phase[]; completionPercentage: number }>(),
    'Select Phase': props<{ phase: Phase }>(),
    'Close Phase Details': emptyProps(),

    'Check Completion Status': emptyProps(),
    'Get Phases And Completion Percentage': emptyProps(),
    'Open Resource Link': props<{ url: string }>(),
    'Page Viewed': props<{ page: keyof PageViews }>(),

    'Something Failed': props<{ error: unknown; message: string }>(),
  },
});
