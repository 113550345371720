import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RockLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-rock-language',
  templateUrl: './rock-language.component.html',
  styles: [],
})
export class RockLanguageComponent {
  @Input() rock: RockLanguage;
  @Input() resetLanguage: RockLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof RockLanguage>();
}
