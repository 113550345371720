<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="one-on-one" />
      <span data-cy="one-on-one-language_card-title">{{ oneOnOne.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="feedback">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="oneOnOne.route + ' Page Name'"
          data-cy="one-on-one-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="one-on-one-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="oneOnOne.item"
          data-cy="one-on-one-language_discussion-singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="one-on-one-language_discussion-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="oneOnOne.items"
          data-cy="one-on-one-language_discussion-plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="one-on-one-language_discussion-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.quarterlyItem }} (singular):</mat-label>
        <input
          #quarterlyItem
          matInput
          formControlName="quarterlyItem"
          required
          [attr.aria-label]="oneOnOne.quarterlyItem"
          data-cy="one-on-one-language_quarterly-discussion-singular-input-field" />
        <ninety-reset-btn
          *ngIf="quarterlyItem.value !== resetLanguage?.quarterlyItem"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('quarterlyItem')"
          [disabled]="readonly"
          data-cy="one-on-one-language_quarterly-discussion-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.quarterlyItems }} (plural):</mat-label>
        <input
          #quarterlyItems
          matInput
          formControlName="quarterlyItems"
          required
          [attr.aria-label]="oneOnOne.quarterlyItems"
          data-cy="one-on-one-language_quarterly-discussion-plural-input-field" />
        <ninety-reset-btn
          *ngIf="quarterlyItems.value !== resetLanguage?.quarterlyItems"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('quarterlyItems')"
          [disabled]="readonly"
          data-cy="one-on-one-language_quarterly-discussion-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.annualItem }} (singular):</mat-label>
        <input
          #annualItem
          matInput
          formControlName="annualItem"
          required
          [attr.aria-label]="oneOnOne.annualItem"
          data-cy="one-on-one-language_annual-discussion-singular-input-field" />
        <ninety-reset-btn
          *ngIf="annualItem.value !== resetLanguage?.annualItem"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('annualItem')"
          [disabled]="readonly"
          data-cy="one-on-one-language_annual-discussion-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.annualItems }} (plural):</mat-label>
        <input
          #annualItems
          matInput
          formControlName="annualItems"
          required
          [attr.aria-label]="oneOnOne.annualItems"
          data-cy="one-on-one-language_annual-discussion-plural-input-field" />
        <ninety-reset-btn
          *ngIf="annualItems.value !== resetLanguage?.annualItems"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('annualItems')"
          [disabled]="readonly"
          data-cy="one-on-one-language_annual-discussion-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.managee }}:</mat-label>
        <input
          #managee
          matInput
          formControlName="managee"
          required
          [attr.aria-label]="oneOnOne.managee"
          data-cy="one-on-one-language_team-member-input-field" />
        <ninety-reset-btn
          *ngIf="managee.value !== resetLanguage?.managee"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('managee')"
          [disabled]="readonly"
          data-cy="one-on-one-language_team-member-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.manager }}:</mat-label>
        <input
          #manager
          matInput
          formControlName="manager"
          required
          [attr.aria-label]="oneOnOne.manager"
          data-cy="one-on-one-language_manager-input-field" />
        <ninety-reset-btn
          *ngIf="manager.value !== resetLanguage?.manager"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('manager')"
          [disabled]="readonly"
          data-cy="one-on-one-language_manager-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.meeting }}:</mat-label>
        <input
          #meeting
          matInput
          formControlName="meeting"
          required
          [attr.aria-label]="oneOnOne.meeting"
          data-cy="one-on-one-language_meeting-input-field" />
        <ninety-reset-btn
          *ngIf="meeting.value !== resetLanguage?.meeting"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('meeting')"
          [disabled]="readonly"
          data-cy="one-on-one-language_meeting-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.peopleAnalyzer }}:</mat-label>
        <input
          #peopleAnalyzer
          matInput
          formControlName="peopleAnalyzer"
          required
          [attr.aria-label]="oneOnOne.peopleAnalyzer"
          data-cy="one-on-one-language_fit-check-input-field" />
        <ninety-reset-btn
          *ngIf="peopleAnalyzer.value !== resetLanguage?.peopleAnalyzer"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('peopleAnalyzer')"
          [disabled]="readonly"
          data-cy="one-on-one-language_fit-check-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.CCC }} (Max length: 4):</mat-label>
        <input
          #CCC
          matInput
          formControlName="CCC"
          required
          [attr.aria-label]="oneOnOne.CCC"
          maxlength="4"
          data-cy="one-on-one-language_ccc-input-field" />
        <ninety-reset-btn
          *ngIf="CCC.value !== resetLanguage?.CCC"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('CCC')"
          [disabled]="readonly"
          data-cy="one-on-one-language_ccc-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.competency }}:</mat-label>
        <input
          #competency
          matInput
          formControlName="competency"
          required
          [attr.aria-label]="oneOnOne.competency"
          data-cy="one-on-one-language_competency-input-field" />
        <ninety-reset-btn
          *ngIf="competency.value !== resetLanguage?.competency"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('competency')"
          [disabled]="readonly"
          data-cy="one-on-one-language_competency-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.commitment }}:</mat-label>
        <input
          #commitment
          matInput
          formControlName="commitment"
          required
          [attr.aria-label]="oneOnOne.commitment"
          data-cy="one-on-one-language_commitment-input-field" />
        <ninety-reset-btn
          *ngIf="commitment.value !== resetLanguage?.commitment"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('commitment')"
          [disabled]="readonly"
          data-cy="one-on-one-language_commitment-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.capacity }}:</mat-label>
        <input
          #capacity
          matInput
          formControlName="capacity"
          required
          [attr.aria-label]="oneOnOne.capacity"
          data-cy="one-on-one-language_capacity-input-field" />
        <ninety-reset-btn
          *ngIf="capacity.value !== resetLanguage?.capacity"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('capacity')"
          [disabled]="readonly"
          data-cy="one-on-one-language_capacity-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.LAC }} (Max length: 4):</mat-label>
        <input
          #LAC
          matInput
          formControlName="LAC"
          required
          [attr.aria-label]="oneOnOne.LAC"
          maxlength="4"
          data-cy="one-on-one-language_lac-input-field" />
        <ninety-reset-btn
          *ngIf="LAC.value !== resetLanguage?.LAC"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('LAC')"
          [disabled]="readonly"
          data-cy="one-on-one-language_lac-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.leadershipCommitments }}:</mat-label>
        <input
          #leadershipCommitments
          matInput
          formControlName="leadershipCommitments"
          required
          [attr.aria-label]="oneOnOne.leadershipCommitments"
          data-cy="one-on-one-language_leadership-commitments-input-field" />
        <ninety-reset-btn
          *ngIf="leadershipCommitments.value !== resetLanguage?.leadershipCommitments"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('leadershipCommitments')"
          [disabled]="readonly"
          data-cy="one-on-one-language_leadership-commitments-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ oneOnOne.coachingCommitments }}:</mat-label>
        <input
          #coachingCommitments
          matInput
          formControlName="coachingCommitments"
          required
          [attr.aria-label]="oneOnOne.coachingCommitments"
          data-cy="one-on-one-language_coaching-commitments-input-field" />
        <ninety-reset-btn
          *ngIf="coachingCommitments.value !== resetLanguage?.coachingCommitments"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('coachingCommitments')"
          [disabled]="readonly"
          data-cy="one-on-one-language_coaching-commitments-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
