import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isToday } from 'date-fns';

@Pipe({
  name: 'todayOrDate',
  standalone: true,
})
export class TodayOrDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(date: Date | string, dateFormat: string): string {
    if (!date) return;

    if (isToday(new Date(date))) return 'Today';

    return this.datePipe.transform(date, dateFormat);
  }
}
