import { Component, Input } from '@angular/core';

@Component({
  selector: 'ninety-clock-timer',
  templateUrl: './clock-timer.component.html',
  styleUrls: ['./clock-timer.component.scss'],
})
export class ClockTimerComponent {
  @Input() totalSeconds: number;
  @Input() totalTimeAllotted?: number;

  constructor() {}
}
