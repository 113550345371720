import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { PartnerStackService } from '@ninety/partners/partner-management/_shared/services/partner-stack.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Person } from '@ninety/ui/legacy/shared/models/_shared/person';
import { CreatePartnerDTO } from '@ninety/ui/legacy/shared/models/partner-hub/create-parter.dto';
import { PartnerType } from '@ninety/ui/legacy/shared/models/partner-hub/partner-type.enum';
import { CurrentPersonStateActions } from '@ninety/ui/legacy/state/app-global/current-person/current-person.actions';

@Component({
  selector: 'ninety-become-a-partner',
  templateUrl: './become-a-partner.component.html',
  styleUrls: ['./become-a-partner.component.scss'],
})
export class BecomeAPartnerComponent implements OnInit, OnDestroy {
  accepted = false;
  partnerType: PartnerType;
  partnerCreated = false;
  logoUrl: string;
  appName: string;
  website: string;
  poweredBy: string;

  constructor(
    private spinnerService: SpinnerService,
    public stateService: StateService,
    private filterService: FilterService,
    private partnerStackService: PartnerStackService,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {
    this.filterService.setOptions({ filtersToolbar: false });
    this.spinnerService.stop();
    this.appName = this.stateService.appName;
    this.website = 'Ninety.io';
    this.poweredBy = 'Px90';
  }

  ngOnInit(): void {
    this.logoUrl = 'assets/images/90_Ninety_Logo_Ice.png';
    document.body.classList.add(`partner-page`);
  }

  ngOnDestroy(): void {
    document.body.classList.remove(`partner-page`);
  }

  createPartner(): void {
    const currentPerson = this.stateService.currentPerson$.getValue();
    const person: Person = {
      ...currentPerson, // no metadata
      metadata: this.stateService.currentUser$.getValue().metadata,
    };

    const partnerType = this.getPartnerTypeFromParam();

    const partner = CreatePartnerDTO.fromPersonAndType(person, partnerType);
    this.partnerStackService.becomeAPartner(partner).subscribe({
      next: () => {
        this.partnerCreated = true;
        this.store.dispatch(
          CurrentPersonStateActions.becomePartnerUpdatePerson({
            person: { ...currentPerson, partner_key: partner.partner_key },
          })
        );
      },
    });
  }

  getPartnerTypeFromParam(): PartnerType {
    switch (this.activatedRoute.snapshot.params.partnerType) {
      case 'referral':
      case 'referral-partner':
      case 'referralpartner':
        return PartnerType.referralPartner;

      case 'coach':
        return PartnerType.coach;

      case 'white-label':
      case 'white':
      case 'whitelabel':
        return PartnerType.whiteLabel;
      default:
        return null;
    }
  }
}
