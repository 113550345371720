export enum RockMessageType {
  new = 'new-rock',
  newMulti = 'new-multi-rock',
  rock = 'rock',
  delete = 'delete-rock',
  unarchive = 'unarchive-rock',
  moveList = 'move-list',
  drop = 'list-drop-rock',
  sort = 'list-sort-rock',
  fetch = 'fetch-object',
  archiveAllStatuses = 'archive-all-statuses',

  milestone = 'milestone',
  newMilestone = 'new-milestone',
}
