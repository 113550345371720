import { Pipe, PipeTransform } from '@angular/core';

import { Rock } from '../models/rocks/rock';
import { RockStatusCode } from '../models/rocks/rock-status-code';

@Pipe({
  name: 'rockStatusFilter',
})
export class RockStatusFilterPipe implements PipeTransform {
  transform(rocks: Rock[], statusCode: RockStatusCode | 'all', showArchived: boolean): Rock[] {
    if (!rocks || !statusCode || statusCode === 'all' || showArchived) return rocks;
    return rocks.filter(rock => rock.statusCode === statusCode);
  }
}
