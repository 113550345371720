import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

import { Milestone } from '../models/rocks/milestone';

@Pipe({
  name: 'filterMilestones',
})
export class FilterMilestonesPipe implements PipeTransform {
  transform(milestones: Milestone[], status: 'All' | 'Complete' | 'Incomplete'): Milestone[] {
    if (!milestones || !status || status === 'All') return milestones;
    return filter(milestones, milestone => milestone.isDone === (status === 'Complete'));
  }
}
