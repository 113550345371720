import { MemoizedSelector, Store } from '@ngrx/store';
import { take } from 'rxjs';
/*
  Alright folks.  The only intended use of this function is to be a temporary solution for removing dependency on the
  StateService without a full refactor to a Store.

  If you have some code that looks like this:
  object.companyId = this.stateService.companyId;

  You can replace it with this:
  object.companyId = extractValueFromStore(this.store, selectCompanyId);

  Ideally any external dependency would be passed in the function definition, probably composed and called from an NGRX Effect:
  myFunction(companyId: string) {
    object.companyId = companyId
  }

  Please don't abuse this by one off selecting from the store in components and other places where we should be using the store
  in an async rxjs pipeline.
*/
export function extractValueFromStore<T>(store: Store, selector: MemoizedSelector<any, T>): T {
  let value: T;

  store
    .select(selector)
    .pipe(take(1))
    .subscribe(val => {
      value = val as T;
    });

  return value;
}
