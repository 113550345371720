import { createSelector } from '@ngrx/store';

import { selectGlobalAppState } from '..';

import { BannerNotificationStateKey } from './banner-notification.model';

export namespace BannerStateSelectors {
  export const selectBannerNotificationState = createSelector(selectGlobalAppState, globalState =>
    globalState ? globalState[BannerNotificationStateKey] : null
  );
  export const selectMessage = createSelector(selectBannerNotificationState, banner => banner);
}
