<div *ngIf="selectedTimeZone">
  <mat-form-field [appearance]="'outline'">
    <mat-select
      data-cy="timezone-select_menu"
      (valueChange)="onSelectionChange($event)"
      [(value)]="selectedTimeZone.name"
      [disabled]="shouldAutoSelectTimeZoneFromBrowser">
      <mat-option
        *ngFor="let timezone of timezoneList"
        attr.data-cy="timezone-select_option-{{ timezone.name }}"
        value="{{ timezone.name }}">
        ({{ timezone.fullUtcOffset }}) {{ timezone.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
