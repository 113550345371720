<section
  *ngrxLet="
    {
      company: company$,
      currentTheme: currentTheme$,
      currentPerson: currentPerson$,
      currentUser: currentUser$,
      disableNavLinks: disableNavLinks$,
      hasPartnerHubAccess: hasPartnerHubAccess$,
      isExpanded: isExpanded$,
      isHidden: isHidden$,
      language: language$,
      profileMenuIcon: profileMenuIcon$,
      scorecardBeta: scorecardBeta$,
      userAvatarInfo: userAvatarInfo$,
      canAccessInsights: canAccessInsights$,
      meetingsV2: meetingsV2$,
      enableSurveys: enableSurveys$,
      enableSurveysPill: enableSurveysPill$
    } | ngrxPush as context
  "
  (mouseenter)="isHovering = true"
  (mouseleave)="isHovering = false"
  id="main-sidebar"
  [class.is-open]="context.isExpanded"
  [class.is-closed]="!context.isExpanded"
  [class.hide-instead-of-ngIf]="context.isHidden"
  [class.dual-theme]="context.currentTheme === Theme.DUAL"
  [@openCloseSidenav]="context.isExpanded ? 'open' : 'closed'">
  <div
    *ngIf="isTablet || isHovering"
    data-product="main-sidebar__expand-collapse-button"
    class="toggle-button-wrapper"
    [ngClass]="{
      'toggle-nav-expanded': context.isExpanded,
      'toggle-nav-closed': !context.isExpanded,
      'toggle-button-wrapper--mobile': isMobile,
    }"
    (click)="toggleSideNav()"
    [ninetyTooltip]="context.isExpanded ? 'Collapse' : 'Expand'"
    [tpIsEnabled]="!isTablet"
    tpPlacement="right">
    <div class="toggle-nav-button">
      <terra-icon
        data-cy="main-sidebar_toggle-icon"
        icon="caret-left"
        variant="bold"
        size="12"
        *ngIf="context.isExpanded" />
      <terra-icon
        data-cy="main-sidebar_toggle-icon"
        icon="caret-right"
        variant="bold"
        size="12"
        *ngIf="!context.isExpanded" />
    </div>
  </div>

  <div
    class="company-menu"
    [class]="{
      'dual-theme': context.currentTheme === Theme.DUAL,
    }">
    <ninety-company-menu
      [isSidenavExpanded]="context.isExpanded"
      [theme]="context.currentTheme"
      [v2Sidenav]="true"></ninety-company-menu>
  </div>

  <div
    id="nav-tools"
    #navTools
    class="nav-tools-menu"
    [class.dual-theme]="context.currentTheme === Theme.DUAL"
    [ngClass]="{ 'nav-tools-scroll-shadow': navToolsHasScrollbar }">
    <!-- ============================ Main Navigation ============================== -->
    <mat-nav-list *ngIf="context.currentPerson" [ngClass]="context.isExpanded ? '' : 'nav-list-closed'">
      <ng-container *ngIf="context.hasPartnerHubAccess">
        <ninety-navigation-menu-item
          iconKey="partner-hub"
          data-cy="main-sidebar_partner-hub"
          [title]="'Partner Hub'"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          [routerLink]="[{ outlets: { detail: null, primary: 'partner-hub' } }]">
        </ninety-navigation-menu-item>
        <hr />
      </ng-container>

      <ninety-navigation-menu-item
        *ngIf="context.company.settings.dashboard"
        iconKey="dashboard"
        [title]="context.language.dashboard.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        href="/dashboard">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="my-90"
        data-cy="main-sidebar_my90"
        [title]="context.language.my90.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'home' } }]">
      </ninety-navigation-menu-item>

      <div class="beta-menu-item" *ngIf="context.canAccessInsights">
        <ninety-navigation-menu-item
          iconKey="lightbulb"
          data-cy="main-sidebar_insights"
          title="Insights"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          [disabled]="context.disableNavLinks"
          [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'insights' } }]">
        </ninety-navigation-menu-item>
        <ninety-chip class="beta-chip" color="brand">Beta</ninety-chip>
      </div>

      <hr />

      <ninety-navigation-menu-item
        iconKey="data"
        data-cy="main-sidebar_data"
        [title]="context.language.scorecard.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data' } }]">
      </ninety-navigation-menu-item>

      <div class="beta-menu-item" *ngIf="context.scorecardBeta">
        <ninety-navigation-menu-item
          data-product="main-sidenav__scorecard-beta-link"
          iconKey="data"
          [title]="context.language.scorecard.route"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          [disabled]="context.disableNavLinks"
          [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data-v2' } }]">
        </ninety-navigation-menu-item>
        <ninety-chip class="beta-chip" color="brand">Beta</ninety-chip>
      </div>

      <ninety-navigation-menu-item
        iconKey="rocks"
        data-cy="main-sidebar_rocks"
        [title]="context.language.rock.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'rocks' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="to-dos"
        data-cy="main-sidebar_todos"
        [title]="context.language.todo.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'todos' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="issues"
        data-cy="main-sidebar_issues"
        [title]="context.language.issue.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'issues' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="meetings"
        data-cy="main-sidebar_meetings"
        [title]="context.language.meeting.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="
          context.disableNavLinks
            ? null
            : [{ outlets: { detail: null, primary: context.meetingsV2 ? 'meetings-v2' : 'meetings' } }]
        ">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="headlines"
        data-cy="main-sidebar_headlines"
        [title]="context.language.headline.items"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'headlines' } }]">
      </ninety-navigation-menu-item>

      <hr />

      <ninety-navigation-menu-item
        iconKey="vision"
        data-cy="main-sidebar_vision"
        [title]="context.language.vto.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'vision' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        *ngIf="canAccessOrgChart$ | ngrxPush"
        iconKey="responsibilities"
        data-cy="main-sidebar_responsibilities"
        data-product="main-sidebar__responsibilities-button"
        [title]="context.language.acChart.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'chart' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        *ngIf="context.currentUser.isManageeOrAbove"
        iconKey="one-on-one"
        data-cy="main-sidebar_1-on-1"
        [title]="context.language.feedback.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: '1-on-1' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="process"
        data-cy="main-sidebar_process"
        [title]="context.language.process.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'process' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        iconKey="directory"
        data-cy="main-sidebar_directory"
        [title]="context.language.directory.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory' } }]">
      </ninety-navigation-menu-item>

      <ninety-navigation-menu-item
        *ngIf="context.company.settings?.mastery.enabled && !context.currentUser.isLite"
        iconKey="mastery"
        data-cy="main-sidebar_mastery"
        [title]="context.language.mastery.route"
        [isExpanded]="context.isExpanded"
        [showTooltip]="!isTablet"
        [theme]="context.currentTheme"
        [disabled]="context.disableNavLinks"
        [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'mastery' } }]">
      </ninety-navigation-menu-item>

      <div class="beta-menu-item" *ngIf="(enableLearningModule$ | async) && !context.currentUser.isLite">
        <ninety-navigation-menu-item
          iconKey="student"
          data-cy="main-sidebar_learn"
          [title]="'Knowledge'"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          [disabled]="context.disableNavLinks"
          [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'knowledge' } }]">
        </ninety-navigation-menu-item>
        <ninety-chip class="beta-chip" color="brand">Beta</ninety-chip>
      </div>

      <ng-container *ngIf="context.enableSurveys">
        <!-- Show Assessment tool with "New" pill -->
        <div class="beta-menu-item" *ngIf="context.enableSurveysPill; else assessmentToolNoPill">
          <ng-container *ngTemplateOutlet="assessmentTool" />
          <ninety-chip class="beta-chip" color="brand">New</ninety-chip>
        </div>

        <!-- Show Assessment tool without "New" pill -->
        <ng-template #assessmentToolNoPill>
          <ng-container *ngTemplateOutlet="assessmentTool" />
        </ng-template>

        <ng-template #assessmentTool>
          <ninety-navigation-menu-item
            iconKey="surveys"
            data-cy="main-sidebar_surveys"
            [title]="context.language.survey?.route"
            [isExpanded]="context.isExpanded"
            [showTooltip]="!isTablet"
            [theme]="context.currentTheme"
            [disabled]="context.disableNavLinks"
            [routerLink]="context.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'assessments' } }]">
          </ninety-navigation-menu-item>
        </ng-template>
      </ng-container>
    </mat-nav-list>
  </div>
  <div class="fill-remaining-space"></div>

  <!-- ============================ Bottom Buttons ============================== -->

  <div
    class="utils-section"
    [class]="{
      'dual-theme': context.currentTheme === Theme.DUAL,
    }">
    <ng-container *ngrxLet="showGettingStartedTracker$ as showGettingStartedTracker">
      <div class="getting-started-container" *ngIf="showGettingStartedTracker">
        <ninety-getting-started-tracker [locked]="context.disableNavLinks" [isExpanded]="context.isExpanded" />
      </div>

      <ninety-trial-countdown
        *ngIf="!showGettingStartedTracker && shouldDisplayTrialCountdown$ | ngrxPush"
        [theme]="context.currentTheme"
        [isSidenavExpanded]="context.isExpanded"
        [callToActionActive]="context.currentUser.isAdminOrOwner"
        data-cy="main-sidebar_trial-countdown"></ninety-trial-countdown>
    </ng-container>

    <ng-container *ngIf="context.userAvatarInfo | ngrxPush as avatar">
      <mat-nav-list [ngClass]="context.isExpanded ? '' : 'nav-list-closed'">
        <ninety-navigation-menu-item
          *ngIf="context.currentUser.isManagerOrAbove && !context.disableNavLinks"
          (click)="openAddInviteDialog()"
          iconKey="user-add"
          data-cy="main-sidebar_invite-teammates-item"
          [title]="'Invite Teammates'"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          data-product="main-sidebar__invite-teammates-button">
        </ninety-navigation-menu-item>
        <ninety-navigation-menu-item
          data-cy="main-sidebar_provide-feedback-item"
          data-product="main-sidebar__provide-feedback-button"
          iconKey="note-pencil"
          [title]="'Provide Feedback'"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme">
        </ninety-navigation-menu-item>

        <ninety-navigation-menu-item
          data-cy="main-sidebar_learning-and-support-item"
          data-product="main-sidebar__learn-support-menu-button"
          #learnAndSupportTrigger
          type="button"
          iconKey="question-circle"
          [title]="'Learning and Support'"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          (click)="showLearnAndSupportMenu()">
        </ninety-navigation-menu-item>

        <hr class="user-menu-divider" />

        <ninety-navigation-menu-item
          data-product="main-sidebar__user-profile-button"
          data-cy="main-sidebar_user-profile-button"
          [isTall]="true"
          type="button"
          #userProfileTrigger
          [pictureURL]="avatar.pictureURL"
          [userInitials]="avatar.userInitials"
          [title]="avatar.fullUserName"
          [isExpanded]="context.isExpanded"
          [showTooltip]="!isTablet"
          [theme]="context.currentTheme"
          [secondaryIcon]="context.profileMenuIcon"
          [secondaryIconVariant]="context.profileMenuIcon === 'warning-circle' ? 'fill' : 'regular'"
          [secondaryIconClass]="context.profileMenuIcon === 'warning-circle' ? 'alert-red' : ''"
          (click)="showUserProfileMenu()">
        </ninety-navigation-menu-item>
      </mat-nav-list>
    </ng-container>
  </div>
</section>
