import { RoleName } from '../../../_shared/models/_shared/role-name';

import { DataLayerEvent } from './data-layer-event';

export enum DataLayerKeys {
  event = 'event',
  companyId = 'companyId',
  clickUrl = 'clickUrl',
  clickText = 'clickText',
  clickClasses = 'clickClasses',
  clickElement = 'clickElement',
  clickId = 'clickId',
  errorMessage = 'errorMessage',
  formClasses = 'formClasses',
  formElement = 'formElement',
  formUrl = 'formUrl',
  formId = 'formId',
  gaClientId = 'gaClientId',
  isLoggedIn = 'isLoggedIn',
  navigationClick = 'navigationClick',
  pageTitle = 'pageTitle',
  pageHash = 'pageHash',
  pageHostname = 'pageHostname',
  pagePath = 'pagePath',
  pageUrl = 'pageUrl',
  queryParams = 'queryParams',
  referralPage = 'referralPage',
  personId = 'personId',
  authenticatedUserId = 'authenticatedUserId',
  userRole = 'userRole',
  userStatus = 'userStatus',
  lastLoginDate = 'lastLoginDate',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
}

export class DataLayer {
  event?: DataLayerEvent;
  companyId?: string;
  clickUrl?: string;
  clickText?: string;
  clickClasses?: string;
  clickElement?: string;
  clickId?: string;
  errorMessage?: string;
  formClasses?: string;
  formElement?: string;
  formUrl?: string;
  formId?: string;
  gaClientId?: string;
  isLoggedIn?: string;
  navigationClick?: 'header' | 'footer' | 'body';
  pageTitle?: string;
  pageHash?: string;
  pageHostname?: string;
  pagePath?: string;
  pageUrl?: string;
  queryParams?: string;
  referralPage?: string;
  personId?: string;
  authenticatedUserId?: string;
  userRole?: RoleName;
  userStatus: 'Lead' | 'User';
  lastLoginDate?: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;

  constructor(fields: Partial<DataLayer>) {
    const keys = Object.keys(DataLayerKeys).map(key => DataLayerKeys[key]);

    for (const key of keys) {
      if (fields[key]) {
        this[key] = fields[key];
      }
    }
  }

  /**
   * Creates new reference and excludes undefined properties
   */
  get value() {
    const layer: Partial<DataLayer> = {};
    const props = Object.keys(this);

    for (const prop of props) {
      if (!this.hasOwnProperty(prop) || this[prop] === undefined) continue;

      layer[prop] = this[prop];
    }

    return layer;
  }
}
