import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs';

import { LogLevel } from '../../_core/logging';
import { FilterService } from '../../_core/services/filter.service';
import { DatadogLogger, DatadogLogsService } from '../../_core/vendor/datadog';
import { FeatureFlagActions } from '../app-entities/feature-flag/feature-flag-state.actions';
import { FeatureFlagFacade } from '../app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '../app-entities/feature-flag/feature-flag-state.model';
import { UsersStateActions } from '../app-entities/users/users-state.actions';
import { selectCurrentUser } from '../app-entities/users/users-state.selectors';
import { CompanyActions } from '../app-global/company/company-state.actions';

import { LoggingActions } from './app-logging.actions';
import { LoggerName } from './logging-feature-flag-state';

@Injectable()
export class LoggingEffects {
  constructor(
    private actions$: Actions,
    private datadogLogger: DatadogLogger,
    private datadogLogsService: DatadogLogsService,
    private featureFlagFacade: FeatureFlagFacade,
    private filterService: FilterService,
    private store: Store
  ) {}

  /** Fetch a logger by name or fallback to the AppModule logger */
  private getLogger(name: LoggerName): DatadogLogger {
    const logger = this.datadogLogsService.getLogger(name);

    return !!logger ? logger : this.datadogLogger;
  }

  createLogger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggingActions.createLogger),
      concatLatestFrom(() => this.featureFlagFacade.getFlag(FeatureFlagKeys.appLogging)),
      map(([{ name, conf }, loggersToLogLevel]) => {
        const logger = this.datadogLogsService.createLogger(name, conf);

        // Grab & set the latest feature flag value at the time of creation
        logger.setMinLevel(loggersToLogLevel[name]);

        return LoggingActions.createLoggerSuccess({ name, logger });
      })
    )
  );

  log$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoggingActions.debug, LoggingActions.error, LoggingActions.info, LoggingActions.warn),
        tap(({ log, loggerName, type }) => {
          const logger = this.getLogger(loggerName);

          switch (type) {
            case LoggingActions.debug.type:
              logger.debug(log);
              break;
            case LoggingActions.error.type:
              logger.error(log);
              break;
            case LoggingActions.info.type:
              logger.info(log);
              break;
            case LoggingActions.warn.type:
              logger.warn(log);
              break;
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Listens for feature flag changes and updates the received loggers log-levels.
   */
  onChangeUpdateLoggerLogLevels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagActions.updatedFlagsProcessed),
        map(action => action.flags[FeatureFlagKeys.appLogging]),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        tap(loggersToLogLevel => {
          for (const entry of Object.entries(loggersToLogLevel)) {
            const [name, value] = entry as [LoggerName, LogLevel];

            const logger = this.datadogLogsService.getLogger(name);

            if (logger) {
              logger.setMinLevel(value);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /** Central effect for adding global context */
  addGlobalContext$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoggingActions.addGlobalContext),
        tap(({ data }) => {
          for (const key in data) {
            this.datadogLogsService.setGlobalContextProperty(key, data[key]);
          }
        })
      ),
    { dispatch: false }
  );

  globalContextSetFilterbarTeamId$ = createEffect(() =>
    this.filterService.selectedTeamId$.pipe(
      filter(id => !!id),
      distinctUntilChanged(),
      map(id => LoggingActions.addGlobalContext({ data: { 'filterbar.teamId': id } }))
    )
  );

  globalContextSetFilterbarSearchText$ = createEffect(() =>
    this.filterService.searchText$.pipe(
      filter(text => !!text?.length),
      debounceTime(100),
      distinctUntilChanged(),
      map(text => LoggingActions.addGlobalContext({ data: { 'filterbar.searchText': text } }))
    )
  );

  globalContextSetUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.setCurrentUser),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      map(([_, currentUser]) =>
        LoggingActions.addGlobalContext({
          data: {
            'session.user.id': currentUser._id,
            'session.user.isImplementer': currentUser.isImplementer,
            'session.user.roleCode': currentUser.roleCode,
            'session.person.id': currentUser.personId,
            'session.person.metadata.id': currentUser.personMetadataId,
          },
        })
      )
    )
  );

  globalContextSetCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.init),
      map(({ company }) =>
        LoggingActions.addGlobalContext({
          data: {
            'session.company.id': company?._id,
            'session.company.accountStatus': company?.accountStatus,
            'session.company.bos': company?.bos,
          },
        })
      )
    )
  );

  scope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggingActions.scope),
      map(({ scope }) => LoggingActions.addGlobalContext({ data: { 'session.scope': scope } }))
    )
  );

  clearScope$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoggingActions.clearScope),
        tap(() => {
          this.datadogLogsService.removeGlobalContextProperty('session.scope');
        })
      ),
    { dispatch: false }
  );
}
