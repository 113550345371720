import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { NinetyInputDirective } from '@ninety/_future-libs/recipes/ninety-recipes-inputs/text-input/directives/ninety-input.directive';
import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
// eslint-disable-next-line max-len
import { TextInputWrapperComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/text-input/components/text-input-wrapper/text-input-wrapper.component';

@Component({
  selector: 'ninety-team-search',
  templateUrl: './team-search.component.html',
  styleUrls: ['./team-search.component.scss'],
  standalone: true,
  imports: [ButtonComponent, TerraIconModule, TextInputWrapperComponent, CommonModule, NinetyInputDirective],
})
export class TeamSearchComponent {
  @Output() searchChanged = new EventEmitter<string>();

  @ViewChild(TextInputWrapperComponent) inputWrapper?: TextInputWrapperComponent;

  protected onSearchChange(searchStr: string) {
    this.searchChanged.emit(searchStr);
  }

  protected clearSearch() {
    this.searchChanged.emit('');
  }
}
