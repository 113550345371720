<ng-container *ngrxLet="idpService.cognitoUser$ as currentAuthenticatedUser">
  <h1 data-cy="confirm-identity-dialog_title" [ngSwitch]="data.status" mat-dialog-title>
    <ng-container *ngSwitchCase="statuses.VerifyEmail">Enter email confirmation code</ng-container>
    <ng-container *ngSwitchCase="statuses.ChallengeMFA">Enter SMS verification code</ng-container>
    <ng-container *ngSwitchCase="statuses.UpdateNumber"
      >{{ code.value ? 'Confirm' : 'Enter' }} your Mobile Device number
    </ng-container>
    <ng-container *ngSwitchCase="statuses.InitNumber">Enter your Mobile Device number</ng-container>
    <ng-container *ngSwitchCase="statuses.ConfirmNumber">Enter SMS verification code</ng-container>
    <ng-container *ngSwitchCase="statuses.ConfirmMFA">Enter SMS verification code</ng-container>
  </h1>
  <ninety-button
    flat
    icon
    data-cy="confirm-identity-dialog_close-button"
    class="close-dialog-btn"
    (click)="closeFromButton()"
    *ngIf="data.showCloseButton"
    tooltip="Not validating your phone number will disable MFA">
    <terra-icon icon="x" />
  </ninety-button>

  <mat-dialog-content [ngClass]="expandContentHeight ? 'content-expand' : ''">
    <p data-cy="confirm-identity-dialog_description" [ngSwitch]="data.status">
      <ng-container *ngSwitchCase="statuses.VerifyEmail">Enter the verification code sent to your email</ng-container>
      <ng-container *ngSwitchCase="statuses.ChallengeMFA"
        >Enter verification code sent via SMS to your phone number ending in
        {{ currentAuthenticatedUser?.challengeParam?.CODE_DELIVERY_DESTINATION?.substr(-4) }}</ng-container
      >
      <ng-container *ngSwitchCase="statuses.UpdateNumber"
        >{{ code.value ? 'Confirm' : 'Enter' }} your Mobile Device number
      </ng-container>
      <ng-container *ngSwitchCase="statuses.InitNumber">Your company requires multi-factor authentication</ng-container>
      <ng-container *ngSwitchCase="statuses.ConfirmNumber"
        >Enter verification code sent via SMS to your phone number ending in
        {{ currentAuthenticatedUser?.attributes?.phone_number?.substr(-4) }}</ng-container
      >
      <ng-container *ngSwitchCase="statuses.ConfirmMFA"
        >Enter verification code sent via SMS to your phone number ending in
        {{ currentAuthenticatedUser?.attributes?.phone_number?.substr(-4) }} for full company access
      </ng-container>
    </p>
    <mat-form-field
      class="form-fields"
      appearance="outline"
      *ngIf="data.status !== statuses.UpdateNumber && data.status !== statuses.InitNumber">
      <input
        matInput
        data-cy="confirm-identity-dialog_confirmation-code-input"
        placeholder="Confirmation Code..."
        [formControl]="code"
        autocomplete="one-time-code"
        required
        (keyup.enter)="submitCode()"
        cdkFocusInitial />
    </mat-form-field>
    <form
      #ngxIntlTelInput
      [formGroup]="phoneForm"
      *ngIf="data.status === statuses.UpdateNumber || data.status === statuses.InitNumber">
      <ninety-intl-tel-input
        data-cy="confirm-identity-dialog_phone-number-input"
        formControlName="phone"></ninety-intl-tel-input>
      <p *ngIf="data.status === statuses.InitNumber || data.status === statuses.UpdateNumber">
        <terra-checkbox formControlName="sms_consent">Send SMS messages here to authenticate me</terra-checkbox>
      </p>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ninety-button-row>
      <ninety-button
        stroked
        data-product="confirm-identity-dialog__resend-email-button"
        (click)="resendEmail()"
        *ngIf="data.status === statuses.VerifyEmail">
        Resend Email
      </ninety-button>
      <ninety-button stroked (click)="onResendChallengeMfa()" *ngIf="data.status === statuses.ChallengeMFA">
        Resend SMS
      </ninety-button>
      <ninety-button
        stroked
        data-cy="confirm-identity-dialog_resend-sms-button"
        (click)="onResendSendSmsCode()"
        *ngIf="data.status === statuses.ConfirmNumber || data.status === statuses.ConfirmMFA">
        Resend SMS
      </ninety-button>

      <div class="fill-remaining-space"></div>

      <ninety-button
        filled
        primary
        data-cy="confirm-identity-dialog_submit-button"
        [attr.data-product]="
          data.status === statuses.VerifyEmail ? 'confirm-identity-dialog__verify-email-submit-button' : null
        "
        *ngIf="data.status !== statuses.UpdateNumber && data.status !== statuses.InitNumber"
        (click)="submitCode()">
        Submit
      </ninety-button>
      <ninety-button
        filled
        primary
        data-cy="confirm-identity-dialog_save-button"
        *ngIf="data.status === statuses.UpdateNumber || data.status === statuses.InitNumber"
        (click)="updatePhoneNumber()"
        [disabled]="phoneForm.invalid">
        Save
      </ninety-button>
    </ninety-button-row>
  </mat-dialog-actions>
</ng-container>
