import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { TerraTheme } from '../../models/terra-component-models';
import { TerraInputBoolean } from '../../models/terra-input-boolean.models';

export type TerraDividerHeight = 'tiny' | 'short' | 'standard' | 'tall';
export type TerraDividerMargins = 'none' | 'narrow' | 'wide';
export type TerraDividerWeight = 'standard' | 'light';
@Component({
  selector: 'terra-divider',
  standalone: true,
  exportAs: 'terraDivider',
  imports: [CommonModule],
  templateUrl: './terra-divider.component.html',
  styleUrls: ['./terra-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraDividerComponent implements OnInit {
  /**
   * Reference to the wrapping div element where classes will be added to
   */
  @ViewChild('divider', { static: true }) private _divider!: ElementRef<HTMLElement>;

  /**
   * Height of the divider
   * @default tiny
   */
  @Input() get height(): TerraDividerHeight {
    return this._height;
  }
  set height(value: TerraDividerHeight) {
    this._removeClass('height', this._height);
    this._height = value;
    this._addClass('height', this._height);
    this._changeDetectorRef.markForCheck();
  }
  private _height: TerraDividerHeight = 'tiny';

  /**
   * Left & Right Margins of the divider
   * @default none
   */
  @Input() get margins(): TerraDividerMargins {
    return this._margins;
  }
  set margins(value: TerraDividerMargins) {
    this._removeClass('margins', this._margins);
    this._margins = value;
    this._addClass('margins', this._margins);
    this._changeDetectorRef.markForCheck();
  }
  private _margins: TerraDividerMargins = 'none';

  /**
   * Visual Weight of the divider
   * @default standard
   */
  @Input() get weight(): TerraDividerWeight {
    return this._weight;
  }
  set weight(value: TerraDividerWeight) {
    this._removeClass('weight', this._weight);
    this._weight = value;
    this._addClass('weight', this._weight);
    this._changeDetectorRef.markForCheck();
  }
  private _weight: TerraDividerWeight = 'standard';

  /**
   * Theme of the divider
   * @default light
   */
  @Input() get theme(): TerraTheme {
    return this._theme;
  }
  set theme(value: TerraTheme) {
    this._removeClass('theme', this._theme);
    this._theme = value;
    this._addClass('theme', this._theme);
    this._changeDetectorRef.markForCheck();
  }
  private _theme: TerraTheme = 'light';

  /**
   * Sets whether the divider is vertical-oriented
   * @default false
   */
  @Input() get vertical(): boolean {
    return this._vertical;
  }
  set vertical(value: TerraInputBoolean) {
    this._vertical = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _vertical = false;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef, private readonly _renderer: Renderer2) {}

  ngOnInit() {
    this._addClass('height', this.height);
    this._addClass('margins', this.margins);
    this._addClass('weight', this.weight);
    this._addClass('theme', this.theme);
  }

  /**
   * Helper function for adding proper styles to the divider
   */
  private _addClass(modifier: string, value: string): void {
    if (this._divider) {
      this._renderer.addClass(this._divider.nativeElement, `terra-divider--${modifier}-${value}`);
    }
  }

  /**
   * Helper function to remove previously added classes from the divider
   */
  private _removeClass(modifier: string, value: string): void {
    if (this._divider) {
      this._renderer.removeClass(this._divider.nativeElement, `terra-divider--${modifier}-${value}`);
    }
  }
}
