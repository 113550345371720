import { Country } from '../directory/country';
import { EmergencyContact } from '../directory/emergency-contact';
import { StateProvince } from '../directory/state-province';

import { User } from './user';

export type MyersBrigs = {
  lifestylePreference: string;
  judgingFunction: string;
  perceivingFunction: string;
  attitude: string;
};

export type Kolbe = {
  factFinder: number;
  followThru: number;
  quickStart: number;
  implementor: number;
};

export type StrengthFinder = {
  bottomFive: string[];
  topFive: string[];
};

export class PersonMetadata {
  _id?: string;
  name?: {
    first: string;
    last: string;
  };
  fullName?: string;
  // TODO: Make Logo interface on Company shared
  picture?: {
    url?: string;
    /** @deprecated */
    count?: number;
  };
  deleted?: boolean;
  strengthFinder?: StrengthFinder;
  myersBriggs?: MyersBrigs;
  kolbe?: Kolbe;
  emergencyContacts?: EmergencyContact[];
  resumeUrl?: string;
  country: Country;
  postalCode?: string;
  stateCode?: any;
  province?: StateProvince;
  city?: any;
  streetAddress?: any;
  linkedInUrl?: any;
  dateOfBirth?: Date;
  preferredPronouns: string;
  __v?: any;

  constructor(first, last) {
    this.name = {
      first,
      last,
    };
    /*this.city = '';
    this.dateOfBirth = null;
    this.genderCode = null;
    this.kolbe = {
      factFinder: null,
      followThru: null,
      implementor: null,
      quickStart: null
    };
    this.myersBriggs = {
      attitude: null,
      judgingFunction: null,
      lifestylePreference: null,
      perceivingFunction: null
    };
    this.postalCode = '';
    this.stateCode = null;
    this.streetAddress = '';*/
  }
}

export const myersBriggsOptions = [
  'ESTP',
  'ESTJ',
  'ESFP',
  'ESFJ',
  'ENTP',
  'ENTJ',
  'ENFP',
  'ENFJ',
  'ISTP',
  'ISTJ',
  'ISFP',
  'ISFJ',
  'INTP',
  'INTJ',
  'INFP',
  'INFJ',
];

export enum States {
  'Alabama' = 'AL',
  'Alaska' = 'AK',
  'Arizona' = 'AZ',
  'Arkansas' = 'AR',
  'California' = 'CA',
  'Colorado' = 'CO',
  'Connecticut' = 'CT',
  'Delaware' = 'DE',
  'District of Columbia' = 'DC',
  'Florida' = 'FL',
  'Georgia' = 'GA',
  'Hawaii' = 'HI',
  'Idaho' = 'ID',
  'Illinois' = 'IL',
  'Indiana' = 'IN',
  'Iowa' = 'IA',
  'Kansas' = 'KS',
  'Kentucky' = 'KY',
  'Louisiana' = 'LA',
  'Maine' = 'ME',
  'Maryland' = 'MD',
  'Massachusetts' = 'MA',
  'Michigan' = 'MI',
  'Minnesota' = 'MN',
  'Mississippi' = 'MS',
  'Missouri' = 'MO',
  'Montana' = 'MT',
  'Nebraska' = 'NE',
  'Nevada' = 'NV',
  'New Hampshire' = 'NH',
  'New Jersey' = 'NJ',
  'New Mexico' = 'NM',
  'New York' = 'NY',
  'North Carolina' = 'NC',
  'North Dakota' = 'ND',
  'Ohio' = 'OH',
  'Oklahoma' = 'OK',
  'Oregon' = 'OR',
  'Pennsylvania' = 'PA',
  'Rhode Island' = 'RI',
  'South Carolina' = 'SC',
  'South Dakota' = 'SD',
  'Tennessee' = 'TN',
  'Texas' = 'TX',
  'Utah' = 'UT',
  'Vermont' = 'VT',
  'Virginia' = 'VA',
  'Washington' = 'WA',
  'West Virginia' = 'WV',
  'Wisconsin' = 'WI',
  'Wyoming' = 'WY',
}

export type PersonMetadataUpdate = {
  metadataUpdate?: Partial<PersonMetadata>;
  userUpdate?: Partial<User>;
};
