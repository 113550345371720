import { createActionGroup, props } from '@ngrx/store';
import { IndividualConfig } from 'ngx-toastr';

import { NotifyV2Params } from '../../../_core/services/notify.service';

export const NotificationActions = createActionGroup({
  source: 'Notifications',
  events: {
    Notify: props<{ message: string; duration?: number; action?: string }>(),
    'Notify V2': props<{ message: string; params?: NotifyV2Params }>(),
    'Notify Error': props<{ error: any; message: string }>(),
    'Show Error': props<{ message: string; title: string; override?: Partial<IndividualConfig> }>(),
    Success: props<{ message: string; title: string; override?: Partial<IndividualConfig> }>(),
  },
});
