/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { catchError, concatMap, exhaustMap, filter, forkJoin, iif, map, of, switchMap, tap } from 'rxjs';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { LinkedItemsActions } from '@ninety/detail-view/_shared/linked-items/_state/linked-items.actions';
import { DetailViewActions } from '@ninety/detail-view/_state/detail-view.actions';
import { MilestoneService } from '@ninety/rocks/_shared/milestone.service';
import { IntegrationService } from '@ninety/settings/user/integration/_api/integration.service';
import { TodoService } from '@ninety/todos/_shared/todo.service';
import { TeamTodosChildStateKey, TodoRootStateKey, selectTeamTodoState } from '@ninety/todos/_state';
import { createOrdinalUpdate } from '@ninety/todos/_state/_shared/todo-state.shared.effects';
import { TodoDetailActions } from '@ninety/todos/_state/detail/todo-detail.actions';
import { RepeatTodoDeleteDialogComponent } from '@ninety/todos/repeat-todo-delete/repeat-todo-delete-dialog.component';
import { TodoApiService } from '@ninety/todos/services/todo-api.service';
import { ExcelExportType } from '@ninety/ui/legacy/core/services/_state/filter-service/excel-export-types.enum';
import { FilterServiceActions } from '@ninety/ui/legacy/core/services/_state/filter-service/filter.service.actions';
import { FileService } from '@ninety/ui/legacy/core/services/file.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { LibraryService } from '@ninety/ui/legacy/core/services/library.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { PrintApi, PrintService } from '@ninety/ui/legacy/core/services/print.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { DetailViewInput } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoDeleOptions } from '@ninety/ui/legacy/shared/models/todos/todo-delete-options';
import { TodoMessageType } from '@ninety/ui/legacy/shared/models/todos/todo-message-types';
import { TodoRealTimeMessage } from '@ninety/ui/legacy/shared/models/todos/todo-realtime-message';
import { TodoRepeatType } from '@ninety/ui/legacy/shared/models/todos/todo-repeat-types';
import { TodoSortFieldEnum } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { UsersStateActions } from '@ninety/ui/legacy/state/app-entities/users/users-state.actions';
import {
  selectCurrentUser,
  selectUserSettings,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { appActions } from '@ninety/ui/legacy/state/app.actions';

import { TodoDetailSelectors } from '../detail/todo-detail.selectors';
import { PersonalTodoActions } from '../personal/personal-todo.actions';

import { TeamTodoActions, TeamTodoInlineActions, TodoAgreementActions } from './team-todo.actions';
import { TeamTodoFacade } from './team-todo.facade';
import { TeamTodoSelectors } from './team-todo.selectors';

const TODOS_URL = '/todos';

function isTodosUrl(url: string) {
  return url?.includes(TODOS_URL);
}

@Injectable()
export class TeamTodoEffects {
  constructor(
    private actions$: Actions,
    private todoService: TodoService,
    private milestoneService: MilestoneService,
    private store: Store,
    private createDialogService: CreateDialogService,
    private integrationService: IntegrationService,
    private detailService: DetailService<DetailViewInput<Todo>>,
    private notifyService: NotifyService,
    private stateService: StateService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private todoApi: TodoApiService,
    private printService: PrintService,
    private libraryService: LibraryService,
    private facade: TeamTodoFacade,
    private fileService: FileService,
    private filterService: FilterService
  ) {}

  sortBy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.sortBy),
        concatLatestFrom(() => [this.facade.currentUrl$]),
        filter(([_, url]) => isTodosUrl(url)),
        tap(([{ sort }, _]) => {
          if (sort.direction) {
            //direction is null when sort cycle is complete
            this.localStorageService.set(`${TodoRootStateKey}.${TeamTodosChildStateKey}.sort`, sort);
          } else {
            this.localStorageService.delete(`${TodoRootStateKey}.${TeamTodosChildStateKey}.sort`);
          }
        })
      ),
    { dispatch: false }
  );

  /** The main entry point for retrieving the current list of todos. Fires on any paging, sorting or archive change */
  getTeamTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TeamTodoActions.getTeam,
        TeamTodoActions.showArchived,
        TeamTodoActions.teamSelected,
        TeamTodoActions.paginationChange,
        TeamTodoActions.initMeetingConclude,
        TeamTodoActions.search,
        TeamTodoActions.deleteSeriesSuccess,
        TeamTodoActions.toggleUserPendingAgreements
      ),
      concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectTodoQueryParams)]),
      switchMap(([, params]) =>
        this.todoService.getTodos(params).pipe(
          map(response => TeamTodoActions.getTeamSuccess({ response: response })),
          catchError((error: unknown) => of(TeamTodoActions.getTeamFailure({ error })))
        )
      )
    )
  );

  getTeamTodosOnSort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.sortBy),
      concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectTodoQueryParams)]),
      switchMap(([, params]) =>
        this.todoService.getTodos(params).pipe(
          map(response => TeamTodoActions.getTeamSuccess({ response: response })),
          catchError((error: unknown) => of(TeamTodoActions.getTeamFailure({ error })))
        )
      )
    )
  );

  //Calls the todo service to update ordinals when a user moves a todo item in the list
  //Ordinals are used as the default sort order when no sort has been set
  updateOrdinals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateOrdinals),
      concatLatestFrom(() => [
        this.store.select(selectTeamTodoState),
        this.store.select(TeamTodoSelectors.selectTodoQueryParams),
      ]),
      filter(([, state, _]) => state.todos.length && !state.showArchived),
      switchMap(([action, state, params]) => {
        const todosWithChanges = createOrdinalUpdate(action, state);

        return this.todoService
          .updateOrdinals(
            todosWithChanges,
            state.ordinalKey,
            0,
            state.teamId,
            state.sortField,
            state.sortDirection,
            false,
            params.pageSize
          )
          .pipe(
            map(_ => TeamTodoActions.updateOrdinalsSuccess()),
            catchError(() => of(TeamTodoActions.updateOrdinalsFailure()))
          );
      }),
      // Never called as the request observable isn't being tracked
      catchError(() => of(TeamTodoActions.updateOrdinalsFailure()))
    )
  );

  updateOrdinalsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateOrdinalsSuccess),
      map(_ => TeamTodoActions.clearSort())
    )
  );

  clearSort$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.clearSort),
        tap(_ => this.localStorageService.delete(`${TodoRootStateKey}.${TeamTodosChildStateKey}.sort`))
      ),
    { dispatch: false }
  );

  updateTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.update, TeamTodoActions.updateInline, TeamTodoActions.updateDueDate),
      concatLatestFrom(() => [
        this.store.select(TeamTodoSelectors.selectedTodoId),
        this.store.select(TodoDetailSelectors.selectedTodo),
      ]),
      concatMap(([action, selectedTodoId, detailSelectedTodo]) => {
        //use the selected _todo Id if not provided. Used by the detail service, which only provides the update values
        //TODO: Remove the todoDetailSelector._id once my90 and team todos page are no longer using the detail store.
        const todoId = action.id ?? selectedTodoId ?? detailSelectedTodo._id;

        //Pass whether the _todo is being marked/unmarked complete so that any associated
        //milestones can be updated in the success effect below.
        const completeStatusChange = action.todo.hasOwnProperty('completed');

        /** Update inline success triggers an additional effect to update detail inputs */
        return this.todoService.updateTodo(todoId, action.todo).pipe(
          map(response => {
            if (action.type === TeamTodoActions.update.type) {
              return TeamTodoActions.updateSuccess({ todo: response, completeStatusChange });
            } else if (action.type === TeamTodoActions.updateDueDate.type) {
              return TeamTodoActions.updateDueDateSuccess({ todo: response, completeStatusChange });
            } else {
              return TeamTodoActions.updateInlineSuccess({ todo: response, completeStatusChange });
            }
          }),
          catchError(() => of(TeamTodoActions.updateFailure()))
        );
      })
    )
  );

  /**
   * Agreement Based Todos. Runs a normal update but updates the todo in state in the response
   * because todo acceptance/negotiation status is all handled server side.
   */
  acceptTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodoAgreementActions.accept),
      switchMap(({ todoId, update }) =>
        this.todoService.updateTodo(todoId, update).pipe(
          map(response => TodoAgreementActions.acceptSuccess({ todo: response })),
          catchError((error: unknown) => of(TodoAgreementActions.acceptFailure({ error })))
        )
      )
    )
  );

  addMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.addMany),
      exhaustMap(({ todo, userIds, createdFrom }) =>
        this.todoService.create(todo, userIds, createdFrom).pipe(
          map(response => TeamTodoActions.addManySuccess({ response, createdFrom })),
          catchError(() => of(TeamTodoActions.addManyFailure()))
        )
      )
    )
  );

  syncLinkedItems = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.addManySuccess),
      filter(({ createdFrom }) => !!createdFrom),
      map(({ createdFrom }) =>
        LinkedItemsActions.getLinkedItem({ id: createdFrom.id, linkedItemType: createdFrom.type })
      )
    )
  );

  uploadAttachments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.addManySuccess),
        filter(({ response }) => !!response.fileAttachments?.length),
        switchMap(data =>
          iif(
            () => !!data.response?.fileAttachments?.length,
            forkJoin([
              ...this.libraryService.handleTodoFileUploads(data.response.todos, data.response.fileAttachments),
            ]).pipe(map(attachments => ({ attachments }))),
            of({ attachments: [] })
          )
        )
      ),
    { dispatch: false }
  );

  syncSomeMilestoneFieldsFromTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.addManySuccess),
      //NOTE: if we get here it should only be one todo in the array
      //Universal create has multiple user selection disable for cases
      //when the todo is created from a milestone
      filter(({ response }) => !!response.todos[0].milestoneId),
      concatMap(({ response }) =>
        this.milestoneService
          .update(response.todos[0].milestoneId, {
            toDoId: response.todos[0]._id,
            dueDate: response.todos[0].dueDate,
            description: response.todos[0].description,
          })
          .pipe(
            map(() => TeamTodoActions.updateConnectedMilestoneSuccess()),
            catchError(() => of(TeamTodoActions.updateConnectedMilestoneFailure()))
          )
      )
    )
  );

  /**
   * Handles any integration errors that might have occurred while creating to-dos. To-do creation isn't blocked by external errors
   * but those errors are returned in the response so they can be handled here.
   */
  todoIntegrationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.addManySuccess, TeamTodoInlineActions.createOneSuccess),
      concatLatestFrom(() => [this.store.select(selectCurrentUser), this.store.select(selectUserSettings)]),
      filter(([{ response }, currentUser]) => !!response.integrationErrors?.find(e => e.userId === currentUser._id)),
      map(([{ response }, currentUser, userSettings]) => {
        /** Todos can be created for multiple users at once - check if any errors came back for the current user */
        const e = response.integrationErrors.find(e => e.userId === currentUser._id);
        const settingsAlerts = Object.assign({}, userSettings.settingsAlerts, { integration: e.error });
        const integrations = cloneDeep(userSettings.integrations);
        integrations.google.requiresAuthentication = true;

        return UsersStateActions.updateOne({
          _id: currentUser._id,
          changes: {
            settings: { ...currentUser.settings, ...{ settingsAlerts, integrations } },
          } as Partial<User>,
        });
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateUser),
      concatMap(({ id, todo }) =>
        this.todoService.updateTodo(id, todo).pipe(
          map(todo => TeamTodoActions.updateUserSuccess({ todo })),
          catchError(() => of(TeamTodoActions.updateUserFailure()))
        )
      )
    )
  );

  setCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.setCompleted),

      map(action =>
        TeamTodoActions.updateInline({
          id: action.id,
          todo: {
            completed: action.todo.completed,
            completedDate: action.todo.completedDate,
          },
        })
      )
    )
  );

  updatePersonalTodosOnMyNinety$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateSuccess),
      concatLatestFrom(() => [this.facade.isMyNinetyUrl$]),
      filter(([_, isMyNinetyUrl]) => isMyNinetyUrl),
      map(([{ todo }]) => {
        if (todo.isPersonal) return PersonalTodoActions.addToPersonal({ todo });
        else return PersonalTodoActions.removeFromPersonal({ todo });
      })
    )
  );

  refreshTeamTodosInSeriesInStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.updateSuccess, TeamTodoActions.updateInlineSuccess, TeamTodoActions.updateUserSuccess),
      map(({ todo }) => {
        if (todo.seriesId && todo.repeat !== TodoRepeatType.DontRepeat) {
          return TeamTodoActions.updateDisplayedTodosInSeries({ todo });
        }
        return appActions.noop();
      })
    )
  );

  removeAcceptedTodoFromStoreIfPendingFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodoAgreementActions.acceptSuccess),
      concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectUserPendingAgreementsFilter)]),
      filter(([_, filter]) => filter),
      map(([{ todo }]) => TeamTodoActions.removeAcceptedTodoFromState({ id: todo._id }))
    )
  );

  //Update any milestones associated with a _todo as a result of a successful update
  updateMilestones$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.updateSuccess),
        map(action => {
          if (action.completeStatusChange && action.todo?.milestoneId) {
            this.milestoneService.update(action.todo.milestoneId, { isDone: action.todo.completed });
          }
        })
      ),
    { dispatch: false }
  );

  toggleArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.toggleArchived),
      exhaustMap(action =>
        this.todoService.toggleArchive(cloneDeep(action.todo)).pipe(
          map(todo => TeamTodoActions.toggleArchivedSuccess({ todo, id: todo._id })),
          catchError(() => of(TeamTodoActions.toggleArchivedFailure()))
        )
      )
    )
  );

  deleteConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.deleteConfirm),
      concatLatestFrom(() => this.store.select(selectFeatureFlag(FeatureFlagKeys.repeatingTodos))),
      map(([action, repeatingTodos]) =>
        repeatingTodos && action.todo.seriesId && action.todo.repeat !== TodoRepeatType.DontRepeat
          ? TeamTodoActions.openDeleteTodoDialog({ todo: action.todo })
          : TeamTodoActions.delete({ todo: action.todo })
      )
    )
  );

  openDeleteTodoDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.openDeleteTodoDialog),
      exhaustMap(({ todo }) =>
        this.dialog
          .open(RepeatTodoDeleteDialogComponent, {
            data: { todo },
          })
          .afterClosed()
      ),
      filter(option => !!option),
      map(({ option, todo }) => {
        if (option === TodoDeleOptions.item) return TeamTodoActions.delete({ todo: todo });
        else return TeamTodoActions.deleteSeries({ todo: todo });
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.delete),
      concatMap(({ todo }) =>
        this.todoService.delete(todo).pipe(
          map(() => TeamTodoActions.deleteSuccess({ id: todo._id })),
          catchError(() => of(TeamTodoActions.deleteFailure()))
        )
      )
    )
  );

  deleteSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.deleteSeries),
      concatMap(action =>
        this.todoService.deleteSeries(action.todo).pipe(
          map(_ => TeamTodoActions.deleteSeriesSuccess({ seriesId: action.todo.seriesId, todo: action.todo })),
          catchError(() => of(TeamTodoActions.deleteSeriesFailure()))
        )
      )
    )
  );

  createOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoInlineActions.createOne),
      exhaustMap(({ todo }) => {
        const newTodo = Object.assign(cloneDeep(todo), { originalDueDate: todo.dueDate });
        return this.todoService.createOne(newTodo).pipe(
          map(response => TeamTodoInlineActions.createOneSuccess({ response })),
          catchError(() => of(TeamTodoInlineActions.createOneFailure()))
        );
      })
    )
  );

  /**
   * Handles opening the create dialog, but does not respond to it being closed.
   *
   * New todos created by the create dialog are handed by {@link MyNinetyPageEffects#addTeamTodosToStateAfterPersist$}
   * in My90 and the {@link TodosComponent} on the main Todos page.
   */
  openCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.openCreateDialog),
        switchMap(() => this.createDialogService.open({ itemType: ItemType.todo }))
      ),
    { dispatch: false }
  );

  //TODO: check from milestone todo to personal for errors or missing fields

  /** Creates a google task from a todo */
  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.createTask),
      concatMap(action =>
        this.integrationService.createTask(action.id).pipe(
          map(task => TeamTodoActions.createTaskSuccess({ todoId: action.id, googleTaskId: task.id })),
          catchError(() => of(TeamTodoActions.createTaskFailure()))
        )
      )
    )
  );

  syncTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.syncTasks),
      concatLatestFrom(() => this.store.select(TeamTodoSelectors.selectTeamId)),
      exhaustMap(([, teamId]) =>
        this.integrationService.syncTasks(teamId).pipe(
          tap(() =>
            this.notifyService.notify(
              `${this.stateService.language.todo.items} successfully resynced with Google Tasks`
            )
          ),
          map(() => TeamTodoActions.getTeam()),
          catchError(() => of(TeamTodoActions.syncTasksFailure()))
        )
      )
    )
  );

  moveToBottom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.moveToBottom),
      concatLatestFrom(() => this.store.select(TeamTodoSelectors.selectTeamTodoCount)),
      map(([{ index: previousIndex }, todosCount]) =>
        TeamTodoActions.updateOrdinals({ previousIndex, currentIndex: todosCount - 1 })
      )
    )
  );

  moveToTop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.moveToTop),
      map(({ index: previousIndex }) => TeamTodoActions.updateOrdinals({ previousIndex, currentIndex: 0 }))
    )
  );

  makeItAnIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.makeItAnIssue),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.issue,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.createTodo),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.todo,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createRock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.createRock),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.rock,
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.createHeadline),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.headline,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  savePageSizeOnLocalStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.savePageSizeOnLocalStore),
        tap(({ pageSize }) => {
          this.localStorageService.set(`${TodoRootStateKey}.${TeamTodosChildStateKey}.pagination.size`, pageSize);
        })
      ),
    { dispatch: false }
  );

  promptOnRequestToArchiveAllCompletedUserTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.requestArchiveAllCompletedUserTeam),
      switchMap(() => this.todoService.confirmArchiveCompletedDialog()),
      filter(shouldContinue => !!shouldContinue),
      map(() => TeamTodoActions.archiveAllCompletedUserTeam())
    )
  );

  archiveAllCompletedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.archiveAllCompletedUserTeam),
      switchMap(() => this.todoApi.archiveAllCompletedUserTeam()),
      map(resp => TeamTodoActions.archiveAllCompletedUserTeamSuccess(resp)),
      catchError((error: unknown) => of(TeamTodoActions.archiveAllCompletedUserTeamFailure({ error })))
    )
  );

  archiveAllCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.archiveAllCompleted),
      switchMap(() =>
        this.todoService.archiveAllCompleted().pipe(
          filter(archivedCount => !!archivedCount),
          tap(archivedCount => {
            const language = this.stateService.language;
            // null if confirm dialog is canceled
            this.notifyService.notify(
              `${archivedCount} ${archivedCount === 1 ? language.todo.item : language.todo.items} archived.`,
              3000,
              undefined
            );
          })
        )
      ),
      map(() => TeamTodoActions.archiveAllCompletedSuccess()),
      catchError((error: unknown) => of(TeamTodoActions.archiveAllCompletedFailure({ error })))
    )
  );

  refetchTeamTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.archiveAllCompletedUserTeamSuccess, TeamTodoActions.archiveAllCompletedSuccess),
      map(() => TeamTodoActions.getTeam())
    )
  );

  print$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.print),
      concatLatestFrom(() => [this.store.select(selectTeamTodoState)]),
      switchMap(([{ printOptions }, state]) =>
        this.printService
          .openPdf(PrintApi.todos, {
            teamId: state.teamId,
            showArchived: state.showArchived,
            sortField: TodoSortFieldEnum[state.sortField],
            sortDirection: state.sortDirection,
            searchText: state.searchText,
            printOptions,
          })
          .pipe(
            map(() => TeamTodoActions.printSuccess()),
            catchError(() => of(TeamTodoActions.printFailure()))
          )
      )
    )
  );

  deleteCommentTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.deleteComment),
      concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectTeamTodos)]),
      concatMap(([action, selectedTodos]) => {
        const selectedTodo = selectedTodos.find(t => t._id === action.event._id);

        const comments = selectedTodo.comments.filter(
          c =>
            c.userId !== action.event.comment.userId ||
            new Date(c.createdDate).getTime() !== new Date(action.event.comment.createdDate).getTime()
        );

        return this.todoService.updateTodo(selectedTodo._id, { comments: comments }).pipe(
          map(response => TeamTodoActions.updateSuccess({ todo: response, completeStatusChange: false })),
          catchError(() => of(TeamTodoActions.updateFailure()))
        );
      })
    )
  );

  /** An attachment was updated locally - broadcast the event */
  attachmentUploaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.attachmentUploaded, TeamTodoActions.attachmentRemoved),
        switchMap(action => {
          const messageType =
            action.type === TeamTodoActions.attachmentUploaded.type
              ? TodoMessageType.attachmentUpload
              : TodoMessageType.attachmentRemove;
          const payload = {
            messageType,
            document: action.event.attachment.parentId,
          } as TodoRealTimeMessage;

          return this.todoService.broadcastMessage(payload);
        })
      ),
    {
      dispatch: false,
    }
  );

  /** An attachment was reordered locally - broadcast the event */
  attachmentReordered$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamTodoActions.attachmentReordered),
        switchMap(({ event }) => {
          const payload = {
            messageType: TodoMessageType.attachmentUpload,
            document: event._id,
          } as TodoRealTimeMessage;

          return this.todoService.broadcastMessage(payload);
        })
      ),
    {
      dispatch: false,
    }
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.select),
      map(({ todo }) => TodoDetailActions.openInDetail({ todo }))
    )
  );

  setSelectedIdOnReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.getTeamSuccess),
      concatLatestFrom(() => [this.store.select(TeamTodoSelectors.selectTodoIdFromRoute)]),
      filter(([_, todoId]) => !!todoId),
      map(([_, todoId]) => TeamTodoActions.setSelectedId({ todoId }))
    )
  );

  clearSelectedOnDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamTodoActions.deselect),
      map(() => DetailViewActions.closed({ itemType: DetailType.todo }))
    )
  );

  downloadExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterServiceActions.exportToExcel),
      filter(({ exportType }) => exportType === ExcelExportType.teamTodo),
      concatLatestFrom(() => [
        this.store.select(TeamTodoSelectors.selectTodoQueryParams),
        this.filterService.selectedTeam$,
        this.store.select(selectLanguage),
      ]),
      switchMap(([, params, team, language]) =>
        this.todoService
          .downloadExcel(params)
          .pipe(
            tap(response => {
              const currentDate = new Date();
              const fileName = `${language.todo.items}_${team.name}_${formatDate(
                currentDate,
                'MMddyyyy',
                'en-US'
              )}.xlsx`;
              this.fileService.downloadExcelFile(response, fileName);
            })
          )
          .pipe(
            map(() => TeamTodoActions.downloadExcelSuccess()),
            catchError(() => of(TeamTodoActions.downloadExcelFailure()))
          )
      )
    )
  );
}
