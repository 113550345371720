import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { BannerNotificationActions } from '../../../_state/app-global/banner-notification/banner-notification.actions';
import { BannerNotificationModel } from '../../../_state/app-global/banner-notification/banner-notification.model';
import { BannerStateSelectors } from '../../../_state/app-global/banner-notification/banner-notification.selectors';

@Component({
  selector: 'ninety-notification-banner',
  template: `<ng-container>
    <span class="message-container">
      <ng-template [ngIf]="banner?.hasIcon">
        <terra-icon [icon]="banner?.iconKey" />
      </ng-template>
      <span class="message" data-cy="banner_content">
        {{ banner?.message }}
      </span>
      <span class="action" *ngIf="banner?.actionRoute && banner?.callToActionActive">
        <a
          data-cy="notification-banner_add-billing-info-button"
          [routerLink]="banner?.actionRoute"
          [attr.data-product]="
            banner?.dismissible
              ? 'trial-banner__add-billing-info-button-dismissible'
              : 'trial-banner__add-billing-info-button-non-dismissible'
          "
          >{{ banner?.actionMessage }}</a
        >
      </span>
    </span>
    <span class="dismissible">
      <ninety-button
        data-cy="notification-banner_close-button"
        *ngIf="banner?.dismissible"
        compact
        flat
        icon
        (click)="bannerDismissed()">
        <terra-icon icon="x" ninetyTooltip="Close" />
      </ninety-button>
    </span>
  </ng-container>`,

  styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit, OnDestroy {
  banner: BannerNotificationModel;
  constructor(private store: Store) {}
  public ngOnInit(): void {
    this.store
      .select(BannerStateSelectors.selectMessage)
      .pipe(
        tap((data: BannerNotificationModel) => {
          this.banner = data;
          this.visible = data?.message.length > 0;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  protected destroy$ = new Subject<void>();

  @HostBinding('class.ninety-banner-primary') get primaryClass() {
    return this.banner?.type === 'primary';
  }
  @HostBinding('class.ninety-banner-negative') get negativeClass() {
    return this.banner?.type === 'negative';
  }
  @HostBinding('class.ninety-banner-neutral') get neutralClass() {
    return this.banner?.type === 'neutral';
  }
  @HostBinding('class.ninety-banner-positive') get positiveClass() {
    return this.banner?.type === 'positive';
  }
  @HostBinding('class.hide') get hideClass() {
    return this.visible === false;
  }

  /**
   * Toggle the visibility of the Banner.
   */
  @Input()
  set visible(visible: boolean) {
    if (this._visible !== visible) {
      this._visible = visible;
      this.visibleChange.emit(visible);
    }
  }
  get visible() {
    return this._visible;
  }

  private _visible = false;
  /**
   * Event triggered when Banner visibility changed.
   */
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  public bannerDismissed(): void {
    this.visible = false;
    this.store.dispatch(BannerNotificationActions.dismissBanner({ banner: this.banner }));
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
