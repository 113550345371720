import { Pipe, PipeTransform } from '@angular/core';

import { Item } from '../models/_shared/item';

@Pipe({
  name: 'filterOutItemById',
})
export class FilterOutItemByIdPipe implements PipeTransform {
  transform<T>(items: T[], itemId: string): T[] {
    if (!items || !itemId) {
      return items;
    }

    return items.filter((item: T & { _id: string }) => item._id !== itemId);
  }
}
