import { SortDirection } from '../enums/sort-direction';

import { IssueSortField } from './issues-sort-field';

export class IssueOptions {
  page = 0;
  pageSize = 20;
  sortField: IssueSortField = 'None';
  sortDirection: SortDirection = SortDirection.ASC;
  onlyPublic = false;
  longTerm = false;
  showArchived = false;
  searchText = '';
  teamId: string;

  constructor(config?: Partial<IssueOptions>) {
    if (config) Object.assign(this, config);
  }
}
