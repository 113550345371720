import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TopToolbarActions = createActionGroup({
  source: 'MainToolbar',
  events: {
    'Reset To Default': emptyProps(),
    'Toolbar Hidden': props<{ toolbarHidden: boolean }>(),

    'Show Toolbar Shadow': emptyProps(),
    'Hide Toolbar Shadow': emptyProps(),

    'Set Chip Message': props<{ message: string }>(),
    'Hide Chip Message': emptyProps(),
    'Set Page Title': props<{ pageTitle: string }>(),
  },
});
