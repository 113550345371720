import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LinkedItemsStateModel } from './linked-items-state.model';

export namespace LinkedItemsSelectors {
  export const selectLinkedItemsState = createFeatureSelector<LinkedItemsStateModel>('linked-items');

  export const selectId = createSelector(selectLinkedItemsState, state => state._id);
  export const selectType = createSelector(selectLinkedItemsState, state => state.type);
  export const selectLoading = createSelector(selectLinkedItemsState, state => state.loading);
  export const selectLinkedItems = createSelector(selectLinkedItemsState, state => state.linkedItems);
  export const selectChannelId = createSelector(selectLinkedItemsState, state => state.channelId);
  export const selectShouldBroadcast = createSelector(selectLinkedItemsState, state => state.shouldBroadcast);

  export const selectTotalItems = createSelector(
    selectLinkedItemsState,
    state =>
      state.linkedItems?.totalTodos +
      state.linkedItems?.totalIssues +
      state.linkedItems?.totalRocks +
      state.linkedItems?.totalHeadlines +
      state.linkedItems?.totalCascadingMessages +
      state.linkedItems?.totalLearningTasks +
      state.linkedItems?.totalLearningTopics +
      state.linkedItems?.totalMilestones
  );
  export const selectUnlinkedItem = createSelector(selectLinkedItemsState, state => state.unlinkedItem);
}
