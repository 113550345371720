import { Directive, HostBinding, Input } from '@angular/core';

import { TextInputWrapperVariant } from './ninety-input.directive';

@Directive({
  selector: '[ninetyInputVariant]',
  standalone: true,
})
export class InputVariantDirective {
  @Input() ninetyInputVariant: TextInputWrapperVariant = 'outline';

  @HostBinding('class.ninety-input--outline')
  protected get isOutline(): boolean {
    return this.ninetyInputVariant === 'outline';
  }

  @HostBinding('class.ninety-input--inline')
  protected get isInline(): boolean {
    return this.ninetyInputVariant === 'inline';
  }

  @HostBinding('class.ninety-input--none-variant')
  protected get isNone(): boolean {
    return !this.ninetyInputVariant;
  }
}
