import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceStrict, isValid } from 'date-fns';

@Pipe({
  name: 'dateDistance',
  standalone: true,
})
export class DateDistancePipe implements PipeTransform {
  transform(date: Date | string): string {
    if (!date && !isValid(date)) return;

    const difference = formatDistanceStrict(new Date(date), new Date());

    if (difference === '0 seconds') return 'Just now';

    return difference + ' ago';
  }
}
