import { Pipe, PipeTransform } from '@angular/core';

import { PersonMetadata } from '../models/_shared/person-metadata';

@Pipe({
  name: 'sortByUserName',
})
export class SortByUserNamePipe implements PipeTransform {
  transform<T extends { metadata?: PersonMetadata }>(users: T[], ascending = true): T[] {
    return SortByUserNamePipe.sort(users, ascending);
  }

  public static sort<T extends { metadata?: PersonMetadata }>(users: T[], ascending: boolean): T[] {
    if (!users) return users;
    return users.sort((a: T, b: T) => {
      const userA = `${a?.metadata?.name?.first ?? ''}${a?.metadata?.name?.last ?? ''}`.toLocaleLowerCase();
      const userB = `${b?.metadata?.name?.first ?? ''}${b?.metadata?.name?.last ?? ''}`.toLocaleLowerCase();
      return ascending ? (userA < userB ? -1 : userB < userA ? 1 : 0) : userB < userA ? -1 : userA < userB ? 1 : 0;
    });
  }
}
