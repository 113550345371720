import { BosDefaultSection } from '@ninety/ui/legacy/shared/models/vto/bos-default-section';
import { Vto } from '@ninety/ui/legacy/shared/models/vto/vto';
import { EmptyPinnacleSections, PinnacleSectionLabels } from '@ninety/ui/legacy/shared/models/vto/vto-sections';

export type BosDefaultSectionIdToTitleMap = Record<keyof typeof PinnacleSectionLabels, BosDefaultSection['title']>;

export namespace BosDefaultSectionIdToTitleMap {
  /**
   * A way to extract out all the titles of the {@link BosDefaultSection}s.
   *
   * This is needed for
   * 1. Caching - Allow title changes to be reverted from Manage Sections when in layout mode
   * 2. Persisting - Apply changes from Manage Sections to in memory state (and then, through that state, the API/DB)
   *
   * Future refactors will seek to eliminate the need for this process and standardize title access/persistence across
   * all section types.
   */
  export function extractFrom(vto: Vto): BosDefaultSectionIdToTitleMap {
    const initial: Partial<BosDefaultSectionIdToTitleMap> = {};
    return Object.keys(PinnacleSectionLabels).reduce((acc, pinnacleSectionKey: keyof typeof PinnacleSectionLabels) => {
      if (pinnacleSectionKey === 'brandPromises') {
        acc[pinnacleSectionKey] = 'Brand Promises';
        return acc;
      }

      const sectionInVto: BosDefaultSection = vto[pinnacleSectionKey] as BosDefaultSection; // Safe cast - already handled brandPromises
      acc[pinnacleSectionKey] = sectionInVto?.title ?? EmptyPinnacleSections[pinnacleSectionKey].title;

      return acc;
    }, initial) as BosDefaultSectionIdToTitleMap; // convince compiler the object is no longer a partial
  }

  /**
   * Applies the titles from BosDefaultSectionIdToTitleMap to their corresponding VTO section.
   */
  export function enrich(
    vto: Vto,
    titles: BosDefaultSectionIdToTitleMap
  ): Pick<Vto, keyof typeof PinnacleSectionLabels> {
    return Object.entries(titles)
      .filter(([key, _]) => key !== 'brandPromises')
      .reduce((acc, [pinnacleKey, title]) => {
        acc[pinnacleKey] = { ...vto[pinnacleKey], title };
        return acc;
      }, {});
  }
}
