import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { LoginQueryStringParams } from '@ninety/login/_api/models/login-query-string-parameters.model';
import { RedirectType } from '@ninety/ui/legacy/core/services/identity-provider.service';
import { UserInviteStatus } from '@ninety/ui/legacy/shared/models/_shared/user-invite-status';
import { CompanyAffiliateResponse } from '@ninety/ui/legacy/shared/models/company/company-affiliate-response';
import { DomainPartnerConfiguration } from '@ninety/ui/legacy/shared/models/login/login-domain-partner-configurations';

export const LoginSharedStateActions = createActionGroup({
  source: 'Login',
  events: {
    'Use Existing Partner Configuration': props<{ configuration: DomainPartnerConfiguration }>(),
    'Open Forgot Password Dialog': props<{ email: string }>(),
    'Close Forgot Password Dialog': emptyProps(),
    'Map Login Query Params': props<{ params: LoginQueryStringParams }>(),
    'Has Cognito Errors': props<{ error: string; errorDescription: string }>(),
    'Affiliate Company Received': props<{ affiliateCompany: CompanyAffiliateResponse }>(),
    'Social Signup Redirect': props<{ provider: CognitoHostedUIIdentityProvider; redirectType: RedirectType }>(),
    'Navigate to Login': props<{ event?: MouseEvent }>(),
    'Navigate to App': props<{ route: string }>(),
    'Check User Invite Status': props<{ email: string }>(),
  },
});

export const UserStatusDialogActions = createActionGroup({
  source: 'UserStatusDialogComponent',
  events: {
    'Dialog Closed': props<{ userInviteStatus: UserInviteStatus }>(),
  },
});
