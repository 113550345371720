import { ReadonlyDeep } from 'type-fest';

import { BusinessOperatingSystem } from '../company/business-operating-system.enum';
import { Issue } from '../issues/issue';
import { Rock } from '../rocks/rock';

import { BosDefaultSection } from './bos-default-section';
import { CascadedSections } from './cascaded-sections';
import { CoreFocus } from './core-focus';
import { CoreValue } from './core-value';
import { DescriptionAndValues } from './description-and-values';
import { MarketingStrategy } from './marketing-strategy';
import { BrandPromise } from './pinnacle/brand-promise';
import { TextItem } from './text-item';
import { VisionFutureDate } from './vision-future-date';
import { VisionMeasurable } from './vision-measurable';
import { VtoLabels } from './vto-labels';
import { EmptyPinnacleSections, PinnacleSectionLabels, VtoSections, VtoSectionSettings } from './vto-sections';

type VtoBosDependentDefaults = Pick<Vto, 'labels' | 'sectionSettings' | 'customSectionSettings'>;

export type ReadonlyVtoBosDependentDefaults = ReadonlyDeep<VtoBosDependentDefaults>;

export namespace ReadonlyVtoBosDependentDefaults {
  export function forBos(bos: BusinessOperatingSystem): ReadonlyVtoBosDependentDefaults {
    return {
      labels: VtoLabels.LabelsByBosLookup[bos] ?? VtoLabels.LabelsByBosLookup[BusinessOperatingSystem.ninetyOS],
      sectionSettings:
        VtoSectionSettings.StandardSettingsByBosLookup[bos] ??
        VtoSectionSettings.StandardSettingsByBosLookup[BusinessOperatingSystem.ninetyOS],
      customSectionSettings: [],
    };
  }
}

export type ReadonlyVtoLabels = ReadonlyVtoBosDependentDefaults['labels'];
export type ReadonlyVtoSectionSettings = ReadonlyVtoBosDependentDefaults['sectionSettings'];
export type ReadonlyVtoCustomSectionSettings = ReadonlyVtoBosDependentDefaults['customSectionSettings'];

export interface Vto extends VtoSections {
  _id: string;
  teamId: string;
  deleted: boolean;
  visionMeasurables: VisionMeasurable[];
  ninetyDays: VisionFutureDate;
  oneYear: VisionFutureDate;
  threeYear: VisionFutureDate;
  tenYear: string;
  profits: DescriptionAndValues[];
  revenues: DescriptionAndValues[];
  marketingStrategies: MarketingStrategy[];
  coreValues: CoreValue[];
  labels: VtoLabels;
  cascadedSections: CascadedSections;
  isSharing: boolean;
  archivedVtoIds: string[];
  isArchived: boolean;
  rocks: Rock[];
  issues: Issue[];
  strengths: TextItem[];
  weaknesses: TextItem[];
  opportunities: TextItem[];
  threats: TextItem[];
  coreFocus: CoreFocus;

  // Bos Specific Fields
  // Pinnacle ========
  purposeWhyWeExist?: BosDefaultSection;
  coreBusiness?: BosDefaultSection;
  pinnacleTopOfMountain?: BosDefaultSection;
  quarterlyTheme?: BosDefaultSection;
  flywheelDesign?: BosDefaultSection;
  oneLineMarketingPhrase?: BosDefaultSection;
  brandPromises?: BrandPromise[];
}

export namespace Vto {
  export function pickPinnacleSections(vto: Vto): Pick<Vto, keyof typeof PinnacleSectionLabels> {
    const initial: Partial<Pick<Vto, keyof typeof PinnacleSectionLabels>> = {};
    return Object.keys(EmptyPinnacleSections).reduce((acc, key) => {
      acc[key] = vto[key];
      return acc;
    }, initial);
  }
}
