import { ExcelExportType } from '../../../_core/services/_state/filter-service/excel-export-types.enum';

export interface FilterOptions {
  filtersToolbar?: boolean;
  searchInput?: boolean;
  rockStatusDropdown?: boolean;
  masteryStatusDropdown?: boolean;
  archiveToggle?: boolean;
  completeToggle?: boolean;
  printToPdfBtn?: boolean;
  downloadCsvBtn?: boolean;
  archiveAllBtn?: boolean;
  importBtn?: boolean;
  settingsBtn?: boolean;
  personDropdown?: boolean;
  ownerDropdown?: boolean;
  teamDropdown?: boolean;
  allTeamsOption?: boolean;
  refreshButton?: boolean;
  filterOutProjectTeams?: boolean;
  zoomButtons?: boolean;
  expandCollapse?: boolean;
  topSeatDropdown?: boolean;
  rightPadding?: string;
  userStatusDropdown?: boolean;
  showForecastingBtn?: boolean;
  customerCompaniesDropdown?: boolean;
  feedbackSettingsBtn?: boolean;
  showCompletedConversations?: boolean;

  /**
   * When true, all buttons/options are hidden in place of a title
   */
  showTitle?: boolean;

  /**
   * Text to show instead of options
   */
  title?: string;

  /**
   * The descriptor of the toolbar, shown in the far left. Usually: 'Filters'
   */
  toolbarDescriptor?: string;

  // VTO

  /**
   * Show button to print the VTO, Rocks, Issues, Data, Todos, and other data relevant to a quarter.
   */
  quarterlyExportBtn?: boolean;

  /**
   * Show button to archive a copy of the VTO and view the archived copies.
   */
  archiveCopy?: boolean;

  /**
   * Show the "Share VTO" and the "View SLT VTO" toggle on SLT and non-SLT teams respectively.
   */
  vtoToggle?: boolean;

  /**
   * Show toggle to enable the layout mode of the VTO grid
   */
  vtoGrid?: boolean;

  /**
   * Show the manage sections button, which launches a dialog to add/remove/hide sections from the VTO
   */
  gridLayoutOptions?: boolean;

  /**
   * Button to trigger layout mode for the given page
   */
  editLayoutBtn?: boolean;

  /** Sets the type of Excel export for filtering actions in effects */
  excelExportType?: ExcelExportType;
}

export namespace FilterOptions {
  export function Default(): FilterOptions {
    return {
      filtersToolbar: true,
      teamDropdown: true,
      searchInput: true,
      rockStatusDropdown: false,
      masteryStatusDropdown: false,
      archiveToggle: false,
      completeToggle: false,
      printToPdfBtn: false,
      downloadCsvBtn: false,
      archiveAllBtn: false,
      importBtn: false,
      settingsBtn: false,
      quarterlyExportBtn: false,
      personDropdown: false,
      ownerDropdown: false,
      refreshButton: false,
      allTeamsOption: false,
      showTitle: false,
      title: '',
      filterOutProjectTeams: false,
      zoomButtons: false,
      expandCollapse: false,
      topSeatDropdown: false,
      vtoToggle: false,
      archiveCopy: false,
      userStatusDropdown: false,
      showForecastingBtn: false,
      vtoGrid: false,
      customerCompaniesDropdown: false,
      feedbackSettingsBtn: false,
      gridLayoutOptions: false,
      toolbarDescriptor: 'Filters',
      editLayoutBtn: false,
      excelExportType: null,
    };
  }
}
