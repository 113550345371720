<ng-container *ngIf="options && !readonly && !isDisabled; else viewOnly">
  <ninety-text-editor-froala
    [options]="options"
    [placeholder]="placeholder"
    [text]="text | falseyMapper"
    (textChange)="onChange($event)"></ninety-text-editor-froala>
</ng-container>

<ng-template #viewOnly>
  <ninety-text-editor-froala
    data-cy="text-editor_view-only-text"
    [readonly]="readonly || isDisabled"
    [text]="text | falseyMapper"
    [variant]="variant"></ninety-text-editor-froala>
</ng-template>
