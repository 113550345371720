import { createSelector } from '@ngrx/store';

import { RealTimeStateKey, selectGlobalAppState } from '..';
import { CONVERSATION_CHANNEL_PREFIX } from '../../../_shared/models/feedback/conversation-real-time.models';

export const selectRealTimeState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[RealTimeStateKey] : null
);
export const selectChannels = createSelector(selectRealTimeState, state => state.channels);

export function selectConversationChannel(conversationId: string) {
  return createSelector(
    selectChannels,
    channels => channels.find(c => c.channelId === `${CONVERSATION_CHANNEL_PREFIX}${conversationId}`) ?? null
  );
}
