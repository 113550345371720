export const routeNames = {
  ac: 'chart',
  feedback: 'feedback',
  myfocus: 'home',
  process: 'process',
  directory: 'directory',
  meeting: 'meeting',
  meetings: 'meetings',
  myaccount: 'my-account',
  conversation: 'conversation',
  oneOnOne: '1-on-1',
  discussions: 'discussions',
  discussion: 'discussion',
};
