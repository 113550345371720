import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ninety-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.scss'],
})
export class MetricCardComponent {
  @HostBinding('class') class = 'flex';

  _comparedMetric: number;
  _positiveMetricCard: boolean;

  @Input() title: string;
  @Input() titleTooltip: string;
  @Input() metric: string | number;

  @Input() set comparedMetric(value: number) {
    this._comparedMetric = value;
    this._positiveMetricCard = null;
    if (this._comparedMetric > 0) this._positiveMetricCard = true;
    if (this._comparedMetric < 0) this._positiveMetricCard = false;
  }
  get comparedMetric(): number {
    return this._comparedMetric;
  }
  get positiveMetric(): boolean {
    return this._positiveMetricCard;
  }

  @Input() comparedMetricTooltip: string;
  @Input() unit: string;
  @Input() disabledStyle: boolean;
  //** use a threshold value to enable the top bar style */
  @Input() threshold: number;
}
