import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, catchError } from 'rxjs';

import { HeadlineService } from '../services/headline.service';

@Injectable({
  providedIn: 'root',
})
export class HeadlineResolver {
  constructor(private headlineService: HeadlineService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.headlineService.getHeadlineById(route.params.id).pipe(
      catchError((_error: unknown) => {
        this.router.navigate(['/headlines']);
        return EMPTY;
      })
    );
  }
}
