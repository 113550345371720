import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DragAndDropEvent } from '@ninety/todos/_state/_shared/todo-state.shared.actions';
import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';
import { CascadingMessagesSort } from '@ninety/ui/legacy/shared/models/cascading-messages/cascading-message-sort';
import { CascadingMessage } from '@ninety/ui/legacy/shared/models/meetings/cascading-message';
import { CascadingMessagesResponse } from '@ninety/ui/legacy/shared/models/meetings/cascading-messages-response';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { RealtimeMessage } from '@ninety/ui/legacy/shared/models/meetings/realtime-message';

export const CascadingMessagesStateActions = createActionGroup({
  source: 'Cascading Messages',
  events: {
    Hydrate: props<{ pageSize: number; sort: CascadingMessagesSort }>(),

    Get: emptyProps(),
    'Get Success': props<{
      cascadingMessages: CascadingMessagesResponse;
      //Temp solution when headlines are loaded in meeting
      //We need to set done in meeting headlines and CMs
      //Meeting can be retrieved from state in the future
      meeting?: Meeting;
    }>(),
    'Get Failed': props<{ error: unknown }>(),

    Update: props<{ update: Update<CascadingMessage> }>(),
    'Update Success': props<{ update: Update<CascadingMessage> }>(),
    'Update Failed': props<{ error: unknown }>(),
    'Update Local': props<{ update: Update<CascadingMessage> }>(),

    'Set Archived': props<{ id: string; archived: boolean }>(),
    'Set Archived Success': props<{ cascadingMessage: CascadingMessage }>(),
    'Set Archived Failed': props<{ error: unknown }>(),
    'Archive Local': props<{ id: string }>(),

    Search: props<{ searchText: string }>(),

    'Set Completed': props<{ id: string; completed: boolean }>(),
    'Set Completed Success': props<{ update: Update<CascadingMessage> }>(),
    'Set Completed Failed': props<{ error: unknown }>(),

    'Set Completed In Meeting Success': props<{ update: Update<CascadingMessage> }>(),
    'Set Completed In Meeting Failed': props<{ error: unknown }>(),

    'Set User': props<{ id: string; ownedByUserId: string }>(),
    'Set User Success': props<{ update: Update<CascadingMessage> }>(),
    'Set User Failed': props<{ error: unknown }>(),

    Delete: props<{ id: string }>(),
    'Delete Success': props<{ id: string }>(),
    'Delete Failed': props<{ error: unknown }>(),
    'Delete Local': props<{ id: string }>(),

    'Set Team': props<{ id: string; teamId: string }>(),
    'Set Team Success': props<{ id: string; teamId: string }>(),
    'Set Team Failed': props<{ error: unknown }>(),

    Add: props<{ cascadingMessage: CascadingMessage }>(),
    //TODO NEXT: when select team is added for headlines in the filter bar
    //currently you can't create a CM for the meeting team, and attendees can't see other teams
    // 'Add Local': props<{ cascadingMessage: CascadingMessage }>(),

    'Filter By Team': props<{ teamId: string }>(),
    'Show Archived': props<{ archived: boolean }>(),
    'Pagination Change': props<{ index: number; size: number }>(),
    'Sort Change': props<{ sort: CascadingMessagesSort }>(),

    //sets flag to include discussed in meeting headlines
    'Set In Meeting Id': props<{ meetingId: string }>(),

    'Open Create Dialog': emptyProps(),
    'Open In Detail View': props<{ cascadingMessageId: string }>(),

    'Set Selected': props<{ selectedId: string }>(),
    'Clear Selected': emptyProps(),

    Cascade: props<{ cascadingMessage: CascadingMessage }>(),
    'Create Issue': props<{ cascadingMessage: CascadingMessage }>(),
    'Create Todo': props<{ cascadingMessage: CascadingMessage }>(),
    'Create Headline': props<{ cascadingMessage: CascadingMessage }>(),
    'Create Rock': props<{ cascadingMessage: CascadingMessage }>(),

    'Add Attachment': props<{ id: string; attachment: Attachment }>(),
    'Remove Attachment': props<{ id: string; attachmentId: string }>(),

    'Update Ordinals': props<DragAndDropEvent>(),

    'Page Destroy': emptyProps(),
    'Reset Store': emptyProps(),

    'Clear Sort': emptyProps(),

    'Broadcast Message': props<{ message: RealtimeMessage }>(),
    'Create Cascaded For Segment': props<{ cascadingMessage: CascadingMessage }>(),
    'Mark Completed For Segment': props<{ update: Update<CascadingMessage> }>(),

    'Remove Done Headline Success': emptyProps(),
    'Remove Done Headline Failure': emptyProps(),
    'Remove Cascaded Message Success': emptyProps(),
    'Remove Cascaded Message Failure': emptyProps(),
  },
});
