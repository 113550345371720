export enum DialogMode {
  expanded = 'expanded',
  mini = 'mini',
  collapsed = 'collapsed',
}

export enum DialogModeClass {
  expanded = 'dialog-expanded',
  mini = 'dialog-minimized',
  collapsed = 'dialog-collapsed',
}
