import { OneOnOneSettings } from '@ninety/ui/legacy/shared/models/feedback/one-on-one-settings';

export interface FormalConversationState {
  oneOnOneSettings: OneOnOneSettings;
}
export const defaultFormalConversationState: FormalConversationState = {
  oneOnOneSettings: {
    leadershipCommitments: [],
    coachingCommitments: [],
    annualQuestions: [],
    quarterlyQuestions: [],
  },
};
