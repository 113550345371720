import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';
import { NinetyChipComponent } from '@ninety/ui/legacy/components/ninety-chip/ninety-chip.component';
import { UserAvatarComponent } from '@ninety/ui/legacy/components/user-avatar/user-avatar.component';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { PastMeetingsPaginatedResponse } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';
import { TodayOrDatePipe } from '@ninety/ui/legacy/shared/pipes/dates/today-or-date.pipe';
import { SecondsToDurationPipe } from '@ninety/ui/legacy/shared/pipes/seconds-to-duration.pipe';
import { UserInitialsPipe } from '@ninety/ui/legacy/shared/pipes/user-initials.pipe';

@Component({
  selector: 'ninety-meetings-table',
  standalone: true,
  templateUrl: './meetings-table.component.html',
  styleUrls: ['./meetings-table.component.scss'],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    LetDirective,
    UserAvatarComponent,
    UserInitialsPipe,
    TerraIconModule,
    SecondsToDurationPipe,
    TodayOrDatePipe,
    NinetyChipComponent,
    DatePipe,
  ],
})
export class MeetingsTableComponent {
  private _matSortDirection: SortDirection;
  private _matSortActive: string;

  @Input() dataSource: Observable<PastMeetingsPaginatedResponse[]>;

  @Input() set sort(val: Sort) {
    const { direction, active } = val;
    this._matSortDirection = direction;
    this._matSortActive = active;
  }

  get matSortDirection(): SortDirection {
    return this._matSortDirection;
  }
  get matSortActive(): string {
    return this._matSortActive;
  }

  @Input() dateFormat: string;
  @Input() selectedId: string;

  @Output() sortChange = new EventEmitter<Sort>();
  @Output() meetingSelected = new EventEmitter<Meeting>();

  displayedColumns: string[] = [
    'frontSpacer',
    'date',
    'agendaName',
    'totalDuration',
    'presenterName',
    'ratingAverage',
    'endSpacer',
  ];

  trackById(index: number, item: PastMeetingsPaginatedResponse) {
    return item._id;
  }

  onSortChange(event: Sort): void {
    this.sortChange.emit(event);
  }

  onSelected(meeting: Meeting): void {
    this.meetingSelected.emit(meeting);
  }
}
