<div class="container" *ngrxLet="companyUserList$ | async as companyUsersList">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Add Helpful to New Company</mat-card-title>
    </mat-card-header>
    <mat-card-content class="find-company-card-content">
      <div class="search-div">
        <mat-form-field class="full-width">
          <input
            #companyToFind
            matInput
            placeholder="Find company by ID"
            [formControl]="companyToFindForm"
            name="company-search"
            aria-label="company search"
            type="text"
            (keyup.enter)="findCompanyById(companyToFind.value)" />
          <button
            mat-icon-button
            matSuffix
            color="accent"
            aria-label="Find company"
            (click)="findCompanyById(companyToFind.value)"
            matTooltip="Find Company"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="search" />
          </button>
          <mat-error *ngIf="companyToFindForm.errors?.isObjectId">Must be a valid ObjectId</mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>

    <ng-container *ngIf="foundCompany">
      <terra-divider />
      <mat-card-footer>
        <div class="found-company">
          <img
            [src]="foundCompany.logoUrl"
            alt="company logo"
            *ngIf="foundCompany.logoUrl"
            class="company-logo"
            loading="lazy" />
          <div class="flex1">
            {{ foundCompany.companyName }}
          </div>
          <ninety-alert *ngIf="alreadyInCompany" alertClass="warning">Helpful is already in this company</ninety-alert>
          <ng-container *ngIf="!alreadyInCompany">
            <button *ngIf="!newCompanyUser" mat-raised-button class="uppercase" color="accent" (click)="addHelpful()">
              <terra-icon icon="add" />
              Add Helpful to this Company
            </button>
            <button
              *ngIf="newCompanyUser"
              mat-raised-button
              class="uppercase"
              color="accent"
              (click)="sessionService.switchCompany(newCompanyUser)">
              <terra-icon icon="sign-in" />
              Login
            </button>
          </ng-container>
        </div>
      </mat-card-footer>
    </ng-container>

    <terra-divider />

    <mat-card-header>
      <mat-card-title>Helpful Companies</mat-card-title>
      <div class="fill-remaining-space"></div>
      <div class="search-div current-search">
        <mat-form-field class="full-width" [class.hidden]="companyUsersList.length <= 10">
          <input
            #companySearchInput
            matInput
            placeholder="Search current companies by Name or ID"
            value=""
            name="current-company-search"
            aria-label="current company search"
            type="text"
            (change)="companySearchInput.focus()" />
          <button
            mat-button
            *ngIf="companySearchInput.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="companySearchInput.value = null">
            <terra-icon icon="x" />
          </button>
        </mat-form-field>
      </div>
    </mat-card-header>

    <mat-list class="ninety-list">
      <mat-list-item
        class="list-item"
        *ngFor="let c of companyUsersList | searchCompanyFilter : companySearchInput.value; trackBy: trackById">
        <div class="list-title">
          {{ c.companyName }}
        </div>
        <img [src]="c?.logoUrl" alt="company logo" *ngIf="c?.logoUrl" class="company-logo" loading="lazy" />
        <button
          mat-icon-button
          color="accent"
          (click)="sessionService.switchCompany(c)"
          [disabled]="c.userId === stateService.currentUser._id"
          matTooltip="Login to this company"
          matTooltipPosition="above"
          aria-label="Login to this company">
          <terra-icon icon="sign-in" />
        </button>
        <button
          mat-icon-button
          class="delete-btn"
          color="warn"
          (click)="removeHelpfulFromCompany(c)"
          [disabled]="c.userId === stateService.currentUser._id"
          matTooltip="Remove Helpful from this company"
          matTooltipPosition="above"
          aria-label="Delete">
          <terra-icon icon="delete" />
        </button>
      </mat-list-item>
    </mat-list>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Suppression List</mat-card-title>
    </mat-card-header>
    <mat-card-content class="find-company-card-content">
      <div class="search-div current-search">
        <mat-form-field class="full-width">
          <input
            #suppressionSearchInput
            matInput
            placeholder="Search email suppression list"
            value=""
            name="suppression-search"
            aria-label="suppression list search"
            type="text"
            [(ngModel)]="suppressionListSearch"
            (change)="suppressionSearchInput.focus()" />
          <button
            mat-icon-button
            matSuffix
            color="accent"
            aria-label="Search"
            (click)="searchSuppressionList(suppressionSearchInput.value); suppressionSearchInput.value = null"
            matTooltip="Search suppression list"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="search" />
          </button>
        </mat-form-field>
      </div>
      <mat-spinner color="accent" *ngIf="fetchingSuppressionList"></mat-spinner>
      <mat-list class="ninety-list">
        <mat-list-item
          class="list-item"
          *ngFor="
            let suppressionRecord of suppressionList$ | async | emailAddressSearch : suppressionListSearch;
            trackBy: trackByEmail
          ">
          <div class="list-title">{{ suppressionRecord.EmailAddress }}</div>
          <p class="right-space-10">{{ suppressionRecord.Reason }}</p>
          <p>{{ suppressionRecord.LastUpdateTime }}</p>
          <button
            mat-icon-button
            class="delete-btn"
            color="warn"
            (click)="removeFromSuppressionList(suppressionRecord.EmailAddress)"
            matTooltip="Remove from suppression list"
            matTooltipPosition="above"
            aria-label="Delete">
            <terra-icon icon="delete" />
          </button>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="canHardDeleteCompany$ | ngrxPush" class="ninety-card">
    <mat-card-header>
      <mat-card-title>Delete Company Data</mat-card-title>
      <div>
        <mat-form-field class="full-width">
          <input
            #companyIdDeleteInput
            matInput
            placeholder="companyId to permanently delete"
            value=""
            name="company-delete"
            aria-label="companyId to permanently delete"
            type="text"
            [pattern]="helperService.mongoIdRegexp()"
            [(ngModel)]="companyIdToDelete"
            (change)="companyIdDeleteInput.focus()" />
          <button
            mat-button
            *ngIf="companyIdDeleteInput.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="companyIdDeleteInput.value = null">
            <terra-icon icon="x" />
          </button>
        </mat-form-field>
        <button mat-button color="warn" [disabled]="!companyIdToDelete" (click)="openDeleteCompanyDialog()">
          Hard Delete
        </button>
      </div>
    </mat-card-header>
    <div class="flex-1">
      <div class="left-space-16" *ngFor="let line of (companyDeletionResult$ | async).split('\n')">{{ line }}</div>
    </div>
  </mat-card>
</div>
