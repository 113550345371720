import { Partner } from '@ninety/ui/legacy/shared/models/partner-hub/partner';

export type PartnerKeyUpdate = {
  keyUpdate: string;
  partner: Partner;
};

export class PartnerKeyUpdateDto {
  public internal_key: string;
  public personId: string;
  public partner_key: string;
  constructor({ partner: { key, person }, keyUpdate }: PartnerKeyUpdate) {
    this.internal_key = key;
    this.personId = person._id;
    this.partner_key = keyUpdate;
  }
}
