import { Pipe, PipeTransform } from '@angular/core';

import { Milestone } from '../models/rocks/milestone';
import { Rock } from '../models/rocks/rock';

@Pipe({
  name: 'filterByRocksTeamId',
})
export class FilterByRocksTeamIdPipe implements PipeTransform {
  transform(list: Milestone[], teamId: string, rocks: Rock[]): Milestone[] {
    if (!list || !teamId || teamId === 'all' || !rocks?.length) return list;

    const rockIds: string[] = rocks
      .filter((rock: Rock) => {
        if ('additionalTeamIds' in rock) {
          return [rock.teamId, ...rock.additionalTeamIds]
            .filter((value, index, self) => self.indexOf(value) === index) // Gets unique ids just in case.
            .find(id => id === teamId);
        }

        return rock.teamId === teamId;
      })
      .map((rock: Rock) => rock._id);

    return list.filter(el => rockIds.includes(el.rockId));
  }
}
