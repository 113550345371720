import { createReducer, on } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';

import { CompanyLanguage, CustomLanguage } from '../../../_shared/models/language/custom-language';

import { LanguageActions } from './language.actions';

export interface LanguageStateModel {
  companyLanguage: CompanyLanguage;
  resetLanguage: CustomLanguage;
  /** @deprecated */
  defaultBOSLanguage?: CustomLanguage;
}

export const initialLanguageState: LanguageStateModel = {
  companyLanguage: null,
  resetLanguage: null,
  defaultBOSLanguage: null,
};
export const languageReducer = createReducer<LanguageStateModel>(
  initialLanguageState,
  on(
    LanguageActions.init,
    (state, companyAndDefault): LanguageStateModel => ({ ...state, ..._cloneDeep(companyAndDefault) })
  ),
  on(
    LanguageActions.updateCurrentCompanyLanguageSuccess,
    (state, { companyLanguage }): LanguageStateModel => ({ ...state, companyLanguage: _cloneDeep(companyLanguage) })
  ),
  on(
    LanguageActions.setResetLanguage,
    (state, { resetLanguage }): LanguageStateModel => ({ ...state, resetLanguage: _cloneDeep(resetLanguage) })
  )
);
