import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, filter, map, Subscription } from 'rxjs';

import { ElementResizeAwareDirective } from '../../directives/element-resize-aware/element-resize-aware.directive';
import {
  FroalaOptions,
  FroalaTextEditorOptions,
  getResponsiveOptions,
  ResponsiveFroalaOptions,
} from '../../models/_shared/froala-options';
import { elementWidthToToolbarSize, TextEditorToolbarSize } from '../../models/_shared/froala-toolbar-options';
import { ITextEditorOptions } from '../../models/_shared/text-editor-options';
import { TextEditorVariant } from '../text-editor/text-editor.component';

const RESIZE_DEBOUNCE = 100;

@Component({
  selector: 'ninety-text-editor-froala',
  templateUrl: './text-editor-froala.component.html',
  styleUrls: ['./text-editor-froala.component.scss'],
})
export class TextEditorFroalaComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly subscriptions = new Subscription();
  TextEditorToolbarSize = TextEditorToolbarSize;

  /**
   * Why: responsive on element width, not viewport
   * Froala has no mechanism to refresh the toolbar without re-initializing the entire editor.
   * Thus, limited to using ngIf or ngSwitch to trigger a re-paint of Froala.
   */
  size: TextEditorToolbarSize;
  sizedOptions: ResponsiveFroalaOptions;

  /** Optional override. */
  @Input() options!: ITextEditorOptions<FroalaOptions>;

  @Input() readonly = false;
  @Input() placeholder!: string;
  @Input() text!: string;
  @Input() formControl?: UntypedFormControl;
  @Input() variant: TextEditorVariant = TextEditorVariant.Primary;

  @Output()
  textChange = new EventEmitter<string>();

  @ViewChild(ElementResizeAwareDirective) resizer: ElementResizeAwareDirective;

  @HostBinding('class.text-editor-primary')
  protected get primaryClass() {
    return this.variant === TextEditorVariant.Primary;
  }

  @HostBinding('class.ninety-input--outline')
  protected get outlineClass() {
    return this.variant === TextEditorVariant.Outline;
  }

  constructor(private defaultOptions: FroalaTextEditorOptions, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.options) {
      this.options = this.defaultOptions.clone();
    }

    if (this.placeholder) this.options.setPlaceholder(this.placeholder);
    else this.options.resetPlaceholder();

    this.sizedOptions = getResponsiveOptions(this.options.value());
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.resizer
        .getWidthObserver({ debounceTime: RESIZE_DEBOUNCE })
        .pipe(
          filter(_ => !this.readonly),
          map(width => elementWidthToToolbarSize(width)),
          distinctUntilChanged()
        )
        .subscribe(size => {
          this.size = size;
          this.cdr.detectChanges();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onFroalaChangeEvent(text: string) {
    if (this.readonly) return;

    if (this.formControl) {
      this.formControl.setValue(text);
    }

    this.textChange.emit(text);
  }
}
