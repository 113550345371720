export class DefaultConversationQuestion {
  question?: string;
  elementId?: string;
  title?: string;
  sectionTitleSameAsQuestion?: boolean;

  constructor(sidebarText = '', question = '') {
    this.question = question;
    this.elementId = sidebarText;
    this.title = sidebarText;
    this.sectionTitleSameAsQuestion = false;
  }
}

export class FormalConversationQuestion extends DefaultConversationQuestion {
  manageeAnswer?: string;
  managerAnswer?: string;
  constructor(sidebarText = '', question = '') {
    super(question, sidebarText);
    this.manageeAnswer = '';
    this.managerAnswer = '';
  }
}

export const isConversationQuestion = (q: DefaultConversationQuestion): q is DefaultConversationQuestion =>
  q && !!q.question;

export const getDefaultConversationQuestions = (): FormalConversationQuestion[] => [
  new FormalConversationQuestion('Strengths & Key Accomplishments', 'Strengths & Accomplishments'),
  new FormalConversationQuestion('Area(s) that Needs Improvement', 'Improvement Areas'),
  new FormalConversationQuestion('Plan to Get Things on Track', 'Plan to Get On Track'),
];

export const getDefaultQuarterlyQuestions = (): FormalConversationQuestion[] => [
  new FormalConversationQuestion('Where and how did I/this team member add the most value?', 'Value Added'),
  new FormalConversationQuestion('Overall strengths - what is mine/their superpower?', 'Strengths'),
  new FormalConversationQuestion('Overall areas of opportunity and development', 'Opportunity and Development'),
  new FormalConversationQuestion('Current growth goals (i.e., focus and needs)', 'Growth Goals'),
];
