import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { tap } from 'rxjs';

import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';

@Component({
  selector: 'ninety-meeting-duration',
  templateUrl: './meeting-duration.component.html',
})
export class MeetingDurationComponent implements OnChanges {
  @Input()
  meeting: Meeting;

  duration = 0;

  constructor(private meetingService: MeetingService, private spinnerService: SpinnerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this._getElapsedMeetingDuration();
  }

  private _getElapsedMeetingDuration() {
    if (this.meeting.previousMeetingId || this.meeting.nextMeetingId) {
      this.spinnerService.start();
      this.meetingService
        .getMeetingDetail(this.meeting.previousMeetingId ?? this.meeting.nextMeetingId)
        .pipe(
          tap(previousMeeting => {
            this.duration = this.meeting.elapsedTime + previousMeeting.elapsedTime;
            this.spinnerService.stop();
          })
        )
        .subscribe();
    } else {
      this.duration = this.meeting.elapsedTime;
    }
  }
}
