import { Injectable } from '@angular/core';
import type { Context } from '@datadog/browser-core';
import { HandlerType, Logger as DDLogger, StatusType } from '@datadog/browser-logs';

import { LogDto, Logger, LogLevel } from '../../logging';

/**
 * Wrapper around datadogLogs.Logger.
 *
 * Allows us to intercept messages to the class and modify details without touching the library code.
 */
@Injectable()
export class DatadogLogger implements Logger {
  constructor(private ddLogger: DDLogger) {}

  private log(level: LogLevel, log: LogDto): void {
    const { message, data = {}, error = undefined } = log;

    switch (level) {
      case LogLevel.Debug:
        this.ddLogger.debug(message, data, error as Error);
        break;
      case LogLevel.Error:
        this.ddLogger.error(message, data, error as Error);
        break;
      case LogLevel.Info:
        this.ddLogger.info(message, data, error as Error);
        break;
      case LogLevel.Warn:
        this.ddLogger.warn(message, data, error as Error);
        break;
    }
  }

  debug(log: LogDto): void {
    this.log(LogLevel.Debug, log);
  }

  info(log: LogDto): void {
    this.log(LogLevel.Info, log);
  }

  warn(log: LogDto): void {
    this.log(LogLevel.Warn, log);
  }

  error(log: LogDto): void {
    this.log(LogLevel.Error, log);
  }

  setContext(context: object): void {
    this.ddLogger.setContext(context);
  }

  getContext(): Context {
    return this.ddLogger.getContext();
  }

  addContext(key: string, value: any): void {
    this.ddLogger.addContext(key, value);
  }

  removeContext(key: string): void {
    this.ddLogger.removeContext(key);
  }

  setHandler(handler: HandlerType | HandlerType[]): void {
    this.ddLogger.setHandler(handler);
  }

  getHandler(): HandlerType | HandlerType[] {
    return this.ddLogger.getHandler();
  }

  private getStatusFromLevel(level: LogLevel) {
    let status: StatusType;

    switch (level) {
      case LogLevel.Debug:
        status = 'debug';
        break;
      case LogLevel.Error:
        status = 'error';
        break;
      case LogLevel.Info:
        status = 'info';
        break;
      case LogLevel.Warn:
        status = 'warn';
        break;
    }

    return status;
  }

  setMinLevel(level: LogLevel): void {
    const status = this.getStatusFromLevel(level);

    this.ddLogger.setLevel(status);
  }

  private getLevelFromStatus(status: StatusType): LogLevel {
    let level: LogLevel;

    switch (status) {
      case StatusType.debug:
        level = LogLevel.Debug;
        break;
      case StatusType.error:
        level = LogLevel.Error;
        break;
      case StatusType.info:
        level = LogLevel.Info;
        break;
      case StatusType.warn:
        level = LogLevel.Warn;
        break;
    }

    return level;
  }

  getMinLevel(): LogLevel {
    const status = this.ddLogger.getLevel();
    const level = this.getLevelFromStatus(status);
    return level;
  }
}
