import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DetailViewState, DetailViewStateKey } from '@ninety/detail-view/_state/detail-view.model';

export namespace DetailServiceSelectors {
  export const featureState = createFeatureSelector<DetailViewState>(DetailViewStateKey);

  export const isStreamingEnabled = createSelector(featureState, state => state.isStreamingEnabled);

  export const selectDisableScrollbar = createSelector(featureState, state => state.disableScrollbar);
}
