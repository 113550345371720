import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';

import { CompanyUserListModel } from '../../_state';
import { selectCurrentCompanyUserWithActiveCompanyUsers } from '../../_state/app-entities/company-users/company-users-state.selectors';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class SwitchCompanyGuard {
  constructor(private sessionService: SessionService, private store: Store, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const companyId = route.url[1]?.toString();
    let nextCompanyUser: CompanyUserListModel = null;
    let currentUser: CompanyUserListModel = null;

    this.store
      .select(selectCurrentCompanyUserWithActiveCompanyUsers)
      .pipe(take(1))
      .subscribe(({ currentCompanyUser, activeCompanyUsers }) => {
        nextCompanyUser = activeCompanyUsers.find(u => u.companyId === companyId);
        currentUser = currentCompanyUser;
      });

    if (!companyId || !nextCompanyUser) {
      if (!companyId) console.log('Missing companyId param');
      if (!nextCompanyUser) console.log('No company user found');
      return this.redirectToLogin(state);
    }

    const redirectUrl = this.getRedirectUrl(route.url);

    if (currentUser?.companyId === companyId) {
      console.log('Company already matches companyId');
      if (redirectUrl) {
        return this.router.parseUrl(redirectUrl);
      }

      return this.redirectToHome();
    }

    console.log(`switching company to ${nextCompanyUser?.companyId}`);
    this.sessionService.switchCompany(nextCompanyUser, this.getRedirectUrl(route.url));

    return true;
  }

  /**
   * Gets the redirect URL from an array of URL segments after removing the company ID from the URL.
   * @returns {string} The redirect URL.
   */
  private getRedirectUrl(url: UrlSegment[]): string {
    // company/:companyId/issues/:issueId
    // company/:companyId/settings/user/notifications
    // etc...
    if (url.length > 2) {
      return `/${url.slice(2).join('/')}`;
    }

    return '';
  }

  private redirectToHome(): UrlTree {
    return this.router.parseUrl(`/home`);
  }

  private redirectToLogin(state: RouterStateSnapshot): UrlTree {
    return this.router.parseUrl(`/login?redirectUrl=${state.url}`);
  }
}
