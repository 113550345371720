import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import _cloneDeep from 'lodash/cloneDeep';

import { MasterySettings } from '../../models/mastery/mastery';

@Component({
  selector: 'ninety-mastery-config',
  templateUrl: './mastery-config.component.html',
  styleUrls: ['./mastery-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasteryConfigComponent {
  // this is temporary until the ngrx completion that's about to FINALLY happen
  masterySettings: MasterySettings;

  @Input() set mastery(mastery: MasterySettings) {
    this.masterySettings = _cloneDeep(mastery || new MasterySettings());
  }

  @Input() title = 'Mastery';
  @Input() subText = 'A customizable tool that allows you to learn and track mastery across your teams';
  @Input() headerClass: string;
  @Input() readonly?: boolean;
  //for PH a generic text is used and item can be empty
  @Input() masteryItemLanguage?: string;

  @Output() updateMastery = new EventEmitter<MasterySettings>();

  update(key: keyof MasterySettings, value: boolean): void {
    // something strange with two-way binding.  this is a workaround until the ngrx completion for mastery
    this.masterySettings = { ...this.masterySettings, [key]: value };
    this.updateMastery.emit(this.masterySettings);
  }
}
