import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, filter, map } from 'rxjs';

import { GridLayoutActions } from '@ninety/ui/legacy/layouts/grid-layout/_state/grid-layout-state.actions';
import { GridLayoutSelectors } from '@ninety/ui/legacy/layouts/grid-layout/_state/grid-layout-state.selectors';
import { GridWidget } from '@ninety/ui/legacy/layouts/grid-layout/models/grid-widget.model';
import { ResetLayoutOptions } from '@ninety/ui/legacy/layouts/grid-layout/models/reset-layout.options';

import { VtoLayoutModeState } from '../models/vto-layout-mode-state.enum';

/**
 * @deprecated This is VTO only, use NGRX directly for future use cases.
 */
@Injectable({
  providedIn: 'root',
})
export class LegacyVTOGridLayoutActions {
  private readonly layoutModeState = new BehaviorSubject<VtoLayoutModeState>(VtoLayoutModeState.DISABLED);

  private readonly customVtoChange = new Subject<boolean>();
  private readonly resetLayout = new Subject<ResetLayoutOptions>();
  private readonly updateLayout = new Subject<void>();
  private readonly cacheLayout = new Subject<void>();
  private readonly persistLayout = new Subject<void>();
  private readonly openManageSections = new Subject<void>();

  private readonly itemChanges = new Subject<GridWidget<unknown>[]>();

  /**
   * Emits whenever the items registered in a grid change. Happens when:
   *  a) A user resizes an element
   *  b) A user moves an element
   *  c) An element is moved due to a collision
   *
   * Emits all the items that change after an action, not just the one the user interacted with.
   *
   * See this.buildItemChanges$ - initialized in constructor
   *
   * @deprecated rely on NGRX.
   */
  itemChanges$: Observable<GridWidget<unknown>[]> = this.itemChanges.asObservable();

  /**
   * Emits on any layout mode state change
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   * */
  layoutModeState$: Observable<VtoLayoutModeState> = this.layoutModeState.asObservable();

  /**
   * Emits when the layout mode is enabled/disabled, which enables resize/drag-drop. When this observable emits true,
   * the grid is already enabled. False => any other VtoLayoutModeState
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  layoutModeEnabled$: Observable<boolean> = this.layoutModeState$.pipe(
    filter(state => state !== VtoLayoutModeState.REQUEST_ENABLE),
    map(state => state === VtoLayoutModeState.ENABLED),
    distinctUntilChanged()
  );

  /**
   * Emits when a re-paint of the grid should occur
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  updateLayout$: Observable<void> = this.updateLayout.asObservable();

  /**
   * Emits when a copy of the current in-memory content should be cached.
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  cacheLayout$: Observable<void> = this.cacheLayout.asObservable();

  /**
   * Emits when a user has chosen to save the layout. Causes a PATCH request to update the settings
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  persistLayout$: Observable<void> = this.persistLayout.asObservable();

  /**
   * Emits when any in-memory layout changes should be reset to their persisted values
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  resetLayout$: Observable<ResetLayoutOptions> = this.resetLayout.asObservable();

  /**
   * Emits to request that the manage sections dialog is opened.
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  openManageSections$: Observable<void> = this.openManageSections.asObservable();

  /**
   * When true, the grid is being displayed with only one column.
   *
   * @deprecated Rely on NGRX
   */
  isSingleColumnView$: Observable<boolean> = this.store.select(GridLayoutSelectors.selectIsOneColumnMode);

  /**
   * Emits whenever the user changes whether they are on custom VTO
   *
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  customVtoChange$: Observable<boolean> = this.customVtoChange.asObservable();

  constructor(private store: Store) {}

  /**
   * @deprecated Rely on NGRX.
   */
  emitGridSettingsUpdate(items: GridWidget<unknown>[]) {
    this.itemChanges.next(items);
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitCustomVtoChange(customVtoOn: boolean) {
    this.customVtoChange.next(customVtoOn);
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitResetLayout(opts: Partial<ResetLayoutOptions> = {}) {
    const combinedOpts: ResetLayoutOptions = Object.assign({}, ResetLayoutOptions.Default(), opts);
    this.resetLayout.next(combinedOpts);
    this.emitLayoutModeStateChange(VtoLayoutModeState.DISABLED);
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitCacheLayout() {
    this.cacheLayout.next();
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  forceDisableLayoutMode(opts: Partial<ResetLayoutOptions> = {}) {
    if (this.layoutModeState.value === VtoLayoutModeState.ENABLED) {
      const combinedOpts: ResetLayoutOptions = Object.assign({ force: true }, opts);

      this.emitResetLayout(combinedOpts);
      this.emitLayoutModeStateChange(VtoLayoutModeState.DISABLED);
    }
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitPersistLayoutChanges() {
    this.persistLayout.next();
    this.emitLayoutModeStateChange(VtoLayoutModeState.DISABLED);
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitLayoutModeStateChange(layoutModeState: VtoLayoutModeState) {
    this.layoutModeState.next(layoutModeState);

    if (layoutModeState === VtoLayoutModeState.DISABLED || layoutModeState === VtoLayoutModeState.ENABLED) {
      const editLayoutEnabled = layoutModeState === VtoLayoutModeState.ENABLED;
      this.store.dispatch(GridLayoutActions.modifyEditState({ editLayoutEnabled }));
    }
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitLaunchManageSections() {
    this.openManageSections.next();
  }

  /**
   * @deprecated Needs to be migrated to NGRX as a part of the VTO rewrite.
   */
  emitUpdateLayout() {
    this.updateLayout.next();
  }
}
