<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="my-90" />
      <span data-cy="my-ninety-language_card-title">{{ my90.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="my90">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="my90.route + ' Page Name'"
          data-cy="my-ninety-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="my-ninety-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.teamTodos }}:</mat-label>
        <input
          #teamTodos
          matInput
          formControlName="teamTodos"
          required
          [attr.aria-label]="my90.teamTodos"
          data-cy="my-ninety-language_team-to-dos-input-field" />
        <ninety-reset-btn
          *ngIf="teamTodos.value !== resetLanguage?.teamTodos"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('teamTodos')"
          [disabled]="readonly"
          data-cy="my-ninety-language_team-to-dos-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.personalTodos }}:</mat-label>
        <input
          #personalTodos
          matInput
          formControlName="personalTodos"
          required
          [attr.aria-label]="my90.personalTodos"
          data-cy="my-ninety-language_private-to-dos-input-field" />
        <ninety-reset-btn
          *ngIf="personalTodos.value !== resetLanguage?.personalTodos"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('personalTodos')"
          [disabled]="readonly"
          data-cy="my-ninety-language_private-to-dos-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.rocksAndMilestones }}:</mat-label>
        <input
          #rocksAndMilestones
          matInput
          formControlName="rocksAndMilestones"
          required
          [attr.aria-label]="my90.rocksAndMilestones"
          data-cy="my-ninety-language_rocks-and-milestones-input-field" />
        <ninety-reset-btn
          *ngIf="rocksAndMilestones.value !== resetLanguage?.rocksAndMilestones"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('rocksAndMilestones')"
          [disabled]="readonly"
          data-cy="my-ninety-language_rocks-and-milestones-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.scorecard }}:</mat-label>
        <input
          #scorecard
          matInput
          formControlName="scorecard"
          required
          [attr.aria-label]="my90.scorecard"
          data-cy="my-ninety-language_scorecard-input-field" />
        <ninety-reset-btn
          *ngIf="scorecard.value !== resetLanguage?.scorecard"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('scorecard')"
          [disabled]="readonly"
          data-cy="my-ninety-language_scorecard-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.todoChart }}:</mat-label>
        <input
          #todoChart
          matInput
          formControlName="todoChart"
          required
          [attr.aria-label]="my90.todoChart"
          data-cy="my-ninety-language_todos-last-90days-input-field" />
        <ninety-reset-btn
          *ngIf="todoChart.value !== resetLanguage?.todoChart"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('todoChart')"
          [disabled]="readonly"
          data-cy="my-ninety-language_todos-last-90days-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.rockChart }}:</mat-label>
        <input
          #rockChart
          matInput
          formControlName="rockChart"
          required
          [attr.aria-label]="my90.rockChart"
          data-cy="my-ninety-language_rocks-statuses-input-field" />
        <ninety-reset-btn
          *ngIf="rockChart.value !== resetLanguage?.rockChart"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('rockChart')"
          [disabled]="readonly"
          data-cy="my-ninety-language_rocks-statuses-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ my90.measurableChart }}:</mat-label>
        <input
          #measurableChart
          matInput
          formControlName="measurableChart"
          required
          [attr.aria-label]="my90.measurableChart"
          data-cy="my-ninety-language_kpis-input-field" />
        <ninety-reset-btn
          *ngIf="measurableChart.value !== resetLanguage?.measurableChart"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('measurableChart')"
          [disabled]="readonly"
          data-cy="my-ninety-language_kpis-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
