/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs';

import { CompanyActions } from './company-state.actions';

@Injectable()
export class CompanyStateEffects {
  constructor(private actions$: Actions) {}

  updateCompany$ = createEffect(() => this.actions$.pipe(ofType(CompanyActions.handleCompanyUpdate)));

  updateCompanyTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.handleCompanyUpdate),
      filter(({ changes }) => !!changes?.settings?.colorBranding),
      map(({ changes }) => CompanyActions.updateTheme({ colorBranding: changes.settings.colorBranding }))
    )
  );
}
