import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { User } from '../../../_shared/models/_shared/user';
import { mongoIdAdapter } from '../../../_shared/ngrx/mongo-id-adapter';

export interface UsersStateModel extends EntityState<User> {
  currentUserId: string | null;
}

const selectId = mongoIdAdapter<User & { _id: string }>();
export const usersStateAdapter = createEntityAdapter<User>({
  selectId,
});

export const usersInitialState: UsersStateModel = {
  ...usersStateAdapter.getInitialState(),
  currentUserId: null,
};

export class UserModelState extends User {
  isCurrentUser = false;
  isAdmin = false;
  isOwner = false;
  isManager = false;
  isManagee = false;
  isObserver = false;
  isLite = false;
  isAdminOrOwner = false;
  isManagerOrAbove = false;
  isManageeOrAbove = false;
  isImplementer = false;
  role = '';
}

export interface UserAvatarInfo {
  fullUserName?: string;
  pictureURL?: string;
  userInitials?: string;
}
