import { Injectable } from '@angular/core';

export const COMPANY_LOGO_RATIO_MAX_WIDTH = 16;
export const COMPANY_LOGO_RATIO_MAX_HEIGHT = 9;

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  COMPANY_LOGO_RATIO_MAX_WIDTH = 16;
  COMPANY_LOGO_RATIO_MAX_HEIGHT = 9;
  /**
   * The logo is "square" - meaning it should be rendered as a square even if it requires a transformation - if it's
   * ratio is less than or equal to 16:9
   *
   * https://traxion.atlassian.net/browse/DEV-1541
   *
   * @param actualWidth The width of the image
   * @param actualHeight The height of the image
   * @param maxWidth The maximum width used to calculate the max ratio
   * @param maxHeight The maximum height used to calculate the max ratio
   */
  logoIsSquare(
    actualWidth: number,
    actualHeight: number,
    maxWidth = COMPANY_LOGO_RATIO_MAX_WIDTH,
    maxHeight = COMPANY_LOGO_RATIO_MAX_HEIGHT
  ): boolean {
    const maxRatio = maxWidth / maxHeight;
    const actualRatio = actualWidth / actualHeight;

    return actualRatio < maxRatio;
  }
}
