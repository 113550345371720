import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { allCountries, countryTuples } from 'country-region-data';

import { TerraDividerModule, TerraIconModule } from '@ninety/terra';

import { MaterialMdcModule } from '../../../_angular-material/material-mdc.module';
import { BillingOverviewDetails } from '../../../_shared/models/billingv2/billing-overview-details.model';
import { BillingRedirectReasons } from '../../../_shared/models/billingv2/billing-redirect-reasons.enum';
import { CustomerBillingInfo } from '../../../_shared/models/billingv2/customer-billing-info-request.model';
import { selectUpcomingInvoice } from '../../../_state/app-global/billing/billing-state.selectors';
import { SubscriptionActions } from '../../../_state/app-global/company/subscription/subscription-state.actions';
import { ButtonComponent } from '../../buttons/button/button.component';
import { SelectOption } from '../../inputs/selects/base/select-option.interface';

@Component({
  selector: 'ninety-address-validator',
  standalone: true,
  templateUrl: './address-validator.component.html',
  styleUrls: ['./address-validator.component.scss'],
  imports: [
    MatDialogModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialMdcModule,
    ButtonComponent,
    TerraIconModule,
    TerraDividerModule,
    TerraIconModule,
  ],
})
export class AddressValidatorComponent {
  billingOverviewDetails: BillingOverviewDetails;
  countries: SelectOption[] = [];
  countryStatesProvinces: SelectOption[] = [];
  customerBillingInfo: CustomerBillingInfo = new CustomerBillingInfo();
  defaultCountry = { text: 'United States', value: 'US' } as unknown as SelectOption;
  invoice$ = this.store.select(selectUpcomingInvoice);
  selectedCountry = this.defaultCountry.value;

  form = new FormGroup({
    country: new FormControl(this.customerBillingInfo.country, [Validators.required]),
    line1: new FormControl(this.customerBillingInfo.line1 || ''),
    line2: new FormControl(this.customerBillingInfo.line2 || ''),
    city: new FormControl(this.customerBillingInfo.city, [Validators.required, this.noWhitespaceValidator]),
    state: new FormControl(this.customerBillingInfo.state, [Validators.required]),
    postalCode: new FormControl(this.customerBillingInfo.postalCode, [Validators.required, this.noWhitespaceValidator]),
    email: new FormControl(this.customerBillingInfo.email, [Validators.required, Validators.email]),
  });

  constructor(
    public dialogRef: MatDialogRef<AddressValidatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BillingRedirectReasons,
    public store: Store
  ) {
    this.initializeCountries();
    this.form.get('country').setValue(this.defaultCountry.value);
    this.setCountryStatesProvinces(this.defaultCountry.value);
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  onCountryChange($event: MatSelectChange) {
    this.selectedCountry = $event.value;
    this.setCountryStatesProvinces($event.value);
  }

  initializeCountries() {
    this.countries = countryTuples.reduce((acc: SelectOption[], [country, slug]) => {
      acc.push({ text: country.toString(), value: slug.toString() });
      return acc;
    }, []);
  }

  setCountryStatesProvinces(country: string) {
    this.countryStatesProvinces = allCountries
      .find(element => element[1] === country)[2]
      .reduce((acc: SelectOption[], [state]) => {
        acc.push({ text: state.toString(), value: state.toString() });
        return acc;
      }, [])
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  onAddressComplete() {
    switch (this.data['billingRedirectReasons']) {
      case BillingRedirectReasons.SubscriptionReactivate:
        this.store.dispatch(
          SubscriptionActions.requestResumeSubscriptionUrl({
            customerBillingInfo: this.form.value as CustomerBillingInfo,
          })
        );
        break;
      case BillingRedirectReasons.UpdatePaymentMethod:
        this.store.dispatch(
          SubscriptionActions.requestPaymentMethodUpdateUrl({
            customerBillingInfo: this.form.value as CustomerBillingInfo,
          })
        );
        break;
    }
  }
}
