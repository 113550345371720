/**
 * Examples of future levels to add may be warning.browserCompatibility, info.http, error.http, error.fatal, etc.
 *
 * Definitions taken from:
 * [BetterStack - Log Levels Explained and How to Use Them](https://betterstack.com/community/guides/logging/log-levels-explained)
 */
export enum LogLevel {
  /**
   * Intended for logging detailed information about the system that is useful for debugging.
   * The DEBUG level is used for logging messages that aid developers in identifying issues during a debugging session.
   * The content of the messages logged at the DEBUG level will vary depending on your application, but they typically
   * contain detailed information that assists its developers in troubleshooting problems efficiently. This can
   * include variables' state within the surrounding scope or relevant error codes.
   *
   * Unlike the other levels discussed so far, DEBUG logs should typically not be enabled in production environments
   * chiefly due to volume of the logs produced, as this results in increased disk I/O and storage requirements
   * especially under heavy load. If you must use DEBUG in production for a debugging session, ensure to turn
   * it off immediately afterwards to minimize the performance impact.
   *
   * Examples:
   * - Database queries, which can aid in identifying performance bottlenecks or issues related to data retrieval or manipulation.
   * - The details of external API calls and their responses.
   * - Configuration values to aid in troubleshooting issues related to misconfigured or mismatched settings.
   * - Timing information such as the duration of specific operations or method executions.
   *
   * > Note: Please be aware that DEBUG logs often contain sensitive information such as usernames, passwords,
   * > application secrets, and more, so be careful of how such logs are managed so that access is restricted
   * > to authorized personnel only.
   */
  Debug = 'debug',

  /**
   * The INFO level is used to capture events in the system that are significant to the application's business purpose.
   * Such events are logged to show that the system is operating normally. Production systems typically default to
   * logging at this level so that a summary of the application's normal behavior is visible to anyone reviewing
   * the logs.
   *
   * Examples:
   * - Changes in the state of an operation, such as transitioning from "PENDING" to "IN PROGRESS".
   * - The successful completion of scheduled jobs or tasks.
   * - Starting or stopping a service or application component.
   * - Record of important milestones or significant events within the application.
   * - Progress updates during long-running processes or tasks
   * - Information about system health checks or status reports.
   *
   * > Note: While its good to log the events that are significant to the business purpose, ensure that you do not
   * > log excessive details that may clutter the logs or compromise security.
   */
  Info = 'info',

  /**
   * Events logged at the WARN level typically indicate that something unexpected has occurred, but the application can
   * still recover and continue to function normally. It can also be used to signify conditions that should be
   * addressed promptly before they escalate into problems for the application.
   *
   * Examples:
   * - Resource consumption nearing predefined thresholds (such as memory, CPU, or bandwidth)
   * - Errors that the application can recover from without any significant impact
   * - Configuration settings that are outdated or not in line with recommended practices
   * - Excessive number of failed login attempts indicating potential security threats
   * - External API response times exceeding acceptable thresholds
   */
  Warn = 'warn',

  /**
   * The ERROR log level indicates error conditions within an application that hinder the execution of a specific
   * operation. While the application can continue functioning at a reduced level of functionality or performance,
   * ERROR logs signify issues that should be investigated promptly.
   *
   * Unlike FATAL messages, ERROR logs do not carry the same sense of urgency, as the application can still continue
   * to function.
   *
   * However, not all occurrences of errors in your application should be logged at the ERROR level. For instance, if
   * an exception is an expected behavior and does not result in a degradation of application functionality or
   * performance, it can be logged at a lower level such as DEBUG. Similarly, errors with a potential for
   * recovery, such as network connectivity issues with automated retry mechanisms, can be logged at the WARN level.
   * Such conditions may be elevated to the ERROR level if recovery is unsuccessful after a predetermined time.
   *
   * Examples:
   * - External API or service failures impacting the application's functionality (after automated recovery attempts have failed)
   * - Network communication errors, such as connection timeouts or DNS resolution failures
   * - Failure to create or update a resource in the system
   * - An unexpected error, such as the failure to decode a JSON object
   */
  Error = 'error',

  /** When to set Silent, no logs will be emitted */
  Silent = 'silent',
}
