import { InvoiceV2 } from '../../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { SubscriptionProviderConfiguration } from '../../../_shared/models/billingv2/subscription-provider-configuration.model';

export const BillingStateKey = 'billing';
export interface BillingState {
  subscriptionConfigurations: SubscriptionProviderConfiguration[];
  companyBillingCounts: BillingCounts;
  upcomingInvoice?: InvoiceV2;
}

export const InitialBillingState: BillingState = {
  subscriptionConfigurations: [],
  companyBillingCounts: {} as BillingCounts,
  upcomingInvoice: {} as InvoiceV2,
};
