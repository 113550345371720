import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[ninetyHasError]',
  standalone: true,
})
export class HasErrorDirective {
  @Input() ninetyHasError = false;

  @HostBinding('class.ninety-input--error')
  protected get isOutline(): boolean {
    return this.ninetyHasError;
  }
}
