import { Directive, OnInit } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

@Directive({
  selector: '[ninetyInputAutofocus]',
})
export class InputAutofocusDirective implements OnInit {
  constructor(private matInput: MatInput) {}

  ngOnInit() {
    setTimeout(() => this.matInput.focus());
  }
}
