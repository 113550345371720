import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { TodosContextMenuData } from '@ninety/my-ninety/components/todos-context-menu';
import { TodoSharedActionEvents } from '@ninety/todos/_state/_shared/todo-state.shared.actions';
import { TeamTodoState } from '@ninety/todos/_state/team/team-todo.model';
import { CreateTodoResponse } from '@ninety/todos/services/models/create-todo-response';
import { PrintOptions } from '@ninety/ui/legacy/core/services/print.service';
import { BulkArchiveResult } from '@ninety/ui/legacy/shared/models/_shared/bulk-archive-result';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoGETManyApiResponse } from '@ninety/ui/legacy/shared/models/todos/todo-response';
import { TodoSortField } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';

export const TeamTodoActions = createActionGroup({
  source: 'Team Todos',
  events: {
    ...TodoSharedActionEvents,

    /**
     * Resets the whole todos.team state to {@link initialTeamTodoState} merged with an optional partial override.
     */
    'Reset State': props<{ overrides?: Partial<TeamTodoState> }>(),

    'Clear Sort': emptyProps(),

    'Get Team': emptyProps(),
    'Get Team Success': props<{ response: TodoGETManyApiResponse }>(),
    'Get Team Failure': props<{ error: unknown }>(),

    'Team Selected': props<{ teamId: string }>(),

    'Show Archived': props<{ showArchived: boolean }>(),
    'Toggle Archived': props<{ todo?: Todo }>(),
    'Toggle Archived Success': props<{ todo: Todo; id: string }>(),
    'Toggle Archived Failure': emptyProps(),
    'Request Archive All Completed User Team': emptyProps(),
    'Archive All Completed User Team': emptyProps(),
    'Archive All Completed User Team Success': props<BulkArchiveResult>(),
    'Archive All Completed User Team Failure': props<{ error: unknown }>(),

    'Archive All Completed': emptyProps(),
    'Archive All Completed Success': emptyProps(),
    'Archive All Completed Failure': props<{ error: unknown }>(),

    // Only in use in My90 to fire its add item CTA
    'Open Create Dialog': emptyProps(),

    'Init Meeting Conclude': props<{ teamId: string; pageSize: number }>(),

    'Move To Bottom': props<TodosContextMenuData>(),
    'Move To Top': props<TodosContextMenuData>(),

    'Hydrate Page Size From Local Store': props<{ pageSize: number }>(),
    'Save Page Size On Local Store': props<{ pageSize: number }>(),

    'Hydrate Page Sorting From Local Store': props<{ sortField: TodoSortField; sortDirection: SortDirection }>(),

    Print: props<{ printOptions: PrintOptions }>(),
    'Print Success': emptyProps(),
    'Print Failure': emptyProps(),

    //when the list shows multiple todos in the same series we need to update them as well
    //...when one of them is updated inline or in detail view
    'Update Displayed Todos In Series': props<{ todo: Todo }>(),

    /** When a todo is created from a milestone, update desc, due date and toDoId fields on the milestone */
    'Update Connected Milestone Success': emptyProps(),
    'Update Connected Milestone Failure': emptyProps(),

    'Add To Team': props<{ todo: Todo }>(),
    'Remove From Team': props<{ todo: Todo }>(),
    'Remove Accepted Todo From State': props<{ id: string }>(),

    'Download Excel': emptyProps(),
    'Download Excel Success': emptyProps(),
    'Download Excel Failure': emptyProps(),

    'Toggle User Pending Agreements': emptyProps(),
  },
});

export const TeamTodoInlineActions = createActionGroup({
  source: 'Team Todos Inline',
  events: {
    'Add One': props<{ todo: Item }>(),
    'Cancel Add One': emptyProps(),

    'Create One': props<{ todo: Item }>(),
    'Create One Failure': emptyProps(),
    'Create One Success': props<{ response: CreateTodoResponse }>(),
  },
});

export const TeamTodoPubnubActions = createActionGroup({
  source: 'Team Todos Pubnub',
  events: {
    Unarchive: props<{ todo: Todo }>(),

    'Attachment Event Received': props<{ id: string }>(),
    'Attachment Event Success': props<{ todo: Todo }>(),
    'Attachment Event Failure': emptyProps(),

    /**
     * Fires when the local user adds todos and shouldBroadcast is set. These additions will have
     * already been handled by other reducers and should never have their own reducer methods.
     */
    'Broadcast Add Many Success': emptyProps(),
    'Broadcast Add Many Failure': props<{ error: unknown }>(),
    'Broadcast Add One Success': emptyProps(),
    'Broadcast Add One Failure': props<{ error: unknown }>(),

    /** Dispatched when a meeting attendee receives a message that todos were added  */
    'Add Many From Broadcast': props<{ todos: Todo[] }>(),
    'Add Many From Success': props<{ todos: Todo[] }>(),
    'Add Many From Failure': props<{ error: unknown }>(),

    'Update From Broadcast Success': props<{ todo: Todo }>(),
    'Update From Broadcast Failure': props<{ error: unknown }>(),

    'Update Ordinals From Broadcast Success': emptyProps(),
    'Update Ordinals From Broadcast Failure': props<{ error: unknown }>(),

    'Broadcast Delete Success': emptyProps(),
    'Broadcast Delete Failure': emptyProps(),
  },
});

export const TodoAgreementActions = createActionGroup({
  source: 'Todo Agreements',
  events: {
    Accept: props<{ todoId: string; update: Partial<Todo> }>(),
    'Accept Success': props<{ todo: Todo }>(),
    'Accept Failure': props<{ error: unknown }>(),
  },
});
