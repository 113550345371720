import { PhaseStep } from '@ninety/getting-started/guide/models/phase-step';

export class Phase {
  title: string;
  description: string;
  details_description: string;
  image_url: string;
  steps: PhaseStep[];
  ordinal: number;
  completed: boolean;

  // computed properties
  locked: boolean;
  percentageComplete: number;
  stepsCompleted: number;
}

export type PhasesResponse = {
  completion_percentage: number;
  phases: Phase[];
};
