import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PrintPdfDialogResult } from '@ninety/accountability-chart/components/print-pdf-dialog/print-pdf-dialog.component';
import { DtoParseError, GetChartResponseDto } from '@ninety/accountability-chart/models/get-chart-response.dto';
import { ResponsibilityContextMenuSource } from '@ninety/accountability-chart/models/responsibility-chart.model';
import { ChangeParentParams } from '@ninety/ui/legacy/components/tree/logic/move-node.logic';

export const ResponsibilitiesChartActions = createActionGroup({
  source: 'Responsibilities - Chart',
  events: {
    /** Lifecycle Events*/
    Init: emptyProps(),
    Destroy: emptyProps(),

    /** Fetch a specific Chart */
    Fetch: props<{ chartId?: string }>(),
    'Fetch Success': props<{ response: GetChartResponseDto; lastTopSeatId?: string }>(),
    'Fetch Failure': props<{ error?: unknown; message: string }>(),
    'Fetch Failure To Parse': props<{ errors: DtoParseError[] }>(),

    // Override for v1 compatability with v2 API endpoints. DO NOT DISPATCH IN OTHER CASES
    // TODO Remove under DEV-7514
    'Set v1 ChartId Override': props<{ chartId: string }>(),

    'Update Visible Node Length': props<{ length: number }>(),

    'Open Print Dialog': props<{ chartId: string; source: ResponsibilityContextMenuSource }>(),
    Print: props<{ opts: PrintPdfDialogResult }>(),
    'Cancel Print Dialog': emptyProps(),
    'Print Success': emptyProps(),
    'Print Failure': props<{ error?: unknown; message: string }>(),

    'Notify After Node Moved Outside of Subtree': props<{ params: ChangeParentParams }>(),

    'Select Seat': props<{ seatId: string }>(),
    'Clear Selected Seat': emptyProps(),
  },
});
