export const DetailViewStateKey = 'page-detail-view';

/**
 * NGRX state model for the detail side panel.
 *
 * TODO migrate all state subjects from {@link DetailService} here, such as {@link DetailService#data$}.
 * TODO probably a good idea to have "selected" state here (ie: selected todo, selected rock, etc).
 */
export interface DetailViewState {
  /**
   * When true, the {@link DetailService} will stream certain actions to the store.
   *
   * TODO deprecate once all detail types are migrated to NGRX based flows.
   */
  isStreamingEnabled: boolean;

  /** Solves DEV-7350:
   * For detail views that want to control their own scrolling with sticky headers/footers.
   *
   * When true, the MainLayoutComponent will take away its default scrollbar.
   *
   * When false, the MainLayoutComponent will have its own auto vertical scrollbar.
   */
  disableScrollbar: boolean;
}

export const initialDetailViewState: DetailViewState = {
  isStreamingEnabled: false,
  disableScrollbar: false,
};
