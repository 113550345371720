import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { CompanyUsersStateActions } from './company-users-state.actions';
import { CompanyUserListModel } from './company-users-state.model';

@Injectable()
export class CompanyUsersStateEffects {
  $appInit = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyUsersStateActions.appInit),
      map(({ companyUsers }) => {
        const cus: CompanyUserListModel[] = companyUsers.map(c => ({
          userId: c._id,
          companyId: c.company._id,
          accountStatus: c.company.accountStatus,
          companyName: c.company.name,
          logoUrl: c.company?.logo?.url,
          bos: c.company.bos,
        }));
        return CompanyUsersStateActions.setAllCompanyUsers({ companyUsers: cus });
      })
    )
  );

  constructor(private action$: Actions) {}
}
