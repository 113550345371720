export type IssueSortField = `${IssueSortFieldEnum}`;

export enum IssueSortFieldEnum {
  none = 'None',
  title = 'title',
  user = 'user',
  rating = 'rating',
  archivedDate = 'archivedDate',
  numOfLikes = 'numOfLikes',
  createdDate = 'createdDate',
  completedDate = 'completedDate',
}
