import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';

import { InvoiceV2 } from '../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../_shared/models/billingv2/billing-counts-response';
import { BillingPortalActionResponse } from '../../_shared/models/billingv2/billing-portal-action-response';
import { CustomerBillingInfo } from '../../_shared/models/billingv2/customer-billing-info-request.model';
import { UpdateSubscriptionQuantityRequestModel } from '../../_shared/models/billingv2/modify-seats-request';
import { SubscriptionProviderConfiguration } from '../../_shared/models/billingv2/subscription-provider-configuration.model';
import { SubscriptionProviders } from '../../_shared/models/billingv2/subscription-providers.enum';
import { Subscription } from '../../_shared/models/billingv2/subscription.model';

import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class BillingV2Service {
  private billingUrl = 'api/v4/BillingV2';

  constructor(private http: HttpClient, private errorService: ErrorService) {}

  public getSubscriptionCancelUrl(): Observable<BillingPortalActionResponse> {
    return this.http
      .get<BillingPortalActionResponse>(`${this.billingUrl}/SubscriptionCancelUrl`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getSubscriptionReactivateUrl(): Observable<BillingPortalActionResponse> {
    return this.http
      .get<BillingPortalActionResponse>(`${this.billingUrl}/SubscriptionReactivateUrl`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getResumeSubscriptionUrl(customerBillingInfo: CustomerBillingInfo): Observable<BillingPortalActionResponse> {
    return this.http
      .post<BillingPortalActionResponse>(`${this.billingUrl}/ResumeSubscriptionUrl`, {
        customerBillingInfo,
      })
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getUpdatedSubscription(): Observable<Subscription> {
    return this.http
      .get<Subscription>(`${this.billingUrl}/UpdatedSubscription`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  getBillingPortalUrl(): Observable<BillingPortalActionResponse> {
    return this.http
      .get<BillingPortalActionResponse>(`${this.billingUrl}/BillingPortalUrl`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getSubscriptionUpdateUrl(): Observable<BillingPortalActionResponse> {
    return this.http
      .get<BillingPortalActionResponse>(`${this.billingUrl}/SubscriptionUpdateUrl`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getUpdatePaymentMethodUrl(customerBillingInfo: CustomerBillingInfo): Observable<BillingPortalActionResponse> {
    return this.http
      .post<BillingPortalActionResponse>(`${this.billingUrl}/UpdatePaymentMethodUrl`, {
        customerBillingInfo,
      })
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getSubscriptionQuantityUpdateUrl(
    updateSubscriptionQuantityRequest: UpdateSubscriptionQuantityRequestModel
  ): Observable<BillingPortalActionResponse> {
    return this.http
      .post<BillingPortalActionResponse>(
        `${this.billingUrl}/SubscriptionQuantityUpdateUrl`,
        updateSubscriptionQuantityRequest
      )
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public autoIncrementSubscriptionQuantity(quantity: number): Observable<Subscription> {
    return this.http
      .post<Subscription>(`${this.billingUrl}/AutoUpdateSubscriptionQuantity`, { quantity })
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getActiveSubscriptionConfigurations(
    provider: SubscriptionProviders = SubscriptionProviders.Stripe
  ): Observable<SubscriptionProviderConfiguration[]> {
    return this.http
      .get<SubscriptionProviderConfiguration[]>(`${this.billingUrl}/ActiveSubscriptionConfigurations/${provider}`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getBillingCounts(): Observable<BillingCounts> {
    return this.http
      .get<BillingCounts>(`${this.billingUrl}/BillingCounts`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  public getUpcomingInvoice(): Observable<InvoiceV2> {
    return this.http
      .get<InvoiceV2>(`${this.billingUrl}/UpcomingInvoice`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }
}
