import { createSelector } from '@ngrx/store';

import { CompanyUsersStateKey, selectEntitiesState } from '..';
import { AccountStatus } from '../../../_shared/models/company/company';
import { selectCurrentUserId } from '../users/users-state.selectors';

import { companyUsersStateAdapter } from './company-users-state.model';

const { selectAll, selectEntities } = companyUsersStateAdapter.getSelectors();

const selectUsersState = createSelector(selectEntitiesState, appEntities => appEntities[CompanyUsersStateKey]);

export const selectAllCompanyUsers = createSelector(selectUsersState, selectAll);

export const selectCompanyUserEntities = createSelector(selectUsersState, selectEntities);

export const selectCurrentCompanyUser = createSelector(
  selectCompanyUserEntities,
  selectCurrentUserId,
  (companyUserEntities, currentCompanyUserId) => {
    if (!currentCompanyUserId || !companyUserEntities || !companyUserEntities[currentCompanyUserId]) {
      return null;
    }
    return companyUserEntities[currentCompanyUserId];
  }
);

export const selectActiveCompanyUsers = createSelector(selectAllCompanyUsers, companyUsers =>
  companyUsers.filter(u => u.accountStatus !== AccountStatus.INACTIVE)
);

export const selectInactiveCompanyUsers = createSelector(selectAllCompanyUsers, companyUsers =>
  companyUsers.filter(u => u.accountStatus === AccountStatus.INACTIVE)
);

export const selectCompanyUserIds = createSelector(selectAllCompanyUsers, companyUsers =>
  companyUsers.map(u => u.companyId)
);

export const selectCompanyUserByCompanyId = (companyId: string) =>
  createSelector(selectAllCompanyUsers, companyUsers => companyUsers.find(u => u.companyId === companyId));

export const selectActiveInactiveCompanyUsers = createSelector(
  selectActiveCompanyUsers,
  selectInactiveCompanyUsers,
  (active, inactive) => [...active, ...inactive]
);

export const selectCurrentCompanyUserWithActiveCompanyUsers = createSelector(
  selectCurrentCompanyUser,
  selectActiveCompanyUsers,
  (currentCompanyUser, users) => ({ currentCompanyUser: currentCompanyUser, activeCompanyUsers: users })
);
