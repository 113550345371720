import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { BidiModule, Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty, coerceCssPixelValue } from '@angular/cdk/coercion';
import { DOWN_ARROW, END, ENTER, ESCAPE, HOME, SPACE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, filter, merge, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../models';
import { TerraErrorStateMatcher } from '../forms/terra-error-state-matcher';
import { TerraDividerComponent } from '../terra-divider';
import { TerraIconModule } from '../terra-icon';
import { TerraOptionBase, TERRA_OPTION_BASE } from '../terra-option/terra-option.interface';

import { terraSelectAnimations } from './terra-select.animations';

export type TerraSelectLayout = 'list' | 'icons';

let selectUniqueId = 1;

/**
 * TODO
 * enforce prefix components (avatar)
 * a11y review
 */
@Component({
  selector: 'terra-select',
  standalone: true,
  exportAs: 'terraSelect',
  imports: [CommonModule, TerraIconModule, BidiModule],
  templateUrl: './terra-select.component.html',
  styleUrls: ['./terra-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[class.terra-select__icons-layout]': 'layout === "icons"',
  },
  animations: [terraSelectAnimations.transformPanel],
})
export class TerraSelectComponent implements ControlValueAccessor, AfterContentInit, OnDestroy, OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onChange!: (_: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onTouched!: (_: any) => void;

  @ContentChildren(TERRA_OPTION_BASE) private _options!: QueryList<TerraOptionBase>;
  @ContentChildren(TerraDividerComponent) private _dividers!: QueryList<TerraDividerComponent>;

  @ViewChild('selectPanel', { read: TemplateRef, static: true }) private _selectPanel!: TemplateRef<HTMLElement>;

  @ViewChild('trigger', { static: true }) private _trigger!: ElementRef<HTMLElement>;

  protected _selectId = `terra-select-${selectUniqueId++}`;
  protected _tabIndex = 0;

  private _destroyed$ = new Subject<void>();

  private _overlayRef?: OverlayRef;

  private _keyManager!: ActiveDescendantKeyManager<TerraOptionBase>;

  private _isFocused = false;

  private _componentHasInitialized = false;

  protected get _activeDescendant(): string | undefined {
    return this.isSelectOpen ? this._keyManager?.activeItem?.optionId : undefined;
  }

  // Current state of the panel animation
  protected _panelAnimationState: 'void' | 'enter' = 'void';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._valueBS.getValue();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _valueBS = new BehaviorSubject<any>(undefined);
  private readonly _value$ = this._valueBS.asObservable();

  /**
   * Event emitted when the select panel has been toggled
   */
  @Output() readonly openedChange = new EventEmitter<boolean>(false);
  get isSelectOpen(): boolean {
    return this._isSelectOpen;
  }
  private _isSelectOpen = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _prefixTemplate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _suffixTemplate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _iconTemplate?: any;
  protected _label?: string;

  /**
   * Event emitted when the selected value changes
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @angular-eslint/no-output-native
  @Output() readonly selectionChange = new EventEmitter<any>();

  /**
   * Placeholder for the trigger if no value is selected
   * @default
   */
  @Input({ required: false }) get placeholder(): string | undefined {
    return this._placeholder;
  }
  set placeholder(value: string | undefined) {
    this._placeholder = value;
    this._changeDetectorRef.markForCheck();
  }
  private _placeholder?: string;

  /**
   * Allow multiple selections, can't be changed after initialization
   * @default false
   */
  @Input({ required: false }) get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: TerraInputBoolean) {
    // We don't support this input chaning after initialization
    if (this._componentHasInitialized === false) {
      // If layout is icons then we just silently don't support this property
      if (this._layout === 'list') {
        this._multiple = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
      }
    } else {
      // Switching from one mode to the other would add a lot of complexity
      // consumers can have many selects with different modes if needed
      throw new Error('Multiple mode cannot be changed after it has been set');
    }
  }
  private _multiple = false;

  /**
   * Disabled state of the select
   * @default false
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Layout of the select, can't be changed after initialization
   * @default 'list'
   */
  @Input() get layout(): TerraSelectLayout {
    return this._layout;
  }
  set layout(value: TerraSelectLayout) {
    // We don't support this input chaning after initialization
    if (this._componentHasInitialized === false) {
      this._layout = value;
      this._changeDetectorRef.markForCheck();
    } else {
      throw new Error('Layout cannot be changed after it has been set');
    }
  }
  private _layout: TerraSelectLayout = 'list';

  /**
   * Maximum width of the select panel of options.  If number is provided, pixel units are assumed
   */
  @Input() get maxWidth(): number | string | undefined {
    return this._maxWidth;
  }
  set maxWidth(value: number | string | undefined) {
    // Compensates for maxWidth="12" scenario where 12 is passed as a string
    // This checks if the length of the int and string are the same, if so we parse to an int so
    // that the coerceCSSPixelValue will correctly coerce it to 12px
    if (typeof value === 'string' && parseInt(value).toString().length === value.length) {
      value = parseInt(value);
    }
    this._maxWidth = coerceCssPixelValue(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxWidth?: number | string;

  /**
   * Maximum height of the select panel of options.  If number is provided, pixel units are assumed
   */
  @Input() get maxHeight(): number | string | undefined {
    return this._maxHeight;
  }
  set maxHeight(value: number | string | undefined) {
    // Compensates for maxHeight="12" scenario where 12 is passed as a string
    // This checks if the length of the int and string are the same, if so we parse to an int so
    // that the coerceCSSPixelValue will correctly coerce it to 12px
    if (typeof value === 'string' && parseInt(value).toString().length === value.length) {
      value = parseInt(value);
    }
    this._maxHeight = coerceCssPixelValue(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxHeight?: number | string;

  /**
   * Function to compare the option values with the selected values. The first argument is a value from an option.
   * The second is a value from the selection. A boolean should be returned.
   * @default (o1: any, o2: any) => o1 === o2
   */
  @Input() get compareWith() {
    return this._compareWith;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    this._compareWith = fn;
    this._changeDetectorRef.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _compareWith: (optionValue: any, selectionValue: any) => boolean = (optionVal, selectionVal) =>
    optionVal === selectionVal;

  /**
   * Optional input to override aria-label
   * @default null
   */
  @Input('aria-label') ariaLabel: string | null = null;
  /**
   * Optional input to override aria-labelledby
   * @default null
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _overlay: Overlay,
    private readonly _elementRef: ElementRef,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _direction: Directionality,
    protected readonly _terraErrorStateMatcher: TerraErrorStateMatcher,
    @Attribute('tabindex') readonly tabIndex: string,
    @Self() protected readonly ngControl: NgControl
  ) {
    this._tabIndex = parseInt(tabIndex) || 0;
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this._componentHasInitialized = true;
  }

  ngAfterContentInit(): void {
    const optionSelected$ = this._options.changes.pipe(
      startWith(this._options),
      switchMap((options: QueryList<TerraOptionBase>) => {
        return merge(...options.map(option => option.select));
      }),
      // Update the value stream with corrected values
      tap(event => {
        if (this._multiple) {
          if (event === '') {
            // An empty string value of the option is selected, so we emit an empty array
            this._valueBS.next([]);
          } else if (this._multipleValueContainsOptionValue(event)) {
            // The value of the option was already selected, so we remove it (toggle)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this._valueBS.next(this.value.filter((v: any) => v !== event));
          } else {
            // The value of the option hasn't been selected so we add it to the array
            this._valueBS.next([...this.value, event]);
          }
        } else {
          this._valueBS.next(event);
        }
      }),
      // When an option has been selected we mark the form as touched and update the form value
      // These don't need to happen when the form value changes from the parent
      tap(() => {
        this._touch();
        this._onChange(this.value);
        this.selectionChange.emit(this.value);
      })
    );

    // When the content of any of the options changes (like actual template changes)
    // Ignore if the value isn't currently selected
    const optionContentChanged$ = this._options.changes.pipe(
      startWith(this._options),
      switchMap((options: QueryList<TerraOptionBase>) => {
        return merge(...options.map(option => option.content));
      }),
      filter(value => {
        // Is the option that changed the currently selected option
        return this._multiple ? this._multipleValueContainsOptionValue(value) : this._compareWith(value, this.value);
      }),
      tap(_option => {
        if (this._multiple) {
          this._label = this._options
            .filter(option => option.selected)
            .map(option => option.getLabel())
            .join(', ');
        } else {
          const selectedOption = this._options.find(option => option.selected);
          if (selectedOption) {
            this._updateTemplates(selectedOption);
          }
        }
      }),
      tap(() => {
        this._changeDetectorRef.markForCheck();
      })
    );

    // When the value stream changes
    const valueChanged$ = this._value$.pipe(
      // Update the ui
      // Updates the state of each option
      // Updates the trigger portion of the select
      tap(event => {
        if (this._multiple) {
          const labels: string[] = [];
          // For multiple we need to update the state of each option and get the selected labels for the trigger
          this._options.forEach(option => {
            if (this._multipleValueContainsOptionValue(option.value)) {
              option.selected = true;
              labels.push(option.getLabel());
            } else {
              option.selected = false;
            }
            this._label = labels.join(', ');
          });
        } else {
          // Clear templates
          this._prefixTemplate = this._suffixTemplate = this._label = this._iconTemplate = undefined;
          this._options.forEach(option => {
            if (event === '') {
              // If an option is "undefined" then this will short circuit and the placeholder will show
              // which allows an option to reset the select to initial state (unlike HTML select)
              option.selected = false;
            } else if (this._compareWith(option.value, event)) {
              option.selected = true;
              this._updateTemplates(option);
            } else {
              option.selected = false;
            }
          });
        }
      }),
      tap(() => {
        this._changeDetectorRef.markForCheck();
      })
    );

    // Stream of actions that should close the select
    const closeActions$ = this.openedChange.pipe(
      tap(selectState => {
        // Update the state of the selectOpen
        this._isSelectOpen = selectState;
      }),
      filter(() => this.isSelectOpen && !!this._overlayRef),
      switchMap(() => {
        // Clicks on the "invisible" backdrop that covers the rest of the page
        const backdrop$ = this._overlayRef?.backdropClick();
        // Detachments are when the overlay is removed from the DOM
        // Not entirely sure when this would happen, but here because Material uses this pattern
        const detachments$ = this._overlayRef?.detachments();
        const actions = [backdrop$, detachments$];

        // We only want to close the select when an option is selected when NOT in multiple mode
        if (!this._multiple) {
          actions.push(optionSelected$);
        }
        return merge(...actions);
      }),
      tap(() => {
        this.close();
      })
    );

    this._keyManager = new ActiveDescendantKeyManager(this._options)
      .withPageUpDown()
      .withHomeAndEnd()
      .withVerticalOrientation()
      .withTypeAhead()
      .withWrap();
    this._keyManager.setFirstItemActive();

    const dividersEnforcer$ = this._dividers.changes.pipe(
      startWith(this._dividers),
      tap((dividers: QueryList<TerraDividerComponent>) => {
        dividers.forEach(divider => {
          divider.height = 'short';
          divider.margins = 'narrow';
        });
      })
    );

    if (this._multiple) {
      this._initializeMultipleMode();
    }
    // Because of issues with ngModel we have to wait one extra turn
    // With ReactiveForms the valueBS is already set to the correct value before we subscribe
    // ngModel is not quite settled yet, but will be after an additional turn, it's annoying
    setTimeout(() => {
      const streams = [optionSelected$, valueChanged$, closeActions$, dividersEnforcer$];

      // The icon options don't support content changing so we only need this in list mode
      if (this._layout === 'list') {
        streams.push(optionContentChanged$);
      }
      merge(...streams)
        .pipe(takeUntil(this._destroyed$))
        .subscribe();
    }, 0);
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = undefined;
    }
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Focuses the select
   */
  focus(): void {
    this._trigger.nativeElement.focus();
  }

  /**
   * Blurs the select
   */
  blur(): void {
    this._trigger.nativeElement.blur();
  }

  /** Toggle the current state open/close */
  toggle(): void {
    this.isSelectOpen ? this.close() : this.open();
  }

  /** Open the select if able */
  open(): void {
    if (this._canOpen()) {
      this._openSelectPanel();
      if (this.value) {
        const selectedIndex = this._options.toArray().findIndex(option => option.value === this.value);
        this._keyManager.setActiveItem(selectedIndex > -1 ? selectedIndex : 0);

        // Timeout is needed because it wasn't scrolling properly to item at top of long list without it
        setTimeout(() => {
          this._keyManager.activeItem?.setActiveStyles();
        });
      } else {
        this._keyManager.setFirstItemActive();
      }
      this._enterAnimation();
      this.openedChange.emit(true);

      this._changeDetectorRef.markForCheck();
    }
  }

  /** Close the select if open */
  close(): void {
    if (this.isSelectOpen) {
      this.openedChange.emit(false);
      this._overlayRef?.detach();
      this._exitAnimation();

      this._changeDetectorRef.markForCheck();
    }
  }

  protected _focused(): void {
    this._isFocused = true;
  }

  protected _blurred(): void {
    this._isFocused = false;
  }

  private _initializeMultipleMode(): void {
    if (!Array.isArray(this.value)) {
      throw new Error('Value must be an array in multiple mode');
    }

    this._options.forEach(option => (option.checkbox = true));
  }

  private _canOpen(): boolean {
    return !this.disabled && !this.isSelectOpen;
  }

  private _updateTemplates(option: TerraOptionBase): void {
    if (option._iconTemplate) {
      this._iconTemplate = option._iconTemplate;
    } else {
      this._prefixTemplate = option._prefixTemplate;
      this._suffixTemplate = option._suffixTemplate;
      this._label = option.getLabel();
    }
  }

  // Implemented as part of ControlValueAccessor.

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // Value has changed from parent
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    if (value !== null) {
      this._valueBS.next(value);
    }
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetectorRef.markForCheck();
  }

  protected _touch(): void {
    this._onTouched(true);
  }

  // Overlay

  private _openSelectPanel(): void {
    const overlayRef = this._createOverlay(this._elementRef);

    const portal = new TemplatePortal(this._selectPanel, this._viewContainerRef);

    overlayRef.attach(portal);
  }

  private _createOverlay(connectedTo: ElementRef): OverlayRef {
    if (!this._overlayRef) {
      const overlayConfig = new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        direction: this._direction,
        minWidth: this._elementRef.nativeElement.getBoundingClientRect().width,
        maxWidth: this.layout === 'icons' ? this._maxWidth ?? 172 : this._maxWidth ?? 'calc(100% - 16px)',
        width: this.layout === 'icons' ? '100%' : 'unset',
        maxHeight: this._maxHeight,
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(connectedTo)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
              offsetY: 4,
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
              offsetY: -4,
            },
          ])
          .withFlexibleDimensions(true)
          .withGrowAfterOpen(true)
          .withLockedPosition(true)
          .withViewportMargin(8),
      });
      this._overlayRef = this._overlay.create(overlayConfig);
    }
    return this._overlayRef;
  }

  // Keybindings for a11y, some aren't correct or included with KeyManager
  // Following guidelines from ARIA:  https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
  @HostListener('document:keydown', ['$event'])
  private _manageKeyEvents(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    if (this.isSelectOpen) {
      switch (keyCode) {
        case ESCAPE:
          this.close();
          break;
        case SPACE:
        case ENTER:
        case TAB:
          event.preventDefault();
          this._keyManager.activeItem?._onClick(event);
          break;
        default:
          this._keyManager.onKeydown(event);
      }
    } else if (this._isFocused) {
      switch (keyCode) {
        case SPACE:
        case ENTER:
        case DOWN_ARROW:
          this.open();
          event.preventDefault();
          break;
        case END:
          this.open();
          event.preventDefault();
          this._keyManager.setLastItemActive();
          break;
        case HOME:
        case UP_ARROW:
          this.open();
          this._keyManager.setFirstItemActive();
          event.preventDefault();
          break;
      }
    }
  }

  // Animation
  // Starts enter animation
  private _enterAnimation(): void {
    this._panelAnimationState = 'enter';
  }

  // Starts exit animation
  private _exitAnimation(): void {
    this._panelAnimationState = 'void';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _multipleValueContainsOptionValue(optionValue: any): boolean {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.value.findIndex((val: any) => {
        return this._compareWith(optionValue, val);
      }) > -1
    );
  }
}
