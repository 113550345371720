import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DataImportComponent } from '@ninety/data-import/data-import.component';

const routes: Routes = [{ path: '', component: DataImportComponent, data: { title: 'Data Import' } }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DataImportRoutingModule {}
