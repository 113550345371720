/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { ungzip } from 'pako';
import { BehaviorSubject, take } from 'rxjs';

import { Person } from '../../_shared/models/_shared/person';
import { User } from '../../_shared/models/_shared/user';
import { Locale, UserSettings } from '../../_shared/models/_shared/user-settings';
import { AccessToken } from '../../_shared/models/auth/access-token';
import { Tokens } from '../../_shared/models/auth/refresh-token';
import { CompanyUser } from '../../_shared/models/company/company-user';
import { CompanyLanguage, CustomLanguage } from '../../_shared/models/language/custom-language';
import { CompanyUsersStateActions } from '../../_state/app-entities/company-users/company-users-state.actions';
import { FeatureFlagActions } from '../../_state/app-entities/feature-flag/feature-flag-state.actions';
import { teamsStateActions } from '../../_state/app-entities/teams/teams-state.actions';
import { userListStateActions } from '../../_state/app-entities/user-list/user-list-actions';
import { UsersStateActions } from '../../_state/app-entities/users/users-state.actions';
import { UserModelState } from '../../_state/app-entities/users/users-state.model';
import { selectCurrentUser } from '../../_state/app-entities/users/users-state.selectors';
import { CurrentPersonStateActions } from '../../_state/app-global/current-person/current-person.actions';
import { HelpfulPermissionsActions } from '../../_state/app-global/helpful-permissions/helpful-permissions.actions';
import { LanguageActions } from '../../_state/app-global/language/language.actions';
import { LoggingActions } from '../../_state/app-logging/app-logging.actions';
import { appActions } from '../../_state/app.actions';

import { CookieService } from './cookie.service';
import { TokenService } from './token.service';

type BlockingResponses = [
  CompanyUser[],
  User[],
  { teamsBuffer: { data: Buffer } }, //Team[]
  Person,
  // ToDo: Remove this from this - DEV-7250
  { users: User[]; invitedTotalUsers: number },
  { companyLanguage: CompanyLanguage; defaultBOSLanguage: CustomLanguage }
];

@Injectable()
export class AppLoadService {
  // ToDo: Move CompanyUsers to Store - DEV-7249
  companyUsers$ = new BehaviorSubject<CompanyUser[]>(null);
  // ToDo: Move SelectedCompanyUser to Store
  initialCompanyUser$ = new BehaviorSubject<CompanyUser>(null);
  // ToDo: Remove this and use allusers$ - DEV-7250
  directoryUsers$ = new BehaviorSubject<User[]>(null);

  /**
   * Please make sure that you do not inject anything in here that would affect the dependency hierarchy.
   * Nothing can contain something like the http client, etc. which would cause this to run after angular is bootstrapped, not before
   */
  constructor(private cookieService: CookieService, private tokenService: TokenService, private store: Store) {}

  redirectToLogin(): void {
    // Need to remove token since app-load service fires before angular bootstraps
    this.tokenService.removeAccessToken();

    const path = window.location.pathname;

    // Preserve the url the user tried to access for redirect
    const url = path ? `/login?redirectUrl=${path}` : '/login';

    console.log('AppLoadService.redirectToLogin');
    if (window.location.pathname !== '/login') window.location.replace(url);
  }

  // the response of this sets an xsrf token as a cookie
  private async initializeXsrf(): Promise<any> {
    try {
      const response = await (await fetch('/api/v4/Init')).json();

      if (response?.token && !this.cookieService.getXsrfCookie()) this.cookieService.setXsrfCookie(response.token);

      return response;
    } catch (e) {
      console.error(e);
    }
  }

  isLoginComponent(): boolean {
    return Array.isArray(window.document.location.pathname.match('(login|sign-up|accept-invite)'));
  }

  async initializeCompanyUser(tokens?: Tokens): Promise<void> {
    this.store.dispatch(FeatureFlagActions.initLaunchDarkly());

    if (tokens?.access) {
      this.tokenService.setAccessToken(tokens.access);
    }
    const accessToken: string = this.tokenService.getAccessToken(); // auth service saves ninety's access token on login

    const decoded: AccessToken = this.tokenService.decodeToken(accessToken);

    if (decoded && !decoded.companyId) {
      // non-mfa user without non-mfa company
      return this.redirectToLogin();
    }

    if (tokens?.xsrf) this.cookieService.setXsrfCookie(tokens.xsrf);
    const xsrfToken =
      this.cookieService.getXsrfCookie() ||
      ((await this.initializeXsrf()) && this.cookieService.getXsrfCookie()) ||
      decoded?.xsrf;
    if (!xsrfToken) return;

    const isLoginComponent = this.isLoginComponent();

    let { headers, jsonRequestOptions } = this.getHeadersAndOptions(accessToken, xsrfToken);

    // nothing can proceed unless we have the Ninety issued access token,
    // which only exists after a user has linked a company and then signed in or refreshed
    if (!accessToken && isLoginComponent) {
      return;
    } else if (!accessToken) {
      // if they're trying to deep link to something but aren't authenticated, redirect after logging in
      return this.redirectToLogin();
    } else if (this.tokenService.isExpired(decoded)) {
      const refreshResponse = await fetch('api/v4/Refresh', { headers });
      if (!refreshResponse.ok) return this.redirectToLogin();
      const { tokens } = await refreshResponse.json();
      if (!tokens?.access || !tokens?.xsrf) return this.redirectToLogin();
      const refreshedOptions = this.getHeadersAndOptions(tokens.access, tokens.xsrf);
      headers = refreshedOptions.headers;
      jsonRequestOptions = refreshedOptions.jsonRequestOptions;
      this.saveTokens(tokens);
    }

    // Blocking requests, things that need to succeed in order for the user to complete login/refresh
    const responses = await Promise.all([
      fetch('api/v4/Users?company=true', { headers }),
      fetch('api/v4/Users', jsonRequestOptions),
      fetch(`api/v4/Teams`, jsonRequestOptions),
      fetch(`/api/v4/Person/${decoded.personId}`, jsonRequestOptions),
      // ToDo: Remove this and use allusers$ - DEV-7250
      fetch('api/v4/Users/Directory', jsonRequestOptions),
      fetch('api/v4/Companies/Language/CompanyAndDefault', { headers }),
    ]);

    const errorResponses = responses.filter(r => !!r && r.status >= 400);
    for (const r of errorResponses) {
      const body = await r.json().catch(() => '');
      console.error(
        `Required request failed ${r.url} - ${r.status} - ${r.statusText} - ${JSON.stringify(body, null, 2)}`
      );
    }
    if (errorResponses.length) {
      return !isLoginComponent ? this.redirectToLogin() : null;
    }

    const [companyUsers, allUsers, allTeamsResp, person, directoryUsers, companyAndDefaultBOSLanguage] =
      (await Promise.all(responses.map(r => r.json()))) as BlockingResponses;
    // convert
    const decompressedTeamsStr = ungzip(allTeamsResp.teamsBuffer.data, { to: 'string' });
    const allTeams = JSON.parse(decompressedTeamsStr);

    allUsers.forEach(u => {
      const firstName = u.metadata?.name?.first || '';
      const lastName = u.metadata?.name?.last || '';
      u.fullName = u.fullName ? u.fullName : `${firstName} ${lastName}`;
    });
    let initialCompanyUser: CompanyUser = companyUsers?.find(u => u._id === decoded.userId) ?? null;

    if (!initialCompanyUser) {
      console.error(`Could not match current user to a company \n
        accessToken userId: ${decoded.userId} \n
        companyUser ids: ${companyUsers?.map(u => u._id).join(', ')} \n
      `);

      // ToDo: Use allusers$ - DEV-7250
      const user = directoryUsers.users.find(u => u._id === decoded.userId);
      if (!user) {
        console.error('Could not find current user in the company directory');
        return;
      } else {
        const companyResp = await fetch(`api/v4/Companies/${user.company.companyId}`, jsonRequestOptions);
        if (companyResp.status !== 200) {
          console.error('Could not fetch company by user company id');
          return;
        }
        initialCompanyUser = { ...user, company: await companyResp.json() } as CompanyUser;
      }
    }

    // Preventing this from running in ACME
    if (initialCompanyUser?.company._id !== '5ab03c1f7b5ab7000e9b23c5' && initialCompanyUser?.company?.stripe) {
      fetch('api/v4/Billing', { method: 'PATCH', headers, body: null });
    }

    // ToDo: Use allusers$ - DEV-7250
    const currentUser: User = directoryUsers?.users.find(u => u._id === decoded.userId) ?? null;
    if (!currentUser) {
      // Assuming this is due to a mismatch of the token and the current company. Causes hanging loading bar, see
      // DEV-6012
      this.store.dispatch(
        LoggingActions.error({
          log: {
            message: 'Failed to find user in directory, redirect to log in',
            data: {
              token: decoded,
              directoryUsers: directoryUsers?.users.map(u => u._id), // Sanitize out PII, only necessary debugging info
              ticket: 'DEV-6012',
            },
          },
        })
      );

      return this.redirectToLogin();
    }

    if (!currentUser.settings) currentUser.settings = new UserSettings(); // Just in case...

    this.store.dispatch(
      UsersStateActions.loadAllUsers({
        users: cloneDeep(allUsers) as UserModelState[],
      })
    );
    this.store.dispatch(userListStateActions.setLoggedInUser({ loggedInUser: currentUser }));
    this.store.dispatch(UsersStateActions.setCurrentUser({ userId: decoded.userId }));
    this.store.dispatch(LanguageActions.init(companyAndDefaultBOSLanguage));
    this.store.dispatch(CurrentPersonStateActions.appInit({ person }));
    this.store.dispatch(CompanyUsersStateActions.appInit({ companyUsers: cloneDeep(companyUsers) }));

    this.companyUsers$.next(companyUsers);
    this.initialCompanyUser$.next(initialCompanyUser);
    // ToDo: Refactor per - DEV-7250
    this.directoryUsers$.next(directoryUsers.users);

    this.store.dispatch(teamsStateActions.init({ teams: allTeams }));

    this.store.dispatch(HelpfulPermissionsActions.init({ helpfulPermissions: person.helpfulPermissions }));

    this.store.dispatch(appActions.initStateService());

    //=========================================================
    // Non-blocking requests - completes after method returns
    // Do not await to prevent blocking page load
    this.store.dispatch(FeatureFlagActions.updateLaunchDarklyUser());
  }

  private saveTokens(tokens: Tokens) {
    const now = new Date();

    localStorage.setItem('lastLoginDate', `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`);

    if (tokens?.access) this.tokenService.setAccessToken(tokens.access);

    //backups in case browser failed to set cookies
    if (tokens?.refresh && !this.cookieService.getRefreshCookie()) {
      this.cookieService.setRefreshCookie(tokens.refresh);
    }

    if (tokens?.xsrf && !this.cookieService.getXsrfCookie()) {
      this.cookieService.setXsrfCookie(tokens.xsrf);
    }

    if (tokens?.ninetyUserId && !this.cookieService.getNinetyUserIdCookie()) {
      this.cookieService.setNinetyUserIdCookie(tokens.ninetyUserId);
    }
  }

  private getHeadersAndOptions(
    accessToken: string,
    xsrfToken: string
  ): {
    headers: {
      'X-XSRF-TOKEN': string;
      Authorization: string;
    };
    jsonRequestOptions: {
      headers: {
        Accept: string;
        Authorization: string;
      };
    };
  } {
    const Authorization = `Bearer ${accessToken}`;
    const headers = {
      'X-XSRF-TOKEN': xsrfToken,
      Authorization,
    };
    const jsonRequestOptions = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        Authorization,
      },
    };
    return { headers, jsonRequestOptions };
  }

  // ToDo: Move this to it's own file/module and update AppModule.
  getLocale(): Locale {
    // This will run synchronously. If the user is not logged in, the default locale will be returned.
    let locale = Locale.default;
    this.store
      .select(selectCurrentUser)
      .pipe(take(1))
      .subscribe(user => {
        locale = user?.settings?.preferredLocale ?? locale;
      });
    return locale;
  }
}
