import { BusinessOperatingSystem } from '../company/business-operating-system.enum';

import { ReadonlyVtoLabels } from './vto';

export interface VtoLabels {
  guarantee: string;
  threeUniques: string;
  provenProcess: string;
  title: string;
  targetMarket: string;
  ninetyDays: string;
  oneYear: string;
  threeYear: string;
  tenYear: string;
  marketingStrategy: string;
  coreValues: string;

  /**
   * When a BOS uses Core Focus as a section on their VTO, this is used as the section title. Otherwise, it has no
   * meaning.
   */
  coreFocus: string;

  /**
   * When a BOS uses Core Focus - subheading of the Core Focus content
   * When a BOS does not - the title of the standalone Niche section
   */
  niche: string;

  /**
   * When a BOS uses Core Focus - subheading of the Core Focus content
   * When a BOS does not - the title of the standalone Purpose section
   */
  purpose: string;

  /**
   * @deprecated - use customLanguage.Issues
   */
  issuesList: string;
}

export namespace VtoLabels {
  export const LabelsByBosLookup: Readonly<Partial<Record<BusinessOperatingSystem, ReadonlyVtoLabels>>> = {
    [BusinessOperatingSystem.eos]: {
      coreValues: 'Core Values',
      coreFocus: 'Core Focus™',
      purpose: 'Purpose/Cause/Passion',
      niche: 'Niche',
      marketingStrategy: 'Go to Market Strategy',
      tenYear: '10 Year Target™',
      threeYear: '3 Year',
      oneYear: '1 Year',
      ninetyDays: '90 Days',
      targetMarket: 'Target Market',
      provenProcess: 'Proven Process',
      threeUniques: 'Three Uniques™',
      guarantee: 'Guarantee',

      // Deprecated - constructed from custom lang
      title: 'The Vision / Traction Organizer',
      issuesList: 'Issues List',
    },

    [BusinessOperatingSystem.ninetyOS]: {
      marketingStrategy: 'Marketing Strategy',
      tenYear: 'Compelling and Audacious Goals (CAGs)',
      oneYear: '1-Year Goals',
      threeYear: '3-Year Goals',
      ninetyDays: '90-Day Goals',
      targetMarket: 'Ideal Customer',
      threeUniques: 'Unique Value Proposition',
      niche: 'Niche',
      purpose: 'Purpose',

      // Unchanged
      guarantee: 'Guarantee',
      coreValues: 'Core Values',
      provenProcess: 'Proven Process',

      // Deprecated - constructed from custom lang
      title: 'The Vision / Traction Organizer',
      issuesList: 'Issues List',

      // Deprecated - use root level niche/purpose
      coreFocus: 'Purpose',
    },

    [BusinessOperatingSystem.pinnacle]: {
      coreValues: 'Core Values',
      marketingStrategy: 'Go to Market Strategy',
      tenYear: 'Long Term Goal',
      threeYear: 'Milestones (3-5 Year)',
      oneYear: 'Annual Growth Plan',
      ninetyDays: 'Quarterly Execution',
      targetMarket: 'Ideal Customer',
      provenProcess: 'Customer Journey',
      threeUniques: 'Differentiators',
      guarantee: 'Promise',

      // Deprecated - not used by Pinnacle
      coreFocus: 'Just Cause',
      purpose: 'Purpose/Cause/Passion',
      niche: 'Niche',
      issuesList: 'Issues List',
      title: 'The Vision / Traction Organizer',
    },
  };
}
