import { moveItemInArray } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { CascadingMessage } from '@ninety/ui/legacy/shared/models/meetings/cascading-message';

import { CascadingMessagesStateActions } from './cascading-messages-state.actions';
import {
  CascadingMessagesStateModel,
  cascadingMessagesInitialState,
  cascadingMessagesStateAdapter,
  defaultPagination,
  defaultSort,
} from './cascading-messages-state.model';

export const CascadingMessagesStateReducer = createReducer(
  cascadingMessagesInitialState,
  on(
    CascadingMessagesStateActions.hydrate,
    (state, { pageSize, sort }): CascadingMessagesStateModel => ({
      ...state,
      pagination: {
        ...state.pagination,
        ...(pageSize && { size: pageSize }),
      },
      ...(sort && { sort }),
    })
  ),

  on(
    CascadingMessagesStateActions.get,
    (state): CascadingMessagesStateModel => ({ ...state, loading: true, error: false })
  ),

  on(CascadingMessagesStateActions.getSuccess, (state, { cascadingMessages, meeting }) => {
    let cascadingMessagesToSet: CascadingMessage[];
    if (meeting?.doneHeadlines.length) {
      cascadingMessagesToSet = cloneDeep(cascadingMessages.data);
      cascadingMessagesToSet.forEach(cm => {
        if (meeting.doneHeadlines.includes(cm._id)) cm.isDone = true;
      });
    }
    cascadingMessagesToSet = cascadingMessagesToSet ?? cascadingMessages.data;

    return cascadingMessagesStateAdapter.setAll(cascadingMessagesToSet, {
      ...state,
      totalCount: cascadingMessages.totalCount,
      loading: false,
      error: false,
    });
  }),

  on(
    CascadingMessagesStateActions.getFailed,
    (state): CascadingMessagesStateModel => ({
      ...state,
      loading: false,
      error: true,
    })
  ),
  on(
    CascadingMessagesStateActions.filterByTeam,
    (state, { teamId }): CascadingMessagesStateModel => ({
      ...state,
      filters: { ...state.filters, teamId },
      pagination: {
        ...defaultPagination,
        size: state.pagination.size,
      },
      loading: true,
    })
  ),
  on(
    CascadingMessagesStateActions.showArchived,
    (state, { archived }): CascadingMessagesStateModel => ({
      ...state,
      selectedId: null,
      filters: { ...state.filters, archived },
      pagination: {
        ...defaultPagination,
        size: state.pagination.size,
      },
      loading: true,
    })
  ),
  on(
    CascadingMessagesStateActions.search,
    (state, { searchText }): CascadingMessagesStateModel => ({
      ...state,
      filters: { ...state.filters, searchText },
      pagination: {
        ...defaultPagination,
        size: state.pagination.size,
      },
      loading: true,
    })
  ),
  on(
    CascadingMessagesStateActions.sortChange,
    (state, { sort }): CascadingMessagesStateModel => ({
      ...state,
      sort,
    })
  ),

  on(
    CascadingMessagesStateActions.setInMeetingId,
    (state, { meetingId }): CascadingMessagesStateModel => ({
      ...state,
      filters: { ...state.filters, inMeetingId: meetingId, includeDiscussed: !meetingId },
    })
  ),
  on(
    CascadingMessagesStateActions.clearSort,
    (state): CascadingMessagesStateModel => ({
      ...state,
      sort: defaultSort,
    })
  ),
  on(
    CascadingMessagesStateActions.updateOrdinals,
    (state, { previousIndex, currentIndex }): CascadingMessagesStateModel => {
      const cascadingMessagesToBeOrdered = cloneDeep(cascadingMessagesStateAdapter.getSelectors().selectAll(state));
      moveItemInArray(cascadingMessagesToBeOrdered, previousIndex, currentIndex);
      cascadingMessagesToBeOrdered.map((cm, i) => (cm.ordinal = state.pagination.index * state.pagination.size + i));
      return cascadingMessagesStateAdapter.setAll(cascadingMessagesToBeOrdered, state);
    }
  ),

  on(
    CascadingMessagesStateActions.openInDetailView,
    (state, { cascadingMessageId }): CascadingMessagesStateModel => ({
      ...state,
      selectedId: cascadingMessageId,
    })
  ),
  on(
    CascadingMessagesStateActions.setSelected,
    (state, { selectedId }): CascadingMessagesStateModel => ({ ...state, selectedId })
  ),
  on(
    CascadingMessagesStateActions.clearSelected,
    (state): CascadingMessagesStateModel => ({ ...state, selectedId: null })
  ),
  on(
    CascadingMessagesStateActions.paginationChange,
    (state, { index, size }): CascadingMessagesStateModel => ({
      ...state,
      pagination: { index, size },
    })
  ),
  on(
    CascadingMessagesStateActions.update,
    CascadingMessagesStateActions.updateLocal,
    CascadingMessagesStateActions.setCompletedSuccess,
    CascadingMessagesStateActions.setUserSuccess,
    (state, { update }) => cascadingMessagesStateAdapter.updateOne(update, state)
  ),

  on(CascadingMessagesStateActions.setArchivedSuccess, (state, { cascadingMessage }) =>
    cascadingMessagesStateAdapter.removeOne(cascadingMessage._id, { ...state, totalCount: state.totalCount - 1 })
  ),

  on(
    CascadingMessagesStateActions.delete,
    CascadingMessagesStateActions.deleteLocal,
    CascadingMessagesStateActions.setTeam,
    (state, { id }) => cascadingMessagesStateAdapter.removeOne(id, { ...state, totalCount: state.totalCount - 1 })
  ),
  on(
    CascadingMessagesStateActions.resetStore,
    (state): CascadingMessagesStateModel => ({
      ...cascadingMessagesInitialState,
      pagination: { ...cascadingMessagesInitialState.pagination, size: state.pagination.size },
      //reset to the page size saved in local storage
    })
  ),

  on(CascadingMessagesStateActions.add, (state, { cascadingMessage }) =>
    cascadingMessagesStateAdapter.addOne(cascadingMessage, { ...state, totalCount: state.totalCount + 1 })
  )
);
