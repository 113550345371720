import { createActionGroup, emptyProps, props } from '@ngrx/store';

import type { TodosContextMenuData } from '@ninety/my-ninety/components/todos-context-menu';
import { DragAndDropEvent, TodoSharedActionEvents } from '@ninety/todos/_state/_shared/todo-state.shared.actions';
import type { PersonalTodoState } from '@ninety/todos/_state/personal/personal-todo.model';
import { CreateTodoResponse } from '@ninety/todos/services/models/create-todo-response';
import { PrintOptions } from '@ninety/ui/legacy/core/services/print.service';
import type { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import type { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import type { TodoGETManyApiResponse } from '@ninety/ui/legacy/shared/models/todos/todo-response';
import { TodoSort } from '@ninety/ui/legacy/shared/models/todos/todo-sort';

export const PersonalTodoActions = createActionGroup({
  source: 'Personal Todos',
  events: {
    ...TodoSharedActionEvents,

    'Select Incomplete': emptyProps(),
    'Select Complete': emptyProps(),
    'Open Create Dialog': emptyProps(),

    'Get Many Success': props<{ response: TodoGETManyApiResponse }>(),
    'Get Many Failure': props<{ error: unknown }>(),

    'Toggle Completed': props<{ todo: Todo; isCompleted: boolean; source: 'inline' | 'detail' }>(),

    'Reset State': props<{ overrides?: Partial<PersonalTodoState> }>(),

    'Move To Bottom': props<TodosContextMenuData>(),
    'Move To Top': props<TodosContextMenuData>(),
    'Add To Personal': props<{ todo: Todo }>(),
    'Remove From Personal': props<{ todo: Todo }>(),

    //when the list shows multiple todos in the same series we need to update them as well
    //...when one of them is updated inline or in detail view
    'Update Displayed Todos In Series': props<{ todo: Todo }>(),

    'Download Excel': emptyProps(),
    'Download Excel Success': emptyProps(),
    'Download Excel Failure': emptyProps(),
  },
});

export const PersonalTodoInlineActions = createActionGroup({
  source: 'Personal Todos Inline',
  events: {
    'Add One': props<{ todo: Item }>(),
    'Cancel Add One': emptyProps(),

    'Create One': props<{ todo: Item }>(),
    'Create One Failure': emptyProps(),
    'Create One Success': props<{ response: CreateTodoResponse }>(),
  },
});

export const PersonalTabTodosActions = createActionGroup({
  source: 'Personal Tab Todos',
  events: {
    Init: emptyProps(),
    'Set Filter Toolbar Success': emptyProps(),
    'Open Create Dialog': emptyProps(),
    'Show Completed': props<{ showCompleted: boolean }>(),
    'Select Todo': props<{ todo: Todo }>(),
    'Add One Inline': emptyProps(),

    Hydrate: props<{ pageSize: number; sort: TodoSort }>(),

    'Pagination Change': props<{ index: number; size: number }>(),
    'Sort By': props<{ sort: TodoSort }>(),

    'Update Ordinals': props<DragAndDropEvent>(),
    'Update Ordinals Success': emptyProps(),
    'Update Ordinals Failure': emptyProps(),

    'Move To Bottom': props<TodosContextMenuData>(),
    'Move To Top': props<TodosContextMenuData>(),

    Print: props<{ printOptions: PrintOptions }>(),
    'Print Success': emptyProps(),
    'Print Failure': emptyProps(),
  },
});
