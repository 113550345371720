import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraErrorComponent } from './terra-error.component';

@NgModule({
  imports: [CommonModule, TerraErrorComponent],
  exports: [TerraErrorComponent],
})
export class TerraErrorModule {}
