<div class="container">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Social</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <mat-label class="muted">LinkedIn</mat-label>
        <mat-form-field class="outline-field" appearance="outline">
          <input
            data-cy="social_input-field"
            matInput
            type="url"
            [(ngModel)]="metadata.linkedInUrl"
            (ngModelChange)="debounceUpdate()" />
          <a
            data-cy="social_open-link"
            matSuffix
            mat-icon-button
            aria-label="Copy Link"
            [href]="metadata.linkedInUrl"
            target="_blank">
            <terra-icon icon="arrow-square-out" />
          </a>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
