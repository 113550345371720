<div
  #avatar
  class="terra-avatar"
  [ngClass]="{ 'terra-avatar--interactive': interactive, 'terra-avatar--inactive': inactive }">
  <div class="terra-avatar__avatar">
    <div class="terra-avatar__initials" *ngIf="!src && initials">{{ _displayedInitials }}</div>
    <img class="terra-avatar__image" *ngIf="src" [src]="src" [alt]="altText" (error)="src = ''" />
    <terra-icon class="terra-avatar__icon" *ngIf="!src && !initials" icon="user" [size]="_getIconSize()" />
  </div>
</div>
