import { Injectable } from '@angular/core';
import { Context } from '@datadog/browser-core';
import { datadogLogs, type LogsInitConfiguration } from '@datadog/browser-logs';

import { LoggerName } from '../../../_state/app-logging/logging-feature-flag-state';

import { DATADOG_DEFAULT_LOGGER_CONF } from './datadog-contants';
import { DatadogLogger } from './datadog-logger';
import { DatadogLoggerFactory } from './datadog-logger-factory';

/**
 * A wrapper around the datadogLogs library.
 *
 * Features:
 *  - Initializes the library
 *  - Create/Manage Global Context
 *  - Create/Manage Loggers
 */
@Injectable()
export class DatadogLogsService {
  get logger() {
    return datadogLogs.logger;
  }

  init(initConfiguration: LogsInitConfiguration) {
    datadogLogs.init(initConfiguration);
    return this;
  }

  getGlobalContext() {
    return datadogLogs.getGlobalContext();
  }

  setGlobalContext(newContext: Context) {
    datadogLogs.setGlobalContext(newContext);
  }

  setGlobalContextProperty(key: string, property: unknown) {
    datadogLogs.setGlobalContextProperty(key, property);
  }

  removeGlobalContextProperty(key: string) {
    datadogLogs.removeGlobalContextProperty(key);
  }

  clearGlobalContext() {
    datadogLogs.clearGlobalContext();
  }

  createLogger(name: string, conf = DATADOG_DEFAULT_LOGGER_CONF): DatadogLogger {
    return DatadogLoggerFactory(name, conf);
  }

  getLogger(name: LoggerName): DatadogLogger {
    let ddLogger = datadogLogs.getLogger(name);

    if (!ddLogger) {
      // Fallback to root logger
      ddLogger = datadogLogs.getLogger(LoggerName.AppModule);
    }

    return new DatadogLogger(ddLogger);
  }

  getInitConfiguration() {
    return datadogLogs.getInitConfiguration();
  }

  getInternalContext(startTime?: number) {
    return datadogLogs.getInternalContext(startTime);
  }

  onReady(callback: () => void): void {
    datadogLogs.onReady(callback);
  }
}
