import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName',
})
export class SortByNamePipe implements PipeTransform {
  transform<T extends { name?: string }>(values: T[], ascending?: boolean): T[] {
    return SortByNamePipe.transform(values, ascending);
  }

  static transform<T extends { name?: string }>(values: T[], ascending = true): T[] {
    if (!values) return values;

    // Changed to spread to avoid mutating the input array
    return [...values].sort((a: T, b: T) => {
      const teamA = a.name.toLowerCase();
      const teamB = b.name.toLowerCase();
      return ascending ? (teamA < teamB ? -1 : teamB < teamA ? 1 : 0) : teamB < teamA ? -1 : teamA < teamB ? 1 : 0;
    });
  }

  static ascending<T extends { name: string }>(a: T, b: T) {
    const nameA = a.name.toLowerCase().replace(' ', '');
    const nameB = b.name.toLowerCase().replace(' ', '');

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  }

  static descending<T extends { name: string }>(a: T, b: T) {
    const nameA = a.name.toLowerCase().replace(' ', '');
    const nameB = b.name.toLowerCase().replace(' ', '');

    return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
  }
}
