<!--
  Need top level div so resize directive is applied regardless of readOnly status,
  otherwise you get undefined errors in the afterViewInit() callback
-->
<div ninetyElementResizeAware>
  <ng-container *ngIf="size && !readonly; else viewOnly">
    <div data-cy="text-editor-froala__description" [ngSwitch]="size">
      <!-- Extra Small -->
      <div
        id="froala-xs"
        *ngSwitchCase="TextEditorToolbarSize.xs"
        [froalaEditor]="sizedOptions.extraSmall"
        [froalaModel]="text"
        (froalaModelChange)="onFroalaChangeEvent($event)"></div>

      <!-- Small -->
      <div
        id="froala-sm"
        *ngSwitchCase="TextEditorToolbarSize.sm"
        [froalaEditor]="sizedOptions.small"
        [froalaModel]="text"
        (froalaModelChange)="onFroalaChangeEvent($event)"></div>

      <!-- Medium -->
      <div
        id="froala-md"
        *ngSwitchCase="TextEditorToolbarSize.md"
        [froalaEditor]="sizedOptions.medium"
        [froalaModel]="text"
        (froalaModelChange)="onFroalaChangeEvent($event)"></div>

      <!-- Large+ -->
      <div
        id="froala-lg"
        *ngSwitchDefault
        [froalaEditor]="sizedOptions.large"
        [froalaModel]="text"
        (froalaModelChange)="onFroalaChangeEvent($event)"></div>
    </div>
  </ng-container>
</div>

<ng-template #viewOnly>
  <div [froalaView]="text"></div>
</ng-template>
