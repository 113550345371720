<div class="flex">
  <h2 class="terra-title">Manage Licenses</h2>
  <button
    data-cy="manage-seats_close-modal-button"
    mat-button
    mat-dialog-close
    class="close"
    (click)="this.dialogRef.close()">
    <terra-icon icon="x" />
  </button>
</div>
<p>
  Manage the number of Licenses available for your company. Licenses become available immediately upon purchase.
  <a
    class="terra-body-bold"
    data-cy="manage-seats_pricing-model-link"
    href="https://www.ninety.io/ninety-pricing"
    target="_blank"
    >Learn more about our pricing model.</a
  >
</p>
<div>
  <table>
    <thead>
      <th>Qty</th>
      <th>License Type</th>
    </thead>
    <tbody>
      <tr>
        <td>
          <mat-form-field class="count">
            <input
              data-cy="manage-seats_seats-qty-input-field"
              [min]="0"
              [(ngModel)]="standardSeatCountInput"
              type="number"
              id="standardSeatCountInput"
              matInput />
          </mat-form-field>
        </td>
        <td>
          Single User License
          <span class="low-opacity">{{ costPeriodDescriptor }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="info">
    <table>
      <tr>
        <td>Currently Billed Licenses</td>
        <td>Active Billed Users</td>
        <td>Remaining Assignable Seats</td>
      </tr>
      <tr class="numbers">
        <td data-cy="manage-seats_seats-paid-for">{{ initial.seatsPaidFor }}</td>
        <td data-cy="manage-seats_active-billing-users">{{ initial.activeBillingUsers }}</td>
        <td data-cy="manage-seats_remaining-assignable-seats">{{ initial.assignableSeats }}</td>
      </tr>
    </table>
  </div>
</div>
<div class="flex submit-row">
  <button
    data-cy="manage-seats_confirm-seats-changes-button"
    class="submit"
    [class.active]="this.standardSeatCountInput !== this.initial.seatsPaidFor"
    (click)="submit()"
    mat-button>
    {{ submitText }}
  </button>
  <button data-cy="manage-seats_cancel-seats-changes-button" mat-button (click)="this.dialogRef.close()">Cancel</button>
</div>
