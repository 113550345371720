import { BreakpointState } from '@angular/cdk/layout';
import { createActionGroup, props } from '@ngrx/store';

import { NinetyBreakpointNames } from './viewport.model';

export const BreakpointActions = createActionGroup({
  source: 'Viewport Effects',
  events: {
    'Breakpoint Set': props<{ breakpointState: BreakpointState; currentScreenSize: NinetyBreakpointNames }>(),
  },
});
