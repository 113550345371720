import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LayoutStateKey, layoutStateReducers } from '@ninety/layouts/_state/index';
import { SpinnerStateEffects } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.effects';

import { FilterBarStateEffects } from './filterbar/filterbar-state.effects';
import { TopToolbarEffects } from './top-toolbar/top-toolbar-state.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(LayoutStateKey, layoutStateReducers),
    EffectsModule.forFeature([FilterBarStateEffects, SpinnerStateEffects, TopToolbarEffects]),
  ],
})
export class LayoutStateModule {}
