export interface BrandingTheme {
  name: keyof typeof ThemeName;
  properties: BrandingThemeProperties;
}

export interface BrandingOptions {
  themes: BrandingTheme[];
  active: keyof typeof ThemeName;
}

export interface BrandingThemeProperties {
  '--primaryAccent'?: string;
  '--secondaryAccent'?: string;
}

export enum ThemeName {
  default = 'default',
  branded = 'branded',
}

export const defaultBrandingTheme: BrandingTheme = {
  name: ThemeName.default,
  properties: {
    '--primaryAccent': '#2f8baa', // blue
    '--secondaryAccent': '#262626', // black
  },
};

export function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : null;
}
