import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty } from 'lodash';
import { catchError, concatMap, exhaustMap, filter, iif, map, of, switchMap, tap } from 'rxjs';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { DetailViewActions } from '@ninety/detail-view/_state/detail-view.actions';
import { HeadlineService } from '@ninety/headlines/_shared/services/headline.service';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { ExcelExportType } from '@ninety/ui/legacy/core/services/_state/filter-service/excel-export-types.enum';
import { FilterServiceActions } from '@ninety/ui/legacy/core/services/_state/filter-service/filter.service.actions';
import { FileService } from '@ninety/ui/legacy/core/services/file.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { PrintApi, PrintService } from '@ninety/ui/legacy/core/services/print.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { OrdinalOrUserOrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { FromLinkedItem, LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';
import { HeadlineMessageType } from '@ninety/ui/legacy/shared/models/meetings/headline-message-type';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import * as FeatureFlagSelectors from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';

import * as cascadingMessagesSelectors from '../cascading-messages/cascading-messages-state.selectors';

import { HeadlinesStateActions } from './headlines-state.actions';
import { HeadlineStateKey } from './headlines-state.model';
import * as headlinesSelectors from './headlines-state.selectors';

@Injectable()
export class HeadlinesStateEffects {
  static readonly source = 'HeadlinesStateEffects';

  constructor(
    private actions$: Actions,
    private headlineService: HeadlineService,
    private localStorageService: LocalStorageService,
    private store: Store,
    private createDialogService: CreateDialogService,
    private meetingService: MeetingService,
    private printService: PrintService,
    private filterService: FilterService,
    private fileService: FileService,
    /** deprecated use MatDialog version when MDC ConfirmDialogComponent version is done */
    private dialog: MatDialog
  ) {}

  paginationChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.paginationChange),
        tap(({ size }) => this.localStorageService.set(`${HeadlineStateKey}.pagination.size`, size))
      ),
    { dispatch: false }
  );

  sortChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.sortChange),
        tap(({ sort }) => {
          if (sort.direction) {
            //direction is null when sort cycle is complete
            this.localStorageService.set(`${HeadlineStateKey}.sort`, sort);
          } else {
            this.localStorageService.delete(`${HeadlineStateKey}.sort`);
          }
        })
      ),
    { dispatch: false }
  );

  clearSort$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.clearSort),
        tap(_ => this.localStorageService.delete(`${HeadlineStateKey}.sort`))
      ),
    { dispatch: false }
  );

  broadcastOnSortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.sortChange),
      concatLatestFrom(() => [
        this.store.select(headlinesSelectors.selectFilterByTeamId),
        this.meetingService.currentMeeting$,
        this.store.select(selectCurrentUser),
      ]),
      filter(
        ([, , currentMeeting, currentUser]) =>
          !currentUser.isObserver && currentMeeting?.presenterUserId === currentUser._id
      ),
      map(([{ sort }, teamId]) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.sort,
            document: {
              listType: 'headlines',
              field: sort.field as string,
              currentTeamId: teamId,
              sortDirection: sort.direction,
              screen: 'headlines',
              //TODO NEXT: handle conclude when refactored. maybe even skip this for conclude as lists are combined,
              //because lists are combined on conclude and we save sort in local storage
            },
          },
        })
      )
    )
  );

  openCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.openCreateDialog),
        switchMap(() => this.createDialogService.open({ itemType: ItemType.headline }))
      ),
    { dispatch: false }
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        HeadlinesStateActions.filterByTeam,
        HeadlinesStateActions.sortChange,
        HeadlinesStateActions.paginationChange,
        HeadlinesStateActions.showArchived,
        HeadlinesStateActions.search,
        HeadlinesStateActions.archiveAllCompletedSuccess
      ),
      concatLatestFrom(() => [this.store.select(headlinesSelectors.selectGetHeadlineQueryParams)]),
      switchMap(([_, params]) =>
        this.headlineService.getHeadlinesByTeamId(params).pipe(
          map(headlines =>
            HeadlinesStateActions.getSuccess({ headlines, meeting: cloneDeep(this.meetingService.currentMeeting) })
          ),
          catchError((error: unknown) => of(HeadlinesStateActions.getFailed({ error })))
        )
      )
    )
  );

  setSelectedHeadline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.getSuccess),
      concatLatestFrom(() => [this.store.select(headlinesSelectors.selectHeadlineIdFromDetailRoute)]),
      filter(([_, headlineId]) => !!headlineId),
      map(([_, headlineId]) => HeadlinesStateActions.setSelected({ selectedId: headlineId }))
    )
  );

  openInDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.openInDetailView),
      map(action =>
        DetailViewActions.opened({
          config: { pathParts: [DetailType.headline, action.headlineId] },
          source: 'Headline Selected',
        })
      )
    )
  );

  clearSelectedOnClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.closed),
      filter(({ itemType }) => this.isHeadlineAction(itemType)),
      map(() => HeadlinesStateActions.clearSelected())
    )
  );

  saveInline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.saveInline),
      concatMap(({ headline }) => {
        const { _id: _, ...noIdHeadline } = headline;
        return this.headlineService.createHeadline(noIdHeadline).pipe(
          //NOTE: saving inline should create only one headline so we can do [0]
          map(response => HeadlinesStateActions.saveInlineSuccess({ headline: response[0] })),
          catchError((error: unknown) => of(HeadlinesStateActions.saveInlineFailed({ error })))
        );
      })
    )
  );

  onSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.saveInlineSuccess, HeadlinesStateActions.saveInlineFailed),
      map(_ => HeadlinesStateActions.cancelAddInline())
    )
  );

  broadcastOnCreateHeadline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.add),
      filter(_ => this.headlineService.shouldBroadcast),
      map(({ headline }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.new,
            document: headline,
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.update),
      concatMap(({ update }) =>
        this.headlineService.updateHeadline(update.id as string, update.changes).pipe(
          map(_ => HeadlinesStateActions.updateSuccess({ update })),
          catchError((error: unknown) => of(HeadlinesStateActions.updateFailed({ error })))
        )
      )
    )
  );

  //TODO NEXT: remove/refactor when meeting is moved to store
  updateDoneHeadlinesDocsInMeeting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.updateSuccess, HeadlinesStateActions.setUserSuccess),
        filter(_ => this.meetingService.currentMeeting?.inProgress),
        tap(({ update }) => {
          const doneHeadlineDocs = [...(this.meetingService.currentMeeting.doneHeadlineDocs || [])];
          const indexH = doneHeadlineDocs.findIndex(h => h._id === update.id);
          if (indexH >= 0) {
            doneHeadlineDocs[indexH] = { ...doneHeadlineDocs[indexH], ...update.changes };
            this.meetingService.setCurrentMeeting({ ...this.meetingService.currentMeeting, doneHeadlineDocs });
          }

          const cascadingMessageDocs = [...(this.meetingService.currentMeeting.cascadingMessageDocs || [])];
          const indexCm = cascadingMessageDocs.findIndex(h => h._id === update.id);
          if (indexCm >= 0) {
            cascadingMessageDocs[indexCm] = { ...cascadingMessageDocs[indexCm], ...update.changes };
            this.meetingService.setCurrentMeeting({ ...this.meetingService.currentMeeting, cascadingMessageDocs });
          }
        })
      ),
    { dispatch: false }
  );

  broadcastOnUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.updateSuccess),
      filter(_ => this.headlineService.shouldBroadcast),
      map(({ update }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { ...update.changes, _id: update.id as string },
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  setTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setTeam),
      concatMap(({ id, teamId }) =>
        this.headlineService.updateHeadline(id, { teamId }).pipe(
          map(_ => HeadlinesStateActions.setTeamSuccess({ id, teamId })),
          catchError((error: unknown) => of(HeadlinesStateActions.setTeamFailed({ error })))
        )
      )
    )
  );

  broadcastOnSetTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setTeamSuccess),
      filter(_ => this.headlineService.shouldBroadcast),
      map(({ id, teamId }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { teamId, _id: id },
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setUser),
      concatMap(({ id, ownedByUserId }) =>
        this.headlineService.updateHeadline(id, { ownedByUserId }).pipe(
          map(update =>
            HeadlinesStateActions.setUserSuccess({
              update: { id, changes: { ownedByUserId: update.ownedByUserId, ownedByUser: update.ownedByUser } },
            })
          ),
          catchError((error: unknown) => of(HeadlinesStateActions.setUserFailed({ error })))
        )
      )
    )
  );

  broadcastOnSetUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setUserSuccess),
      filter(_ => this.headlineService.shouldBroadcast),
      map(({ update }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { ...update.changes, _id: update.id as string },
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  setArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setArchived),
      concatMap(({ id, archived }) =>
        this.headlineService.setArchived(id, archived).pipe(
          map(headline => HeadlinesStateActions.setArchivedSuccess({ headline })),
          catchError((error: unknown) => of(HeadlinesStateActions.setArchivedFailed({ error })))
        )
      )
    )
  );

  broadcastSetArchivedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setArchivedSuccess),
      filter(_ => this.headlineService.shouldBroadcast),
      map(({ headline }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.archive,
            document: headline,
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  setCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setCompleted),
      concatMap(({ id, completed }) =>
        this.headlineService.setCompleted(id, completed, this.meetingService.currentMeeting?._id).pipe(
          map(update => {
            if (!update.isDone) {
              //remove isDone meta fields by setting them to undefined,
              update.completedDate = undefined;
              update.completedBy = undefined;
              update.completedInMeeting = undefined;
            }
            return HeadlinesStateActions.setCompletedSuccess({ update: { id, changes: update } });
          }),
          catchError((error: unknown) => of(HeadlinesStateActions.setCompletedFailed({ error })))
        )
      )
    )
  );

  setCompletedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setCompletedSuccess),
      filter(_ => this.meetingService.currentMeeting?.inProgress),
      concatMap(({ update }) =>
        iif(
          () => update.changes.isDone,
          this.meetingService.addDoneHeadline(update.id as string).pipe(map(_ => update)),
          this.meetingService.removeDoneHeadline(update.id as string).pipe(map(_ => update))
        ).pipe(
          map(update => HeadlinesStateActions.setCompletedInMeetingSuccess({ update })),
          catchError((error: unknown) => of(HeadlinesStateActions.setCompletedInMeetingFailed({ error })))
        )
      )
    )
  );

  broadcastSetCompletedInMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.setCompletedInMeetingSuccess),
      map(({ update }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { ...update.changes, _id: update.id as string },
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.delete),
      concatMap(({ id }) =>
        this.headlineService.deleteHeadline(id).pipe(
          map(_ => HeadlinesStateActions.deleteSuccess({ id })),
          catchError((error: unknown) => of(HeadlinesStateActions.deleteFailed({ error })))
        )
      )
    )
  );

  broadcastOnDeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.deleteSuccess),
      filter(_ => this.headlineService.shouldBroadcast),
      map(({ id }) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.delete,
            document: id,
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  inMeetingRemoveDoneHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.deleteSuccess),
        filter(_ => this.meetingService.currentMeeting?.inProgress),
        concatMap(({ id }) =>
          this.meetingService.removeDoneHeadline(id).pipe(
            map(() => HeadlinesStateActions.removeDoneHeadlineSuccess()),
            catchError(() => of(HeadlinesStateActions.removeDoneHeadlineFailure()))
          )
        )
      ),
    { dispatch: false }
  );

  //you can complete a CM in detail view, so we need to remove it from doneHeadlines as well
  //TODO NEXT: discuss feature
  inMeetingRemoveCascadedMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.deleteSuccess),
        filter(_ => this.meetingService.currentMeeting?.inProgress),
        filter(({ id }) => this.meetingService.currentMeeting?.cascadingMessages.includes(id)),
        concatMap(({ id }) =>
          this.meetingService.removeCascadingMessage(id).pipe(
            map(() => HeadlinesStateActions.removeCascadedMessageSuccess()),
            catchError(() => of(HeadlinesStateActions.removeCascadedMessageFailure()))
          )
        )
      ),
    { dispatch: false }
  );

  closeDetailIfOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.deleteLocal),
      concatLatestFrom(() => [this.store.select(headlinesSelectors.selectSelectedId)]),
      filter(([{ id }, selectedId]) => id === selectedId),
      map(_ => DetailViewActions.close())
    )
  );

  closeDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        HeadlinesStateActions.delete,
        HeadlinesStateActions.setArchived,
        HeadlinesStateActions.clearSelected,
        HeadlinesStateActions.setTeam
      ),
      map(() => DetailViewActions.close())
    )
  );

  private isHeadlineAction(itemType: DetailType) {
    return itemType === DetailType.headline;
  }

  cascade$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.cascade),
        switchMap(({ headline }) =>
          this.createDialogService.open({
            item: { ...headline, isCascadedMessage: true },
            itemType: ItemType.cascadedMessage,
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.createIssue),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        concatMap(([{ headline }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: headline._id,
              type: headline.isCascadedMessage ? LinkedItemTypeEnum.cascadingMessage : LinkedItemTypeEnum.headline,
            };
          }
          return this.createDialogService.open({
            item: {
              title: headline.title,
              description: headline.description,
              comments: headline.comments,
              attachments: headline.attachments,
              userId: headline.ownedByUserId,
            },
            itemType: ItemType.issue,
            ...(createdFrom ? { createdFrom } : null),
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  createTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.createTodo),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        concatMap(([{ headline }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: headline._id,
              type: headline.isCascadedMessage ? LinkedItemTypeEnum.cascadingMessage : LinkedItemTypeEnum.headline,
            };
          }
          return this.createDialogService.open({
            item: {
              title: headline.title,
              description: headline.description,
              comments: headline.comments,
              attachments: headline.attachments,
              userId: headline.ownedByUserId,
            },
            itemType: ItemType.todo,
            ...(createdFrom ? { createdFrom } : null),
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  createRock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.createRock),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        concatMap(([{ headline }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: headline._id,
              type: headline.isCascadedMessage ? LinkedItemTypeEnum.cascadingMessage : LinkedItemTypeEnum.headline,
            };
          }
          return this.createDialogService.open({
            item: {
              title: headline.title,
              description: headline.description,
              comments: headline.comments,
              attachments: headline.attachments,
              userId: headline.ownedByUserId,
            },
            itemType: ItemType.rock,
            ...(createdFrom ? { createdFrom } : null),
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  createHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.createHeadline),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        concatMap(([{ headline }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: headline._id,
              type: headline.isCascadedMessage ? LinkedItemTypeEnum.cascadingMessage : LinkedItemTypeEnum.headline,
            };
          }
          return this.createDialogService.open({
            item: {
              title: headline.title,
              description: headline.description,
              comments: headline.comments,
              attachments: headline.attachments,
              userId: headline.ownedByUserId,
            },
            itemType: ItemType.headline,
            ...(createdFrom ? { createdFrom } : null),
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  updateOrdinals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.updateOrdinals),
      concatLatestFrom(() => [
        this.store.select(headlinesSelectors.selectHeadlines),
        this.store.select(headlinesSelectors.selectPagination),
        this.store.select(headlinesSelectors.selectFilterBy),
        this.store.select(headlinesSelectors.selectSort),
      ]),
      map(([{ previousIndex, currentIndex }, headlines, pagination, filterBy, sort]) => {
        const start = Math.min(previousIndex, currentIndex) + pagination.index * pagination.size;
        const stop = Math.max(previousIndex, currentIndex) + pagination.index * pagination.size;

        const headlinesWithNewOrdinals = headlines.filter(t => t.ordinal >= start && t.ordinal <= stop);

        this.headlineService
          .updateOrdinals(
            headlinesWithNewOrdinals.map((h: Headline) => new OrdinalOrUserOrdinalUpdate(h._id, h.ordinal, 'ordinal')),
            'ordinal',
            filterBy.teamId,
            false,
            filterBy.inMeetingId,
            sort.field,
            sort.direction
          )
          .subscribe();

        return HeadlinesStateActions.clearSort();
      })
    )
  );

  print$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.printHeadlinesAndCms),
      concatLatestFrom(() => [
        this.store.select(headlinesSelectors.selectFilterBy),
        this.store.select(headlinesSelectors.selectSort),
      ]),
      switchMap(([{ printOptions }, filterBy, sort]) =>
        this.printService
          .openPdf(PrintApi.headlinesAndCms, {
            teamId: filterBy.teamId,
            showArchived: filterBy.archived,
            searchText: filterBy.searchText,
            ...(sort.direction ? { sortField: sort.field, sortDirection: sort.direction } : null),
            printOptions,
          })
          .pipe(
            map(() => HeadlinesStateActions.printHeadlinesAndCmsSuccess()),
            catchError(() => of(HeadlinesStateActions.printHeadlinesAndCmsFailure()))
          )
      )
    )
  );

  downloadExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterServiceActions.exportToExcel),
      filter(({ exportType }) => exportType === ExcelExportType.teamHeadlines),
      concatLatestFrom(() => [
        this.store.select(headlinesSelectors.selectFilterBy),
        this.store.select(headlinesSelectors.selectSortOrNull),
        this.store.select(cascadingMessagesSelectors.selectSortOrNull),
        this.filterService.selectedTeam$,
        this.store.select(selectLanguage),
      ]),
      switchMap(([, filterBy, sortHeadlines, sortCascadingMessages, team, language]) => {
        let sortOptions = {
          ...(sortHeadlines ? { headlines: sortHeadlines } : null),
          ...(sortCascadingMessages ? { cascadingMessages: sortCascadingMessages } : null),
        };

        sortOptions = isEmpty(sortOptions) ? null : sortOptions;

        return this.headlineService
          .downloadExcel(filterBy, sortOptions)
          .pipe(
            tap(response => {
              const name = `${language.headline.items}_${language.cascadingMessage.items}_${team.name}`;
              const fileName = `${name}_${formatDate(new Date(), 'yyyy-MM-dd', 'en-US')}.xlsx`;
              this.fileService.downloadExcelFile(response, fileName);
            })
          )
          .pipe(
            map(() => HeadlinesStateActions.downloadExcelSuccess()),
            catchError(() => of(HeadlinesStateActions.downloadExcelFailure()))
          );
      })
    )
  );

  broadcastOnAttachmentsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.addAttachment, HeadlinesStateActions.removeAttachment),
      filter(_ => this.headlineService.shouldBroadcast),
      concatLatestFrom(({ id }) => [this.store.select(headlinesSelectors.selectHeadlineById(id))]),
      map(([_, headline]) =>
        HeadlinesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { attachments: headline?.attachments || [], _id: headline._id },
            isCascadingMessage: false,
          },
        })
      )
    )
  );

  archiveAllCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.archiveAllCompleted),
      concatLatestFrom(() => [this.store.select(selectLanguage)]),
      exhaustMap(([_, language]) =>
        this.dialog
          .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
            data: {
              title: 'Archive completed?',
              message: `All completed ${language.headline.items} and ${language.cascadingMessage.items} will be archived.`,
              confirmButtonText: 'Archive',
            },
          })
          .afterClosed()
          .pipe(
            filter(accepted => !!accepted),
            concatLatestFrom(_ => [this.store.select(headlinesSelectors.selectFilterByTeamId)]),
            exhaustMap(([, teamId]) =>
              this.headlineService.archiveAllCompleted(teamId).pipe(
                map(({ numOfArchivedHeadlines }) =>
                  HeadlinesStateActions.archiveAllCompletedSuccess({ numOfArchivedHeadlines })
                ),
                catchError((error: unknown) => of(HeadlinesStateActions.archiveAllCompletedFailure({ error })))
              )
            )
          )
      )
    )
  );

  notifyOnArchiveAllCompletedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.archiveAllCompletedSuccess),
      concatLatestFrom(() => [this.store.select(selectLanguage)]),
      map(([{ numOfArchivedHeadlines }, language]) =>
        NotificationActions.notify({
          message:
            numOfArchivedHeadlines > 0
              ? `Archived ${numOfArchivedHeadlines} ${language.headline.items} and/or ${language.cascadingMessage.items}.`
              : `No ${language.headline.items} or ${language.cascadingMessage.items} to archive.`,
          duration: 5000,
        })
      )
    )
  );

  broadcastMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.broadcastMessage),
        tap(({ message }) => {
          this.headlineService.broadcastMessage(message);
        })
      ),
    { dispatch: false }
  );

  startSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.downloadExcel),
      map(() => SpinnerActions.startPrimary({ source: 'Headlines' }))
    )
  );

  stopSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeadlinesStateActions.downloadExcelSuccess, HeadlinesStateActions.downloadExcelFailure),
      map(() => SpinnerActions.stopPrimary({ source: 'Headlines' }))
    )
  );
}
