import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { TerraIconModule, TerraInputBoolean } from '@ninety/terra';

export type NinetyChipSizes = 'small' | 'large';
export type NinetyChipColors = 'brand' | 'neutral' | 'positive' | 'negative';
@Component({
  selector: 'ninety-chip',
  exportAs: 'ninetyChip',
  standalone: true,
  imports: [CommonModule, TerraIconModule],
  templateUrl: './ninety-chip.component.html',
  styleUrls: ['./ninety-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NinetyChipComponent implements OnInit {
  /**
   * Reference to the wrapping div element where classes will be added to
   */
  @ViewChild('chip', { static: true }) private _chip!: ElementRef<HTMLElement>;

  /**
   * Size of the chip - small | large
   */
  @Input() get size(): NinetyChipSizes {
    return this._size;
  }
  set size(value: NinetyChipSizes) {
    this._removeClass('size', this._size);
    this._size = value;
    this._addClass('size', this._size);
    this._changeDetectorRef.markForCheck();
  }
  private _size: NinetyChipSizes = 'small';

  /**
   * Color of the chip - brand | neutral | positive | negative
   */
  @Input() get color(): NinetyChipColors {
    return this._color;
  }
  set color(value: NinetyChipColors) {
    this._removeClass('color', this._color);
    this._color = value;
    this._addClass('color', this._color);
    this._changeDetectorRef.markForCheck();
  }
  private _color: NinetyChipColors = 'neutral';

  /**
   * Determines whether the chip displays removable styles and emits remove events
   */
  @Input() get removable(): boolean {
    return this._removable;
  }
  set removable(value: TerraInputBoolean) {
    this._removable = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _removable = false;

  /**
   * Event emitted when a removable chip's X is clicked
   */
  @Output() readonly removed = new EventEmitter();

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef, private readonly _renderer: Renderer2) {}

  ngOnInit() {
    this._addClass('size', this.size);
    this._addClass('color', this.color);
  }

  /**
   * Called when "x" clicked on a removable chip
   */
  remove(): void {
    this.removed.emit();
  }

  /**
   * Helper function for adding proper size and color classes to the chip
   */
  private _addClass(modifier: string, value: string): void {
    if (this._chip) {
      this._renderer.addClass(this._chip.nativeElement, `ninety-chip-ds--${modifier}-${value}`);
    }
  }

  /**
   * Helper function to remove previously added size and color classes from the chip
   */
  private _removeClass(modifier: string, value: string): void {
    if (this._chip) {
      this._renderer.removeClass(this._chip.nativeElement, `ninety-chip-ds--${modifier}-${value}`);
    }
  }
}
