import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'terra-error',
  standalone: true,
  exportAs: 'terraError',
  imports: [CommonModule],
  template: '<ng-content />',
  styles: [
    `
      @use '../../../terra';

      :host {
        display: block;
        color: terra.color('red');
        @include terra.text('body');
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraErrorComponent {}
