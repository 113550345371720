<div
  [class.align-left]="alignLeft"
  [matTooltip]="userToolTipOverride ? userToolTipOverride : fullUserName"
  [matTooltipDisabled]="!fullUserName"
  (mouseenter)="removeBtnDisplay = 'inherit'"
  (mouseleave)="removeBtnDisplay = 'none'"
  matTooltipClass="ninety-tooltip"
  matTooltipPosition="above"
  ninetyStopPropagation
  [stopPropagation]="stopPropagation"
  class="d-print-none user-avatar-div"
  [ngClass]="{ 'transparent-avatar': transparent }">
  <ng-content></ng-content>

  <ninety-button
    *ngIf="showRemoveBtn"
    data-cy="user-avatar-remove-user-btn"
    icon
    class="remove-btn"
    [style.display]="removeBtnDisplay"
    (click)="removeUser.emit()"
    matTooltip="Remove {{ language.acChart.seatHolder }}"
    matTooltipPosition="below">
    <terra-icon icon="minus-circle" />
  </ninety-button>

  <div class="main-circle {{ size }}" [ngClass]="{ 'main-circle--hover': isHoverable }">
    <div class="overlay" *ngIf="pictureURL"></div>

    <!--
      In the edge case that the user picture exists, but cannot be loaded, we fallback to the user initials with the
      error handler
    -->
    <img *ngIf="pictureURL" [src]="pictureURL" (error)="pictureURL = null" [alt]="fullUserName + ' avatar image'" />

    <div
      attr.data-cy="user-avatar_user-initials-{{ userInitials }}"
      class="user-initials"
      [ngClass]="size"
      *ngIf="!pictureURL && userInitials">
      {{ userInitials }}
    </div>

    <div class="user-initials" [ngClass]="size" *ngIf="!pictureURL && !userInitials">
      <terra-icon icon="user" [size]="ICON_SIZES[size]" />
    </div>
  </div>
</div>
