export enum TodoMessageType {
  todo = 'todo',
  new = 'new-todo',
  newTodos = 'new-todos',
  done = 'done-todos',
  delete = 'delete-todo',
  unarchive = 'unarchive-todo',
  drop = 'list-drop-todo',
  sort = 'list-sort-todo',
  vote = 'vote-item-todo',
  fetch = 'fetch-object',
  deleteSeries = 'delete-series',
  attachmentUpload = 'todo-attachment-upload',
  attachmentRemove = 'todo-attachment-remove',
  attachmentReorder = 'todo-attachment-reorder',
}
