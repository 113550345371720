import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OneSchemaModule } from '@oneschema/angular';

import { DataImportRoutingModule } from '@ninety/data-import/data-import-routing.module';
import { DataImportComponent } from '@ninety/data-import/data-import.component';
import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/inputs/quick-filter/quick-filter.component';
import { ActionBarComponent } from '@ninety/ui/legacy/components/toolbars/action-bar/action-bar.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { environment } from '@ninety/ui/web/environments';

import { DataImportStateKey } from './_state/data-import-state.model';
import { dataImportStateReducer } from './_state/data-import-state.reducer';
import { DataImportStateEffects } from './_state/data-import.effects';
import { OneSchemaImportComponent } from './components/one-schema-import/one-schema-import.component';

@NgModule({
  declarations: [DataImportComponent, OneSchemaImportComponent],
  exports: [],
  imports: [
    CommonModule,
    DataImportRoutingModule,

    // FlexLayoutModule,
    ButtonComponent,
    CardModule,
    TerraIconModule,
    StoreModule.forFeature(DataImportStateKey, dataImportStateReducer),
    EffectsModule.forFeature([DataImportStateEffects]),
    OneSchemaModule.forRoot({
      manageDOM: false,
      clientId: 'fd43b55f-9558-4e16-a8ab-0eb32df133c4',
      templateKey: 'issues_template',
      styles: {
        width: '99.8%',
        height: 'calc(100vh - 260px)',
        border: 'none',
      },
      devMode: !environment.production,
      saveSession: false,
    }),
    MatTabsModule,
    LetDirective,
    ActionBarComponent,
    MatFormFieldModule,
    MatInputModule,
    NinetyTooltipDirective,
    PushPipe,
    QuickFilterComponent,
  ],
})
export class DataImportModule {}
