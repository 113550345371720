import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ProcessLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-process-language',
  templateUrl: './process-language.component.html',
  styles: [],
})
export class ProcessLanguageComponent {
  @Input() process: ProcessLanguage;
  @Input() resetLanguage: ProcessLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof ProcessLanguage>();
}
