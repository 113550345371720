import { SortDirection } from '../enums/sort-direction';
import { TodoSortField } from '../todos/todo-sort-field';

export class OrdinalOrUserOrdinalUpdate {
  _id: string;
  ordinal?: number;
  userOrdinal?: number;

  constructor(id, ordinal, prop: 'ordinal' | 'userOrdinal') {
    this._id = id;
    this[prop] = ordinal;
  }
}

export class OrdinalUpdateWithSort {
  models: OrdinalOrUserOrdinalUpdate[];
  sortField?: TodoSortField; // | IssueSortField..
  sortDirection?: SortDirection;

  constructor(sortField?, sortDirection?) {
    this.sortField = sortField;
    this.sortDirection = sortDirection;
  }
}
