import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TerraIconModule } from '@ninety/terra';
import { ImageService } from '@ninety/ui/legacy/core/services/image.service';
import { EllipsifyDirective } from '@ninety/ui/legacy/shared/directives/ellipsify.directive';
import { Theme } from '@ninety/ui/legacy/shared/models/enums/theme';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

@Component({
  selector: 'ninety-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule, SharedModule, TerraIconModule],
})
export class CompanyLogoComponent {
  @Input() isSideNavExpanded: boolean;
  @Input() companyName: string;

  /**
   * Optional - The image src to load
   */
  @Input() userLogoSrc?: string;

  /**
   * This component is either rendered in the sidenav navigation pane or in the hamburger menu on smaller screens
   */
  @Input() inSidenav: boolean;

  /**
   * When used in the hamburger menu, the drop-down icon is not needed - the parent is a mat-select which already
   * provides one. When used in the sidebar, this component will create its own.
   */
  @Input() showDropDownIcon: boolean;

  @Input() theme: Theme;
  Theme = Theme;

  @ViewChild('imgLogo', { static: false }) logo: ElementRef;
  @ViewChild(EllipsifyDirective) ellipsifyDirective: EllipsifyDirective;

  /**
   * A "square" logo is set by aspect-constant resizing - It's width fills available space.
   * A "rectangle" logo is used instead of the company name. It fills available width until a max-height is met.
   */
  logoShape: 'square' | 'rectangle';

  imgLoaded = false;

  constructor(private imageService: ImageService) {}

  imageLoaded() {
    // Img always loaded after content init, no need to check the @ViewChild to see if it exists
    const img = this.logo.nativeElement as HTMLImageElement;
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    if (!width || !height) {
      this.userLogoSrc = undefined;
      return;
    }

    this.logoShape = this.imageService.logoIsSquare(width, height) ? 'square' : 'rectangle';
    this.imgLoaded = true;
  }
}
