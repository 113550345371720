import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { ButtonRowComponent } from '@ninety/ui/legacy/components/buttons/button-row/button-row.component';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { MeetingAgenda } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { StartMeetingData } from '@ninety/ui/legacy/shared/models/meetings/start-meeting-data';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

@Component({
  selector: 'ninety-meeting-start-dialog',
  standalone: true,
  templateUrl: './meeting-start-dialog.component.html',
  styleUrls: ['./meeting-start-dialog.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    SharedModule,
    ButtonRowComponent,
    ButtonComponent,
  ],
})
export class MeetingStartDialogComponent implements OnInit, OnDestroy {
  MeetingType = MeetingType;
  selectedTeam: Team;
  selectedAgendaId: MeetingType | string;
  data = new StartMeetingData();

  constructor(
    public dialogRef: MatDialogRef<MeetingStartDialogComponent>,
    public teamService: TeamService,
    private filterService: FilterService,
    private meetingService: MeetingService,
    public stateService: StateService
  ) {}

  ngOnInit() {
    this.selectTeam(this.filterService.selectedTeam$.getValue());
    this.selectedAgendaId = this.stateService.meetingType;
    this.data.type = this.stateService.meetingType;
  }

  ngOnDestroy(): void {
    (document.activeElement as HTMLButtonElement).blur();
  }

  selectTeam(team: Team): void {
    this.selectedTeam = team;
    this.data.teamId = team._id;
  }

  selectMeetingType(type: MeetingType): void {
    if (!type || !Object.values(MeetingType).includes(type)) return;
    this.data.type = type;
    this.data.agendaId = null;
  }

  selectCustomAgenda(agenda: MeetingAgenda): void {
    this.data.type = MeetingType.custom;
    this.data.agendaId = agenda._id;
  }

  startMeeting() {
    this.meetingService.startingMeeting = true;
    this.filterService.selectTeam$.next(this.selectedTeam);
    this.stateService.meetingType = this.data.type;
    this.stateService.meetingIsAnnual =
      this.data.type === MeetingType.annualDayOne || this.data.type === MeetingType.annualDayTwo;
    this.dialogRef.close(this.data);
  }

  close(res): void {
    this.dialogRef.close(res);
  }

  teamsAreSame(option, value): boolean {
    return option._id === value._id;
  }
}
