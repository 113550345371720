import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import ObjectId from 'bson-objectid';

export class NinetyValidators {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { noWhiteSpace: true };
    }

    return null;
  }
  static preventOnlyWhiteSpace(control: FormControl): ValidationErrors | null {
    return !(control.value || '').trim().length ? { onlyWhiteSpace: true } : null;
  }

  static isObjectId(control: AbstractControl): ValidationErrors | null {
    return ObjectId.isValid(control.value) ? null : { isObjectId: true };
  }

  static onlyAlphaNumeric(control: AbstractControl): ValidationErrors | null {
    return /^\w*$/.test(control.value) ? null : { onlyAlphaNumeric: true };
  }
}
