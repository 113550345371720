import { environment } from '@ninety/ui/web/environments';

export const PlanIds: Readonly<{
  TXFREE: string;
  TXPREM01: string;
  TXPREM02: string;
  TXPREM03: string;
  NINETY02: string;
  NINETY03: string;
  MONTHLY_OLD: string;
  ANNUAL_OLD: string;
  MONTHLY: string;
  ANNUAL: string;
}> = {
  TXFREE: 'TXFREE',
  TXPREM01: 'TXPREM01',
  TXPREM02: 'TXPREM02',
  TXPREM03: 'TXPREM03',
  NINETY02: 'ninety02',
  NINETY03: 'ninety03',
  MONTHLY_OLD: 'plan_ninetymonthly',
  ANNUAL_OLD: 'plan_ninetyannual',
  MONTHLY: environment.monthlyPrice,
  ANNUAL: environment.annualPrice,
};
