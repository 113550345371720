import { PartnerGroup } from './_shared/partner-group';
import { Stats } from './_shared/stats';

export class Customer {
  customer_key?: string; // the company _id
  partner_key?: string; // the partner person's _id
  name: string;
  email: string;

  // internal key
  key?: string;

  // other partner stack properties
  created_at?: number;
  updated_at?: number;
  joined_at?: number;
  group?: PartnerGroup;
  manager_key?: string;
  meta?: object;
  stats?: Stats;
  archived?: boolean;
  has_paid?: boolean;
  test?: boolean;
  provider_key?: string;

  constructor(info: { email: string; partner_key: string; customer_key?: string; name?: string }) {
    Object.assign(this, info);
  }
}
