<ng-container
  *ngrxLet="{
    companyUser: currentCompanyUser$,
    allCompanyUsers: allCompanyUsers$,
    activeCompanyUsers: activeCompanyUsers$,
    inactiveCompanyUsers: inactiveCompanyUsers$,
  } as vm">
  <!-- Need duplicated template as you cannot conditionally apply a directive -->

  <!-- Shown as a part of the toolbar. Considered a "nested" Angular Material Menu -->
  <button
    data-cy="company-menu_company-button"
    *ngIf="menuItem; else notMenuItem"
    mat-menu-item
    [matMenuTriggerFor]="companyMenu"
    [disabled]="!vm.allCompanyUsers?.length">
    <ninety-company-logo
      data-cy="company-menu_company-logo"
      [userLogoSrc]="vm.companyUser?.logoUrl"
      [companyName]="vm.companyUser?.companyName"
      [isSideNavExpanded]="false"
      [inSidenav]="false"
      [showDropDownIcon]="false">
    </ninety-company-logo>
  </button>

  <!-- Shown when collapsed as a part of the popup menu -->
  <ng-template #notMenuItem>
    <button
      [class.v2-adjust]="v2Sidenav && !isSidenavExpanded"
      data-cy="company-menu_company-button-expanded-view"
      class="company-button"
      mat-button
      [matMenuTriggerFor]="companyMenu"
      [disabled]="!vm.allCompanyUsers?.length">
      <ninety-company-logo
        attr.data-cy="company-menu_company-name-{{ vm.companyUser?.companyName }}"
        [userLogoSrc]="vm.companyUser?.logoUrl"
        [companyName]="vm.companyUser?.companyName"
        [isSideNavExpanded]="isSidenavExpanded"
        [inSidenav]="true"
        [showDropDownIcon]="isSidenavExpanded && vm.allCompanyUsers?.length > 0"
        [theme]="theme">
      </ninety-company-logo>
    </button>
  </ng-template>

  <mat-menu #companyMenu="matMenu" class="company-menu">
    <ng-template matMenuContent>
      <div class="company-search">
        <mat-form-field class="company-search-field" [class.hidden]="vm.allCompanyUsers.length <= 5">
          <input
            data-cy="company-menu_company-search-input"
            #companySearchInput
            matInput
            placeholder="Search companies"
            value=""
            name="company-search"
            ninetyStopPropagation
            aria-label="company search"
            type="text"
            (change)="companySearchInput.focus()" />
        </mat-form-field>
      </div>
      <div data-cy="company-menu_company-select" class="company-select" ninetyStopPropagation>
        <button
          mat-menu-item
          attr.data-cy="company-menu_company-select-option-{{ c.companyName }}"
          *ngFor="let c of vm.activeCompanyUsers | searchCompanyFilter : companySearchInput.value; trackBy: trackById"
          (click)="sessionService.switchCompany(c)"
          ninetyStopPropagation>
          <span>{{ c.companyName }}</span>
          <img [src]="c?.logoUrl" alt="company logo" *ngIf="c?.logoUrl" class="company-logo" loading="lazy" />
        </button>

        <terra-divider />

        <button
          mat-menu-item
          class="muted"
          attr.data-cy="company-menu_company-select-option-{{ c.companyName }}"
          *ngFor="let c of vm.inactiveCompanyUsers | searchCompanyFilter : companySearchInput.value; trackBy: trackById"
          (click)="sessionService.switchCompany(c)"
          ninetyStopPropagation>
          <span>{{ c.companyName }} <small>(Inactive)</small></span>
          <img [src]="c?.logoUrl" alt="company logo" *ngIf="c?.logoUrl" class="company-logo" loading="lazy" />
        </button>
      </div>
    </ng-template>
  </mat-menu>
</ng-container>
