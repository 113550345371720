import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoDeleOptions } from '@ninety/ui/legacy/shared/models/todos/todo-delete-options';

@Component({
  selector: 'ninety-repeat-todo-delete-dialog',
  templateUrl: './repeat-todo-delete-dialog.component.html',
  styleUrls: ['./repeat-todo-delete-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialMdcModule, FormsModule, ButtonComponent],
})
export class RepeatTodoDeleteDialogComponent {
  TodoDeleOptions = TodoDeleOptions;
  protected deleteOption: TodoDeleOptions = TodoDeleOptions.item;

  constructor(
    public dialogRef: MatDialogRef<RepeatTodoDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { todo: Todo },
    public stateService: StateService
  ) {}

  delete(): void {
    this.dialogRef.close({ option: this.deleteOption, todo: this.data.todo });
  }
}
