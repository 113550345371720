import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { TerraIconModule } from '@ninety/terra';

import { StopPropagationDirective } from '../../../directives/stop-propagation.directive';
import { ResetBtnComponent } from '../reset-btn/reset-btn.component';

import { CascadingMessageLanguageComponent } from './cascading-message-language/cascading-message-language.component';
import { DirectoryLanguageComponent } from './directory-language/directory-language.component';
import { HeadlineLanguageComponent } from './headline-language/headline-language.component';
import { IssueLanguageComponent } from './issue-language/issue-language.component';
import { MasteryLanguageComponent } from './mastery-language/mastery-language.component';
import { MeasurableLanguageComponent } from './measurable-language/measurable-language.component';
import { MeetingLanguageComponent } from './meeting-language/meeting-language.component';
import { MilestoneLanguageComponent } from './milestone-language/milestone-language.component';
import { MyNinetyLanguageComponent } from './my-ninety-language/my-ninety-language.component';
import { OneOnOneLanguageComponent } from './one-on-one-language/one-on-one-language.component';
import { ProcessLanguageComponent } from './process-language/process-language.component';
import { ResponsibilitiesLanguageComponent } from './responsibilities-language/responsibilities-language.component';
import { RockLanguageComponent } from './rock-language/rock-language.component';
import { ScorecardLanguageComponent } from './scorecard-language/scorecard-language.component';
import { SurveyLanguageComponent } from './survey-language/survey-language.component';
import { TodoLanguageComponent } from './todo-language/todo-language.component';
import { VisionLanguageComponent } from './vision-language/vision-language.component';

const languageComponents = [
  CascadingMessageLanguageComponent,
  DirectoryLanguageComponent,
  HeadlineLanguageComponent,
  IssueLanguageComponent,
  MasteryLanguageComponent,
  MeasurableLanguageComponent,
  MeetingLanguageComponent,
  MilestoneLanguageComponent,
  MyNinetyLanguageComponent,
  OneOnOneLanguageComponent,
  ProcessLanguageComponent,
  ResponsibilitiesLanguageComponent,
  RockLanguageComponent,
  ScorecardLanguageComponent,
  SurveyLanguageComponent,
  TodoLanguageComponent,
  VisionLanguageComponent,
];

@NgModule({
  declarations: languageComponents,
  exports: languageComponents,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ResetBtnComponent,
    StopPropagationDirective,
    TerraIconModule,
  ],
})
export class LanguageSectionsModule {}
