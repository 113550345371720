import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, tap } from 'rxjs';

import { GoogleTaskList } from '@ninety/settings/user/integration/_api/models/google-task-list';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';

import { GoogleTask } from './models/google-task';

@Injectable()
export class IntegrationService {
  readonly integrationApi = 'api.qa1.90srv.com/Integration';

  constructor(private http: HttpClient, private errorService: ErrorService, private store: Store) {}

  getGoogleTaskList(): Observable<GoogleTaskList[]> {
    const request = this.http.get<GoogleTaskList[]>(`${this.integrationApi}/GoogleTaskLists`).pipe(
      tap(() => this.store.dispatch(SpinnerActions.stopPrimary({ source: 'Google Task List' }))),
      catchError((e: unknown) => this.errorService.notify(e, `Could not retrieve Google task lists`))
    );
    return request;
  }

  getGoogleAuthUrl(): Observable<string> {
    return this.http
      .get<string>(`${this.integrationApi}/GoogleAuthURL`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not update user.  Please try again.')));
  }

  exchangeGoogleAuthCode(code: string, state: string): Observable<GoogleTaskList> {
    return this.http
      .get<GoogleTaskList>(`${this.integrationApi}/GoogleAuthReturn?code=${code}&state=${state}`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not update user.  Please try again.')));
  }

  createTask(todoId: string): Observable<GoogleTask> {
    return this.http.get<GoogleTask>(`${this.integrationApi}/CreateTask/${todoId}`).pipe(
      tap(() => this.store.dispatch(SpinnerActions.stopPrimary({ source: 'Create Google Task' }))),
      catchError((e: unknown) =>
        this.errorService.notify(e, 'Integration failed. Click to retry or check Integration Settings.')
      )
    );
  }

  syncTasks(teamId: string): Observable<GoogleTask> {
    return this.http.get<GoogleTask>(`${this.integrationApi}/SyncTasks/${teamId}`).pipe(
      tap(() => this.store.dispatch(SpinnerActions.stopPrimary({ source: 'Sync Google Task' }))),
      catchError((e: unknown) => this.errorService.notify(e, 'An error occurred while syncing tasks.'))
    );
  }

  syncPersonalTasks(): Observable<GoogleTask> {
    return this.http.get<GoogleTask>(`${this.integrationApi}/SyncPersonalTasks`).pipe(
      tap(() => this.store.dispatch(SpinnerActions.stopPrimary({ source: 'Sync Google Task' }))),
      catchError((e: unknown) => this.errorService.notify(e, 'An error occurred while syncing tasks.'))
    );
  }
}
