import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SkeletonComponent } from '../skeleton.component';
import { SkeletonConstants, SkeletonPropsArray } from '../types';

import { SkeletonListComponent } from './skeleton-list.component';

/**
 * Skeleton Loader configured for a Widget. Renders a widgets title and table. The table skeleton can be configured to
 * render a specific number of rows.
 */
@Component({
  selector: 'ninety-widget-skeleton',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="widget-skeleton">
      <ninety-skeleton-list [skeletonProps]="skeletonList"></ninety-skeleton-list>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .widget-skeleton {
        flex: 1;
        // TODO the widgets themselves should configure their own padding
        padding: 15px 24px 18px 24px;
      }
    `,
  ],
  imports: [SkeletonComponent, SkeletonListComponent, CommonModule],
})
export class WidgetSkeletonComponent implements OnChanges {
  /** The number of rows to render in the table body. */
  @Input() tableBodyCount = SkeletonConstants.INIT_COUNT;

  /** Array passed to the SkeletonListComponent. */
  protected skeletonList: SkeletonPropsArray = WidgetSkeletonComponent.createSkeletonListProps(this.tableBodyCount);

  ngOnChanges(changes: SimpleChanges) {
    // Only one input, assume any change is to the tableBodyCount
    const count = changes.tableBodyCount?.currentValue ?? SkeletonConstants.INIT_COUNT;
    this.skeletonList = WidgetSkeletonComponent.createSkeletonListProps(count);
  }

  /**
   * A small width title, followed by a full width table made up of a header, body and footer. Body row count is equal
   * to the parameter count.
   */
  public static createSkeletonListProps(count: number): SkeletonPropsArray {
    return [
      { theme: { width: '150px', height: '55px', 'margin-bottom': '27px' } },
      { theme: { height: '24px', 'margin-bottom': '12px' } },
      { theme: { height: '45px', 'margin-bottom': '8px' }, count },
      { theme: { height: '24px', 'margin-top': '4px' } },
    ];
  }

  /** Calculates a reasonable row count for a grid widget based on the height of the widget. */
  public static calculateRowCountFromGridWidgetHeight(h: number) {
    if (!h || h <= 1) return 1;
    return (h - 1) * 4;
  }
}
