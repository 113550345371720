import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';
import { UserWithSeats } from '@ninety/ui/legacy/shared/models/user/user-with-seat.model';
import { CoreValue } from '@ninety/ui/legacy/shared/models/vto/core-value';

export const FitCheckComponentActions = createActionGroup({
  source: 'Fit Check Component',
  events: {
    Initialized: emptyProps(),
    'User Selected': props<{ selectedUser: User }>(),
    'Create Review Dialog Opened': props<{ user: User }>(),
    'Pager Changed': props<{ pageIndex: number; pageSize: number }>(),
  },
});

export const FitCheckEffectActions = createActionGroup({
  source: 'Fit Check Effects',
  events: {
    'Set Current User': props<{
      currentUser: User;
      isLiteUser: boolean;
      isObserver: boolean;
      language: CustomLanguage;
    }>(),
    'Users and Core Values Requested': emptyProps(),
    'Users and Core Values Received': props<{
      users: PagedResponse<UserWithSeats>;
      coreValues: CoreValue[];
    }>(),
    'Unable to Retreive Users and Core Values': props<{ error: any; customMessage: string }>(),
    'User Deselected': emptyProps(),
    'Detail View Opened': props<{ user: User }>(),
  },
});

export const FitCheckFilterEffectActions = createActionGroup({
  source: 'Fit Check Filter Effects',
  events: {
    Refreshed: emptyProps(),
    'Search Text Updated': props<{ searchText: string }>(),
    'Selected User Filter Updated': props<{ selectedUserId: string }>(),
    'Selected Team Filter Updated': props<{ selectedTeamId: string }>(),
    'Users and Core Values Requested': emptyProps(),
    'Is Fetching Set': props<{ isFetchingData: boolean }>(),
  },
});
