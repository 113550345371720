import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ninety-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-content select="[header]"></ng-content>

    <!--
        As this is the scrollable element in cards, attach cdkScrollable so material components (ie: drag & drop)
        know which element is scrolling.
      -->
    <div class="ninety-card-content" cdkScrollable>
      <ng-content></ng-content>
    </div>

    <ng-content select="[footer]"></ng-content>
  `,
  styles: [
    `
      @use 'terra';

      :host {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;
        max-height: 100%;

        box-sizing: border-box;
        @include terra.radius(small);
        overflow: hidden;
        background-color: terra.color('white');
        @include terra.elevation(3);

        .ninety-card-content {
          overflow-x: auto;
          overflow-y: auto;
        }

        &.top-border {
          border-top: 5px solid terra.color('border', 'heavy');
          @include terra.radius(medium);
        }

        &.hide-overflow {
          .ninety-card-content {
            overflow-x: hidden;
            overflow-y: hidden;
          }
        }
      }
    `,
  ],
})
export class CardComponent {
  @HostBinding('class.ninety-card-new') get cardStyles() {
    return true;
  }
}
