import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraIconModule } from '../terra-icon';

import { TerraAvatarComponent } from './terra-avatar.component';

@NgModule({
  imports: [CommonModule, TerraAvatarComponent, TerraIconModule],
  exports: [TerraAvatarComponent],
})
export class TerraAvatarModule {}
