<mat-card
  [ngClass]="{
    'negative-metric': threshold && positiveMetric === false,
    'positive-metric': threshold && positiveMetric === true
  }">
  <h3 [matTooltip]="titleTooltip" matTooltipPosition="above" matTooltipClass="ninety-tooltip">{{ title }}</h3>
  <div class="metric" [ngClass]="{ disabled: disabledStyle }">
    {{ metric }}{{ unit }}
    <span
      *ngIf="comparedMetric !== undefined && comparedMetric !== null"
      class="compare"
      [ngClass]="{ 'negative-metric': positiveMetric === false, 'positive-metric': positiveMetric === true }"
      [matTooltip]="comparedMetricTooltip"
      matTooltipPosition="above"
      matTooltipClass="ninety-tooltip">
      <terra-icon [icon]="positiveMetric ? 'arrow-up' : 'arrow-down'" size="12" />
      {{ comparedMetric }}{{ unit }}
    </span>
  </div>
</mat-card>
