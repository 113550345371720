import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AddClassBasedOnWidthDirective } from './add-class-based-on-width.directive';
import { ElementResizeAwareDirective } from './element-resize-aware.directive';
import {
  BreakpointObserverConfigFactory,
  BreakpointObserverConfigFactoryForTesting,
} from './services/breakpoint-observer-config-factory.service';
import { ResizeObserverService, ResizeObserverServiceForTests } from './services/resize-observer.service';

/**
 * This module should only be used in the shared module and while testing. Uses of the directives should be done through
 * standalone imports.
 */
@NgModule({
  imports: [CommonModule, ElementResizeAwareDirective, AddClassBasedOnWidthDirective],
  exports: [ElementResizeAwareDirective, AddClassBasedOnWidthDirective],
})
export class ElementResizeAwareModule {
  static forTests(): ModuleWithProviders<ElementResizeAwareModule> {
    return {
      ngModule: ElementResizeAwareModule,
      providers: [
        { provide: ResizeObserverService, useClass: ResizeObserverServiceForTests },
        { provide: BreakpointObserverConfigFactory, useClass: BreakpointObserverConfigFactoryForTesting },
      ],
    };
  }
}
