import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { MenuItemData } from '../../menu/menu.component';
import { MenuRowDirective } from '../menu-row.directive';

@Component({
  selector: 'ninety-menu-item-large',
  templateUrl: './large-menu.item.component.html',
  styleUrls: ['./large-menu-item.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TerraIconModule, MenuRowDirective],
})
export class MenuItemLargeComponent {
  @Input() item: MenuItemData;
}
