/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import {
  AssessmentIntercomActions,
  DetailViewIntercomActions,
  MainLayoutIntercomActions,
  NavMenuIntercomActions,
  ReportViewerIntercomActions,
} from './intercom-state.actions';

@Injectable()
export class IntercomStateEffects {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly window: any = window;
  constructor(private actions$: Actions) {}

  showIntercomLauncher$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssessmentIntercomActions.showLauncher,
          ReportViewerIntercomActions.showIntercom,
          DetailViewIntercomActions.showIntercom,
          MainLayoutIntercomActions.showIntercom
        ),
        tap(_ => this._intercom('update', { hide_default_launcher: false }))
      ),
    { dispatch: false }
  );

  hideIntercomLauncher$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssessmentIntercomActions.hideLauncher,
          ReportViewerIntercomActions.hideIntercom,
          DetailViewIntercomActions.hideIntercom,
          MainLayoutIntercomActions.hideIntercom
        ),
        tap(_ => this._intercom('update', { hide_default_launcher: true }))
      ),
    { dispatch: false }
  );

  showIntercomMessenger$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavMenuIntercomActions.showMessenger),
        tap(_ => this._intercom('show'))
      ),
    { dispatch: false }
  );

  /** More Info: https://developers.intercom.com/installing-intercom/web/attributes-objects/ */
  private _intercom(action: string, config?: Record<string, boolean | string | number>) {
    // Sometimes window.Intercom doesn't exist yet or at all.
    if (!this.window?.Intercom) return;
    else if (!config) this.window.Intercom(action);
    else this.window.Intercom(action, config);
  }
}
