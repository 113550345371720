import { HttpParams } from '@angular/common/http';

export class QuerySeatParamsDto {
  public static IDENTITY: Readonly<QuerySeatParamsDto> = new QuerySeatParamsDto();

  includeSeatHolders = false;
  includeAccountabilities = false;
  includeUserAndPerson = false;

  constructor(partial?: Partial<QuerySeatParamsDto>) {
    Object.assign(this, partial);
  }

  toHttpParams(): HttpParams {
    const filtered = Object.entries(this).reduce((acc: Record<string, boolean>, [key, value]) => {
      if (QuerySeatParamsDto.IDENTITY[key] !== value) acc[key] = value;
      return acc;
    }, {});

    return new HttpParams({ fromObject: filtered });
  }
}
