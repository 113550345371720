<h1 mat-dialog-title>Print Agenda</h1>
<div class="header">Please select what agenda you would like to be printed</div>
<ninety-button flat icon class="close-dialog-btn" (click)="dialogRef.close()">
  <terra-icon icon="x" />
</ninety-button>
<terra-divider height="short" />
<mat-dialog-content>
  <div class="content">
    <div *ngrxLet="language$ as language">
      <div class="menu-item">
        <terra-checkbox
          class="project-checkbox"
          ninetyStopPropagation
          aria-labelledby="meeting-level-ten-label"
          (change)="updateSelectedMeetingType(!!$event, MeetingAgendaType.weekly)"
          [ngModel]="selectedMeetingType === MeetingAgendaType.weekly"></terra-checkbox>
        <terra-icon icon="calendar" />
        <span id="meeting-level-ten-label" class="flex-2">{{ language.meeting.levelTen }}</span>
      </div>
      <div class="menu-item">
        <terra-checkbox
          class="project-checkbox"
          ninetyStopPropagation
          aria-labelledby="meeting-quarterly-session-label"
          (change)="updateSelectedMeetingType(!!$event, MeetingAgendaType.quarterly)"
          [ngModel]="selectedMeetingType === MeetingAgendaType.quarterly"></terra-checkbox>
        <terra-icon icon="calendar" />
        <span id="meeting-quarterly-session-label" class="flex-2">{{ language.meeting.quarterlySession }}</span>
      </div>
      <div class="menu-item">
        <terra-checkbox
          class="project-checkbox"
          ninetyStopPropagation
          aria-labelledby="meeting-annual-session-label"
          (change)="updateSelectedMeetingType(!!$event, MeetingAgendaType.annualDayOne)"
          [ngModel]="selectedMeetingType === MeetingAgendaType.annualDayOne">
        </terra-checkbox>
        <terra-icon icon="calendar" />
        <span id="meeting-annual-session-label" class="flex-2">{{ language.meeting.annualSession }}</span>
      </div>
      <div *ngFor="let customMeetingAgenda of customMeetingSettings$ | async; let i = index">
        <div class="menu-item custom-agenda">
          <terra-checkbox
            class="project-checkbox"
            ninetyStopPropagation
            [aria-labelledby]="'custom-meeting-agenda-label' + i"
            (change)="updateSelectedMeetingType(!!$event, MeetingAgendaType.custom, customMeetingAgenda.id)"
            [ngModel]="selectedCustomAgendaId === customMeetingAgenda.id">
          </terra-checkbox>
          <terra-icon icon="calendar" />
          <span [id]="'custom-meeting-agenda-label' + i" class="flex-2">{{ customMeetingAgenda.displayText }}</span>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<terra-divider height="short" />
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <ninety-button stroked mat-dialog-close>Cancel</ninety-button>
  <ninety-button
    stroked
    primary
    (click)="print()"
    mat-dialog-close
    [disabled]="!selectedMeetingType"
    ninetyStopPropagation
    class="print-agenda-button"
    aria-label="Print">
    <terra-icon icon="file-print" aria-hidden="true" /> Print
  </ninety-button>
</mat-dialog-actions>
