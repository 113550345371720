import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Represents a directive that sets focus on an element when the `ninetyFocused` input changes to true.
 *
 * @example
 * // Usage in template:
 * // <input [ninetyFocused]="true">
 */
@Directive({
  selector: '[ninetyFocused]',
  standalone: true,
})
export class FocusedDirective implements OnChanges {
  @Input() ninetyFocused: boolean;

  constructor(private host: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    // Single input, assume any change is here
    const { currentValue, previousValue } = changes.ninetyFocused;
    // Set focus on change of truthy-ness (null, false, undefined are all false, so null > false is not a change)
    if (previousValue != currentValue && currentValue) {
      setTimeout(() => {
        const element: HTMLElement = this.host.nativeElement;
        element.focus();
      });
    }
  }
}
