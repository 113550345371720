import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

import { AccountStatus } from '../models/company/company';
import { CustomerCompany } from '../models/partner-hub/customer-company';

@Pipe({
  name: 'filterReferredCompanies',
})
export class FilterReferredCompaniesPipe implements PipeTransform {
  transform(companies: CustomerCompany[], searchText: string, filterStatus: AccountStatus | 'all'): CustomerCompany[] {
    return FilterReferredCompaniesPipe.transform(companies, searchText, filterStatus);
  }

  static transform(
    companies: CustomerCompany[],
    searchText: string,
    filterStatus: AccountStatus | 'all'
  ): CustomerCompany[] {
    if (!companies || (!searchText && filterStatus === 'all')) return companies;
    const filtered = filterStatus === 'all' ? companies : companies.filter(c => c.accountStatus === filterStatus);

    if (!searchText) return filtered;

    const predicate = searchText?.toLowerCase();
    return filter(filtered, c => {
      if (c.name.toLowerCase().includes(predicate)) return true;
      if (c.customer?.email.toLowerCase().includes(predicate)) return true;
    });
  }
}
