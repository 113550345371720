import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { UsersStateActions } from '../users/users-state.actions';

import { UserListModel } from './api/user-list.model';
import { userListStateActions } from './user-list-actions';

@Injectable()
export class UserListStateEffects {
  constructor(private action$: Actions) {}

  // Temporary until we have a new User List API
  $getUserListSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(UsersStateActions.loadAllUsers),
      map(({ users }) => {
        const userList: UserListModel[] = users.map(t => ({
          _id: t._id,
          firstName: t.metadata.name.first,
          lastName: t.metadata.name.last,
          name: t.fullName,
          avatarUrl: t.metadata?.picture?.url,
          title: t.title,
          teamIds: t.teams.map(x => x._id),
          isDeleted: t.deleted,
        }));
        return userListStateActions.getUserListSuccess({ users: userList });
      })
    )
  );
}
