<ng-container>
  <div class="dialog-top">
    <div class="flex flex-between">
      <div class="title" data-cy="address-validator_modal-title">Upgrade to a paid subscription</div>
      <ninety-button flat icon mat-dialog-close class="close" (click)="this.dialogRef.close()">
        <terra-icon icon="x" />
      </ninety-button>
    </div>
    <p>
      You are subscribing to the Monthly Ninety Standard Plan. You will be charged
      <br />
      <span class="charges" *ngrxLet="invoice$ as invoice">
        {{ invoice.total / 100 | currency }} a month plus tax.</span
      >
      To continue, please enter your billing address.
    </p>
  </div>
  <mat-card class="mat-elevation-z0">
    <div class="address-dialog-modal">
      <form [formGroup]="form" id="billing-address-entry-form" autocomplete="on" (ngSubmit)="onAddressComplete()">
        <span>
          <mat-label>Country</mat-label>
          <br />
          <mat-form-field appearance="outline" class="full-width">
            <mat-select
              data-cy="address-validator_country-dropdown"
              name="country"
              id="country"
              appearance="outline"
              formControlName="country"
              (selectionChange)="onCountryChange($event)"
              autocomplete="country"
              type="text">
              <mat-option
                attr.data-cy="address-validator_country-{{ country.text }}"
                *ngFor="let country of countries"
                [value]="country.value">
                {{ country.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <ng-container>
          <span>
            <mat-label>Address</mat-label>
            <br />
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                data-cy="address-validator_address-input-field"
                name="address1"
                type="text"
                autocomplete="address-line1"
                formControlName="line1" />
              <mat-hint>Street address, P.O. box, etc.</mat-hint>
            </mat-form-field>
          </span>
          <span>
            <mat-label>Address2</mat-label>
            <br />
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                data-cy="address-validator_address2-input-field"
                name="address2"
                type="text"
                autocomplete="address-line2"
                formControlName="line2" />
              <mat-hint>Apartment, Suite, etc.</mat-hint>
            </mat-form-field>
          </span>
          <div class="row">
            <span class="col" style="padding-right: 10px">
              <mat-label>City</mat-label>
              <br />
              <mat-form-field appearance="outline" floatLabel="always">
                <input
                  matInput
                  data-cy="address-validator_city-field"
                  formControlName="city"
                  name="city"
                  type="text"
                  autocomplete="home city" />
              </mat-form-field>
            </span>
            <span class="col">
              <mat-label *ngIf="selectedCountry === 'US'">State</mat-label>
              <mat-label *ngIf="selectedCountry === 'CA'">Province</mat-label>
              <mat-label *ngIf="selectedCountry !== 'US' && selectedCountry !== 'CA'"> Region/Province</mat-label>
              <br />
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-select
                  data-cy="address-validator_state-dropdown"
                  name="state"
                  id="state"
                  appearance="outline"
                  formControlName="state"
                  autocomplete="address-level1"
                  type="text">
                  <mat-option
                    attr.data-cy="address-validator_state-{{ state.text }}"
                    *ngFor="let state of countryStatesProvinces"
                    [value]="state.value">
                    {{ state.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <span>
            <mat-label *ngIf="selectedCountry === 'US'">Zip code</mat-label>
            <mat-label *ngIf="selectedCountry !== 'US'">Postal code</mat-label>
            <br />
            <mat-form-field appearance="outline" floatLabel="always" style="width: 49%">
              <input
                matInput
                data-cy="address-validator_postalCode-field"
                type="text"
                name="postalCode"
                autocomplete="postal-code"
                formControlName="postalCode" />
            </mat-form-field>
            <terra-divider height="short" />
          </span>
          <span>
            <mat-label>Billing email address</mat-label>
            <br />
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                data-cy="address-validator_email-field"
                name="email"
                type="email"
                autocomplete="email"
                formControlName="email" />
            </mat-form-field>
          </span>
          <span style="display: block">
            <ninety-button
              data-cy="address-validator_next-button"
              filled
              primary
              [disabled]="form.invalid"
              type="submit">
              Next
            </ninety-button>
          </span>
        </ng-container>
      </form>
    </div>
  </mat-card>
</ng-container>
