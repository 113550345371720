import { createReducer, on } from '@ngrx/store';

import { MainSidebarActions } from './main-sidebar-state.actions';
import { initialMainSidebarState, MainSidebarStateModel } from './main-sidebar-state.model';

type State = MainSidebarStateModel;

export const MainSidebarReducer = createReducer(
  initialMainSidebarState,
  on(MainSidebarActions.expand, (state): State => ({ ...state, isExpanded: true })),
  on(MainSidebarActions.collapse, (state): State => ({ ...state, isExpanded: false })),
  on(MainSidebarActions.show, (state): State => ({ ...state, isVisible: true })),
  on(MainSidebarActions.hide, (state): State => ({ ...state, isVisible: false }))
);
