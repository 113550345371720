import { createActionGroup, emptyProps } from '@ngrx/store';

export const AssessmentIntercomActions = createActionGroup({
  source: 'Assessment',
  events: {
    'Show Launcher': emptyProps(),
    'Hide Launcher': emptyProps(),
  },
});

export const ReportViewerIntercomActions = createActionGroup({
  source: 'ReportViewer',
  events: {
    'Show Intercom': emptyProps(),
    'Hide Intercom': emptyProps(),
  },
});

export const NavMenuIntercomActions = createActionGroup({
  source: 'NavMenu',
  events: {
    'Show Messenger': emptyProps(),
  },
});

export const DetailViewIntercomActions = createActionGroup({
  source: 'DetailView',
  events: {
    'Show Intercom': emptyProps(),
    'Hide Intercom': emptyProps(),
  },
});

export const MainLayoutIntercomActions = createActionGroup({
  source: 'MainLayout',
  events: {
    'Show Intercom': emptyProps(),
    'Hide Intercom': emptyProps(),
  },
});
