export class TemplateSectionsToApply {
  labels = false;
  colorBranding = false;
  logo = false;
  vto = false;
  meetingAgendas = false;
  mastery = false;
  oneOnOne = false;
  agreementBasedTodos = false;
}
