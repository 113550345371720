import { createReducer, on } from '@ngrx/store';

import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';

import { FilterBarStateModel, initialFilterBarState } from './filterbar-state.model';

export const FilterBarStateReducer = createReducer<FilterBarStateModel>(
  initialFilterBarState,
  on(
    FilterBarActions.selectTeam,
    (state, { team }): FilterBarStateModel => ({
      ...state,
      selectedTeam: team,
    })
  )
);
