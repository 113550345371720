<h1 mat-dialog-title data-cy="forgot-password-dialog_title">Reset your password</h1>
<div mat-dialog-content>
  <p *ngIf="!disableEmailEdit">
    Input the email address associated with your account. If you have an account with us you will receive an email with
    instructions on how to reset your password.
  </p>
  <p *ngIf="disableEmailEdit">Click the Reset button to receive an email with instructions to reset your password.</p>
  <mat-form-field class="form-fields" appearance="outline">
    <input
      data-cy="forgot-password-dialog_email-input"
      matInput
      autocomplete="email"
      placeholder="Email"
      type="email"
      [formControl]="emailControl"
      required />
    <mat-error *ngIf="emailControl.errors?.required">{{ ErrorMessages.required }}</mat-error>
    <mat-error *ngIf="emailControl.errors?.email">{{ ErrorMessages.email }}</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="reset-button-box" fxLayout="row" fxLayoutAlign="space-between center">
  <button
    data-cy="forgot-password-dialog_close-button"
    mat-raised-button
    (click)="dialogRef.close()"
    [disabled]="spinnerService.primary$ | async">
    Cancel
  </button>
  <button
    data-cy="forgot-password-dialog_reset-button"
    mat-raised-button
    color="accent"
    cdkFocusInitial
    [disabled]="emailControl.invalid || (spinnerService.primary$ | async)"
    (click)="forgotPassword()">
    Reset
  </button>
</div>
