import { Sort } from '@angular/material/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { OutletRouteParams } from '@ninety/ui/legacy/core/services/auxiliary-router-outlet.service';
import { ListViewConversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationType } from '@ninety/ui/legacy/shared/models/feedback/conversation-type.enum';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';

export const ConversationListFacadeActions = createActionGroup({
  source: 'Conversation List Facade',
  events: {
    'Conversation List Requested': emptyProps(),
    'Conversation List Pager Changed': props<{ pageIndex: number; pageSize: number }>(),
    'Conversation List Sorted': props<Sort>(),
    'Create Conversation Dialog Opened': emptyProps(),
    'Conversation Selected': props<{ id: string }>(),
    'Conversation Deselected': emptyProps(),
    'Conversation List Init': props<{ currentUserId: string }>(),
    'Conversation List Destroyed': emptyProps(),
    'Conversation Form Started': props<{ conversation: ListViewConversation }>(),
  },
});

export const ConversationListEffectActions = createActionGroup({
  source: 'Conversation List Effects',
  events: {
    'Conversation List Requested': emptyProps(),
    'Set Conversation Type': props<{
      language: CustomLanguage;
      conversationListTitle: string;
      conversationType: ConversationType;
      selectedConversationId: string | null;
    }>(),
    'Conversation Deselected': emptyProps(),
    'Detail View Opened': props<{
      config: OutletRouteParams<{ pathParts: string[] }>;
      selectedConversationId: string;
    }>(),
    'Detail View Closed': emptyProps(),
    'Detail Closed From Detail Panel': emptyProps(),
  },
});

export const ConversationListFilterEffectActions = createActionGroup({
  source: 'Conversation List Filter Effects',
  events: {
    'Conversation List Requested': emptyProps(),
    'Conversation List Refreshed': emptyProps(),
    'Conversation Search Filter Text Updated': props<{ searchText: string }>(),
    'Conversation Selected User Filter Updated': props<{ selectedUserId: string }>(),
  },
});
