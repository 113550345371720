/**
 * @deprecated
 */
export enum VtoLayoutModeState {
  /**
   * Drag/Drop/Resize all not possible
   */
  DISABLED = 'DISABLED',

  /**
   * The grid toolbar only requests that layout mode be enabled. Feature implementors (such as vto-settings or
   * vto.component) are expected to listen for this event and handle any pre-enable logic (such as refreshing the vto)
   * and then emit ENABLED once complete.
   */
  REQUEST_ENABLE = 'REQUEST_ENABLE',

  /**
   * Drag/Drop/Resize all possible (note, GridStack does allow this to be overridden on a per-item basis)
   */
  ENABLED = 'ENABLED',
}
