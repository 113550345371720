import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import type { SuppressionList } from '../../_shared/models/helpful/suppression-list.interface';

@Injectable({
  providedIn: 'root',
})
export class HelpfulService {
  private helpfulApi = 'api/v4/Helpful';

  constructor(private http: HttpClient) {}

  getSuppressionList(email: string): Observable<SuppressionList[]> {
    return this.http.get<SuppressionList[]>(`${this.helpfulApi}/SuppressionList`, { params: { email } });
  }

  removeFromSuppressionList(email: string) {
    return this.http.delete(`${this.helpfulApi}/SuppressionList`, { params: { email } });
  }

  hardDeleteCompany(companyId: string): Observable<string> {
    return this.http
      .delete<{ result: string }>(`${this.helpfulApi}/Company`, { params: { companyId } })
      .pipe(map(({ result }) => result));
  }
}
