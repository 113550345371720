<form [formGroup]="form" class="hide-mobile input-container">
  <div class="avatar-section">
    <ninety-user-avatar [pictureURL]="form.get('avatarImageUrl').value"></ninety-user-avatar>
    <span class="icon-container">
      <terra-icon class="clickable" (click)="openAvatarDialog()" icon="edit" />
    </span>
  </div>
  <div class="input-column extra-indented">
    <mat-form-field class="legacy-no-background">
      <input
        matInput
        required
        formControlName="firstName"
        placeholder="First Name *"
        [tabindex]="this.formIndex + 1"
        data-cy="directory-add-invite-user_first-name" />
      <mat-error *ngIf="form.get('firstName').invalid">You must enter a first name</mat-error>
    </mat-form-field>
  </div>
  <div class="input-column">
    <mat-form-field class="legacy-no-background">
      <input
        matInput
        required
        formControlName="lastName"
        placeholder="Last Name *"
        [tabindex]="this.formIndex + 2"
        data-cy="directory-add-invite-user_last-name" />
      <mat-error *ngIf="form.get('lastName').invalid">You must enter a last name</mat-error>
    </mat-form-field>
    <ninety-teams-select
      [disabled]="form.get('teams').disabled"
      [userTeams]="form.get('teams').value"
      placeholder="Teams"
      [hideLabel]="true"
      [required]="true"
      [tabindex]="this.formIndex + 4"
      requiredText="You must select one or more teams"
      *ngIf="!directoryOnly"
      (selectionChange)="updateUserTeams($event)"
      data-cy="directory-add-invite-user_teams">
    </ninety-teams-select>
  </div>
  <div class="input-column">
    <mat-form-field class="email-form-field legacy-no-background">
      <input
        matInput
        [required]="!directoryOnly"
        formControlName="email"
        [placeholder]="directoryOnly ? 'Email' : 'Email *'"
        [tabindex]="this.formIndex + 3"
        data-cy="directory-add-invite-user_email" />
      <mat-error *ngIf="form.get('email').invalid">{{ form.get('emailError').value }}</mat-error>
    </mat-form-field>

    <ninety-role-select
      *ngIf="!directoryOnly"
      [initialRoleCode]="form.get('role').value"
      [required]="true"
      [tabindex]="this.formIndex + 5"
      [class.error]="form.get('role').invalid && roleSelectOpened"
      (roleChange)="updateUserRole($event)"
      (click)="roleSelectOpened = true"
      data-cy="directory-add-invite-user_role"></ninety-role-select>
    <mat-error class="role-error" *ngIf="!directoryOnly && form.get('role').invalid && roleSelectOpened">
      You must select a role
    </mat-error>
  </div>
</form>
<div class="mobile-content">
  <div class="personal-info">
    <div class="terra-body-bold">{{ form.get('firstName').value }} {{ form.get('lastName').value }}</div>
    <div *ngIf="!directoryOnly" class="team-and-role">
      {{ form.get('teamsSummary').value }}{{ form.get('teams').value.length < 2 ? ',' : ';' }}
      <span [class.invalid-role]="!form.get('role').value">No role selected.</span>
      <span
        *ngIf="form.get('role').value?.roleCode !== roleCode.observer && !form.get('role').value?.isImplementer"
        class="grey-text">
        (Paid Role)
      </span>
    </div>
    <div class="grey-text small-font">
      {{ form.get('email').value }}
    </div>
  </div>
</div>
