import { createSelector } from '@ngrx/store';

import { BrandingTheme, defaultBrandingTheme } from '../../../_shared/models/branding';
import { BillingTypes } from '../../../_shared/models/company/billing-types.enum';
import { BusinessOperatingSystem } from '../../../_shared/models/company/business-operating-system.enum';
import { AccountStatus } from '../../../_shared/models/company/company';
import { loginDomainPartnerConfigurations } from '../../../_shared/models/login/login-domain-partner-configurations';
import { FeatureFlagKeys } from '../../app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../../app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentUser } from '../../app-entities/users/users-state.selectors';
import { selectUrl } from '../../route.selectors';
import {
  selectAnonymousAffiliatePartner,
  selectAnonymousPartnerConfiguration,
} from '../anonymous/anonymous-state.selectors';
import { CompanyStateKey, selectGlobalAppState } from '../index';

export const selectCompanyState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[CompanyStateKey] : null
);

export const selectCompany = createSelector(selectCompanyState, state => state);
export const selectCompanyId = createSelector(selectCompany, state => state?._id);
export const selectCompanySltTeamId = createSelector(selectCompany, state => state?.seniorLeadershipTeamId);

export const selectCompanyName = createSelector(selectCompany, company => company.name);

export const selectBos = createSelector(selectCompany, company => company.bos);

export const selectTeamTodosTutorialVideoId = createSelector(selectBos, curBos =>
  curBos === BusinessOperatingSystem.eos ? '76yX5iUSWbNridBvdDnrDu' : 'qhmXEU25tPxePN9HuRok3w'
);

export const selectRocksTutorialVideoId = createSelector(selectBos, curBos =>
  curBos === BusinessOperatingSystem.eos ? 'cp3gT2NNUE8ERdKFdpbivc' : 'EJanjZTfqrixfd9jA2T22R'
);

export const selectCompanyAgendas = createSelector(selectCompany, company => company?.meetingAgendas);

export const selectIsPinnacleCompany = createSelector(selectBos, bos => bos === BusinessOperatingSystem.pinnacle);

export const selectIsNinetyOsCompany = createSelector(selectBos, bos => bos === BusinessOperatingSystem.ninetyOS);

export const selectIsTrialExpired = createSelector(selectCompany, company =>
  company.trialingUntil ? new Date(company.trialingUntil).getTime() < Date.now() : false
);
export const selectIsTrialingLegacy = createSelector(selectIsTrialExpired, isTrialExpired => !isTrialExpired);

export const selectCompanyColorBranding = createSelector(
  selectUrl,
  selectCompany,
  selectAnonymousAffiliatePartner,
  selectAnonymousPartnerConfiguration,
  (url, company, affiliatePartnerCompany, partnerConfiguration) => {
    const theme: BrandingTheme = {
      ...defaultBrandingTheme,
    };

    // If we're on a login path, always use the subdomain or affiliate code colors
    if (url && url.startsWith('/login')) {
      if (affiliatePartnerCompany && affiliatePartnerCompany.colorBranding) {
        theme.properties['--primaryAccent'] = affiliatePartnerCompany.colorBranding.primaryAccent;
        theme.properties['--secondaryAccent'] = affiliatePartnerCompany.colorBranding.secondaryAccent;
        return theme;
      }
      if (partnerConfiguration) {
        theme.properties['--primaryAccent'] = partnerConfiguration.primaryAccent;
        theme.properties['--secondaryAccent'] = partnerConfiguration.secondaryAccent;
        return theme;
      }
    }

    if (company?.settings?.colorBranding?.active) {
      theme.properties['--primaryAccent'] = company.settings.colorBranding.primaryAccent;
      theme.properties['--secondaryAccent'] = company.settings.colorBranding.secondaryAccent;
      return theme;
    }

    if (company?.bos) {
      for (const key in loginDomainPartnerConfigurations) {
        const config = loginDomainPartnerConfigurations[key];
        if (config.bos === company.bos) {
          theme.properties['--primaryAccent'] = config.primaryAccent;
          theme.properties['--secondaryAccent'] = config.secondaryAccent;
          return theme;
        }
      }
    }

    // Use the default;
    return theme;
  }
);

export const selectCompanySettings = createSelector(selectCompany, company => company.settings);
export const selectCompanySettingsAgreementBasedTodos = createSelector(
  selectCompanySettings,
  settings => settings?.agreementBasedTodos
);

export const selectIsAgreementsBasedTodosCompany = createSelector(
  selectCompanySettingsAgreementBasedTodos,
  agreementBasedCompany => agreementBasedCompany
);

export const selectCompanyMasterySettings = createSelector(selectCompanySettings, settings => settings?.mastery);

export const selectIsOrgChartPrivate = createSelector(
  selectCompanySettings,
  settings => settings?.privateAccountabilityChart
);
export const selectDoesUserHaveAccessToOrgChart = createSelector(
  selectIsOrgChartPrivate,
  selectCurrentUser,
  selectCompanySltTeamId,
  (isOrgChartPrivate, user, sltId) =>
    !isOrgChartPrivate || user.isAdminOrOwner || user.teams.some(t => t.teamId === sltId)
);

// Could be argued as a user state selector but since it depends on selectCompanySltTeamId I need it here
// If this is moved to user state, it would cause a circular dependency.
export const selectUserIsSLT = createSelector(selectCurrentUser, selectCompanySltTeamId, (user, sltTeamId) =>
  user?.teams?.some(team => team.teamId === sltTeamId)
);
