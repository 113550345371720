import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOut',
})
export class FilterOutPipe implements PipeTransform {
  transform<T>(values: Array<T>, property: string): Array<T> {
    if (!values || !property) return values;
    return values.filter(value => !value[property]);
  }
}
