import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderAlphabetically',
})
export class OrderAlphabeticallyPipe implements PipeTransform {
  transform<T>(values: Array<T>, property: string): Array<T> {
    if (!values || !property) return values;
    return values.sort((a, b) => {
      const stringA = a[property].toLocaleLowerCase();
      const stringB = b[property].toLocaleLowerCase();
      return stringA < stringB ? -1 : stringB < stringA ? 1 : 0;
    });
  }
}
