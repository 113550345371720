import { TooltipPosition } from '@angular/material/tooltip';

import { TerraIconName, TerraIconVariant } from '@ninety/terra';

// TODO this should be generic and value should be type T. We could migrate all current usages to type
//  StringSelectOption and let the IDE do the most of this refactor. Why? We need the value to be a number in the
//  Pagination component. Right now we have to call parseInt on emissions of the select.
export interface SelectOption {
  value: string;
  text: string;
  icon?: TerraIconName;
  iconVariant?: TerraIconVariant;
  iconColor?: IconColors;
  isItemDisabled?: boolean;
  itemCssClass?: string;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
}

// TODO this should probably go elsewhere, doesn't belong to this file. Seems like icon should declare this and select
//  should just reference
export type IconColors = 'good' | 'warn' | 'neutral';

export namespace SelectOption {
  export function fromNumber(n: number) {
    const asString = String(n);
    return { value: asString, text: asString };
  }

  export function fromNumbers(values: number[]): SelectOption[] {
    return values.map(n => fromNumber(n));
  }
}
