import { createSelector } from '@ngrx/store';

import { SpinnerStateKey } from '..';
import { selectGlobalAppState } from '../index';

export const selectSpinnerState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[SpinnerStateKey] : null
);

export const selectPrimary = createSelector(selectSpinnerState, state => state.primary);
export const selectPrimaryStartTime = createSelector(selectSpinnerState, state => state.primaryStartTime);

export const selectAuxiliary = createSelector(selectSpinnerState, state => state.auxiliary);
export const selectAuxiliaryStartTime = createSelector(selectSpinnerState, state => state.auxiliaryStartTime);
