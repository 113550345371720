import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  EnvironmentInjector,
  HostListener,
  Input,
  OnDestroy,
  createComponent,
} from '@angular/core';
import { Observable, take } from 'rxjs';

import { FeatureFlagFacade } from '../../../_state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '../../../_state/app-entities/feature-flag/feature-flag-state.model';
import { ContactCardStatusModel } from '../../models/_shared/contact-card-status-model';

import { ContactCardContainerComponent, ContactCardHoverOrientation } from './contact-card-container.component';

@Directive({
  selector: '[ninetyContactCardHover]',
  standalone: true,
})
export class ContactCardHoverDirective implements OnDestroy {
  @Input() ninetyContactCardHover: string;
  @Input() orientation: ContactCardHoverOrientation = 'above';
  @Input() statusModel: ContactCardStatusModel = null;

  private readonly showDelay = 500;
  private readonly mouseOutContactCardCloseDelay = 300;
  private readonly mouseOutCloseCardDelay = 300;
  public componentRef: ComponentRef<ContactCardContainerComponent>;
  private timeoutId: ReturnType<typeof setTimeout>;
  private disposeCard = true;
  contactCardEnabled$: Observable<boolean>;
  contactCardEnabled = false;
  CONTACT_CARD_WIDTH = 240;
  PADDING = 25;

  constructor(
    private appRef: ApplicationRef,
    public elementRef: ElementRef,
    private featureFlagFacade: FeatureFlagFacade,
    private injector: EnvironmentInjector
  ) {
    this.contactCardEnabled$ = this.featureFlagFacade.getFlag(FeatureFlagKeys.enableContactCard);
    this.contactCardEnabled$.pipe(take(1)).subscribe(isEnabled => {
      this.contactCardEnabled = isEnabled;
    });
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.contactCardEnabled) {
      this.timeoutId = setTimeout(() => {
        // If user is still hovered over element, create contact card.
        // Hack for contact cards staying open when user moves mouse too quickly
        if (this.elementRef.nativeElement.matches(':hover')) {
          this.createContactCard();
        }
      }, this.showDelay);
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.closeContactCard(this.mouseOutCloseCardDelay);
  }

  private createContactCard(): void {
    // if a contact card already exists, destroy it
    if (!!this.componentRef) {
      this.destroy();
    }

    if (this.ninetyContactCardHover) {
      this.componentRef = createComponent<ContactCardContainerComponent>(ContactCardContainerComponent, {
        environmentInjector: this.injector,
      });
      this.appRef.attachView(this.componentRef.hostView);
      document.body.appendChild(this.componentRef.location.nativeElement);

      if (this.componentRef) {
        this.componentRef.setInput('userId', this.ninetyContactCardHover);
        this.componentRef.setInput('orientation', this.orientation);
        this.componentRef.setInput('statusModel', this.statusModel);
        this.setPosition();
        this.setContactCardMouseEvents();
        this.disposeCard = true;
        this.componentRef.changeDetectorRef.detectChanges();
      }
    }

    this.ensureContactCardsShouldBeVisible();
  }

  // Failsafe to make sure contact cards are not rogue
  private ensureContactCardsShouldBeVisible() {
    setTimeout(() => {
      if (
        !this.componentRef?.location.nativeElement.matches(':hover') &&
        !this.elementRef.nativeElement.matches(':hover')
      ) {
        this.destroy();
      }
    }, 100);
  }

  private setContactCardMouseEvents(): void {
    const element = this.componentRef.location.nativeElement as HTMLElement;
    element.onmouseenter = () => {
      this.disposeCard = false;
    };
    element.onmouseleave = () => {
      this.closeContactCard(this.mouseOutContactCardCloseDelay);
    };
  }

  // The contact-card-container also has a CSS class for each orientation to move the contact card to the correct place
  // We need both the CSS and this function because here we don't know the size of the contact card and inside the contact
  // card container we do not know the size of the host element
  public setPosition(): void {
    const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();
    const horizontalCenter = (right + left) / 2;
    const verticalCenter = (bottom + top) / 2;

    const orientationPositions = {
      below: { left: horizontalCenter, top: bottom },
      above: { left: horizontalCenter, top: top },
      left: { left: left, top: verticalCenter },
      right: { left: right, top: verticalCenter },
      'upper-right': { left: right, top: top },
      'upper-left': { left: left, top: top },
      'lower-left': { left: left, top: bottom },
      'lower-right': { left: right, top: bottom },
    };

    const position = orientationPositions[this.orientation];
    const rightEdge = position.left + this.CONTACT_CARD_WIDTH / 2;
    const leftEdge = position.left - this.CONTACT_CARD_WIDTH / 2;

    let adjustedLeft;
    if (rightEdge > window.innerWidth - this.PADDING) {
      adjustedLeft = position.left + window.innerWidth - rightEdge - this.PADDING;
    } else if (leftEdge < this.PADDING) {
      adjustedLeft = this.PADDING + this.CONTACT_CARD_WIDTH / 2;
    } else {
      adjustedLeft = position.left;
    }

    this.componentRef.setInput('left', adjustedLeft);
    this.componentRef.setInput('top', position.top);
  }

  closeContactCard(delay: number) {
    // Setup to dispose contact card
    this.disposeCard = true;

    setTimeout(() => {
      // If the mouse moves over contact card, disposeCard will be set to false to avoid closing the component
      if (this.disposeCard) {
        this.destroy();
      }
    }, delay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    clearTimeout(this.timeoutId);
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
