import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, finalize, isEmpty, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { IntlTelInputComponent } from '@ninety/ui/legacy/components/inputs/intl-tel-input/intl-tel-input.component';
import { AuthService } from '@ninety/ui/legacy/core/services/auth.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { CognitoMfaType, IdentityProviderService } from '@ninety/ui/legacy/core/services/identity-provider.service';
import { LoggerService } from '@ninety/ui/legacy/core/services/logger.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { PersonService } from '@ninety/ui/legacy/core/services/person.service';
import { RecaptchaService } from '@ninety/ui/legacy/core/services/recaptcha-service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
// eslint-disable-next-line max-len
import { AccountChangePasswordDialogComponent } from '@ninety/ui/legacy/shared/components/account-change-password-dialog/account-change-password-dialog.component';
import {
  ConfirmIdentityDialogComponent,
  ConfirmIdentityDialogData,
  ConfirmIdentityDialogStatus,
} from '@ninety/ui/legacy/shared/components/confirm-identity-dialog/confirm-identity-dialog.component';
import {
  CreateTempCognitoUserDialogComponent,
  CreateTempCognitoUserDialogData,
  CreateTempCognitoUserDialogResponse,
} from '@ninety/ui/legacy/shared/components/create-temp-cognito-user-dialog/create-temp-cognito-user-dialog.component';
import { PasswordDialogComponent } from '@ninety/ui/legacy/shared/components/password-dialog/password-dialog.component';
import { Person } from '@ninety/ui/legacy/shared/models/_shared/person';
import { RecaptchaResponse } from '@ninety/ui/legacy/shared/models/_shared/recaptcha-response';
import { CognitoUserStatus } from '@ninety/ui/legacy/shared/models/cognito/cognito-user-status.enum';
import { Countries } from '@ninety/ui/legacy/shared/models/directory/countries.constant';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import * as FeatureFlagSelectors from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';

import { AccountRecaptchaAction } from './account-recaptcha-action.enum';

interface DialogClosed {
  closedFromBtn: boolean;
}

@Component({
  selector: 'ninety-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent implements OnInit, OnDestroy {
  countries = Countries;
  ConfirmIdentityDialogStatus = ConfirmIdentityDialogStatus;
  phoneControl = new FormControl<string>('', [Validators.required]);
  person: Person;
  cognitoUser: CognitoUser;
  subscriptions = new Subscription();
  personIsMfa: boolean;
  phoneIsVerified: boolean;
  hasPhoneNumber: boolean;
  personIsFederated: boolean;
  companyEnforcedMfa: boolean;
  editingEmail: boolean;
  isReauthenticating: boolean;
  finalizingNewPrimaryEmail: boolean;
  recaptchaValidated: boolean;
  public recaptchaActions = AccountRecaptchaAction;

  constructor(
    public stateService: StateService,
    public idpService: IdentityProviderService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private notifyService: NotifyService,
    private errorService: ErrorService,
    public dialog: MatDialog,
    private personService: PersonService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private recaptchaService: RecaptchaService,
    private loggerService: LoggerService
  ) {}

  @ViewChild('telInput') ninetyIntlTelInput: IntlTelInputComponent;

  ngOnInit() {
    this.phoneControl.disable();
    this.subscriptions.add(
      this.stateService.currentPerson$.pipe(filter(p => !!p)).subscribe({
        next: p => {
          this.person = p;
          this.phoneControl.setValue(p.primaryPhoneNumber);
        },
      })
    );
    this.subscriptions.add(
      this.idpService.cognitoUser$
        .pipe(
          tap(u => {
            this.isReauthenticating = !u?.attributes;
            this.cdr.markForCheck();
          }),
          filter(u => !!u?.attributes)
        )
        .subscribe(user => {
          this.phoneIsVerified = !!user.attributes.phone_number_verified;
          this.hasPhoneNumber = !!user.attributes.phone_number;
          this.personIsFederated = !!user.attributes?.identities;
          this.personIsMfa = user.preferredMFA === CognitoMfaType.SMS;
          this.cdr.markForCheck();
        })
    );
    this.idpService
      .refreshAuthenticatedUser()
      .pipe(
        isEmpty(),
        filter(userIsEmpty => !!userIsEmpty),
        tap(() => {
          this.isReauthenticating = true;
          this.cdr.markForCheck();
        }),
        switchMap(() => this.dialog.open(PasswordDialogComponent).afterClosed()),
        tap(() => {
          this.isReauthenticating = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updatePrimaryEmail() {
    const currentPrimaryEmail = this.person.primaryEmail;

    // create new temp cognito user with new email as username/email attr
    this.dialog
      .open(CreateTempCognitoUserDialogComponent, {
        data: {
          personPrimaryEmail: currentPrimaryEmail,
        } as CreateTempCognitoUserDialogData,
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        filter((tempUser: CreateTempCognitoUserDialogResponse) => !!Object.keys(tempUser || {}).length),
        switchMap((tempUser: CreateTempCognitoUserDialogResponse) =>
          (tempUser.status === CognitoUserStatus.CONFIRMED || tempUser.status === CognitoUserStatus.EXTERNAL_PROVIDER
            ? of(null)
            : this.finalizeCognitoEmail(tempUser)
          ).pipe(
            switchMap(() => {
              this.spinnerService.start();
              return this.personService
                .updatePerson({ primaryEmail: tempUser.newPrimaryEmail }, this.person._id)
                .pipe(mapTo(tempUser));
            }),
            finalize(() => {
              this.finalizingNewPrimaryEmail = false;
              this.cdr.markForCheck();
            })
          )
        )
      )
      .subscribe({
        next: (tempUser: CreateTempCognitoUserDialogResponse) => {
          this.spinnerService.stop();

          this.person.primaryEmail = tempUser.newPrimaryEmail;
          this.snackBar.open('New Primary Email verified and updated! Please sign in with your new email.', undefined, {
            duration: 20000,
          });
          this.authService.logout();
          this.cdr.markForCheck();
        },
        error: (e: unknown) => this.errorService.notify(e, 'Unable to update Primary Email.'),
      });
  }

  private finalizeCognitoEmail(tempUser: CreateTempCognitoUserDialogResponse) {
    return this.dialog
      .open(ConfirmIdentityDialogComponent, {
        data: {
          status: ConfirmIdentityDialogStatus.VerifyEmail,
          email: tempUser.newPrimaryEmail,
        } as ConfirmIdentityDialogData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        switchMap(() => {
          this.spinnerService.start();
          this.finalizingNewPrimaryEmail = true;
          this.cdr.markForCheck();
          return this.idpService
            .finalizeNewCognitoEmail(tempUser.newPrimaryEmail, tempUser.password)
            .pipe(mapTo(tempUser));
        })
      );
  }

  toggleMfa(): void {
    this.spinnerService.start();
    const mfaType = this.personIsMfa ? CognitoMfaType.SMS : CognitoMfaType.NONE;

    if (this.personIsMfa && !this.hasPhoneNumber) {
      this.openPhoneDialog(ConfirmIdentityDialogStatus.UpdateNumber)
        .pipe(switchMap(() => this.idpService.setMfaType(mfaType)))
        .subscribe({
          next: () => {
            this.spinnerService.stop();
            this.snackBar.open(`MFA ${this.personIsMfa ? 'enabled' : 'disabled'} `, undefined, {
              duration: 3000,
            });
          },
          error: (err: unknown) => {
            this.personIsMfa = !this.personIsMfa;
            this.errorService.oops(err);
          },
        });
    } else if (this.personIsMfa && !this.phoneIsVerified) {
      this.openPhoneDialog(ConfirmIdentityDialogStatus.ConfirmNumber)
        .pipe(switchMap(() => this.idpService.setMfaType(mfaType)))
        .subscribe({
          next: () => {
            this.spinnerService.stop();
            this.snackBar.open(`MFA ${this.personIsMfa ? 'enabled' : 'disabled'} `, undefined, {
              duration: 3000,
            });
          },
          error: (err: unknown) => {
            this.personIsMfa = !this.personIsMfa;
            this.errorService.oops(err);
          },
        });
    } else if (!this.personIsMfa && this.stateService.company.settings.requireMfa) {
      this.idpService.setMfaType(mfaType).subscribe({
        next: () => {
          this.spinnerService.stop();
          this.notifyService.showError('Please enable MFA for full access to this company', 'MFA Setup Required');
          this.snackBar.open(`MFA ${this.personIsMfa ? 'enabled' : 'disabled'} `, undefined, {
            duration: 3000,
          });
        },
        error: (err: unknown) => {
          this.personIsMfa = !this.personIsMfa;
          this.errorService.oops(err);
        },
      });
    } else {
      this.idpService.setMfaType(mfaType).subscribe({
        next: () => {
          this.spinnerService.stop();
          this.snackBar.open(`MFA ${this.personIsMfa ? 'enabled' : 'disabled'} `, undefined, {
            duration: 3000,
          });
        },
        error: (err: unknown) => {
          this.personIsMfa = !this.personIsMfa;
          this.errorService.oops(err);
        },
      });
    }
  }

  updatePrimaryPhoneNumber() {
    this.phoneControl.disable();

    if (this.numberIsBlocked(this.phoneControl.value)) {
      this.notifyService.showError(
        'Unable to update your phone number. Please contact support.',
        'Phone Number Update Failed'
      );

      console.info({
        msg: 'Country code blocked',
        data: this.getLogData(),
      });

      return;
    }

    this.personService
      .updatePerson({ primaryPhoneNumber: this.phoneControl.value }, this.person._id)
      .pipe(
        // Prevent user from updating to unverified SMS number and keeping MFA on, causing lockout on next login.
        switchMap(_ => this.idpService.setMfaType(CognitoMfaType.NONE)),
        // tap(_ => this.personIsMfa = false),
        switchMap(_ => this.idpService.updateTelNumber(this.phoneControl.value)),
        finalize(() => {
          this.spinnerService.stop();
          // this.closeEditPrimaryPhoneNumber();
        })
      )
      .subscribe({
        next: _ => {
          this.person.primaryPhoneNumber = this.phoneControl.value;

          this.snackBar.open('Profile information updated.', undefined, {
            duration: 3000,
          });

          console.info({ msg: `User updated phone number`, data: this.getLogData() });

          // try to verify tel number, regardless of MFA on/off
          this.openPhoneDialog(ConfirmIdentityDialogStatus.ConfirmNumber);
        },
      });
  }

  getLogData() {
    return {
      personId: this.person._id,
      userId: this.stateService.currentCompanyUser?._id,
      company: this.stateService.company?._id,
      emailDomain: this.person.primaryEmail.split('@')[1] ?? '',
      countryCode: `+${this.ninetyIntlTelInput.selectedCountry.dialCode}` || '',
    };
  }

  numberIsBlocked(phoneNumber: string): boolean {
    let denyList: string[] = [];
    this.store
      .select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.mfaDenyList))
      .pipe(
        take(1),
        tap(list => (denyList = list))
      )
      .subscribe();

    return denyList.some(prefix => phoneNumber.startsWith(prefix));
  }

  editPhone(): void {
    this.phoneControl.enable();
    setTimeout(() => document.getElementById('phone-number-input').focus());
  }

  cancelEditPhone(): void {
    this.phoneControl.reset(this.person.primaryPhoneNumber);
    this.phoneControl.disable();
  }

  openPhoneDialog(
    status: ConfirmIdentityDialogStatus.UpdateNumber | ConfirmIdentityDialogStatus.ConfirmNumber
  ): Observable<boolean> {
    this.spinnerService.stop();
    return this.dialog
      .open<ConfirmIdentityDialogComponent, ConfirmIdentityDialogData, DialogClosed | string | void>(
        ConfirmIdentityDialogComponent,
        {
          disableClose: true,
          data: {
            phone: this.phoneControl.value,
            status,
            showCloseButton: true,
          },
        }
      )
      .afterClosed()
      .pipe(
        map((r: DialogClosed | undefined) => !r?.closedFromBtn),
        catchError((err: unknown) => this.errorService.notify(err, (err as Error).message))
      );
  }

  openChangePasswordDialog() {
    return this.dialog
      .open<AccountChangePasswordDialogComponent>(AccountChangePasswordDialogComponent, {
        data: { email: this.person.primaryEmail },
      })
      .afterClosed();
  }

  redirectToCreateCompanyUrl(): void {
    if (this.stateService.company?.affiliateCode)
      this.router.navigateByUrl(`/create-company?affiliate=${this.stateService.company.affiliateCode}`);
    else this.router.navigateByUrl('/create-company');
  }

  public processRecaptchaResponse(captchaToken: string): void {
    this.recaptchaService.verifyRecaptchaToken(captchaToken).subscribe({
      next: (response: RecaptchaResponse) => {
        if (response.success && response.score > 0.5) {
          switch (response.action) {
            case AccountRecaptchaAction.verifyPhone: {
              this.openPhoneDialog(ConfirmIdentityDialogStatus.ConfirmNumber);
              break;
            }
            default:
              this.loggerService.error('Unknown captcha action', response.action);
              break;
          }
        } else {
          this.notifyService.notify('There was a problem validating your request, please try again.');
        }
      },
      error: (error: unknown) => {
        this.loggerService.error('Error validating captcha token', error);
      },
    });
  }

  public executeAction(action: string): void {
    this.recaptchaService.executeAction(action).subscribe({
      next: (token: string) => {
        console.log(token);
        this.processRecaptchaResponse(token);
      },
      error: (error: unknown) => {
        console.log(error);
      },
    });
  }
}
