import { createSelector } from '@ngrx/store';

import { selectEntitiesState, UsersStateKey } from '..';
import { UserService } from '../../../_core/services/user.service';
import { Theme } from '../../../_shared';
import { RoleCode } from '../../../_shared/models/_shared/role-code';
import { RoleCodeAsName } from '../../../_shared/models/_shared/role-code-as-name';
import { User } from '../../../_shared/models/_shared/user';
import { UserProfile } from '../../../_shared/models/_shared/user-profile';
import { CompanyLanguage } from '../../../_shared/models/language/custom-language';
import { selectHelpfulPermissions } from '../../app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '../../app-global/language/language.selectors';
import { selectFeatureFlag } from '../feature-flag/feature-flag-state.selectors';

import { UserAvatarInfo, UserModelState, usersInitialState, usersStateAdapter } from './users-state.model';

const { selectAll, selectEntities } = usersStateAdapter.getSelectors();

const selectUsersState = createSelector(selectEntitiesState, appEntities =>
  appEntities ? appEntities[UsersStateKey] : usersInitialState
);

export const selectCurrentUserId = createSelector(selectUsersState, state => state.currentUserId);

export const selectAllUsers = createSelector(selectUsersState, selectAll);
export const selectUserEntities = createSelector(selectUsersState, selectEntities);

export const selectAllNonCoachUsers = createSelector(selectAllUsers, (users: UserModelState[]) =>
  users.filter(u => !u.isImplementer)
);

export const selectAllNonObserverUsers = createSelector(selectAllUsers, users =>
  users?.filter(user => user.roleCode !== RoleCode.observer)
);

export const selectCurrentUser = createSelector(
  selectUserEntities,
  selectCurrentUserId,
  selectLanguage,
  (userEntities, currentUserId, language) => addCalculatedProps(userEntities[currentUserId], currentUserId, language)
);

export const selectCurrentUserScheduledNotifications = createSelector(
  selectCurrentUser,
  user => user.settings.notificationEmails
);

export const selectRawCurrentUser = createSelector(
  selectUserEntities,
  selectCurrentUserId,
  (userEntities, currentUserId) => userEntities[currentUserId]
);

export const selectCurrentUserIsObserver = createSelector(selectCurrentUser, user => user.isObserver);
export const selectCurrentUserIsNotObserver = createSelector(selectCurrentUser, user => !user.isObserver);
export const selectCurrentUserIsAdminOrOwner = createSelector(selectCurrentUser, user => user.isAdminOrOwner);
export const selectCurrentUserIsOwner = createSelector(selectCurrentUser, user => user.isOwner);
export const selectCurrentUserIsManagerOrAbove = createSelector(selectCurrentUser, user => user.isManagerOrAbove);
export const selectCurrentUserIsManageeOrAbove = createSelector(selectCurrentUser, user => user.isManageeOrAbove);
export const selectCurrentUserIsManagerOrAboveOrImplementer = createSelector(
  selectCurrentUser,
  user => user.isImplementer || user.isManagerOrAbove
);
export const selectCurrentUserIsImplementer = createSelector(selectCurrentUser, user => user.isImplementer);
export const selectCurrentUserIsTeamMember = createSelector(selectCurrentUser, user => user.isManagee);

export const selectCurrentUserTheme = createSelector(selectCurrentUser, user => user.settings.theme);
export const selectIsDualTheme = createSelector(selectCurrentUserTheme, theme => theme === Theme.DUAL);

export const selectCanCurrentUserEditOtherUserPersonMetadata = (otherUserId: string) =>
  createSelector(
    selectCurrentUser,
    selectHelpfulPermissions,
    selectUserById(otherUserId),
    (currentUser, currentUserHelpfulPermissions, otherUser) => {
      if (!currentUser || !otherUser) return false;
      if (currentUser._id === otherUser._id) return true;
      const currentUserIsSuperHelpful = currentUserHelpfulPermissions?.manageHelpfuls;
      const otherUserIsHelpful = otherUser?.isHelpful;
      if (currentUserIsSuperHelpful && otherUserIsHelpful) return true;
      const currentUserIsHelpful = currentUserHelpfulPermissions?.users;
      if (currentUserIsHelpful && !otherUserIsHelpful) return true;
      if (otherUserIsHelpful) return false;
      if (!currentUser.isManagerOrAbove) return false;
      if (currentUser.roleCode < otherUser.roleCode) return true;
      return false;
    }
  );

export const selectUserById = (userId: string) =>
  createSelector(selectUserEntities, selectCurrentUserId, selectLanguage, (userEntities, currentUserId, language) =>
    addCalculatedProps(userEntities?.[userId], currentUserId, language)
  );

export const selectUserProfileById = (userId: string) =>
  createSelector(selectUserById(userId), user => getUserProfile(user));

export const selectUserAvatarInfo = (userId: string) =>
  createSelector(selectUserById(userId), user => getUserAvatarInfo(user));

export const selectUserNameById = (userId: string, notFoundValue = '') =>
  createSelector(selectUserById(userId), user => {
    if (!user) return notFoundValue;
    return UserService.userName(user);
  });

export const selectUserSettings = createSelector(selectCurrentUser, user => user.settings);

/** Determines if any alerts exist in settingsAlerts */
export const selectHasUserSettingsAlerts = createSelector(
  selectUserSettings,
  settings =>
    settings.settingsAlerts && Object.keys(settings.settingsAlerts).some(x => settings.settingsAlerts[x] !== null)
);

export const selectTeamTodosTutorialVideoVisible = createSelector(
  selectCurrentUser,
  selectFeatureFlag('vidyard-learning-videos'),
  (currentUser, tutorialVideosVisible) => !currentUser?.tutorialsHidden?.myNinetyTeamTodos && tutorialVideosVisible
);
export const selectRocksTutorialVideoVisible = createSelector(
  selectCurrentUser,
  selectFeatureFlag('vidyard-learning-videos'),
  (currentUser, tutorialVideosVisible) => !currentUser?.tutorialsHidden?.myNinetyRocks && tutorialVideosVisible
);

/**************************** Helper functions ***************************************/
export const addCalculatedProps = (
  user: User,
  currentUserId: string | null,
  language: CompanyLanguage
): UserModelState | null => {
  if (!user) return null;

  const isOwner = user.roleCode === RoleCode.owner;
  const isAdmin = user.roleCode === RoleCode.admin;
  const isManager = user.roleCode === RoleCode.manager;
  const isManagee = user.roleCode === RoleCode.managee;
  const isObserver = user.roleCode === RoleCode.observer;
  const isLite = user.roleCode === RoleCode.lite;
  const isImplementer = isOwner && (user.isImplementer ?? false);

  return {
    ...user,
    isCurrentUser: currentUserId ? user._id === currentUserId : false,
    isOwner,
    isAdmin,
    isManager,
    isManagee,
    isObserver,
    isLite,
    isAdminOrOwner: isOwner || isAdmin,
    isManagerOrAbove: isOwner || isAdmin || isManager,
    isManageeOrAbove: isOwner || isAdmin || isManager || isManagee,
    isImplementer,
    role: getUserRole(user, language),
    primaryEmail: user.primaryEmail,
  };
};

export const getUserRole = (user: User, language: CompanyLanguage): string => {
  if (!language?.directory || user.roleCode === RoleCode.lite) {
    return '';
  }

  return user.isImplementer ? language.directory.implementer : language.directory[RoleCodeAsName[user.roleCode]];
};

export const getUserProfile = (user: UserModelState): UserProfile => ({
  _id: user._id,
  firstName: user?.metadata?.name?.first || '',
  lastName: user?.metadata?.name?.last || '',
  avatarUrl: user?.metadata?.picture?.url || '',
  primaryEmail: user.primaryEmail,
  roleCode: user.roleCode,
  role: user.role,
  title: user.title,
  isCurrentUser: user.isCurrentUser,
  isOwner: user.isOwner,
  isAdminOrOwner: user.isAdminOrOwner,
  isManagerOrAbove: user.isManagerOrAbove,
  isManageeOrAbove: user.isManageeOrAbove,
  isImplementer: user.isImplementer,
  active: user.active,
});

export const getUserAvatarInfo = (user: UserModelState): UserAvatarInfo => {
  if (!user)
    return {
      fullUserName: '',
      pictureURL: '',
      userInitials: '',
    };

  return {
    fullUserName: user.fullName || '',
    pictureURL: user.metadata?.picture?.url || '',
    userInitials: `${user.metadata?.name?.first[0] || ''}${user.metadata?.name?.last[0] || ''}`.toUpperCase(),
  };
};
