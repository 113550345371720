<ng-container *ngIf="(vm.paymentFailed | async) === false">
  <div id="add-invite" class="max-height-90vh" mat-dialog-content data-cy="directory-add-invite">
    <div class="close-x-container">
      <button tabindex="0" class="close-x empty-button fixed-button-on-mobile" (click)="close()"></button>
    </div>
    <div class="header">
      <h3 [class.short]="!userCanAddToCompany">
        {{ userCanAddToCompany ? 'Add People' : 'Add People to ' + stateService.language.directory.route }}
      </h3>
      <h4 *ngIf="userCanAddToCompany" class="show-mobile">Add users to:</h4>
      <div *ngIf="userCanAddToCompany" class="add-user-type-buttons-and-text-container">
        <div class="add-user-type-buttons">
          <button
            mat-flat-button
            class="add-user-type-button"
            [class.inactive]="directoryOnly"
            (click)="onClickSetDirectoryOnly(false)"
            data-cy="directory-add-invite_add-to-company">
            Invite to Company
          </button>
          <span class="add-user-type-spacer"></span>
          <button
            mat-flat-button
            class="add-user-type-button"
            [class.inactive]="!directoryOnly"
            (click)="onClickSetDirectoryOnly(true)"
            data-cy="directory-add-invite_add-to-directory">
            Add to {{ stateService.language.directory.route }}
          </button>
        </div>
      </div>
      <div class="add-user-type-explanation">
        <p *ngIf="directoryOnly">
          <b>Add an entry to the {{ stateService.language.directory.route }}.</b> The entry will still appear in the
          {{ stateService.language.acChart.route }}, but won't have a login attached and can't be assigned contents like
          {{ stateService.language.rock.items }}, {{ stateService.language.todo.items }}, and
          {{ stateService.language.issue.items }}.
        </p>
        <p *ngIf="!isStripe && !directoryOnly" data-cy="directory-add-invite_user-type-explanation">
          <strong
            >You are currently using {{ vm.licenseUsed | async }} of {{ vm.licenseQty | async }} available
            licenses.</strong
          >
          Assigning paid roles to users beyond the number of available licenses will increase your subscription cost.
          The payment method on file will be charged the prorated difference. You can manage your licenses on the
          <a (click)="onBillingInfoClick()">Billing page.</a>
        </p>
      </div>
    </div>
    <mat-dialog-content
      class="padding-0"
      #scrollContainer
      [class.pristine]="forms.pristine"
      [scrollTop]="scrollContainer.scrollHeight">
      <div class="user-form-container" *ngFor="let form of forms.controls; index as i">
        <div style="display: flex; flex: 1 0 100%">
          <!-- Hidden on mobile -->
          <ninety-directory-add-invite-user [form]="$any(form)" [index]="i" [directoryOnly]="directoryOnly" />

          <div class="close-column hide-mobile">
            <button
              class="close-x empty-button grey-text"
              [class.invisible]="forms.length < 2"
              (click)="onClickDeleteUser(i, false)"></button>
          </div>
          <div class="mobile-edit-close show-mobile">
            <button class="spaced-out empty-button grey-text" (click)="editUserMobile(i)">
              <terra-icon icon="edit" />
            </button>
            <button class="close-x empty-button grey-text" (click)="onClickDeleteUser(i, true)"></button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div class="middle-content-container">
      <button class="hide-mobile uppercase full-button accented-button" mat-flat-button (click)="onClickAddUser()">
        <terra-icon icon="add-circle" /> Add Another {{ directoryOnly ? 'Entry' : 'User' }}
      </button>
      <button
        class="show-mobile uppercase full-button accented-button"
        mat-flat-button
        (click)="onClickAddUserMobile()">
        <terra-icon icon="add-circle" /> Add {{ forms.length > 1 ? 'Another ' : '' }}User
      </button>
      <div class="inner-container" *ngIf="!directoryOnly">
        <terra-checkbox [ngModel]="shouldSendInvites" (change)="onClickCheckInviteEmail($event)"
          >Send invite email</terra-checkbox
        >
      </div>
    </div>
    <div class="bottom-content-container">
      <button
        class="uppercase primary-button full-button wide-spaced-letters"
        mat-flat-button
        (click)="onClickSubmitForms()"
        [disabled]="forms.invalid || submissionInProgress || !canCreatePeople"
        data-cy="directory-add-invite_submit_form"
        [attr.data-product]="
          directoryOnly ? 'directory-add-invite_add-to-directory-button' : 'directory-add-invite_add-to-company-button'
        ">
        {{ directoryOnly ? 'add to directory' : 'invite to company' }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="vm.paymentFailed | async">
  <div class="paymentFail-card">
    <div>
      <span class="title"><strong>Payment Failed</strong></span>
      <button tabindex="0" class="close-x empty-button fixed-button-on-mobile right" (click)="close()"></button>
    </div>
    <p>There was an issue processing your payment. You can still add your teammates as free Observers.</p>
    <mat-card-actions class="centered">
      <button mat-button mat-raised-button (click)="goBack()">Go Back</button>
      <button class="primary-button" mat-button mat-raised-button (click)="addAsObservers()">
        <span class="white">Add as Observers</span>
      </button>
    </mat-card-actions>
  </div>
</ng-container>
