<ninety-menu data-cy="learn-support-menu_menu">
  <span *ngFor="let item of dataSource; index as i">
    <div class="menu-section-container" *ngIf="item.title">
      <span class="menu-section">{{ item.title }}</span>
    </div>
    <ng-container *ngFor="let cell of item.data">
      <div class="menu-row">
        <ninety-menu-item-large
          attr.data-cy="learn-support-menu_item-{{ cell.label }}"
          [item]="cell"></ninety-menu-item-large>
      </div>
      <hr *ngIf="cell.divider" />
    </ng-container>
  </span>
</ninety-menu>
