/**
 * Represents the different kinds of image avatars for companies & users.
 */
export enum AvatarType {
  /**
   * Used when changing a company logo.
   */
  company = 'company',

  /**
   * Used when a user changes their own profile image, which updates their person metadata.
   */
  person = 'person',

  /**
   * Used when a user changes the avatar for another person on the directory or during the invite process.
   */
  otherPerson = 'otherPerson',

  /**
   * Used when changing the company logo in a partnerhub customization template.
   */
  template = 'template',
}
