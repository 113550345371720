<ng-container
  *ngrxLet="{
    language: language$ | async
  } as vm">
  <ninety-button
    *ngIf="showRemoveBtn"
    flat
    icon
    class="remove-btn"
    [style.display]="removeBtnDisplay"
    (mouseenter)="removeBtnDisplay = 'inherit'"
    (mouseleave)="removeBtnDisplay = 'none'"
    (click)="removeUser.emit()"
    matTooltip="Remove {{ vm.language.acChart.seatHolder }}"
    matTooltipPosition="above">
    <terra-icon icon="minus-circle" />
  </ninety-button>
  <ninety-button
    #addUserButton
    [size]="addButtonSize"
    flat
    [matMenuTriggerFor]="userSelectMenu"
    class="menu-btn user"
    data-cy="user-select_add-user-button"
    (mouseenter)="removeBtnDisplay = 'inherit'"
    (mouseleave)="removeBtnDisplay = 'none'"
    [disabled]="disabled || isMeeting"
    [matTooltip]="tooltip"
    [class.small]="accountabilityChart && !user && !userId"
    matTooltipClass="ninety-tooltip"
    matTooltipPosition="above"
    [class.no-user]="!user"
    [class.opaque]="disabled && !isMeeting">
    <span
      class="avatar-image"
      *ngIf="!iconBtn && user?.metadata?.picture?.url && (!multiple || selectedUserIds.length < 2)"
      [style.backgroundImage]="'url(' + user?.metadata?.picture?.url + ')'">
      {{ user | userInitials }}
    </span>
    <span class="user-initials" *ngIf="user && user?.metadata && !user?.metadata?.picture?.url && showInitials()">
      {{ user | userInitials }}
    </span>
    <terra-icon
      *ngIf="iconBtn"
      [matBadge]="selectedUserIds.length.toString()"
      matBadgeColor="accent"
      [matBadgeHidden]="hideCountBadge || !selectedUserIds.length"
      icon="user-add" />
    <terra-icon icon="user-circle" class="avatar-icon" *ngIf="!iconBtn && !user && selectedUserIds.length === 1" />
    <terra-icon
      icon="warning-triangle"
      variant="fill"
      class="orange"
      *ngIf="
        (!iconBtn && !user && !selectedUserIds.length && !userId && !accountabilityChart) || (warningBtn && !user)
      " />
    <terra-icon
      icon="users"
      variant="fill"
      class="avatar-icon"
      *ngIf="multiple && !iconBtn && selectedUserIds.length > 1"
      [matBadge]="selectedUserIds.length.toString()"
      matBadgeColor="accent"
      matBadgePosition="before" />

    <terra-icon *ngIf="accountabilityChart && !user && !userId" icon="user-add" class="person-add" />

    <ng-content></ng-content>
  </ninety-button>

  <mat-menu #userSelectMenu="matMenu" class="user-select-menu" (closed)="saveOnClose()">
    <ng-template matMenuContent>
      <div class="user-search">
        <mat-form-field class="user-search-field">
          <input
            #userSearchInput
            matInput
            placeholder="Search users"
            value=""
            ninetyStopPropagation
            type="text"
            name="user-search"
            aria-label="user search"
            (change)="userSearchInput.focus()" />
        </mat-form-field>
      </div>
      <div class="multi-user-select-options" ninetyStopPropagation [stopPropagation]="multiple">
        <ninety-button
          data-cy="user-select_card-select-user"
          mat-menu-item
          attr.data-cy-item="user-select_name-{{ u?.metadata.name | displayFullName }}"
          (click)="onSelect(u)"
          [ngClass]="{ 'row-reverse': multiple }"
          *ngFor="
            let u of filteredUsers | searchUserFilter : userSearchInput.value;
            trackBy: trackByUserId;
            let i = index
          "
          ninetyStopPropagation
          [stopPropagation]="multiple"
          [disabled]="u.isUnconfirmed || u.hasNoSeat">
          <span
            class="avatar-image"
            *ngIf="u.metadata?.picture?.url; else noPicture"
            [style.backgroundImage]="'url(' + u.metadata?.picture?.url + ')'">
            {{ u | userInitials }}
          </span>
          <ng-template #noPicture>
            <span class="user-initials">
              {{ u | userInitials }}
            </span>
          </ng-template>
          <span [id]="'user-name-label' + i" class="user-name">
            {{ u?.metadata.name | displayFullName }}
            <span *ngIf="u.isUnconfirmed; else checkNotInSeat">(unconfirmed user)</span>
            <ng-template #checkNotInSeat>
              <span *ngIf="u.hasNoSeat"> (not in a {{ vm.language.acChart.seat }}) </span>
            </ng-template>
          </span>
          <terra-checkbox
            (change)="onSelect(u)"
            [ngModel]="isSelected(u)"
            [aria-labelledby]="'user-name-label' + i"
            *ngIf="multiple"
            [disabled]="u.isUnconfirmed || u.hasNoSeat"
            ninetyStopPropagation></terra-checkbox>
        </ninety-button>
      </div>
      <div class="dflex">
        <ninety-button
          mat-menu-item
          (click)="selectedUserIds = []; onSelectUsers(); removeUser.emit()"
          [disabled]="!selectedUserIds.length"
          class="cancel-btn"
          *ngIf="multiple"
          ninetyStopPropagation>
          Clear
        </ninety-button>
        <ninety-button
          mat-menu-item
          data-cy="user-select_ok-button"
          (click)="onSelectUsers()"
          [disabled]="!selectedUserIds.length"
          class="ok-btn branded-primary-color"
          *ngIf="multiple">
          OK
        </ninety-button>
      </div>
    </ng-template>
  </mat-menu>
</ng-container>
