import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge as _merge } from 'lodash';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { MeetingAgendaService } from '@ninety/ui/legacy/core/services/meeting-agenda.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import { CompanyMeetingAgendaPush, MeetingAgendas, Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { Company } from '@ninety/ui/legacy/shared/models/company/company';
import { MeetingAgendaType } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda-type.enum';
import { selectCompany, selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

@Component({
  selector: 'ninety-company-meeting-agenda',
  template: `<ninety-meeting-agendas
    *ngrxLet="currentCompanyId$ as currentCompanyId"
    class="meeting-settings"
    [agendas]="agendas$ | ngrxPush"
    subtitle="See More Options to push these agenda changes to all existing teams."
    (saveCompanyAgendaByType)="companyService.updateMeetingAgendasByType($event).subscribe()"
    (deleteCustomAgenda)="deleteAgendas($event)"
    (pushCompanyAgenda)="pushCompanyAgenda($event)"
    (getResetAgendas)="getCompanyResetAgendas(currentCompanyId)"
    [resetAgendas]="resetAgendas$ | ngrxPush"></ninety-meeting-agendas>`,
  styles: [],
})
export class CompanyMeetingAgendaComponent {
  agendas$ = this.store.select(selectCompany).pipe(switchMap(company => this.setAgendas(company)));
  currentCompanyId$ = this.store.select(selectCompanyId);

  private _resetAgendas = new BehaviorSubject<MeetingAgendas>(null);
  resetAgendas$ = this._resetAgendas.asObservable();

  constructor(
    public stateService: StateService,
    public companyService: CompanyService,
    private teamService: TeamService,
    private filterService: FilterService,
    private spinnerService: SpinnerService,
    private store: Store,
    private agendasService: MeetingAgendaService
  ) {}

  setAgendas(currentCompany: Company): Observable<MeetingAgendas> {
    return this.agendasService
      .getDefaultBosAgendas(currentCompany.bos)
      .pipe(map(bosAgendas => _merge({}, bosAgendas, currentCompany.meetingAgendas)));
  }

  deleteAgendas(agendaId: string): void {
    this.companyService.deleteCustomAgenda(agendaId).subscribe();
  }

  pushCompanyAgenda({ agendaType, agendaId }: CompanyMeetingAgendaPush): void {
    const companyId = this.stateService.company._id;
    const requests = [this.teamService.getV4Teams(), this.companyService.getCompanyById(companyId)];
    const request =
      agendaType === MeetingAgendaType.custom
        ? this.companyService.pushCustomAgenda(companyId, agendaId)
        : this.companyService.pushAgendaByType(companyId, agendaType);
    this.spinnerService
      .spinWhile(request)
      .pipe(switchMap(() => this.spinnerService.spinWhile(forkJoin(requests))))
      .subscribe({
        next: ([teams, cia]: [Team[], Company]) => {
          this.stateService.company.meetingAgendas = cia.meetingAgendas;

          this.teamService.allTeams$.next(teams);
          this.teamService.allTeamsMap$.next(teams.reduce((map, t) => map.set(t._id, t), new Map()));

          const selectedTeam = teams.find(t => t._id === this.filterService.selectedTeam$.value._id);
          if (selectedTeam) this.filterService.selectedTeam$.next(selectedTeam);
        },
      });
  }

  getCompanyResetAgendas(companyId: string): void {
    this.agendasService.getCompanyResetAgendas(companyId).subscribe({
      next: agendas => this._resetAgendas.next(agendas),
    });
  }
}
