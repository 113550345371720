import { BusinessOperatingSystem } from '../company/business-operating-system.enum';
import { CustomLanguage } from '../language/custom-language';

import { RoleCode } from './role-code';

export interface RoleMetadata {
  readonly title: string;
  readonly subtitle: string;
  readonly description: string;
  readonly roleCode: RoleCode;
  readonly isImplementer: boolean;
}

type CustomLanguageRoleSelect = Pick<CustomLanguage, 'directory' | 'issue' | 'rock' | 'todo'>;

const EOS_ROLE_METADATA: (language?: CustomLanguageRoleSelect) => RoleMetadata[] = language => [
  {
    title: language?.directory?.owner ?? 'Owner',
    subtitle: '',
    description:
      'View and edit access to all private & public teams, company settings, and has the ability to delete the company account.',
    roleCode: RoleCode.owner,
    isImplementer: false,
  },
  {
    title: language?.directory?.admin ?? 'Admin',
    subtitle: '',
    description: 'View and edit access to all private & public teams, and company settings.',
    roleCode: RoleCode.admin,
    isImplementer: false,
  },
  {
    title: language?.directory?.manager ?? 'Manager',
    subtitle: '',
    description: 'View and edit access to assigned teams and can create new teams.',
    roleCode: RoleCode.manager,
    isImplementer: false,
  },
  {
    title: language?.directory?.managee ?? 'Team Member',
    subtitle: '',
    description: 'View and edit access to assigned teams.',
    roleCode: RoleCode.managee,
    isImplementer: false,
  },
  {
    title: language?.directory?.implementer ?? 'Coach',
    subtitle: '(Free)',
    description: `View and edit access to the entire company account but cannot own ${
      language?.rock?.items ?? 'Rocks'
    }, ${language?.issue?.items ?? 'Issues'}, and ${language?.todo?.items ?? 'To-Dos'}.`,
    roleCode: RoleCode.owner,
    isImplementer: true,
  },
  {
    title: language?.directory?.observer ?? 'Observer',
    subtitle: '(Free)',
    description: `View-only access to assigned teams and cannot own ${language?.rock?.items ?? 'Rocks'}, ${
      language?.issue?.items ?? 'Issues'
    }, and ${language?.todo?.items ?? 'To-Dos'}.`,
    roleCode: RoleCode.observer,
    isImplementer: false,
  },
];

const NINETYOS_ROLE_METADATA: (language?: CustomLanguageRoleSelect) => RoleMetadata[] = language => [
  {
    title: language?.directory?.owner ?? 'Owner',
    subtitle: '',
    description:
      'View and edit access to all private & public teams, company settings, and has the ability to delete the company account.',
    roleCode: RoleCode.owner,
    isImplementer: false,
  },
  {
    title: language?.directory?.admin ?? 'Admin',
    subtitle: '',
    description: 'View and edit access to all private & public teams, and company settings.',
    roleCode: RoleCode.admin,
    isImplementer: false,
  },
  {
    title: language?.directory?.manager ?? 'Manager',
    subtitle: '',
    description: 'View and edit access to assigned teams and can create new teams.',
    roleCode: RoleCode.manager,
    isImplementer: false,
  },
  {
    title: language?.directory?.managee ?? 'Team Member',
    subtitle: '',
    description: 'View and edit access to assigned teams.',
    roleCode: RoleCode.managee,
    isImplementer: false,
  },
  {
    title: language?.directory?.implementer ?? 'Coach',
    subtitle: '(Free)',
    description: `View and edit access to the entire company account but cannot own ${
      language?.rock?.items ?? 'Rocks'
    }, ${language?.issue?.items ?? 'Issues'}, and ${language?.todo?.items ?? 'To-Dos'}.`,
    roleCode: RoleCode.owner,
    isImplementer: true,
  },
  {
    title: 'Observer',
    subtitle: '(Free)',
    description: `View-only access to assigned teams and cannot own ${language?.rock?.items ?? 'Rocks'}, ${
      language?.issue?.items ?? 'Issues'
    }, and ${language?.todo?.items ?? 'To-Dos'}.`,
    roleCode: RoleCode.observer,
    isImplementer: false,
  },
];

const PINNACLE_ROLE_METADATA: (language?: CustomLanguageRoleSelect) => RoleMetadata[] = language => [
  {
    title: language?.directory?.owner ?? 'Owner',
    subtitle: '',
    description:
      'View and edit access to all private & public teams, company settings, and has the ability to delete the company account.',
    roleCode: RoleCode.owner,
    isImplementer: false,
  },
  {
    title: language?.directory?.admin ?? 'Admin',
    subtitle: '',
    description: 'View and edit access to all private & public teams, and company settings.',
    roleCode: RoleCode.admin,
    isImplementer: false,
  },
  {
    title: language?.directory?.manager ?? 'Manager',
    subtitle: '',
    description: 'View and edit access to assigned teams and can create new teams.',
    roleCode: RoleCode.manager,
    isImplementer: false,
  },
  {
    title: language?.directory?.managee ?? 'Managee',
    subtitle: '',
    description: 'View and edit access to assigned teams.',
    roleCode: RoleCode.managee,
    isImplementer: false,
  },
  {
    title: language?.directory?.implementer ?? 'Guide',
    subtitle: '(Free)',
    description: 'View and edit access to the entire company account but cannot own items.',
    roleCode: RoleCode.owner,
    isImplementer: true,
  },
  {
    title: language?.directory?.observer ?? 'Observer',
    subtitle: '(Free)',
    description: `View-only access to assigned teams and cannot own ${language?.rock?.items ?? 'FAST Rocks'}, ${
      language?.issue?.items ?? 'Action Items'
    }, and ${language?.todo?.items ?? 'Topics'}.`,
    roleCode: RoleCode.observer,
    isImplementer: false,
  },
];

const EMPIRE_ROLE_METADATA: (language?: CustomLanguageRoleSelect) => RoleMetadata[] = language => [
  {
    title: language?.directory?.owner ?? 'Owner',
    subtitle: '',
    description:
      'View and edit access to all private & public teams, company settings, and has the ability to delete the company account.',
    roleCode: RoleCode.owner,
    isImplementer: false,
  },
  {
    title: language?.directory?.admin ?? 'Admin',
    subtitle: '',
    description: 'View and edit access to all private & public teams, and company settings.',
    roleCode: RoleCode.admin,
    isImplementer: false,
  },
  {
    title: language?.directory?.manager ?? 'Manager',
    subtitle: '',
    description: 'View and edit access to assigned teams and can create new teams.',
    roleCode: RoleCode.manager,
    isImplementer: false,
  },
  {
    title: language?.directory?.managee ?? 'Managee',
    subtitle: '',
    description: 'View and edit access to assigned teams.',
    roleCode: RoleCode.managee,
    isImplementer: false,
  },
  {
    title: language?.directory?.implementer ?? 'Coach',
    subtitle: '(Free)',
    description: `View and edit access to the entire company account but cannot own ${
      language?.rock?.items ?? 'Commitments'
    }, ${language?.issue?.items ?? 'Issues'}, and ${language?.todo?.items ?? 'To-Dos'}.`,
    roleCode: RoleCode.owner,
    isImplementer: true,
  },
  {
    title: language?.directory?.observer ?? 'Observer',
    subtitle: '(Free)',
    description: `View-only access to assigned teams and cannot own  ${language?.rock?.items ?? 'Commitments'}, ${
      language?.issue?.items ?? 'Issues'
    }, and ${language?.todo?.items ?? 'To-Dos'}.`,
    roleCode: RoleCode.observer,
    isImplementer: false,
  },
];

const FIREPROOF_ROLE_METADATA: (language?: CustomLanguageRoleSelect) => RoleMetadata[] = language => [
  {
    title: language?.directory?.owner ?? 'Owner',
    subtitle: '',
    description:
      'View and edit access to all private & public teams, company settings, and has the ability to delete the company account.',
    roleCode: RoleCode.owner,
    isImplementer: false,
  },
  {
    title: language?.directory?.admin ?? 'Admin',
    subtitle: '',
    description: 'View and edit access to all private & public teams, and company settings.',
    roleCode: RoleCode.admin,
    isImplementer: false,
  },
  {
    title: language?.directory?.manager ?? 'Manager',
    subtitle: '',
    description: 'View and edit access to assigned teams and can create new teams.',
    roleCode: RoleCode.manager,
    isImplementer: false,
  },
  {
    title: language?.directory?.managee ?? 'Managee',
    subtitle: '',
    description: 'View and edit access to assigned teams.',
    roleCode: RoleCode.managee,
    isImplementer: false,
  },
  {
    title: language?.directory?.implementer ?? 'Coach',
    subtitle: '(Free)',
    description: `View and edit access to the entire company account but cannot own ${
      language?.rock?.items ?? 'Flames'
    }, ${language?.issue?.items ?? 'Action Items'}, and ${language?.todo?.items ?? 'Fires'}.`,
    roleCode: RoleCode.owner,
    isImplementer: true,
  },
  {
    title: language?.directory?.observer ?? 'Observer',
    subtitle: '(Free)',
    description: `View-only access to assigned teams and cannot own ${language?.rock?.items ?? 'Flames'}, ${
      language?.issue?.items ?? 'Action Items'
    }, and ${language?.todo?.items ?? 'Fires'}.`,
    roleCode: RoleCode.observer,
    isImplementer: false,
  },
];

export function getRolesMetadata(bos: BusinessOperatingSystem, language?: CustomLanguage): RoleMetadata[] {
  switch (bos) {
    case BusinessOperatingSystem.pinnacle:
      return PINNACLE_ROLE_METADATA(language);
    case BusinessOperatingSystem.empire:
      return EMPIRE_ROLE_METADATA(language);
    case BusinessOperatingSystem.fireproof:
      return FIREPROOF_ROLE_METADATA(language);
    case BusinessOperatingSystem.ninetyOS:
      return NINETYOS_ROLE_METADATA(language);
    case BusinessOperatingSystem.eos:
    default:
      return EOS_ROLE_METADATA(language);
  }
}
