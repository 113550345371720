import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  filter,
  interval,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { CreateDialogInput } from '@ninety/ui/legacy/shared/models/_shared/create-dialog-input-params';
import { DialogMode, DialogModeClass } from '@ninety/ui/legacy/shared/models/_shared/dialog-mode-types';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';

import { UniversalCreateComponent } from '../components/universal-create/universal-create.component';

const DEFAULT_DIALOG_CLASS = 'create-dialog';
const DEFAULT_DIALOG_MODE = DialogMode.mini;
const DIALOG_MODE_CLASSES = Object.values(DialogModeClass);

type CreateDialogResponse = Item | Item[] | null;
type CreateDialogOpenResponse = Observable<{
  afterClosed: () => Observable<CreateDialogResponse>;
  backdropClick: () => Observable<void>;
}>;
type CreateDialogCloseResponse = Observable<CreateDialogResponse | null>;

/**
 * This service acts as an abstraction over the MatDialog.  Instead of directly
 * interacting with the dialog reference, we hide the reference and create our
 * own event streams to expose for things like notifying when the dialog
 * has closed or the backdrop has been clicked. This prevents breaking
 * subscriptions when we close and re-initialize the create-dialog.
 *
 * Per the requirements for our UI/UX redesign, we only want a backdrop for
 * the fully expanded dialog mode, the minimized and collapsed views should
 * not prevent app interactions, so we need to disable the backdrop for
 * those views.
 *
 * But, Mat-Dialog only allows setting `hasBackdrop` during dialog creation.
 *
 * To circumvent this, we open a new modal when we need to change an initialization
 * only value, such as `hasBackdrop`, otherwise we customize by toggling classes
 * and updating the position, such as when toggling between mini & collapsed.
 */
@Injectable({ providedIn: 'root' })
export class CreateDialogService {
  private dialogRef!: MatDialogRef<UniversalCreateComponent> | null;

  private readonly _currentMode$ = new BehaviorSubject<DialogMode>(null);
  readonly currentMode$ = this._currentMode$.asObservable();

  private readonly _isOpen$ = new ReplaySubject<boolean>(1);
  public readonly isOpen$ = this._isOpen$.asObservable();
  private readonly closed$ = new Subject<CreateDialogResponse>();
  public readonly backdropClicked$ = new Subject<void>();

  public createOpenEvent$ = new Subject<void>();

  constructor(private legacyDialog: MatDialog, private stateService: StateService) {}

  private getConfig(mode: DialogMode): Partial<MatDialogConfig> {
    switch (mode) {
      case DialogMode.mini:
        return {
          panelClass: [DEFAULT_DIALOG_CLASS, DialogModeClass.mini],
          hasBackdrop: false,
          position: {
            top: 'auto',
            left: 'auto',
            bottom: '0',
            right: '0',
          },
        };
      case DialogMode.collapsed:
        return {
          panelClass: [DEFAULT_DIALOG_CLASS, DialogModeClass.collapsed],
          hasBackdrop: false,
          position: {
            top: 'auto',
            left: 'auto',
            bottom: '0',
            right: '0',
          },
        };
      default:
        return {
          panelClass: [DEFAULT_DIALOG_CLASS, DialogModeClass.expanded],
          hasBackdrop: true,
          disableClose: true,
        };
    }
  }

  open(data?: CreateDialogInput, mode?: DialogMode): CreateDialogOpenResponse {
    mode ??= this.stateService.currentUser!.settings.dialogModePreference || DEFAULT_DIALOG_MODE;

    // Make sure dialogRef.afterClosed has ran before opening new dialog
    const observable$ = !!this.dialogRef?.componentInstance ? this.close() : of(null);

    return observable$.pipe(
      tap(_ => {
        this._currentMode$.next(mode);
        this._isOpen$.next(true);
        this.createOpenEvent$.next();

        this.dialogRef = this.legacyDialog.open(UniversalCreateComponent, {
          ...this.getConfig(mode),
          data,
        });

        if (mode === DialogMode.expanded) {
          this.dialogRef
            .backdropClick()
            .pipe(
              take(1),
              tap(() => this.backdropClicked$.next())
            )
            .subscribe();
        }
      }),
      /**
       * Since the dialog reference changes, the callers will lose the reference
       * to the active dialog. So we create a separate stream and pass back a hook
       * to maintain the regular mat-dialog behavior.
       */
      map(() => ({
        afterClosed: () => this.closed$.asObservable(),
        backdropClick: () => this.backdropClicked$.asObservable(),
      }))
    );
  }

  close(result?: CreateDialogResponse): CreateDialogCloseResponse {
    if (!this.dialogRef) return of(null);

    this.dialogRef
      .afterClosed()
      .pipe(
        tap(_ => {
          this.dialogRef = null;
          this._isOpen$.next(false);
          this.closed$.next(result);
        })
      )
      .subscribe();

    this.dialogRef.close(result);

    return this.closed$.asObservable().pipe(take(1));
  }

  changeMode(nextMode: DialogMode, data: CreateDialogInput): void {
    if (!this.dialogRef) return;

    if (this._currentMode$.getValue() === DialogMode.expanded || nextMode === DialogMode.expanded) {
      // Prevents weird border/box-shadow rendering artifact that persists a few frames after closing
      this.dialogRef.addPanelClass('hidden');

      this.dialogRef
        .afterClosed()
        .pipe(
          tap(() => (this.dialogRef = null)),
          switchMap(() => this.open(data, nextMode))
        )
        .subscribe();

      // Note: Don't use `this.close()` because we don't want to emit like a regular close
      this.dialogRef.close();

      return;
    }

    // Don't need to change hasBackDrop, just update class and position

    this._currentMode$.next(nextMode);

    let nextClass: DialogModeClass;
    switch (nextMode) {
      case DialogMode.mini:
        nextClass = DialogModeClass.mini;
        break;
      case DialogMode.collapsed:
        nextClass = DialogModeClass.collapsed;
        break;
    }

    this.dialogRef.updatePosition(this.getConfig(nextMode)?.position);
    this.dialogRef.removePanelClass(DIALOG_MODE_CLASSES);
    this.dialogRef.addPanelClass(nextClass);
  }

  handleUnsafeClose() {
    // Check in next macro-task whether the dialog still has an instance on it
    // If not, that means it was closed unsafely, possibly by another
    // component calling MatDialog.closeAll().  Update service state
    // here to reflect it being closed.
    interval(0)
      .pipe(
        take(1),
        filter(() => !this.dialogRef?.componentInstance),
        tap(() => {
          this._isOpen$.next(false);
          this.closed$.next(void 0);
        })
      )
      .subscribe();
  }
}
