<div class="phase-list-container">
  <div class="top-content">
    <div class="header">
      <div class="title">{{ title$ | ngrxPush }}</div>
      <ninety-button flat icon class="close-btn" (click)="close()">
        <terra-icon icon="x" size="20" />
      </ninety-button>
    </div>
    <ng-container *ngIf="isTrialing$ | ngrxPush">
      <div class="days-remaining" *ngrxLet="daysLeftInTrial$ as daysLeft" [class.week-left]="daysLeft <= 7">
        <terra-icon *ngIf="daysLeft <= 14" icon="warning-triangle" variant="fill" size="16" />
        <div [class.regular-text]="daysLeft <= 14">{{ daysLeft }} days remaining in your trial</div>
        <a *ngIf="isManagerOrAbove$ | ngrxPush" (click)="goToBilling()" class="subscribe-now">Subscribe now</a>
      </div>
    </ng-container>

    <div class="subtitle">{{ subtitle$ | ngrxPush }}</div>
  </div>

  <div class="phase-list" *ngrxLet="phases$ as phases">
    <ninety-phase
      *ngFor="let phase of phases; let i = index"
      [phase]="phase"
      [previousPhaseTitle]="phases[i - 1]?.title"
      (click)="selectPhase(phase)" />
  </div>
</div>
