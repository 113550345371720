import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SortingTracker } from '../../_shared/models/_shared/sorting-tracker';
import { User } from '../../_shared/models/_shared/user';
import { SortDirection } from '../../_shared/models/enums/sort-direction';
import { Sorted } from '../../_shared/models/enums/sorted';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  static changeSortDirection(sorter: SortingTracker, property: string, direction?: Sorted): void {
    if (direction != null) {
      sorter[property] = direction;
    } else if (sorter[property] === Sorted.descending) {
      sorter[property] = Sorted.false;
    } else {
      sorter[property]++;
    }
    Object.keys(sorter).forEach(k => {
      if (k !== property) sorter[k] = Sorted.false;
    });
  }

  static getCurrentSortProperty(obj: SortingTracker): string | undefined {
    return Object.keys(obj).find((key: string) => obj[key] === Sorted.ascending || obj[key] === Sorted.descending);
  }

  static sortDirectionToSorted(sortDirection: SortDirection): Sorted {
    switch (sortDirection) {
      case SortDirection.ASC:
        return Sorted.ascending;
      case SortDirection.DESC:
        return Sorted.descending;
      default: //null
        return Sorted.false;
    }
  }

  static sortedToSortDirection(sorted: Sorted): SortDirection {
    switch (sorted) {
      case Sorted.descending:
        return SortDirection.DESC;
      case Sorted.ascending:
      default:
        return SortDirection.ASC;
    }
  }

  /** Sorts an array of objects by ordinal property */
  static sortByOrdinal<T extends { ordinal: number }>(list: Array<T>): Array<T> {
    return list.sort((a, b) => (a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0));
  }

  constructor(private usersService: UserService) {}

  sortAlphabetically<T>(list: Array<T>, property: string, sortOrder: Sorted): Observable<Array<T>> {
    const ascending = sortOrder === Sorted.ascending;
    return of(
      list.sort((a, b) => {
        const stringA = a[property].toLocaleLowerCase();
        const stringB = b[property].toLocaleLowerCase();
        return ascending
          ? stringA < stringB
            ? -1
            : stringB < stringA
            ? 1
            : 0
          : stringB < stringA
          ? -1
          : stringA < stringB
          ? 1
          : 0;
      })
    );
  }

  sortAlphabeticallyByUser<T>(list: Array<T>, property: 'user' | 'userId', sortOrder: Sorted): Observable<Array<T>> {
    return of(
      list.sort((a, b) => {
        let userNameA: string;
        let userNameB: string;
        let userA: User;
        let userB: User;

        if (property === 'userId') {
          userA = this.usersService.allUsersAndImplementers.find((u: User) => u._id === a[property]);
          userB = this.usersService.allUsersAndImplementers.find((u: User) => u._id === b[property]);
        }

        userNameA = UserService.userName(userA ? userA : a[property])?.toLocaleLowerCase();
        userNameB = UserService.userName(userB ? userB : b[property])?.toLocaleLowerCase();

        return sortOrder === Sorted.ascending
          ? userNameA < userNameB
            ? -1
            : userNameB < userNameA
            ? 1
            : 0
          : userNameB < userNameA
          ? -1
          : userNameA < userNameB
          ? 1
          : 0;
      })
    );
  }

  // is there way to say <T extends  {[keyof T]]: User}>
  sortAlphabeticallyByUserName<T extends { user?: User; managee?: User; manager?: User }>(
    list: Array<T>,
    key: 'user' | 'managee' | 'manager' | 'ownedByUser' = 'user',
    sortOrder: Sorted
  ): Observable<Array<T>> {
    return of(
      list.sort((a, b) => {
        const userAMetaData = a[key] && a[key].metadata;
        const userBMetaData = b[key] && b[key].metadata;
        const userA = userAMetaData
          ? `${a[key].metadata.name.first}${a[key].metadata.name.last}`.toLocaleLowerCase()
          : 0;
        const userB = userBMetaData
          ? `${b[key].metadata.name.first}${b[key].metadata.name.last}`.toLocaleLowerCase()
          : 0;
        return sortOrder === Sorted.ascending
          ? userA < userB
            ? -1
            : userB < userA
            ? 1
            : 0
          : userB < userA
          ? -1
          : userA < userB
          ? 1
          : 0;
      })
    );
  }

  sortByDate<T>(list: Array<T>, property: string, sortOrder: Sorted): Observable<Array<T>> {
    return of(
      list.sort((a, b) => {
        // @ts-ignore
        const dateA = a[property] ? new Date(a[property]).getTime() : 0;
        // @ts-ignore
        const dateB = b[property] ? new Date(b[property]).getTime() : 0;
        return sortOrder === Sorted.ascending ? dateA - dateB : dateB - dateA;
      })
    );
  }

  sortByProperty<T>(list: Array<T>, property: string, sortOrder: Sorted): Observable<Array<T>> {
    return of(
      list.sort((a, b) => {
        const propA = a[property];
        const propB = b[property];
        return sortOrder === Sorted.ascending
          ? propA < propB
            ? -1
            : propB < propA
            ? 1
            : 0
          : propB < propA
          ? -1
          : propA < propB
          ? 1
          : 0;
      })
    );
  }

  sortByNumber<T>(list: Array<T>, property: string, sortOrder: Sorted): Observable<Array<T>> {
    return of(
      list.sort((a, b) => {
        const numA = a[property] ? parseInt(a[property], 10) : Number.NEGATIVE_INFINITY;
        const numB = b[property] ? parseInt(b[property], 10) : Number.NEGATIVE_INFINITY;
        return sortOrder === Sorted.ascending
          ? numA < numB
            ? -1
            : numB < numA
            ? 1
            : 0
          : numB < numA
          ? -1
          : numA < numB
          ? 1
          : 0;
      })
    );
  }
}

export enum SortingServiceMethod {
  sortAlphabetically = 'sortAlphabetically',
  sortAlphabeticallyByUser = 'sortAlphabeticallyByUser',
  sortAlphabeticallyByUserName = 'sortAlphabeticallyByUserName',
  sortByDate = 'sortByDate',
  sortByProperty = 'sortByProperty',
}
