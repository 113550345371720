import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  static build(source: Object, filterOutNullValues = false): HttpParams {
    let params: HttpParams = new HttpParams();
    Object.keys(source).forEach((key: string) => {
      const value: any = source[key];
      if (filterOutNullValues ? value != null : value !== undefined) {
        params = params.append(key, `${value}`);
      }
    });
    return params;
  }
}
