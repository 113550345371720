import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectDataImportSelectedIcon,
  selectDataImportTemplateOptions,
} from '@ninety/data-import/_state/data-import-state.selectors';
import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportType } from '@ninety/data-import/models/data-import-types';
import { selectTeamFilterOptions } from '@ninety/layouts/_state/filterbar/filterbar-state.selectors';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';

@Component({
  selector: 'ninety-data-import',
  template: `
    <ninety-action-bar>
      <div left class="import-selects">
        <ninety-quick-filter
          [options]="templateOptions$ | ngrxPush"
          pillTitle="Import"
          [pillIcon]="icon$ | ngrxPush"
          (selectionChange)="selectTemplate(asDataImportType($event))"></ninety-quick-filter>

        <ninety-quick-filter
          [options]="teamOptions$ | ngrxPush"
          pillTitle="Team"
          (selectionChange)="selectTeam(asTeam($event))"></ninety-quick-filter>
      </div>

      <ninety-button stroked primary (click)="downloadCSVTemplate()">
        <terra-icon icon="download" size="20" />
        Download template
      </ninety-button>
    </ninety-action-bar>

    <ninety-one-schema-import></ninety-one-schema-import>
  `,
  styles: [
    `
      .import-selects {
        display: flex;
        gap: 8px;
      }
    `,
  ],
})
export class DataImportComponent implements OnInit, OnDestroy {
  icon$ = this.store.select(selectDataImportSelectedIcon);
  templateOptions$ = this.store.select(selectDataImportTemplateOptions);
  teamOptions$ = this.store.select(selectTeamFilterOptions);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(DataImportActions.init());
  }

  ngOnDestroy() {
    this.store.dispatch(DataImportActions.destroy());
  }

  selectTemplate(option: DataImportType) {
    this.store.dispatch(DataImportActions.selectTemplate({ option }));
  }

  selectTeam({ _id: teamId }: Team) {
    this.store.dispatch(DataImportActions.selectTeam({ teamId }));
  }

  downloadCSVTemplate() {
    this.store.dispatch(DataImportActions.downloadCSVTemplate());
  }

  asDataImportType = (option): DataImportType => option as DataImportType;
  asTeam = (option): Team => option as Team;
}
