import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TerraInputBoolean } from '../../models/terra-input-boolean.models';
import { TerraBooleanAttributeModule } from '../../pipes/boolean-attribute/boolean-attribute.module';

export type TerraSwitchColor = 'brand' | 'green';

let switchUniqueId = 1;

@Component({
  selector: 'terra-switch',
  standalone: true,
  exportAs: 'terraSwitch',
  templateUrl: './terra-switch.component.html',
  styleUrls: ['./terra-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // Removes tabindex on the host - applies it instead to the input checkbox
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TerraSwitchComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, TerraBooleanAttributeModule],
})
export class TerraSwitchComponent implements OnInit, ControlValueAccessor {
  /**
   * Reference to the wrapping div element where classes will be added to
   */
  @ViewChild('switch', { static: true }) private _switch!: ElementRef<HTMLElement>;

  /**
   * Reference to internal checkbox input
   */
  @ViewChild('checkboxInput', { static: true }) private _checkboxInput!: ElementRef<HTMLInputElement>;

  /** Emits event when the switch's `checked` value changes. */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Color of the switch
   * @default brand
   */
  @Input() get color(): TerraSwitchColor {
    return this._color;
  }
  set color(value: TerraSwitchColor) {
    this._removeClass('color', this._color);
    this._color = value;
    this._addClass('color', this._color);
    this._changeDetectorRef.markForCheck();
  }
  private _color: TerraSwitchColor = 'brand';

  /**
   * Set the disabled state of the switch
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Optional input to override aria-label
   * @default null
   */
  @Input('aria-label') ariaLabel: string | null = null;
  /**
   * Optional input to override aria-labelledby
   * @default null
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  /**
   * Optional input to override aria-describedby
   * @default null
   */
  @Input('aria-describedby') ariaDescribedby: string | null = null;

  /**
   * State of the checkbox input
   * @default false
   */
  protected _checked = false;

  /** Tabindex of the switch */
  protected _tabIndex = 0;

  /** Used to set a unique id for the switch for aria referencing purposes. */
  protected _switchId = `terra-switch-${switchUniqueId++}`;

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onTouched = (_: any) => {};

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    @Attribute('tabindex') tabIndex: string
  ) {
    this._tabIndex = parseInt(tabIndex) || 0;
  }

  ngOnInit() {
    this._addClass('color', this.color);
  }

  /**
   * Helper function for adding proper styles to the switch
   */
  private _addClass(modifier: string, value: string): void {
    if (this._switch) {
      this._renderer.addClass(this._switch.nativeElement, `terra-switch--${modifier}-${value}`);
    }
  }

  /**
   * Helper function to remove previously added classes from the switch
   */
  private _removeClass(modifier: string, value: string): void {
    if (this._switch) {
      this._renderer.removeClass(this._switch.nativeElement, `terra-switch--${modifier}-${value}`);
    }
  }

  /**
   * Updates the internal checkbox state and emits change events*/
  public toggle(): void {
    if (!this.disabled) {
      this._checked = !this._checked;
      this._checkboxInput.nativeElement.checked = this._checked;
      this._emitChangeEvent();
    }
  }

  /**
   * Focus the switch
   */
  public focus(): void {
    this._checkboxInput.nativeElement.focus();
  }

  /**
   * Blur the switch
   */
  public blur(): void {
    this._checkboxInput.nativeElement.blur();
    this._onTouched(true);
  }

  /**
   * Triggered when the label for the input is clicked */
  protected _inputChange($event: Event): void {
    $event.stopPropagation();
    this.toggle();
  }

  /**
   * @ignore
   * Sends change related events */
  private _emitChangeEvent(): void {
    this._onChange(this._checked);
    this.change.emit(this._checked);
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(value: any): void {
    this._checked = !!value;
    this._checkboxInput.nativeElement.checked = this._checked;
    this._changeDetectorRef.markForCheck();
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetectorRef.markForCheck();
  }
}
