import { LoggerConfiguration } from '@datadog/browser-logs';

import { environment } from '@ninety/ui/web/environments';

export const DATADOG_DEFAULT_LOGGER_CONF: LoggerConfiguration = {
  /*
    IMPORTANT:
    If you only define `console` as the handler type, then datadog WILL NOT forward the logs to the Datadog service,
    regardless of whether forwardConsoleLogs: 'all' is set at initialization.
  */
  handler: environment.production ? 'http' : ['http', 'console'],
  level: environment.production ? 'info' : 'debug',
  // context: {},
};
