import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ninetyChatWindowHeight]',
})
export class ChatWindowHeightDirective implements AfterViewInit {
  private el: HTMLElement;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement as HTMLElement;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setHeight();
      // wait until after animation runs.
    }, 300);
  }

  private setHeight() {
    const wrapper = document.getElementById('conversation-window');
    const height = wrapper?.clientHeight;
    this.renderer.setStyle(this.el, 'height', `${height}px`);
    //const conversation = document.getElementById('maz-conversation');
    // conversation.scrollTop = conversation.scrollHeight;
    this.el.scrollTop = this.el.scrollHeight;
  }
}
