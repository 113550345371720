import { Component } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { MeetingAgendaService } from '@ninety/ui/legacy/core/services/meeting-agenda.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import {
  DeleteTeamCustomAgenda,
  TeamMeetingAgendaUpdateByType,
  TeamMeetingAgendaPush,
  Team,
  MeetingAgendas,
} from '@ninety/ui/legacy/shared/models/_shared/team';
import { Company } from '@ninety/ui/legacy/shared/models/company/company';
import { MeetingAgendaType } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda-type.enum';

@Component({
  template: `<ninety-meeting-agendas
    class="meeting-settings"
    *ngIf="selectedTeam$ | ngrxPush as selectedTeam"
    [selectedTeam]="selectedTeam"
    [hidePushButton]="true"
    (saveTeamAgendaByType)="updateTeamMeetingAgendaByType($event)"
    (deleteTeamCustomAgenda)="deleteTeamMeetingAgenda($event)"
    (pushTeamAgenda)="pushTeamAgenda($event)"
    (getResetAgendas)="getResetAgendas()"
    [resetAgendas]="resetAgendas$ | ngrxPush"></ninety-meeting-agendas>`,
  styles: [
    `
      .meeting-settings {
        height: calc(100vh - 160px);
        overflow-y: auto;
      }
    `,
  ],
})
export class TeamMeetingAgendasComponent {
  selectedTeam$ = this.filterService.selectedTeam$.asObservable().pipe(
    filter(team => !!team),
    distinctUntilChanged((a, b) => a?._id === b?._id)
  );
  private _resetAgendas = new BehaviorSubject<MeetingAgendas>(null);
  resetAgendas$ = this._resetAgendas.asObservable();

  constructor(
    public filterService: FilterService,
    private teamService: TeamService,
    private companyService: CompanyService,
    private stateService: StateService,
    private spinnerService: SpinnerService,
    private agendasService: MeetingAgendaService
  ) {
    this.spinnerService.stop();
  }

  updateTeamMeetingAgendaByType(update: TeamMeetingAgendaUpdateByType): void {
    const update$ = this.teamService.updateTeamMeetingAgendaByType(update);
    this.spinnerService.spinWhile(update$).subscribe();
  }

  deleteTeamMeetingAgenda({ teamId, agendaId }: DeleteTeamCustomAgenda): void {
    const update$ = this.teamService.deleteCustomAgenda(teamId, agendaId);
    this.spinnerService.spinWhile(update$).subscribe();
  }

  pushTeamAgenda({ teamId, agendaType, agendaId }: TeamMeetingAgendaPush): void {
    const requests = [this.teamService.getV4Teams(), this.companyService.getCompanyById(this.stateService.company._id)];
    const request =
      agendaType === MeetingAgendaType.custom
        ? this.teamService.pushCustomAgenda(teamId, agendaId)
        : this.teamService.pushAgendaByType(teamId, agendaType);
    this.spinnerService
      .spinWhile(request)
      .pipe(switchMap(() => this.spinnerService.spinWhile(forkJoin(requests))))
      .subscribe({
        next: ([teams, cia]: [Team[], Company]) => {
          this.stateService.company.meetingAgendas = cia.meetingAgendas;

          this.teamService.allTeams$.next(teams);
          this.teamService.allTeamsMap$.next(teams.reduce((map, t) => map.set(t._id, t), new Map()));
          const selectedTeam = teams.find(t => t._id === this.filterService.selectedTeam$.value._id);
          if (selectedTeam) this.filterService.selectedTeam$.next(selectedTeam);
        },
      });
  }

  getResetAgendas(): void {
    this.agendasService.getCurrentCompanyAgendas().subscribe({
      next: agendas => this._resetAgendas.next(agendas),
    });
  }
}
