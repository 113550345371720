import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridItemHTMLElement, GridStack, GridStackNode } from 'gridstack';
import { ColumnOptions, GridStackWidget } from 'gridstack/dist/types';

import { LegacyVTOGridLayoutActions } from '@ninety/vto/services/legacy-vto-grid-layout-actions.service';

import { GridLayoutActions } from '../_state/grid-layout-state.actions';
import { GridItemComponent } from '../components/grid-item/grid-item.component';
import { GridItemContext } from '../models/grid-item-context.model';
import { GridTemplate } from '../models/grid-template.model';
import { GridWidgetPosition } from '../models/grid-widget-position';
import { GridWidget } from '../models/grid-widget.model';
import { GridOptions } from '../models/grid.options';
import { RegisteredComponentsMap, RegisteredComponentsMapValue } from '../models/registered-components-map';

import { GridStackFactoryService } from './grid-stack-factory.service';

/**
 * Wraps a GridStack instance to expose Angular-specific mappings of functions.
 *
 * Note, the GridStack documentation has notes on how to extend the "engine" of the library. The engine is concerned
 * with how collisions are resolved and how a node is inserted into the grid. This is distinct from rendering and
 * destroying components, which is the job of the object GridStack. Thus, we choose to use composition instead of
 * modifying the internals
 *
 * Use composition not inheritance => only expose the methods that have been mapped to Angular counterparts
 */
@Injectable({
  providedIn: 'root',
})
export class NgGridStackService {
  private readonly idToTemplateModel = new RegisteredComponentsMap();

  private grid: Readonly<GridStack>;
  private options: Readonly<GridOptions>;
  private template: Readonly<GridTemplate>;

  /**
   * The id of the widget that was detached from the grid and rendered as a singleton. Expected to be null if the
   * singleton view is not active.
   */
  private detachedSingletonTemplateId: string;

  constructor(
    private readonly gridStackFactory: GridStackFactoryService,
    private store: Store,
    private legacyVTOGridLayoutActions: LegacyVTOGridLayoutActions
  ) {}

  setGridTemplate(template: GridTemplate) {
    // I'd love to store the template in the store, but complex objects which are expected to mutate (such as
    // ViewContainerRef) are advised against in the NGRX docs. Note, they will also cause the only-in-dev
    // modify-frozen-object error.
    this.template = template;
  }

  /** Create the grid and initialize it with the given options. */
  init(options: Readonly<GridOptions> = GridOptions.defaults()) {
    this.options = options;

    this.grid = this.gridStackFactory.createGridStack(options);
    this.grid.on('change', (_event: Event, nodes: GridStackNode[]) => {
      // A weird bug triggers a gridstack change event when a custom section is updated? But its an empty event - ignore
      if (nodes?.length > 0) {
        const models = this.registerGridStackChange(nodes);
        this.store.dispatch(GridLayoutActions.userUpdatedLayout({ models }));
        this.legacyVTOGridLayoutActions.emitGridSettingsUpdate(models);
      }
    });
  }

  /** Enable drag & drop + resize */
  enable() {
    this.grid.enable();
  }

  /** Disable drag & drop + resize */
  disable() {
    this.grid.disable();
  }

  /**
   * Destroy the grid and clear out relevant data. Should be called whenever a grid component is destroyed (as it is in
   * GridLayoutComponent)
   */
  destroy() {
    // Must be called to trigger ngOnDestroy for components loaded in grid
    this.idToTemplateModel.clear();

    this.detachedSingletonTemplateId = null;
    this.grid?.destroy();
  }

  /**
   * Loads a set of models into the grid. Adds new widgets, updates existing widgets positions, and removes no longer
   * visible widgets.
   */
  load(models: GridWidget<unknown>[]) {
    const layout: GridStackWidget[] = models.map(m => this.prepareItemForGrid(m)); // TODO use new TS `satisfies`

    // Upon updating to Gridstack 7.3.0, the first argument changes from `Gridstack`->`HTMLElement`, it also rendered an empty first row.
    this.grid.load(layout, (grid, widget, isAdd) =>
      // eslint-disable-next-line
      isAdd ? this.addWidget(grid as any, widget) : this.removeWidget(grid as any, widget)
    );
  }

  /**
   * Updates the models with the current state of the grid and then returns them.
   */
  getUpdatedModels(): GridWidget<unknown>[] {
    const currentNodes: GridStackWidget[] = this.grid.save(false, false) as GridStackWidget[];
    return this.registerGridStackChange(currentNodes);
  }

  /** Update the number of available columns in the grid. Will resize widgets */
  updateColumnCount(count: number, opts?: ColumnOptions) {
    this.grid.column(count, opts);
  }

  /** Update the height of every row (and thus the cells inside them) */
  updateCellHeight(cellHeight: number) {
    this.grid.cellHeight(cellHeight, true);
  }

  /**
   * `float` controls how items compact upwards when there is empty space and a widget could fit in the space above it.
   * When disabled (the default), if a widget can compact upwards, it will. When enabled, widgets will not compact
   * upwards and will instead "float" in the current spot.
   */
  updateFloatMode(enabled: boolean) {
    this.grid.float(enabled);
  }

  /** Attempts to reclaim open space. Most effective when float is true. */
  compact() {
    this.grid.compact();
  }

  /**
   * Detaches the component from the grid and renders it in the alternate view. Component is full width and height is
   * set by content. Used to render a single component opposite of the detail view.
   */
  setSingleton(templateId: string) {
    // Do nothing if the singleton is already set to the same id passed to this method.
    if (this.detachedSingletonTemplateId) {
      if (this.detachedSingletonTemplateId === templateId) return;
      else
        throw new Error(
          `Tried to set singleton, but there was already a detached id (${this.detachedSingletonTemplateId})`
        );
    }

    const template = this.idToTemplateModel.get(templateId);
    if (!template) throw new Error(`Tried to render singleton, but no template found for id ${templateId}`);

    // Store which template was detached to easily reattach it later
    this.detachedSingletonTemplateId = templateId;

    // Find and store the index where grid-stack originally rendered the component
    this.detachFromGridAndAttachToAlternate(template);
  }

  /**
   * Reattaches the component to the grid and destroys the alternate view. Grid is rendered as normal once complete.
   */
  clearSingleton() {
    if (!this.detachedSingletonTemplateId)
      throw new Error('Tried to clear singleton view, but there was no detached id');

    // Detach the component from the singleton view
    this.template.alternateTemplateRef.detach();

    // Render the component ref that was created during addWidget (explicitly don't render the ref returned by detach)
    const toAttach = this.idToTemplateModel.get(this.detachedSingletonTemplateId);
    const existingHost = toAttach.gridItemComponentRef.hostView;
    this.template.gridViewRef.insert(existingHost, toAttach.previousIndex);

    this.detachedSingletonTemplateId = null;
  }

  /**
   * Custom implementation of a small-width mode, referred to as "one column mode" in GridStack. Causes all widgets to
   * be rendered in a single column, with a width of 100%. This is used on small viewports. GridStack's built-in one
   * column mode is not used because it still uses the height set by the user. This creates unnecessary empty space and
   * causes overflow to scroll, both undesirable at small viewports/mobile.
   */
  enterOneColumMode() {
    // Get the ordered list of widgets from gridstack
    const currentWidgets = this.grid.save(false, false) as GridStackWidget[];

    // Attach every grid-item component, in order, to the singleton ref
    currentWidgets.forEach((widget, index) => {
      const templateId = widget.id as string;
      const template = this.idToTemplateModel.get(templateId);

      this.detachFromGridAndAttachToAlternate(template, index);
    });
  }

  /**
   * Reverses the effects of enterOneColumnMode. Detaches all components from the singleton view and reattaches them
   * to the grid in the order they were previously in.
   */
  exitOneColumnMode() {
    this.idToTemplateModel.orderedByPreviousIndex().forEach(template => {
      const index = template.previousIndex;
      const existingHost = template.gridItemComponentRef.hostView;

      this.template.alternateTemplateRef.detach();

      this.template.gridViewRef.insert(existingHost, index);
    });
  }

  /**
   * Suspends GridStack's implementation of one-column mode.
   *
   * During layout mode, it can be useful to disable the one-column mode so that the user can see the grid at any width.
   */
  disableOneColumnMode() {
    this.grid.opts.oneColumnSize = 1; // Work around - an element width of 1px will never happen
  }

  /**
   * Re-enables GridStack's implementation of one-column mode.
   *
   * Works because this.options does not get reset by disableOneColumnMode.
   */
  reEnableOneColumnMode() {
    this.grid.opts.oneColumnSize = this.options.oneColumnSize;
  }

  private detachFromGridAndAttachToAlternate(template: RegisteredComponentsMapValue, index?: number) {
    const detachedIndex = this.template.gridViewRef.indexOf(template.gridItemComponentRef.hostView);
    if (detachedIndex === -1) throw new Error('Implementation fault - could not find component index to detach');

    // Store the index, so it can be used to reattach the component to the grid view
    template.previousIndex = detachedIndex;

    // Detach the component from the grid view
    this.template.gridViewRef.detach(detachedIndex);

    // Render the component in the singleton view
    this.template.alternateTemplateRef.insert(template.gridItemComponentRef.hostView, index);
  }

  /**
   * Register a model in state and ensure it meets GridStacks criteria
   */
  private prepareItemForGrid(frozenModel: GridWidget<unknown>): GridWidgetPosition {
    const model = { ...frozenModel };

    const id = model._id;
    if (!id) throw new Error('Implementation fault - model must have id');

    if (model.position) {
      model.position = GridWidgetPosition.sanitizeInput(model.position, { columnCount: this.options.column as number });
      model.position.id ??= id;
    } else {
      model.position = GridWidgetPosition.Default(id);
    }

    this.idToTemplateModel.registerModel(id, model);

    return model.position;
  }

  /**
   * Render a widget by matching it to a template, wrapping it with grid-item.component, rendering both in the DOM, and
   * registering the addition with GridStack
   */
  private addWidget(grid: GridStack, widget: GridStackWidget): GridItemHTMLElement {
    const id = widget.id as string;
    const template = this.idToTemplateModel.get(id);

    const type = template.model.type;
    let matchingTemplate = this.template.templates.find(t => t.gridItemTemplate === type)?.templateRef;
    if (!matchingTemplate) {
      if (this.template.default) matchingTemplate = this.template.default.templateRef;
      else {
        console.warn(`No grid-item template for type ${type} and there is no default. Skipping rendering`);
        return {} as GridItemHTMLElement; // Calling function does nothing with this reference
      }
    }

    // Render ng-template, temporarily attached to the host
    const context: GridItemContext = { item: { ...template.model } };
    const embeddedTemplateRef = this.template.hostViewRef.createEmbeddedView(matchingTemplate, context);
    embeddedTemplateRef.detectChanges();

    // Create and render the wrapping component, attached to container ref
    const componentRef = this.template.gridViewRef.createComponent(GridItemComponent, {
      projectableNodes: [embeddedTemplateRef.rootNodes],
    });
    componentRef.changeDetectorRef.detectChanges();

    // Maintain reference for removing/ngOnDestroy
    this.idToTemplateModel.registerTemplateRefs(id, componentRef, embeddedTemplateRef);

    // Call Gridstack to add widget WITH HTML ELEMENT AS PARAM
    const element: HTMLElement = componentRef.location.nativeElement;
    return grid.addWidget(element, widget);
  }

  /**
   * Remove a widget by locating its templates in state, explicitly calling destroy() to trigger component lifecycle,
   * and removing the node from GridStacks internals
   */
  private removeWidget(grid: GridStack, widget: GridStackWidget): GridItemHTMLElement {
    const id = widget.id as string;
    const template = this.idToTemplateModel.get(id);

    // Destroy managed templates
    if (!template.embeddedTemplateRef) console.warn('Implementation error - no embedded template ref to destroy');
    else {
      template.embeddedTemplateRef.destroy();
    }

    if (!template.gridItemComponentRef) console.warn('Implementation error - no loaded component ref to destroy');
    else {
      const nativeElement = template.gridItemComponentRef.location.nativeElement as HTMLElement;
      grid.removeWidget(nativeElement, false);

      template.gridItemComponentRef.destroy();
    }

    // Drop reference from internal map
    this.idToTemplateModel.delete(id);

    return template.gridItemComponentRef?.location?.nativeElement;
  }

  /**
   * Merge the current, non-grid properties of the model with the current grid settings from GridStack.
   */
  private registerGridStackChange(nodes: GridStackWidget[]): GridWidget<unknown>[] {
    return nodes.map(node => {
      const id = node.id as string;
      const position = GridWidgetPosition.sanitizeOutput(node);

      const currentTemplate: RegisteredComponentsMapValue = this.idToTemplateModel.get(id);
      const currentModel: GridWidget<unknown> = currentTemplate.model;

      const newTemplate: RegisteredComponentsMapValue = {
        ...currentTemplate,
        model: {
          ...currentModel,
          position,
        },
      };
      this.idToTemplateModel.set(id, newTemplate);

      return newTemplate.model;
    });
  }
}
