export enum ItemType {
  rock,
  todo,
  issue,
  headline,
  cascadedMessage,
  target,
  milestone,
  learningModule,
  learningSubject,
  learningTopic,
  learningTask,
}
