import { Injectable } from '@angular/core';

export enum StorageKeyPrefix {
  MFA_CONFIRM = 'mfa_confirm_',
  TOP_SEATS_KEY = 'TOP_SEATS_BY_CHART',
}

export interface IStorage {
  exists(name: string): boolean;
  get(name: string): string;
  getAndParse<T>(name: string): T;
  getAll(): { [key: string]: string };
  keys: string[];
  set(name: string, value: any, options?: any): void;
  delete(name: string): void;
  deleteAll(): void;
}

export class StorageService implements IStorage {
  constructor(protected store: Storage) {}

  get keys(): string[] {
    return Object.keys(this.store);
  }

  exists(name: string): boolean {
    return !!this.store.getItem(name);
  }

  get(name: string): string {
    return this.store.getItem(name);
  }

  getAndParse<T>(name: string): T {
    const value = this.store.getItem(name);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value as unknown as T;
    }
  }

  getAll(): { [key: string]: string } {
    return Object.keys(this.store).reduce((obj, k) => {
      if (this.store.hasOwnProperty(k)) {
        obj[k] = this.store.getItem(k);
      }
      return obj;
    }, {});
  }

  set(name: string, value: any): void {
    const _value = typeof value === 'string' ? value : JSON.stringify(value);

    this.store.setItem(name, _value);
  }

  delete(name: string): void {
    this.store.removeItem(name);
  }

  deleteAll(): void {
    this.store.clear();
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends StorageService {
  constructor() {
    super(localStorage);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends StorageService {
  constructor() {
    super(sessionStorage);
  }
}
