import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import Fuse, { IFuseOptions } from 'fuse.js';
import { cloneDeep } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models/confirm-dialog-data';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { TeamDetailInput } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { SortByNamePipe } from '@ninety/ui/legacy/shared/pipes/sort-by-name.pipe';
import { teamsStateActions } from '@ninety/ui/legacy/state/app-entities/teams/teams-state.actions';
import { UsersStateActions } from '@ninety/ui/legacy/state/app-entities/users/users-state.actions';
import {
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { CompanyActions } from '@ninety/ui/legacy/state/app-global/company/company-state.actions';
import { selectCompany } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectUserTeams } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';
import { FeatureFlagFacade, FeatureFlagKeys } from '@ninety/ui/legacy/state/index';

import { NewTeamDialogComponent } from './new-team-dialog/new-team-dialog.component';

@Component({
  selector: 'ninety-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamsComponent implements OnInit, OnDestroy {
  protected readonly enableTeamPageEnhancements$ = this.featureFlags.getFlag(
    FeatureFlagKeys.enableTeamPageEnhancements
  );

  teams$: Observable<(Team & { canEdit: boolean })[]>;

  filteredTeams$: Observable<(Team & { canEdit: boolean })[]> = of([]); // Filtered teams based on search term
  private searchTerm$ = new BehaviorSubject<string>(''); // Initialize with empty search term
  private fuse: Fuse<Team & { canEdit: boolean }> | undefined;
  private fuseOptions: IFuseOptions<Team & { canEdit: boolean }> = {
    keys: ['name'],
    includeScore: true,
    threshold: 0.3, // Adjust this value for fuzziness; lower values mean stricter matching
  };

  canEdit: boolean;
  currentUserTeamIds: string[];
  currentSltTeamId: string;
  currentUserId: string;
  selectedTeam: Team;

  private refresh$ = new Subject<void>();
  company$ = this.store.select(selectCompany).pipe(map(company => cloneDeep(company)));

  private subscriptions = new Subscription();

  constructor(
    public teamService: TeamService,
    public stateService: StateService,
    public legacyDialog: MatLegacyDialog,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private companyService: CompanyService,
    private userService: UserService,
    private sortByNamePipe: SortByNamePipe,
    public spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef,
    private detailService: DetailService<TeamDetailInput>,
    private store: Store,
    private featureFlags: FeatureFlagFacade
  ) {}

  ngOnInit() {
    this.currentSltTeamId = this.stateService.company.seniorLeadershipTeamId;

    // Set current team properties based on changes to company users
    this.subscriptions.add(
      this.stateService.currentCompanyUser$
        .pipe(
          tap(user => {
            this.currentUserId = user._id;
            this.currentUserTeamIds = user.teams.map(t => t.teamId);
          })
        )
        .subscribe()
    );

    // Sort teams
    this.teams$ = this.teamService.teamsWithAllEmbeddedUsers$.pipe(
      filter(m => !!m),
      combineLatestWith(
        this.store.select(selectCurrentUserIsAdminOrOwner),
        this.store.select(selectCurrentUserIsManagerOrAbove),
        this.store.select(selectUserTeams)
      ),
      map(([teams, userIsAdminOrOwner, userIsManagerOrAbove, userTeams]) => {
        const userTeamIds = userTeams?.map(t => t?._id) ?? [];
        const teamAndCanEdit = teams.map(team => {
          const canEdit = userIsAdminOrOwner || (userIsManagerOrAbove && userTeamIds.includes(team._id));
          return { ...team, canEdit };
        });
        return this.sortByNamePipe.transform(teamAndCanEdit);
      })
    );

    // Initialize filteredTeams$ based on teams$ and search term
    this.filteredTeams$ = this.searchTerm$.pipe(
      combineLatestWith(this.teams$),
      map(([searchTerm, teams]) => {
        if (searchTerm.trim() === '') {
          // Return all teams if search term is empty
          return teams;
        }

        this.fuse = new Fuse(teams, this.fuseOptions);
        const results = this.fuse.search(searchTerm);
        const filteredTeams = results.map(result => result.item);

        return filteredTeams;
      })
    );

    // On refresh handler
    this.subscriptions.add(
      this.refresh$
        .pipe(
          switchMap(() =>
            forkJoin([
              this.userService.getUsers(),
              this.teamService.getV4Teams(),
              this.teamService.getTeamUsers(),
              this.userService.getDirectoryUsers(),
            ])
          ),
          tap(() => {
            this.cdr.markForCheck();
          }),
          catchError((error: unknown) => {
            this.cdr.markForCheck();
            return this.errorService.notify(error, 'Failed to get updated teams data, please try refreshing the page.');
          })
        )
        .subscribe()
    );

    this.registerDetailEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerDetailEvents(): void {
    // Set current team based on inputs to detail
    this.subscriptions.add(
      this.detailService
        .getInputs()
        .pipe(
          tap(data => {
            this.selectedTeam = data?.team;
          })
        )
        .subscribe()
    );

    // Persist changes to team name from detail view
    this.subscriptions.add(
      this.detailService.teamUpdate$
        .pipe(
          filter(x => !!x?.name),
          map(x => x.name),
          debounceTime(400),
          distinctUntilChanged(),
          tap((name: string) => this.updateTeam(this.selectedTeam._id, { name }))
        )
        .subscribe()
    );
  }

  selectTeam(team: Team): void {
    if (team._id === this.selectedTeam?._id) {
      this.closeDetailView();
      return;
    }

    this.detailService.open({ pathParts: [DetailType.team, team._id] }).subscribe({
      next: () => this.cdr.markForCheck(),
    });
  }

  closeDetailView(): void {
    this.detailService.close().subscribe();
  }

  openNewTeamDialog() {
    this.legacyDialog
      .open(NewTeamDialogComponent, { panelClass: 'new-team-dialog-container' })
      .afterClosed()
      .pipe(
        filter(t => !!t),
        tap((newTeam: Team) => {
          this.teamService.teamsWithAllEmbeddedUsers$.next([
            ...this.teamService.teamsWithAllEmbeddedUsers$.value,
            newTeam,
          ]);
        }),
        switchMap(() => this.userService.fetchUser(this.stateService.currentUser$?.getValue()?._id)),
        tap(user => {
          this.store.dispatch(UsersStateActions.updateOne({ _id: user._id, changes: user }));
          this.currentUserTeamIds = user.teams.map(t => t.teamId);
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  setSltTeam(team: Team): void {
    if (team.private) {
      //Todo - Confirm that this line of code seems like a bug...
      // this.store.dispatch(CompanyActions.updateCompany({ changes: { seniorLeadershipTeamId: this.currentSltTeamId } }));
      this.errorService.notify(undefined, 'A leadership team cannot be private.');
      return;
    }

    const data: ConfirmDialogData = {
      title: 'Change the Leadership Team',
      message: `This team owns the company's ${this.stateService.language.vto.item} and ${this.stateService.language.rock.items}
        and controls how they're shared with other teams.`,
      confirmButtonText: 'Change',
    };

    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        data,
      })
      .afterClosed()
      .pipe(
        switchMap(result => {
          if (result) {
            this.currentSltTeamId = team._id;
            return this.companyService.update({ seniorLeadershipTeamId: team._id });
          } else {
            this.store.dispatch(
              CompanyActions.updateCompany({ changes: { seniorLeadershipTeamId: this.currentSltTeamId } })
            );
            return of(null);
          }
        })
      )
      .subscribe();
  }

  updateTeam(teamId: string, update: Partial<Team>): void {
    this.teamService.update(teamId, update).subscribe({
      next: () => {
        if (update.private !== undefined) {
          this.refresh$.next();
        }
        this.cdr.markForCheck();
      },
      error: () => {
        this.cdr.markForCheck();
      },
    });
  }

  deleteTeam(team: Team): void {
    const data: ConfirmDialogData = {
      title: `Delete team: ${team.name}`,
      message: '<strong>Caution:</strong> This action cannot be undone!',
      additionalMessage: `
All content (${this.stateService.language.rock.items},
${this.stateService.language.todo.items},
${this.stateService.language.issue.items}, etc.) will be deleted.
Please cancel and reassign all content if it's needed on another team before deleting.`.replace(/\n/g, ' '),
      confirmButtonText: 'I understand...Delete Anyway',
    };
    const confirmMarkingAbsentRef = this.dialog.open<WarningConfirmDialogComponent, ConfirmDialogData>(
      WarningConfirmDialogComponent,
      { data }
    );
    confirmMarkingAbsentRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.spinnerService.start();
          this.teamService.delete(team._id).subscribe({
            next: () => {
              this.store.dispatch(teamsStateActions.removeOne({ _id: team._id }));
              this.refresh$.next();
            },
            error: (error: unknown) => {
              this.cdr.markForCheck();
            },
          });
          if (this.selectedTeam?._id === team._id) this.closeDetailView();
        }
      },
    });
  }

  trackByTeamId(i: number, team: Team): string {
    return team._id;
  }

  onSearchChanged(term: string): void {
    this.searchTerm$.next(term);
  }
}
