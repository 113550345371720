import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraCounterComponent } from './terra-counter.component';

@NgModule({
  imports: [CommonModule, TerraCounterComponent],
  exports: [TerraCounterComponent],
})
export class TerraCounterModule {}
