import { PathBuilder } from '@ninety/ui/legacy/shared/builders/path.builder';

export class OrgChartApiPathBuilder extends PathBuilder {
  public static ofCompany(companyId: string): OrgChartApiPathBuilder {
    return new OrgChartApiPathBuilder(['api', 'v4', companyId, 'responsibilities-chart']);
  }

  public primaryChart(): WithChart {
    return new WithChart([...this.parts, 'primary']);
  }

  public chart(chartId: string): WithChart {
    return new WithChart([...this.parts, chartId]);
  }

  public getUserCharts(): PathBuilder {
    return new PathBuilder([...this.parts, 'get-user-charts']);
  }

  public setPrimaryChart(chartId: string): WithChart {
    return new WithChart([...this.parts, chartId, 'set-primary']);
  }

  public cloneChart(chartId: string): WithChart {
    return new WithChart([...this.parts, chartId, 'clone-chart']);
  }
}

export class WithChart extends PathBuilder {
  public seatHolders(): PathBuilder {
    return new WithChart([...this.parts, 'seat-holders']);
  }

  public accountabilities(): PathBuilder {
    return new WithChart([...this.parts, 'accountabilities']);
  }

  public seats(seatId?: string): WithSeat {
    const parts = [...this.parts, 'seats'];
    if (seatId) parts.push(seatId);
    return new WithSeat(parts);
  }

  public toggleVisionary(): PathBuilder {
    return this.seats().append('toggle-visionary');
  }

  public updateOrdinals(): PathBuilder {
    return this.seats().append('update-ordinals');
  }
}

export class WithSeat extends PathBuilder {
  public accountabilities(): PathBuilder {
    return new WithSeat([...this.parts, 'accountabilities']);
  }
}
