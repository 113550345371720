import { Pipe, PipeTransform } from '@angular/core';

import { Item } from '../models/_shared/item';

@Pipe({
  name: 'asItem',
})
export class AsItemPipe implements PipeTransform {
  transform(item: any): Partial<Item> {
    return item as Partial<Item>;
  }
}
