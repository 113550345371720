export class MeetingSection {
  name = '';
  path = '';
  duration = 300;
  isHidden? = false;
  isEditable? = false;
  subtitle? = '';

  ordinal?: number;
  elapsedTime? = 0;
  details? = '';
  iframeUrl?: string;
  isCustom? = true;
  isDefault? = false;
  showGrayIndicator? = false;

  // legacy properties
  segue?: MeetingSection;
  scorecard?: MeetingSection;
  rocks?: MeetingSection;
  headlines?: MeetingSection;
  todos?: MeetingSection;
  issues?: MeetingSection;
  conclude?: MeetingSection;

  constructor(i: number, section?: Partial<MeetingSection>) {
    this.ordinal = i;
    Object.assign(this, section);
  }
}
