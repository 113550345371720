import { LogLevel } from '../../_core/logging';

/**
 * Explicitly registers the names of loggers, which can be targeted with feature flags.
 *
 * Check the local README for more info on logging feature flags.
 */
export enum LoggerName {
  AppModule = 'AppModule',
  MyNinetyModule = 'MyNinetyModule',
}
export type LoggingFeatureFlagState = Record<LoggerName, LogLevel>;

export const InitialLoggingFeatureFlagState: LoggingFeatureFlagState = {
  AppModule: LogLevel.Info,
  MyNinetyModule: LogLevel.Info,
};
