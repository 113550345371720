import { Injectable } from '@angular/core';
import { GridStack } from 'gridstack';

import { GridOptions } from '../models/grid.options';

/**
 * Creates a GridStack instance
 *
 * Why this service, instead of just calling init where it's called?
 * > Easier testing - it's much easier to provide a mock version of this service than mock out the static call of the
 *   library
 */
@Injectable({
  providedIn: 'root',
})
export class GridStackFactoryService {
  constructor() {}

  createGridStack(options: GridOptions): GridStack {
    return GridStack.init(options);
  }
}
