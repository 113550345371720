export enum BillingRedirectReasons {
  BillingPortal = 'bp',
  UpdatePaymentMethod = 'upm',
  SubscriptionUpdate = 'su',
  ReturnNoActon = 'rna',
  SubscriptionUpdateConfirmation = 'suc',
  SubscriptionReactivate = 'sr',
  SubscriptionCancel = 'sc',
  SubscriptionQuantityUpdate = 'squ',
}
