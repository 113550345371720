import { EntityState } from '@ngrx/entity';

import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { MeetingsV2Sort } from '@ninety/ui/legacy/shared/models/meetings-v2/meetings-v2-sort-fields';
import { PastMeetingsPaginatedResponse } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';

export const MeetingsFeatureStateKey = 'meetings';

export interface MeetingsStateModel extends EntityState<PastMeetingsPaginatedResponse> {
  selectedId: string;

  loading: boolean;
  error: boolean;

  totalCount: number;

  filters: { meetingType: MeetingType; agendaId: string; teamId: string };

  page: { index: number; size: number };
  sort: MeetingsV2Sort;

  /** Partial Meeting information including meeting status for the team. */
  meetingStatus: Partial<Meeting>;

  /** Full meeting object used during an active meeting. */
  currentMeeting: Meeting;

  attendees: string[];
}
