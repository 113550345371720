import { Injectable } from '@angular/core';

import { TypeCode } from './../../_shared/models/directory/type-code.interface';

@Injectable({
  providedIn: 'root',
})
export default class TypeCodes {
  // why?  just why?
  emailAddresses = [
    {
      typeCode: '0000',
      label: 'Main',
    },
    {
      typeCode: '0001',
      label: 'Personal',
    },
    {
      typeCode: '0002',
      label: 'Work',
    },
  ];
  genders = [
    {
      typeCode: '0000',
      label: 'Male',
    },
    {
      typeCode: '0001',
      label: 'Female',
    },
  ];
  phoneNumbers = [
    {
      typeCode: '0000',
      label: 'Main',
    },
    {
      typeCode: '0001',
      label: 'Cell',
    },
    {
      typeCode: '0002',
      label: 'Home',
    },
    {
      typeCode: '0003',
      label: 'Work',
    },
  ];
  issueIntervals = [
    {
      typeCode: '0002',
      label: 'Short-term',
    },
    {
      typeCode: '0003',
      label: 'Long-term',
    },
  ];
  issueTypes = [
    {
      typeCode: '0000',
      label: 'Analysis',
    },
    {
      typeCode: '0001',
      label: 'Design',
    },
    {
      typeCode: '0002',
      label: 'Training',
    },
    {
      typeCode: '0003',
      label: 'Enforcement',
    },
    {
      typeCode: '0004',
      label: 'Awareness',
    },
  ];
  measurableIntervals = [
    {
      typeCode: 'weekly',
      label: 'Weekly',
    },
    {
      typeCode: 'monthly',
      label: 'Monthly',
    },
  ];
  measurableUnits = [
    {
      typeCode: 'dollar',
      label: 'Dollar',
    },
    {
      typeCode: 'percentage',
      label: 'Percentage',
    },
    {
      typeCode: 'number',
      label: 'Number',
    },
    {
      typeCode: 'yesno',
      label: 'YesNo',
    },
  ];
  rockStatuses = [
    {
      typeCode: '0000',
      label: 'Off-track',
    },
    {
      typeCode: '0001',
      label: 'On-track',
    },
    {
      typeCode: '0002',
      label: 'Done',
    },
  ];
  roles = [
    {
      typeCode: '0000',
      label: 'Owner',
    },
    {
      typeCode: '0001',
      label: 'Admin',
    },
    {
      typeCode: '0002',
      label: 'Manager',
    },
    {
      typeCode: '0003',
      label: 'Managee',
    },
    {
      typeCode: '0004',
      label: 'Observer',
    },
  ];
  targetOrientations = [
    {
      typeCode: 'inside',
      label: 'Inside min and max',
    },
    {
      typeCode: 'outside',
      label: 'Outside min and max',
    },
    {
      typeCode: 'gte',
      label: 'Greater than or equal to goal',
    },
    {
      typeCode: 'gt',
      label: 'Greater than',
    },
    {
      typeCode: 'equal',
      label: 'Equal to goal',
    },
    {
      typeCode: 'lt',
      label: 'Less than goal',
    },
    {
      typeCode: 'lte',
      label: 'Less than or equal to goal',
    },
  ];

  getTypes(type: string): TypeCode[] {
    return this[type];
  }

  findCode(code: string, type: string): TypeCode {
    const types = this.getTypes(type);
    return types.find(t => t.typeCode === code);
  }

  findTypeCodeByTypeLabel(type: string, label: string): TypeCode {
    const types = this.getTypes(type);
    return types.find(t => t.label === label);
  }
}
