import { createSelector, MemoizedSelector } from '@ngrx/store';

import { LanguageStateKey } from '..';
import { CustomLanguage } from '../../../_shared/models/language/custom-language';
import { selectGlobalAppState } from '../index';

type DomainLanguageSelector<T extends keyof CustomLanguage> = MemoizedSelector<
  object,
  CustomLanguage[T],
  (s1: CustomLanguage) => CustomLanguage[T]
>;

export const selectLanguageState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[LanguageStateKey] : null
);

export const selectLanguage = createSelector(selectLanguageState, state => state.companyLanguage);

export const selectLanguageArea = <T extends keyof CustomLanguage>(key: T): DomainLanguageSelector<T> =>
  createSelector(selectLanguage, companyLanguage => companyLanguage[key]);

export const selectResetLanguage = createSelector(selectLanguageState, state => state?.resetLanguage);

export const selectBosDefaultLanguage = createSelector(selectLanguageState, state => state?.defaultBOSLanguage);

export const selectSurveyLanguage = selectLanguageArea('survey');
