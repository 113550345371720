import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleNameAsCode } from '@ninety/ui/legacy/shared/models/_shared/role-name-as-code';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { Company } from '@ninety/ui/legacy/shared/models/company/company';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { PhoneNumber } from '@ninety/ui/legacy/shared/models/directory/phone-number';
import { UserTeam } from '@ninety/ui/legacy/shared/models/directory/user-team';

export class UserRecord {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  team1: string;
  team2: string;
  team3: string;
  team4: string;
  team5: string;

  static toInviteUsersPayload(
    records: UserRecord[],
    { _id: companyId, seniorLeadershipTeamId }: Company,
    allTeams: Team[]
  ): InviteUserPayload[] {
    return records.map(r => ({
      ...r,
      children: [],
      teams: mapTeams(r, seniorLeadershipTeamId, allTeams),
      roleCode: RoleNameAsCode[r.role.toLowerCase()] || RoleCode.managee,
      companyId,
      phoneNumbers: [new PhoneNumber(r.phone, '0000')],
      active: false,
      isImplementer: false,
      hasBeenInvited: false,
    }));
  }
}

function mapTeams(item: UserRecord, seniorLeadershipTeamId: string, allTeams: Team[]): UserTeam[] {
  const teams = [];
  allTeams.map(t => {
    const teamName = t.name?.toLowerCase();

    if (
      teamName === item.team1?.toLowerCase() ||
      teamName === item.team2?.toLowerCase() ||
      teamName === item.team3?.toLowerCase() ||
      teamName === item.team4?.toLowerCase() ||
      teamName === item.team5?.toLowerCase()
    )
      teams.push(new UserTeam(t._id));
  });

  if (teams.length === 0) {
    teams.push(new UserTeam(seniorLeadershipTeamId));
  }

  return teams;
}
