<ng-container
  *ngrxLet="{
    showWhatNext: store.showWhatNext$,
    showLastAction: store.showLastAction$,
    needingMoreHelp: store.needingMoreHelp$,
    buttonText: store.buttonText$,
    mazNextResponse: store.mazNextResponse$,
    componentButtonLabel: store.buttonLabel$,
    userQuestion: store.userQuestion$,
    canViewButton: store.canViewButton$,
    hideWhatNextButton: store.hideWhatNextButton$
  } as state">
  <div
    class="what-next-button elevation-2"
    *ngIf="state.canViewButton && !state.hideWhatNextButton"
    (click)="store.clickWhatNext()">
    <terra-icon icon="question-circle" variant="fill" />
    <span class="button-text-padding">{{ state.componentButtonLabel }}</span>
    <terra-icon
      icon="x"
      ninetyTooltip="Close"
      class="close-icon"
      (click)="$event.stopPropagation(); store.hideWhatNext()" />
  </div>
  <div *ngIf="state.showWhatNext" class="what-next-dialog">
    <ninety-button flat icon class="close-what-next-btn" (click)="store.allDoneClicked()">
      <terra-icon icon="x" ninetyTooltip="Close" />
    </ninety-button>

    <ng-container *ngIf="state.showLastAction">
      <h1 class="dialog-header">What's next?</h1>
      <span [innerHTML]="state.mazNextResponse.completedTask"></span>
      <div class="suggestion-box">
        <div class="suggestion-icon">
          <terra-icon icon="lightbulb" />
        </div>
        <div class="maz-text" [innerHTML]="state.mazNextResponse.nextText"></div>
        <div class="maz-text" [innerHTML]="state.mazNextResponse.taskExplanation"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!state.showLastAction && state.needingMoreHelp">
      <h1 class="dialog-header">What's on your mind?</h1>

      <div class="whats-on-your-mind">
        <mat-form-field appearance="outline" class="full-width">
          <textarea
            [ngModel]="state.userQuestion"
            (ngModelChange)="store.userQuestion({ value: $event })"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="8"
            cdkAutosizeMaxRows="12"
            minlength="5"
            maxlength="1000"
            placeholder="Please tell us more about how we can help (optional)..."></textarea>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="!state.showLastAction && !state.needingMoreHelp">
      <h1 class="dialog-header">Learn more</h1>

      <div class="what-next-content" [innerHTML]="state.mazNextResponse.nextText">.</div>

      <div class="learn-more-box" (click)="store.openNewTab(state.mazNextResponse.videoLink)">
        <div>
          <a class="link-text">Watch a tutorial</a>
        </div>
        <div class="maz-text">{{ state.mazNextResponse.videoTutorialText }}</div>
      </div>

      <div class="learn-more-box" (click)="store.launchIntercom()">
        <div><a class="link-text">Live chat</a></div>
        <div class="maz-text">{{ state.mazNextResponse.supportAgentText }}</div>
      </div>
    </ng-container>

    <div class="what-next-footer">
      <div class="button-container">
        <ninety-button
          class="full-width"
          primary
          filled
          (click)="
            store.footerButtonClicked({
              showLastAction: state.showLastAction,
              needingMoreHelp: state.needingMoreHelp
            })
          "
          >{{ state.buttonText }}</ninety-button
        >
      </div>
    </div>
  </div>
</ng-container>
