import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/_shared/user';

// TODO turn into UserName pipe which returns name, then pipe into more generic initials pipe
@Pipe({
  name: 'userInitials',
  standalone: true,
})
export class UserInitialsPipe implements PipeTransform {
  transform(user: Pick<User, 'metadata'>): string {
    if (!user?.metadata?.name) return '';

    const { first, last } = user.metadata.name;

    if (!first && !last) return '';

    return `${first[0] || ''}${last[0] || ''}`.toUpperCase();
  }
}
