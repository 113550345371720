import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TerraIconModule, TerraIconName, TerraIconVariant, TerraSizing } from '@ninety/terra';

import { DividerMenuItemComponent } from '../menu-items/divider-menut-item/divider-menu-item.component';
import { MenuRowDirective } from '../menu-items/menu-row.directive';
import { MenuItemStandardComponent } from '../menu-items/standard-menu-item/standard-menu-item.component';
import { PopoverOriginDirective } from '../popover/popover-origin.directive';

export interface MenuItemData {
  label: string;
  description?: string;
  icon?: { name: TerraIconName; size: TerraSizing; variant: TerraIconVariant };
  suffix?: string;
  action?: (value: MenuItemData) => void;
  isDisabled?: boolean;
  attr?: string;
  divider?: boolean;
}

export interface MenuSection {
  title?: string;
  data: MenuItemData[];
}

@Component({
  selector: 'ninety-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CdkMenuModule,
    MenuRowDirective,
    PopoverOriginDirective,
    DividerMenuItemComponent,
    MenuItemStandardComponent,
    TerraIconModule,
  ],
})
export class MenuComponent implements OnInit {
  public isOfTypeSectionData = false;
  public dataSourceWithSection: MenuSection[] = [];
  public dataSourceMenuItem: MenuItemData[] = [];
  ngOnInit(): void {
    this.isOfTypeSectionData = this.isDataSection();
    this.getDataSource();
  }
  @Input() isOpen: boolean;
  @Input() dataSource: MenuItemData[] | MenuSection[];

  private getDataSource(): void {
    if (this.dataSource) {
      // We need this bc you dataSource is optional, you can use the menu with content projection.
      if (this.isOfTypeSectionData) {
        this.dataSourceWithSection = this.dataSource as MenuSection[];
      } else {
        this.dataSourceMenuItem = this.dataSource as MenuItemData[];
      }
    }
  }

  private isDataSection() {
    return this.dataSource && this.dataSource.length > 0 && 'data' in this.dataSource[0] ? true : false;
  }
}
