<div *ngIf="avatarDialog.companyId" data-cy="logo-upload-dialog_title" class="upload-header">Upload Company Logo</div>
<ng-container *ngIf="!shouldShowImageCropper">
  <div class="drop-container flex-center-x-y-col" ninety-file-drag-drop (fileDropped)="onFileDropped($event)">
    <terra-icon icon="cloud-upload" size="80" />
    <span class="terra-body flex-center-x-y-col">
      Drag and drop your logo here, or
      <label for="fileDropRef" class="browse-label" tabindex="0">Upload logo</label>
      <input
        data-cy="logo-upload-dialog_file-input"
        type="file"
        #fileDropRef
        id="fileDropRef"
        class="invisible"
        aria-hidden="true"
        tab-index="-1"
        [multiple]="false"
        accept=".jpg, .jpeg, .png"
        (change)="onFileSelected($event)" />
    </span>
  </div>
</ng-container>

<ng-container *ngIf="shouldShowImageCropper">
  <div class="crop-area">
    <image-cropper
      [roundCropper]="false"
      [imageChangedEvent]="imageChangedEvent"
      [imageFile]="imageFile"
      [maintainAspectRatio]="false"
      [onlyScaleDown]="true"
      format="png"
      [resizeToWidth]="300"
      (imageCropped)="imageCropped($event)">
    </image-cropper>
  </div>
</ng-container>

<mat-dialog-actions align="end" class="action-buttons">
  <ninety-button data-cy="logo-upload-dialog_cancel-button" stroked (click)="onCancel()">Cancel</ninety-button>
  <ninety-button
    data-cy="logo-upload-dialog_change-logo"
    stroked
    primary
    (click)="onUpload()"
    [disabled]="croppedImage?.length === 0"
    >Change logo</ninety-button
  >
</mat-dialog-actions>
