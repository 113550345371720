import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template.[gridItemTemplate]',
})
export class GridItemTemplateDirective {
  constructor(public templateRef: TemplateRef<any>) {}

  @Input() gridItemTemplate!: string;
}
