import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GuideInfo } from '@ninety/getting-started/guide/models/guide-info';
import { PhasesResponse } from '@ninety/getting-started/guide/models/phase';

@Injectable({
  providedIn: 'root',
})
export class GettingStartedService {
  private readonly api = '/api/maz/v1';

  constructor(private http: HttpClient) {}

  getGuideInfo(): Observable<GuideInfo> {
    return this.http.get<GuideInfo>(`${this.api}/common_copy`);
  }

  getPhases(): Observable<PhasesResponse> {
    return this.http.get<PhasesResponse>(`${this.api}/phases`);
  }

  notifyResourceLinkOpened(url: string): Observable<void> {
    return this.http.post<void>(`${this.api}/resource_view`, { url });
  }
}
