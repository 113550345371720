import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { Person } from '../../../_shared/models/_shared/person';

import { CurrentPersonStateActions } from './current-person.actions';
import { initialCurrentPersonState } from './current-person.model';

export const currentPersonStateReducer = createReducer<Person>(
  initialCurrentPersonState,
  on(
    CurrentPersonStateActions.appInit,
    CurrentPersonStateActions.becomePartnerUpdatePerson,
    CurrentPersonStateActions.loginUser,
    CurrentPersonStateActions.templateUpdatePerson,
    CurrentPersonStateActions.updatePerson,
    (_, { person }) => {
      const newState: Person = cloneDeep(person);
      return newState;
    }
  ),
  on(
    CurrentPersonStateActions.updateCurrentPersonSuccess,
    (state, { update }): Person => ({ ...state, ...cloneDeep(update) })
  ),
  on(CurrentPersonStateActions.appLogout, () => null)
);
