import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/_shared/user';

@Pipe({
  name: 'addFullName',
})
export class AddFullNamePipe implements PipeTransform {
  transform(users: User[]): User[] {
    return users.map(u => ({
      ...u,
      fullName: `${u.metadata.name.first} ${u.metadata.name.last}`,
    }));
  }
}
