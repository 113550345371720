import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import ObjectID from 'bson-objectid';
import { cloneDeep as _cloneDeep } from 'lodash';
import { Observable, Subscription, filter, forkJoin, tap } from 'rxjs';

import { DirectoryAddInviteVewModel } from '@ninety/directory/directory-add-invite/directory-add-invite-view-model.interface';
import { BillingV2DirectoryAddInviteFacadeService } from '@ninety/directory/legacy-compatibility/billing-v2-directory-add-invite-facade.service';
import { TerraCheckboxModule, TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { AccountStatus, Company } from '@ninety/ui/legacy/shared/models/company/company';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { NinetyValidators } from '@ninety/ui/legacy/shared/validators/ninety-validators';
import { BillingStateActions } from '@ninety/ui/legacy/state/app-global/billing/billing-state.actions';
import { selectBillingOverviewDetails } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';

import { EmailErrors } from '../email-errors.enum';
import { BillingUserMapperService } from '../legacy-compatibility/billing-user-mapper.service';
import { BillingV2SeatFacadeService } from '../legacy-compatibility/billing-v2-seat-facade.service';
import { UserLimitDialogComponent } from '../user-limit-dialog/user-limit-dialog.component';

import { DirectoryAddInviteMobileModalComponent } from './directory-add-invite-mobile-modal.component';
import { DirectoryAddInviteUserComponent } from './directory-add-invite-user.component';

@Component({
  selector: 'ninety-directory-add-invite-dialog',
  templateUrl: 'directory-add-invite.component.html',
  styleUrls: ['directory-add-invite.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MaterialMdcModule,
    MatDialogModule,
    TerraIconModule,
    DirectoryAddInviteUserComponent,
    TerraCheckboxModule,
    FormsModule,
  ],
  providers: [BillingUserMapperService, BillingV2SeatFacadeService, BillingV2DirectoryAddInviteFacadeService],
})
export class DirectoryAddInviteDialogComponent implements OnInit, OnDestroy {
  directoryOnly = false;
  userCanAddToCompany = true;
  submissionInProgress = false;
  shouldSendInvites = true;
  forms: UntypedFormArray;
  company: Company;
  canCreatePeople = true;

  /*************************************
   * Adding due to Regression migrating Stripe users to Paddle
   * https://traxion.atlassian.net/wiki/spaces/CTQA/pages/2290941953/22+Dec+2021+-+DEV-3101
   * https://traxion.atlassian.net/browse/DEV-3101
   *
   * This Stripe logic can be removed once Stripe companies have all migrated
   */
  isStripe = false;

  vm = {} as DirectoryAddInviteVewModel;
  private isBillingV2Company = false;
  private isTrialingCompany = false;

  private subscriptions = new Subscription();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DirectoryAddInviteDialogComponent>,
    public stateService: StateService,
    public userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private teamService: TeamService,
    private facade: BillingV2DirectoryAddInviteFacadeService,
    private store: Store,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.resetForms();
    this.userCanAddToCompany = this.stateService.isManagerOrAbove;
    this.company = this.stateService.company;
    this.isStripe = !!this.company.stripe;

    this.subscriptions.add(
      this.store
        .select(selectBillingOverviewDetails)
        .pipe(
          tap(billingOverviewDetails => {
            this.isBillingV2Company = billingOverviewDetails.isBillingV2Company;
            this.vm = this.facade.vm;
            this.isTrialingCompany =
              this.company.accountStatus === AccountStatus.TRIALING || billingOverviewDetails.isTrialingSubscription;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    (document.activeElement as HTMLButtonElement).blur();
  }

  getNumOfUsersAllowedToBeInvited(): number {
    const directoryInvitedTotalUsers = this.userService.directoryInvitedTotalUsers$.value;

    //-1 for owner
    const numOfUsersAllowedToBeInvited = this.isTrialingCompany
      ? this.company.trialingUserLimit - 1 - directoryInvitedTotalUsers - this.forms.length
      : this.company.userLimit - 1 - directoryInvitedTotalUsers - this.forms.length;

    return numOfUsersAllowedToBeInvited;
  }

  /**
   * Checks invited users limit and show dialog if needed
   * @param [skipOne] skip one user, form already has one user form by default
   * @param [showDialog] option to not show a dialog
   * @returns true if invited users limit dialog is displayed
   */
  checkInvitedUsersLimit(skipOne = false, showDialog = true, mobile = false): boolean {
    const numOfUsersAllowedToBeInvited = this.getNumOfUsersAllowedToBeInvited();

    this.canCreatePeople = this.directoryOnly || !this.shouldSendInvites || numOfUsersAllowedToBeInvited >= 0;

    //disable if directory only and no invites required to be sent
    if (this.directoryOnly) return false;

    if (mobile && this.forms.controls[0].value.email !== '') {
      //handle mobile version of the form, we need to check that the first user is not empty before making a decision
      if (!this.directoryOnly && numOfUsersAllowedToBeInvited > 0) return false;
    } else {
      //needed for "company add" toggle and when form has one user, avoids triggering the dialog
      if (!this.directoryOnly && numOfUsersAllowedToBeInvited - +skipOne >= 0) return false;
    }

    if (!showDialog) return false;

    this.showUserLimitDialog(
      this.isTrialingCompany ? this.company.trialingUserLimit : this.company.userLimit,
      numOfUsersAllowedToBeInvited
    );

    return true;
  }

  showUserLimitDialog(companyUserLimit: number, numOfUsersToRemove: number) {
    this.dialog.open(UserLimitDialogComponent, {
      data: {
        companyUserLimit,
        numOfUsersToRemove,
      },
    });
  }

  private getUniqueEmailValidator(): (emailControl: AbstractControl) => ValidationErrors | null {
    return (emailControl: AbstractControl) =>
      this.userService.directoryUsers$.value.some(
        (user: User) => user.emailAddresses[0]?.email.trim().toLowerCase() == emailControl.value.trim().toLowerCase()
      ) ||
      this.forms.controls.some((control: UntypedFormControl) => {
        const compareEmailControl = control.get('email');
        return (
          compareEmailControl !== emailControl &&
          compareEmailControl.value.trim().toLowerCase() === emailControl.value.trim().toLowerCase()
        );
      })
        ? { uniqueEmail: { valid: false } }
        : null;
  }

  private getEmailValidator(): (control: AbstractControl) => ValidationErrors | null {
    const defaultEmailValidator = Validators.compose([
      Validators.required,
      Validators.email,
      Validators.pattern(this.helperService.emailRegex()),
      this.getUniqueEmailValidator(),
    ]);
    return (control: AbstractControl) => (this.directoryOnly && !control.value ? null : defaultEmailValidator(control));
  }

  private getRequiredUnlessDirectoryOnlyValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => (this.directoryOnly || control.value ? null : { required: { valid: false } });
  }

  private getTeamsRequiredValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) =>
      control.get('role')?.value === RoleCode.lite || this.directoryOnly || control.value.length
        ? null
        : { required: { valid: false } };
  }

  private getTeamsSummary(teams: Team[]): string {
    return teams.length
      ? teams.map(({ teamId }) => this.teamService.allTeams.find(t => t._id.toString() === teamId).name).join(', ')
      : 'No teams';
  }

  private resetForms(pushEmptyForm = true): void {
    this.forms = this.formBuilder.array([], Validators.required);
    if (pushEmptyForm) this.forms.push(this.getUserForm());
  }

  private getUserForm(): UntypedFormGroup {
    const form = this.formBuilder.group({
      firstName: ['', [Validators.required, NinetyValidators.preventOnlyWhiteSpace]],
      lastName: ['', [Validators.required, NinetyValidators.preventOnlyWhiteSpace]],
      email: ['', this.getEmailValidator()],
      teams: [[], this.getTeamsRequiredValidator()],
      teamsSummary: [''],
      emailError: [EmailErrors.none],
      role: [null, this.getRequiredUnlessDirectoryOnlyValidator()],
      avatarImageUrl: [''],
    });

    const updateTeamsSummary = (teams: Team[] = []) => {
      form.get('teamsSummary').setValue(this.getTeamsSummary(teams));
    };
    const teamsForm = form.get('teams');
    teamsForm.valueChanges.subscribe({
      next: updateTeamsSummary,
    });
    updateTeamsSummary();

    const roleForm = form.get('role');
    roleForm.valueChanges.subscribe({
      next: (role: RoleSelectOption) => {
        if (!role) {
          return;
        }
        roleForm.markAsTouched();
        if (role.isImplementer || role.roleCode === RoleCode.lite) {
          teamsForm.disable();
        } else {
          teamsForm.enable();
        }
      },
    });

    const emailForm = form.get('email');
    const emailErrorForm = form.get('emailError');
    const updateEmailError = () => {
      const emailError =
        emailForm.hasError('pattern') || emailForm.hasError('email')
          ? EmailErrors.invalid
          : emailForm.hasError('uniqueEmail')
          ? EmailErrors.taken
          : EmailErrors.missing;
      emailErrorForm.setValue(emailError);
    };
    emailForm.valueChanges.subscribe({
      next: () => {
        updateEmailError();
      },
    });
    updateEmailError();

    return form;
  }

  private collectUsersFromForm(): InviteUserPayload[] {
    return this.forms.controls.map(
      (userFormControl: AbstractControl) =>
        new InviteUserPayload({
          active: !this.directoryOnly,
          companyId: this.stateService.companyId,
          ...(userFormControl.get('email').value.trim() ? { email: userFormControl.get('email').value.trim() } : {}),
          firstName: userFormControl.get('firstName').value.trim(),
          lastName: userFormControl.get('lastName').value.trim(),
          hasBeenInvited: false,
          roleCode: this.directoryOnly ? null : userFormControl.get('role').value.roleCode,
          teams:
            this.directoryOnly || userFormControl.get('role').value.value === RoleCode.lite
              ? []
              : userFormControl
                  .get('teams')
                  .value.map(({ teamId }, ordinal) => ({ teamId: new ObjectID(teamId), ordinal })),
          isImplementer: (!this.directoryOnly && userFormControl.get('role').value.isImplementer) || false,
          ...(userFormControl.get('avatarImageUrl').value
            ? { pictureUrl: userFormControl.get('avatarImageUrl').value }
            : {}),
        })
    );
  }

  private executeInvitation(inviteUserPayloads: InviteUserPayload[]): void {
    this.facade
      .performInvitationWithAutoAddingLicenses(inviteUserPayloads, this.shouldSendInvites, this.directoryOnly)
      .subscribe({
        next: _ => {
          if (this.isBillingV2Company) {
            this.store.dispatch(BillingStateActions.billingInit());
          }
          this.facade.vm.paymentFailed.next(false);

          this.close(true);
        },
        error: () => {
          this.submissionInProgress = false;
          this.facade.vm.paymentFailed.next(true);
        },
      });
  }

  onClickAddUser(): UntypedFormGroup {
    if (this.checkInvitedUsersLimit(true)) return;
    const form = this.getUserForm();
    this.forms.push(form);

    return form;
  }

  onClickAddUserMobile(): void {
    if (this.checkInvitedUsersLimit(false, true, true)) return;

    // it's unlikely that a user will drag the screen over or reduce the size.
    // Edge case would be if the user dirties up the form, then reduces the screen size and then adds a user.
    // But at least wouldn't be able to click the save button if the form is invalid.
    if (this.forms.controls?.[0]?.pristine) this.resetForms(false);
    const form = this.getUserForm();

    this.openAddUserMobilDialog(form)
      .pipe(filter(f => !!f))
      .subscribe({
        next: (newUserForm: UntypedFormGroup) => {
          this.forms.push(newUserForm);
          this.checkInvitedUsersLimit(false, false);
          this.forms.markAsDirty();
          this.forms.markAsTouched();
        },
      });
  }

  /** Not being used right now because the view is hidden, (done by someone else)
   * but keeping it wired up because I think we should add this back in */
  editUserMobile(index: number): void {
    const form = _cloneDeep(this.forms[index]);
    this.openAddUserMobilDialog(form)
      .pipe(filter(f => !!f))
      .subscribe({
        next: (userForm: UntypedFormGroup) => {
          this.forms.controls[index] = userForm;
          this.forms.updateValueAndValidity();
          this.checkInvitedUsersLimit(false, false);
        },
      });
  }

  openAddUserMobilDialog(form: UntypedFormGroup): Observable<UntypedFormGroup> {
    return this.dialog
      .open(DirectoryAddInviteMobileModalComponent, {
        id: 'add-invite-mobile-modal',
        width: '900px',
        maxWidth: '90vw',
        data: {
          form,
          directoryOnly: this.directoryOnly,
        },
      })
      .afterClosed();
  }

  onClickDeleteUser(index: number, mobile: boolean): void {
    if (this.forms.length > 1) {
      this.forms.controls.splice(index, 1);
      this.forms.updateValueAndValidity();
    } else if (mobile) {
      this.resetForms();
    }
    this.checkInvitedUsersLimit(false, false);
  }

  onClickSetDirectoryOnly(directoryOnly: boolean): void {
    this.directoryOnly = directoryOnly;
    this.checkInvitedUsersLimit();

    for (const form of this.forms.controls) {
      form.get('email').updateValueAndValidity();
      form.get('role').updateValueAndValidity();
      form.get('teams').updateValueAndValidity();
    }
  }

  onClickSubmitForms(): void {
    if (this.forms.invalid) {
      return;
    }
    this.submissionInProgress = true;
    if (this.isStripe) {
      this.submitLegacyStripeForms();
    } else {
      this.executeInvitation(this.collectUsersFromForm());
    }
  }

  goBack(): void {
    this.vm.paymentFailed.next(false);
  }

  addAsObservers(): void {
    const inviteUserPayloads = this.collectUsersFromForm();
    inviteUserPayloads.map((user: InviteUserPayload) => (user.roleCode = RoleCode.observer));
    this.executeInvitation(inviteUserPayloads);
  }

  onClickCheckInviteEmail(checked: boolean) {
    this.shouldSendInvites = checked;
    this.checkInvitedUsersLimit();
  }

  close(isSubmitted = false) {
    this.dialogRef.close(isSubmitted);
  }

  submitLegacyStripeForms() {
    const inviteUserPayloads = this.forms.controls.map(
      (userFormControl: AbstractControl) =>
        new InviteUserPayload({
          active: !this.directoryOnly,
          companyId: this.stateService.companyId,
          ...(userFormControl.get('email').value.trim() ? { email: userFormControl.get('email').value.trim() } : {}),
          firstName: userFormControl.get('firstName').value.trim(),
          lastName: userFormControl.get('lastName').value.trim(),
          hasBeenInvited: false,
          roleCode: this.directoryOnly ? null : userFormControl.get('role').value.code,
          teams:
            this.directoryOnly || userFormControl.get('role').value.code === RoleCode.lite
              ? []
              : userFormControl
                  .get('teams')
                  .value.map(({ teamId }, ordinal) => ({ teamId: new ObjectID(teamId), ordinal })),
          isImplementer: !this.directoryOnly && userFormControl.get('role').value.isImplementer,
          ...(userFormControl.get('avatarImageUrl').value
            ? { pictureUrl: userFormControl.get('avatarImageUrl').value }
            : {}),
        })
    );

    const inviteUserObservables: Observable<User>[] = inviteUserPayloads.map(payload =>
      this.userService.inviteUser(payload, !this.directoryOnly)
    );

    forkJoin(inviteUserObservables).subscribe({
      complete: () => this.close(true),
      error: () => (this.submissionInProgress = false),
    });
  }

  onBillingInfoClick() {
    this.router.navigateByUrl('settings/billing/overview');
  }
}
