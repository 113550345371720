import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';

@Component({
  standalone: true,
  imports: [CommonModule, MatDialogModule, ButtonComponent],
  selector: 'ninety-alert-dialog',
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content *ngIf="data.message">{{ data.message }}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <ninety-button primary filled mat-dialog-close>OK</ninety-button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      @use 'terra';

      mat-dialog-actions {
        padding-top: 0 !important;
      }

      .mat-mdc-dialog-title {
        padding-bottom: 0 !important;
      }
    `,
  ],
})
export class AlertDialogComponent {
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
