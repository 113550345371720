import { createActionGroup, emptyProps } from '@ngrx/store';

export const appActions = createActionGroup({
  source: 'App',
  events: {
    // This can be used when we conditionally need to return an action from an effect
    // but only have an action for one side of an expression.  In this case we can
    // use this action for the other side of the expression.  Which should never be
    // handled by a reducer or another effect.
    Noop: emptyProps(),
    'Init State Service': emptyProps(),
    'Dispatch app start actions': emptyProps(),
  },
});
