/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { DataLayerKeys } from '../model/data-layer';
import { DataLayerEvent } from '../model/data-layer-event';
import { DataLayerService } from '../services/data-layer.service';

import { dataLayerActions } from './data-layer.actions';

@Injectable()
export class DataLayerEffects {
  constructor(private actions$: Actions, private dataLayerService: DataLayerService) {}

  dataLayerTrackClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dataLayerActions.trackClick),
        tap(({ event, clickUrl }) => this.dataLayerService.trackClick(event?.target as HTMLElement, { clickUrl }))
      ),
    { dispatch: false }
  );

  dataLayerCreateCompanyEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dataLayerActions.createCompany),
        tap(({ event }) =>
          this.dataLayerService.push({
            [DataLayerKeys.event]: DataLayerEvent.companyCreate,
            ...this.dataLayerService.getFormMeta(event),
            ...this.dataLayerService.getUrlMeta(new URL(location.href)),
          })
        )
      ),
    { dispatch: false }
  );

  dataLayerTrackFormSubmit = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dataLayerActions.trackFormSubmit),
        tap(({ event }) => this.dataLayerService.trackFormSubmit(event))
      ),
    { dispatch: false }
  );
}
