import { Pipe, PipeTransform } from '@angular/core';
import ObjectID from 'bson-objectid';
import filter from 'lodash/filter';

import { CompanyUserListModel } from '../../_state';

@Pipe({
  name: 'searchCompanyFilter',
})
export class SearchCompanyFilterPipe implements PipeTransform {
  transform(companyUsers: CompanyUserListModel[], input: string): CompanyUserListModel[] {
    if (!companyUsers || !input) return companyUsers;
    if (!ObjectID.isValid(input)) {
      input = input.toLocaleLowerCase();
      return filter(companyUsers, c => c.companyName.toLocaleLowerCase().includes(input));
    } else {
      return filter(companyUsers, c => c.companyId === input);
    }
  }
}
