import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface UseLimitDialogData {
  companyUserLimit: number;
  numOfUsersToRemove: number;
}

@Component({
  selector: 'ninety-user-limit-dialog',
  templateUrl: './user-limit-dialog.component.html',
  styleUrls: ['./user-limit-dialog.component.scss'],
})
export class UserLimitDialogComponent implements OnInit {
  removeMsg = '';

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: UseLimitDialogData) {}

  ngOnInit(): void {
    if (this.data.numOfUsersToRemove < 0)
      this.removeMsg = `Remove ${
        this.data.numOfUsersToRemove === -1 ? 'one user' : -1 * this.data.numOfUsersToRemove + ' users'
      } to continue.`;
  }
}
