import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { VidyardComponent } from '@ninety/ui/legacy/components/vidyard/vidyard.component';
import { EmptyListViewBannerComponent } from '@ninety/ui/legacy/shared/components/empty-list-view-banner/empty-list-view-banner.component';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import { EmptyListViewComponent } from '../empty-list-view/empty-list-view.component';

const COMPONENTS = [EmptyListViewBannerComponent, EmptyListViewComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    TerraIconModule,
    MatButtonModule,
    ButtonComponent,
    MatCardModule,
    SharedModule,
    VidyardComponent,
  ],
})
export class EmptyListViewModule {}
