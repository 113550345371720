import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraDividerModule } from '@ninety/terra';
import { AngularMaterialModule } from '@ninety/ui/legacy/angular-material/angular-material.module';
import { SessionService } from '@ninety/ui/legacy/core/services/session.service';
import { CompanyUser } from '@ninety/ui/legacy/shared/models/company/company-user';
import { Theme } from '@ninety/ui/legacy/shared/models/enums/theme';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import {
  selectActiveCompanyUsers,
  selectAllCompanyUsers,
  selectCurrentCompanyUser,
  selectInactiveCompanyUsers,
} from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.selectors';

import { CompanyLogoComponent } from '../company-logo/company-logo.component';

@Component({
  selector: 'ninety-company-menu',
  templateUrl: './company-menu.component.html',
  styleUrls: ['./company-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, CompanyLogoComponent, SharedModule, LetDirective, TerraDividerModule],
})
export class CompanyMenuComponent {
  @Input() menuItem?: boolean;
  @Input() isSidenavExpanded: boolean;
  @Input() theme: Theme;
  @Input() v2Sidenav = false;

  public activeCompanyUsers$ = this.store.select(selectActiveCompanyUsers);
  public inactiveCompanyUsers$ = this.store.select(selectInactiveCompanyUsers);
  public allCompanyUsers$ = this.store.select(selectAllCompanyUsers);
  public currentCompanyUser$ = this.store.select(selectCurrentCompanyUser);

  constructor(public sessionService: SessionService, private store: Store) {}

  trackById: TrackByFunction<CompanyUser> = (_i: number, user: CompanyUser): string => user._id;
}
