import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Interaction } from './models/interaction';
import { InteractionType } from './models/interaction-type.enum';

export const InteractionsStateActions = createActionGroup({
  source: 'Interactions',
  events: {
    'Show What Next Dialog': emptyProps(),
    'Hide What Next Dialog': emptyProps(),
    'Hide What Next Button': emptyProps(),
    'create interaction': props<{ interactionType: InteractionType; description: string; args: object }>(),
    'update interaction': props<{ args: object }>(),
    'success create interaction': props<{ interaction: Interaction }>(),
    'success update interaction': props<{ args: object; submittedQuestion: boolean }>(),
    'update submitted question': props<{ submittedQuestion: boolean }>(),
  },
});
