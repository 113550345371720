import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TerraIconModule } from '@ninety/terra';

import { AngularMaterialModule } from '../../../_angular-material/angular-material.module';
import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { FileDragDropDirective } from '../../../_components/files/file-drag-drop/file-drag-drop.directive';
import { FileService } from '../../../_core/services/file.service';
import { LibraryService } from '../../../_core/services/library.service';
import { NotifyService } from '../../../_core/services/notify.service';
import { StateService } from '../../../_core/services/state.service';
import { SpinnerActions } from '../../../_state/app-global/spinner/spinner-state.actions';
import { AvatarType } from '../../models/library/avatar-type.enum';
import { SignPutResponseDto } from '../../models/library/signed-put-response.dto';

import { LogoUploadModel } from './models/LogoUploadModel';
@Component({
  selector: 'ninety-logo-upload-dialog',
  templateUrl: './logo-upload-dialog.component.html',
  styleUrls: ['./logo-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    FileDragDropDirective,
    ImageCropperModule,
    TerraIconModule,
    ButtonComponent,
  ],
})
export class LogoUploadDialogComponent {
  @ViewChild('fileDropRef', { static: false })
  fileDropEl: ElementRef;
  imageChangedEvent: Event;
  imageFile: File;
  croppedImage = '';
  fileName = 'No file chosen';
  shouldShowImageCropper = false;
  private files: {
    fileName: string;
    fileSize: number;
    uploadPercent: number;
    uploadedBytes: number;
  }[] = [];
  constructor(
    public dialogRef: MatLegacyDialogRef<LogoUploadDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public avatarDialog: LogoUploadModel,
    private libraryService: LibraryService,
    private notifyService: NotifyService,
    private fileService: FileService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private stateService: StateService
  ) {}
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  onCancel(): void {
    this.shouldShowImageCropper = false;
    this.croppedImage = '';
    this.dialogRef.close();
  }
  onUpload(): void {
    this.store.dispatch(SpinnerActions.startPrimary(null));
    this.libraryService
      .getImageUploadUrl(
        this.avatarDialog.companyId ? AvatarType.company : AvatarType.template,
        this.avatarDialog.companyId
      )
      .pipe(
        switchMap((uploadImageData: SignPutResponseDto) =>
          this.fileService
            .compressImage(this.fileService.dataURItoFile(this.croppedImage, this.fileName))
            .pipe(map((compressedImage: File) => ({ uploadImageData, compressedImage })))
        ),
        switchMap(({ uploadImageData, compressedImage }) =>
          this.fileService
            .downsizeImage(compressedImage, { maxWidth: 300 })
            .pipe(map((image: File) => ({ uploadImageData, image })))
        ),
        switchMap(({ uploadImageData, image }) =>
          this.libraryService.uploadImage(uploadImageData.uploadUrl, image).pipe(map(() => uploadImageData))
        ),
        catchError((error: unknown) => {
          this.store.dispatch(SpinnerActions.stopPrimary(null));
          this.notifyService.showError('An error occurred while uploading image. Please try again.', 'Image Upload');
          throw error;
        })
      )
      .subscribe((data: SignPutResponseDto) => {
        this.store.dispatch(SpinnerActions.stopPrimary(null));
        if (this.stateService.company._id === this.avatarDialog.companyId) {
          this.stateService.company.logo.url = data.location;
          const currentCompanyUser = this.stateService.currentCompanyUser$.getValue();
          this.stateService.currentCompanyUser$.next({
            ...currentCompanyUser,
            company: {
              ...currentCompanyUser.company,
              logo: {
                ...currentCompanyUser.company.logo,
                url: data.location,
              },
            },
          });
        }
        this.dialogRef.close({
          url: data.location,
        });
      });
  }
  onFileSelected(evt: Event) {
    const files = (evt.target as HTMLInputElement).files;
    this.extractFileData(files);
    this.imageChangedEvent = evt;
    this.shouldShowImageCropper = true;
    this.cdr.detectChanges();
  }
  onFileDropped(files: FileList) {
    this.fileDropEl.nativeElement.value = '';
    this.extractFileData(files);
    this.imageFile = files[0];
    this.shouldShowImageCropper = true;
    this.cdr.detectChanges();
  }
  private extractFileData(files: FileList): void {
    this.files = Object.entries(files).map(([_, value]) => ({
      fileName: value.name,
      fileSize: value.size,
      uploadPercent: 0,
      uploadedBytes: 0,
    }));
    const file = this.files[0];
    let fileSize: number;
    if (file && file.fileSize) {
      fileSize = file.fileSize / 1024 ** 2;
    }
    if (fileSize && fileSize > 25) {
      this.notifyService.notify('Uploaded images should be smaller than 25MB.');
    } else {
      this.fileName = this.files[0].fileName;
    }
  }
}
