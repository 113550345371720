<div [class.warning-confirm-dialog]="data.isWarningDialog">
  <h1 mat-dialog-title data-cy="confirm-dialog_title">{{ data.title }}</h1>
  <ninety-button data-cy="confirm-dialog_close-button" flat icon class="close-dialog-btn" (click)="dialogRef.close()">
    <terra-icon icon="x" />
  </ninety-button>

  <mat-dialog-content>
    <p data-cy="confirm-dialog_message-text" [innerHTML]="data.message"></p>
    <p data-cy="confirm-additional-message-text" [innerHTML]="data.additionalMessage"></p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <ninety-button-row>
      <ninety-button
        [attr.data-product]="
          data.title === 'Delete Content Forever' && data.cancelButtonText === 'Cancel'
            ? 'learning-modules__confirm-dialog_cancel-delete-forever-btn'
            : null
        "
        stroked
        data-cy="confirm-dialog_cancel-button"
        (click)="dialogRef.close()">
        <terra-icon *ngIf="data.cancelButtonIcon" [icon]="data.cancelButtonIcon" />
        {{ data.cancelButtonText }}
      </ninety-button>
      <ninety-button
        [attr.data-product]="
          data.title === 'Delete Content Forever' && data.confirmButtonText === 'Delete Forever'
            ? 'learning-modules__confirm-dialog_delete-forever-btn'
            : null
        "
        filled
        primary
        class="confirm-dialog_ok-button"
        data-cy="confirm-dialog_ok-button"
        (click)="confirm()">
        <terra-icon *ngIf="data.confirmButtonIcon" [icon]="data.confirmButtonIcon" />
        {{ data.confirmButtonText }}
      </ninety-button>
    </ninety-button-row>
  </mat-dialog-actions>
</div>
