import { createSelector } from '@ngrx/store';

import { HelpfulPermissions } from '../../../_shared/models/_shared/person';
import { HelpfulPermissionsKey, selectGlobalAppState } from '../index';

export const selectHelpfulPermissionsState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[HelpfulPermissionsKey] : null
);

export const selectHelpfulPermissions = createSelector(
  selectHelpfulPermissionsState,
  ({ helpfulPermissions }) => helpfulPermissions
);

export const selectHasHelpfulPermission = (key: keyof HelpfulPermissions) =>
  createSelector(selectHelpfulPermissions, permissions => permissions[key]);

export const selectHasAnyHelpfulPermission = createSelector(selectHelpfulPermissions, permissions =>
  Object.values(permissions || []).some(p => p)
);

export const selectHasPartnerPermissions = createSelector(
  selectHelpfulPermissions,
  permissions => permissions.partners || permissions.partnersAdmin
);
