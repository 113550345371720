import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { GuideSelectors } from '@ninety/getting-started/guide/_state/guide.selectors';
import { Phase } from '@ninety/getting-started/guide/models/phase';
import { selectCurrentUserIsManagerOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import {
  selectDaysLeftInTrial,
  selectIsTrialSubscription,
} from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';

@Component({
  selector: 'ninety-phase-list',
  templateUrl: './phase-list.component.html',
  styleUrls: ['./phase-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhaseListComponent {
  protected readonly title$ = this.store.select(GuideSelectors.selectTitle);
  protected readonly subtitle$ = this.store.select(GuideSelectors.selectSubtitle);
  protected readonly phases$ = this.store.select(GuideSelectors.selectPhases);
  protected readonly isTrialing$ = this.store.select(selectIsTrialSubscription);
  protected readonly daysLeftInTrial$ = this.store.select(selectDaysLeftInTrial);
  protected readonly isManagerOrAbove$ = this.store.select(selectCurrentUserIsManagerOrAbove);

  constructor(private store: Store, private router: Router) {}

  close(): void {
    this.store.dispatch(GuideActions.closeGuide());
  }

  selectPhase(phase: Phase): void {
    if (phase.locked) return;
    this.store.dispatch(GuideActions.selectPhase({ phase }));
  }

  goToBilling(): void {
    this.router.navigateByUrl('settings/billing/overview');
    this.store.dispatch(GuideActions.closeGuide());
  }
}
