<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="rocks" />
      <span data-cy="rock-language_card-title">{{ rock.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="rock">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="rock.route + ' Page Name'"
          data-cy="rock-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="rock-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ rock.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="rock.item"
          data-cy="rock-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="rock-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ rock.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="rock.items"
          data-cy="rock-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="rock-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ rock.company }} (Leadership team):</mat-label>
        <input
          #company
          matInput
          formControlName="company"
          required
          [attr.aria-label]="rock.company"
          data-cy="rock-language_company-leadership-team-input-field" />
        <ninety-reset-btn
          *ngIf="company.value !== resetLanguage?.company"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('company')"
          [disabled]="readonly"
          data-cy="rock-language_company-leadership-team-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ rock.departmental }} (non-Leadership team):</mat-label>
        <input
          #departmental
          matInput
          formControlName="departmental"
          required
          [attr.aria-label]="rock.departmental"
          data-cy="rock-language_departmental-team-input-field" />
        <ninety-reset-btn
          *ngIf="departmental.value !== resetLanguage?.departmental"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('departmental')"
          [disabled]="readonly"
          data-cy="rock-language_departmental-team-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
