import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BannerNotificationModel } from './banner-notification.model';

export const BannerNotificationActions = createActionGroup({
  source: 'Banner Notification',
  events: {
    'Display Banner': props<{ banner: BannerNotificationModel }>(),
    'Dismiss Banner': props<{ banner: BannerNotificationModel }>(),
    'Dismiss Banner Success': emptyProps(),
    'No Banner': emptyProps(),
  },
});
