import { Pipe, PipeTransform } from '@angular/core';
import * as showdown from 'showdown';

import { MazMessage } from '@ninety/getting-started/maz/models/maz-message';
import { MazMessageSender } from '@ninety/getting-started/maz/models/maz-message-sender.enum';

@Pipe({
  name: 'mazMessageHtml',
})
export class MazMessageHtmlPipe implements PipeTransform {
  transform(message: MazMessage): string {
    if (message.sender === MazMessageSender.user) return message.text;
    const converter = new showdown.Converter();
    return converter.makeHtml(message.text);
  }
}
