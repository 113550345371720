import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ninety-empty-list-view-banner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!showBannerEmptyStateNoCardStyle; else bannerEmptyStateNoCardStyle">
      <mat-card>
        <mat-card-header *ngIf="bannerTitle">
          <mat-card-title>
            {{ bannerTitle }}
          </mat-card-title>
        </mat-card-header>
        <ninety-alert alertClass="secondary" class="alert-container">
          {{ bannerText }}
        </ninety-alert>
      </mat-card>
    </ng-container>
    <ng-template #bannerEmptyStateNoCardStyle>
      <mat-card-header *ngIf="bannerTitle">
        <mat-card-title class="title-box">
          {{ bannerTitle }}
        </mat-card-title>
      </mat-card-header>
      <div class="banner-view">
        <ninety-alert alertClass="secondary" class="alert-container">
          {{ bannerText }}
        </ninety-alert>
      </div>
    </ng-template>
  `,
  styleUrls: ['empty-list-view-banner.component.scss'],
})
export class EmptyListViewBannerComponent {
  @Input() bannerText: string;
  @Input() showBannerEmptyStateNoCardStyle: boolean;
  @Input() bannerTitle = '';
}
