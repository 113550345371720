import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ninety-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderComponent {
  @Input() inMeeting: boolean;
}
