import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatMenuModule } from '@angular/material/menu';

import { TerraIconModule } from '@ninety/terra';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { LogoUploadDialogComponent } from '@ninety/ui/legacy/shared/components/logo-upload-dialog/logo-upload-dialog.component';
import { PersonMetadata } from '@ninety/ui/legacy/shared/models/_shared/person-metadata';

@Component({
  selector: 'ninety-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  styleUrls: ['./logo-uploader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TerraIconModule, CommonModule, MatMenuModule],
})
export class NinetyLogoUploaderComponent {
  private _metadata;
  get metadata() {
    return this._metadata;
  }
  @Input() set metadata(metadata) {
    this._metadata = metadata;
  }

  @Input() readonly = false;

  @Input() companyId: string;

  @Output() updateMetadata = new EventEmitter<Partial<PersonMetadata>>();
  constructor(
    public legacyDialog: MatLegacyDialog,
    private userService: UserService,
    private spinnerService: SpinnerService
  ) {}

  public uploadNewLogo(): void {
    if (this.readonly) {
      return;
    }
    this.legacyDialog
      .open(LogoUploadDialogComponent, {
        id: 'avatar-upload-dialog',
        data: { companyId: this.companyId },
        maxHeight: '95vh',
      })
      .afterClosed()
      .subscribe({
        next: dialogResponse => {
          if (dialogResponse?.url) {
            this.metadata.picture = { url: dialogResponse.url, count: 1 };
            this.updateMetadata.emit(this.metadata);
          }
        },
      });
  }
}
