import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { AcceptInviteStateActions } from '@ninety/login/_state/accept-invite/accept-invite-state.actions';
import {
  initialAcceptInviteState,
  AcceptInviteStateModel,
} from '@ninety/login/_state/accept-invite/accept-invite-state.model';
import { LoginSharedStateActions } from '@ninety/login/_state/shared/login-shared-state.actions';

export const acceptInviteStateReducer = createReducer<AcceptInviteStateModel>(
  initialAcceptInviteState,

  on(
    AcceptInviteStateActions.startSpinner,
    (state): AcceptInviteStateModel => ({
      ...state,
      isSpinning: true,
    })
  ),

  on(
    AcceptInviteStateActions.stopSpinner,
    (state): AcceptInviteStateModel => ({
      ...state,
      isSpinning: false,
    })
  ),

  on(AcceptInviteStateActions.signUp, (state, action): AcceptInviteStateModel => {
    const newState: AcceptInviteStateModel = {
      submitEvent: action.submitEvent,
      isSpinning: true,
      registration: {
        firstName: action.firstName,
        lastName: action.lastName,
        emailAddress: action.emailAddress,
        password: action.password,
        signupCompanyRole: action.signupCompanyRole,
      },
    };
    return newState;
  }),

  on(
    AcceptInviteStateActions.signUpFailed,
    AcceptInviteStateActions.navigateToSignup,
    LoginSharedStateActions.navigateToLogin,
    (state): AcceptInviteStateModel => ({
      ...state,
      isSpinning: false,
    })
  ),

  on(LoginSharedStateActions.navigateToApp, (state, action): AcceptInviteStateModel => {
    const newState: AcceptInviteStateModel = {
      ...state,
      isSpinning: false,
      registration: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        password: null,
        signupCompanyRole: null,
      },
    };
    return newState;
  })
);
