import { MeasurableWeeklyStartDay } from './company';

export const MeasurableWeeklyStartDays: MeasurableWeeklyStartDay[] = [
  { offset: -1, day: 'Sunday' },
  { offset: 0, day: 'Monday' },
  { offset: 1, day: 'Tuesday' },
  { offset: 2, day: 'Wednesday' },
  { offset: 3, day: 'Thursday' },
  { offset: 4, day: 'Friday' },
  { offset: 5, day: 'Saturday' },
];
