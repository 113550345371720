import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';

export const FeedbackPageStateKey = 'page';

export interface FeedbackPageState {
  isWaiting: boolean;
  language: CustomLanguage | null;
}

export const initialFeedbackRootState: FeedbackPageState = {
  isWaiting: false,
  language: null,
};
