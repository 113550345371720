import type { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { MeetingAgenda, MeetingAgendas, Team } from '../../../_shared';

export const teamsStateActions = createActionGroup({
  source: 'Teams',
  events: {
    'Get By Id': props<{ _id: string }>(),
    'Get By Id Success': props<Team>(),
    'Get By Id Failed': props<{ error: unknown }>(),
    /** All Teams in the Company */
    'Get All': emptyProps(),
    'Get All Success': props<{ teams: Team[] }>(),
    'Get All Failed': props<{ error: unknown }>(),
    'Add One': props<{ team: Team }>(),
    'Remove One': props<{ _id: string }>(),
    'Update One': props<{ _id: string; changes: Partial<Team> }>(),
    'Update Many': props<{ updates: Update<Team>[] }>(),
    'Set All': props<{ teams: Team[] }>(),

    'Update Custom Agendas': props<{ teamId: string; customAgendas: MeetingAgenda[] }>(),
    'Update Agenda': props<{
      teamId: string;
      agendaType: keyof MeetingAgendas;
      agenda: MeetingAgenda;
    }>(),
    'Delete Custom Agenda': props<{ teamId: string; agendaId: string }>(),

    Init: props<{ teams: Team[] }>(),
  },
});
