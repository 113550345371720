<div
  class="agenda-settings"
  *ngIf="{
    somethingChanged: somethingChanged$ | ngrxPush,
    selectedMeetingType: selectedMeetingType$ | ngrxPush,
    selectedSection: selectedSection$ | ngrxPush,
    userTeams: teamService.userTeams$ | ngrxPush,
    meetingName: meetingName$ | ngrxPush,
  } as data">
  <mat-toolbar class="mat-elevation-z2 agendas-toolbar" [ngClass]="{ hidden: agendaV2 }">
    <div class="toolbar-block">
      <mat-form-field *ngIf="!template && selectedTeam" appearance="outline">
        <mat-label>Team</mat-label>
        <mat-select
          [value]="selectedTeam"
          [compareWith]="teamsAreSame"
          (selectionChange)="setTeamAgendas($event.value)"
          [disabled]="data.somethingChanged"
          matTooltip="Save or cancel changes before selecting a different Team"
          matTooltipClass="ninety-tooltip"
          matTooltipPosition="below"
          [matTooltipDisabled]="!data.somethingChanged">
          <mat-option *ngFor="let team of data.userTeams" [value]="team">
            {{ team.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="select-add-button-wrapper">
        <mat-form-field appearance="outline" [class.none-selected]="!data.selectedMeetingType" class="meeting-select">
          <mat-label>Agenda</mat-label>
          <mat-select
            data-cy="meeting-agendas_agenda-select-menu"
            [(ngModel)]="selectedAgendaId"
            (selectionChange)="selectMeetingType($event.value)"
            [disabled]="data.somethingChanged"
            [matTooltipDisabled]="!data.somethingChanged"
            matTooltip="Save or cancel changes before selecting a different Agenda"
            matTooltipClass="ninety-tooltip"
            matTooltipPosition="below"
            placeholder="Select Agenda">
            <mat-optgroup label="Default Agendas">
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.levelTen }}"
                [value]="MeetingAgendaType.weekly"
                >{{ meetingLang.levelTen }}</mat-option
              >
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.quarterlySession }}"
                [value]="MeetingAgendaType.quarterly"
                >{{ meetingLang.quarterlySession }}</mat-option
              >
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.annualSession }}"
                [value]="MeetingAgendaType.annualDayOne"
                >{{ meetingLang.annualSession }} - Day One</mat-option
              >
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.annualSession }}"
                [value]="MeetingAgendaType.annualDayTwo"
                >{{ meetingLang.annualSession }} - Day Two</mat-option
              >
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.focusDay }}"
                [value]="MeetingAgendaType.focusDay"
                >{{ meetingLang.focusDay }}</mat-option
              >
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.visionBuildingDayOne }}"
                [value]="MeetingAgendaType.visionBuildingDayOne"
                >{{ meetingLang.visionBuildingDayOne }}</mat-option
              >
              <mat-option
                attr.data-cy="meeting-agendas_item-{{ meetingLang.visionBuildingDayTwo }}"
                [value]="MeetingAgendaType.visionBuildingDayTwo"
                >{{ meetingLang.visionBuildingDayTwo }}</mat-option
              >
            </mat-optgroup>
            <mat-optgroup label="Custom Agendas" *ngIf="agendas?.custom">
              <mat-option [disabled]="true" *ngIf="!agendas.custom.length">No Custom Agendas</mat-option>
              <mat-option
                attr.data-cy="meeting-agendas_select-agenda-option-{{ agenda.name }}"
                *ngFor="let agenda of agendas.custom | sortByName; let i = index"
                [value]="agenda._id"
                (click)="selectCustomAgenda(agenda, i)">
                {{ agenda.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

        <button
          data-cy="meeting-agendas_add-custom-agenda-button"
          mat-stroked-button
          (click)="newCustomAgenda()"
          color="accent"
          aria-label="Add custom meeting agenda"
          [disabled]="meetingSectionsForm?.invalid || data.somethingChanged"
          matTooltip="Add Custom Meeting Agenda"
          class="add-custom-meeting-btn"
          matTooltipClass="ninety-tooltip"
          matTooltipPosition="above"
          *ngIf="!readonly">
          <terra-icon icon="add" />
        </button>
      </div>
    </div>

    <div class="fill-remaining-space"></div>

    <div class="action-btns" *ngIf="!readonly">
      <ninety-button-group>
        <button
          data-cy="meeting-agendas_save-changes-button"
          mat-stroked-button
          color="accent"
          (click)="save()"
          aria-label="Save"
          [disabled]="meetingSectionsForm?.invalid || !data.somethingChanged">
          <terra-icon icon="save" />
          <span class="large-screen-text">Save</span>
        </button>
        <button data-cy="meeting-agendas_more-options-button" mat-stroked-button [matMenuTriggerFor]="resetMenu">
          <terra-icon icon="list" />
          <span class="large-screen-text">More Options</span>
        </button>
      </ninety-button-group>

      <mat-menu #resetMenu="matMenu">
        <button
          data-cy="meeting-agendas_undo-all-changes-button"
          mat-menu-item
          (click)="cancel()"
          *ngIf="!cancelDisabled && !newAgenda"
          [disabled]="!data.somethingChanged">
          <terra-icon icon="undo" />
          Undo All Changes
        </button>
        <button mat-menu-item (click)="resetBackToWeekly()" *ngIf="newAgenda" [disabled]="!data.somethingChanged">
          <terra-icon icon="undo" />
          Undo All Changes
        </button>
        <terra-divider *ngIf="!hidePushButton"></terra-divider>
        <button
          data-cy="meeting-agendas_push-to-all-teams"
          mat-menu-item
          (click)="push()"
          *ngIf="!hidePushButton"
          [disabled]="data.somethingChanged">
          <terra-icon icon="caret-double-right" />
          Push to All Teams
        </button>
        <terra-divider />
        <button
          data-cy="meeting-agendas_reset-to-default-button"
          *ngIf="data.selectedMeetingType !== MeetingAgendaType.custom"
          mat-menu-item
          (click)="getResetAgendas.emit()">
          <terra-icon icon="reset" />
          Reset to Default
        </button>
        <terra-divider *ngIf="data.selectedMeetingType === MeetingAgendaType.custom"></terra-divider>
        <button
          data-cy="meeting-agendas_delete-agenda-button"
          *ngIf="data.selectedMeetingType === MeetingAgendaType.custom && !newAgenda"
          mat-menu-item
          aria-label="Delete Agenda"
          (click)="deleteAgenda()">
          <terra-icon icon="delete" />
          <span class="large-screen-text">Delete Agenda</span>
        </button>
      </mat-menu>

      <ng-container *ngIf="!template">
        <terra-divider vertical="true" class="vertical-divider left-space-16" />
        <button
          data-cy="meeting-agendas_close-page-button"
          mat-icon-button
          class="close-btn"
          aria-label="Close"
          (click)="goBackToMeetings()">
          <terra-icon icon="x" />
        </button>
      </ng-container>
    </div>
  </mat-toolbar>

  <div class="ninety-page-container">
    <form *ngIf="agendas && meetingSectionsForm; else noAgenda" [formGroup]="meetingSectionsForm">
      <div class="section-cards">
        <mat-card class="agenda-card" *ngIf="meetingSectionsForm" [fxHide.lt-md]="data.selectedSection">
          <div class="agenda-toolbar">
            <div class="title-div">
              <mat-card-title
                class="flex2"
                [ngClass]="{ hidden: agendaV2 }"
                *ngIf="data.selectedMeetingType !== MeetingAgendaType.custom; else customMeetingTitle">
                {{ data.meetingName }} Agenda
              </mat-card-title>

              <ng-template #customMeetingTitle>
                <div class="field-wrapper">
                  <mat-form-field
                    class="outline-field full-width meeting-title-field"
                    ninetyStopRipple
                    appearance="outline">
                    <mat-label class="muted">Custom Meeting Name</mat-label>
                    <input
                      id="meeting-title"
                      data-cy="meeting-agendas_meeting-title-input"
                      matInput
                      formControlName="name" />
                  </mat-form-field>
                </div>
              </ng-template>
            </div>
          </div>

          <mat-card-subtitle class="top-space-5" *ngIf="subtitle">{{ subtitle }}</mat-card-subtitle>

          <mat-toolbar class="header-btns">
            <mat-card-subtitle class="name-header">Section Name</mat-card-subtitle>
            <mat-card-subtitle class="number-header">Duration (minutes)</mat-card-subtitle>
            <mat-card-subtitle class="visible">Visible</mat-card-subtitle>
            <mat-card-subtitle class="more-button header">&nbsp;</mat-card-subtitle>
          </mat-toolbar>

          <mat-list
            data-cy="meeting-agendas_section-list"
            class="ninety-list no-hover agenda-list"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            formArrayName="agenda">
            <mat-list-item
              class="list-item"
              cdkDrag
              *ngFor="let section of meetingSectionsForm.get('agenda')['controls']; let i = index"
              [formGroupName]="i"
              [class.section-hidden]="section.value.isHidden"
              attr.data-cy="meeting-agendas_section-list-item-{{ i }}"
              [class.opened]="selectedIndex === i">
              <div
                attr.data-cy="meeting-agendas_drag-handle-{{ i }}"
                cdkDragHandle
                [class.invisible]="section.value.path === 'conclude' || readonly"
                class="drag">
                <terra-icon icon="drag" />
              </div>

              <mat-form-field class="flex1" appearance="outline">
                <mat-label *ngIf="isSmallScreen">Agenda Name</mat-label>
                <input
                  #nameInput
                  matInput
                  attr.data-cy="meeting-agendas_section-name-input-{{ i }}"
                  aria-label="Agenda name"
                  formControlName="name"
                  (input)="updateSectionName(i)" />
                <mat-error *ngIf="section.controls.name.errors?.['reservedName']">
                  Custom section names must be different than default section names
                </mat-error>
              </mat-form-field>

              <mat-form-field class="number" appearance="outline">
                <mat-label *ngIf="isSmallScreen">Duration</mat-label>
                <input
                  matInput
                  type="number"
                  attr.data-cy="meeting-agendas_section-duration-input-{{ i }}"
                  aria-label="Section duration"
                  formControlName="duration" />
              </mat-form-field>

              <div class="visible" [class.invisible]="section.value.path === 'conclude'">
                <ng-container *ngIf="!section.value.isCustom && !section.value.isDefault; else deleteBtn">
                  <terra-switch
                    class="section-toggle"
                    *ngIf="section.value.path === 'mastery' && isMasteryDisabled; else regularSectionToggle"
                    [matTooltip]="masteryDisabledTooltip"
                    matTooltipClass="ninety-tooltip"
                    matTooltipPosition="before"
                    [ngModel]="false"
                    [disabled]="true"
                    aria-label="Disabled">
                    <span class="small-screen-text">Disabled</span>
                  </terra-switch>
                  <ng-template #regularSectionToggle>
                    <terra-switch
                      attr.data-cy="meeting-agendas_section-default-visible-{{ i }}"
                      class="section-toggle"
                      name="isHidden"
                      formControlName="isHidden"
                      [ngModel]="section.value.isHidden"
                      matTooltip="Hide default section from agenda"
                      matTooltipClass="ninety-tooltip"
                      matTooltipPosition="below"
                      aria-label="Visible">
                    </terra-switch>
                  </ng-template>
                </ng-container>
                <ng-template #deleteBtn>
                  <terra-switch
                    class="section-toggle"
                    *ngIf="section.value.path === 'mastery' && isMasteryDisabled; else regularSectionDelete"
                    [matTooltip]="masteryDisabledTooltip"
                    matTooltipClass="ninety-tooltip"
                    matTooltipPosition="before"
                    [ngModel]="false"
                    [disabled]="true"
                    aria-label="Disabled">
                  </terra-switch>
                  <ng-template #regularSectionDelete>
                    <button
                      attr.data-cy="meeting-agendas_section-delete-button-{{ i }}"
                      type="button"
                      mat-icon-button
                      color="warn"
                      matTooltip="Delete Section"
                      matTooltipClass="ninety-tooltip"
                      matTooltipPosition="before"
                      (click)="removeSection(i)"
                      *ngIf="!readonly">
                      <terra-icon icon="delete" />
                    </button>
                  </ng-template>
                </ng-template>
              </div>
              <div
                class="action-column"
                [matTooltip]="
                  !readonly && (section.value.isEditable || section.value.isCustom)
                    ? 'Edit section'
                    : 'This default section cannot be edited'
                "
                matTooltipClass="ninety-tooltip"
                matTooltipPosition="below">
                <button
                  attr.data-cy="meeting-agendas_section-action-button-{{ i }}"
                  mat-icon-button
                  [disabled]="readonly || (!section.value.isEditable && !section.value.isCustom)"
                  (click)="selectSection(section.value, i)"
                  class="more-button">
                  <terra-icon
                    attr.data-cy="meeting-agendas_section-action-icon-{{ i }}"
                    [icon]="section.value.isEditable || section.value.isCustom ? 'edit' : 'lock'" />
                </button>
              </div>
            </mat-list-item>

            <mat-list-item class="list-item" *ngIf="!readonly">
              <button
                data-cy="meeting-agendas_add-custom-section-button"
                mat-button
                color="accent"
                (click)="addSection()">
                <terra-icon icon="add" />
                Add Custom Section
              </button>

              <button
                data-cy="meeting-agendas_add-tool-menu-button"
                mat-button
                [matMenuTriggerFor]="defaultSectionMenu"
                color="primary"
                *ngIf="data.selectedMeetingType === MeetingAgendaType.custom || stateService.isManagerOrAbove">
                <terra-icon icon="add" />
                Add Tool Section
                <terra-icon icon="caret-down" />
              </button>
              <mat-menu #defaultSectionMenu="matMenu">
                <ninety-button
                  *ngFor="let section of availableDefaultSections"
                  attr.data-cy="meeting-agendas_add-tool-menu-item-{{ section.name }}"
                  mat-menu-item
                  (click)="addSection(section)">
                  <terra-icon [icon]="section.terraIcon" size="20" class="mat-menu-icon" />
                  <span>{{ section.name }}</span>
                </ninety-button>
              </mat-menu>

              <div class="fill-remaining-space"></div>
              <h4 class="total-duration">
                <span class="muted">Total Duration:</span>&nbsp;
                <span *ngIf="totalDuration / 60 | roundDown as hours"
                  >{{ hours | number : '0.0-0' }} {{ hours > 1 ? 'hours' : 'hour' }}</span
                >&nbsp;
                <span *ngIf="totalDuration % 60 as minutes"
                  >{{ minutes | number : '1.0-0' }} {{ minutes > 1 ? 'minutes' : 'minutes' }}</span
                >
              </h4>
            </mat-list-item>
          </mat-list>
        </mat-card>
        <div class="flex1" *ngIf="data.selectedSection">
          <mat-card class="detail-view">
            <mat-toolbar>
              <button
                mat-icon-button
                data-cy="meeting-agendas_section-details-close-button"
                (click)="closeDetails()"
                matTooltip="Close details"
                matTooltipPosition="above"
                matTooltipClass="ninety-tooltip"
                aria-label="Close details">
                <terra-icon data-cy="meeting-agendas_section-details-close-icon" icon="caret-right" />
              </button>
              <terra-divider vertical="true" class="vertical-divider" />
              <mat-card-title class="custom-section-title">{{ data.selectedSection.name }}</mat-card-title>
            </mat-toolbar>

            <mat-card-content class="item-details">
              <div *ngIf="data.selectedSection.isCustom" class="flex-align-center bottom-space-15">
                <mat-form-field class="full-width suffix-btn-link iframe-form" appearance="outline">
                  <mat-label>Web page URL to load (must be secure site that starts with https://)</mat-label>
                  <input
                    #iframeUrl
                    matInput
                    type="url"
                    name="iframeUrl"
                    [value]="data.selectedSection.iframeUrl"
                    placeholder="https://www.{{ stateService.domain }}"
                    [disabled]="readonly"
                    (blur)="updateSelectedSection({ iframeUrl: iframeUrl.value })"
                    aria-label="Web page URL to load"
                    (change)="iframeVisible = false" />
                  <button
                    mat-button
                    matSuffix
                    color="accent"
                    (click)="showIframe()"
                    aria-label="Open Preview"
                    [disabled]="!iframeUrl.value">
                    Check URL
                    <terra-icon icon="tool" />
                  </button>
                </mat-form-field>
                <button
                  mat-icon-button
                  class="iframe-info-btn"
                  (click)="iframeWarning = !iframeWarning"
                  aria-label="Show iFrame warning">
                  <terra-icon icon="warning-triangle" />
                </button>
              </div>
              <div class="top-space-15" *ngIf="iframeWarning">
                <ninety-alert>
                  *** Some websites block the ability to be loaded into an iframe on a different domain. Please make
                  sure your page is visible before saving. If you are unable to see the page, double check the URL. Then
                  see if there is an iframe friendly link.
                </ninety-alert>
              </div>
              <div class="iframe-div" #iframeDiv>
                <iframe
                  *ngIf="iframeVisible && data.selectedSection.iframeUrl"
                  [height]="iframeHeight + 'px'"
                  [width]="iframeWidth + 'px'"
                  [src]="data.selectedSection.iframeUrl | safeUrl"></iframe>
              </div>
              <div *ngIf="data.selectedSection.isEditable" class="flex1 bottom-space-15">
                <mat-form-field class="full-width meeting-title-field" appearance="outline">
                  <mat-label>Subtitle</mat-label>
                  <textarea
                    #subtitle
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    aria-label="subtitle"
                    name="subtitle"
                    data-cy="meeting-agendas_section-details-subtitle-input"
                    [value]="data.selectedSection.subtitle"
                    (blur)="updateSelectedSection({ subtitle: subtitle.value })">
                  </textarea>
                </mat-form-field>
              </div>
              <ninety-text-editor
                placeholder="Section Details..."
                *ngIf="
                  data.selectedSection.isCustom ||
                  (data.selectedSection.isEditable && data.selectedSection.path.toLowerCase() !== 'headlines')
                "
                data-cy="meeting-agendas_section-details-text-editor"
                [(text)]="data.selectedSection.details"
                [readonly]="readonly"
                (textChange)="updateSelectedSection({ details: $event })">
              </ninety-text-editor>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
  </div>

  <ng-template #noAgenda>
    <ninety-alert alertClass="warning">No Agenda Selected</ninety-alert>
  </ng-template>
</div>
