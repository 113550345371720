import { createActionGroup, emptyProps, props } from '@ngrx/store';
import Pubnub from 'pubnub';

import { RealtimeMessage } from '../../../_shared/models/meetings/realtime-message';

export const RealTimeActions = createActionGroup({
  source: 'Real Time',
  events: {
    'Send Message': props<{ channelId: string; message: RealtimeMessage }>(),
    'Message Received': props<{ channelId: string; message: RealtimeMessage; emitterId?: string }>(),

    'Add Channel': props<{ channelId: string; withPresence: boolean }>(),
    'Remove Channel': props<{ channelId: string }>(),
    'Reset Channels': emptyProps(),

    'Presence Changed': props<{ channelId: string; userId: string; action: Pubnub.PresenceEvent['action'] }>(),
  },
});
