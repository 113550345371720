import { createActionGroup, props } from '@ngrx/store';

import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationManagee } from '@ninety/ui/legacy/shared/models/feedback/conversation-managee';

export const CreateConversationDialogActions = createActionGroup({
  source: 'Create Conversation Dialog',
  events: {
    'Create Conversation Dialog Saved': props<{
      managees: ConversationManagee[];
      conversation: Conversation;
      createTodos: boolean;
    }>(),
  },
});
