import { ErrorWithId } from './error-with-id';

export class ValidationError extends ErrorWithId {
  public static fromFailedValidation<T>(actual: T, message: string): ValidationError {
    return new ValidationError(`Validation Error: ${message} - received ${actual}`);
  }

  constructor(public message: string, public readonly name = 'ValidationError') {
    super(message, name);
  }
}
