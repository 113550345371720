<h3 mat-dialog-title data-cy="terms-conditions-dialog_header">Terms and Conditions</h3>

<mat-dialog-content id="terms-conditions" aria-label="Terms and Conditions">
  <div>
    <p class="body-font">We reserve the right to modify these Terms and Conditions at any time.</p>
    <h4 class="headline">1. GENERAL</h4>
    <p class="body-font">
      The terms “we,” “us,” and “our” refer to {{ appName }}, LLC. and its subsidiaries and affiliates (collectively,
      “{{ appName }}”). The term “Services” refers to our Software as a Service application provided pursuant to these
      terms and conditions (“Terms and Conditions”). The terms “you” and “yours” refer to the individual or company
      registering for or using the Services offered by us on this website.
    </p>
    <h4 class="headline">2. SCOPE</h4>
    <p class="body-font">
      2.1 These Terms and Conditions apply to your use of all of the sites and Services owned or operated by
      {{ appName }}, LLC. (“{{ appName }}”) (“we” and “us”), for the benefit of users in the United States of America
      (“USA”) and any other site that we may own or operate in the future (collectively, the “Site”). Unless we say
      otherwise, all references to the Site in these Terms and Conditions include all such sites. {{ appName }} agrees
      to provide the Services that you register for through this Site. You agree to perform the obligations set forth in
      these Terms and Conditions that are required to allow {{ appName }} to deliver the Services.
    </p>
    <p class="body-font">
      2.2 The contents of this Site are protected by national and international copyright and trademark laws and are the
      property of {{ appName }}. You may not change or delete any author credit, trademark, legend, proprietary or
      copyright notice. You must follow and observe all additional copyright notices or other restrictions contained in
      any parts of the Site.
    </p>
    <p class="body-font">
      2.3 {{ appName }} hereby grants you a non-exclusive, non-transferable, limited right to access and use the Site
      and the Services for your own personal or your internal commercial use only. You agree not to interrupt, or
      attempt to interrupt, the operation of the Site in any way. We reserve the right to restrict your access to part
      or all of the Site (including any password protected areas) at any time without notice or liability.
    </p>
    <h4 class="headline">3. {{ appName }} SERVICES</h4>
    <p class="body-font">
      3.1 You are responsible for providing {{ appName }} with the correct information about you when you register for
      the {{ appName }} Service. Required details may include, without limitation, email address and method of payment
      details. Account details may be updated within the Settings Pages for the users account within the
      {{ appName }} website.
    </p>
    <p class="body-font">
      3.2 You activate the Services by either: (a) using the {{ appName }} website (www.Ninety.com).
    </p>
    <h4 class="headline">4. COMPLIANCE OF Ninety WEBSITE</h4>
    <p class="body-font">
      4.1 You are responsible for your own continuous compliance with these Terms and Conditions and other guidelines
      released by us from time to time. You shall not, and shall not permit others to, engage in activities prohibited
      by us, including, without limitation: (a) intentionally accessing data not intended for your use; (b) attempting
      to breach security or authentication measures without proper authorization, or interfere with this Site and any
      products or Services offered on the Site; (c) taking any action to obtain Services to which you are not entitled;
      or (d) assisting or permitting any persons in engaging in any of the activities described above.
    </p>
    <p class="body-font">
      4.2 We own and shall retain all rights and interest in the intellectual property rights in the Site content,
      Services.
    </p>
    <h4 class="headline">5. EMAIL NOTIFICATIONS</h4>
    <p class="body-font">
      5.1 As part of the Services, Ninety may send reminders, alerts (further referred to as ‘notifications’).
    </p>
    <h4 class="headline">6. FEES</h4>
    <p class="body-font">
      6.1 You agree to pay all fees for Services provided to you pursuant to these Terms and Conditions.
    </p>
    <p class="body-font">
      6.2 Ninety shall process the subscription fee and other Services by charging your credit card/debit card at the
      time of activation and will reoccur on a monthly basis. You must have a valid credit/debit card or suspension of
      services may occur. If your credit card or debit card is declined your Ninety account may be blocked or
      terminated.
    </p>
    <h4 class="headline">7. TERMINATION AND REMEDIES</h4>
    <p class="body-font">
      7.1 Without limiting other rights or remedies set forth in these Terms and Conditions or as otherwise available by
      law or in equity, we may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate your
      access to this website and the Services, in whole or in part: (a) if you breach these Terms and Conditions; (b) if
      we are unable to verify or authenticate any information you provide to us, should we elect to engage in such
      verification; or (c) if we believe, in our sole discretion, that your actions may cause legal liability for you,
      other users, us or third parties.
    </p>
    <h4 class="headline">8. WARRANTY DISCLAIMER; LIMITATION OF LIABILITY</h4>
    <p class="body-font">
      8.1 Except as expressly provided otherwise, all Services sold, licensed or provided through this website are
      governed by these terms and conditions and are provided on an “AS IS” basis. Ninety does not make any express or
      implied warranties, conditions or representations to you with respect to the Services, third party work provided
      hereunder or otherwise regarding these terms and conditions, whether oral or written, express, implied or
      statutory. No representation or other affirmation of facts, including, but not limited to, statements regarding
      the performance of the Services which is not contained in these terms and conditions, shall be binding on Ninety.
      You acknowledge and agree that Ninety shall not be liable for any error, omissions, defect, deficiency or
      nonconformity in the Services. Without limiting the foregoing, Ninety expressly excludes and disclaims any implied
      warranty or condition of merchantability, accuracy or fitness for a particular purpose or that the Services (or
      your use hereof) will be error free or uninterrupted. You assume all risks associated with the Services.
    </p>
    <p class="body-font">
      8.2 Neither Ninety nor its officers, directors, employees, shareholders, agents, or representatives shall be
      liable for any incidental, indirect, special, exemplary, consequential or punitive damage or other similar type of
      damages, including, but not limited to, damages or costs incurred as a result of loss of time, loss of savings,
      loss of data, loss of profits, loss of goodwill, loss of business opportunities or software error, whether
      foreseeable or unforeseeable, that may arise out of or in connection with these Terms and Conditions, including
      but not limited to, damages or costs resulting from the use of or inability to use the Services, even if Ninety
      has been notified of the possibility or likelihood of such damages or costs occurring, and whether such liability
      is based on contract, tort, warranty, negligence, strict liability, products liability or otherwise, and in no
      event will your recovery for any proceeding arising out of or relating to these Terms and Conditions exceed in the
      aggregate the amount of money paid by you to Ninety.
    </p>
    <p class="body-font">
      8.3 Ninety is not responsible for any loss or damage that you may suffer as a result of you not using the Services
      correctly or if you fail to comply with these Terms and Conditions or any other instructions given to you by
      Ninety.
    </p>
    <h4 class="headline">9. INDEMNIFICATION</h4>
    <p class="body-font">
      You agree to indemnify, defend and hold us, our subsidiaries and affiliates and each of our respective officers,
      directors, agents, employees, independent contractors and suppliers, harmless from any claim, demand, action, cost
      and expense, including reasonable attorneys’ fees, due to or arising out of the following events: (i) your giving
      us any information which is inaccurate; (ii) your breach of any warranty, representation or other obligation set
      forth in these Terms and Conditions; (iii) your negligence or willful misconduct; (iv) your violation of any law,
      regulation or right of any third party; (v) any dispute or action between you and any third party, including
      parties selling or advertising goods or Services on this website; and (vi) your use of this website or the
      products or Services of Ninety or any third party.
    </p>
    <h4 class="headline">10. GOVERNING LAW; ARBITRATION</h4>
    <p class="body-font">
      10.1 These Terms and Conditions shall be governed and interpreted in accordance with the laws of the State of
      Georgia without regard to its conflicts of laws principles. Contracts shall not apply to or govern these Terms and
      Conditions. The parties further agree that these Terms and Conditions shall be deemed to have been entered into,
      executed and performed for all purposes within the State of Georgia.
    </p>
    <p class="body-font">
      10.2 If there should be a dispute arising out of or relating to these Terms and Conditions or yours or our
      respective rights and duties hereunder, then the dispute shall be resolved in the following manner: (i) any party
      to the dispute may at any time deliver to the others a written dispute notice setting forth a brief description of
      the issue(s) for which such notice initiates the dispute resolution mechanism contemplated by this Section 10.2;
      (ii) during the forty-five (45) day period following the delivery of the notice described in Section 10.2(i)
      above, appropriate representatives of the various parties will meet and seek to resolve the disputed issue(s)
      through negotiation, (iii) if representatives of the parties are unable to resolve the disputed issue(s) through
      negotiation, then within fifteen (15) days after the period described in Section 10.2(ii) above, the parties will
      refer the issue (to the exclusion of a court of law) to final and binding arbitration in Atlanta, Georgia (which
      shall be the seat of the arbitration) in accordance with the Federal Arbitration Act, 9 U.S.C. Section 1, et.
      seq., as amended from time to time (the “FAA”), and judgment upon the award rendered by the arbitrators may be
      entered in any court having jurisdiction thereof; provided, however, that the law applicable to any controversy
      shall be the law of the State of Georgia, regardless of principles of conflicts of laws. In any arbitration
      pursuant to these Terms and Conditions, (i) discovery shall be allowed and governed in accordance with the then
      existing rules (“Rules”) of the American Arbitration Association, (ii) the award or decision shall be rendered by
      a majority of the members of a Board of Arbitration consisting of three (3) members, one of whom shall be
      appointed by each of the respective parties and the third of whom shall be the chairman of the panel and be
      appointed by mutual agreement of said two party-appointed arbitrators, and (iii) arbitrator fees and expenses
      shall be paid jointly by the parties. In the event of failure of said two (2) arbitrators to agree within thirty
      (30) days after the commencement of the arbitration proceeding upon the appointment of the third arbitrator, the
      third arbitrator shall be appointed by the mutual agreement of the parties. In the event that either party shall
      fail to appoint an arbitrator within fifteen (15) days after the commencement of the arbitration proceeding, such
      arbitrator and the third arbitrator shall be appointed by the arbitrator(s) appointed by the other party(ies).
      Nothing set forth above shall be interpreted to prevent the parties from agreeing in writing to submit any dispute
      to a single arbitrator in lieu of a three (3) member Board of Arbitration. Upon the completion of the selection of
      the Board of Arbitration (or if the parties agree otherwise in writing, a single arbitrator), an award or decision
      shall be rendered within no more than thirty (30) days. Notwithstanding the foregoing, the request by either party
      for preliminary or permanent injunctive relief, whether prohibitive or mandatory, shall not be subject to
      arbitration and may be adjudicated only by the courts of the State of Georgia or the Federal District Court for
      the Northern District of Georgia; provided, the party seeking any such injunctive relief shall be entitled to make
      such request in any court of any other jurisdiction where jurisdiction and venue are proper. EACH PARTY HERETO
      HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVES, TO THE FULLEST EXTENT IT MAY LEGALLY AND EFFECTIVELY DO SO, TRIAL
      BY JURY IN ANY SUIT, ACTION OR PROCEEDING ARISING HEREUNDER.
    </p>
    <p class="body-font">
      10.3 No arbitration or other action may be brought by either you or us, or on any claim or controversy based upon
      or arising in any way out of these Terms and Conditions, after the first to occur of the expiration of the
      applicable statute of limitations and two (2) years from the date of the Service in question.
    </p>
    <p class="body-font">
      10.4 We intend for this website to be used by adults only and individuals or companies that can form legally
      binding contracts under applicable law. This Site and Services should not be used by minors. If you do not
      qualify, you are not permitted to use this Site or order or use the Services, and you do not have our consent to
      do so.
    </p>
    <p class="body-font">
      10.5 Without limiting any other remedies, we may suspend or terminate your account if you are found (by
      conviction, settlement, insurance or escrow investigation, or otherwise) to have engaged in fraudulent activity in
      connection with this website or to have otherwise violated these Terms and Conditions. We reserve the right in our
      sole discretion to refund any part of or none of the monies paid by you to us for use of the Services.
    </p>
    <h4 class="headline">11. MISCELLANEOUS</h4>
    <p class="body-font">
      11.1 Privacy. Our current Privacy Policy is available on this website and is incorporated in these Terms and
      Conditions by reference. We may change our Privacy Policy from time to time, as stated therein.
    </p>
    <p class="body-font">
      11.2 Acknowledgment. You acknowledge that (i) you have had the opportunity to have these Terms and Conditions
      reviewed by counsel and be advised by counsel as to the rights and obligations of you under these Terms and
      Conditions and (ii) you understand these Terms and Conditions.
    </p>
    <p class="body-font">
      11.3 Miscellaneous. If any provision of these Terms and Conditions are found unlawful, void or for any reason
      unenforceable, then that provision will be considered severable from the remaining Terms and Conditions, and will
      not affect the validity and enforceability of the remaining provisions. These Terms and Conditions are the entire
      agreement between you and us relating to the subject matter hereof. These Terms and Conditions may be modified
      only by our posting of changes to these Terms and Conditions, or by a writing signed by both parties. Any
      inquiries concerning these Terms and Conditions should be directed to
      <a href="mailto://info@ninety.com">info@ninety.com</a>
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ninety-button stroked mat-dialog-close="false" data-cy="terms-conditions-dialog_close-button"> Close </ninety-button>
</mat-dialog-actions>
