import { TerraIconName } from '@ninety/terra';

import { BannerType } from '../../../_shared/models/_shared/banner-type';

import { BannerNotificationEventName } from './banner-notification.event-name.type';

export interface BannerNotificationModel {
  message: string;
  actionMessage?: string;
  actionRoute?: string;
  type?: BannerType;
  hasIcon?: boolean;
  iconKey?: TerraIconName;
  dismissible?: boolean;
  callToActionActive?: boolean;
  bannerNotificationEventName?: BannerNotificationEventName;
}

export const BannerNotificationStateKey = 'banner-notification';
