import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError } from 'rxjs';

import { Interaction } from '../../_state/app-global/interactions/models/interaction';

import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  private readonly interactionApi = 'api.qa1.90srv.com/Interactions';

  constructor(private http: HttpClient, private errorService: ErrorService, private router: Router) {}

  getInteraction(interactionId: string): Observable<Interaction> {
    return this.http.get<Interaction>(`${this.interactionApi}/${interactionId}`).pipe(catchError(ErrorService.handle));
  }

  createNewInteraction(newInteraction: Interaction) {
    return this.http
      .post<Interaction>(`${this.interactionApi}/`, { ...newInteraction, currentPage: this.router.url })
      .pipe(catchError((e: unknown) => this.errorService.catchError(e)));
  }
  updateInteraction(interactionId: string, update: Partial<Interaction>) {
    return this.http
      .patch<void>(`${this.interactionApi}/${interactionId}`, update)
      .pipe(catchError((e: unknown) => this.errorService.catchError(e)));
  }
}
