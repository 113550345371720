import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ninetyMenuRow]',
  exportAs: 'ninetyMenuRow',
  standalone: true,
})
export class MenuRowDirective {
  private isSelected = false;
  constructor(public elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(elementRef.nativeElement, 'menu-row');
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.renderer.addClass(this.elementRef.nativeElement, 'menu-row-hover');
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.renderer.removeClass(this.elementRef.nativeElement, 'menu-row-hover');
  }

  @HostListener('click')
  onClick() {
    this.setSelectedClass();
  }

  private setSelectedClass() {
    if (!this.isSelected) {
      this.renderer.addClass(this.elementRef.nativeElement, 'menu-row-selected');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'menu-row-selected');
    }
    this.isSelected = !this.isSelected;
  }
}
