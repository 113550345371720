import { EventEmitter } from '@angular/core';

import { SelectOption } from './select-option.interface';

export class SelectBase<T extends SelectOption> {
  public validOptions: T[] = [];
  public selectedOption?: T = null;
  public disabled: boolean;
  public valueChanged = new EventEmitter<T>();
  public value = '';

  // Write a value to the UI, something changed on the form, lets the UI know about it.
  public writeValue(value: string): void {
    if (!value) {
      this.selectedOption = null;
      return;
    }
    this.value = value;
    this.selectedOption = this.validOptions?.find(o => o.value === value);
  }

  // Something changed on the UI lets know the form about it.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public onChange = (_: string) => {};

  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Update the local value and notify the parent
  public onValueChange(option: T) {
    this.onTouched();
    this.onChange(option.value);
    this.value = option.value;
    this.selectedOption = option;
    this.valueChanged.emit(option);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
