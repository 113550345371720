import { createSelector } from '@ngrx/store';

import { ViewportStateKey, selectGlobalAppState } from '../..';

import { NinetyBreakpointNames, NinetyBreakpoints, initialViewportState } from './viewport.model';

export const selectViewportState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[ViewportStateKey] : initialViewportState
);

/**
 * Selects the current screen size from Store.
 *
 * Examples: 'HandsetPortrait', 'XLarge', etc.
 *
 * @see `NinetyBreakpointNames`
 */
export const selectCurrentScreenSize = createSelector(selectViewportState, state => state.currentScreenSize);

/**
 * Selects the current screen size from Store and returns a boolean.
 */
export const selectIsHandsetPortrait = createSelector(
  selectViewportState,
  state => state.currentScreenSize === NinetyBreakpointNames.HandsetPortrait
);

/**
 * Selects a layout mode based on the currentScreenSize and
 * provided mapping configuration.
 *
 * @param layoutMode Configuration object that maps screen sizes to desired layout
 *
 * @example
 *  type Mode = 'list' | 'cards' | 'columns';
 *  const config: Record<NinetyBreakpointNames, Mode> = {
 *     'Small': 'list',
 *     'Medium': 'list',
 *     'Large': 'cards',
 *     'XLarge': 'columns',
 *     'HandsetPortrait': 'list',
 *     'TabletPortrait': 'list',
 *     'HandsetLandscape': 'list',
 *     'TabletLandscape': 'cards'
 *  }
 *  const layoutMode$: Observable<Mode> = this.store.select(selectLayoutFactory(config))
 *
 */
export function selectLayoutFactory<T>(layoutMode: Record<NinetyBreakpointNames, T>) {
  return createSelector(selectCurrentScreenSize, screenSize => (screenSize ? layoutMode[screenSize] : null));
}

/**
 * Selects whether there is a match for one of the provided breakpoints. This is helpful because
 * it is possible that there is a match for more than 1 breakpoint at a time. In this case, if you don't
 * care about which specific breakpoint is matched, only that there is a match, this factory can be useful.
 *
 * @param breakpoints Array of breakpoints to check for matches
 *
 * @example
 *  const specialBreakpoints: NinetyBreakpointNames[] = [
 *   NinetyBreakpointNames.Small,
 *   NinetyBreakpointNames.Medium,
 * ];
 *  const specialBreakpointIsMatched$: Observable<boolean> = this.store.select(selectBreakpointsContainMatchFactory(specialBreakpoints))
 */
export function selectBreakpointsContainMatchFactory(breakpoints: NinetyBreakpointNames[]) {
  return createSelector(selectViewportState, state => breakpoints.some(b => state.breakpoints[NinetyBreakpoints[b]]));
}
