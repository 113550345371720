import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionModule } from '../forms/terra-description';

import { TerraCheckboxComponent } from './terra-checkbox.component';

@NgModule({
  imports: [CommonModule, TerraCheckboxComponent, TerraDescriptionModule],
  exports: [TerraCheckboxComponent, TerraDescriptionModule],
})
export class TerraCheckboxModule {}
