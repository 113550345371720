import { createActionGroup, props } from '@ngrx/store';

import { CompanyAffiliateResponse } from '../../../_shared/models/company/company-affiliate-response';
import { DomainPartnerConfiguration } from '../../../_shared/models/login/login-domain-partner-configurations';

export const AnonymousLoginStateActions = createActionGroup({
  source: 'Anonymous Login',
  events: {
    'Set Partner Configuration': props<{
      configuration: DomainPartnerConfiguration;
    }>(),
    'Set Affiliate Company': props<{ affiliateCompany: CompanyAffiliateResponse }>(),
  },
});
