import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ninety-card-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        box-sizing: border-box;
        padding: 16px 24px;
      }
    `,
  ],
})
export class CardHeaderComponent {
  @HostBinding('class.ninety-card-header') classes = true;
}
