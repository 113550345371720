<div
  class="profile-pic"
  [class.no-logo]="!metadata.picture?.url"
  aria-label="Upload New Profile Picture"
  [matMenuTriggerFor]="menu">
  <img data-cy="avatar-uploader_avatar-picture" [src]="metadata.picture?.url" alt="profile picture" />

  <terra-icon icon="image-square" size="40" />
</div>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let item of menuItems"
    (click)="onMenuItemClick(item)"
    [ngClass]="item.className"
    [disabled]="item.isDisabled">
    <span class="menu-item"><terra-icon [icon]="item.icon" /></span>
    <span attr.data-cy="avatar-uploader_menu-item-{{ item.label }}" class="menu-item">{{ item.label }}</span>
  </button>
</mat-menu>
