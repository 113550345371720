import { Team } from './team';

export interface CreateTeamRequest {
  addCreatorToTeam?: boolean;
  name: string;
}

export class CreateTeam extends Team {
  addCreatorToTeam?: boolean;

  constructor(name: string, addCreatorToTeam?: boolean) {
    super(name);
    this.addCreatorToTeam = addCreatorToTeam;
  }

  static toTeam(createTeam: CreateTeam): Team {
    const team = { ...createTeam };
    delete team['addCreatorToTeam'];
    return team;
  }

  static toCreateTeamRequest({ addCreatorToTeam, name }: CreateTeam): CreateTeamRequest {
    return {
      ...(addCreatorToTeam != null ? { addCreatorToTeam } : null),
      name,
    };
  }
}
