import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { GetUserChartsResponseDto } from '@ninety/accountability-chart/models/get-user-charts-response.dto';
import {
  CreateResponsibilityChartModel,
  ResponsibilityChartModel,
  ResponsibilityContextMenuSource,
  UpdateResponsibilityChartModel,
} from '@ninety/accountability-chart/models/responsibility-chart.model';

export const SharedResponsibilityChartsActions = createActionGroup({
  source: 'Responsibilities - Shared',
  events: {
    /** Lifecycle Events*/
    Init: emptyProps(),
    Destroy: emptyProps(),

    /** Fetches the users created/shared with MACs */
    'Fetch MAC': emptyProps(),
    'Fetch MAC Success': props<{ response: GetUserChartsResponseDto }>(),
    'Fetch MAC Failure': props<{ error?: unknown; message: string }>(),

    /** When clicking on a MAC in the list */
    'Set Selected Chart Id': props<{ chartId: string }>(),
    'Open Update MAC Dialog': props<{ chartId: string }>(),
    'Update MAC': props<{ chart: UpdateResponsibilityChartModel }>(),
    'Update MAC Success': props<{ chart: ResponsibilityChartModel }>(),
    'Update MAC Failure': props<{ error?: unknown; message: string }>(),

    /** When closing the detail dialog */
    'Cancel MAC Dialog': emptyProps(),

    /** When cloning a MAC */
    'Clone MAC': props<{ chartId: string; source: ResponsibilityContextMenuSource }>(),
    'Clone MAC Success': props<{
      chart: ResponsibilityChartModel;
      source: ResponsibilityContextMenuSource;
    }>(),
    'Clone MAC Failure': props<{ error?: unknown; message: string }>(),

    /** When clicking on create from the toolbar */
    'Open Create MAC Dialog': emptyProps(),
    'Create MAC': props<{
      chart: CreateResponsibilityChartModel;
      source: ResponsibilityContextMenuSource;
    }>(),
    'Create MAC Success': props<{
      chart: ResponsibilityChartModel;
      source: ResponsibilityContextMenuSource;
    }>(),
    'Create MAC Failure': props<{ error?: unknown; message: string }>(),

    /** Soft delete a MAC */
    'Open Delete MAC Dialog': props<{ chartId: string }>(),
    'Cancel Delete MAC Dialog': emptyProps(),
    'Delete MAC': props<{ chartId: string }>(),
    'Delete MAC Confirmed': props<{ chartId: string }>(),
    'Delete MAC Canceled': emptyProps(),
    'Delete MAC Success': props<{ chartId: string }>(),
    'Delete MAC Failure': props<{ error?: unknown; message: string }>(),

    /** Context actions */
    'Set Primary': props<{ chartId: string; source: ResponsibilityContextMenuSource }>(),
    'Set Primary Success': props<{
      oldPrimaryChartId: string;
      newPrimaryChartId: string;
      source: ResponsibilityContextMenuSource;
    }>(),
    'Set Primary Failure': props<{ error?: unknown; message: string }>(),
  },
});
