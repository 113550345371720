import { createSelector } from '@ngrx/store';

import { CurrentPersonStateKey, selectGlobalAppState } from '..';

/** helpful@ninety.io */
export const HELPFUL_ACTUAL_PERSON_ID = '58f64a444b58c10010d09d38';

export const selectCurrentPersonState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[CurrentPersonStateKey] : null
);

export const selectCurrentPerson = createSelector(selectCurrentPersonState, state => state);

export const selectCurrentPersonIsImplementer = createSelector(selectCurrentPerson, person => !!person?.isImplementer);

export const selectIsHelpfulActual = createSelector(
  selectCurrentPerson,
  person => person?._id === HELPFUL_ACTUAL_PERSON_ID
);

export const selectHasPartnerHubAccess = createSelector(
  selectCurrentPerson,
  person => !!person?.partner_key && !!person?.partnerType
);
