import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { cloneDeep as _cloneDeep } from 'lodash';
import { map } from 'rxjs/operators';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { TeamService } from '@ninety/ui/legacy/core/services/team.service';
import { CreateTeam } from '@ninety/ui/legacy/shared/models/_shared/create-team';

@Component({
  selector: 'ninety-new-team-dialog',
  templateUrl: './new-team-dialog.component.html',
  styleUrls: ['./new-team-dialog.component.scss'],
})
export class NewTeamDialogComponent implements OnDestroy {
  spinner = false;
  name = '';
  includeUser = !this.stateService.currentUser.isImplementer;

  constructor(
    public dialogRef: MatLegacyDialogRef<NewTeamDialogComponent>,
    private errorService: ErrorService,
    private teamService: TeamService,
    public stateService: StateService
  ) {}

  ngOnDestroy(): void {
    (document.activeElement as HTMLButtonElement).blur();
  }

  createTeam() {
    this.dialogRef.disableClose = true;
    this.spinner = true;

    const newCreateTeam = new CreateTeam(this.name, this.includeUser);
    this.teamService
      .create(newCreateTeam)
      .pipe(
        map((newTeamId: string) => {
          const user = _cloneDeep(this.stateService.currentUser$.value);
          newCreateTeam._id = newTeamId;
          newCreateTeam.users = this.includeUser ? [user] : [];
          return { ...CreateTeam.toTeam(newCreateTeam), users: this.includeUser ? [user] : [] };
        })
      )
      .subscribe({
        next: createdTeam => {
          this.spinner = false;
          this.dialogRef.close(createdTeam);
        },
        error: (error: unknown) => {
          this.spinner = false;
          this.errorService.notify(error, 'Failed to create team, please try again');
          this.dialogRef.disableClose = false;
        },
      });
  }
}
