import { compareAsc, parseISO, startOfDay, startOfToday } from 'date-fns';

/**
 * Compares a date to today, irrespective of time, returning -1 if the date is before today, 0 if the date is today,
 * and 1 if the date is after today.
 *
 * @see [Date Fns - compareAsc](https://date-fns.org/v2.30.0/docs/compareAsc
 */
export function compareToToday(dueDate: Date | string): number {
  const today = startOfToday();
  const itemDueDate = parseTodoDate(dueDate);

  return compareAsc(itemDueDate, today);
}

/**
 * Parse a date or ISO date string, returning a valid `Date` object at the start of the day. Note, its expected that the
 * input string is in UTC, ISO. This means that if you pass a _todo with 0 for the hour, it will be parsed as the day
 * before.
 *
 * @see [Date Fns - startOfDay](https://date-fns.org/v2.30.0/docs/startOfDay)
 * @see [Date Fns - parseISO](https://date-fns.org/v2.30.0/docs/parseISO)
 */
export function parseTodoDate(dueDate: Date | string): Date {
  const parsedDate = typeof dueDate === 'string' ? parseISO(dueDate) : dueDate;
  return startOfDay(parsedDate);
}
