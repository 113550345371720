import { Pipe, PipeTransform } from '@angular/core';

import { CompanyUser } from '../models/company/company-user';

@Pipe({
  name: 'sortByCompanyName',
})
export class SortByCompanyNamePipe implements PipeTransform {
  transform(companyUsers: CompanyUser[]): CompanyUser[] {
    if (!companyUsers) return companyUsers;
    return companyUsers.sort((a, b) => {
      const stringA = a.company.name.toLocaleLowerCase();
      const stringB = b.company.name.toLocaleLowerCase();
      return stringA < stringB ? -1 : stringB < stringA ? 1 : 0;
    });
  }
}
