<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="process" />
      <span data-cy="process-language_card-title">{{ process.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="process">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="process.route + ' Page Name'"
          data-cy="process-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="process-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="process.item"
          data-cy="process-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="process-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="process.items"
          data-cy="process-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="process-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.coreProcess }}:</mat-label>
        <input
          #coreProcess
          matInput
          formControlName="coreProcess"
          required
          [attr.aria-label]="process.coreProcess"
          data-cy="process-language_core-process-input-field" />
        <ninety-reset-btn
          *ngIf="coreProcess.value !== resetLanguage?.coreProcess"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('coreProcess')"
          [disabled]="readonly"
          data-cy="process-language_core-process-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.step }} (singular):</mat-label>
        <input
          #step
          matInput
          formControlName="step"
          required
          [attr.aria-label]="process.step"
          data-cy="process-language_step-singular-input-field" />
        <ninety-reset-btn
          *ngIf="step.value !== resetLanguage?.step"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('step')"
          [disabled]="readonly"
          data-cy="process-language_step-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.steps }} (plural):</mat-label>
        <input
          #steps
          matInput
          formControlName="steps"
          required
          [attr.aria-label]="process.steps"
          data-cy="process-language_step-plural-input-field" />
        <ninety-reset-btn
          *ngIf="steps.value !== resetLanguage?.steps"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('steps')"
          [disabled]="readonly"
          data-cy="process-language_step-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.subStep }} (singular):</mat-label>
        <input
          #subStep
          matInput
          formControlName="subStep"
          required
          [attr.aria-label]="process.subStep"
          data-cy="process-language_substep-singular-input-field" />
        <ninety-reset-btn
          *ngIf="subStep.value !== resetLanguage?.subStep"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('subStep')"
          [disabled]="readonly"
          data-cy="process-language_substep-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.subSteps }} (plural):</mat-label>
        <input
          #subSteps
          matInput
          formControlName="subSteps"
          required
          [attr.aria-label]="process.subSteps"
          data-cy="process-language_substep-plural-input-field" />
        <ninety-reset-btn
          *ngIf="subSteps.value !== resetLanguage?.subSteps"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('subSteps')"
          [disabled]="readonly"
          data-cy="process-language_substep-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.documented }}:</mat-label>
        <input
          #documented
          matInput
          formControlName="documented"
          required
          [attr.aria-label]="process.documented"
          data-cy="process-language_documented-input-field" />
        <ninety-reset-btn
          *ngIf="documented.value !== resetLanguage?.documented"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('documented')"
          [disabled]="readonly"
          data-cy="process-language_documented-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.followedByAll }}:</mat-label>
        <input
          #followedByAll
          matInput
          formControlName="followedByAll"
          required
          [attr.aria-label]="process.followedByAll"
          data-cy="process-language_followed-by-all-input-field" />
        <ninety-reset-btn
          *ngIf="followedByAll.value !== resetLanguage?.followedByAll"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('followedByAll')"
          [disabled]="readonly"
          data-cy="process-language_followed-by-all-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ process.effective }}:</mat-label>
        <input
          #effective
          matInput
          formControlName="effective"
          required
          [attr.aria-label]="process.effective"
          data-cy="process-language_effective-input-field" />
        <ninety-reset-btn
          *ngIf="effective.value !== resetLanguage?.effective"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('effective')"
          [disabled]="readonly"
          data-cy="process-language_effective-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
