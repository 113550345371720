<mat-form-field>
  <mat-select [value]="selectedTime" (selectionChange)="selectTime($event.value)">
    <mat-option *ngFor="let time of timeService.timeList" [value]="time">
      {{
        stateService.currentUser.settings.notificationEmails.useTwentyFourHourTime
          ? time.twentyFourHourFormat
          : time.twelveHourFormat
      }}
    </mat-option>
  </mat-select>
</mat-form-field>
