import { createActionGroup, props } from '@ngrx/store';

import { SeatHolder, SeatHolderCreatePick } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-holder';

export type GlobalSeatHolderIdentifier = Pick<SeatHolder, 'chartId' | 'seatId' | '_id'>;

export type ChangeSeatHolderUserProps = GlobalSeatHolderIdentifier & { newUserId: string };

export const CurrentChartSeatHolderActions = createActionGroup({
  source: 'Responsibilities - Seat Holder',
  events: {
    'Create One': props<{ seatHolder: SeatHolderCreatePick }>(),
    'Create One Success': props<{ seatHolder: SeatHolder }>(),
    'Create One Failed': props<{ error: unknown; message: string }>(),

    'Remove One': props<{ params: GlobalSeatHolderIdentifier }>(),
    'Remove One Success': props<{ params: GlobalSeatHolderIdentifier }>(),
    'Remove One Failed': props<{ error: unknown; message: string }>(),

    'Change SeatHolder User': props<{ patch: ChangeSeatHolderUserProps }>(),
    'Change SeatHolder User Success': props<{ patch: ChangeSeatHolderUserProps }>(),
    'Change SeatHolder User Failed': props<{ error: unknown; message: string }>(),
  },
});
