import { createReducer, on } from '@ngrx/store';

import { AnonymousLoginStateActions } from './anonymous-state.actions';
import { AnonymousStateModel, initialAnonymousState } from './anonymous-state.model';

export const anonymousStateReducer = createReducer<AnonymousStateModel>(
  initialAnonymousState,
  on(AnonymousLoginStateActions.setAffiliateCompany, (state, { affiliateCompany }) => ({
    ...state,
    affiliatePartnerCompany: affiliateCompany,
  })),
  on(AnonymousLoginStateActions.setPartnerConfiguration, (state, { configuration }) => ({
    ...state,
    partnerConfiguration: configuration,
  }))
);
