import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { SelectOption } from '../../selects/base/select-option.interface';
import { SelectModule } from '../../selects/select.component';
import { SelectBase } from '../base/select-base';

export enum CompletedSelectValue {
  incomplete = 'incomplete',
  complete = 'complete',
}

const INCOMPLETE_OPTION: SelectOption = {
  text: 'Incomplete',
  value: CompletedSelectValue.incomplete,
  isItemDisabled: false,
};
const COMPLETED_OPTION: SelectOption = {
  text: 'Completed',
  value: CompletedSelectValue.complete,
  isItemDisabled: false,
};

@Component({
  selector: 'ninety-completed-select',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SelectModule],
  template: `
    <ninety-select
      [options]="validOptions"
      class="short-select"
      [initialOptionValue]="initialOptionValue"
      (valueChanged)="handleSelect($event)"></ninety-select>
  `,
  styles: [
    `
      ninety-select {
        position: relative;
        top: -8px;
      }
    `,
  ],
})
export class CompletedSelectComponent extends SelectBase<SelectOption> implements OnChanges {
  initialOptionValue: SelectOption;

  @Input() initialValue: CompletedSelectValue;

  /**
   * Emits `true` when the selected option is 'completed'
   * Emits `false` when the selection option is 'incomplete'
   */
  @Output() isComplete = new EventEmitter<boolean>();

  constructor() {
    super();
    this.validOptions = [INCOMPLETE_OPTION, COMPLETED_OPTION];
  }

  handleSelect(option: SelectOption) {
    this.isComplete.emit(option.value === COMPLETED_OPTION.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialValue']) {
      const currentValue = changes['initialValue'].currentValue;

      // Don't to pass initialValue if you don't want to.
      if (!currentValue) this.initialOptionValue = this.validOptions[0];
      else
        this.initialOptionValue =
          currentValue === CompletedSelectValue.incomplete ? this.validOptions[0] : this.validOptions[1];
    }
  }
}
