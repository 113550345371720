import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

import { User } from '../models/_shared/user';
import { Seat } from '../models/accountability-chart/seat';

@Pipe({
  name: 'searchUserFilter',
})
export class SearchUserFilterPipe implements PipeTransform {
  transform(users: (User & { seats?: Seat[] })[], predicate: string): any {
    if (!users || !predicate) return users;
    predicate = predicate.trim().toLocaleLowerCase();
    return filter(
      users,
      user =>
        user?.metadata &&
        `${user.metadata.name.first} ${user.metadata.name.last}`.toLocaleLowerCase().includes(predicate)
    );
  }
}
