import { createSelector } from '@ngrx/store';

import { selectTopToolbarState } from '@ninety/layouts/_state';

export const selectMainToolbarHidden = createSelector(selectTopToolbarState, state => state.toolbarHidden);

export const selectMainToolbarHasShadow = createSelector(selectTopToolbarState, state => state.toolbarHasShadow);

export const selectChipMessage = createSelector(selectTopToolbarState, state => state.chipMessage);

export const selectPageTitle = createSelector(selectTopToolbarState, state => state.pageTitle);
