import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectUserAvatarInfo } from '../../../_state/app-entities/users/users-state.selectors';

@Pipe({
  name: 'userIdToAvatar',
  standalone: true,
})
export class UserIdToAvatarPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(userId: string) {
    return this.store.select(selectUserAvatarInfo(userId));
  }
}
