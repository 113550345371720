import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import {
  BillingSubscriptionViewModel,
  ISeatFacade,
  UserApiReturnValue,
  UserPayloadOptions,
} from '@ninety/ui/legacy/shared/models/_shared/billing-subscription.model';
import { LicenseUserModel } from '@ninety/ui/legacy/shared/models/_shared/license-user-model';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { Subscription } from '@ninety/ui/legacy/shared/models/billingv2/subscription.model';
import { DirectoryUserStatus } from '@ninety/ui/legacy/shared/models/directory/directory-user-status';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { selectRoleSelectData } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';
import { selectAllUsers, selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { extractValueFromStore, selectCompany } from '@ninety/ui/legacy/state/index';

import { BillingUserMapperService } from './billing-user-mapper.service';

// TODO: This is a duplicate of PaddleSeatFacadeService. This needs to be cleaned up after BTS as a Paddle clean up task.
@Injectable()
export class BillingV2SeatFacadeService implements ISeatFacade {
  private companyUserRoleCode: RoleCode;
  private appRoles: RoleSelectOption[];
  private readonly filterUsers$ = new Subject<string>();
  private readonly roleUpdate$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();
  private dataSource$: BehaviorSubject<LicenseUserModel[]> = new BehaviorSubject<LicenseUserModel[]>([]);
  private pageOptionsUsers: UserPayloadOptions = {
    page: 0,
    pageSize: 10,
    sortDirection: SortDirection.ASC,
    userStatus: DirectoryUserStatus.free,
  };

  public vm: BillingSubscriptionViewModel = {
    dataSource$: this.dataSource$,
    paginator: {
      pageSize: 10,
      pageSizeOptions: [5, 10, 25, 100],
      length: 0,
      pageEvent$: new Subject<PageEvent>(),
    },
    onSearchTermChanged: (term: any) => this.onSearchTermChanged(term),
    frmControl: new FormControl(''),
    menuRoles: this.filterUserRoleMenuItems(),
    licenseUsed: new BehaviorSubject<number>(0),
    licenseQty: new BehaviorSubject<number>(0),
  };
  constructor(
    private mapper: BillingUserMapperService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private store: Store
  ) {}

  initDataSource(options: UserPayloadOptions): void {
    this.pageOptionsUsers = { ...options };
    combineLatest([
      this.store.select(selectAllUsers),
      merge(
        this.store.select(selectRoleSelectData).pipe(
          tap(roles => {
            this.appRoles = roles;
          })
        ),
        this.filterUsers$.pipe(
          distinctUntilChanged(),
          tap(searchTerm => (this.pageOptionsUsers.searchText = searchTerm))
        ),
        this.vm.paginator.pageEvent$.pipe(
          tap((data: PageEvent) => {
            this.pageOptionsUsers.page = data.pageIndex;
            this.pageOptionsUsers.pageSize = data.pageSize;
          })
        ),

        // When the role is updated, we force an API call to keep code simple, since the 2 grids are linked.
        // Performing a local change, will make the code more complicated, considering the relationship between the grids.
        this.roleUpdate$.pipe(takeUntil(this.destroy$))
      ),
    ])
      .pipe(
        startWith(this.filterUsers$.next('')), // Triggers the first data load
        switchMap(_ =>
          this.spinnerService.spinWhile<UserApiReturnValue>(this.performApiCallWithOptions(this.pageOptionsUsers))
        ),
        tap(data => {
          this.updateViewModelFromDataStream(data);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Get updates for company total license count.
    this.companyUserRoleCode = extractValueFromStore(this.store, selectCurrentUser).roleCode;
    this.store
      .select(selectCompany)
      .pipe(
        filter(company => !!company && !!company?.subscription),
        map(company => company.subscription as Subscription),
        distinctUntilChanged((prev, curr) => prev !== curr),
        tap(subscription => {
          this.updateTotalLicenseCount(subscription);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  updateTotalLicenseCount(subscription: Subscription) {
    this.vm.licenseQty.next(subscription.seatsPaidFor);
  }

  updateViewModelFromDataStream(data: UserApiReturnValue): void {
    this.vm.paginator.length = data.count;
    this.vm.licenseUsed.next(data.count);

    const numberOfOwners = data.users.filter((user: User) => user.roleCode === RoleCode.owner).length;
    const users = data.users.map((user: User) => this.mapper.fromApi(user, this.appRoles, numberOfOwners));
    this.dataSource$.next(users);
  }
  performApiCallWithOptions(options: UserPayloadOptions): Observable<UserApiReturnValue> {
    this.spinnerService.start();
    return this.userService.getDirectoryUsersPaginated(options);
  }

  onSearchTermChanged(term: string): void {
    this.filterUsers$.next(term);
  }

  onUpdateRole(): void {
    this.roleUpdate$.next();
  }

  private filterUserRoleMenuItems(): RoleSelectOption[] {
    return this.appRoles?.filter((role: RoleSelectOption) => this.companyUserRoleCode <= role.roleCode);
  }
}
