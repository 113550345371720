import { createReducer, on } from '@ngrx/store';

import { TopToolbarActions } from './top-toolbar-state.actions';
import { TopToolbarStateModel, initialTopToolbarState } from './top-toolbar-state.model';

export const topToolbarStateReducer = createReducer(
  initialTopToolbarState,
  on(
    TopToolbarActions.toolbarHidden,
    (state, { toolbarHidden }): TopToolbarStateModel => ({
      ...state,
      toolbarHidden: toolbarHidden,
    })
  ),
  on(TopToolbarActions.resetToDefault, (): TopToolbarStateModel => initialTopToolbarState),
  on(TopToolbarActions.showToolbarShadow, (state): TopToolbarStateModel => ({ ...state, toolbarHasShadow: true })),
  on(TopToolbarActions.hideToolbarShadow, (state): TopToolbarStateModel => ({ ...state, toolbarHasShadow: false })),
  on(
    TopToolbarActions.setChipMessage,
    (state, { message }): TopToolbarStateModel => ({
      ...state,
      chipMessage: message,
    })
  ),
  on(
    TopToolbarActions.hideChipMessage,
    (state): TopToolbarStateModel => ({
      ...state,
      chipMessage: null,
    })
  ),
  on(
    TopToolbarActions.setPageTitle,
    (state, { pageTitle }): TopToolbarStateModel => ({
      ...state,
      pageTitle,
    })
  )
);
