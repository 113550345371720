import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform<T extends { title?: string; description?: string }>(
    values: Array<T>,
    input: string,
    serverSide = false,
    ...otherProperties: string[]
  ): Array<T> {
    if (!values || !input || serverSide) return values;
    input = input.toLocaleLowerCase();
    return filter(values, value => {
      if (value.title) {
        if (value.title.toLocaleLowerCase().includes(input)) return true;
      }
      if (value.description) {
        if (value.description.toLocaleLowerCase().includes(input)) return true;
      }
      otherProperties.forEach(prop => {
        if (value[prop].toLocaleLowerCase().includes(input)) return true;
      });
      return false;
    });
  }
}
