import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root',
})
export class MasteryGuard {
  constructor(public stateService: StateService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    return this.stateService.company.settings?.mastery.enabled ? true : this.router.parseUrl('/home');
  }
}
