<h4 class="section-header {{ headerClass }}">{{ title }}</h4>
<div class="mastery-config__container">
  <terra-switch
    data-cy="mastery-config_toggle-button"
    [ngModel]="masterySettings.enabled"
    (change)="update('enabled', $event)"
    [disabled]="readonly">
    Enable {{ title }}
    <terra-description>
      A customizable tool that allows you to learn and track mastery across your teams.
    </terra-description>
  </terra-switch>
  <ng-container *ngIf="masterySettings.enabled">
    <terra-switch
      data-cy="mastery-config_toggle-button_show-icons"
      [ngModel]="masterySettings.showIcons"
      (change)="update('showIcons', $event)"
      [disabled]="readonly">
      Show Icons
      <terra-description>
        Turning this on will display icons on {{ masteryItemLanguage }} cards. You can change this icon anytime you
        create or edit a {{ masteryItemLanguage }} card.
      </terra-description>
    </terra-switch>
    <terra-switch
      data-cy="mastery-config_toggle-button_show-links"
      [ngModel]="masterySettings.showLinks"
      (change)="update('showLinks', $event)"
      [disabled]="readonly">
      Show Links
      <terra-description>
        Turning this on will display a "Learn More" button on {{ masteryItemLanguage }} cards, allowing you to enter a
        custom URL to link your teammates to learning materials.
      </terra-description>
    </terra-switch>
  </ng-container>
</div>
