export interface SpinnerStateModel {
  primary: boolean;
  primaryCount: number;
  primaryStartTime: number;

  auxiliary: boolean;
  auxiliaryCount: number;
  auxiliaryStartTime: number;
  auxStart: number;
}

export const initialSpinnerState: SpinnerStateModel = {
  primary: false,
  primaryCount: 0,
  primaryStartTime: 0,
  auxiliary: false,
  auxiliaryCount: 0,
  auxiliaryStartTime: 0,
  auxStart: 0,
};
