import { TerraIconName } from '@ninety/terra';

import { TimezoneEntry } from '../../../../models/_shared/timezone-select';

export class TimezoneDialogData {
  title = 'Are you sure?';
  message?: string;
  additionalMessage?: string;
  browserTimezoneEntry: TimezoneEntry;

  timeZoneForUserWeDisplay: string;

  // Buttons
  confirmButtonText? = 'OK';
  cancelButtonText? = 'Cancel';
  confirmButtonIcon?: TerraIconName;
  cancelButtonIcon?: TerraIconName;

  // Options
  isWarningDialog?: boolean; // If true, the dialog will have a warning icon and a red confirm button

  constructor(data: TimezoneDialogData) {
    Object.assign(this, data);
  }

  static WarningDialog(data: TimezoneDialogData): TimezoneDialogData {
    return new TimezoneDialogData({
      isWarningDialog: true,
      confirmButtonText: 'Delete',
      ...data,
    });
  }
}

export interface TimezoneDialogResult {
  dontAskAgain: boolean;
  browserTimezoneEntry: TimezoneEntry;
  confirm: boolean;
}
