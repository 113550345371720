import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to convert a boolean to true or undefined for cases when attribute="false" is truthy for native HTML attributes.
 * @example <terra-component [attr.checked]="checked | boolAttr"></terra-component>
 */
@Pipe({
  name: 'boolAttr',
})
export class BoolAttrPipe implements PipeTransform {
  transform(value: unknown): unknown {
    return value ? true : undefined;
  }
}
