import { Injectable } from '@angular/core';
import { sortBy as _sortBy } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

import { TimeEntry } from '../../_shared/models/_shared/time-select';
import { TimezoneEntry } from '../../_shared/models/_shared/timezone-select';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  readonly timezoneList: Readonly<TimezoneEntry>[] = [];

  readonly defaultTime: Readonly<TimeEntry> = {
    twentyFourHourFormat: 'End of Day',
    twelveHourFormat: 'End of Day',
    offsetFromMidnightInMinutes: 60 * 24 - 1,
  };

  readonly timeList: Readonly<TimeEntry>[] = [this.defaultTime];

  constructor() {
    this.initTimeZones();

    //Sort timezones by offsetInMinutes
    this.timezoneList = _sortBy(this.timezoneList, [(x: TimezoneEntry) => x.offsetInMinutes]);

    this.initTimeList();
  }

  private initTimeList() {
    const timestep = 30;

    for (let offset = 0; offset < 60 * 24; offset += timestep) {
      const offsetTime = moment().startOf('day').add(offset, 'minutes');

      this.timeList.push({
        twentyFourHourFormat: offsetTime.format('HH:mm'),
        twelveHourFormat: offsetTime.format('h:mm a'),
        offsetFromMidnightInMinutes: offset,
      });
    }
  }

  private initTimeZones() {
    for (const name of moment.tz.names()) {
      if (name.toLowerCase().includes('etc')) {
        continue;
      }

      const offsetInMinutes = moment.utc().tz(name).utcOffset();
      const offsetSign = offsetInMinutes < 0 ? '-' : offsetInMinutes > 0 ? '+' : '';

      const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
      const minutes = Math.abs(offsetInMinutes) - hours * 60;

      const fullUtcOffset = `${offsetSign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      const nameFormatted = name.replace(/_/g, ' ');

      this.timezoneList.push({ name, nameFormatted, offsetInMinutes, fullUtcOffset });
    }
  }

  getTimezoneByName(timezoneName: string): TimezoneEntry {
    if (!timezoneName) {
      return null;
    }

    return this.timezoneList.find((entry: TimezoneEntry) => entry.name === timezoneName);
  }

  getBrowserTimezone(): TimezoneEntry {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const browserTimezoneEntry: TimezoneEntry = this.timezoneList.find(tzl => tzl.name == browserTimezone);
    return browserTimezoneEntry;
  }
}
