import ObjectID from 'bson-objectid';

import { IntervalCode } from '@ninety/ui/legacy/shared/models/issues/interval-code';
import { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';

export class IssueRecord {
  title: string;
  description: string;
  email: string;
  archivedDate: Date;
  completed_date: Date;
  statusCode: string;
  userId?: string;
  rating: string;

  static toIssues(records: IssueRecord[], teamId: string): Issue[] {
    return records.map((record, i: number) => ({
      ...record,
      userId: ObjectID.isValid(record.userId) ? record.userId : null,
      teamId,
      //This will always be an integer from 0-5 coming from oneSchema
      rating: +record.rating,
      archived: !!record.archivedDate,
      archivedDate: record.archivedDate || null,
      completed: !!record.completed_date,
      completedDate: record.completed_date || null,
      createdBy: 'Data Import',
      intervalCode: IssueRecordStatusCode[record.statusCode] || IntervalCode.shortTerm,
      // just throw them at the bottom without slowing down the process by trying to count mongo documents
      ordinal: 100000 + i,
      userOrdinal: 100000 + i,
      deleted: false,
    }));
  }
}

enum IssueRecordStatusCode {
  'Short Term' = IntervalCode.shortTerm,
  'Long Term' = IntervalCode.longTerm,
}
