import { Pipe, PipeTransform } from '@angular/core';

import { MeetingSection } from '../models/meetings/meeting-section';

@Pipe({
  name: 'filterOutUsedDefaultSections',
})
export class FilterOutUsedDefaultSectionsPipe implements PipeTransform {
  transform(sections: MeetingSection[], usedSections: MeetingSection[], isMasteryDisabled: boolean): MeetingSection[] {
    if (!sections) return sections;
    const usedPaths = usedSections.map(s => s.path);
    return sections.filter(
      (s: MeetingSection) => !usedPaths.includes(s.path) && !(isMasteryDisabled && s.path === 'mastery')
    );
  }
}
