<mat-select
  matInput
  data-cy="role-select"
  [value]="selectedRole"
  (valueChange)="onValueChange($event)"
  [disabled]="isDisabled || selectedRole?.cannotAssign"
  [class.error]="required && !selectedRole?.title"
  [placeholder]="required ? 'Role *' : 'Role'"
  class="main-selector"
  panelClass="role-select-panel">
  <mat-select-trigger>
    {{ selectedRole?.title }}
  </mat-select-trigger>
  <div class="menu-container">
    <mat-option
      *ngFor="let role of roles"
      attr.data-cy="select_option-{{ role.title }}"
      mat-select-panel-wrap
      [value]="role"
      [disabled]="role?.isItemDisabled"
      [matTooltip]="role?.tooltip"
      [matTooltipDisabled]="selectedRole?.cannotAssign"
      [matTooltipPosition]="role?.tooltipPosition || 'left'"
      matTooltipClass="ninety-tooltip">
      <div class="menu-row">
        <terra-divider *ngIf="!!role.subtitle"></terra-divider>
        <div
          attr.data-cy="select_option-code_{{ role.roleCode }}-impl_{{ role.isImplementer }}-title"
          class="terra-body">
          {{ role.title }} <span class="text-light">{{ role.subtitle }}</span>
        </div>
        <div
          attr.data-cy="select_option-code_{{ role.roleCode }}-impl_{{ role.isImplementer }}-description"
          class="terra-body-small text-light">
          {{ role.description }}
        </div>
      </div>
    </mat-option>
  </div>
</mat-select>
