import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs';

import { DataLayerKeys } from '../model/data-layer';
import { DataLayerEvent } from '../model/data-layer-event';
import { UrlSanitizer } from '../model/url-sanitizer';

import { DataLayerService } from './data-layer.service';

// Global function provided by Google Analytics
// Reference: https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference
// TODO: Encapsulate in a service
declare let ga: Function;

interface RouteData extends Data {
  title: string;
  referralPage?: URL;
}

/**
 * TODO: Move to Ngrx selectors
 */
@Injectable()
export class NavigationTagService {
  private isInitialized = false;

  // Update after user navigates with last page url
  private referralPage: URL;
  private urlSanitizer: UrlSanitizer = new UrlSanitizer(['firstname', 'lastname', 'email']);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataLayerService: DataLayerService
  ) {}

  init() {
    if (this.isInitialized) return;

    this.setReferralPage(document.referrer);
    this.listenForRouteChange();

    this.isInitialized = true;
  }

  private setReferralPage(uri: string): void {
    this.referralPage = this.urlSanitizer.createFrom(uri);
  }

  private listenForRouteChange(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          if ('ga' in window) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
          }
        }),
        map(_ => this.activatedRoute)
      )
      .subscribe({
        next: (route: ActivatedRoute) => {
          while (route.firstChild) route = route.firstChild;

          const { title } = route.snapshot.data;

          this.newNavigation({
            title,
            referralPage: this.referralPage,
          });

          this.setReferralPage(location.href);
        },
      });
  }

  newNavigation(data: RouteData, event: DataLayerEvent = DataLayerEvent.pageView): void {
    const url = this.urlSanitizer.createFrom(location.href);

    this.dataLayerService.push({
      event,
      [DataLayerKeys.referralPage]: data.referralPage?.href || undefined,
      [DataLayerKeys.pageTitle]: data.title || undefined,
      ...this.dataLayerService.getUrlMeta(url),
    });
  }
}
