import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import ObjectId from 'bson-objectid';

import { DetailType } from '../_shared/models/_shared/detail-type.enum';
import { routeNames } from '../_shared/models/constants/route-names';

// `router` is used as the default feature name. You can use the feature name
// of your choice by creating a feature selector and pass it to the `getSelectors` function
// export const selectRouter = createFeatureSelector<RouterReducerState>('yourFeatureName');
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectTitle, // Select the title if available
} = getRouterSelectors();

const v2DetailTypes: ReadonlyArray<DetailType> = [
  DetailType.cascadingMessage,
  DetailType.chart,
  DetailType.headline,
  DetailType.kpiGroup,
  DetailType.kpi,
  DetailType.seat,
  DetailType.todo,
];

/** Utility function which checks if the current route is of a specific detail type */
export function routeIsOfDetailType(parsedUrl: UrlTree, type: DetailType) {
  return parsedUrl.root.children.detail?.segments[1]?.path === type;
}

export const selectUrlTree = createSelector(selectUrl, url => new DefaultUrlSerializer().parse(url));

export const selectIsV2DetailRoute = createSelector(selectUrlTree, parsedUrl =>
  v2DetailTypes.some(type => routeIsOfDetailType(parsedUrl, type))
);

export const selectRouteIsOfDetailType = (type: DetailType) =>
  createSelector(selectUrlTree, parsedUrl => routeIsOfDetailType(parsedUrl, type));

export const selectIsMyNinetyUrl = createSelector(
  selectUrlTree,
  parsedUrl => parsedUrl.root.children.primary?.segments[0]?.path === routeNames.myfocus
);

export const selectIsInMeeting = createSelector(selectUrlTree, parsedUrl => {
  const [meetingSegment, meetingIdSegment] = parsedUrl.root.children.primary?.segments;
  return meetingSegment.path === routeNames.meeting && ObjectId.isValid(meetingIdSegment.path);
});
