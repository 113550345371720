import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { selectIsBillingV2Company } from '../../_state/app-global/company/subscription/subscription-state.selectors';
import { StateService } from '../services/state.service';

import { STRIPE_BILLING_PATH } from './active-subscription.guard';

@Injectable({
  providedIn: 'root',
})
export class BillingGuard {
  isBillingV2Company = false;

  constructor(private store: Store, private stateService: StateService, private router: Router) {
    this.store
      .select(selectIsBillingV2Company)
      .pipe(
        tap(isBillingV2Company => {
          this.isBillingV2Company = isBillingV2Company;
        })
      )
      .subscribe();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this.isBillingV2Company) {
      return true;
    }

    return this.getBillingUrlForCompany();
  }

  private getBillingUrlForCompany(): UrlTree {
    if (!this.stateService?.isManagerOrAbove) {
      return this.router.parseUrl('/settings/user/profile');
    }

    return this.router.parseUrl(`settings/${STRIPE_BILLING_PATH}`);
  }
}
