import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash/filter';

@Pipe({
  name: 'titleFilter',
})
export class TitleFilterPipe implements PipeTransform {
  transform<T extends { title?: string }>(values: Array<T>, input: string): Array<T> {
    if (!values || !input) return values;
    input = input.toLocaleLowerCase();

    return filter(values, value => {
      if (value.title) {
        if (value.title.toLocaleLowerCase().includes(input)) return true;
      }
      return false;
    });
  }
}
