import { createActionGroup, props } from '@ngrx/store';

import { Measurable } from '@ninety/ui/legacy/shared/models/scorecard/measurable';

export const MeasurableActions = createActionGroup({
  source: 'Measurables',
  events: {
    'Create Measurable': props<{ measurable: Measurable }>(),
    'Create And Add Measurable': props<{ measurable: Measurable; teamId: string }>(),

    'Open Delete Dialog': props<{ id: string }>(),
    Delete: props<{ id: string }>(),
    'Cancel Delete': props<{ id: string }>(),
  },
});
