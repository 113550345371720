export enum SnackbarTemplateType {
  adminEmail = 'adminEmail',
  newMeeting = 'newMeeting',
  offline = 'offline',
  online = 'online',
  success = 'success',
  successWithAction = 'successWithAction',
  warning = 'warning',
  newConversationMeeting = 'newConversationMeeting',
}
