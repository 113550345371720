import { Injectable } from '@angular/core';

import { DataLayerKeys } from '../model/data-layer';
import { DataLayerEvent } from '../model/data-layer-event';

import { DataLayerService } from './data-layer.service';

/**
 * TODO: Move to Ngrx
 */
@Injectable()
export class ErrorTagService {
  private defaultEvent: DataLayerEvent = DataLayerEvent.error;
  private isInitialized = false;

  constructor(private dataLayerService: DataLayerService) {}

  init() {
    if (this.isInitialized) return;

    this.listenForErrors();

    this.isInitialized = true;
  }

  trackError(error: { message: string }, event: DataLayerEvent = this.defaultEvent): void {
    this.dataLayerService.push({
      event,
      [DataLayerKeys.errorMessage]: error.message,
    });
  }

  // Listen for global window errors
  private listenForErrors(): void {
    window.addEventListener('error', error => this.trackError(error));
  }
}
