import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { merge } from 'lodash';

import { ResponsibilityChartModel } from '@ninety/accountability-chart/models/responsibility-chart.model';
import { mongoIdAdapter } from '@ninety/ui/legacy/shared/ngrx/mongo-id-adapter';

export type SharedWithMeStateModel = EntityState<ResponsibilityChartModel>;

export type CreatedByMeStateModel = EntityState<ResponsibilityChartModel>;

export interface SharedResponsibilityChartsStateModel {
  sharedWithMe: SharedWithMeStateModel;
  createdByMe: CreatedByMeStateModel;
  selectedChartId?: string;
  doneLoading?: boolean;
  isSaving: boolean;
}

export const sharedWithMeAdapter = createEntityAdapter<ResponsibilityChartModel>({
  selectId: mongoIdAdapter<ResponsibilityChartModel>(),
});
export const createdByMeAdapter = createEntityAdapter<ResponsibilityChartModel>({
  selectId: mongoIdAdapter<ResponsibilityChartModel>(),
});

export function createInitialSharedWithMeStateModel(
  partial?: Partial<SharedResponsibilityChartsStateModel>
): SharedResponsibilityChartsStateModel {
  const _default: SharedResponsibilityChartsStateModel = {
    sharedWithMe: sharedWithMeAdapter.getInitialState(),
    createdByMe: createdByMeAdapter.getInitialState(),
    selectedChartId: undefined,
    doneLoading: false,
    isSaving: false,
  };
  return merge({}, _default, partial);
}
