import ObjectID from 'bson-objectid';

import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

export class TodoRecord {
  completed_date: string;
  created_date: string;
  dueDate: string;
  email: string;
  title: string;
  userId?: string;
  description: string;
  archivedDate: string;
  is_personal: string;
  attachment_link: string;

  static toTodos(records: TodoRecord[], teamId: string): Todo[] {
    return records.map((record, i: number) => ({
      ...record,
      userId: ObjectID.isValid(record.userId) ? record.userId : null,
      teamId,
      archived: !!record.archivedDate,
      archivedDate: HelperService.formatDate(record.archivedDate) || null,
      completed: !!record.completed_date,
      completedDate: HelperService.formatDate(record.completed_date) || null,
      createdBy: 'Data Import',
      dueDate: HelperService.fixDateForDb(HelperService.formatDate(record.dueDate)),
      originalDueDate: HelperService.fixDateForDb(HelperService.formatDate(record.dueDate)),
      isPersonal: record.is_personal.toLowerCase() === 'yes', // maybe we can work this in later
      description:
        record.description +
        (record.attachment_link
          ? ` <a href="${record.attachment_link}" target="_blank" rel="noopener noreferrer">${record.attachment_link}</a>`
          : ''),
      // just throw them at the bottom without slowing down the process by trying to count mongo documents
      ordinal: 100000 + i,
      userOrdinal: 100000 + i,
    })) as unknown as Todo[];
  }
}
