import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[ninetyStopPropagation]',
  standalone: true,
})
export class StopPropagationDirective {
  @Input() stopPropagation = true;

  @HostListener('click', ['$event'])
  @HostListener('mousedown', ['$event'])
  onClick(e) {
    if (this.stopPropagation) e.stopPropagation();
  }
}
