import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraIconComponent } from './terra-icon.component';

@NgModule({
  imports: [CommonModule, TerraIconComponent],
  exports: [TerraIconComponent],
})
export class TerraIconModule {}
