import Color from 'color';

export interface DSBrandingThemeProperties {
  '--brand-shader-light': string;
  '--brand-shader': string;
  '--brand-shader-heavy': string;
  '--brand-shader-heaviest': string;
  '--brand-light': string;
  '--brand': string;
  '--brand-heavy': string;
  '--brand-heaviest': string;
}

export class DSColorUtils {
  static generateDynamicBrandColors(inHexCode: string): DSBrandingThemeProperties {
    const baseBrandColor = Color(inHexCode);
    const brandColorHSL = baseBrandColor.hsl();
    const brandColorVariations: DSBrandingThemeProperties = {
      '--brand-shader-light': brandColorHSL.alpha(0.04).string(),
      '--brand-shader': brandColorHSL.alpha(0.1).string(),
      '--brand-shader-heavy': brandColorHSL.alpha(0.2).string(),
      '--brand-shader-heaviest': brandColorHSL.alpha(0.4).string(),
      '--brand-light': brandColorHSL.lighten(0.08).string(),
      '--brand': brandColorHSL.string(),
      '--brand-heavy': brandColorHSL.darken(0.08).string(),
      '--brand-heaviest': brandColorHSL.darken(0.16).string(),
    };
    return brandColorVariations;
  }
}
