export enum ForgotPasswordStatus {
  /**
   * No person exists for the given email
   */
  NONEXISTENT = 'NONEXISTENT',
  /**
   * No person exists but at least one invited user exists
   */
  INVITED = 'INVITED',
  /**
   * Person exists and Cognito forgot password flow should follow
   */
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  /**
   * Person exists and Cognito identity verification should follow
   */
  VERIFY_IDENTITY = 'VERIFY_IDENTITY',
}
