import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs';

import { InteractionService } from '../../../_core/services/interaction.service';

import { InteractionsStateActions } from './interactions.actions';
import { selectLatestInteraction } from './interactions.selectors';
import { Interaction } from './models/interaction';

@Injectable()
export class InteractionsStateEffects {
  constructor(private actions$: Actions, private store: Store, private interactionService: InteractionService) {}

  createInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InteractionsStateActions.createInteraction),
      switchMap(a =>
        this.interactionService
          .createNewInteraction(a)
          .pipe(map((i: Interaction) => InteractionsStateActions.successCreateInteraction({ interaction: i })))
      )
    )
  );
  updateInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InteractionsStateActions.updateInteraction),
      concatLatestFrom(() => this.store.select(selectLatestInteraction)),
      switchMap(([action, interaction]) =>
        this.interactionService.updateInteraction(interaction._id, { args: action.args }).pipe(
          map(() =>
            InteractionsStateActions.successUpdateInteraction({
              args: action.args,
              submittedQuestion: true,
            })
          )
        )
      )
    )
  );
}
