import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import {
  AcceptInviteStateKey,
  AcceptInviteStateModel,
} from '@ninety/login/_state/accept-invite/accept-invite-state.model';
import { acceptInviteStateReducer } from '@ninety/login/_state/accept-invite/accept-invite-state.reducer';
import { LoginSharedStateModel, LoginSharedStateKey } from '@ninety/login/_state/shared/login-shared-state.model';
import { loginSharedStateReducer } from '@ninety/login/_state/shared/login-shared-state.reducer';
import { SignupStateKey, SignupStateModel } from '@ninety/login/_state/sign-up/signup-state.model';
import { signupStateReducer } from '@ninety/login/_state/sign-up/signup-state.reducer';

export const LoginStateFeatureKey = 'login';

export interface LoginFeatureState {
  [LoginSharedStateKey]: LoginSharedStateModel;
  [SignupStateKey]: SignupStateModel;
  [AcceptInviteStateKey]: AcceptInviteStateModel;
}

export const loginReducers: ActionReducerMap<LoginFeatureState> = {
  [LoginSharedStateKey]: loginSharedStateReducer,
  [SignupStateKey]: signupStateReducer,
  [AcceptInviteStateKey]: acceptInviteStateReducer,
};

export const selectLoginFeature = createFeatureSelector<LoginFeatureState>(LoginStateFeatureKey);
