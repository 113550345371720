//Cognito statuses - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html
export enum CognitoUserStatus {
  /**
   * Cognito user is no longer active
   */
  ARCHIVED = 'ARCHIVED',
  /**
   * Cognito user is disabled due to a potential security threat
   */
  COMPROMISED = 'COMPROMISED',
  /**
   * Cognito user has been confirmed
   */
  CONFIRMED = 'CONFIRMED',
  /** Cognito user is federated with an external provider */
  EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER',
  /**
   *  The Cognito user is confirmed and the user can sign in using a
   *  temporary password, but on first sign-in, the user must
   *  change his or her password to a new value before doing
   *  anything else.
   */
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  /**
   * Cognito user is confirmed, but the user must request a code and
   * reset his or her password before he or she can sign in.
   */
  RESET_REQUIRED = 'RESET_REQUIRED',
  /**
   * Cognito user has been created but not confirmed
   */
  UNCONFIRMED = 'UNCONFIRMED',
  /**
   * Cognito user status is not known
   */
  UNKNOWN = 'UNKNOWN',
}
