import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDividerComponent } from './terra-divider.component';

@NgModule({
  imports: [CommonModule, TerraDividerComponent],
  exports: [TerraDividerComponent],
})
export class TerraDividerModule {}
