export enum MasteryStatus {
  NotMastered,
  Mastered,
}

export enum MasteryFilterStatus {
  NotMastered,
  Mastered,
  All = 'all',
}
