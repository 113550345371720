import { Pipe, PipeTransform } from '@angular/core';

export interface Titled {
  title: string;
}

@Pipe({
  name: 'orderByTitle',
})
export class OrderByTitlePipe implements PipeTransform {
  transform<T extends Titled>(values: Array<T>): Array<T> {
    return OrderByTitlePipe.sort(values);
  }

  static sort<T extends Titled>(values: Array<T>): Array<T> {
    if (!values || values.length === 1) return values;
    return values.sort((a, b) => {
      const stringA = a.title.toLocaleLowerCase();
      const stringB = b.title.toLocaleLowerCase();

      if (stringA < stringB) return -1;
      if (stringA > stringB) return 1;
      return 0;
    });
  }
}
