import { Pipe, PipeTransform } from '@angular/core';

import { YesNoMaybe } from '../models/feedback/yes-no';

@Pipe({ name: 'yesNoMaybeClass' })
export class YesNoMaybeClassPipe implements PipeTransform {
  transform(yesNoMaybe: YesNoMaybe): string {
    if (yesNoMaybe === 'Yes') return 'green';
    if (yesNoMaybe === 'Maybe') return 'black';
    if (yesNoMaybe === 'No') return 'red';
    return '';
  }
}
