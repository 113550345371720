<div class="logo-and-text">
  <div
    class="img-wrapper"
    [class.rectangle]="logoShape === 'rectangle' && isSideNavExpanded"
    [class.square]="logoShape === 'square'"
    [tp]="companyName"
    [tpIsEnabled]="!isSideNavExpanded"
    tpPlacement="right"
    [tpOffset]="[0, 39]">
    <ng-container *ngIf="userLogoSrc; else textLogo">
      <!-- The image is loaded hidden so that a reference can be attained to apply styles -->
      <img
        #imgLogo
        alt="Logo"
        [class.expanded]="isSideNavExpanded"
        [src]="userLogoSrc"
        (load)="imageLoaded()"
        [class.hidden]="!imgLoaded || (logoShape === 'rectangle' && !isSideNavExpanded)" />

      <!-- When the image is a rectangle logo, it should be hidden when the sidebar is collapsed -->
      <ng-container *ngIf="imgLoaded && logoShape === 'rectangle' && !isSideNavExpanded">
        <ng-template *ngTemplateOutlet="textLogo"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div
    *ngIf="!inSidenav || (isSideNavExpanded && logoShape !== 'rectangle')"
    ninetyEllipsify
    [content]="companyName"
    class="company-name"
    data-cy="company-logo_company-name"
    [class.menu]="!inSidenav"
    [class.sidenav]="inSidenav"
    [ngClass]="{ 'dark-theme': theme === Theme.DUAL }">
    {{ companyName }}
  </div>
  <terra-icon
    class="company-select-more-icon"
    [ngClass]="{ 'dark-theme': theme === Theme.DUAL }"
    *ngIf="showDropDownIcon"
    icon="more"
    size="20" />
</div>

<ng-template #textLogo>
  <span class="text-logo" [matTooltip]="companyName" matTooltipClass="ninety-tooltip" matTooltipPosition="right">{{
    companyName | initials
  }}</span>
</ng-template>
