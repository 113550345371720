import { createSelector } from '@ngrx/store';

import { selectFilterBarState } from '@ninety/layouts/_state';
import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { selectUserTeams } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';

export const selectSelectedTeam = createSelector(selectFilterBarState, state => state?.selectedTeam);

export const selectSelectedTeamId = createSelector(selectSelectedTeam, team => team?._id);

/** For ninety-quick-filter */
export const selectTeamFilterOptions = createSelector(
  selectUserTeams,
  selectSelectedTeamId,
  (teams, selectedTeamId): QuickFilterOption<Team>[] => {
    if (selectSelectedTeamId)
      return teams.map(team => ({
        displayText: team.name,
        id: team._id,
        item: team,
        selected: team._id === selectedTeamId,
      }));
  }
);
