import ObjectID from 'bson-objectid';

import { BusinessOperatingSystem } from '../company/business-operating-system.enum';

import { CoreFocus } from './core-focus';
import { ReadonlyVtoSectionSettings, Vto } from './vto';

export enum VtoTabsName {
  vision = 'Vision',
  traction = 'Traction',
}

export type VtoEditableSections = VtoEditableSectionsInVto | CoreFocusNestedSections;

export type CoreFocusNestedSections = keyof CoreFocus;

export type VtoSectionKeys = keyof typeof VtoSectionLabels | keyof typeof PinnacleSectionLabels;
export type VtoSectionKeyArray = VtoSectionKeys[];

export type VtoEditableSectionsInVto = keyof Pick<
  Vto,
  | 'coreValues'
  | 'coreFocus'
  | 'tenYear'
  | 'marketingStrategies'
  | 'labels'
  | 'threeYear'
  | 'ninetyDays'
  | 'oneYear'
  | 'customSectionSettings'
  | 'cascadedSections'
  // pinnacle svep sections
  | 'purposeWhyWeExist'
  | 'coreBusiness'
  | 'pinnacleTopOfMountain'
  | 'quarterlyTheme'
  | 'flywheelDesign'
  | 'oneLineMarketingPhrase'
  | 'brandPromises'
>;

export enum VtoSectionLabels {
  coreValues = 'Core Values',
  coreFocus = 'Core Focus',
  tenYear = 'Ten Year',
  marketingStrategy = 'Marketing Strategy',
  threeYear = 'Three Year',
  oneYear = 'One Year',
  ninetyDays = 'Ninety Days',
  issuesList = 'Issues',
  customSection = 'Custom Section',
  niche = 'Niche',
  purpose = 'Purpose',
}

export enum PinnacleSectionLabels {
  purposeWhyWeExist = 'Purpose: Why We Exist',
  coreBusiness = 'Core Business',
  pinnacleTopOfMountain = 'Pinnacle: The Top of Our Mountain',
  quarterlyTheme = 'Quarterly Theme',
  flywheelDesign = 'Flywheel Design',
  oneLineMarketingPhrase = 'One Phrase Strategy',
  brandPromises = 'Brand Promises',
}

export interface VtoSectionSettings {
  _id?: string;
  visible: boolean;
  section: VtoSectionKeys;
  tab: keyof typeof VtoTabsName;
  cols?: number;
  rows?: number;
  x?: number;
  y?: number;
  preventTitleEdit?: boolean;
}

export interface VtoSections {
  sectionSettings: VtoSectionSettings[];
  customSectionSettings: VtoCustomSectionSettings[];
}

export class VtoCustomSectionSettings implements VtoSectionSettings {
  _id = ObjectID.generate();
  section: VtoSectionKeys = 'customSection';
  tab: keyof typeof VtoTabsName = 'vision';
  cascaded? = false;
  visible = true;
  title: string;
  cols?: number;
  rows?: number;
  x?: number;
  y?: number;
  iframeUrl?: string;
  details?: string;
}

export namespace VtoSectionSettings {
  type KeysAddedAfterConversionToNinety = Extract<keyof typeof VtoSectionLabels, 'niche' | 'purpose'>;

  export const ADDED_AFTER_CONVERSION_TO_NINETY: Record<KeysAddedAfterConversionToNinety, VtoSectionSettings> = {
    niche: { cols: 1, rows: 1, y: 101, x: 0, section: 'niche', visible: true, tab: 'vision' },
    purpose: { cols: 1, rows: 1, y: 100, x: 0, section: 'purpose', visible: true, tab: 'vision' },
  };

  export const ADDED_AFTER_CONVERSION_TO_PINNACLE: Record<keyof typeof PinnacleSectionLabels, VtoSectionSettings> = {
    // vision
    purposeWhyWeExist: {
      cols: 1,
      rows: 1,
      y: 102,
      x: 0,
      section: 'purposeWhyWeExist',
      visible: false,
      tab: 'vision',
    },
    coreBusiness: {
      cols: 1,
      rows: 1,
      y: 103,
      x: 0,
      section: 'coreBusiness',
      visible: false,
      tab: 'vision',
    },
    pinnacleTopOfMountain: {
      cols: 1,
      rows: 1,
      y: 104,
      x: 0,
      section: 'pinnacleTopOfMountain',
      visible: false,
      tab: 'vision',
    },

    // traction
    quarterlyTheme: {
      cols: 1,
      rows: 1,
      y: 101,
      x: 0,
      section: 'quarterlyTheme',
      visible: false,
      tab: 'traction',
    },
    flywheelDesign: {
      cols: 1,
      rows: 1,
      y: 102,
      x: 0,
      section: 'flywheelDesign',
      visible: false,
      tab: 'traction',
    },
    oneLineMarketingPhrase: {
      cols: 1,
      rows: 1,
      y: 103,
      x: 0,
      section: 'oneLineMarketingPhrase',
      visible: false,
      tab: 'traction',
    },
    brandPromises: {
      cols: 2,
      rows: 2,
      y: 104,
      x: 0,
      section: 'brandPromises',
      visible: false,
      tab: 'traction',
      preventTitleEdit: true,
    },
  };

  export const StandardSettingsByBosLookup: Partial<Record<BusinessOperatingSystem, ReadonlyVtoSectionSettings>> = {
    [BusinessOperatingSystem.eos]: [
      // Vision
      { cols: 2, rows: 1, y: 0, x: 0, section: 'coreValues', visible: true, tab: 'vision' },
      { cols: 2, rows: 1, y: 1, x: 0, section: 'coreFocus', visible: true, tab: 'vision' },
      { cols: 2, rows: 1, y: 2, x: 0, section: 'tenYear', visible: true, tab: 'vision' },
      { cols: 2, rows: 2, y: 3, x: 0, section: 'marketingStrategy', visible: true, tab: 'vision' },
      { cols: 1, rows: 5, y: 0, x: 2, section: 'threeYear', visible: true, tab: 'vision' },

      // Traction
      { cols: 1, rows: 3, y: 0, x: 0, section: 'ninetyDays', visible: true, tab: 'traction' },
      { cols: 1, rows: 3, y: 0, x: 1, section: 'issuesList', visible: true, tab: 'traction' },
      { cols: 1, rows: 3, y: 0, x: 2, section: 'oneYear', visible: true, tab: 'traction' },
    ],

    [BusinessOperatingSystem.ninetyOS]: [
      // Vision
      { cols: 1, rows: 1, y: 0, x: 0, section: 'coreValues', visible: true, tab: 'vision' },
      { cols: 1, rows: 1, y: 1, x: 0, section: 'purpose', visible: true, tab: 'vision' },
      { cols: 1, rows: 1, y: 2, x: 0, section: 'niche', visible: true, tab: 'vision' },
      { cols: 2, rows: 3, y: 0, x: 1, section: 'marketingStrategy', visible: true, tab: 'vision' },

      // Traction
      { cols: 3, rows: 1, y: 0, x: 0, section: 'tenYear', visible: true, tab: 'traction' },
      { cols: 1, rows: 4, y: 1, x: 0, section: 'threeYear', visible: true, tab: 'traction' },
      { cols: 1, rows: 4, y: 1, x: 1, section: 'oneYear', visible: true, tab: 'traction' },
      { cols: 1, rows: 4, y: 1, x: 2, section: 'ninetyDays', visible: true, tab: 'traction' },
    ],

    [BusinessOperatingSystem.pinnacle]: [
      // Vision
      { cols: 1, rows: 3, y: 3, x: 0, section: 'coreValues', visible: true, tab: 'vision' },
      { cols: 1, rows: 1, y: 9, x: 0, section: 'coreFocus', visible: false, tab: 'vision' },
      { cols: 1, rows: 5, y: 3, x: 0, section: 'marketingStrategy', visible: false, tab: 'vision' },
      { cols: 1, rows: 4, y: 2, x: 1, section: 'threeYear', visible: true, tab: 'vision' },
      { cols: 2, rows: 1, y: 8, x: 0, section: 'tenYear', visible: false, tab: 'vision' },
      { cols: 1, rows: 6, y: 0, x: 2, section: 'oneYear', visible: true, tab: 'vision' },
      {
        cols: 1,
        rows: 2,
        y: 0,
        x: 0,
        section: 'purposeWhyWeExist',
        visible: true,
        tab: 'vision',
      },
      {
        cols: 1,
        rows: 1,
        y: 2,
        x: 0,
        section: 'coreBusiness',
        visible: true,
        tab: 'vision',
      },
      {
        cols: 1,
        rows: 2,
        y: 0,
        x: 1,
        section: 'pinnacleTopOfMountain',
        visible: true,
        tab: 'vision',
      },

      // Traction
      { cols: 1, rows: 5, y: 0, x: 0, section: 'ninetyDays', visible: true, tab: 'traction' },
      { cols: 1, rows: 6, y: 3, x: 0, section: 'issuesList', visible: false, tab: 'traction' },
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 1,
        section: 'quarterlyTheme',
        visible: true,
        tab: 'traction',
      },
      {
        cols: 2,
        rows: 2,
        y: 1,
        x: 1,
        section: 'flywheelDesign',
        visible: true,
        tab: 'traction',
      },
      {
        cols: 2,
        rows: 1,
        y: 3,
        x: 1,
        section: 'oneLineMarketingPhrase',
        visible: true,
        tab: 'traction',
      },
      {
        cols: 2,
        rows: 2,
        y: 4,
        x: 1,
        section: 'brandPromises',
        visible: true,
        tab: 'traction',
        preventTitleEdit: true,
      },
    ],
  };

  export function isPinnacleSectionKey(key: VtoSectionKeys): key is keyof typeof PinnacleSectionLabels {
    return typeof PinnacleSectionLabels[key] === 'string';
  }
}

export const EmptyPinnacleSections: Pick<Vto, keyof typeof PinnacleSectionLabels> = {
  purposeWhyWeExist: { _id: ObjectID.generate(), title: 'Purpose: Why We Exist', details: '' },
  coreBusiness: { _id: ObjectID.generate(), title: 'Core Business', details: '' },
  pinnacleTopOfMountain: { _id: ObjectID.generate(), title: 'Pinnacle: The Top of Our Mountain', details: '' },
  quarterlyTheme: { _id: ObjectID.generate(), title: 'Quarterly Theme', details: '' },
  flywheelDesign: { _id: ObjectID.generate(), title: 'Flywheel Design', details: '' },
  oneLineMarketingPhrase: { _id: ObjectID.generate(), title: 'One Phrase Strategy', details: '' },
  brandPromises: [],
};
