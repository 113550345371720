import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { GuideSelectors } from '@ninety/getting-started/guide/_state/guide.selectors';
import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { ProgressBarComponent } from '@ninety/ui/legacy/components/progress-bar/progress-bar.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { StopPropagationDirective } from '@ninety/ui/legacy/shared/directives/stop-propagation.directive';
import { selectIsDualTheme } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

@Component({
  selector: 'ninety-getting-started-tracker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [],
  imports: [
    CommonModule,
    PushPipe,
    ProgressBarComponent,
    LetDirective,
    StopPropagationDirective,
    TerraIconModule,
    ButtonComponent,
    NinetyTooltipDirective,
  ],
  styleUrls: ['./getting-started-tracker.component.scss'],
  template: `
    <ng-container *ngrxLet="completionPercentage$ as percentage">
      <div
        ninetyStopPropagation
        *ngIf="isExpanded"
        class="getting-started-tracker"
        [class.locked]="locked"
        (click)="toggleGettingStartedGuide()"
        [class.dual-theme]="isDualTheme$ | ngrxPush"
        data-product="getting-started-guide-left-nav-element"
        data-cy="getting-started_tracker">
        <ninety-button
          flat
          icon
          class="dismiss-guide-btn"
          ninetyStopPropagation
          *ngIf="percentage >= 100"
          (click)="dismissGuide()">
          <terra-icon icon="x" size="20" />
        </ninety-button>

        <div class="gsg-locked" *ngIf="locked">
          <terra-icon icon="lock" variant="fill" size="52" />
        </div>
        <div class="header">
          <div class="title">Getting Started</div>
          Unlock the power of Ninety
        </div>

        <ninety-progress-bar [percentage]="percentage" />

        <div class="completion-percent">
          <span *ngIf="percentage < 100; else unlocked">{{ percentage | number : '1.0-0' }}% complete</span>
          <ng-template #unlocked>Unlocked!</ng-template>
        </div>
      </div>
      <div
        ninetyStopPropagation
        *ngIf="!isExpanded"
        [class.locked]="locked"
        [class.dual-theme]="isDualTheme$ | ngrxPush"
        class="collapsed-tracker"
        (click)="toggleGettingStartedGuide()"
        [ninetyTooltip]="percentage + '% complete'"
        tpPlacement="right"
        data-product="getting-started-guide-left-nav-element"
        data-cy="collapsed-getting-started_tracker">
        <img *ngIf="!locked" src="/assets/icons/ninety/90_Logo_Square_Margins_Black.svg" />
        <terra-icon *ngIf="locked" icon="lock" size="24" />
        <ninety-progress-bar [percentage]="percentage" />
      </div>
    </ng-container>
  `,
})
export class GettingStartedTrackerComponent implements OnInit {
  @Input() locked: boolean;
  @Input() isExpanded: boolean;

  protected readonly completionPercentage$ = this.store.select(GuideSelectors.selectCompletionPercentage);
  protected readonly isDualTheme$ = this.store.select(selectIsDualTheme);
  protected readonly language$ = this.store.select(selectLanguage);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(GuideActions.init());
  }

  toggleGettingStartedGuide(): void {
    if (this.locked) return;
    this.store.dispatch(GuideActions.toggleGuide());
  }

  dismissGuide() {
    this.store.dispatch(GuideActions.dismissGuide());
  }
}
