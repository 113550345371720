import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ninety-card-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        box-sizing: border-box;
        padding: 0 24px 16px 24px;
        flex-wrap: unset;

        &.small {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    `,
  ],
})
export class CardFooterComponent {
  @HostBinding('class.ninety-card-footer') classes = true;
}
