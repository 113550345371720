import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

export const TodoDetailActions = createActionGroup({
  source: 'Todo Detail',
  events: {
    'Open In Detail': props<{ todo: Todo }>(),
    'Clear Selected Todo': emptyProps(),

    'Toggle Personal to Team': props<{ todo: Todo }>(),
    'Toggle Team to Personal': props<{ todo: Todo }>(),
  },
});
