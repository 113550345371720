<ninety-item-card *ngIf="teamUsers$ | async" (closed)="closeDetail.emit()">
  <ng-container item-card-header>
    <h4 class="card-title">Team Members</h4>
    <terra-divider vertical="true" class="vertical-divider" />
    <ninety-user-select
      *ngIf="userIdsOnTeam && canEdit"
      class="branded-link-primary"
      tooltipOverride="Add Team Members"
      [clearSelectedAfterClose]="true"
      [disabled]="spinnerService.primary$ | async"
      [filterOutUsersByIds]="userIdsOnTeam"
      [iconBtn]="true"
      [includeObservers]="true"
      [multiple]="true"
      (selectUsers)="onClickAddUsers($event)"></ninety-user-select>
  </ng-container>

  <ng-container item-card-content>
    <ng-container *ngIf="canEdit; else readOnlyTitle">
      <mat-form-field class="full-width">
        <input
          matInput
          data-cy="team-card_team-name-input"
          aria-label="Team Name"
          placeholder="Team Name"
          required
          [disabled]="spinnerService.primary$ | async"
          [(ngModel)]="team.name"
          (ngModelChange)="update.emit({ name: team.name })" />
      </mat-form-field>
    </ng-container>
    <ng-template #readOnlyTitle>
      <h3>{{ teamUsers.team.name }}</h3>
    </ng-template>

    <mat-list class="ninety-list attachment-list" [disabled]="spinnerService.primary$ | async">
      <mat-list-item
        data-cy="team-card_members-list"
        class="list-item"
        *ngFor="let user of teamUsers.users; let i = index">
        <div class="flex1" attr.data-cy="team-card_member-name-{{ user?.metadata?.name | displayFullName }}">
          {{ user?.metadata?.name | displayFullName }}
        </div>
        <button
          *ngIf="canEdit"
          (click)="onClickRemoveUser(user)"
          attr.data-cy="team-card_remove-user-from-team-{{ user?.metadata?.name | displayFullName }}"
          class="delete-btn"
          color="warn"
          mat-icon-button
          matTooltip="Remove user from team"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip">
          <terra-icon icon="delete" />
        </button>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ninety-item-card>
