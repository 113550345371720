<ninety-meeting-agendas
  [selectedTeam]="selectedTeam$ | ngrxPush"
  [agendaV2SelectedTypeOrIdOrNewCustom]="agendaTypeOrIdOrNew$ | ngrxPush"
  [agendaV2]="true"
  (saveTeamAgendaByType)="updateAgendaByType($event)"
  (deleteTeamCustomAgenda)="deleteCustomAgenda($event)"
  (validForm)="validForm($event)"
  (newChanges)="newChanges($event)"
  [resetAgendas]="agendaV2Service.resetAgendas$ | ngrxPush">
</ninety-meeting-agendas>
