import { BusinessOperatingSystem } from '../company/business-operating-system.enum';

export interface DomainPartnerConfiguration {
  name: string;
  company: string;
  bos: BusinessOperatingSystem;
  loginLogo: string;
  loginSplash: string;
  primaryAccent?: string;
  secondaryAccent?: string;
}

export type DomainPartnerConfigurations = Record<keyof typeof BusinessOperatingSystem, DomainPartnerConfiguration>;

export const assetFolder = '/assets/login-bos';

export const loginDomainPartnerConfigurations: DomainPartnerConfigurations = {
  ninetyOS: {
    name: 'ninety',
    company: 'Ninety',
    bos: BusinessOperatingSystem.ninetyOS,
    loginLogo: `${assetFolder}/ninety/logo.svg`,
    loginSplash: `${assetFolder}/ninety/splash.jpg`,
    primaryAccent: '#2F8BAA',
    secondaryAccent: '#262626',
  },
  empire: {
    name: 'empire',
    company: 'Empire',
    bos: BusinessOperatingSystem.empire,
    loginLogo: `${assetFolder}/empire/logo.png`,
    loginSplash: `${assetFolder}/empire/splash.jpg`,
    primaryAccent: '#DABC62',
    secondaryAccent: '#DABC62',
  },
  eos: {
    name: 'eos',
    company: 'EOS',
    bos: BusinessOperatingSystem.eos,
    loginLogo: `${assetFolder}/eos/logo.png`,
    loginSplash: `${assetFolder}/eos/splash.jpg`,
    primaryAccent: '#FF7900',
    secondaryAccent: '#FF7900',
  },
  fireproof: {
    name: 'fireproof',
    company: 'Fireproof',
    bos: BusinessOperatingSystem.fireproof,
    loginLogo: `${assetFolder}/fireproof/logo.png`,
    loginSplash: `${assetFolder}/fireproof/splash.jpg`,
    primaryAccent: '#ED1C23',
    secondaryAccent: '#ED1C23',
  },
  pinnacle: {
    name: 'pinnacle',
    company: 'Pinnacle',
    bos: BusinessOperatingSystem.pinnacle,
    loginLogo: `${assetFolder}/pinnacle/logo.png`,
    loginSplash: `${assetFolder}/pinnacle/splash.jpg`,
    primaryAccent: '#FF052B',
    secondaryAccent: '#FF052B',
  },
};
