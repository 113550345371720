import { Pipe, PipeTransform } from '@angular/core';
class Options {
  readonly separator?: string = ', ';
  readonly withCount?: boolean = true;
}

type KeysOfType<T, KT> = {
  [K in keyof T]: T[K] extends KT ? K : never;
}[keyof T];
@Pipe({
  name: 'joinProperties',
})
export class JoinPropertiesPipe implements PipeTransform {
  transform<T, K extends KeysOfType<T, string | number>>(array: T[], property: K, options?: Options): string {
    if (!array) return '';

    const { separator, withCount } = { ...new Options(), ...options };
    const joined = array.reduce(
      (acc: string, item: T, currentIndex) =>
        currentIndex === 0 ? `${item[property]}` : `${acc}${separator}${item[property]}`,
      ''
    );

    if (withCount && array.length > 1) return `(${array.length}) ${joined}`;

    return joined;
  }
}
