<div (click)="item.action(item)" ninetyMenuRow cdkMenuItem>
  <span class="menu-cell prefix-cell-layout">
    <span *ngIf="item.icon" class="icon">
      <terra-icon [icon]="item.icon.name" [size]="item.icon.size" [variant]="item.icon.variant" />
    </span>
    <span>{{ item.label }}</span>
  </span>
  <span class="menu-cell suffix-cell-layout">
    <span>{{ item.suffix }}</span>
  </span>
</div>
