import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { Person } from '../../_shared/models/_shared/person';
import { Company as CompanyModel } from '../../_shared/models/company/company';

import { AnonymousStateModel } from './anonymous/anonymous-state.model';
import { anonymousStateReducer } from './anonymous/anonymous-state.reducer';
import { BannerNotificationStateEffects } from './banner-notification/banner-notification.effects';
import { BannerNotificationModel, BannerNotificationStateKey } from './banner-notification/banner-notification.model';
import { bannerNotificationStateReducer } from './banner-notification/banner-notification.reducer';
import { BillingStateEffects } from './billing/billing-state.effects';
import { BillingState, BillingStateKey } from './billing/billing-state.model';
import { BillingStateReducer } from './billing/billing-state.reducer';
import { CompanyStateEffects } from './company/company-state.effects';
import { companyStateReducer } from './company/company-state.reducer';
import { SubscriptionStateEffects } from './company/subscription/subscription-state.effects';
import { currentPersonStateReducer } from './current-person/current-person.reducer';
import {
  HelpfulPermissionsStateModel,
  helpfulPermissionsReducer,
} from './helpful-permissions/helpful-permissions.reducer';
import { InteractionsStateEffects } from './interactions/interactions.effects';
import { interactionsStateReducer } from './interactions/interactions.reducer';
import { Interactions } from './interactions/models/interaction';
import { IntercomStateEffects } from './intercom/intercom-state.effects';
import { LanguageEffects } from './language/language.effects';
import { LanguageStateModel, languageReducer } from './language/language.reducer';
import { NotificationStateEffects } from './notifications/notification.effects';
import { PoliciesStateEffects } from './policies/policies.effects';
import { PoliciesStateModel } from './policies/policies.model';
import { policiesStateReducer } from './policies/policies.reducer';
import { RealTimeEffects } from './real-time/real-time.effects';
import { RealTimeReducer, RealTimeStateModel } from './real-time/real-time.reducer';
import { SpinnerStateModel } from './spinner/spinner-state.models';
import { SpinnerStateReducer } from './spinner/spinner-state.reducers';
import { ViewportStateEffects } from './viewport/viewport.effects';
import { ViewportState } from './viewport/viewport.model';
import { viewportStateReducer } from './viewport/viewport.reducer';

import {
  AnonymousStateKey,
  AppGlobalStateKey,
  CompanyStateKey,
  HelpfulPermissionsKey,
  InteractionsStateKey,
  LanguageStateKey,
  PoliciesStateKey,
  RealTimeStateKey,
  SpinnerStateKey,
  ViewportStateKey,
} from '.';
import { CurrentPersonStateKey } from './index';

export interface AppGlobalState {
  [BannerNotificationStateKey]: BannerNotificationModel;
  [CompanyStateKey]: CompanyModel;
  [LanguageStateKey]: LanguageStateModel;
  [RealTimeStateKey]: RealTimeStateModel;
  [HelpfulPermissionsKey]: HelpfulPermissionsStateModel;
  [PoliciesStateKey]: PoliciesStateModel;
  [CurrentPersonStateKey]: Person;
  [BillingStateKey]: BillingState;
  [InteractionsStateKey]: Interactions;
  [AnonymousStateKey]: AnonymousStateModel;
  [SpinnerStateKey]: SpinnerStateModel;
  [ViewportStateKey]: ViewportState;
}

export const appStateReducers: ActionReducerMap<AppGlobalState> = {
  [BannerNotificationStateKey]: bannerNotificationStateReducer,
  [CompanyStateKey]: companyStateReducer,
  [LanguageStateKey]: languageReducer,
  [RealTimeStateKey]: RealTimeReducer,
  [HelpfulPermissionsKey]: helpfulPermissionsReducer,
  [PoliciesStateKey]: policiesStateReducer,
  [CurrentPersonStateKey]: currentPersonStateReducer,
  [BillingStateKey]: BillingStateReducer,
  [InteractionsStateKey]: interactionsStateReducer,
  [AnonymousStateKey]: anonymousStateReducer,
  [SpinnerStateKey]: SpinnerStateReducer,
  [ViewportStateKey]: viewportStateReducer,
};

@NgModule({
  imports: [
    StoreModule.forFeature(AppGlobalStateKey, appStateReducers),
    EffectsModule.forFeature([
      CompanyStateEffects,
      IntercomStateEffects,
      LanguageEffects,
      RealTimeEffects,
      PoliciesStateEffects,
      NotificationStateEffects,
      SubscriptionStateEffects,
      BannerNotificationStateEffects,
      BillingStateEffects,
      InteractionsStateEffects,
      ViewportStateEffects,
    ]),
  ],
})
export class AppGlobalStateModule {}
