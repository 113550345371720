import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { InvoiceV2 } from '../../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { SubscriptionProviderConfiguration } from '../../../_shared/models/billingv2/subscription-provider-configuration.model';

export const BillingStateActions = createActionGroup({
  source: 'Billing',
  events: {
    Init: props<{ subscriptionConfigurations: SubscriptionProviderConfiguration[] }>(),
    'Billing Init': emptyProps(),
    'Get Active Subscription Configurations': emptyProps(),
    'Get Active Subscription Configurations Success': props<{
      subscriptionConfigurations: SubscriptionProviderConfiguration[];
    }>(),
    'Get Active Subscription Configurations Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Company Billing Counts': emptyProps(),
    'Get Company Billing Counts Success': props<{ companyBillingCounts: BillingCounts }>(),
    'Get Company Billing Counts Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Upcoming Invoice': emptyProps(),
    'Get Upcoming Invoice Success': props<{ invoice: InvoiceV2 }>(),
    'Get Upcoming Invoice Failure': props<{ error: unknown; customMessage: string }>(),
  },
});
