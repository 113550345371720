import { Person } from '../_shared/person';
import { BusinessOperatingSystem } from '../company/business-operating-system.enum';

import { PartnerType } from './partner-type.enum';

export class CreatePartnerDTO {
  partner_key: string;
  first_name: string;
  last_name: string;
  email: string;
  group_key?: string;

  /** used for updating the person in the database */
  personId: string;
  partnerType: PartnerType;
  bosTypes?: BusinessOperatingSystem[];

  static fromPersonAndType(
    { metadata: { name }, primaryEmail: email, _id: personId }: Person,
    partnerType: PartnerType
  ): CreatePartnerDTO {
    return {
      partner_key: personId,
      first_name: name.first,
      last_name: name.last,
      partnerType,
      email,
      personId,
    };
  }
}
