import { GridWidgetPosition } from './grid-widget-position';

/**
 * Encapsulates the positioning and other metadata of a widget in the Grid. Explicitly has no knowledge of any feature
 * module.
 *
 * @param TypeKey Expected to be an enum defining types of widgets.
 */
export interface GridWidget<TypeKey> {
  /**
   * A unique identifier for this widget. Must be unique in a grid.
   */
  _id: string;

  /**
   * The type of the widget. Used to match this data model to its template.
   */
  readonly type: TypeKey;

  /**
   * Whether the widget is visible in the grid. Note, this property missing from the data model is treated the same as
   * it being false.
   */
  visible?: boolean;

  /**
   * Where the grid widget is rendered.
   */
  position: GridWidgetPosition;
}

/**
 * Legacy VTO type.
 *
 * TODO This will likely get removed by 1906, but it might need to persist until the VTO re-write.
 */
export type LegacyGridWidget = GridWidget<string>;

export namespace GridWidget {
  export function equal(a: GridWidget<unknown>, b: GridWidget<unknown>): boolean {
    if (!a && !b) return true;
    if (!a) return false;
    if (!b) return false;

    if (a._id !== b._id) return false;
    if (a.type !== b.type) return false;
    if (a.visible !== b.visible) return false;
    return GridWidgetPosition.equalByPosition(a.position, b.position);
  }

  /**
   * Prepares a widget to be added to the grid by marking it as visible and configuring x/y to place widget in the
   * bottom left corner. Note, when x/y are missing, GridStack will auto-position the widget in the bottom left corner.
   */
  export function transformWidgetToAddToGrid<T>(widget: GridWidget<T>): GridWidget<T> {
    const position = { ...widget.position };
    delete position.x;
    delete position.y;

    return {
      ...widget,
      visible: true,
      position,
    };
  }
}
