import { Conversation } from './conversation';
import { ConversationManagee } from './conversation-managee';
import { ConversationRepetitionFrequency } from './conversation-repetition-frequency';
import { ConversationType } from './conversation-type.enum';

export class NewConversation {
  title = '';
  managerUserId: string;
  managees: Array<ConversationManagee>;
  periodStartDate: string | Date | null = null;
  periodEndDate: string | Date | null = null;
  canEvaluateSelf = true;
  canEvaluateManager = true;
  shouldSendEmailNotifications = false;
  holdingMeeting: boolean;
  type: ConversationType;
  description: string;
  repeat: ConversationRepetitionFrequency;
  revealAnswersPriorToMeeting: boolean;
  createTodos?: boolean;

  constructor(conversation: Conversation, managees: ConversationManagee[], createTodos?: boolean) {
    this.title = conversation.title;
    this.managerUserId = conversation.managerUserId;
    this.managees = managees;
    this.periodStartDate = conversation.periodStartDate;
    this.periodEndDate = conversation.periodEndDate;
    this.canEvaluateSelf = conversation.canEvaluateSelf;
    this.canEvaluateManager = conversation.canEvaluateManager;
    this.shouldSendEmailNotifications = conversation.shouldSendEmailNotifications;
    this.holdingMeeting = conversation.holdingMeeting;
    this.type = conversation.type;
    this.description = conversation.description ?? '';
    this.repeat = conversation.repeat ?? ConversationRepetitionFrequency.none;
    this.revealAnswersPriorToMeeting = conversation.revealAnswersPriorToMeeting ?? true;
    this.createTodos = createTodos ?? false;
  }
}
