import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';

import { Team } from '../../../_shared/models/_shared/team';
import { User } from '../../../_shared/models/_shared/user';
import { mongoIdAdapter } from '../../../_shared/ngrx/mongo-id-adapter';
import { SortByNamePipe } from '../../../_shared/pipes/sort-by-name.pipe';

export type TeamStateModel = EntityState<Team>;
export type TeamUpdateOne = Update<Team>;
export interface TeamUsers {
  team: Team;
  users: User[];
}
/*
  _id is optional because the Team object is created client-side & patched with an _id
  when the API responds. Need to make a separate CreateTeamDto without an id and change
  _id to required on the Team interface.
*/
const selectId = mongoIdAdapter<Team & { _id: string }>();
export const teamsStateAdapter = createEntityAdapter<Team>({
  selectId,
  sortComparer: SortByNamePipe.ascending,
});
export const teamsInitialState: TeamStateModel = teamsStateAdapter.getInitialState({});
