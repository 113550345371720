import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, delay, Observable, of, retryWhen, throwError } from 'rxjs';

const retryCount = 1;
const retryDelayMiliseconds = 300;

@Injectable()
export class BadGatewayErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(err =>
        err.pipe(
          concatMap((err, count) => {
            if (err.status === 502 && count < retryCount) {
              console.log(`Attempting to retry bad gateway request`);
              return of(err);
            }
            return throwError(() => err);
          }),
          delay(retryDelayMiliseconds)
        )
      )
    );
  }
}
