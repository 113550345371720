import { BidiModule } from '@angular/cdk/bidi';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDividerModule } from '../terra-divider';
import { TerraIconModule } from '../terra-icon';
import { TerraOptionModule } from '../terra-option';

import { TerraSelectComponent } from './terra-select.component';

@NgModule({
  imports: [CommonModule, TerraSelectComponent, TerraIconModule, TerraOptionModule, TerraDividerModule],
  exports: [TerraSelectComponent, TerraIconModule, BidiModule, TerraOptionModule, TerraDividerModule],
})
export class TerraSelectModule {}
