import { ItemType } from '../enums/item-type';
import { IntervalCode } from '../issues/interval-code';
import { Milestone } from '../rocks/milestone';
import { Rock } from '../rocks/rock';
import { RockLevelCode } from '../rocks/rock-level-code';
import { RockStatusCode } from '../rocks/rock-status-code';
import { TodoAgreementStatus } from '../todos/todo';

import { Attachment } from './attachment';
import { Comment } from './comment';
import { Team } from './team';
import { User } from './user';

export class Item {
  userId: string | null = null;
  title = '';
  description = '';
  userEmail?: string; // only for when creating via upload/import
  dueDate?: Date | string = null;
  teamId?: string = null;
  ordinal: number;
  userOrdinal = -1;
  archived?: boolean;
  comments?: Comment[];
  completed?: boolean;
  deleted?: boolean;
  user?: User;
  _id?: string;
  createdBy?: string;
  originalDueDate?: Date;
  completedDate?: Date;
  dueDateChanged?: boolean;
  fileAttachments?: File[] = [];
  attachments?: Attachment[];
  archivedDate?: Date;
  itemType?: ItemType;
  vtoId?: string;

  // Todos
  milestoneId?: string;
  isPersonal?: boolean;
  repeat?: string;
  seriesId?: string | null;

  /** Agreements */
  createdByUserId?: string;
  createdDate?: string | Date;
  ownerAcceptedDate?: Date;
  creatorAcceptedDate?: Date;
  status?: TodoAgreementStatus;

  // Rocks
  statusCode?: RockStatusCode = RockStatusCode.onTrack;
  levelCode?: RockLevelCode = RockLevelCode.user;
  additionalTeamIds?: string[];
  companyId?: string;
  milestones?: Milestone[];

  // Issues
  who = '';
  intervalCode: IntervalCode = IntervalCode.shortTerm;
  isPublic?: boolean;
  workingTeamId?: string;
  workingTeamSentBack?: boolean;
  rating?: number;

  // Headlines
  teamIds?: string[];
  ownedByUser?: User;
  ownedByUserId?: string;
  cascadedFromTeamId?: string;
  cascadedFromTeam?: Team;
  isCascadedMessage?: boolean;

  // Milestones
  rock?: Rock;
  isDone?: boolean;

  constructor(item?: Partial<Item>) {
    if (item) {
      Object.assign(this, item);
    }

    if (this.itemType === ItemType.headline) {
      if (this.teamId && !this.teamIds?.length) {
        this.teamIds = [this.teamId];
      }
    } else if (this.itemType !== ItemType.cascadedMessage) {
      this.teamIds = [];
    }

    if (this.itemType !== ItemType.issue || this.intervalCode !== IntervalCode.longTerm) {
      this.isPublic = false;
    }

    if (this.itemType !== ItemType.todo) {
      this.isPersonal = false;
    }
  }
}
