import { createReducer, on } from '@ngrx/store';

import { DataImportStateModel, initialDataImportState } from '@ninety/data-import/_state/data-import-state.model';
import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';

export const dataImportStateReducer = createReducer(
  initialDataImportState,
  on(
    DataImportActions.launchOneSchema,
    (state, { userJwt }): DataImportStateModel => ({
      ...state,
      userJwt,
    })
  ),
  on(
    DataImportActions.selectTemplate,
    (state, { option }): DataImportStateModel => ({
      ...state,
      templateKey: option.templateKey,
      selectedIcon: option.icon,
    })
  ),
  on(
    DataImportActions.selectTeam,
    (state, { teamId }): DataImportStateModel => ({
      ...state,
      selectedTeamId: teamId,
    })
  )
);
