<mat-dialog-content>
  <mat-nav-list>
    <a mat-list-item href="//help.ninety.io/" target="_blank">
      <terra-icon matListIcon icon="question-circle" />
      HELP CENTER
    </a>
    <a mat-list-item href="//status.ninety.io/" target="_blank">
      <terra-icon matListIcon icon="info-circle" />
      STATUS
    </a>
    <a mat-list-item href="//blog.ninety.io/updates" target="_blank">
      <terra-icon matListIcon icon="newspaper" />
      UPDATES
    </a>
  </mat-nav-list>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>DONE</button>
</mat-dialog-actions>
