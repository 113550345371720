import { createActionGroup, props } from '@ngrx/store';

import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { ListViewConversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';

export const ConversationContextMenuActions = createActionGroup({
  source: 'Conversation Context Menu',
  events: {
    'Conversation Form Started': props<{ conversation: ListViewConversation }>(),
    'Conversation Meeting Started': props<{ conversation: ListViewConversation }>(),
    'Conversation Status Updated': props<{ conversation: ListViewConversation }>(),
    'Conversation Deleted': props<{ conversation: ListViewConversation }>(),
    'Universal Create Modal Opened': props<{
      itemType: ItemType.todo | ItemType.issue;
      conversation: ListViewConversation;
    }>(),
  },
});
