import { MeetingAgendaType } from './meeting-agenda-type.enum';

export const ReservedSectionNames: { [key in MeetingAgendaType]: string[] } = {
  weeklyMeetings: ['segue', 'data', 'scorecard', 'rocks', 'headlines', 'todos', 'issues', 'conclude'],
  //TODO: 'ninety-tools','eos-tools' could be removed once the transition to /mastery is done
  quarterlyMeetings: [
    'objectives',
    'check-in',
    'review-prior-quarter',
    'vision',
    'ninety-tools',
    'eos-tools',
    'mastery',
    'rocks',
    'issues',
    'next-steps',
    'conclude',
  ],
  annualDayOne: [
    'objectives',
    'check-in',
    'review-prior-year',
    'review-prior-quarter',
    'team-health',
    'org-checkup',
    'swot',
    'vision',
    '3-year',
    '3-year-picture',
    'conclude',
  ],
  annualDayTwo: [
    'objectives',
    'check-in',
    'issues-and-3-year',
    '1-year-plan',
    'rocks',
    'issues',
    'next-steps',
    'conclude',
  ],
  focusDay: [
    'check-in',
    'hitting-the-ceiling',
    'org-chart',
    'accountability-chart',
    'rocks',
    'meeting-pulse',
    'data',
    'next-steps',
    'conclude',
  ],
  visionBuildingDayOne: [
    'check-in',
    'reviewing-focus-day-tools',
    'vision-building-segue',
    'core-values',
    'core-purpose',
    'core-focus',
    'long-term-goal',
    '10-year-target',
    'next-steps',
    'conclude',
  ],
  visionBuildingDayTwo: [
    'check-in',
    'reviewing-focus-day-tools',
    'core-values',
    'core-purpose',
    'core-focus',
    'marketing-strategy',
    '3-year',
    '3-year-picture',
    '1-year-plan',
    'rocks',
    'issues',
    'next-steps',
    'conclude',
  ],

  // what we want to prevent route changes in custom meetings....this list should grow
  custom: [],
};

const allSectionNames = Object.values(ReservedSectionNames)
  .filter(sections => Array.isArray(sections))
  .flat();

ReservedSectionNames.custom = allSectionNames;
