import { Sort } from '@angular/material/sort';

import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { ListViewConversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationSortField } from '@ninety/ui/legacy/shared/models/feedback/conversation-sort-field';
import { ConversationType } from '@ninety/ui/legacy/shared/models/feedback/conversation-type.enum';

export const ConversationListStateKey = 'conversation-list';

export interface ConversationListSort {
  sortField: ConversationSortField | null;
  sortDirection: SortDirection | null;
}

export interface ConversationListPager {
  pageSizeOptions: number[];
  pageSize: number;
  pageIndex: number;
}

export interface ConversationList {
  [ConversationType.quarterly]: PagedResponse<ListViewConversation>;
  [ConversationType.annual]: PagedResponse<ListViewConversation>;
}

export interface ConversationListState {
  conversationType: ConversationType | null;
  selectedConversationId: string | null;
  activeList: ConversationList;
  pastList: ConversationList;
  sort: Sort;
  pager: ConversationListPager;
  filter: {
    searchText: string | null;
    selectedUserId: string;
    showCompletedConversations: boolean;
    // Used to determine whether to set the Filtered person to the logged-in user
    // We only do this once on the first ConversationList initialization
    initFilterWithCurrentUser: boolean;
  };
}

export type ConversationListTense = Extract<keyof ConversationListState, 'activeList' | 'pastList'>;

export const initialConversationListState: ConversationListState = {
  conversationType: ConversationType.quarterly,
  selectedConversationId: null,
  activeList: {
    [ConversationType.quarterly]: {
      items: [],
      totalCount: 0,
    },
    [ConversationType.annual]: {
      items: [],
      totalCount: 0,
    },
  },
  pastList: {
    [ConversationType.quarterly]: {
      items: [],
      totalCount: 0,
    },
    [ConversationType.annual]: {
      items: [],
      totalCount: 0,
    },
  },
  sort: { active: 'dueDate', direction: 'desc' },
  pager: {
    pageSizeOptions: [10, 25, 50, 100],
    pageSize: 10,
    pageIndex: 0,
  },
  filter: {
    searchText: null,
    selectedUserId: 'all',
    showCompletedConversations: false,
    initFilterWithCurrentUser: true,
  },
};
