import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ninety-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent {
  constructor(
    public dialogRef: MatLegacyDialogRef<ImageDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { imgSrc: string }
  ) {}
}
