import { createSelector } from '@ngrx/store';

import { selectMazState } from '@ninety/getting-started/_state';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';

export namespace MazSelectors {
  export const selectMazChatEnabled = createSelector(
    selectFeatureFlag(FeatureFlagKeys.mazChat),
    mazChatFlag => mazChatFlag
  );
  export const selectIsMazChatExpanded = createSelector(selectMazState, state => state.mazChatExpanded);
  export const selectCommonQuestions = createSelector(selectMazState, state => state.commonQuestions);
  export const selectConversationHistory = createSelector(selectMazState, state => state.mazMessages);
  export const selectWaitingForMazResponse = createSelector(selectMazState, state => state.waitingForMazResponse);
}
