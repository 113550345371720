import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CompanyUserListModel } from '../..';
import { CompanyUser } from '../../../_shared/models/company/company-user';

export const CompanyUsersStateActions = createActionGroup({
  source: 'Company Users',
  events: {
    'App Init': props<{ companyUsers: CompanyUser[] }>(),
    'Set All Company Users': props<{ companyUsers: CompanyUserListModel[] }>(),
    'App Logout': emptyProps(),
    'Delete Current Company User': props<{ userId: string }>(),
    'Add One': props<{ entity: CompanyUserListModel }>(),
    'Remove One': props<{ userId: string }>(),
  },
});
