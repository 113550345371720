import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import { Subject, Subscription } from 'rxjs';

import { selectUserAvatarInfo } from '../../../_state/app-entities/users/users-state.selectors';
import { Comment } from '../../models/_shared/comment';
import { Locale } from '../../models/_shared/user-settings';
import { Issue } from '../../models/issues/issue';
import { Rock } from '../../models/rocks/rock';
import { Todo } from '../../models/todos/todo';

@Component({
  selector: 'ninety-item-comments',
  templateUrl: './item-comments.component.html',
  styleUrls: ['./item-comments.component.scss'],
})
export class ItemCommentsComponent implements OnInit, OnDestroy, OnChanges {
  newCommentText: string;
  commentSub = new Subject<Comment>();

  private subscriptions = new Subscription();

  @Input() item: Rock | Todo | Issue;
  @Input() locale: Locale;
  @Input() readOnly: boolean;
  @Input() canAddComment: boolean;
  @Input() currentUserId: string;

  @Output() updateComments = new EventEmitter<Comment>();
  @Output() deleteComments = new EventEmitter<Comment>();
  selectUserAvatarInfo = selectUserAvatarInfo;
  constructor(public store: Store) {}
  ngOnInit(): void {
    this.subscriptions.add(
      //NOTE: adding distinctChange only updates the comment the first time because pointers
      this.commentSub.subscribe({
        next: (comment: Comment) => this.onCommentEdit(comment),
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) this.newCommentText = '';
  }

  onAddComment() {
    if (!this.item.comments) this.item.comments = [];
    const comment = new Comment(this.currentUserId, this.newCommentText);
    this.item.comments.push(comment);
    this.newCommentText = null;
    this.updateComments.emit(comment);
    (document.activeElement as HTMLButtonElement).blur();
  }

  onCommentEdit(comment: Comment) {
    comment.editedDate = new Date();
    this.updateComments.emit(comment);
  }

  onDeleteComment(index: number) {
    const comment = _cloneDeep(this.item.comments[index]);
    this.deleteComments.emit(comment);
    this.item.comments = this.item.comments.filter(
      c => c.userId !== comment.userId || new Date(c.createdDate).getTime() !== new Date(comment.createdDate).getTime()
    );
  }

  onCommentFocusOut(comment: Comment) {
    if (comment.userId === this.currentUserId && !this.readOnly) this.commentSub.next(comment);
  }
}
