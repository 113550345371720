import { UrlSegment, UrlTree } from '@angular/router';

/** A function which navigates a UrlTree and returns a relative path as a list of segments */
export type SegmentNavigator = (tree: UrlTree) => UrlSegment[];

/** Utility functions for navigating UrlTrees */
export namespace SegmentNavigator {
  export const primary: SegmentNavigator = (tree: UrlTree) => tree?.root?.children?.primary?.segments ?? [];

  export const detail: SegmentNavigator = (tree: UrlTree) => tree?.root?.children?.detail?.segments ?? [];
}

/**
 * Given some root segment and fn to navigate the tree to find the initial segments, produce a {@link SegmentNavigator}.
 * Helpful for producing new relative paths from any arbitrary current path (ie: from a meeting, from main nav, from
 * child). See {@link ResponsibilityNavigationEffects}
 */
export const getPathRelativeToRoot =
  (root: string | UrlSegment, segmentSupplier: SegmentNavigator = SegmentNavigator.primary): SegmentNavigator =>
  (tree: UrlTree) => {
    const segments = segmentSupplier(tree);
    const rootPath: string = typeof root === 'string' ? root : root.path;
    const rootIndex = segments.findIndex(segment => segment.path === rootPath);
    return rootIndex !== -1 ? segments.slice(0, rootIndex + 1) : segments.concat([new UrlSegment(rootPath, {})]);
  };
