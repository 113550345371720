export class Comment {
  _id?: string;
  userId: string;
  createdDate: string | Date;
  editedDate?: string | Date | null;
  text: string;

  constructor(userId: string, text: string) {
    this.userId = userId;
    this.text = text;
    this.createdDate = new Date();
  }
}
