import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notDoneByDueDate',
})
export class NotDoneByDueDatePipe implements PipeTransform {
  transform(completedDate: Date, dueDate: Date | string): boolean {
    if (!completedDate) return false;

    let originalDueDateAtMidnight = new Date(dueDate);
    originalDueDateAtMidnight.setHours(24, 0, 0, 0);
    originalDueDateAtMidnight = new Date(originalDueDateAtMidnight);

    return completedDate
      ? new Date(originalDueDateAtMidnight) < new Date(completedDate)
      : new Date() > new Date(originalDueDateAtMidnight);
  }
}
