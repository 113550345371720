export enum LinkedItemTypeEnum {
  todo = 'todo',
  issue = 'issue',
  headline = 'headline',
  rock = 'rock',
  cascadingMessage = 'cascadingMessage',
  learningTask = 'learningTask',
  learningTopic = 'learningTopic',
  milestone = 'milestone',
}

export type LinkedItem = { _id: string; title: string };

export type LinkedItemsType = {
  todos?: LinkedItem[];
  issues?: LinkedItem[];
};

export type FromLinkedItem = {
  id: string;
  type: LinkedItemTypeEnum;
};

export enum ItemsToLinkedItemEnumMap {
  rock = 'rock',
  todo = 'todo',
  issue = 'issue',
  headline = 'headline',
  cascadedMessage = 'cascadingMessage',
  learningTopic = 'learningTopic',
  learningTask = 'learningTask',
  milestone = 'milestone',
}
