import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { BehaviorSubject, Observable, Subject, catchError, tap } from 'rxjs';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { OrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-update';
import { Accountability } from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';
import { Seat } from '@ninety/ui/legacy/shared/models/accountability-chart/seat';
import { SeatHolder, SeatHolderCreatePick } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-holder';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { SeatNode } from '@ninety/ui/legacy/shared/models/accountability-chart/seatNode';

import { CreateSeatModel } from '../models/seat.model';

import { SeatApiService } from './seat-api.service';
import { SeatHolderApiService } from './seat-holder-api.service';

/** @deprecated - prefer services in apps/ng-ninety/src/app/accountability-chart/services */
@Injectable({
  providedIn: 'root',
})
export class AccountabilityChartService {
  private acApi = 'api.qa1.90srv.com/AccountabilityChart';

  /** @deprecated - prefer NGRX state */
  seats: Seat[] = []; // Delete all usages, force usage of selector
  /** @deprecated - prefer NGRX state */
  seats$ = new BehaviorSubject<Seat[]>(null);
  /** @deprecated - prefer NGRX state */
  topLevelSeatId$ = new Subject<string>();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private spinnerService: SpinnerService,
    public stateService: StateService,
    private seatHolderApiService: SeatHolderApiService,
    private seatApiService: SeatApiService
  ) {}

  getAccountabilityChart(showSpinner = true): Observable<Seat[]> {
    if (showSpinner && !this.spinnerService.primary) this.spinnerService.start();
    return this.http.get<Seat[]>(this.acApi).pipe(
      tap(seats => {
        this.seats = seats;
        this.seats$.next(seats);
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get the ${this.stateService.language.acChart.route}.
       Please refresh the page.`
        )
      )
    );
  }

  // Seats ===========================================================

  /** @deprecated - prefer SeatApiService calls */
  createSeat(seat: Partial<Seat>): Observable<Seat> {
    return this.seatApiService.createSeatModel(seat as CreateSeatModel).pipe(
      tap((s: Seat) => {
        this.seats = [...this.seats, s];
        this.seats$.next(this.seats);
      }),
      catchError((e: { error?: { errorMessage: string } }) =>
        this.errorService.notify(
          e,
          e.error?.errorMessage || `Could not create ${this.stateService.language.acChart.seat}.\nPlease try again.`
        )
      )
    );
  }

  /** @deprecated - prefer updateSeatModel */
  updateSeat(seatId: string, update: Partial<Seat>): Observable<void> {
    return this.seatApiService
      .updateSeatModel({ id: seatId, changes: update as Partial<SeatModel> }) // TODO better type
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.acChart.seat}.\nPlease try again.`)
        )
      );
  }

  deleteSeats(seatIds: string[]): Observable<Seat[]> {
    return this.http.post<Seat[]>(`${this.acApi}/Seats/Delete`, seatIds).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not delete ${this.stateService.language.acChart.seats}.
       Please try again.`
        )
      )
    );
  }

  /** @deprecated - prefer updateSeatModelOrdinals */
  updateSeatOrdinals(nodes: SeatNode[], moreNodes?: SeatNode[]): void {
    const models: OrdinalUpdate[] = nodes.map((n: SeatNode, i: number) => new OrdinalUpdate(n.id, i));
    this.http
      .put<OrdinalUpdate[]>(`${this.acApi}/Seats/Ordinals`, { models })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not update the order.  Please try again.')))
      .subscribe();
    if (moreNodes) this.updateSeatOrdinals(moreNodes);
  }

  /** @deprecated - use SeatApiService.updateSeatOrdinals
   * This method is only used in v1 accountability chart (accountability-chart.component.ts) */
  updateSeatModelOrdinals(updates: Update<Pick<SeatModel, 'ordinal'>>[]): Observable<OrdinalUpdate[]> {
    const models: OrdinalUpdate[] = updates.map(u => new OrdinalUpdate(u.id, u.changes.ordinal));
    return this.http.put<OrdinalUpdate[]>(`${this.acApi}/Seats/Ordinals`, { models });
  }

  // Accountabilities ===========================================================

  createAccountabilities(accountabilities: Partial<Accountability>[]): Observable<Accountability[]> {
    return this.http.post<Accountability[]>(`${this.acApi}/Accountabilities`, { accountabilities }).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not create ${this.stateService.language.acChart.rolesAndResponsibilities}.
      Please try again.`
        )
      )
    );
  }

  updateAccountabilities(accountabilities: Partial<Accountability>[]): Observable<Accountability[]> {
    return this.http.patch<Accountability[]>(`${this.acApi}/Accountabilities`, { accountabilities }).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update ${this.stateService.language.acChart.rolesAndResponsibilities}.
       Please try again.`
        )
      )
    );
  }

  deleteAccountabilities(accountabilityIds: string[]): Observable<string[]> {
    return this.http.post<string[]>(`${this.acApi}/Accountabilities/Delete`, accountabilityIds).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not delete ${this.stateService.language.acChart.rolesAndResponsibilities}.
       Please try again.`
        )
      )
    );
  }

  updateAccountabilityOrdinals(accountabilities: Partial<Accountability>[]): Observable<void> {
    const models: OrdinalUpdate[] = accountabilities.map((a: Accountability, i: number) => new OrdinalUpdate(a._id, i));
    return this.http
      .put<void>(`${this.acApi}/Accountabilities/Ordinals`, { models })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update the ${this.stateService.language.acChart.rolesAndResponsibilities} order.  Please try again.`
          )
        )
      );
  }

  // SeatHolders ===========================================================

  /** @deprecated - prefer { @link seatHoldersStateActions.createOne } */
  createSeatHolder(seatHolder: SeatHolderCreatePick): Observable<SeatHolder> {
    return this.seatHolderApiService.createSeatHolder(seatHolder).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not create ${this.stateService.language.acChart.seatHolder}.
        Please try again.`
        )
      )
    );
  }

  /** @deprecated - prefer {@link SeatHolderApiService } or { @link seatHoldersStateActions.updateOne } */
  updateSeatHolder(seatHolderId: string, update: Partial<SeatHolder>, seatId: string): Observable<void> {
    return this.http.patch<void>(`${this.acApi}/${seatHolderId}/SeatHolders`, update).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update ${this.stateService.language.acChart.seatHolder}.
       Please try again.`
        )
      )
    );
  }
}
