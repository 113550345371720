import { createReducer, on } from '@ngrx/store';

import { userListStateActions } from './user-list-actions';
import { userListInitialState, userListStateAdapter } from './user-list-state.model';

export const UserListStateReducer = createReducer(
  userListInitialState,
  on(userListStateActions.getUserListSuccess, (state, { users }) => userListStateAdapter.setAll(users, state)),
  on(userListStateActions.setLoggedInUser, (state, { loggedInUser }) => ({
    ...state,
    loggedInUser,
  })),
  on(userListStateActions.getUserListSuccess, (state, { users }) => userListStateAdapter.setAll(users, state))
);
