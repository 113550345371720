export class Section {
  elementId: string;
  title: string;
  manageeSection?: boolean;

  constructor(elementId, title, manageeSection = false) {
    this.elementId = elementId;
    this.title = title;
    if (manageeSection) this.manageeSection = manageeSection;
  }
}
