import { Component, OnInit, TrackByFunction } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import ObjectID from 'bson-objectid';
import { BehaviorSubject, catchError, filter, of, switchMap, tap } from 'rxjs';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { HelpfulService } from '@ninety/ui/legacy/core/services/helpful.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { SessionService } from '@ninety/ui/legacy/core/services/session.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import type { SuppressionList } from '@ninety/ui/legacy/shared/models/helpful/suppression-list.interface';
import { selectCompanyUserByCompanyId } from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.selectors';
import { selectHasHelpfulPermission } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import {
  CompanyUserListModel,
  CompanyUsersStateActions,
  extractValueFromStore,
  selectAllCompanyUsers,
} from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-helpful',
  templateUrl: './helpful.component.html',
  styleUrls: ['./helpful.component.scss'],
})
export class HelpfulComponent implements OnInit {
  companyUserList$ = this.store.select(selectAllCompanyUsers);

  companyIdToDelete: string;
  companyDeletionResult$ = new BehaviorSubject<string>('');
  foundCompany: CompanyUserListModel;
  newCompanyUser: CompanyUserListModel;
  alreadyInCompany = false;
  companyToFindForm = new UntypedFormControl('', {
    validators: [
      (c: AbstractControl): ValidationErrors =>
        !c.value || ObjectID.isValid(c.value) ? null : { isObjectId: { value: c.value } },
    ],
    updateOn: 'blur',
  });

  suppressionListSearch = '';
  suppressionList$ = new BehaviorSubject<SuppressionList[]>([]);
  fetchingSuppressionList = false;
  readonly canHardDeleteCompany$ = this.store.select(selectHasHelpfulPermission('hardDeleteCompany'));
  constructor(
    private spinnerService: SpinnerService,
    private store: Store,
    public stateService: StateService,
    private companyService: CompanyService,
    private userService: UserService,
    private filterService: FilterService,
    public sessionService: SessionService,
    public helpfulService: HelpfulService,
    private notifyService: NotifyService,
    public helperService: HelperService,
    public dialog: MatDialog
  ) {
    this.filterService.setOptions({ filtersToolbar: false });
  }

  ngOnInit(): void {
    this.spinnerService.stop();
  }

  findCompanyById(companyId: string) {
    if (!ObjectID.isValid(companyId)) return;

    const companyUser = extractValueFromStore(
      this.store,
      selectCompanyUserByCompanyId(companyId)
    ) as CompanyUserListModel;
    if (companyUser) {
      this.alreadyInCompany = true;
      this.foundCompany = companyUser;
      return;
    }

    this.companyService.getCompanyById(companyId).subscribe({
      next: company => {
        this.foundCompany = {
          userId: null,
          companyId: company._id,
          accountStatus: company.accountStatus,
          companyName: company.name,
          logoUrl: company.logo.url,
          bos: company.bos,
        };
        this.alreadyInCompany = false;
        this.newCompanyUser = null;
        this.spinnerService.stop();
      },
    });
  }

  removeFromSuppressionList(email: string) {
    this.spinnerService.start();
    this.helpfulService.removeFromSuppressionList(email).subscribe({
      next: () => {
        this.suppressionList$.next(this.suppressionList$.value.filter(({ EmailAddress }) => EmailAddress !== email));
        this.spinnerService.stop();
      },
      // eslint-disable-next-line
      error: (err: any) => {
        this.notifyService.showError(err, 'Suppression list removal failure');
      },
    });
  }

  addHelpful() {
    this.userService.addHelpfulToCompany(this.foundCompany.companyId).subscribe({
      next: cu => {
        const entity: CompanyUserListModel = {
          companyId: cu.company._id,
          userId: cu._id,
          accountStatus: cu.company.accountStatus,
          companyName: cu.company.name,
          logoUrl: cu.company.logo.url,
          bos: cu.company.bos,
        };
        this.store.dispatch(CompanyUsersStateActions.addOne({ entity: entity }));
        this.newCompanyUser = entity;
      },
    });
  }

  removeHelpfulFromCompany(companyUser: CompanyUserListModel) {
    this.spinnerService.start();
    this.userService.update({ deleted: true }, companyUser.userId).subscribe({
      next: () => {
        this.store.dispatch(CompanyUsersStateActions.removeOne({ userId: companyUser.userId }));
        this.spinnerService.stop();
      },
    });
  }

  trackById: TrackByFunction<CompanyUserListModel> = (_i: number, user: CompanyUserListModel): string => user.userId;
  trackByEmail: TrackByFunction<SuppressionList> = (_i: number, item: SuppressionList): string => item.EmailAddress;

  searchSuppressionList(email: string) {
    this.suppressionList$.next([]);
    this.fetchingSuppressionList = true;
    this.helpfulService.getSuppressionList(email).subscribe({
      next: list => {
        this.suppressionList$.next(list);
        this.fetchingSuppressionList = false;
      },
      // eslint-disable-next-line
      error: (err: any) => {
        this.notifyService.showError(err, 'Get suppression list failure');
        this.fetchingSuppressionList = false;
      },
    });
  }

  openDeleteCompanyDialog(): void {
    this.dialog
      .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
        data: {
          title: `Hard Delete Company ${this.companyIdToDelete}?`,
          message: '<strong>Caution:</strong> This action cannot be undone.',
          confirmButtonText: 'I understand... Delete Anyway',
        },
      })
      .afterClosed()
      .pipe(
        filter(shouldDelete => shouldDelete),
        switchMap(() => this.helpfulService.hardDeleteCompany(this.companyIdToDelete)),
        tap(deleteResult => {
          this.companyDeletionResult$.next(deleteResult);
        }),
        // eslint-disable-next-line
        catchError((err: any) => {
          this.notifyService.showError(err);
          return of(err);
        })
      )
      .subscribe();
  }
}
