<button
  #trigger
  class="terra-select__trigger"
  [disabled]="disabled"
  [ngClass]="{
    'terra-select__trigger--invalid': _terraErrorStateMatcher.isErrorState(ngControl),
    'terra-select__trigger--icons-layout': layout === 'icons',
  }"
  [attr.aria-label]="placeholder || (value && value.length > 0) ? null : ariaLabel || null"
  [attr.aria-labelledby]="ariaLabelledby"
  role="combobox"
  [tabIndex]="disabled ? -1 : _tabIndex"
  [attr.aria-controls]="_selectId"
  [attr.aria-expanded]="isSelectOpen"
  [attr.aria-activedescendant]="_activeDescendant || null"
  aria-haspopup="listbox"
  (click)="toggle()"
  (focus)="_focused()"
  (blur)="_blurred()">
  <ng-container *ngIf="layout === 'list'; else iconTrigger">
    <ng-template *ngIf="_prefixTemplate" [ngTemplateOutlet]="_prefixTemplate" />
    <div class="terra-select__placeholder" *ngIf="placeholder && !_label; else label">{{ placeholder }}</div>
    <ng-template #label>
      <div class="terra-select__label">
        {{ _label }}
      </div>
    </ng-template>
    <ng-template *ngIf="_suffixTemplate" [ngTemplateOutlet]="_suffixTemplate" />
  </ng-container>
  <ng-template #iconTrigger>
    <div class="terra-select__icon-placeholder" *ngIf="!_iconTemplate">-</div>
    <div class="terra-select__icon-trigger">
      <ng-template *ngIf="_iconTemplate" [ngTemplateOutlet]="_iconTemplate" />
    </div>
  </ng-template>
  <terra-icon
    class="terra-select__trigger-caret"
    [icon]="(openedChange | async) ? 'caret-up' : 'caret-down'"
    size="16"
    variant="bold" />
</button>

<ng-template #selectPanel>
  <div
    *ngIf="layout === 'list'"
    tabindex="-1"
    [attr.id]="_selectId"
    [attr.aria-multiselectable]="multiple"
    role="listbox"
    [@transformPanel]="_panelAnimationState"
    class="terra-select__panel terra-select__panel--list">
    <ng-content select="terra-option, terra-divider" />
  </div>
  <div
    *ngIf="layout === 'icons'"
    tabindex="-1"
    [attr.id]="_selectId"
    [attr.aria-multiselectable]="multiple"
    role="listbox"
    [@transformPanel]="_panelAnimationState"
    class="terra-select__panel terra-select__panel--grid">
    <ng-content select="terra-icon-option" />
  </div>
</ng-template>
