<div class="ninety-menu" cdkMenu>
  <!-- Section in data -->
  <ng-container *ngIf="isOfTypeSectionData">
    <span *ngFor="let item of dataSourceWithSection; index as i">
      <div *ngIf="item.title" class="menu-section-container">
        <span class="menu-section">{{ item.title }}</span>
      </div>
      <ng-container *ngFor="let cell of item.data">
        <ninety-menu-item-standard [item]="cell"></ninety-menu-item-standard>
      </ng-container>
      <ninety-divider-menu-item *ngIf="i < dataSourceWithSection.length - 1" />
    </span>
  </ng-container>
  <!-- No Section if Data -->
  <ng-container *ngIf="!isOfTypeSectionData">
    <span *ngFor="let item of dataSourceMenuItem" (click)="item.action(item)" ninetyMenuRow cdkMenuItem>
      <span class="menu-cell prefix-cell-layout">
        <span *ngIf="item.icon" class="icon">
          <terra-icon [icon]="item.icon.name" [size]="item.icon.size" [variant]="item.icon.variant" />
        </span>
        <span>{{ item.label }}</span>
      </span>
      <span class="menu-cell suffix-cell-layout">
        <span>{{ item.suffix }}</span>
      </span>
    </span>
  </ng-container>
  <ng-content></ng-content>
</div>
