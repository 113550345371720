import { routerReducer } from '@ngrx/router-store';
import { RouterReducerState } from '@ngrx/router-store/src/reducer';
import { ActionReducerMap } from '@ngrx/store';

import { LoggingEffects } from './app-logging/app-logging.effects';
import { AppEffects } from './app.effects';

/**
 * This is the Global root state declared in storeModule.forRoot() in app.module.ts
 *
 * It is rare that anything should be added to this store.  If you find yourself in need of
 * adding a new prop here, please review app-global, app-entites, and app-layout.
 * This is where most new global state should be registered or in a domain specific module.
 *
 * Any questions can be directed to the senior engineers.
 */

export interface rootState {
  router: RouterReducerState;
}

export const globalReducers: ActionReducerMap<rootState> = {
  router: routerReducer,
};

export const globalStateEffects = [LoggingEffects, AppEffects];
