import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { selectHasPartnerPermissions } from '../../_state/app-global/helpful-permissions/helpful-permissions.selectors';

@Injectable({
  providedIn: 'root',
})
export class PartnersPermissionsGuard {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectHasPartnerPermissions)
      .pipe(map(hasPermission => hasPermission || this.router.parseUrl('/')));
  }
}
