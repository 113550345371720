import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LearningActionToolbarActions = createActionGroup({
  source: 'Learning-Action-Toolbar-Actions',
  events: {
    'Save Learning Task': props<{ navBackAfterSavingTask: boolean }>(),
    'Reset To Default': emptyProps(),
    'Task Details Modified': props<{ isModified: boolean }>(),
    'Reset Task Update Success': emptyProps(),
    'Save Learning Item': props<{ navBackAfterSaving: boolean }>(),
    'Details Modified': props<{ isModified: boolean }>(),
    'Reset Update Success': emptyProps(),
  },
});
