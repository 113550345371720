import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HeadlinesStateEffects } from '@ninety/headlines/_state/headlines/headlines-state.effects';
import { IntegrationService } from '@ninety/settings/user/integration/_api/integration.service';
import { TodoDetailEffects } from '@ninety/todos/_state/detail/todo-detail.effects';
import { todoReducerMap, TodoRootStateKey } from '@ninety/todos/_state/index';
import { PersonalTodoEffects } from '@ninety/todos/_state/personal/personal-todo.effects';
import { PubnubTodoEffects } from '@ninety/todos/_state/pubnub/pubnub-todo.effects';
import { TeamTodoEffects } from '@ninety/todos/_state/team/team-todo.effects';

import { TeamTodoFacade } from './team/team-todo.facade';

/**
 * Configures NGRX for the todos module.
 *
 * Why separate and not in TodoModule? Need to import it in My90, but don't want the baggage of the TodoModule
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(TodoRootStateKey, todoReducerMap),
    EffectsModule.forFeature([
      TeamTodoEffects,
      PersonalTodoEffects,
      PubnubTodoEffects,
      TodoDetailEffects,
      HeadlinesStateEffects,
    ]),
    MatDialogModule,
  ],
  providers: [IntegrationService, TeamTodoFacade],
})
export class TodoStateModule {}
