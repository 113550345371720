<mat-toolbar class="sticky-toolbar">
  <div class="fill-remaining-space"></div>
  <ninety-button
    filled
    primary
    (click)="save()"
    aria-label="Save"
    [disabled]="!hasChanges"
    data-product="user-preferences__save-changes-button"
    data-cy="preferences_save-btn">
    <terra-icon icon="save" />
    Save Changes
  </ninety-button>
</mat-toolbar>

<mat-card class="ninety-card">
  <mat-card-header>
    <mat-card-title>Theme</mat-card-title>
    <mat-card-subtitle>View your workspace in either classic (light) or dual (high contrast) mode.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-radio-group
      class="radio-group"
      (change)="themeChanged()"
      [(ngModel)]="settings.theme"
      (ngModelChange)="checkForChanges()">
      <mat-radio-button
        data-cy="preferences_theme-radio-light"
        class="radio-btn"
        value="light"
        data-product="user-preferences__theme-light-radio-button">
        <div class="theme-button-title">Light</div>
        <div class="theme-description">A light workspace for maximum clarity.</div>
      </mat-radio-button>
      <mat-radio-button
        data-cy="preferences_theme-radio-dual"
        class="radio-btn dual-button"
        value="dual"
        data-product="user-preferences__theme-dual-radio-button">
        <div class="theme-button-title">Dual</div>
        <div class="theme-description">A contrasting side navigation for improved visual hierarchy.</div>
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-content>
</mat-card>

<mat-card class="ninety-card">
  <mat-card-header>
    <mat-card-title>Preferred Date Format</mat-card-title>
    <mat-card-subtitle>Choose the way dates get displayed across the app.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-radio-group
      aria-labelledby="locale-label"
      class="radio-group"
      [(ngModel)]="settings.preferredLocale"
      (ngModelChange)="checkForChanges()">
      <mat-radio-button class="radio-btn" [value]="Locale.default" [attr.data-cy]="'preferences-m_d_y'">
        {{ today | date : 'M/d/yyyy' }}
        | {{ today | date : 'longDate' : undefined : 'en-US' }} | {{ today | date : 'MMM d' }}
      </mat-radio-button>
      <mat-radio-button class="radio-btn" [value]="Locale.greatBritain" [attr.data-cy]="'preferences-d_m_y'">
        {{ today | date : 'dd/MM/yyyy' }}
        | {{ today | date : 'longDate' : undefined : 'en-GB' }} | {{ today | date : 'd MMM' }}
      </mat-radio-button>
      <mat-radio-button class="radio-btn" [value]="Locale.canada" [attr.data-cy]="'preferences-y_m_d'">
        {{ today | date : 'yyyy-MM-dd' }}
        | {{ today | date : 'longDate' : undefined : 'en-CA' }} | {{ today | date : 'MMM d' }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-content>
</mat-card>

<mat-card class="ninety-card">
  <mat-card-header>
    <mat-card-title>Default Team</mat-card-title>
    <mat-card-subtitle>This is the team that gets selected when you log in to Ninety.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="outline" class="default-team" data-cy="preferences_select-team">
      <mat-select
        [(ngModel)]="settings.defaultTeamId"
        (selectionChange)="checkForChanges()"
        placeholder="None"
        data-cy="preferences_default-team">
        <mat-option [value]="null" data-cy="preferences_team-option-none">None</mat-option>
        <terra-divider />
        <mat-option
          *ngFor="let team of teamService.userTeams"
          [value]="team._id"
          attr.data-cy="preferences_team-option-{{ team.name }}">
          {{ team.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>

<mat-card class="ninety-card">
  <mat-card-header>
    <mat-card-title>Preferred Timezone</mat-card-title>
    <mat-card-subtitle> Select a timezone for your account to be used for timing emails. </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="automatic-timezone-wrapper">
      <terra-checkbox
        data-cy="preferences_set-timezone-automatically-checkbox"
        type="checkbox"
        [ngModel]="settings.setTimezoneAutomatically"
        (change)="setTimeZoneToBrowserTimeZone($event)"
        ninetyStopPropagation>
        Set timezone automatically
      </terra-checkbox>
    </div>

    <ninety-timezone-select
      (selectTimezoneEvent)="changeTimezone($event)"
      (triggerSaveForFirstChangeEvent)="triggerSaveForFirstChange($event)"
      [defaultUserTimezoneName]="settings.timezone"
      [shouldAutoSelectTimeZoneFromBrowser]="settings.setTimezoneAutomatically">
    </ninety-timezone-select>
  </mat-card-content>
</mat-card>
