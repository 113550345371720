import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Score } from '@ninety/scorecard-v2/shared/models/score.model';
import { ScorecardRow } from '@ninety/scorecard-v2/shared/scorecard-row.model';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';

import { ScorecardApiErrorProps } from '../../shared/types';

export const ScorecardPageActions = createActionGroup({
  source: 'Scorecard V2 - Page',
  events: {
    Init: emptyProps(),
    Destroy: emptyProps(),

    'Init Scorecard Id': props<{ scorecardId: string }>(),
    'Set Scorecard Id': props<{ scorecardId: string }>(),

    'Fetch By Team Id': props<{ teamId: string }>(),

    Create: emptyProps(),
    'Create Success': emptyProps(),
    'Create Failure': props<ScorecardApiErrorProps>(),

    Update: emptyProps(),
    'Update Success': emptyProps(),
    'Update Failure': props<ScorecardApiErrorProps>(),

    'Open Create Dialog': emptyProps(),
    'Cancel Create Dialog': emptyProps(),

    'Open Update Dialog': emptyProps(),
    'Cancel Update Dialog': emptyProps(),

    'Open Universal Create Dialog': props<{
      kpiGroupId: string;
      kpiId: string;
      itemType: Extract<ItemType, ItemType.issue | ItemType.todo>;
    }>(),

    'Open Universal Create Dialog Custom Goal': props<{
      score: Score;
      kpiId: string;
      columnLabel: string;
      itemType: ItemType.issue;
    }>(),

    'Open Kpi Future Goal Dialog': props<{ kpiGroupId: string; kpiId: string }>(),
    'Open Custom Goal Dialog': props<{
      score: Score;
      row: ScorecardRow;
      columnLabel: string;
      kpiGroupId: string;
      kpiId: string;
    }>(),
  },
});
