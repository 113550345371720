import { createFeatureSelector } from '@ngrx/store';

import { AppGlobalState } from './app-global-state.module';

// These are declared here to prevent circular references that are very difficult to debug
export const AppGlobalStateKey = 'app-global';
export const CompanyStateKey = 'company';
export const LanguageStateKey = 'language';
export const RealTimeStateKey = 'real-time';
export const HelpfulPermissionsKey = 'helpfulPermissions';
export const PoliciesStateKey = 'policies';
export const CurrentPersonStateKey = 'currentPerson';
export const InteractionsStateKey = 'interactions';
export const AnonymousStateKey = 'anonymous';
export const FollowersStateKey = 'followers';
export const SpinnerStateKey = 'spinner';
export const ViewportStateKey = 'viewport';

export const selectGlobalAppState = createFeatureSelector<AppGlobalState>(AppGlobalStateKey);
