<ng-container *ngIf="itemType >= 0">
  <!-- appearance="none" is not a valid value for appearance but it removes underline
		without any CSS hacks, need to cast as any for typescript to not complain -->
  <mat-form-field *ngIf="!disabled; else readonly" [appearance]="$any('none')">
    <mat-select
      data-cy="item-type-select_type-selector"
      #itemTypeSelect
      class="item-type-select"
      [value]="itemType"
      [disabled]="disabled"
      (selectionChange)="onSelection(itemTypeSelect.value)">
      <mat-option
        data-cy="item-type-select_issue-option"
        [value]="itemTypeEnum.issue"
        *ngIf="!stateService.isLiteUser"
        >{{ stateService.language.issue.item }}</mat-option
      >
      <mat-option data-cy="item-type-select_todo-option" [value]="itemTypeEnum.todo">{{
        stateService.language.todo.item
      }}</mat-option>
      <mat-option data-cy="item-type-select_rock-option" [value]="itemTypeEnum.rock">{{
        stateService.language.rock.item
      }}</mat-option>
      <mat-option
        data-cy="item-type-select_headline-option"
        [value]="itemTypeEnum.headline"
        *ngIf="!stateService.isLiteUser"
        >{{ stateService.language.headline.item }}</mat-option
      >
      <mat-option
        data-cy="item-type-select_cascaded-message-option"
        [value]="itemTypeEnum.cascadedMessage"
        *ngIf="!stateService.isLiteUser || meetingService.currentMeeting?.inProgress || showCascadedOption">
        {{ stateService.language.cascadingMessage.item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-template #readonly>
    <div class="item-type readonly">{{ itemType | itemLabelByType }}</div>
  </ng-template>
</ng-container>
