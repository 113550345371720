<h1 class="bottom-space-30" mat-dialog-title>Delete Repeating {{ stateService.language.todo.item }}</h1>
<mat-dialog-content>
  <div class="content">
    <label id="delete-label"
      >This {{ stateService.language.todo.item }} is scheduled to repeat {{ data.todo.repeat | lowercase }}. Do you want
      to delete only this {{ stateService.language.todo.item }} or all repeating {{ stateService.language.todo.items }}?
    </label>
    <mat-radio-group aria-labelledby="What-to-delete" class="radio-group" [(ngModel)]="deleteOption">
      <mat-radio-button class="radio-btn" [value]="TodoDeleOptions.item">
        Delete only this {{ stateService.language.todo.item }}
      </mat-radio-button>
      <mat-radio-button class="radio-btn" [value]="TodoDeleOptions.series">
        Delete all repeating {{ stateService.language.todo.items }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <ninety-button stroked (click)="dialogRef.close()">Cancel</ninety-button>
  <ninety-button filled primary (click)="delete()" ninetyStopPropagation class="save-button">Delete</ninety-button>
</mat-dialog-actions>
