import { createReducer, on } from '@ngrx/store';

import { FitCheckComponentActions, FitCheckEffectActions, FitCheckFilterEffectActions } from './fit-check.actions';
import { FitCheckState, initialFitCheckState, initialPagerState } from './fit-check.model';

export const fitCheckReducer = createReducer<FitCheckState>(
  initialFitCheckState,

  on(
    FitCheckComponentActions.initialized,
    (state): FitCheckState => ({
      ...state,
      filter: {
        ...state.filter,
        searchText: null,
      },
      pager: {
        ...initialPagerState,
        length: state.pager.length,
      },
    })
  ),

  on(
    FitCheckEffectActions.setCurrentUser,
    (state, { currentUser, isLiteUser, isObserver }): FitCheckState => ({
      ...state,
      currentUser,
      isLiteUser,
      isObserver,
    })
  ),

  on(
    FitCheckEffectActions.usersAndCoreValuesReceived,
    (state, { users, coreValues }): FitCheckState => ({
      ...state,
      coreValues,
      users: users.items,
      pager: {
        ...state.pager,
        length: users.totalCount,
      },
    })
  ),

  on(
    FitCheckEffectActions.detailViewOpened,
    (state, { user }): FitCheckState => ({
      ...state,
      selectedUser: user,
    })
  ),

  on(
    FitCheckEffectActions.userDeselected,
    (state): FitCheckState => ({
      ...state,
      selectedUser: null,
    })
  ),

  on(
    FitCheckFilterEffectActions.searchTextUpdated,
    (state, action): FitCheckState => ({
      ...state,
      selectedUser: null,
      filter: {
        ...state.filter,
        searchText: !action.searchText?.trim() ? null : action.searchText,
      },
      pager: {
        ...state.pager,
        pageIndex: 0,
      },
    })
  ),

  on(
    FitCheckFilterEffectActions.selectedUserFilterUpdated,
    (state, action): FitCheckState => ({
      ...state,
      selectedUser: null,
      filter: {
        ...state.filter,
        selectedUserId: action.selectedUserId,
      },
      pager: {
        ...state.pager,
        pageIndex: 0,
      },
    })
  ),

  on(
    FitCheckFilterEffectActions.selectedTeamFilterUpdated,
    (state, action): FitCheckState => ({
      ...state,
      selectedUser: null,
      filter: {
        ...state.filter,
        selectedTeamId: action.selectedTeamId,
      },
      pager: {
        ...state.pager,
        pageIndex: 0,
      },
    })
  ),

  on(
    FitCheckFilterEffectActions.isFetchingSet,
    (state, { isFetchingData }): FitCheckState => ({
      ...state,
      filter: {
        ...state.filter,
        isFetchingData,
      },
    })
  ),

  on(
    FitCheckComponentActions.pagerChanged,
    (state, { pageIndex, pageSize }): FitCheckState => ({
      ...state,
      pager: {
        ...state.pager,
        pageIndex,
        pageSize,
      },
    })
  )
);
