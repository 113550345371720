import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TemplateChooserDirective } from './directives/template-chooser.directive';
import { TemplateIfDirective } from './directives/template-if.directive';

@NgModule({
  declarations: [TemplateIfDirective, TemplateChooserDirective],
  exports: [TemplateChooserDirective, TemplateIfDirective],
  imports: [CommonModule],
})
export class TemplateSelectModule {}
