import { DefaultUrlSerializer } from '@angular/router';
import { createSelector } from '@ngrx/store';
import ObjectId from 'bson-objectid';

import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';

import { selectHeadlinesModule } from '..';

import { HeadlineStateKey, headlinesInitialState, headlinesStateAdapter } from './headlines-state.model';

const { selectAll } = headlinesStateAdapter.getSelectors();
export const selectHeadlinesStateModel = createSelector(selectHeadlinesModule, state =>
  state && state[HeadlineStateKey] ? state[HeadlineStateKey] : headlinesInitialState
);

export const selectHeadlines = createSelector(selectHeadlinesStateModel, selectAll);
export const selectFilterBy = createSelector(selectHeadlinesStateModel, state => state.filters);
export const selectIsSearching = createSelector(selectHeadlinesStateModel, state => !!state.filters.searchText);
export const selectFilterByArchived = createSelector(selectHeadlinesStateModel, state => state.filters.archived);
export const selectFilterByTeamId = createSelector(selectHeadlinesStateModel, state => state.filters.teamId);

export const selectHeadlineById = (id: string) =>
  createSelector(selectHeadlinesStateModel, state => state.entities[id]);

export const selectSelectedId = createSelector(selectHeadlinesStateModel, state => state.selectedId);

export const selectSelectedHeadline = createSelector(
  selectHeadlinesStateModel,
  selectSelectedId,
  (state, selectedId) => state.entities[selectedId]
);

export const selectTotalCount = createSelector(selectHeadlinesStateModel, state => state.totalCount);
export const selectPagination = createSelector(selectHeadlinesStateModel, state => state.pagination);

/** @deprecated - use selectSortOrNull, rename to selectSort when all selectSort references are removed*/
export const selectSort = createSelector(selectHeadlinesStateModel, state => state.sort);
export const selectSortOrNull = createSelector(selectHeadlinesStateModel, state =>
  state.sort.direction ? state.sort : null
);
export const selectLoading = createSelector(selectHeadlinesStateModel, state => state.loading);
export const selectError = createSelector(selectHeadlinesStateModel, state => state.error);

export const selectFocusOnInlineAdd = createSelector(selectHeadlinesStateModel, state => state.focusOnInlineAdd);
export const selectListControlsDisabled = createSelector(
  selectHeadlinesStateModel,
  state => state.listControlsDisabled
);

export const selectGetHeadlineQueryParams = createSelector(
  selectFilterBy,
  selectPagination,
  selectSort,
  (filterBy, pagination, sort) => ({
    pageIndex: pagination.index,
    pageSize: pagination.size,
    ...(sort.direction != null ? { sortField: sort.field, sortDirection: sort.direction } : null),
    ...(filterBy.teamId && filterBy.teamId !== 'all' ? { teamId: filterBy.teamId } : null),
    archived: filterBy.archived,
    includeDiscussed: filterBy.includeDiscussed,
    //TODO NEXT: when old headlines are removed, remove includeDiscussed and only rely on inMeetingId, unless we find another use case
    //inMeetingId = _id, load not discussed + discussed in meetingId
    //inMeetingId = null, load including discussed
    inMeetingId: filterBy.inMeetingId,
    searchText: filterBy.searchText,
  })
);

export const selectTemplateType = createSelector(
  selectLoading,
  selectError,
  selectTotalCount,
  selectFilterBy,
  (loading, error, totalCount, filterBy) => {
    if (loading) {
      return WidgetTemplateType.loading;
    }
    if (error) {
      return WidgetTemplateType.error;
    }
    if (filterBy.searchText && totalCount === 0) {
      return WidgetTemplateType.noQueryResults;
    }
    if (totalCount === 0 && filterBy.archived) {
      return WidgetTemplateType.archive;
    }
    if (totalCount === 0) {
      return WidgetTemplateType.empty;
    }
    return WidgetTemplateType.data;
  }
);

export const selectHeadlineIdFromDetailRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (parsedUrl.root.children.detail?.segments[1].path === DetailType.headline)
    return parsedUrl.root.children.detail.segments[2].path;
  return null;
});

export const selectHeadlineIdFromRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (parsedUrl.root.children?.primary?.segments[0].path === 'headlines')
    return parsedUrl.root.children.primary.segments[1]?.path;
  return null;
});

//TODO NEXT: discuss - get if on conclude from the meeting state, see currentSection
export const selectOnMeetingConcludeSection = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  return (
    parsedUrl.root.children.primary?.segments[0].path === 'meeting' &&
    parsedUrl.root.children.primary?.segments[3]?.path === 'conclude'
  );
});

export const selectInMeetingViewToolHeadlines = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  const [meetingSegment, meetingIdSegment, sectionSegment] = parsedUrl.root.children.primary?.segments;
  return (
    meetingSegment.path === 'meeting' && sectionSegment.path === 'headlines' && ObjectId.isValid(meetingIdSegment.path)
  );
});
