import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AuthOptions, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types';
import { BehaviorSubject, from, Observable, throwError, of, NEVER, Subject, EMPTY } from 'rxjs';
import { map, tap, catchError, switchMap, filter } from 'rxjs/operators';

import { environment } from '@ninety/ui/web/environments';

import { ICognitoAuthErrorResponse, ICognitoUser } from '../../_shared/models/_shared/cognito-user';
import { SignUp } from '../../_shared/models/_shared/sign-up';
import { CognitoUserStatus } from '../../_shared/models/cognito/cognito-user-status.enum';

import { ErrorService } from './error.service';
import { SpinnerService } from './spinner.service';

export enum CognitoStates {
  forcePasswordChange = `NEW_PASSWORD_REQUIRED`,
  confirmEmail = `UserNotConfirmedException`,
}

// would be nice to get these from amplify lib, but they dont have them
// https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/Auth.ts#L800
export enum CognitoMfaType {
  NONE = 'NOMFA',
  SMS = 'SMS_MFA',
}

export enum MfaStatus {
  NoTelNumber = 'UPDATE_NUMBER',
  UnverifiedTelNumber = 'CONFIRM_NUMBER',
  ChallengeMFA = 'CHALLENGE_MFA',
  None = 'NONE',
}

export enum MfaPreference {
  SMS = 'SMS_MFA',
  TOTP = 'SOFTWARE_TOKEN_MFA',
}

export enum RedirectType {
  SIGN_UP = 'sign-up',
  ACCEPT_INVITE = 'accept-invite',
}

const options = {
  region: environment.region,
  userPoolId: environment.userPoolId,
  userPoolWebClientId: environment.userPoolWebClientId,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  // flow type for active user migration is 'USER_PASSWORD_AUTH'
  authenticationFlowType: environment.authenticationFlowType,

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  // clientMetadata: { myCustomKey: 'myCustomValue' ),

  // federatedSignIn options
  aws_project_region: 'us-east-1',
  aws_user_pools_web_client_id: environment.userPoolWebClientId,
  aws_user_pools_id: environment.userPoolId,
  aws_cognito_region: 'us-east-1',
  oauth: {
    domain: environment.cognitoDomain,
    region: 'us-east-1',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${environment.redirectProtocol}://${window.location.host}/login/`,
    redirectSignOut: `${environment.redirectProtocol}://${window.location.host}/login/`,
    responseType: 'token',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

const cognitoDomain = environment.cognitoDomain;
const redirectProtocol = environment.redirectProtocol;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let amplifyConfig: AuthOptions;

// Is there any reason to use something other than window.location.protocol for the redirectProtocol
//  in this cognito authorize call? If not it would save some configuration if we didn't set it in
//  the build time environment.
// A redirect_uri MUST match what is defined in the cognito backend, otherwise fails
const getFederationUrl = (provider: CognitoHostedUIIdentityProvider, redirectType: RedirectType) =>
  // eslint-disable-next-line max-len
  `https://${cognitoDomain}/oauth2/authorize?redirect_uri=${redirectProtocol}://${window.location.host}/${redirectType}/&response_type=token&client_id=${options.userPoolWebClientId}&identity_provider=${provider}`;

const setupAmplifyAuth = (): void => {
  amplifyConfig = Auth.configure(options);
};

const setupAmplifyAuthWithPassword = (): void => {
  const _options = Object.assign({}, options);
  _options.authenticationFlowType = 'USER_PASSWORD_AUTH';
  Auth.configure(_options);
};

@Injectable({
  providedIn: 'root',
})
export class IdentityProviderService {
  public cognitoUser$ = new BehaviorSubject<ICognitoUser>(null);
  public cognitoAuthError$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private router: Router,
    private _spinnerService: SpinnerService
  ) {
    /**
     * This is a temporary fix for the open Amplify issue linked below. Amplify intercepts any
     * request with the URL query parameter "code" and assumes it's part of their auth flow.
     * https://github.com/aws-amplify/amplify-js/issues/9208
     * */
    this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe({
      next: (ev: NavigationEnd) => {
        if (!ev.url.includes('/settings/user/integrations')) {
          setupAmplifyAuth();
        }
      },
    });
  }

  // TODO: use cognitoUser$ in this service rather than this.
  // BUT have to make sure currentAuthenticatedUser is called at appropriate times(?)
  get cognitoUser(): Observable<ICognitoUser> {
    return from<Promise<ICognitoUser>>(Auth.currentAuthenticatedUser()).pipe(
      tap(user => this.cognitoUser$.next(user)),
      catchError((err: unknown) => {
        console.warn('Could not get currentAuthenticatedUser: ', err);
        return this.refreshAuthenticatedUser();
      })
    );
  }

  get mfaPreference(): Observable<CognitoMfaType> {
    return this.cognitoUser.pipe(switchMap(user => from(Auth.getPreferredMFA(user)) as Observable<CognitoMfaType>));
  }

  refreshAuthenticatedUser() {
    return from<Promise<ICognitoUser>>(Auth.currentAuthenticatedUser({ bypassCache: true })).pipe(
      tap(user => {
        this.cognitoUser$.next(user);
      }),
      catchError((err: unknown) => {
        console.error('Could not get refreshAuthenticatedUser: ', err);
        return EMPTY;
      })
    );
  }

  federatedSignIn(provider: CognitoHostedUIIdentityProvider) {
    return from(Auth.federatedSignIn({ provider }));
  }

  signIn(username: string, password: string): Observable<string> {
    return from(Auth.signIn(username, password)).pipe(
      tap(user => {
        this.cognitoUser$.next(user);
        // challenge id with SMS
        if (user.challengeName) throw new Error(user.challengeName);
      }),
      map(user => this.getIdTokenFromUser(user))
    );
  }

  /**
   * Verifies that the supplied username/password belong to an existing Cognito user.
   */
  verifyUser(username: string, password: string): Observable<ICognitoUser | ICognitoAuthErrorResponse> {
    return from(Auth.signIn(username, password));
  }

  // use USER_PASSWORD_AUTH configuration when migrating a user so we don't have to verify their email first
  signInWithMigration(username: string, password: string): Observable<string> {
    setupAmplifyAuthWithPassword();

    // same thing as signIn() but switch back to default options
    return this.signIn(username, password).pipe(
      tap(() => setupAmplifyAuth()),
      catchError((err: unknown) => {
        setupAmplifyAuth();
        return throwError(err);
      })
    );
  }

  signUp(signup: SignUp): Observable<CognitoUser> {
    return from(Auth.signUp(signup.toCognitoSignupParams())).pipe(map(result => result.user));
  }

  signOut(): Observable<any> {
    return from(Auth.signOut());
  }

  confirmSignin(code: string, mfaChoice: MfaPreference = MfaPreference.SMS): Observable<string> {
    return from(Auth.confirmSignIn(this.cognitoUser$.value, code, mfaChoice)).pipe(map(this.getIdTokenFromUser));
  }

  confirmSignup(username: string, code: string): Observable<CognitoUser> {
    return from(Auth.confirmSignUp(username, code));
  }

  /**
   * Creates an unconfirmed cognito user with the given `newEmail` and a
   * temporary password, and copies over any existing attributes from
   * the old user (`oldEmail`).
   */
  createTempCognitoUser(newEmail: string): Observable<CognitoUserStatus> {
    return this.http.post<{ status: CognitoUserStatus }>('/api/v4/Cognito/User', { newEmail }).pipe(
      map(({ status }) => status),
      catchError((e: unknown) => this.errorService.notify(e, e.toString() || 'Could not create temp cognito user'))
    );
  }

  /**
   * Completes the process of the Primary Email change. This endpoint saves
   * the user's existing password to the new Cognito user, and deletes the old one.
   */
  finalizeNewCognitoEmail(newEmail: string, password: string): Observable<string> {
    return this.http.patch('/api/v4/Cognito/Email', { newEmail, password }).pipe(
      switchMap(() => this.signIn(newEmail, password)),
      catchError((e: unknown) => this.errorService.notify(e, e.toString() || 'Could not update primary email'))
    );
  }

  resendSignup(username: string): Observable<string> {
    return from(Auth.resendSignUp(username));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.cognitoUser$.pipe(switchMap(user => from(Auth.changePassword(user, oldPassword, newPassword))));
  }

  completeNewPassword(password: string): Observable<any> {
    // completeNewPassword returns bool
    return this.cognitoUser$.pipe(
      switchMap(user =>
        from(Auth.completeNewPassword(user, password))
          // amplify auth lib is hot garbage and always errors here when it doesn't need to, ignore and move on
          .pipe(catchError(() => of(null)))
      )
    );
  }

  updateTelNumber(tel: string): Observable<string> {
    // TODO use country code
    return this.cognitoUser.pipe(
      switchMap(user =>
        from(Auth.updateUserAttributes(user, { phone_number: tel })).pipe(
          switchMap(resp =>
            this.refreshAuthenticatedUser().pipe(
              tap(u => this.cognitoUser$.next(u)),
              map(() => resp)
            )
          )
        )
      )
    );
  }

  resendSmsCode(): Observable<void> {
    return from(Auth.verifyCurrentUserAttribute('phone_number'));
  }

  verifyTelNumber(): Observable<void> {
    return this.cognitoUser.pipe(switchMap(user => from(Auth.verifyUserAttribute(user, 'phone_number'))));
  }

  submitVerifyTelNumber(code: string): Observable<string> {
    return this.cognitoUser.pipe(
      switchMap(user => from(Auth.verifyUserAttributeSubmit(user, 'phone_number', code))),
      switchMap(resp => this.refreshAuthenticatedUser().pipe(map(() => resp)))
    );
  }

  setMfaType(mfaType: CognitoMfaType): Observable<MfaStatus> {
    return this.cognitoUser.pipe(
      filter(u => !!u),
      switchMap((user: CognitoUser & ICognitoUser) => {
        const hasPhoneNumber = !!user.attributes.phone_number?.length;
        const phoneNumberIsVerified = user.attributes.phone_number_verified;

        switch (mfaType) {
          case CognitoMfaType.SMS:
            if (hasPhoneNumber) {
              if (!phoneNumberIsVerified) return of(MfaStatus.UnverifiedTelNumber);
              return from(Auth.setPreferredMFA(user, mfaType)).pipe(
                switchMap(() => this.refreshAuthenticatedUser()),
                map(() => MfaStatus.ChallengeMFA)
              );
            }
            return of(MfaStatus.NoTelNumber);
          case CognitoMfaType.NONE:
            if (hasPhoneNumber)
              return from(Auth.setPreferredMFA(user, mfaType)).pipe(
                switchMap(() => this.refreshAuthenticatedUser()),
                map(() => MfaStatus.ChallengeMFA)
              );
            return of(MfaStatus.None);
        }
      })
    );
  }

  private getIdTokenFromUser(user: CognitoUser | ICognitoUser): string {
    return user.getSignInUserSession().getIdToken().getJwtToken();
  }
}

export { getFederationUrl };
