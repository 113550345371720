export enum MeetingType {
  weekly = 'level10',
  quarterly = 'quarterly',
  annualDayOne = 'annualDayOne',
  annualDayTwo = 'annualDayTwo',
  focusDay = 'focusDay',
  visionBuildingDayOne = 'visionBuildingDayOne',
  visionBuildingDayTwo = 'visionBuildingDayTwo',
  other = 'other',
  custom = 'custom',
}

export enum MeetingTypeToMeetingAgendaType {
  level10 = 'weeklyMeetings',
  quarterly = 'quarterlyMeetings',
  annualDayOne = 'annualDayOne',
  annualDayTwo = 'annualDayTwo',
  focusDay = 'focusDay',
  visionBuildingDayOne = 'visionBuildingDayOne',
  visionBuildingDayTwo = 'visionBuildingDayTwo',
}

export enum MeetingTypeToMeetingAgendaLanguage {
  level10 = 'levelTen',
  quarterly = 'quarterlySession',
  annualDayOne = 'annualSession',
  annualDayTwo = 'annualSession',
  focusDay = 'focusDay',
  visionBuildingDayOne = 'visionBuildingDayOne',
  visionBuildingDayTwo = 'visionBuildingDayTwo',
}
