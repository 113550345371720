import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { FilterBarStateModel, initialFilterBarState } from '@ninety/layouts/_state/filterbar/filterbar-state.model';
import { FilterBarStateReducer } from '@ninety/layouts/_state/filterbar/filterbar-state.reducer';
import {
  MainSidebarStateModel,
  initialMainSidebarState,
} from '@ninety/layouts/_state/main-sidebar/main-sidebar-state.model';
import { MainSidebarReducer } from '@ninety/layouts/_state/main-sidebar/main-sidebar-state.reducers';
import { PageStateModel, initialPageState } from '@ninety/layouts/_state/page/page-state.models';
import { pageStateReducer } from '@ninety/layouts/_state/page/page-state.reducers';
import {
  TopToolbarStateModel,
  initialTopToolbarState,
} from '@ninety/layouts/_state/top-toolbar/top-toolbar-state.model';
import { topToolbarStateReducer } from '@ninety/layouts/_state/top-toolbar/top-toolbar-state.reducers';

export const LayoutStateKey = 'app-layout';
export const FilterBarStateKey = 'filter-bar';
export const MainSidebarStateKey = 'main-sidebar';
export const TopToolbarStateKey = 'top-toolbar';
export const PageStateKey = 'page';

export const selectLayoutState = createFeatureSelector<LayoutState>(LayoutStateKey);
export const selectFilterBarState = createSelector(selectLayoutState, layoutState =>
  layoutState ? layoutState[FilterBarStateKey] : initialFilterBarState
);
export const selectMainSidebarState = createSelector(selectLayoutState, layoutState =>
  layoutState ? layoutState[MainSidebarStateKey] : initialMainSidebarState
);
export const selectPageState = createSelector(selectLayoutState, layoutState =>
  layoutState ? layoutState[PageStateKey] : initialPageState
);
export const selectTopToolbarState = createSelector(selectLayoutState, layoutState =>
  layoutState ? layoutState[TopToolbarStateKey] : initialTopToolbarState
);

export interface LayoutState {
  [FilterBarStateKey]: FilterBarStateModel;
  [MainSidebarStateKey]: MainSidebarStateModel;
  [PageStateKey]: PageStateModel;
  [TopToolbarStateKey]: TopToolbarStateModel;
}

export const layoutStateReducers: ActionReducerMap<LayoutState> = {
  [FilterBarStateKey]: FilterBarStateReducer,
  [MainSidebarStateKey]: MainSidebarReducer,
  [PageStateKey]: pageStateReducer,
  [TopToolbarStateKey]: topToolbarStateReducer,
};
