import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorService } from '../services/error.service';
import { NotifyService } from '../services/notify.service';

interface Options {
  msg?: string;
  err?: string;
}
export function SpinThenNotifyOrError(options?: Options) {
  const { msg, err } = options || {};

  return function spinSnackError<P extends unknown[], T>(
    _: Object,
    _propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<(...args: P) => Observable<T>>
  ) {
    const wrappedFunction = descriptor.value;
    descriptor.value = function (...args: P) {
      const spinnerService = this._spinnerService || this.spinnerService;
      const errorService = this._errorService || this.errorService;
      const notifyService = this._notifyService || this.notifyService;
      spinnerService.start();
      const result: Observable<T> = wrappedFunction.apply(this, args);
      return result
        .pipe<T>(
          tap(() => {
            spinnerService.stop();
            if (msg) (notifyService as NotifyService).notify(msg);
          })
        )
        .pipe<T>(catchError((e: unknown) => (errorService as ErrorService).notify(e, err)));
    };
  };
}
