import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, tap } from 'rxjs';

import { StateService } from '../_core/services/state.service';

import { UsersStateActions } from './app-entities/users/users-state.actions';
import { SubscriptionActions } from './app-global/company/subscription/subscription-state.actions';
import { appActions } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private stateService: StateService) {}

  stateServiceInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.initStateService),
        tap(() => this.stateService.init())
      ),
    { dispatch: false }
  );

  appStartActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.dispatchAppStartActions),
      //concatMap  is used to flatten the observable and dispatch actions sequentially.
      concatMap(() => {
        const actions = [SubscriptionActions.checkForBanners(), UsersStateActions.checkToShowTimeZoneUpdateDialog()];

        // Ensure actions are valid
        return actions.filter(action => action !== null && action !== undefined);
      })
    )
  );
}
