import { SelectOption } from '../inputs/selects/base/select-option.interface';

import { PaginationComponent } from './pagination.component';

/**
 * VM that {@link PaginationComponent} depends on. When selected from the component store, optimized with builtin
 * ComponentStore debounce.
 */
export type PaginationComponentViewModel = PaginationStoreModel & PaginationStoreSelectedState;

/** Inputs of {@link PaginationComponent} that are managed by {@link PaginationStore} */
export type PaginationComponentManagedInputs = Pick<
  PaginationComponent,
  | 'length'
  | 'pageSizeOptions'
  | 'pageSize'
  | 'pageIndex'
  | 'disablePageSizeSelect'
  | 'disablePageChange'
  | 'isMobileOverride'
>;

/** In memory model of the {@link PaginationStore} */
export type PaginationStoreModel = PaginationComponentManagedInputs & {
  mode: PaginationViewMode;
};

/** Responsive template type of the {@link PaginationComponent} template. See storybook for visual representations. */
export enum PaginationViewMode {
  mobile = 'mobile',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

/** Properties constructed from {@link PaginationStoreModel} by the {@link PaginationStore} **/
export interface PaginationStoreSelectedState {
  /** Whether the page index can be incremented */
  canIncrement: boolean;

  /** Whether the page index can be decremented */
  canDecrement: boolean;

  /** The active SelectOption passed to the {@link SelectComponent} */
  currentSelectOption: SelectOption;

  /** Options passed to the Ninety Select. Created from {@link PaginationComponent#pageSizeOptions}. */
  allSelectOptions: SelectOption[];

  /** If there are fewer items than this, the page size select will not be displayed. */
  smallestPageSize: number;

  /** The text shown on the next button. On mobile, includes the {@link PaginationConstants.MOBILE_PAGE_SIZE} */
  nextText: string;

  /**
   * The text shown on the previous button. Shortens from "Previous On mobile, includes the
   * {@link PaginationConstants.MOBILE_PAGE_SIZE}
   */
  previousText: string;

  /** Whether to show "Showing" and "of N" text surrounding the page size select */
  showDescriptiveText: boolean;

  /** Whether to show any part of the page select (descriptive text or multi-select) */
  showPageSizeSelectWrapper: boolean;
}

/**
 * **Non-global** constants used in the pagination component. Scoped by namespace to prevent polluting global namespace
 * or prefixing with "Pagination"
 *
 * Note - assume lack of tree-shaking is:
 * 1) negligible (assume size is significantly less than 1kb)
 * 2) unlikely to decrease any module size (assume you use more than 1, typically all)
 */
export namespace PaginationConstants {
  export const INITIAL_PAGE_INDEX = 0;
  export const DEFAULT_PAGE_SIZES = [10, 25, 50, 100];
  export const PAGE_SIZES_WITH_200 = [25, 50, 100, 200];
  export const INITIAL_PAGE_SIZE = DEFAULT_PAGE_SIZES[1];
  export const MOBILE_PAGE_SIZE = 5;
  export const SMALL_WIDTH_BREAKPOINT = 190;
  export const MEDIUM_WIDTH_BREAKPOINT = 260;

  export const NEXT_TEXT = 'Next';
  export const NEXT_TEXT_MOBILE = `${NEXT_TEXT} ${MOBILE_PAGE_SIZE}`;
  export const PREVIOUS_TEXT_LONG = 'Previous';
  export const PREVIOUS_TEXT_SHORT = 'Prev';
  export const PREVIOUS_TEXT_MOBILE = `${PREVIOUS_TEXT_SHORT} ${MOBILE_PAGE_SIZE}`;

  export const DEFAULT_STATE: Readonly<PaginationStoreModel> = {
    pageIndex: INITIAL_PAGE_INDEX,
    pageSize: INITIAL_PAGE_SIZE,
    pageSizeOptions: DEFAULT_PAGE_SIZES,
    length: 0,
    disablePageSizeSelect: false,
    disablePageChange: false,
    mode: PaginationViewMode.large,
    isMobileOverride: false,
  };
}
