import { createActionGroup, props } from '@ngrx/store';

import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';
import { Comment } from '@ninety/ui/legacy/shared/models/_shared/comment';
import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';

export const ConversationDetailStoreActions = createActionGroup({
  source: 'Conversation Detail Store',
  events: {
    'Conversation Edited':
      props<
        Pick<
          Conversation,
          '_id' | 'title' | 'description' | 'dueDate' | 'comments' | 'periodStartDate' | 'periodEndDate'
        >
      >(),
  },
});

export const ConversationDetailCommentActions = createActionGroup({
  source: 'ConversationDetailCommentsComponent',
  events: {
    'Comment Added': props<{ conversationId: string; comment: Comment }>(),
    'Comment Deleted': props<{ conversationId: string; index: number }>(),
  },
});

export const ConversationDetailActions = createActionGroup({
  source: 'Conversation Detail',
  events: {
    'Attachment Added': props<{ conversationId: string; attachment: Attachment }>(),
    'Attachment Removed': props<{ conversationId: string; attachment: Attachment }>(),
  },
});
