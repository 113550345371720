/**
 * Possible states of the widget template
 */
export enum WidgetTemplateType {
  /**
   * API is loading - show loading skeleton
   */
  loading = 'loading',

  /**
   * No filter applied and no items - show CTA for creating item
   */
  empty = 'empty',

  /**
   * Viewing archived, but no items - show info but no CTA
   */
  archive = 'archive',

  /**
   * Filtered query has no results - show not found empty state
   */
  noQueryResults = 'noQueryResults',

  /**
   * API is not available - show error state
   */
  error = 'error',

  /**
   * API is available and returned non-empty data - show data
   */
  data = 'data',
}
