import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FroalaOptions } from '../../models/_shared/froala-options';
import { ITextEditorOptions, TextEditorOptions } from '../../models/_shared/text-editor-options';

export enum TextEditorVariant {
  /** When readonly, editor has no border or toolbar. */
  Primary = 'primary',
  /** When readonly, editor is styled like other ninety inputs (has border) */
  Outline = 'outline',
}

// TODO: Make standalone so that components do not need to import SharedModule
@Component({
  selector: 'ninety-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextEditorComponent,
      multi: true,
    },
  ],
})
export class TextEditorComponent implements OnInit, ControlValueAccessor {
  @Input() readonly = false;
  @Input() placeholder: string;
  @Input() text: string;
  @Input() customOptions: Partial<FroalaOptions>;
  @Input() variant: TextEditorVariant = TextEditorVariant.Primary;

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();

  options: ITextEditorOptions<any>;
  isDisabled = false;

  private onChangeFn: (value: string) => void;
  private onTouchedFn: () => void;
  private touched = false;

  constructor(public defaultOptions: TextEditorOptions<any>, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.options = this.defaultOptions.clone();

    if (this.placeholder) {
      this.options.setPlaceholder(this.placeholder);
    }

    if (this.customOptions) {
      this.options.setCustomOptions(this.customOptions);
    }
  }

  onChange(text: string): void {
    if (this.readonly) return;

    if (!this.touched && this.onTouchedFn) {
      this.touched = true;
      this.onTouchedFn();
    }

    if (this.onChangeFn) {
      this.text = text;
      this.onChangeFn(text);
    }

    this.textChange.emit(text);
  }

  writeValue(value: string): void {
    this.text = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
