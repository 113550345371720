import { WidgetBaseViewModel } from '@ninety/my-ninety/_models/widget-base-view-model';
import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { PersonalTodoState } from '@ninety/todos/_state/personal/personal-todo.model';

/**
 * View Model (data) - Union of {@link PersonalTodosWidgetComponent} template inputs, including child templates.
 *
 * Note, todos is selected as an observable and is thus omitted from this type.
 *
 * TODO deprecate this in favor of individual inputs
 */
export type PersonalTodosWidgetViewModel = Omit<PersonalTodoState, 'todos'> & WidgetBaseViewModel;

/**
 * View Model (logic) - business logic of the view model
 */
export namespace PersonalTodosWidgetViewModel {
  /**
   * Based on current state, calculate the correct variant type
   */
  export function evaluateWidgetTemplateType(todoState: PersonalTodoState): WidgetTemplateType {
    if (todoState.loading) return WidgetTemplateType.loading;
    if (todoState.error) return WidgetTemplateType.error;
    if (!!todoState.searchText && todoState.todos.length === 0) {
      return WidgetTemplateType.noQueryResults;
    }
    if (todoState.todos.length === 0) return WidgetTemplateType.empty;
    return WidgetTemplateType.data;
  }
}
