<div (click)="item.action(item)" ninetyMenuRow cdkMenuItem>
  <span class="row" [attr.data-product]="item.attr ? item.attr : null">
    <span class="prefix-cell-layout" *ngIf="item.icon">
      <terra-icon [icon]="item.icon.name" size="40" [variant]="item.icon.variant" />
    </span>
    <span class="center-cell-layout">
      <div class="cell-text-header">{{ item.label }}</div>
      <div class="cell-text-body">{{ item.description }}</div>
    </span>
    <span class="suffix-cell-layout">
      <span>{{ item.suffix }}</span>
    </span>
  </span>
</div>
