<div #switch class="terra-switch">
  <input
    #checkboxInput
    [id]="_switchId"
    class="terra-switch__input"
    type="checkbox"
    role="switch"
    [disabled]="disabled"
    [tabIndex]="disabled ? -1 : _tabIndex"
    [attr.checked]="_checked | boolAttr"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby || _switchId"
    [attr.aria-describedby]="ariaDescribedby || 'terra-switch-description-' + _switchId"
    (change)="_inputChange($event)"
    (blur)="blur()" />
  <span
    [attr.data-cy]="_checked ? 'switch_toggle_on' : 'switch_toggle_off'"
    class="terra-switch__switch"
    (click)="toggle()"></span>
  <div class="terra-switch__label-group">
    <label class="terra-switch__label" [attr.for]="_switchId" (click)="$event.stopPropagation()">
      <ng-content></ng-content>
    </label>
    <div [id]="'terra-switch-description-' + _switchId" class="terra-switch__description">
      <ng-content select="terra-description"></ng-content>
    </div>
  </div>
</div>
