import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IdentityProviderService } from '@ninety/ui/legacy/core/services/identity-provider.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import TypeCodes from '@ninety/ui/legacy/core/services/type-codes.service';
import { Email } from '@ninety/ui/legacy/shared/models/_shared/email';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { EmailType } from '@ninety/ui/legacy/shared/models/directory/email-type';
import { PhoneNumber } from '@ninety/ui/legacy/shared/models/directory/phone-number';
import { PhoneType } from '@ninety/ui/legacy/shared/models/directory/phone-type';
import { TypeCode } from '@ninety/ui/legacy/shared/models/directory/type-code.interface';

@Component({
  selector: 'ninety-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit {
  @Input() userInfo: Partial<User>;

  @Output() updateUser = new EventEmitter<Partial<User>>();
  updateUser$ = new Subject<null>();
  emailTypes = new TypeCodes().emailAddresses;
  phoneTypes = new TypeCodes().phoneNumbers;

  constructor(
    public stateService: StateService,
    public idpService: IdentityProviderService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateUser$.pipe(debounceTime(1000)).subscribe({ next: () => this.updateUser.emit(this.userInfo) });
  }

  emitUpdate(): void {
    this.updateUser.emit(this.userInfo);
  }

  deleteEmail(i: number): void {
    this.userInfo.emailAddresses.splice(i, 1);
    this.cdr.markForCheck();
    this.emitUpdate();
  }

  addEmail(): void {
    const hasMainEmail = this.userInfo.emailAddresses.find(e => e.typeCode === EmailType.main);
    this.userInfo.emailAddresses.push(new Email('', hasMainEmail ? EmailType.work : EmailType.main));
    this.emitUpdate();
  }

  deletePhoneNumber(i: number): void {
    this.userInfo.phoneNumbers.splice(i, 1);
    this.cdr.markForCheck();
    this.emitUpdate();
  }

  addPhoneNumber(): void {
    const hasMainPhone = this.userInfo.phoneNumbers.find(e => e.typeCode === PhoneType.main);
    this.userInfo.phoneNumbers.push(new PhoneNumber('', hasMainPhone ? PhoneType.cell : PhoneType.main));
    this.emitUpdate();
  }

  compareTypeCode = (option: TypeCode, value: TypeCode): boolean => option === value;

  trackByIndex = (i: number, _item: Email | PhoneNumber): number => i;
}
