import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SignUp } from '@ninety/ui/legacy/shared/models/_shared/sign-up';
import { UserInviteStatus } from '@ninety/ui/legacy/shared/models/_shared/user-invite-status';
import { SignupCompanyRole } from '@ninety/ui/legacy/shared/models/user/signup-company-role.enum';

export const AcceptInviteStateActions = createActionGroup({
  source: 'Accept Invite',
  events: {
    'Start Spinner': emptyProps(),
    'Stop Spinner': emptyProps(),
    'Sign Up': props<{
      firstName: string;
      lastName: string;
      emailAddress: string;
      password: string;
      signupCompanyRole: SignupCompanyRole;
      submitEvent: SubmitEvent;
    }>(),
    'Create User': emptyProps(),
    'Sign Up With Ninety': props<{ idToken: string; user: SignUp }>(),
    'Sign Up With Idp': props<{ user: SignUp }>(),
    'Sign Up Failed': props<{ error: { errorMessage: string } }>(),
    'Create User Success': emptyProps(),
    'User Exists': props<{ userInviteStatus: UserInviteStatus }>(),
    'User Has No Invite': emptyProps(),
    'Navigate to Signup': props<{ emailAddress: string }>(),
  },
});
