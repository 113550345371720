export enum ErrorMessages {
  required = 'Required',
  email = 'Not a valid email',
  email2 = 'Must be a unique email. Email may be associated with someone already in the People Directory.',
  email3 = 'Must be a valid email address',
  mobile = 'Invalid telephone number',
  password = 'Your password needs to be at least of moderate strength.',
  confirmPassword = 'Passwords do not match',
  confirmPassword2 = 'Please choose a valid password.',
  confirmPassword3 = 'Password is too weak.',
}

export enum Errors {
  password = 'password',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  emailAlreadyUsed = 'emailAlreadyUsed',
  required = 'required',
  confirmPassword = 'confirmPassword',
  companyName = 'companyName',
  referrer = 'referrer',
  currentEosType = 'currentEosType',
  mobile = 'mobile',
}
