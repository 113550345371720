import { createSelector } from '@ngrx/store';

import { selectEntitiesState, TeamsStateKey } from '..';
import { selectAllUsers } from '../users/users-state.selectors';

import { teamsInitialState, teamsStateAdapter, TeamUsers } from './teams-state.model';

const { selectAll, selectEntities } = teamsStateAdapter.getSelectors();

export const selectTeamsState = createSelector(selectEntitiesState, appEntities =>
  appEntities ? appEntities[TeamsStateKey] : teamsInitialState
);

export const selectCompanyTeams = createSelector(selectTeamsState, selectAll);
export const selectCompanyTeamsDictionary = createSelector(selectTeamsState, selectEntities);

/**
 * Using a Map now for compatibility with the current allTeamsMap$ on the teams service.
 *
 * We can probably probably migrate to using {@link selectCompanyTeamsDictionary} to avoid looping to build the map.
 */
export const selectCompanyTeamsMap = createSelector(selectTeamsState, state =>
  Object.entries(state.entities).reduce((acc, [id, team]) => {
    acc.set(id, team);
    return acc;
  }, new Map())
);

export const selectCompanyTeamNameById = (teamId: string) =>
  createSelector(selectCompanyTeamsDictionary, state => state[teamId]?.name || '');
export const selectCompanyTeamById = (teamId: string) =>
  createSelector(selectCompanyTeamsDictionary, state => state[teamId]);

export const selectTeamUsers = (teamId: string) =>
  createSelector(
    selectAllUsers,
    selectCompanyTeamById(teamId),
    (users, team) =>
      ({
        team: team,
        users: users?.filter(user => user.teams?.some(team => team.teamId === teamId)),
      } as TeamUsers)
  );
