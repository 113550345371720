import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MeetingV2RoutingModule } from '../meetings-routing.module';

import { MeetingsPageEffects } from './effects/meetings.effects';
import { MeetingsRealTimeBroadcastEffects } from './effects/real-time-broadcasts.effects';
import { MeetingsRealTimeSubscriptionsEffects } from './effects/real-time-subscriptions.effect';
import { MeetingsFeatureStateKey } from './meetings.model';
import { meetingsReducer } from './meetings.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(MeetingsFeatureStateKey, meetingsReducer),
    EffectsModule.forFeature([
      MeetingsPageEffects,
      MeetingsRealTimeBroadcastEffects,
      MeetingsRealTimeSubscriptionsEffects,
    ]),
    MeetingV2RoutingModule,
  ],
})
export class MeetingsV2Module {}
