import { createActionGroup, props } from '@ngrx/store';

import { HelpfulPermissionsStateModel } from './helpful-permissions.reducer';

export const HelpfulPermissionsActions = createActionGroup({
  source: 'HelpfulPermissions',
  events: {
    Init: props<HelpfulPermissionsStateModel>(),
  },
});
