/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';

import { NinetyInputDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/text-input/directives/ninety-input.directive';

import { HasErrorDirective } from '../../../directives/has-error.directive';
import { InputVariantDirective } from '../../directives/input-variant.directive';

/**
 * Partial Implementation of the "TextInput" Terra Component.
 *
 * See storybook for examples.
 *
 * Basic Features:
 * - Disabled
 * - Readonly
 * - Prefix/Suffix
 *
 * @see [MDN Input Element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input)
 */
@Component({
  selector: 'ninety-input-wrapper',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./text-input-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'focusInput()',
  },
  hostDirectives: [
    {
      directive: InputVariantDirective,
      inputs: ['ninetyInputVariant: variant'],
    },
    {
      directive: HasErrorDirective,
      inputs: ['ninetyHasError: hasError'],
    },
  ],
  template: `
    <ng-content select="[ninetyInputPrefix]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[ninetyInputSuffix]"></ng-content>
  `,
})
export class TextInputWrapperComponent {
  @ContentChild(NinetyInputDirective) input?: NinetyInputDirective;

  /** Focus the input element wrapped by this component, if it exists. */
  focusInput() {
    this.input?.focus();
  }
}
