import { createReducer, on } from '@ngrx/store';

import { BannerNotificationActions } from './banner-notification.actions';
import { BannerNotificationModel } from './banner-notification.model';

export const initialBannerNotificationState: BannerNotificationModel = {
  message: '',
};

export const bannerNotificationStateReducer = createReducer<BannerNotificationModel>(
  initialBannerNotificationState,
  on(
    BannerNotificationActions.displayBanner,
    (state, { banner }: { banner: BannerNotificationModel }): BannerNotificationModel => ({ ...state, ...banner })
  ),

  on(
    BannerNotificationActions.dismissBannerSuccess,
    BannerNotificationActions.noBanner,
    (): BannerNotificationModel => ({
      message: '',
    })
  )
);
