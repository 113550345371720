export enum SettingsMenuItem {
  //top level menu items
  Settings = 'Settings',
  UserSettings = 'User',
  CompanySettings = 'Company',

  //sub-menu items
  //user settings
  Profile = 'Profile',
  Account = 'Account',
  Preferences = 'Preferences',
  Notifications = 'Notifications',
  Integrations = 'Integrations',

  //company settings
  Teams = 'Teams',
  Configuration = 'Configuration',
  OurLanguage = 'Language',
  Meetings = 'Meetings',
  Billing = 'Billing',
  AddOns = 'Add-Ons',

  //support settings
  ManualCharges = 'Manual Charges',
}
