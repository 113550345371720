import { createReducer, on } from '@ngrx/store';

import {
  constructSharedReducersWithDistinctActions,
  SharedReducerFunctions,
} from '@ninety/todos/_state/_shared/todo-state.shared.reducers';
import {
  TeamTodoActions,
  TeamTodoInlineActions,
  TeamTodoPubnubActions,
  TodoAgreementActions,
} from '@ninety/todos/_state/team/team-todo.actions';
import { initialTeamTodoState, TeamTodoState } from '@ninety/todos/_state/team/team-todo.model';
import { TodoNegotiatedFields } from '@ninety/ui/legacy/shared/models/todos/todo';

export const teamTodoReducer = createReducer<TeamTodoState>(
  initialTeamTodoState,
  ...constructSharedReducersWithDistinctActions<TeamTodoState>('team'),
  on(TeamTodoActions.getTeam, TeamTodoActions.sortBy, (state): TeamTodoState => ({ ...state, loading: true })),
  on(
    TeamTodoActions.teamSelected,
    (state, { teamId }): TeamTodoState => ({
      ...state,
      loading: true,
      pageIndex: 0,
      selectedTodoId: null,
      teamId,
      userPendingAgreements: null,
    })
  ),
  on(
    TeamTodoActions.showArchived,
    (state, { showArchived }): TeamTodoState => ({
      ...state,
      showArchived,
      loading: true,
      pageIndex: 0,
      selectedTodoId: null,
      userPendingAgreements: null,
    })
  ),
  on(TeamTodoActions.toggleArchivedSuccess, SharedReducerFunctions.removeTodoFromStateById),
  on(TeamTodoActions.removeAcceptedTodoFromState, SharedReducerFunctions.removeTodoFromStateById),
  on(TeamTodoActions.toggleArchivedFailure, SharedReducerFunctions.setLoadingToFalse),
  on(TeamTodoActions.getTeamSuccess, SharedReducerFunctions.updateTodosOnGETManySuccess),
  on(TeamTodoActions.getTeamFailure, SharedReducerFunctions.setErrorOnApiFailure),
  on(
    TeamTodoActions.updateLocal,
    TeamTodoActions.updateInlineSuccess,
    TeamTodoActions.updateDueDateSuccess,
    TodoAgreementActions.acceptSuccess,
    /** A successful pubnub attachment event fetches the updated todo and needs to be merged into the list */
    TeamTodoPubnubActions.attachmentEventSuccess,
    (state, { todo }): TeamTodoState => {
      let todos = SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(state.todos, todo);

      if (state.teamId !== 'all') {
        todos = todos.filter(t => t.teamId === state.teamId);
      }

      return {
        ...state,
        todos: todos,
        loading: false,
      };
    }
  ),
  /** Updating reducer optimistically for TeamTodoActions.update which is Partial<Todo>  */
  on(TeamTodoActions.update, TeamTodoActions.updateUserSuccess, (state, { todo }): TeamTodoState => {
    let todos = state.todos.map(t => {
      if (t._id == state.selectedTodoId) {
        return Object.assign({}, t, todo);
      }
      return t;
    });

    if (state.teamId !== 'all') {
      todos = todos.filter(t => t.teamId === state.teamId);
    }

    return {
      ...state,
      todos: todos,
      loading: false,
    };
  }),
  on(
    TeamTodoInlineActions.addOne,
    (state, { todo }): TeamTodoState => ({
      ...state,
      todos: [...state.todos, todo],
      listControlsDisabled: true,
      focusOnInlineAddTodo: false,
    })
  ),
  on(TeamTodoActions.addManySuccess, (state, { response }): TeamTodoState => {
    let newTodos = [...state.todos];

    const viewingTeamCreatedTodosExistIn =
      state.teamId === 'all' || response.todos.some(t => t.teamId === state.teamId);
    if (!state.showArchived && viewingTeamCreatedTodosExistIn) {
      newTodos = [...newTodos, ...response.todos];
    }

    return {
      ...state,
      todos: newTodos,
      todoCount: viewingTeamCreatedTodosExistIn ? state.todoCount + 1 : state.todoCount,
    };
  }),
  on(TeamTodoPubnubActions.addManyFromBroadcast, (state, { todos }): TeamTodoState => {
    let newTodos = [...state.todos];

    const viewingTeamCreatedTodosExistIn = state.teamId === 'all' || todos.some(t => t.teamId === state.teamId);
    if (!state.showArchived && viewingTeamCreatedTodosExistIn) {
      newTodos = [...newTodos, ...todos];
    }

    return {
      ...state,
      todos: newTodos,
      todoCount: viewingTeamCreatedTodosExistIn ? state.todoCount + 1 : state.todoCount,
    };
  }),
  on(TeamTodoInlineActions.cancelAddOne, (state): TeamTodoState => {
    const todosWithIds = state.todos.filter(todo => todo._id);
    return {
      ...state,
      todos: [...todosWithIds],
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
    };
  }),
  on(TeamTodoInlineActions.createOneSuccess, (state, { response }): TeamTodoState => {
    const todosWithIds = state.todos.filter(todo => todo._id);
    const newTodo = response.todos[0];
    return {
      ...state,
      todos: [...todosWithIds, newTodo],
      todoCount: state.todoCount + 1,
      listControlsDisabled: false,
      focusOnInlineAddTodo: true,
    };
  }),
  on(TeamTodoInlineActions.createOneFailure, (state): TeamTodoState => {
    const todosWithIds = state.todos.filter(todo => todo._id);
    return {
      ...state,
      todos: [...todosWithIds],
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
    };
  }),
  on(
    TeamTodoActions.resetState,
    (_oldState, { overrides }): TeamTodoState => ({ ...initialTeamTodoState, ...overrides })
  ),
  on(
    TeamTodoActions.initMeetingConclude,
    (state, { teamId, pageSize }): TeamTodoState => ({ ...state, teamId, pageSize })
  ),
  on(TeamTodoActions.syncTasks, TodoAgreementActions.accept, (state): TeamTodoState => ({ ...state, loading: true })),
  on(
    TeamTodoActions.createTask,
    (state): TeamTodoState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    TeamTodoActions.hydratePageSizeFromLocalStore,
    (state, { pageSize }): TeamTodoState => ({
      ...state,
      pageSize,
    })
  ),
  on(
    TeamTodoActions.hydratePageSortingFromLocalStore,
    (state, { sortField, sortDirection }): TeamTodoState => ({
      ...state,
      sortField,
      sortDirection,
    })
  ),
  on(TeamTodoActions.archiveAllCompletedUserTeam, (state): TeamTodoState => ({ ...state, loading: true })),
  on(
    TeamTodoActions.archiveAllCompletedUserTeamFailure,
    (state, { error }): TeamTodoState => ({ ...state, loading: false, error })
  ),
  on(TeamTodoActions.archiveAllCompletedSuccess, (state): TeamTodoState => ({ ...state, pageIndex: 0 })),
  /** Pubnub action received via broadcast */
  on(TeamTodoPubnubActions.unarchive, (state, { todo }): TeamTodoState => {
    /** User is on the archived view and todo has been unarchived - remove from state */
    if (state.showArchived) {
      return SharedReducerFunctions.removeTodoFromStateById(state, { id: todo._id });
    } else {
      /** User is not on the archived view and is viewing the team the todo belongs to - add to state */
      if (todo.teamId === state.teamId && !state.todos.find(t => t._id === todo._id)) {
        return {
          ...state,
          todos: [...state.todos, todo],
          todoCount: state.todoCount + 1,
        };
      }
    }
  }),
  /** Attachments */
  on(TeamTodoActions.attachmentUploaded, (state, { event }) => {
    const todo = state.todos.find(t => t._id === event.attachment.parentId);
    if (todo) {
      const update = Object.assign({}, todo, {
        _id: event.attachment.parentId,
        attachments: [...todo.attachments, event.attachment],
      });
      return {
        ...state,
        todos: SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(state.todos, update),
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(TeamTodoActions.attachmentRemoved, (state, { event }) => {
    const todo = state.todos.find(t => t._id === event.attachment.parentId);
    if (todo) {
      const attachments = todo.attachments?.filter(a => a._id !== event.attachment._id) ?? [];

      return {
        ...state,
        todos: SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(
          state.todos,
          Object.assign({}, todo, { attachments })
        ),
      };
    }
  }),
  on(TeamTodoActions.attachmentReordered, (state, { event }) => {
    const todo = state.todos.find(t => t._id === event._id);
    if (todo) {
      return {
        ...state,
        todos: SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(
          state.todos,
          Object.assign({}, todo, { attachments: event.attachments })
        ),
      };
    }
  }),
  /**
   * We don't normally update on success because the implicit save causes the cursor
   * to jump around if the user is still editing the title or description - so we're only updating the
   * negotiated fields on update success.
   */
  on(TeamTodoActions.updateSuccess, (state, { todo: updateResponse }) => {
    const todo = state.todos.find(t => t._id === updateResponse._id);
    let todos = [...state.todos];
    if (!todo && !updateResponse.isPersonal) {
      todos = [...todos, updateResponse];
    } else if (todo && updateResponse.isPersonal) {
      todos = todos.filter(t => t._id !== updateResponse._id);
    }
    if (todo && todo.hasOwnProperty('status')) {
      const negotiatedFields: TodoNegotiatedFields = {
        ownerAcceptedDate: updateResponse.ownerAcceptedDate,
        creatorAcceptedDate: updateResponse.creatorAcceptedDate,
        status: updateResponse.status,
      };
      const update = Object.assign({}, todo, negotiatedFields);
      todos = SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(state.todos, update);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(TeamTodoActions.updateDisplayedTodosInSeries, (state, { todo }) => {
    const todos = state.todos.reduce((acc, t) => {
      //update some fields for todos in series &
      //avoid updating opened todo again and/or causing jumping cursor
      if (t.seriesId === todo.seriesId && t._id !== state.selectedTodoId) {
        if (todo.isPersonal || todo.teamId !== state.teamId) {
          //remove any series todos that are now not part of the list
          //they are now personal or on a different team
          return acc;
        }

        return [
          ...acc,
          Object.assign({}, t, {
            userId: todo.userId,
            user: todo.user,
            title: todo.title,
            description: todo.description,
            teamId: todo.teamId,
            isPersonal: todo.isPersonal,
          }),
        ];
      }
      return [...acc, t];
    }, []);

    return { ...state, todos };
  }),
  on(TeamTodoActions.addToTeam, (state, { todo: updateResponse }) => {
    const todo = state.todos.find(t => t._id === updateResponse._id);
    let todos = [...state.todos];
    if (!todo && !updateResponse.isPersonal) {
      todos = [...todos, updateResponse];
    }

    return { ...state, todos };
  }),
  on(TeamTodoActions.removeFromTeam, (state, { todo: updateResponse }) => {
    const todo = state.todos.find(t => t._id === updateResponse._id);
    let todos = [...state.todos];
    if (todo && updateResponse.isPersonal) {
      todos = todos.filter(t => t._id !== todo._id);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(
    TeamTodoActions.clearSort,
    (state): TeamTodoState => ({
      ...state,
      sortDirection: null,
      sortField: null,
    })
  ),
  on(
    TeamTodoActions.toggleUserPendingAgreements,
    (state): TeamTodoState => ({
      ...state,
      loading: true,
      pageIndex: 0,
      // Toggle the filter value in this case null or true
      // and then QueryParamsService.build(params, true) will remove the value from the query params
      userPendingAgreements: state.userPendingAgreements ? null : true,
    })
  )
);
