/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { SpinnerActions } from './spinner-state.actions';
import { selectAuxiliaryStartTime, selectPrimaryStartTime } from './spinner-state.selectors';

declare let FS: { event: Function };

@Injectable()
export class SpinnerStateEffects {
  stopPrimarySpinner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SpinnerActions.stopPrimary),
        concatLatestFrom(() => this.store.select(selectPrimaryStartTime)),
        tap(([_, startMS]) => this.trackTime(startMS, Date.now()))
      ),
    { dispatch: false }
  );

  stopAuxiliarySpinner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SpinnerActions.stopAuxiliary),
        concatLatestFrom(() => this.store.select(selectAuxiliaryStartTime)),
        tap(([_, startMS]) => this.trackTime(startMS, Date.now()))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store) {}

  trackTime(startMS: number, endMS: number): void {
    if (!window.hasOwnProperty('FS')) return;
    const time = (endMS - startMS) / 3600;
    if (time >= 3) FS.event('Spinner Time', { time });
  }
}
