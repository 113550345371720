import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { TerraIconModule, TerraIconName, TerraIconVariant } from '@ninety/terra';

import { OfflineService } from '../../../_core/services/offline.service';
import { BusinessOperatingSystem } from '../../models/company/business-operating-system.enum';
import { SnackbarTemplateType } from '../../models/enums/snackbar-template-type';
import { ConversationType } from '../../models/feedback/conversation-type.enum';

@Component({
  selector: 'ninety-snackbar',
  standalone: true,
  imports: [CommonModule, TerraIconModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  meetingPath: [];
  templateType: SnackbarTemplateType;
  SnackbarTemplateType = SnackbarTemplateType;
  ConversationType = ConversationType;
  iconKey: TerraIconName;
  iconVariant: TerraIconVariant;
  constructor(
    public offlineService: OfflineService,
    private router: Router,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any
  ) {
    this.templateType = data.templateType;
    if (data.templateType === SnackbarTemplateType.newMeeting) this.meetingPath = data.payload.path;
    if (data.payload.iconKey) {
      this.iconKey = data.payload.iconKey;
      this.iconVariant = data.payload.iconVariant || 'regular';
    }
  }

  navigateToMeeting(): void {
    this.router.navigate(this.meetingPath);
    this.snackBarRef.dismiss();
  }

  navigateToConversationMeeting(conversationId: string, bos: BusinessOperatingSystem) {
    this.router.navigateByUrl(
      `/1-on-1/discussion/${conversationId}/${bos === BusinessOperatingSystem.eos ? 'meeting' : 'conversation-meeting'}`
    );
    this.snackBarRef.dismiss();
  }

  getIconKey(): TerraIconName | null {
    if (this.iconKey) {
      return this.iconKey;
    }
    return null;
  }

  displayFullName(name: { first: string; last: string }): string {
    return `${name?.first || ''} ${name?.last || ''}`.trim();
  }
}
