import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { FocusedDirective } from '../../directives/focused/focused.directive';

@Component({
  selector: 'ninety-add-item-button',
  standalone: true,
  imports: [ButtonComponent, FocusedDirective],
  template: `
    <ninety-button
      compact
      primary
      color="accent"
      (click)="clicked.emit()"
      [ninetyFocused]="focused"
      [disabled]="disabled"
      data-cy="add-item-button_inline-add-button"
      ><span>+ {{ label }}</span></ninety-button
    >
  `,
  styles: [
    `
      @use 'terra';

      .add-item-button {
        @include terra.text(body-bold);
        line-height: 3.5rem !important; //TERRATODO this needs to be looked at as it's giving this button height instead of using a height property
        vertical-align: middle !important;
      }
    `,
  ],
})
export class AddItemButtonComponent {
  @Input() label: string;
  @Input() disabled = false;
  @Input() focused = false;

  @Output() clicked = new EventEmitter<void>();
}
