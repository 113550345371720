import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DragAndDropEvent } from '@ninety/todos/_state/_shared/todo-state.shared.actions';
import { PrintOptions } from '@ninety/ui/legacy/core/services/print.service';
import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';
import { AttachmentEvent } from '@ninety/ui/legacy/shared/models/_shared/attachment-event';
import { AttachmentMessageType } from '@ninety/ui/legacy/shared/models/_shared/attachment-message-type';
import { HeadlinesSort } from '@ninety/ui/legacy/shared/models/headlines/headline-sort';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';
import { HeadlinesResponse } from '@ninety/ui/legacy/shared/models/meetings/headlines-response';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { RealtimeMessage } from '@ninety/ui/legacy/shared/models/meetings/realtime-message';

export const HeadlinesStateActions = createActionGroup({
  source: 'Headlines',
  events: {
    Hydrate: props<{ pageSize: number; sort: HeadlinesSort }>(),

    Get: emptyProps(),
    'Get Success': props<{
      headlines: HeadlinesResponse;
      //Temp solution when headlines are loaded in meeting
      //We need to set done in meeting headlines and CMs
      //Meeting can be retrieved from state in the future
      meeting?: Meeting;
    }>(),
    'Get Failed': props<{ error: unknown }>(),

    Update: props<{ update: Update<Headline> }>(),
    'Update Success': props<{ update: Update<Headline> }>(),
    'Update Failed': props<{ error: unknown }>(),
    'Update Local': props<{ update: Update<Headline> }>(),

    'Set Archived': props<{ id: string; archived: boolean }>(),
    'Set Archived Success': props<{ headline: Headline }>(),
    'Set Archived Failed': props<{ error: unknown }>(),
    'Archive Local': props<{ id: string }>(),

    Search: props<{ searchText: string }>(),

    'Set Completed': props<{ id: string; completed: boolean }>(),
    'Set Completed Success': props<{ update: Update<Headline> }>(),
    'Set Completed Failed': props<{ error: unknown }>(),

    'Set Completed In Meeting Success': props<{ update: Update<Headline> }>(),
    'Set Completed In Meeting Failed': props<{ error: unknown }>(),

    'Set User': props<{ id: string; ownedByUserId: string }>(),
    'Set User Success': props<{ update: Update<Headline> }>(),
    'Set User Failed': props<{ error: unknown }>(),

    Delete: props<{ id: string }>(),
    'Delete Success': props<{ id: string }>(),
    'Delete Failed': props<{ error: unknown }>(),
    'Delete Local': props<{ id: string }>(),

    'Set Team': props<{ id: string; teamId: string }>(),
    'Set Team Success': props<{ id: string; teamId: string }>(),
    'Set Team Failed': props<{ error: unknown }>(),

    Add: props<{ headline: Headline }>(),
    'Add Local': props<{ headline: Headline }>(),

    'Filter By Team': props<{ teamId: string }>(),
    'Show Archived': props<{ archived: boolean }>(),
    'Pagination Change': props<{ index: number; size: number }>(),
    'Sort Change': props<{ sort: HeadlinesSort }>(),

    //sets flag to include discussed in meeting headlines
    'Set In Meeting Id': props<{ meetingId: string }>(),

    'Open Create Dialog': emptyProps(),
    'Open In Detail View': props<{ headlineId: string }>(),

    'Set Selected': props<{ selectedId: string }>(),
    'Clear Selected': emptyProps(),

    Cascade: props<{ headline: Headline }>(),
    'Create Issue': props<{ headline: Headline }>(),
    'Create Todo': props<{ headline: Headline }>(),
    'Create Rock': props<{ headline: Headline }>(),
    'Create Headline': props<{ headline: Headline }>(),

    'Add Attachment': props<{ id: string; attachment: Attachment }>(),
    'Remove Attachment': props<{ id: string; attachmentId: string }>(),
    'Attachment Uploaded': props<{ event: AttachmentEvent & { meetingMessageType?: AttachmentMessageType } }>(),
    'Attachment Removed': props<{ event: AttachmentEvent & { meetingMessageType?: AttachmentMessageType } }>(),

    'Update Ordinals': props<DragAndDropEvent>(),

    'Page Destroy': emptyProps(),
    'Reset Store': emptyProps(),

    'Clear Sort': emptyProps(),

    'Add Inline ': props<{ headline: Headline }>(),
    'Cancel Add Inline': emptyProps(),
    'Save Inline': props<{ headline: Headline }>(),
    'Save Inline Success': props<{ headline: Headline }>(),
    'Save Inline Failed': props<{ error: unknown }>(),

    'Print Headlines And Cms': props<{ printOptions: PrintOptions }>(),
    'Print Headlines And Cms Success': emptyProps(),
    'Print Headlines And Cms Failure': emptyProps(),

    // Both Headlines and CMs handled by this action
    'Download Excel': emptyProps(),
    'Download Excel Success': emptyProps(),
    'Download Excel Failure': emptyProps(),

    'Broadcast Message': props<{ message: RealtimeMessage }>(),
    'Create For Segment': props<{ headline: Headline }>(),
    'Mark Completed For Segment': props<{ update: Update<Headline> }>(),

    'Remove Done Headline Success': emptyProps(),
    'Remove Done Headline Failure': emptyProps(),
    'Remove Cascaded Message Success': emptyProps(),
    'Remove Cascaded Message Failure': emptyProps(),

    'Archive All Completed': emptyProps(),
    'Archive All Completed Success': props<{ numOfArchivedHeadlines: number }>(),
    'Archive All Completed Failure': props<{ error: unknown }>(),
  },
});
