import { LoggerConfiguration } from '@datadog/browser-logs';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import type { GlobalContext, LogDto } from '../../_core/logging';
import { DatadogLogger } from '../../_core/vendor/datadog';

import { LoggerName, LoggingFeatureFlagState } from './logging-feature-flag-state';

interface LogActionProps {
  log: LogDto;
  /**
   * Optionally pass the logger name you would like to use.
   *
   * Note: It must have already been created, otherwise it will use the AppModule logger.
   */
  loggerName?: LoggerName;
}

export const LoggingActions = createActionGroup({
  source: 'Logging',
  events: {
    'Add Global Context': props<{ data: Partial<GlobalContext> }>(),
    'Create Logger': props<{ name: keyof LoggingFeatureFlagState; conf?: LoggerConfiguration }>(),
    'Create Logger Success': props<{ name: keyof LoggingFeatureFlagState; logger: DatadogLogger }>(),
    Debug: props<LogActionProps>(),
    Info: props<LogActionProps>(),
    Warn: props<LogActionProps>(),
    Error: props<LogActionProps>(),

    Scope: props<{ scope: string }>(),
    'Clear Scope': emptyProps(),
  },
});
