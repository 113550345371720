import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash';
import { ungzip } from 'pako';
import { BehaviorSubject, Observable, Subject, forkJoin, of, catchError, map, switchMap, tap } from 'rxjs';

import { CreateTeam } from '../../_shared/models/_shared/create-team';
import { RoleCode } from '../../_shared/models/_shared/role-code';
import { MeetingAgendas, Team, TeamMeetingAgendaUpdateByType, TeamSettings } from '../../_shared/models/_shared/team';
import { TeamUsers } from '../../_shared/models/_shared/team-users';
import { User } from '../../_shared/models/_shared/user';
import { BusinessOperatingSystem } from '../../_shared/models/company/business-operating-system.enum';
import { MeetingAgenda } from '../../_shared/models/meetings/meeting-agenda';
import { SortByNamePipe } from '../../_shared/pipes/sort-by-name.pipe';
import { teamsStateActions } from '../../_state';
import { TeamsStateFacade } from '../../_state/app-entities/teams/teams-state.facade';

import { ErrorService } from './error.service';
import { FilterService } from './filter.service';
import { QueryParamsService } from './query-params.service';
import { StateService } from './state.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private teamsApi = 'api.qa1.90srv.com/Teams';

  userTeams: Team[];
  allTeams: Team[];

  /**
   * Teams user is explicitly assigned to. Better name would be `assignedToTeamIds`.
   */
  userTeamIds: string[] = [];

  teamAll: Team = { name: 'All', _id: 'all' };
  teamNone: Team = { name: 'None', _id: 'none', project: false };

  /**
   * Teams the user has visibility to. For admin+, this will be all non-private company teams and will diverge from
   * {@link userTeamIds}.
   */
  userTeams$ = new BehaviorSubject<Team[]>(null);

  /**
   * All teams in a company.
   *
   * @deprecated prefer using {@link TeamsStateFacade.selectCompanyTeams}.
   */
  allTeams$ = new BehaviorSubject<Team[]>(null);

  /**
   * A map of team.id -> team. Derived from {@link allTeams$}.
   */
  allTeamsMap$ = new BehaviorSubject<Map<string, Team>>(new Map());

  /**
   * Teams with User[] subfield, includes all active users.
   */
  teamsWithAllEmbeddedUsers$ = new BehaviorSubject<Team[]>(null);

  /**
   * Same as {@link teamsWithAllEmbeddedUsers$}, except it filters off observer users
   * from the User[] subfield.
   */
  teamsWithEmbeddedUsers$ = new BehaviorSubject<Team[]>(null);

  /**
   * A map of team.id -> users who are on that team.
   */
  teamUsers$ = new BehaviorSubject<TeamUsers>(null);

  /**
   * Same as {@link allTeamsMap$}, except observer users have been removed from team.users
   */
  teamsWithUsersByTeamId: Record<string, Team> = {};

  defaultMeetingAgendas$ = new Subject<MeetingAgendas>();

  constructor(
    public stateService: StateService,
    private http: HttpClient,
    private userService: UserService,
    private errorService: ErrorService,
    private sortByNamePipe: SortByNamePipe,
    private filterService: FilterService,
    private teamsStateFacade: TeamsStateFacade,
    private store: Store
  ) {}

  selectTeamsWithAllEmbeddedUsers() {
    return this.teamsWithAllEmbeddedUsers$.asObservable();
  }

  selectTeamsWithEmbeddedUsers() {
    return this.teamsWithEmbeddedUsers$.asObservable();
  }

  setCompanyTeams(teams: Team[]) {
    this.allTeams = teams;
    this.allTeams$.next(teams);
    this.allTeamsMap$.next(teams.reduce((map, team) => map.set(team._id, team), new Map()));
  }

  /**
   * Create array of teams that the session user belongs to.
   *
   * Every time all company teams are set, filter the list for teams that are on the list of team ids on the
   * session user.
   */
  setSessionUserTeams(assignedTeamIds: string[], filterableTeams: Team[]): void {
    // This is weird, but userTeamIds is the list of teams the user is explicitly assigned to
    this.userTeamIds = assignedTeamIds ?? [];
    // Then userTeams is the list a logged in user can view (admin+ can view any non-private team)
    this.userTeams = filterableTeams;
    this.userTeams$.next(filterableTeams);
  }

  getTeamsByCompanyId(companyId: string): Observable<Team[]> {
    return this.http.get<Team[]>(`/api/v4/Companies/${companyId}/Teams`).pipe(catchError(ErrorService.handle));
  }

  getV4Teams(): Observable<Team[]> {
    return this.http.get<{ teamsBuffer: { data: Buffer } }>('/api/v4/Teams').pipe(
      map(({ teamsBuffer: { data } }) => JSON.parse(ungzip(new Uint8Array(data), { to: 'string' })) as Team[]),
      tap((teams: Team[]) => this.teamsStateFacade.getAllSuccess({ teams })),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not get teams.  Please try again.'))
    );
  }

  getTeamUsers(): Observable<Team[]> {
    return forkJoin({
      allTeams: this.getV4Teams(),
      allUsers: this.userService.getUsers(),
    }).pipe(
      concatLatestFrom(() => this.teamsWithAllEmbeddedUsers$),
      map(([_, teamsWithUsers]) => teamsWithUsers),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not get users.  Please try again.'))
    );
  }

  getTeamsWithoutObservers(teams: Team[]): Team[] {
    return teams.map(t => {
      const newT = Object.assign({}, t);
      newT.users = newT.users?.filter((u: User) => u.roleCode !== RoleCode.observer) ?? [];
      return newT;
    });
  }

  fetchTeamUsersInBackground(): void {
    this.getTeamUsers().subscribe();
  }

  findTeam(id: string): Observable<Team> {
    const team = this.userTeams ? this.userTeams.find((t: Team) => t._id === id) : null;
    return team ? of(team) : this.getTeamById(id);
  }

  getTeamById(id: string): Observable<Team> {
    return this.http
      .get<Team>(`/api/v4/Teams/${id}`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not get team.  Please try again.')));
  }

  getTeamWithUserById(id: string): Observable<Team> {
    return this.teamsWithAllEmbeddedUsers$.pipe(
      map(teams => teams?.find(t => t._id === id)),
      switchMap(team => {
        if (!!team) return of(team);

        return this.getTeamById(id);
      })
    );
  }

  create(createTeam: CreateTeam): Observable<string> {
    return this.http.post<string>(this.teamsApi, CreateTeam.toCreateTeamRequest(createTeam)).pipe(
      tap(newTeamId => {
        this.addTeamInMemory({ ...CreateTeam.toTeam(createTeam), _id: newTeamId }); // Updates Ngrxs
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not create team.  Please try again.'))
    );
  }

  updateTeamMeetingAgendaByType(update: TeamMeetingAgendaUpdateByType) {
    const { teamId, agendaType, teamAgenda } = update;
    return this.http.patch<void>(`/api/v4/Teams/${teamId}/Settings`, { [agendaType]: teamAgenda }).pipe(
      tap(_ => {
        const update = { settings: {} };
        if (agendaType === 'custom' && Array.isArray(teamAgenda)) {
          update.settings['custom'] = teamAgenda;

          this.store.dispatch(teamsStateActions.updateCustomAgendas({ teamId, customAgendas: _cloneDeep(teamAgenda) }));
        } else if (agendaType !== 'custom' && !Array.isArray(teamAgenda)) {
          update.settings[agendaType] = teamAgenda;

          this.store.dispatch(teamsStateActions.updateAgenda({ teamId, agendaType: agendaType, agenda: teamAgenda }));
        }
        const team = this.allTeams.find(t => t._id === teamId);
        if (team) Object.assign(team.settings, update.settings);
        this.allTeams$.next(this.allTeams);
        this.allTeamsMap$.next(this.allTeams.reduce((map, t) => map.set(t._id, t), new Map()));

        const userTeamIndex = this.userTeams.findIndex(t => t._id === teamId);
        if (userTeamIndex !== -1) {
          this.userTeams[userTeamIndex] = Object.assign(_cloneDeep(this.userTeams[userTeamIndex]), update);
        }
        this.userTeams$.next(this.userTeams);

        const selectedTeam = _cloneDeep(this.filterService.selectedTeam$.value);
        if (selectedTeam?._id === teamId) {
          Object.assign(selectedTeam.settings, update.settings);
          this.filterService.selectedTeam$.next(selectedTeam);
        }
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not update team meeting agenda. Please try again.'))
    );
  }

  pushAgendaByType(teamId: string, agendaType: string) {
    return this.http
      .post<void>(`/api/v4/Teams/${teamId}/pushAgenda/${agendaType}`, {})
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not push team meeting agenda. Please try again.'))
      );
  }

  pushCustomAgenda(teamId: string, customAgendaId: string) {
    return this.http
      .post<void>(`/api/v4/Teams/${teamId}/pushCustomAgenda/${customAgendaId}`, {})
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not push team meeting agenda. Please try again.'))
      );
  }

  update(teamId: string, update: Partial<Omit<Team, 'settings'>>): Observable<void> {
    this.updateTeamInMemory(teamId, update); // Updates Ngrx
    return this.http
      .patch<void>(`/api/v4/Teams/${teamId}`, update)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not update team.  Please try again.')));
  }

  updateSettings(teamId: string, update: Partial<TeamSettings>) {
    this.updateTeamInMemory(teamId, { settings: update }); // Updates Ngrx
    return this.http
      .patch<void>(`/api/v4/Teams/${teamId}/Settings`, update)
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not update team settings.  Please try again.'))
      );
  }

  deleteCustomAgenda(teamId: string, agendaId: string, updateTeamInMemory = true): Observable<MeetingAgenda[]> {
    return this.http.delete<MeetingAgenda[]>(`/api/v4/Teams/${teamId}/DeleteCustomAgenda/${agendaId}`).pipe(
      tap(custom => {
        if (updateTeamInMemory) this.updateTeamInMemory(teamId, { settings: { custom } });
        this.store.dispatch(teamsStateActions.deleteCustomAgenda({ teamId, agendaId }));
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not delete custom agenda.  Please try again.'))
    );
  }

  delete(teamId: string): Observable<void> {
    return this.http.delete<void>(`/api/v4/Teams/${teamId}`).pipe(
      tap(() => this.teamsStateFacade.removeOne({ _id: teamId })),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not delete team.  Please try again.'))
    );
  }

  private addTeamInMemory(newTeam: Team): void {
    this.teamsStateFacade.addOne({ team: newTeam });

    this.userTeams = this.sortByNamePipe.transform([...this.userTeams, newTeam]);
    this.userTeams$.next(this.userTeams);

    // todo -  this needs some major refactoring.  This is just a bug fix, so making a ticket for it.
    // we only store the teams in ngrx, not teams with embedded users, so either need to fetch users on click,
    // or update what keep in the store.
    // add to all teams too....
    this.allTeams = this.sortByNamePipe.transform([...this.allTeams, newTeam]);
    this.allTeams$.next(this.allTeams);
  }

  // https://traxion.atlassian.net/browse/DEV-2291
  //TODO: test all places where updateTeamInMemory is used (scorecard, meetings etc)
  //TODO: updateTeamMeetingAgendaByType could be removed and replaced with a simple updateSettings
  updateTeamInMemory(
    teamId: string,
    update: Partial<Omit<Team, 'settings'>> & { settings?: Partial<TeamSettings> }
  ): void {
    let team = this.allTeams.find(t => t._id === teamId);

    if (team) team = _merge(_cloneDeep(team), update);

    // We pass the whole team instead of the update, because this method has weird interface
    // where it may do a partial update on team settings, and we don’t want to lose any,
    // so we use the result of the merge
    this.teamsStateFacade.updateOne(team._id, team);

    let userTeam = this.userTeams.find(t => t._id === teamId);
    if (userTeam) userTeam = _merge(_cloneDeep(userTeam), update);
    this.userTeams$.next(this.userTeams);

    let selectedTeam = this.filterService.selectedTeam$.value;
    if (selectedTeam._id === teamId) {
      selectedTeam = _merge(_cloneDeep(selectedTeam), update);
      this.filterService.selectedTeam$.next(selectedTeam);
    }
  }

  public getDefaultAgendas(bos?: BusinessOperatingSystem): Observable<MeetingAgendas> {
    const params = QueryParamsService.build(
      {
        bos,
      },
      true
    );
    return this.http.get<MeetingAgendas>(`/api/v4/Templates/DefaultAgendas`, { params }).pipe(
      tap(agendas => this.defaultMeetingAgendas$.next(agendas)),
      catchError((e: unknown) => this.errorService.notify(e, 'An error occurred retrieving default meeting agendas.'))
    );
  }
}
