import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/_shared/user';

import { RoleCode } from './../models/_shared/role-code';

@Pipe({
  name: 'filterUsersByRole',
})
export class FilterUsersByRolePipe implements PipeTransform {
  transform(users: User[], role: RoleCode, doInverse = false, ...args: unknown[]): User[] {
    // Return users who do not have role
    if (doInverse) {
      return users.filter((u: User) => u.roleCode !== role);
    }

    return users.filter((u: User) => u.roleCode === role);
  }
}
