import { createActionGroup, props } from '@ngrx/store';

import type { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';

export const IssuesActions = createActionGroup({
  source: 'Issues',
  events: {
    'Create Issue': props<{ issue: Issue; eventSource: 'regular' | 'inline' }>(),
    'Complete Issue': props<{ issue: Issue; eventSource: 'issues page' | 'meeting' }>(),
  },
});
