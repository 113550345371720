import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { TerraIconName, TerraSizing } from '@ninety/terra';

@Component({
  selector: 'ninety-icon-with-count',
  template: `
    <div
      *ngIf="count && count > 0"
      [class]="'wrapper ' + (containerClasses ?? '')"
      fxHide.lt-sm
      [matTooltip]="tooltip"
      matTooltipClass="ninety-tooltip"
      matTooltipPosition="above">
      <terra-icon [icon]="key" [size]="iconSize" />
      <div class="count">{{ count }}</div>
    </div>
  `,
  styleUrls: ['attachments-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconWithCountComponent implements OnChanges {
  tooltip?: string = undefined;

  @Input() icon!: string;
  @Input() iconSize: TerraSizing = 24;
  @Input() containerClasses?: string;
  @Input() count?: number;

  key: TerraIconName;

  ngOnChanges(): void {
    if (!(this.icon && this.count)) return;

    let type: 'attachment' | 'comment';
    switch (this.icon) {
      case 'attach_file':
        type = 'attachment';
        break;
      case 'chat':
        type = 'comment';
        break;
    }
    this.key = type;

    // Unknown icon, no tooltip
    if (!type) {
      this.tooltip = undefined;
      return;
    }

    if (this.count > 1) type += 's';
    this.tooltip = `${this.count} ${type}`;
  }
}
