import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TerraIconName, TerraIconVariant } from '@ninety/terra';
import { UserTutorialType } from '@ninety/ui/legacy/shared/models/_shared/user-tutorial-type';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';

import { CreateDialogService } from '../../services/create-dialog.service';

@Component({
  selector: 'ninety-empty-list-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="widgetErrorState" class="empty-list-view widget-error">
      <terra-icon *ngIf="iconName" [icon]="iconName" [variant]="iconVariant" size="80" />
      <div class="title-box terra-title-small">{{ title }}</div>
      <div class="widget-message-box terra-body" *ngIf="customMessage">{{ customMessage }}</div>
    </div>
    <ng-container *ngIf="!showBannerEmptyState && !widgetErrorState">
      <div class="empty-list-view widget-error">
        <terra-icon *ngIf="iconName && !showTutorialVideo" [icon]="iconName" [variant]="iconVariant" size="96" />
        <img *ngIf="imageUrl" [src]="imageUrl" />
        <vidyard-widget
          *ngIf="showTutorialVideo && videoId"
          [videoId]="videoId"
          [videoType]="videoType"
          [tutorialType]="tutorialType"></vidyard-widget>
        <div attr.data-cy="empty-list-view_{{ title }}" class="title-box terra-title">{{ title }}</div>
        <div class="message-box terra-body" *ngIf="customMessage">{{ customMessage }}</div>

        <ninety-button
          filled
          primary
          attr.data-cy="empty-list-view_{{ buttonLabel }}"
          class="empty-list-button"
          (click)="onClickOpenDetailView()"
          *ngIf="!isObserver && buttonLabel">
          {{ buttonLabel }}
        </ninety-button>
        <div class="button-container-container">
          <div class="button-container">
            <ninety-button
              data-cy="empty-list-view_button_primary-button"
              [attr.data-product]="dataProductPrimaryButton"
              class="primary-button full-width center-all"
              filled
              primary
              *ngIf="primaryButtonLabel"
              (click)="onPrimaryButtonClick()">
              {{ primaryButtonLabel }}
            </ninety-button>
            <ninety-button
              class="full-width center-all"
              stroked
              *ngIf="secondaryButtonLabel"
              (click)="onSecondaryButtonClick()">
              {{ secondaryButtonLabel }}
            </ninety-button>
          </div>
        </div>
        <ng-content></ng-content>
        <div class="link-box" *ngIf="linkUrl">
          <a data-cy="empty-list-view_link-url" target="_blank" [href]="linkUrl">{{ linkText }}</a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showBannerEmptyState">
      <div class="banner-view">
        <ninety-empty-list-view-banner
          [bannerText]="bannerText"
          [showBannerEmptyStateNoCardStyle]="showBannerEmptyStateNoCardStyle"
          [bannerTitle]="bannerTitle">
        </ninety-empty-list-view-banner>
      </div>
    </ng-container>
  `,
  styleUrls: ['empty-list-view.component.scss'],
})
export class EmptyListViewComponent implements OnInit {
  /**
   * title to be displayed in large font
   */
  @Input() title: string;
  /**
   * Terra icon to be used
   */
  @Input() iconName: TerraIconName;
  /**
   * Terra icon variant, default is regular
   */
  @Input() iconVariant: TerraIconVariant = 'regular';
  /**
   * url of the image you wish to use.
   */
  @Input() imageUrl: string;
  /**
   * custom message string to be shown in smaller font
   */
  @Input() customMessage: string;
  /**
   * @deprecated the label of the button if you are using the built-in button that will open a detail view.
   * To use your own button, simply add a button or other content inside the component HTML and it will be shown via angular transclusion.
   */
  @Input() buttonLabel: string;
  /**
   * label for the primary button
   */
  @Input() primaryButtonLabel: string;
  /**
   * label for the secondary button
   */
  @Input() secondaryButtonLabel: string;
  /**
   * text for the "more info" link at the bottom
   */
  @Input() linkText: string;
  /**
   * url of the link
   */
  @Input() linkUrl: string;
  /**
   * Banner text. This will show only if showBannerEmptyState = true.
   */
  @Input() bannerText: string;
  /**
   * @deprecated type of item you wish the built-in button to open in the detail view
   */
  @Input() itemType?: ItemType;
  /**
   * Banner Empty State is an alternate display mode that displays as a banner without a link, button or icon. You need to use the
   * bannerText and bannerTitle properties with this flag instead of title and customMessage.
   */
  @Input() showBannerEmptyState = false;
  /**
   * No Card Style will disable the "card" styling that normally surrounds the banner with rounded corners and a dropshadow.
   */
  @Input() showBannerEmptyStateNoCardStyle: boolean;
  /**
   * Banner title. this will show only if showBannerEmptyState = true.
   */
  @Input() bannerTitle = '';
  /**
   * @deprecated Setting this to true will remove the built-in create button, even if you have provided a buttonLabel.
   * stateService.isObserver is usually what is passed in to this property.
   */
  @Input() isObserver: boolean;
  /**
   * set to true if using this component to show an error loading a particular widget
   */
  @Input() widgetErrorState: boolean;
  /**
   * set to true if you want to show a tutorial video
   */
  @Input() showTutorialVideo: boolean;
  /**
   * id of the video to show from vidyard
   */
  @Input() videoId: string;
  /**
   * type that represents how the video will be displayed from vidyard
   */
  @Input() videoType: string;
  /**
   * type that represents the tutorial type for the user
   */
  @Input() tutorialType: UserTutorialType;

  /**
   * data attribute for product gainsight mapping to the primary button click
   */
  @Input() dataProductPrimaryButton = 'empty-list-view__primary-button';
  /**
   *Stream that emits when the primary button is clicked
   */
  @Output() primaryButtonClick = new EventEmitter<void>();
  /**
   *Stream that emits when the primary button is clicked
   */
  @Output() secondaryButtonClick = new EventEmitter<void>();

  constructor(private createDialogService: CreateDialogService) {}

  ngOnInit() {
    if (this.widgetErrorState) {
      if (!this.iconName) {
        this.iconName = 'face-frown';
      }
      if (!this.title) {
        this.title = `We couldn't load this widget.`;
      }
      if (!this.customMessage) {
        this.customMessage = `It's nothing you did. Try refreshing the page. If that fails, reach out to us for help!`;
      }
    }
  }

  onClickOpenDetailView() {
    if (this.itemType) this.createDialogService.open({ itemType: this.itemType }).subscribe();
    else this.createDialogService.open().subscribe();
  }

  onPrimaryButtonClick() {
    this.primaryButtonClick.emit();
  }

  onSecondaryButtonClick() {
    this.secondaryButtonClick.emit();
  }
}
