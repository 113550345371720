import { Component } from '@angular/core';

import { MazMessageSender } from '@ninety/getting-started/maz/models/maz-message-sender.enum';

@Component({
  selector: 'ninety-maz-waiting-for-response',
  template: `<div class="message-box">
    <div class="maz-icon"><terra-icon icon="sparkle" variant="fill" size="12" /></div>
    <div class="chat-ellipsis"></div>
    <!--<div>Just a second...</div>-->
  </div>`,
  styleUrls: ['./maz-waiting-for-response.component.scss'],
})
export class MazWaitingForResponseComponent {
  protected readonly MazMessageSender = MazMessageSender;
}
