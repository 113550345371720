import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, filter, map, of, tap } from 'rxjs';
import Stripe from 'stripe';

import { CustomerInvoices } from '../../_shared/models/billing/customer-invoice';
import { CustomerSubscriptionMetadata } from '../../_shared/models/billing/customer-subscription-metadata';
import { Payment } from '../../_shared/models/billing/payment';
import { Company } from '../../_shared/models/company/company';
import { CompanyUser } from '../../_shared/models/company/company-user';
import { BillingPlanType } from '../../_shared/models/enums/billing-plan-type';
import { PlanIds } from '../../_shared/models/enums/plan-ids';
import { CompanyActions } from '../../_state/app-global/company/company-state.actions';
import { STRIPE_BILLING_PATH } from '../guards/active-subscription.guard';

import { ErrorService } from './error.service';
import { NotifyService } from './notify.service';
import { QueryParamsService } from './query-params.service';
import { StateService } from './state.service';
import { UserService } from './user.service';

export interface UserBillingCount {
  total: number;
  directBillable: number;
  billingUserBillable: number;
  nonbillable: number;
}

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private static readonly pathApprovelist = [
    'my-focus',
    'home',
    'charts',
    'admin',
    'directory',
    'settings',
    'billing',
    'icons',
  ];
  userBillingCount$ = new BehaviorSubject<UserBillingCount>({
    total: 0,
    directBillable: 0,
    billingUserBillable: 0,
    nonbillable: 0,
  });
  hasPaymentMethod$ = new BehaviorSubject<boolean>(false);

  private billingUrl = 'api/v4/Billing';
  private customerUrl = (customerId: string) => `${this.billingUrl}/Customer/${customerId}`;
  private subscriptionUrl = (subscriptionId?: string) => `${this.billingUrl}/Subscription/${subscriptionId || ''}`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private notifyService: NotifyService,
    public stateService: StateService,
    public userService: UserService,
    private router: Router,
    private store: Store
  ) {
    this.stateService.currentCompanyUser$
      .pipe(
        distinctUntilChanged((u1: CompanyUser, u2: CompanyUser) => u1?._id === u2?._id),
        filter(u => !!u)
      )
      .subscribe({
        next: () => {
          if (
            !this.stateService.stripeStatusActive &&
            !this.stateService.currentCompanyUser?.company?.implementerFree &&
            !this.stateService.currentPerson$.value.isImplementer &&
            !this.stateService.currentCompanyUser?.company?.subscription
          ) {
            if (this.stateService.isAdminOrOwner && !location.href.includes('settings')) {
              const url = `/settings/${STRIPE_BILLING_PATH}`;
              this.router.navigateByUrl(url);
            } else if (!BillingService.isAllowedLocationForUnpaid()) {
              this.router.navigateByUrl('/home');
            }
          }
        },
      });
  }

  public static isAllowedLocationForUnpaid(): boolean {
    return BillingService.pathApprovelist.some(p => location.href.includes(p));
  }
  //@ts-ignore  stripe types are FUBAR
  applyCouponToCustomer(customerId: string, couponId: string): Observable<Stripe.customers.ICustomer> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .patch<Stripe.customers.ICustomer>(this.customerUrl(customerId), { coupon: couponId })
        .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not apply coupon.  Please try again.')))
    );
  }

  /**
   * @returns the cancelled subscriptions
   */
  //@ts-ignore  stripe types are FUBAR
  cancelCustomerSubscriptions(customerId: string): Observable<Stripe.subscriptions.ISubscription[]> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .delete<Stripe.subscriptions.ISubscription[]>(`${this.customerUrl(customerId)}/Subscriptions`)
        .pipe(
          catchError((e: unknown) => this.errorService.notify(e, 'Could not cancel subscription.  Please try again.'))
        )
    );
  }

  getBillingCounts(companyId: string): Observable<UserBillingCount> {
    return this.http.get<UserBillingCount>(`/api/v4/Companies/${companyId}/Billing/Users/Count`).pipe(
      tap(billingCount => {
        this.userBillingCount$.next(billingCount);
      }),
      catchError((e: unknown) => this.errorService.oops(e))
    );
  }

  updateBillingCount(): Observable<void> {
    return this.http.patch<void>(this.billingUrl, null).pipe(catchError((e: unknown) => this.errorService.oops(e)));
  }
  //@ts-ignore  stripe types are FUBAR
  getCoupon(couponId: string): Observable<Stripe.coupons.ICoupon> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .get<Stripe.coupons.ICoupon>(`${this.billingUrl}/Coupon/${couponId}`)
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  //@ts-ignore  stripe types are FUBAR
  getCustomer(customerId: string): Observable<Stripe.customers.ICustomer> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .get<Stripe.customers.ICustomer>(this.customerUrl(customerId))
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  getCustomerSubscriptionMetadata(customerId: string): Observable<CustomerSubscriptionMetadata> {
    return this.http.get<CustomerSubscriptionMetadata>(`${this.customerUrl(customerId)}/Subscription/Metadata`).pipe(
      // eslint-disable-next-line
      catchError((e: any) => {
        this.errorService.oops(e);
        return of(e.error);
      })
    );
  }

  verifyCustomerSubscriptionActive(
    company: Company = this.stateService.currentCompanyUser$.value.company
  ): Observable<boolean> {
    // Preventing this from running if have valid subscription
    if (
      this.stateService.stripeStatusActive ||
      // Preventing this from running in ACME in anything except prod
      (company._id === '5ab03c1f7b5ab7000e9b23c5' && this.stateService.isDevelopment)
    ) {
      return of(true);
    } else if (company.stripe?.customerId) {
      return this.http
        .get<CustomerSubscriptionMetadata>(`${this.customerUrl(company.stripe.customerId)}/Subscription/Metadata`)
        .pipe(
          map(() => true),
          // eslint-disable-next-line
          catchError((e: any) => of(e.status !== 402)),
          tap(statusActive => {
            this.stateService.stripeStatusActive = statusActive;
          })
        );
    }
    // fallthrough case error occurred with paddle
    return of(true);
  }

  //@ts-ignore  stripe types are FUBAR
  getCustomerUpcomingInvoice(customerId: string): Observable<Stripe.invoices.IInvoice> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .get<Stripe.invoices.IInvoice>(`${this.customerUrl(customerId)}/Invoices/Upcoming`)
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  //@ts-ignore  stripe types are FUBAR
  getSubscription(subscriptionId: string): Observable<Stripe.subscriptions.ISubscription> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .get<Stripe.subscriptions.ISubscription>(this.subscriptionUrl(subscriptionId))
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  createSubscription(planType: BillingPlanType) {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .post<Stripe.subscriptions.ISubscription[]>(this.subscriptionUrl(), { planType })
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  //@ts-ignore  stripe types are FUBAR
  resumeSubscription(subscriptionId: string): Observable<Stripe.subscriptions.ISubscription> {
    //@ts-ignore  stripe types are FUBAR
    return this.http.patch<Stripe.subscriptions.ISubscription>(this.subscriptionUrl(subscriptionId), {
      cancel_at_period_end: false,
    });
  }

  payCustomerInvoice(customerId: string, invoiceId: string): Observable<void> {
    return this.http.post<void>(`${this.customerUrl(customerId)}/Invoices`, { invoiceId });
  }

  listCustomerInvoices(customerId: string): Observable<CustomerInvoices> {
    return this.http
      .get<CustomerInvoices>(`${this.customerUrl(customerId)}/Invoices`)
      .pipe(catchError((e: unknown) => this.errorService.oops(e)));
  }

  getPayments(subscription: number): Observable<Payment[]> {
    return this.http
      .get<Payment[]>(`${this.billingUrl}/Payments`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e)));
  }

  //@ts-ignore  stripe types are FUBAR
  listCustomerPaymentMethods(customerId: string): Observable<Stripe.paymentMethods.IPaymentMethod[]> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .get<Stripe.paymentMethods.IPaymentMethod[]>(`${this.billingUrl}/Customer/${customerId}/PaymentMethod`)
        .pipe(
          tap(paymentMethods => {
            this.hasPaymentMethod$.next(paymentMethods.length > 0);
          }),
          catchError((e: unknown) => this.errorService.oops(e))
        )
    );
  }

  //@ts-ignore  stripe types are FUBAR
  periodEndCancelSubscription(subscriptionId: string): Observable<Stripe.subscriptions.ISubscription> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .patch<Stripe.subscriptions.ISubscription>(this.subscriptionUrl(subscriptionId), { cancel_at_period_end: true })
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  //@ts-ignore  stripe types are FUBAR
  periodEndResumeSubscription(subscriptionId: string): Observable<Stripe.subscriptions.ISubscription> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .patch<Stripe.subscriptions.ISubscription>(this.subscriptionUrl(subscriptionId), {
          cancel_at_period_end: false,
        })
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  //@ts-ignore  stripe types are FUBAR
  removeCouponFromSubscription(subscriptionId: string): Observable<Stripe.subscriptions.ISubscription> {
    return (
      this.http
        //@ts-ignore  stripe types are FUBAR
        .patch<Stripe.subscriptions.ISubscription>(this.subscriptionUrl(subscriptionId), { coupon: null })
        .pipe(catchError((e: unknown) => this.errorService.oops(e)))
    );
  }

  /**
   * @returns the new subscription's id
   */
  switchCustomerSubscription(customerId: string, newSubscriptionPeriod: 'annual' | 'monthly'): Observable<string> {
    let planId: (typeof PlanIds)['ANNUAL'] | (typeof PlanIds)['MONTHLY'];
    switch (newSubscriptionPeriod) {
      case 'annual':
        planId = PlanIds.ANNUAL;
        break;
      case 'monthly':
        planId = PlanIds.MONTHLY;
        break;
      default:
        throw Error(`Invalid subscription period - got '${newSubscriptionPeriod}' - expected 'monthly' | 'annual`);
    }
    return this.http
      .post<string>(`${this.customerUrl(customerId)}/Subscription/Switch`, { planId })
      .pipe(catchError((e: unknown) => this.errorService.oops(e)));
  }

  updateCustomerDefaultPaymentMethod(
    customerId: string,
    paymentMethod: stripe.paymentMethod.PaymentMethod
  ): Observable<stripe.paymentMethod.PaymentMethod> {
    return this.http
      .post<stripe.paymentMethod.PaymentMethod>(`${this.customerUrl(customerId)}/PaymentMethod/Default`, {
        ...paymentMethod,
      })
      .pipe(
        tap(paymentMethod => this.store.dispatch(CompanyActions.updateDefaultStripePaymentMethod({ paymentMethod }))),
        catchError((e: unknown) => this.errorService.oops(e))
      );
  }

  deleteCustomerPaymentMethod(
    customerId: string,
    //@ts-ignore  stripe types are FUBAR
    paymentMethodId: Stripe.paymentMethods.IPaymentMethod['id']
  ): Observable<void> {
    return this.http
      .delete<void>(`${this.customerUrl(customerId)}/PaymentMethod`, {
        params: QueryParamsService.build({ paymentMethodId }),
      })
      .pipe(catchError((e: unknown) => this.errorService.oops(e)));
  }
}
