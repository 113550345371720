import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSourcePaginator } from '@angular/material/table';
import { LetDirective } from '@ngrx/component';

import { TerraIconModule } from '@ninety/terra';

import { ElementResizeAwareDirective } from '../../_shared/directives/element-resize-aware/element-resize-aware.directive';
import { ElementResizeAwareModule } from '../../_shared/directives/element-resize-aware/element-resize-aware.module';
import { ButtonComponent } from '../buttons/button/button.component';
import { SelectModule } from '../inputs/selects/select.component';

import { PaginationComponentManagedInputs, PaginationConstants } from './pagination.component.model';
import { PaginationStore } from './pagination.component.store';

export function getLocalPageSize(key: string, defaultSize?: number): number {
  return (
    parseInt(localStorage.getItem(`${key}.pagination.size`)) || defaultSize || PaginationConstants.INITIAL_PAGE_SIZE
  );
}

// TODO this should be moved to a slice of NGRX state, leaving it here b/e I have to stop with this PR somewhere...
//  return to this in DEV-4592, need a better approach here.
export function checkIfNavigatorIsMobile() {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
  return navigator.userAgent.match(/Mobi/i) !== null;
}

/**
 * Pagination component supporting responsive design, mobile friendly defaults, and page size selection.
 *
 * NOTE, NEVER USE THE INPUTS DIRECTLY IN THIS TEMPLATE. They are only there for passing data from a parent component,
 * not for use by this template. Always source properties from the component store, as demonstrated in the template.
 */
@Component({
  selector: 'ninety-pagination',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TerraIconModule, SelectModule, CommonModule, LetDirective, ElementResizeAwareModule],
  styleUrls: ['./pagination.component.scss'],
  templateUrl: 'pagination.component.html',
  providers: [PaginationStore],
})
export class PaginationComponent implements OnInit, OnChanges, AfterViewInit, MatTableDataSourcePaginator {
  /**
   * The length of the total number of items that are being paginated. (Not the # of items currently displayed).
   */
  @Input() length: number;

  /**
   * The set of provided page size options to display to the user.
   */
  @Input() pageSizeOptions: number[] = PaginationConstants.DEFAULT_PAGE_SIZES;

  /**
   * Number of items to display on a page.
   * Can be passed as an input, but is expected to be a valid value from pageSizeOptions.
   */
  @Input() pageSize: number = PaginationConstants.INITIAL_PAGE_SIZE;

  /**
   * The current page number.
   */
  @Input() pageIndex: number = PaginationConstants.INITIAL_PAGE_INDEX;

  /**
   * When true, the page size select will be disabled.
   */
  @Input() disablePageSizeSelect = false;

  /**
   * When true, regardless of page size and current length, next and previous arrows are disabled.
   */
  @Input() disablePageChange = false;

  /**
   * When true, mobile mode is chosen regardless of the user agent.
   */
  @Input() isMobileOverride: boolean;

  /**
   * Event emitted when the paginator changes the page size or page index.
   * @see MatTableDataSourcePaginator
   */
  @Output() page = this.paginationStore.matPageState$;

  /**
   * Stream that emits once during the component's ngOnInit.
   * @see MatTableDataSourcePaginator
   */
  @Output() initialized = new EventEmitter<void>();

  @ViewChild(ElementResizeAwareDirective, { static: true }) resizeAware: ElementResizeAwareDirective;

  constructor(public readonly paginationStore: PaginationStore) {}

  ngOnInit() {
    const currentState = this.extractInitialInputs();
    this.paginationStore.initializeState(currentState);

    this.initialized.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    const updatedState = this.extractChanges(changes);
    if (!updatedState) return;

    this.paginationStore.setStateOnChanges(updatedState);
  }

  ngAfterViewInit() {
    if (!this.resizeAware) throw Error('ElementResizeAwareDirective not found');

    this.paginationStore.observeElementWidth(this.resizeAware);
  }

  firstPage(): void {
    // ToDo: Implement this
    // this.paginationStore.firstPage();
  }

  lastPage(): void {
    // ToDo: Implement this
    // this.paginationStore.lastPage();
  }

  private extractInitialInputs(): PaginationComponentManagedInputs {
    return {
      disablePageChange: this.disablePageChange,
      disablePageSizeSelect: this.disablePageSizeSelect,
      length: this.length,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      pageSizeOptions: this.pageSizeOptions,
      isMobileOverride: this.isMobileOverride,
    };
  }

  /**
   * Extracts changed inputs to forward to {@link PaginationStore.setStateOnChanges}. Filters out the first change and
   * returns null to allow calling method to short circuit.
   */
  private extractChanges(changes: SimpleChanges): Partial<PaginationComponentManagedInputs> | null {
    return Object.entries(changes).reduce((acc: Partial<PaginationComponentManagedInputs> | null, [key, change]) => {
      if (change.firstChange) return acc;
      if (!acc) acc = {};
      acc[key] = change.currentValue;
      return acc;
    }, null);
  }
}
