import { Pipe, PipeTransform } from '@angular/core';

import { Team } from '../models/_shared/team';

@Pipe({
  name: 'getTeamNameById',
})
export class GetTeamNameByIdPipe implements PipeTransform {
  transform(teamId: string, teams: Map<string, Team>): string {
    if (!teams?.has(teamId)) {
      console.log(`Team ${teamId} not found in GetTeamNameByIdPipe`);
      return '';
    }

    return teams.get(teamId).name;
  }
}
