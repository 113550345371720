import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  Subject,
  Subscription,
  catchError,
  finalize,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { PeriodMeasurable } from '@ninety/ui/legacy/shared/models/_shared/team-measurables';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { ListDropMessage } from '@ninety/ui/legacy/shared/models/meetings/list-drop-message';
import { ListSortMessage } from '@ninety/ui/legacy/shared/models/meetings/list-sort-message';
import { MeasurableChangeMessage } from '@ninety/ui/legacy/shared/models/meetings/measurable-change-message';
import { RealtimeMessage } from '@ninety/ui/legacy/shared/models/meetings/realtime-message';
import { RefreshMessage } from '@ninety/ui/legacy/shared/models/meetings/refresh-message';
import { ScoreChangeMessage } from '@ninety/ui/legacy/shared/models/meetings/score-change-message';
import { Goal } from '@ninety/ui/legacy/shared/models/scorecard/goal';
import { Measurable, MeasurableCollection } from '@ninety/ui/legacy/shared/models/scorecard/measurable';
import { MeasurableSortField } from '@ninety/ui/legacy/shared/models/scorecard/measurable-sort-field';
import { PeriodIntervalScorecard } from '@ninety/ui/legacy/shared/models/scorecard/period-interval-scorecard';
import { PeriodInterval } from '@ninety/ui/legacy/shared/models/scorecard/period-interval.enum';
import { Scorecard } from '@ninety/ui/legacy/shared/models/scorecard/scorecard';
import { ScorecardMessageType } from '@ninety/ui/legacy/shared/models/scorecard/scorecard-message-type';
import { WeeklyRange } from '@ninety/ui/legacy/shared/models/scorecard/weekly-ranges.enum';

import { MeasurableActions } from '../../_state/measurables.actions';

@Injectable({
  providedIn: 'root',
})
export class MeasurableService {
  private api = 'api.qa1.90srv.com/MeasurablesV3';
  // TODO: Figure out if this can be removed
  defaultImageSrc = 'assets/icons/ninety/90_Logo_Square_Margins_Black.svg';
  refresh$ = new Subject<string>();
  openUniversalCreate$ = new Subject<{ measurable: Measurable; itemType: ItemType }>();
  removeMeasurable$ = new Subject<string>();

  newMeasurable$ = new Subject<MeasurableChangeMessage>();
  updatedMeasurable$ = new Subject<MeasurableChangeMessage>();
  deletedMeasurable$ = new Subject<MeasurableChangeMessage>();
  updatedScore$ = new Subject<ScoreChangeMessage>();

  dropListScorecard$ = new Subject<ListDropMessage>();
  sortListScorecard$ = new Subject<ListSortMessage>();
  refreshScorecard$ = new Subject<RefreshMessage>();

  activeScorecard$ = new BehaviorSubject<Scorecard>(null);

  // Fixed by DEV-5134:
  //     This string gets initialized before the custom language is ready if it is not on-demand.
  //     The effect was an uncaught error that killed redirecting when the user was not already signed in.
  get statusTooltip() {
    return `
      Green: On-track - Reached ${this.stateService.language?.measurable.goal} over the last 3 intervals\n
      Yellow: At Risk - Missed ${this.stateService.language?.measurable.goal} at least 1x over the last 3 intervals\n
      Red: Off-track - Missed ${this.stateService.language?.measurable.goal} all 3x over the last 3 intervals
    `;
  }

  channelId: string;
  shouldBroadcast: boolean;

  messageSubscription = new Subscription();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private spinnerService: SpinnerService,
    private filterService: FilterService,
    private stateService: StateService,
    private channelService: ChannelService,
    private store: Store
  ) {}

  update(id: string, update: Partial<Measurable>): Observable<void> {
    this.refresh$.next(id);
    return this.http.patch<void>(`${this.api}/${id}`, update).pipe(
      tap(() => {
        this.broadcastMessage({
          messageType: ScorecardMessageType.measurable,
          document: {
            eventType: 'update',
            periodInterval: this.stateService.periodInterval || '',
            document: {
              _id: id,
              ...update,
            },
          },
        }).subscribe();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.measurable.item}. Please try again.`)
      )
    );
  }

  updateDefaultGoalForFuturePeriods(id: string, defaultGoal: Goal): Observable<void> {
    return this.http.patch<void>(`${this.api}/UpdateDefaultGoalForFuturePeriods/${id}`, defaultGoal).pipe(
      tap(() =>
        this.broadcastMessage({
          messageType: ScorecardMessageType.refresh,
          document: {
            // listType: 'scorecard', //could be refactored for refreshing T4W T13W
            periodInterval: this.stateService.periodInterval,
            currentTeamId: this.filterService.selectedTeamId$.getValue(),
          },
        }).subscribe()
      ),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update default goal for ${this.stateService.language.measurable.item}. Please try again.`
        )
      )
    );
  }

  createAndAddMeasurable(measurable: Measurable): Observable<Measurable> {
    const teamId = this.filterService.selectedTeamId$.value;
    return this.http.post<string>(`${this.api}/TeamScorecard?teamId=${teamId}`, measurable).pipe(
      map(mId => ({ ...measurable, _id: mId })),
      tap(m => this.store.dispatch(MeasurableActions.createAndAddMeasurable({ measurable: m, teamId }))),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not create ${this.stateService.language.measurable.item}. Please try again.`)
      )
    );
  }

  createMeasurable(measurable: Measurable): Observable<Measurable> {
    return this.http.post<string>(`${this.api}`, measurable).pipe(
      map(mId => ({ ...measurable, _id: mId })),
      tap(m => this.store.dispatch(MeasurableActions.createMeasurable({ measurable: m }))),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not create ${this.stateService.language.measurable.item}. Please try again.`)
      )
    );
  }

  /**
   * For weekly, numPeriods is always ignored. It will use preset weeklyRange, or custom date range which overrides weeklyRange
   * For non-weekly, weeklyRange is always ignored. It will use numPeriods from the periodInterval,
   * but we want custom date ran to override it.
   */
  getTeamScorecard(
    numPeriods = 13,
    page = 0,
    pageSize = 25,
    sortField: MeasurableSortField = MeasurableSortField.none,
    sortDirection: SortDirection = SortDirection.ASC,
    searchText = '',
    rangeStartDate?: string,
    rangeEndDate?: string,
    // TODO - DEV-10085 Move periodInterval and weeklyRange to ngrx
    periodInterval = this.stateService.periodInterval,
    weeklyRange = this.filterService.selectedTeam$.value.settings?.defaultWeeklyRange || WeeklyRange.rolling13Weeks
  ): Observable<Scorecard> {
    this.spinnerService.start();
    const params = QueryParamsService.build({
      teamId: this.filterService.selectedTeamId$.value,
      periodInterval,
      numPeriods,
      weeklyRange,
      page,
      pageSize,
      sortField,
      sortDirection,
      searchText,
      ...(rangeStartDate ? { rangeStartDate } : {}),
      ...(rangeEndDate ? { rangeEndDate } : {}),
    });
    return this.http.get<Scorecard>(`${this.api}/TeamScorecard`, { params }).pipe(
      tap(scorecard => this.activeScorecard$.next(scorecard)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get team ${this.stateService.language.scorecard.item}. Please try again.`
        )
      )
    );
  }

  getMeasurableById(id: string, skipLocal = true): Observable<Measurable> {
    if (skipLocal) {
      return this.http
        .get<Measurable>(`${this.api}/${id}`)
        .pipe(
          catchError((e: unknown) =>
            this.errorService.notify(
              e,
              `Could not get ${this.stateService.language.scorecard.item} by id ${id}. Please try again.`
            )
          )
        );
    }

    return this.activeScorecard$.pipe(
      take(1),
      map(scorecard => scorecard?.measurables?.find(m => m._id === id)),
      switchMap(measurable => {
        if (!!measurable) return of(measurable);

        return this.getMeasurableById(id);
      })
    );
  }

  getTeamGoalForecastingScorecard(
    page = 0,
    pageSize = 25,
    sortField: MeasurableSortField = MeasurableSortField.none,
    sortDirection: SortDirection = SortDirection.ASC,
    searchText = ''
  ): Observable<Scorecard> {
    const params = QueryParamsService.build({
      teamId: this.filterService.selectedTeamId$.value,
      periodInterval: this.stateService.periodInterval,
      page,
      pageSize,
      sortField,
      sortDirection,
      searchText,
    });
    return this.http
      .get<Scorecard>(`${this.api}/TeamScorecard/GoalForecasting`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get forecasting team ${this.stateService.language.scorecard.item}. Please try again.`
          )
        )
      );
  }

  getTeamTrailingScorecard(
    periodInterval: PeriodInterval,
    numPeriods = 13,
    page = 0,
    pageSize = 25,
    sortField: MeasurableSortField = MeasurableSortField.none,
    sortDirection: SortDirection = SortDirection.ASC,
    searchText = ''
  ): Observable<Scorecard> {
    const params = QueryParamsService.build({
      teamId: this.filterService.selectedTeamId$.value,
      periodInterval,
      numPeriods,
      page,
      pageSize,
      sortField,
      sortDirection,
      searchText,
    });
    return this.http.get<Scorecard>(`${this.api}/TeamTrailingScorecard`, { params }).pipe(
      tap(scorecard => this.activeScorecard$.next(scorecard)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get team ${this.stateService.language.scorecard.item}. Please try again.`
        )
      )
    );
  }

  getUserScorecard(numPeriods = 13): Observable<Scorecard> {
    const periodInterval =
      this.stateService.currentUser.settings.myMeasurablesPeriodInterval || this.stateService.periodInterval;
    return this.http
      .get<Scorecard>(`${this.api}/UserScorecard?periodInterval=${periodInterval}&numPeriods=${numPeriods}`)
      .pipe(
        tap(scorecard => this.activeScorecard$.next(scorecard)),
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get your ${this.stateService.language.scorecard.item}. Please try refreshing the page.`
          )
        )
      );
  }

  getLiteUserScorecard(numPeriods = 13): Observable<Scorecard> {
    const periodInterval =
      this.stateService.currentUser.settings.myMeasurablesPeriodInterval || this.stateService.periodInterval;
    return this.http.get<Scorecard>(`${this.api}/Lite?periodInterval=${periodInterval}&numPeriods=${numPeriods}`).pipe(
      tap(scorecard => this.activeScorecard$.next(scorecard)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get your ${this.stateService.language.scorecard.item}. Please try refreshing the page.`
        )
      )
    );
  }

  getFullScorecardForConversation(
    conversationId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<PeriodIntervalScorecard> {
    const params = QueryParamsService.build({ startDate, endDate }, true);
    return this.http
      .get<PeriodIntervalScorecard>(`${this.api}/FullScorecardForConversation/${conversationId}`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.scorecard.item} for this ${this.stateService.language.feedback.item}.
            Please try refreshing the page.`
          )
        )
      );
  }

  /**
   * @param measurableIds - actually ids to omit, not ids to find
   */
  addTeamMeasurables(measurableIds: string[], periodInterval = this.stateService.periodInterval): Observable<void> {
    const params = QueryParamsService.build({
      teamId: this.filterService.selectedTeamId$.value,
      periodInterval,
    });
    return this.http.post<void>(`${this.api}/Team`, { measurableIds }, { params }).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not add ${this.stateService.language.measurable.items}
             to ${this.stateService.language.scorecard.item}. Please try again.`
        )
      )
    );
  }

  addLiteMeasurables(measurableIds: string[], periodInterval: PeriodInterval): Observable<void> {
    const params = QueryParamsService.build({
      periodInterval,
    });
    return this.http.patch<void>(`${this.api}/Lite`, { measurableIds }, { params }).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not add ${this.stateService.language.measurable.items}
            to ${this.stateService.language.scorecard.item}. Please try again.`
        )
      )
    );
  }

  updateTeamMeasurablesOrder(
    periodMeasurables: PeriodMeasurable[],
    toggleSpinner = true,
    periodInterval = this.stateService.periodInterval
  ): Observable<void> {
    if (toggleSpinner) this.spinnerService.start();
    const params = QueryParamsService.build({
      periodInterval,
      teamId: this.filterService.selectedTeamId$.value,
    });
    return this.http.patch<void>(`${this.api}/Team`, { periodMeasurables }, { params }).pipe(
      tap(() => {
        if (toggleSpinner) this.spinnerService.stop();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update team ${this.stateService.language.scorecard.item} order. Please try again.`
        )
      )
    );
  }

  updateTeamMeasurablesOrderFromSort(
    sortField: MeasurableSortField,
    sortDirection: SortDirection,
    toggleSpinner = true,
    periodInterval = this.stateService.periodInterval
  ): Observable<void> {
    if (toggleSpinner) this.spinnerService.start();
    const params = QueryParamsService.build({
      sortField,
      sortDirection,
      periodInterval,
      teamId: this.filterService.selectedTeamId$.value,
    });
    return this.http.patch<void>(`${this.api}/Team/SortBy`, null, { params }).pipe(
      tap(() => {
        if (toggleSpinner) this.spinnerService.stop();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update team
          ${this.stateService.language.scorecard.item} sort order. Please try again.`
        )
      )
    );
  }

  updateTeamMeasurablesOrderTrailing(
    measurables: Measurable[],
    toggleSpinner = true,
    periodInterval = this.stateService.periodInterval
  ): void {
    if (toggleSpinner) this.spinnerService.start();
    const periodMeasurables: PeriodMeasurable[] = measurables.map((m, i) => ({ measurableId: m._id, ordinal: i }));
    const params = QueryParamsService.build({
      periodInterval,
      teamId: this.filterService.selectedTeamId$.value,
    });
    this.http
      .patch<void>(`${this.api}/Team`, { periodMeasurables }, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update team ${this.stateService.language.scorecard.item} order. Please try again.`
          )
        )
      )
      .subscribe({
        next: () => {
          if (toggleSpinner) this.spinnerService.stop();
        },
      });
  }

  updateUserMeasurablesOrder(
    measurables: Measurable[],
    periodInterval = this.stateService.currentUser.settings.myMeasurablesPeriodInterval
  ): void {
    const periodMeasurables: PeriodMeasurable[] = measurables.map((m, i) => ({ measurableId: m._id, ordinal: i }));
    this.http
      .patch<void>(`${this.api}/User?periodInterval=${periodInterval}`, { periodMeasurables })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update ${this.stateService.language.my90.route}
            ${this.stateService.language.measurable.items} order. Please try again.`
          )
        )
      )
      .subscribe();
  }

  removeTeamMeasurable(measurableId: string, periodInterval = this.stateService.periodInterval): Observable<void> {
    this.spinnerService.start();
    const params = QueryParamsService.build({
      teamId: this.filterService.selectedTeamId$.value,
      periodInterval,
      measurableId,
    });
    return this.http.delete<void>(`${this.api}/Team`, { params }).pipe(
      tap(() => {
        this.spinnerService.stop();
        this.broadcastMessage({
          messageType: ScorecardMessageType.measurable,
          document: {
            eventType: 'delete',
            periodInterval: this.stateService.periodInterval || '',
            document: {
              _id: measurableId,
            },
          },
        }).subscribe();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not delete ${this.stateService.language.measurable.item}. Please try again.`)
      )
    );
  }

  getMeasurables(
    measurableIds: string[],
    page: number,
    pageSize: number,
    searchTerm: string,
    periodInterval?: PeriodInterval
  ): Observable<MeasurableCollection> {
    // Prevents ExpressionChangedAfterItHasBeenCheckedError when used from v2 AddKpiDialog
    setTimeout(() => this.spinnerService.start());

    return this.http
      .post<MeasurableCollection>(`${this.api}/Paged`, {
        measurableIds: measurableIds,
        page,
        pageSize,
        periodInterval,
        searchTerm,
      })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.measurable.items}. Please try again.`)
        ),
        finalize(() => setTimeout(() => this.spinnerService.stop()))
      );
  }

  deleteMeasurable(measurableId: string): void {
    this.spinnerService.start();
    this.http
      .delete<void>(`${this.api}/${measurableId}`)
      .pipe(
        tap(() => {
          this.removeMeasurable$.next(measurableId);
          this.spinnerService.stop();
        }),
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not delete ${this.stateService.language.measurable.item}. Please try again.`
          )
        )
      )
      .subscribe();
  }

  broadcastMessage(message: RealtimeMessage): Observable<any> {
    if (this.shouldBroadcast) {
      return this.channelService.sendMessage(this.channelId, message);
    } else {
      return NEVER;
    }
  }

  subscribeToMeasurableChannel(teamId: string) {
    this.channelId = `measurable-${this.stateService.companyId}-${teamId}`;
    this.shouldBroadcast = true;

    this.subscribeToMessages();
  }

  subscribeToMessages() {
    this.messageSubscription = this.channelService.messageReceived$.subscribe({
      next: message => {
        switch (message.messageType) {
          case ScorecardMessageType.measurable:
            this.executeMeasurableMessage(message.document);
            break;
          case ScorecardMessageType.drop:
            this.dropListScorecard$.next(message.document);
            break;
          case ScorecardMessageType.sort:
            this.sortListScorecard$.next(message.document);
            break;
          case ScorecardMessageType.refresh:
            this.refreshScorecard$.next(message.document);
            break;
          case ScorecardMessageType.score:
            this.updatedScore$.next(message.document);
            break;
        }
      },
      error: (err: unknown) => console.error(err),
    });
  }

  executeMeasurableMessage(message: MeasurableChangeMessage) {
    switch (message.eventType) {
      case 'add':
        this.newMeasurable$.next(message);
        break;
      case 'update':
        this.updatedMeasurable$.next(message);
        break;
      case 'delete':
        this.deletedMeasurable$.next(message);
        break;
    }
  }

  destroyMeasurableChannel() {
    this.messageSubscription.unsubscribe();
  }
}
