import { datadogLogs } from '@datadog/browser-logs';

import { LoggerName } from '../../../_state/app-logging/logging-feature-flag-state';

import { DatadogLogger } from './datadog-logger';

/**
 * Use this when you need to log outside a DI context:
 *
 * Eg:
 *  - Logging in static methods
 *  - Logging in decorators
 */
export class DatadogLoggerStatic {
  private static logger: DatadogLogger;

  static getLogger() {
    if (!DatadogLoggerStatic.logger) {
      const ddLogger = datadogLogs.getLogger(LoggerName.AppModule);
      this.logger = new DatadogLogger(ddLogger);
    }

    return DatadogLoggerStatic.logger;
  }
}
