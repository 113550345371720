import { Injectable } from '@angular/core';
import ObjectID from 'bson-objectid';
import { cloneDeep, merge } from 'lodash';
import { Observable, map } from 'rxjs';

import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { CascadedSections } from '@ninety/ui/legacy/shared/models/vto/cascaded-sections';
import { ReadonlyVtoBosDependentDefaults, Vto } from '@ninety/ui/legacy/shared/models/vto/vto';
import {
  EmptyPinnacleSections,
  PinnacleSectionLabels,
  VtoCustomSectionSettings,
  VtoSectionSettings,
} from '@ninety/ui/legacy/shared/models/vto/vto-sections';
import { VtoNormalizerOptsFactoryService } from '@ninety/vto/services/vto-normalizer-opts-factory.service';

export interface VtoPair {
  sltVto: Vto;
  teamVto: Vto;
}

export interface VtoNormalizerOpts {
  bos: BusinessOperatingSystem;
  usingNinetyFlag: boolean;
  usingPinnacleFlag: boolean;
  defaults: ReadonlyVtoBosDependentDefaults;
}

@Injectable({
  providedIn: 'root',
})
export class VtoNormalizerService {
  constructor(private vtoNormalizerOptsFactoryService: VtoNormalizerOptsFactoryService) {}

  getOptsAndNormalize(vto: Vto, opts?: Partial<VtoNormalizerOpts>): Observable<Vto> {
    return this.vtoNormalizerOptsFactoryService
      .getCompleteOpts(opts)
      .pipe(map(completeOpts => this.normalize(vto, completeOpts)));
  }

  normalize(unNormalizedVto: Vto, opts: VtoNormalizerOpts): Vto {
    const normalized: Partial<Vto> = {
      customSectionSettings: VtoNormalizerService.normalizeCustomSectionSettings(unNormalizedVto, opts),
      sectionSettings: VtoNormalizerService.normalizeDefaultSectionSettings(unNormalizedVto, opts),
      cascadedSections: VtoNormalizerService.normalizeCascadedSections(unNormalizedVto, opts),
      labels: unNormalizedVto.labels ?? opts.defaults.labels,
    };
    if (opts.bos === BusinessOperatingSystem.pinnacle) {
      Object.entries(EmptyPinnacleSections).forEach(([pinnacleKey, pinnacleEmptySection]) => {
        normalized[pinnacleKey] = unNormalizedVto[pinnacleKey] ?? pinnacleEmptySection;
      });
    }

    return merge(cloneDeep(unNormalizedVto), normalized);
  }

  private static normalizeCustomSectionSettings(vto: Vto, opts: VtoNormalizerOpts): Vto['customSectionSettings'] {
    if (!vto.customSectionSettings) return opts.defaults.customSectionSettings as VtoCustomSectionSettings[];

    return vto.customSectionSettings.map(section => ({
      ...section,
      ...VtoNormalizerService.normalizeGridProperties(section),
      _id: VtoNormalizerService.ensureSectionKey(section),
    }));
  }

  private static normalizeDefaultSectionSettings(vto: Vto, opts: VtoNormalizerOpts): Vto['sectionSettings'] {
    if (!vto.sectionSettings)
      return opts.defaults.sectionSettings.map(current => ({
        ...current,
        _id: this.generateId(),
      })) as VtoSectionSettings[];

    // Note, CascadedSections is really just a general purpose record of section key to boolean. Future refactors will
    // seek to make CascadedSections more generic to make these usages more self-documenting/general purpose
    const hasSections = CascadedSections.allFalse();

    const normalized = vto.sectionSettings.map(section => {
      hasSections[section.section] = true;
      return {
        ...section,
        ...VtoNormalizerService.normalizeGridProperties(section),
        _id: VtoNormalizerService.ensureSectionKey(section),
      };
    });

    if (opts.bos === BusinessOperatingSystem.ninetyOS && opts.usingNinetyFlag)
      return this.normalizeNinetyDefaultSections(normalized, hasSections);
    else if (opts.bos === BusinessOperatingSystem.pinnacle && opts.usingPinnacleFlag)
      return this.normalizePinnacleDefaultSections(normalized, hasSections);
    else return this.normalizeEOSDefaultSections(normalized, hasSections);
  }

  private static normalizeNinetyDefaultSections(
    normalized: VtoSectionSettings[],
    hasSections: CascadedSections
  ): VtoSectionSettings[] {
    // No edge case
    if (hasSections.niche && hasSections.purpose) return normalized;

    // Protect against bad data received from the db: a company is expected to have niche/purpose b/e of their
    // BOS/flags, but doesn't. Add niche/purpose to bottom.
    if (!hasSections.niche) {
      normalized.push({
        ...VtoSectionSettings.ADDED_AFTER_CONVERSION_TO_NINETY.niche,
        _id: this.generateId(),
      });
    }

    if (!hasSections.purpose) {
      normalized.push({
        ...VtoSectionSettings.ADDED_AFTER_CONVERSION_TO_NINETY.purpose,
        _id: this.generateId(),
      });
    }

    return normalized;
  }

  private static normalizePinnacleDefaultSections(
    normalized: VtoSectionSettings[],
    hasSections: CascadedSections
  ): VtoSectionSettings[] {
    Object.keys(PinnacleSectionLabels).forEach(key => {
      if (!hasSections[key]) {
        normalized.push({
          ...VtoSectionSettings.ADDED_AFTER_CONVERSION_TO_PINNACLE[key],
          _id: this.generateId(),
        });
      }
    });

    return normalized;
  }

  private static normalizeEOSDefaultSections(
    normalized: VtoSectionSettings[],
    hasSections: CascadedSections
  ): VtoSectionSettings[] {
    // When BOS is changed from 90os to EOS
    if (!hasSections.issuesList) {
      const standardIssueList = VtoSectionSettings.StandardSettingsByBosLookup.EOS.find(
        s => s.section === 'issuesList'
      );
      normalized.push({
        ...standardIssueList,
        _id: this.generateId(),
      });
    }
    // No edge case
    if (hasSections.coreFocus) return normalized;

    if (hasSections.niche && hasSections.purpose) {
      // Existing purpose becomes CoreFocus. Niche/purpose filtered out when rendering grid.
      return this.createCoreFocusFromExistingSectionType(normalized, 'purpose');
    } else if (!hasSections.niche && !hasSections.purpose) {
      // Very unlikely, but handle just in case - just use standard CoreFocus
      const standardCoreFocus = VtoSectionSettings.StandardSettingsByBosLookup.EOS.find(s => s.section === 'coreFocus');
      normalized.push({
        ...standardCoreFocus,
        _id: this.generateId(),
      });
      return normalized;
    } else {
      const key = hasSections.niche ? 'purpose' : 'niche';
      return this.createCoreFocusFromExistingSectionType(normalized, key);
    }
  }

  private static createCoreFocusFromExistingSectionType(normalized: VtoSectionSettings[], key: string) {
    const expectedDefinedSection = normalized.find(s => s.section === key);
    const mergedCoreFocus: VtoSectionSettings = {
      ...expectedDefinedSection,
      section: 'coreFocus',
      _id: this.generateId(),
    };

    normalized.push(mergedCoreFocus);
    return normalized;
  }

  private static normalizeCascadedSections(vto: Vto, opts: VtoNormalizerOpts): Vto['cascadedSections'] {
    const clone = cloneDeep(vto.cascadedSections);

    // No edge case - bos/flag
    if (opts.bos !== BusinessOperatingSystem.ninetyOS || !opts.usingNinetyFlag) return clone;

    // No edge case - properties are defined
    if (vto.cascadedSections.niche !== undefined && vto.cascadedSections.purpose !== undefined) return clone;

    // Edge case - marked as Ninety and flag is on, but niche and purpose never set.
    clone.niche = vto.cascadedSections.coreFocus;
    clone.purpose = vto.cascadedSections.coreFocus;

    return clone;
  }

  private static normalizeGridProperties(
    section: VtoSectionSettings
  ): Pick<VtoSectionSettings, 'x' | 'y' | 'rows' | 'cols'> {
    return {
      rows: (section.rows ||= 1),
      cols: (section.cols ||= 1),
      x: (section.x ??= 0),
      y: (section.y ??= 0),
    };
  }

  /**
   * Ensure every section setting has an _id for ngFor tracking and GridStack < - > setting alignment.
   *
   * Custom sections have an _id as they are nested objects of the VTO. However, default sections - those identified
   * above in VtoSectionKeys - do not have an ID.
   */
  private static ensureSectionKey(vtoSection: VtoSectionSettings): Vto['_id'] {
    if (!vtoSection._id) {
      const newId = this.generateId();
      console.warn(`Vto section "${vtoSection.section}" was missing an id. Setting to valid value: ${newId}`);
      return newId;
    } else if (!ObjectID.isValid(vtoSection._id)) {
      const newId = this.generateId();
      console.warn(`Vto section "${vtoSection.section}" had an invalid ID. Setting to valid value: ${newId}`);
      return newId;
    }

    return vtoSection._id;
  }

  private static generateId() {
    return new ObjectID().toHexString();
  }
}
