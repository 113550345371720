import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { PoliciesActions } from '../../../_state/app-global/policies/policies.actions';
import { selectPrivacyPolicy } from '../../../_state/app-global/policies/policies.selectors';

@Component({
  selector: 'ninety-privacy-policy-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, ButtonComponent, MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h1 mat-dialog-title data-cy="privacy-policy-dialog_header">Privacy Policy</h1>

    <mat-dialog-content id="privacy-policy" aria-label="Privacy Policy">
      <!-- Policy loaded, either cached or from HubSpot -->
      <ng-container *ngIf="policy$ | async as policy; else loading">
        <div [innerHTML]="policy"></div>
      </ng-container>

      <!-- Loading, initial state -->
      <ng-template #loading>
        <mat-spinner color="accent"></mat-spinner>
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <ninety-button stroked mat-dialog-close="false" type="button" data-cy="privacy-policy-dialog_close-button">
        Close
      </ninety-button>
    </mat-dialog-actions>
  `,
})
export class PrivacyPolicyDialogComponent implements OnInit {
  policy$ = this.store.select(selectPrivacyPolicy);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(PoliciesActions.getPrivacyPolicy());
  }
}
