import { moveItemInArray } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';

import { HeadlinesStateActions } from './headlines-state.actions';
import { INLINE_TEMP_HEADLINE_ID } from './headlines-state.facade';
import {
  defaultPagination,
  defaultSort,
  headlinesInitialState,
  headlinesStateAdapter,
  HeadlinesStateModel,
} from './headlines-state.model';

export const HeadlinesStateReducer = createReducer(
  headlinesInitialState,
  on(
    HeadlinesStateActions.hydrate,
    (state, { pageSize, sort }): HeadlinesStateModel => ({
      ...state,
      pagination: {
        ...state.pagination,
        ...(pageSize && { size: pageSize }),
      },
      ...(sort && { sort }),
    })
  ),

  on(HeadlinesStateActions.get, (state): HeadlinesStateModel => ({ ...state, loading: true, error: false })),

  on(HeadlinesStateActions.getSuccess, (state, { headlines, meeting }) => {
    let headlinesToSet: Headline[];
    if (meeting && meeting?.doneHeadlines.length) {
      headlinesToSet = cloneDeep(headlines.data);
      headlinesToSet.forEach(headline => {
        if (meeting.doneHeadlines.includes(headline._id)) headline.isDone = true;
      });
    }
    headlinesToSet = headlinesToSet ?? headlines.data;

    return headlinesStateAdapter.setAll(headlinesToSet, {
      ...state,
      totalCount: headlines.totalCount,
      loading: false,
      error: false,
    });
  }),

  on(
    HeadlinesStateActions.getFailed,
    (state): HeadlinesStateModel => ({
      ...state,
      loading: false,
      error: true,
    })
  ),
  on(
    HeadlinesStateActions.filterByTeam,
    (state, { teamId }): HeadlinesStateModel => ({
      ...state,
      filters: { ...state.filters, teamId },
      pagination: {
        ...defaultPagination,
        size: state.pagination.size,
      },
      loading: true,
    })
  ),
  on(
    HeadlinesStateActions.showArchived,
    (state, { archived }): HeadlinesStateModel => ({
      ...state,
      selectedId: null,
      filters: { ...state.filters, archived },
      pagination: {
        ...defaultPagination,
        size: state.pagination.size,
      },
      loading: true,
    })
  ),
  on(
    HeadlinesStateActions.search,
    (state, { searchText }): HeadlinesStateModel => ({
      ...state,
      filters: { ...state.filters, searchText },
      pagination: {
        ...defaultPagination,
        size: state.pagination.size,
      },
      loading: true,
    })
  ),
  on(
    HeadlinesStateActions.sortChange,
    (state, { sort }): HeadlinesStateModel => ({
      ...state,
      sort,
    })
  ),
  on(
    HeadlinesStateActions.setInMeetingId,
    (state, { meetingId }): HeadlinesStateModel => ({
      ...state,
      filters: { ...state.filters, inMeetingId: meetingId, includeDiscussed: !meetingId },
    })
  ),

  on(
    HeadlinesStateActions.clearSort,
    (state): HeadlinesStateModel => ({
      ...state,
      sort: defaultSort,
    })
  ),
  on(HeadlinesStateActions.updateOrdinals, (state, { previousIndex, currentIndex }): HeadlinesStateModel => {
    const headlinesToBeOrdered = cloneDeep(headlinesStateAdapter.getSelectors().selectAll(state));
    moveItemInArray(headlinesToBeOrdered, previousIndex, currentIndex);
    headlinesToBeOrdered.map((headline, i) => (headline.ordinal = state.pagination.index * state.pagination.size + i));
    return headlinesStateAdapter.setAll(headlinesToBeOrdered, state);
  }),
  on(
    HeadlinesStateActions.openInDetailView,
    (state, { headlineId }): HeadlinesStateModel => ({
      ...state,
      selectedId: headlineId,
    })
  ),
  on(HeadlinesStateActions.setSelected, (state, { selectedId }): HeadlinesStateModel => ({ ...state, selectedId })),
  on(HeadlinesStateActions.clearSelected, (state): HeadlinesStateModel => ({ ...state, selectedId: null })),
  on(
    HeadlinesStateActions.paginationChange,
    (state, { index, size }): HeadlinesStateModel => ({
      ...state,
      pagination: { index, size },
    })
  ),
  on(
    HeadlinesStateActions.update,
    HeadlinesStateActions.updateLocal,
    HeadlinesStateActions.setCompletedSuccess,
    HeadlinesStateActions.setUserSuccess,
    (state, { update }) => headlinesStateAdapter.updateOne(update, state)
  ),

  on(HeadlinesStateActions.setArchivedSuccess, (state, { headline }) =>
    headlinesStateAdapter.removeOne(headline._id, { ...state, totalCount: state.totalCount - 1 })
  ),

  on(HeadlinesStateActions.delete, HeadlinesStateActions.setTeam, (state, { id }) =>
    headlinesStateAdapter.removeOne(id, { ...state, totalCount: state.totalCount - 1 })
  ),
  on(HeadlinesStateActions.deleteLocal, (state, { id }) =>
    headlinesStateAdapter.removeOne(id, { ...state, totalCount: state.totalCount - 1 })
  ),
  on(
    HeadlinesStateActions.resetStore,
    (state): HeadlinesStateModel => ({
      ...headlinesInitialState,
      pagination: { ...headlinesInitialState.pagination, size: state.pagination.size }, //reset to the page size saved in local storage
    })
  ),
  on(HeadlinesStateActions.add, HeadlinesStateActions.addLocal, (state, { headline }) =>
    headlinesStateAdapter.addOne(headline, { ...state, totalCount: state.totalCount + 1 })
  ),
  on(HeadlinesStateActions.addInline, (state, { headline }) =>
    headlinesStateAdapter.addOne(headline, {
      ...state,
      focusOnInlineAdd: false,
      listControlsDisabled: true,
    })
  ),
  on(HeadlinesStateActions.cancelAddInline, state =>
    headlinesStateAdapter.removeOne(INLINE_TEMP_HEADLINE_ID, {
      ...state,
      listControlsDisabled: false,
    })
  ),

  on(HeadlinesStateActions.saveInlineSuccess, (state, { headline }) =>
    headlinesStateAdapter.setOne(headline, { ...state, totalCount: state.totalCount + 1, focusOnInlineAdd: true })
  ),

  on(HeadlinesStateActions.addAttachment, (state, { id, attachment }) => {
    const headline = state.entities[id];
    if (headline) {
      const updatedAttachments = [...headline.attachments, attachment];
      return headlinesStateAdapter.updateOne({ id, changes: { attachments: updatedAttachments } }, state);
    }
    return state;
  }),

  on(HeadlinesStateActions.removeAttachment, (state, { id, attachmentId }) => {
    const headline = state.entities[id];
    if (headline?.attachments?.length) {
      const updatedAttachments = headline.attachments.filter(attachment => attachment._id !== attachmentId);
      return headlinesStateAdapter.updateOne({ id, changes: { attachments: updatedAttachments } }, state);
    }
    return state;
  }),

  /** Attachments */
  on(HeadlinesStateActions.attachmentUploaded, (state, { event }) => {
    const headline = state.entities[event.attachment.parentId];
    if (headline) {
      const attachments = [...headline.attachments, event.attachment];
      return headlinesStateAdapter.updateOne({ id: event.attachment.parentId, changes: { attachments } }, state);
    }
    return state;
  }),
  on(HeadlinesStateActions.attachmentRemoved, (state, { event }) => {
    const headline = state.entities[event.attachment.parentId];
    if (headline) {
      const attachments = headline.attachments?.filter(a => a._id !== event.attachment._id) ?? [];
      return headlinesStateAdapter.updateOne({ id: event.attachment.parentId, changes: { attachments } }, state);
    }
    return state;
  })
);
