import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

import { SnackbarComponent } from '../../_shared/components/snackbar/snackbar.component';
import { SnackbarTemplateType } from '../../_shared/models/enums/snackbar-template-type';

export interface NotifyV2Params {
  actionText?: string;
  config?: MatSnackBarConfig;
}

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar, private toastr: ToastrService, private zone: NgZone) {}

  showError(message: string, title = 'Error', override?: Partial<IndividualConfig>) {
    this.toastr.error(message, title, override);
  }

  toast(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string) {
    this.toastr.show(message, title, override, type);
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.toastr.success(message, title, override);
  }

  /** @deprecated - transition to notifyV2 */
  notify(message: string, duration = 2000, action?: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, { duration });
  }

  /**
   * The existing `notify` method does not expose the API for configuring the snackbar, so this method was created
   * to avoid having to refactor all the existing calls to `notify` to use the new `notifyV2` method or have
   * multiple undefined arguments in the function signature.
   */
  notifyV2(message: string, params?: NotifyV2Params): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, params.actionText, params.config);
  }

  /**
   * SnackbarComponent was appearing at the top right of screen , flashing and then appearing in the correct position.
   * Added NgZone based on similar issue here: https://github.com/angular/components/issues/9875
   */
  notifyWithTemplate(templateType: SnackbarTemplateType, data: any, duration = 0) {
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { templateType, payload: data },
        duration,
      });
    });
  }
}
