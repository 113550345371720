import { IssuesSolveRate } from '../issues/issues-solve-rate';
import { IssuesListView } from '../issues/list-view';
import { TeamMasteredMastery } from '../mastery/mastery';
import { EosTools } from '../meetings/eos-tools';
import { MeetingAgenda } from '../meetings/meeting-agenda';
import { HideCurrentPeriodOnScorecard } from '../scorecard/show-current-period-on-scorecard';
import { WeeklyRange } from '../scorecard/weekly-ranges.enum';

import { MeasurableDividers } from './measurable-dividers';
import { TeamMeasurables } from './team-measurables';
import { User } from './user';

export class Team {
  name?: string;
  project?: boolean;
  private?: boolean;
  users?: User[];
  _id?: string;
  teamId?: string;
  ordinal?: number;
  settings?: TeamSettings;
  //TODO: remove when all data is transferred to mastery or not needed, also remove component
  eosTools?: EosTools;
  deleted?: boolean;
  companyId?: string;
  measurables?: TeamMeasurables;
  dividers?: MeasurableDividers;
  masteries?: TeamMasteredMastery[];

  constructor(name: string) {
    this.name = name;
    this.project = false;
    this.deleted = false;
    this.private = false;
  }
}

export interface TeamWithUsers extends Team {
  users: User[];
}

export class MeetingAgendas {
  weeklyMeetings: MeetingAgenda;
  quarterlyMeetings: MeetingAgenda;
  annualDayOne: MeetingAgenda;
  annualDayTwo: MeetingAgenda;
  focusDay: MeetingAgenda;
  visionBuildingDayOne: MeetingAgenda;
  visionBuildingDayTwo: MeetingAgenda;
  // calling it custom instead of customAgendas so that we can keep the same simple logic of updating based on type...
  custom?: MeetingAgenda[];
}

export type CompanyMeetingAgendas = Partial<MeetingAgendas>;

export class TeamSettings extends MeetingAgendas {
  issuesListView: IssuesListView;
  hideCurrentPeriodOnScorecard: HideCurrentPeriodOnScorecard;
  defaultWeeklyRange: WeeklyRange;
  sendMeetingRecapEmail?: boolean;
  acceptPendingAgreementBasedTodos?: boolean;
  autoArchive?: boolean;
  issuesSolveRate?: IssuesSolveRate;
  issueVoting?: boolean;

  hasDismissedPinnacleSvepWarning?: boolean;
}

export type CompanyMeetingAgendaUpdateByType = {
  agendaType: keyof MeetingAgendas;
  teamAgenda: MeetingAgenda | MeetingAgenda[];
};

export type TeamMeetingAgendaUpdateByType = {
  teamId: string;
  agendaType: keyof MeetingAgendas;
  teamAgenda: MeetingAgenda | MeetingAgenda[];
  newAgenda: boolean;
};

export type DeleteTeamCustomAgenda = {
  teamId: string;
  agendaId: string;
};

export type TeamMeetingAgendaPush = {
  teamId: string;
  agendaType: keyof MeetingAgendas;
  agendaId: string;
};

export type CompanyMeetingAgendaPush = {
  agendaType: keyof MeetingAgendas;
  agendaId: string;
};
