export type TodoSortField = 'title' | 'dueDate' | 'completedDate' | 'ownerName' | 'teamName';

export enum TodoSortFieldEnum {
  none = 'none',
  title = 'title',
  ownerName = 'ownerName',
  dueDate = 'dueDate',
  completedDate = 'completedDate',
  teamName = 'teamName',
}
