import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'terra-form-label',
  standalone: true,
  exportAs: 'terraFormLabel',
  imports: [CommonModule],
  template: '<ng-content></ng-content>',
  styles: [
    `
      @use '../../../terra';

      :host {
        display: block;
        color: terra.color('text', 'light');
        @include terra.text('body');

        // @eslint-context-ng-deep Prevents content projection of non-text elements
        &::ng-deep > * {
          display: none;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraFormLabelComponent {}
