import { DefaultUrlSerializer } from '@angular/router';
import { createSelector } from '@ngrx/store';

import { PersonalTodosWidgetViewModel } from '@ninety/my-ninety/components/_widgets/personal-todos-widget/models/personal-todos-widget.view-model';
import {
  PERSONAL_COMPLETED_TODOS_TAB_TABLE_COLS,
  PERSONAL_TODO_TABLE_COLS,
} from '@ninety/my-ninety/components/todos-table/models/todos-table-column';
import { selectPersonalTodoState } from '@ninety/todos/_state';
import { CompletedSelectValue } from '@ninety/ui/legacy/components/inputs/selects/completed-select/completed-select.component';
import { GridLayoutSelectors } from '@ninety/ui/legacy/layouts/grid-layout/_state/grid-layout-state.selectors';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { GetTodosQueryParams } from '@ninety/ui/legacy/shared/models/todos/get-todos-query-params';
import { TodoSortFieldEnum } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';

/**
 * Selectors for {@link PersonalTodoState}.
 *
 * Note, putting them inside a namespace has many benefits.
 * 1. Shorter names - You can omit both 'personal' and 'todos' from the name - they are in the namespace name
 * 2. Better DX - autocomplete will import `PersonalTodoSelectors` so you don't have to write import * as ___ from ...
 */
export namespace PersonalTodoSelectors {
  // ********** Common selectors based on todo-state.shared.selectors.ts ********** //

  export const selectAll = createSelector(selectPersonalTodoState, state => state.todos);
  export const selectCount = createSelector(selectPersonalTodoState, state => state.todoCount);
  export const selectIsLoading = createSelector(selectPersonalTodoState, state => state.loading);
  export const selectShouldBrodcast = createSelector(selectPersonalTodoState, state => state.shouldBroadcast);
  export const selectPageIndex = createSelector(selectPersonalTodoState, state => state.pageIndex);
  export const selectPageSize = createSelector(selectPersonalTodoState, state => state.pageSize);
  export const selectPagination = createSelector(selectPageIndex, selectPageSize, (pageIndex, pageSize) => ({
    pageIndex,
    pageSize,
  }));
  export const selectSortField = createSelector(selectPersonalTodoState, state => state.sortField);
  export const selectSortDirection = createSelector(selectPersonalTodoState, state => state.sortDirection);
  export const selectSort = createSelector(selectSortField, selectSortDirection, (sortField, sortDirection) => ({
    sortField,
    sortDirection,
  }));
  export const selectError = createSelector(selectPersonalTodoState, state => state.error);
  export const selectOrdinalKey = createSelector(selectPersonalTodoState, state => state.ordinalKey);
  export const selectedTodoId = createSelector(selectPersonalTodoState, state => state.selectedTodoId);
  export const selectSelectedTodo = createSelector(selectAll, selectedTodoId, (todos, selectedTodoId) =>
    todos.find(t => t._id === selectedTodoId)
  );

  // ********** Personal Selectors Only ********** //

  export const selectTypeOfTodosToShow = createSelector(selectPersonalTodoState, state => state.typeOfTodosToShow);

  export const selectShowingCompleted = createSelector(
    selectTypeOfTodosToShow,
    show => show === CompletedSelectValue.complete
  );
  export const selectSearching = createSelector(selectPersonalTodoState, state => !!state.searchText);

  export const selectListControlsDisabled = createSelector(
    selectPersonalTodoState,
    state => state.listControlsDisabled
  );
  export const selectFocusOnInlineAddTodo = createSelector(
    selectPersonalTodoState,
    state => state.focusOnInlineAddTodo
  );
  // Separate selector for easier future refactoring
  export const selectShouldDisableDragAndDropOnly = createSelector(
    selectShowingCompleted,
    GridLayoutSelectors.selectIsOneColumnMode,
    (showingCompleted, isOneColumnMode) => showingCompleted || isOneColumnMode
  );
  export const selectSearchText = createSelector(selectPersonalTodoState, state => state.searchText);

  export const selectGETRequestParams = createSelector(
    selectPagination,
    selectSort,
    selectShowingCompleted,
    selectSearchText,
    (page, sort, completed, searchText): GetTodosQueryParams => ({
      ...page,
      completed,
      isPersonal: true,
      sortField: TodoSortFieldEnum[sort.sortField],
      sortDirection: sort.sortField ? sort.sortDirection : null,
      searchText,
    })
  );

  export const selectWidgetTemplateType = createSelector(selectPersonalTodoState, state =>
    PersonalTodosWidgetViewModel.evaluateWidgetTemplateType(state)
  );

  export const selectDisplayedColumns = createSelector(selectTypeOfTodosToShow, show =>
    show === CompletedSelectValue.complete
      ? [...PERSONAL_COMPLETED_TODOS_TAB_TABLE_COLS]
      : [...PERSONAL_TODO_TABLE_COLS]
  );

  export const selectRepeatingTodosFlag = createSelector(
    selectFeatureFlag(FeatureFlagKeys.repeatingTodos),
    repeatingTodos => repeatingTodos
  );

  export const selectShowIntegration = createSelector(selectPersonalTodoState, state => state.showIntegrations);

  export const selectTodoIdFromRoute = createSelector(selectUrl, url => {
    const parsedUrl = new DefaultUrlSerializer().parse(url);
    if (parsedUrl.root.children.detail?.segments[1].path === `${DetailType.todo}`)
      return parsedUrl.root.children.detail.segments[2].path;
    return null;
  });
}
