<div class="container">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title class="PageHeader">&nbsp;</mat-card-title>
      <div class="fill-remaining-space"></div>
      <ninety-team-search
        data-cy="teams_search-field"
        *ngIf="enableTeamPageEnhancements$ | ngrxPush"
        class="input-wrapper"
        (searchChanged)="onSearchChanged($event)" />
      <ninety-button
        filled
        primary
        class="right-space-15"
        (click)="openNewTeamDialog()"
        [disabled]="spinnerService.primary$ | async"
        data-cy="teams_create-team-button">
        Create Team
      </ninety-button>
    </mat-card-header>
  </mat-card>
</div>
<div class="container teams-page">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title class="PageHeader">Teams</mat-card-title>
    </mat-card-header>
    <mat-toolbar class="header-btns">
      <h4 class="flex1 name-header">Name</h4>
      <h4
        class="slt-header"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip"
        matTooltip="The Leadership Team (&quot;LT&quot;) owns the
          company's {{ this.stateService.language.vto.item }} and {{ this.stateService.language.rock.items }} and
          controls how they're shared with other teams.">
        LT
      </h4>
      <h4
        class="project-header"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip"
        matTooltip="Project teams retain all the functionality of normal teams, but do not have a {{
          this.stateService.language.vto.item
        }}.">
        Project
      </h4>
      <h4
        class="private-header"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip"
        matTooltip="Private teams are only visible to the users on the team, but owners are still able to assign themselves to the team.">
        Private
      </h4>
      <div class="delete-header"></div>
    </mat-toolbar>
    <mat-list class="ninety-list" *ngrxLet="company$ as company">
      <mat-list-item
        *ngFor="let team of filteredTeams$ | async; trackBy: trackByTeamId"
        matRipple
        class="list-item"
        [class.opened]="selectedTeam?._id === team._id"
        [disabled]="spinnerService.primary$ | async"
        (click)="selectTeam(team)">
        <div class="list-title dflex flex1" attr.data-cy="teams_team-name-{{ team.name }}">
          <div>{{ team.name }}</div>
          <terra-icon
            icon="user-check"
            variant="fill"
            *ngIf="currentUserTeamIds.includes(team._id)"
            class="orange on-team-icon"
            matTooltip="You are on this team"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip" />
        </div>
        <mat-radio-group
          aria-label="Set as Leadership Team"
          class="slt-radio"
          [(ngModel)]="company.seniorLeadershipTeamId"
          [disabled]="team.private || !team.canEdit"
          [matTooltip]="team.private ? 'A leadership team cannot be private' : ''"
          matTooltipPosition="above"
          (change)="setSltTeam(team)"
          ninetyStopPropagation>
          <mat-radio-button
            [value]="team._id"
            attr.data-cy="teams_set-leadership-team_team-name-{{ team.name }}"></mat-radio-button>
        </mat-radio-group>
        <terra-checkbox
          attr.data-cy="teams_set-project_team-name-{{ team.name }}"
          aria-label="Set as Project Team"
          [(ngModel)]="team.project"
          class="project-checkbox"
          [disabled]="team._id === company.seniorLeadershipTeamId || !team.canEdit"
          (change)="updateTeam(team._id, { project: $event })"
          ninetyStopPropagation></terra-checkbox>
        <terra-checkbox
          attr.data-cy="teams_set-private_team-name-{{ team.name }}"
          aria-label="Set as Private Team"
          [(ngModel)]="team.private"
          class="project-checkbox"
          [disabled]="team._id === company.seniorLeadershipTeamId || !team.canEdit"
          (change)="updateTeam(team._id, { private: $event })"
          ninetyStopPropagation></terra-checkbox>
        <button
          attr.data-cy="teams_delete_team-name-{{ team.name }}"
          mat-icon-button
          color="warn"
          class="delete-btn"
          (click)="deleteTeam(team)"
          ninetyStopPropagation
          aria-label="Delete Team"
          [disabled]="team._id === company.seniorLeadershipTeamId || !team.canEdit"
          matTooltip="Delete Team"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip">
          <terra-icon icon="delete" />
        </button>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
