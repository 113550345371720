import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { TerraIconName } from '@ninety/terra';
import { PersonMetadata, States } from '@ninety/ui/legacy/shared/models/_shared/person-metadata';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { Countries } from '@ninety/ui/legacy/shared/models/directory/countries.constant';
import { CountryData } from '@ninety/ui/legacy/shared/models/directory/country';
import { EmergencyContact } from '@ninety/ui/legacy/shared/models/directory/emergency-contact';
import { selectCompanyName } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

interface MenuItem {
  key: string;
  icon: TerraIconName;
  label: string;
  className?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'ninety-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalComponent implements OnInit {
  private _metadata: PersonMetadata;
  get metadata() {
    return this._metadata;
  }

  @Input() set metadata(metadata: PersonMetadata) {
    this._metadata = metadata;
    this.userCountry = Countries.find(c => c.code === metadata.country?.code);
  }

  @Input() userInfo: Partial<User>;

  @Output() updateMetadata = new EventEmitter<Partial<PersonMetadata>>();
  @Output() updateUser = new EventEmitter<Partial<User>>();

  updateMetadata$ = new Subject<void>();
  States = States;
  Countries = Countries;
  userCountry: CountryData;

  readonly companyName$ = this.store.select(selectCompanyName);

  constructor(private cdr: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    this.updateMetadata$.pipe(debounceTime(1000)).subscribe(() => {
      this.updateMetadata.emit(this.metadata);
    });
  }

  debounceUpdate(): void {
    this.updateMetadata$.next();
  }

  selectCountry(countryData: CountryData): void {
    const { provinces, province_codes, ...country } = countryData;
    this.metadata.country = country;
    this.updateMetadata.emit(this.metadata);
    this.userCountry = countryData;
    this.cdr.markForCheck();
  }

  addEmergencyContact(): void {
    this.metadata.emergencyContacts.push(new EmergencyContact());
    this.updateMetadata.emit(this.metadata);
    this.cdr.markForCheck();
  }

  deleteEmergencyContact(i: number): void {
    this.metadata.emergencyContacts.splice(i, 1);
    this.updateMetadata.emit(this.metadata);
    this.cdr.markForCheck();
  }

  compareCountries = (option, value): boolean => option.name === value.name;

  compareRegions = (option, value) => option.code === value.code;

  trackByIndex = (i: number, _item: EmergencyContact): number => i;
}
