import ObjectID from 'bson-objectid';

import { MeetingSection } from './meeting-section';

export class MeetingAgenda {
  agenda: MeetingSection[] = [
    {
      duration: 300,
      name: 'Conclude',
      ordinal: 6,
      path: 'conclude',
      isHidden: false,
      isDefault: true,
      isCustom: false,
      isEditable: true,
      subtitle: '',
    },
  ];

  // custom Meeting agendas
  name? = '';
  isCustom? = true; // adding this for now as something static to key off of
  _id? = new ObjectID().toHexString();
}
