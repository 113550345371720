import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export type AlertClass = 'warning' | 'danger' | 'success' | 'primary' | 'secondary';

@Component({
  selector: 'ninety-alert',
  template: `
    <div class="alert {{ alertClass }}" role="alert">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  /**
   * Bootstrap alerts
   * Taken from: https://getbootstrap.com/docs/4.0/components/alerts/
   */
  @Input() alertClass: AlertClass = 'warning';
}
