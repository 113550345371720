<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="surveys" class="survey-icon" />
      <span data-cy="survey-language_card-title">{{ survey.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="survey">
      <mat-form-field appearance="outline">
        <mat-label>Page Name:</mat-label>
        <input
          #route
          matInput
          formControlName="route"
          required
          [attr.aria-label]="survey.route + ' Page Name'"
          data-cy="survey-language_page-name-input-field" />
        <ninety-reset-btn
          *ngIf="route.value !== resetLanguage?.route"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('route')"
          [disabled]="readonly"
          data-cy="survey-language_page-name-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ survey.item }} (singular):</mat-label>
        <input
          #item
          matInput
          formControlName="item"
          required
          [attr.aria-label]="survey.item"
          data-cy="survey-language_singular-input-field" />
        <ninety-reset-btn
          *ngIf="item.value !== resetLanguage?.item"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('item')"
          [disabled]="readonly"
          data-cy="survey-language_singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ survey.items }} (plural):</mat-label>
        <input
          #items
          matInput
          formControlName="items"
          required
          [attr.aria-label]="survey.items"
          data-cy="survey-language_plural-input-field" />
        <ninety-reset-btn
          *ngIf="items.value !== resetLanguage?.items"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('items')"
          [disabled]="readonly"
          data-cy="survey-language_plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ survey.admin }}:</mat-label>
        <input
          #admin
          matInput
          formControlName="admin"
          required
          [attr.aria-label]="survey.admin"
          data-cy="survey-language_admin-input-field" />
        <ninety-reset-btn
          *ngIf="admin.value !== resetLanguage?.admin"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('admin')"
          [disabled]="readonly"
          data-cy="survey-language_admin-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ survey.mySurveys }}:</mat-label>
        <input
          #mySurveys
          matInput
          formControlName="mySurveys"
          required
          [attr.aria-label]="survey.mySurveys"
          data-cy="survey-language_my-surveys-input-field" />
        <ninety-reset-btn
          *ngIf="mySurveys.value !== resetLanguage?.mySurveys"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('mySurveys')"
          [disabled]="readonly"
          data-cy="survey-language_my-surveys-reset-button">
        </ninety-reset-btn>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ survey.recipient }} (singular):</mat-label>
        <input
          #recipient
          matInput
          formControlName="recipient"
          required
          [attr.aria-label]="survey.recipient"
          data-cy="survey-language_recipient-singular-input-field" />
        <ninety-reset-btn
          *ngIf="recipient.value !== resetLanguage?.recipient"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('recipient')"
          [disabled]="readonly"
          data-cy="survey-language_recipient-singular-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ survey.recipients }} (plural):</mat-label>
        <input
          #recipients
          matInput
          formControlName="recipients"
          required
          [attr.aria-label]="survey.recipients"
          data-cy="survey-language_recipient-plural-input-field" />
        <ninety-reset-btn
          *ngIf="recipients.value !== resetLanguage?.recipients"
          ninetyStopPropagation
          matSuffix
          (click)="resetOne.emit('recipients')"
          [disabled]="readonly"
          data-cy="survey-language_recipient-plural-reset-button">
        </ninety-reset-btn>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
