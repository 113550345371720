import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'stringDatePipe',
  pure: false,
})
export class StringDatePipe implements PipeTransform {
  transform(date: any): Date {
    date.trim('');
    if (typeof date === 'string') return moment(date, 'YYYY-MM-DD').toDate();
    return moment(date).toDate();
  }
}
