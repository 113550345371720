import { TerraIconName, TerraIconVariant } from '@ninety/terra';

export enum ContactCardStatusColor {
  completed = 'completed',
  inProgress = 'in-progress',
  notStarted = 'not-started',
}

export interface ContactCardStatusModel {
  statusText: string;
  statusIcon?: TerraIconName;
  statusIconVariant?: TerraIconVariant;
  statusColor?: ContactCardStatusColor;
}
