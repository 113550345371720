import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  BehaviorSubject,
  catchError,
  delay,
  filter,
  interval,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { SnackbarComponent } from '../../_shared/components/snackbar/snackbar.component';
import { SnackbarTemplateType } from '../../_shared/models/enums/snackbar-template-type';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private wasOffline = false;
  public isOffline$ = new BehaviorSubject(false);
  private checkRetry$: Subscription;
  private dialogCountdown$: Subscription;
  private delayMs = 1000;
  private delayMsOptions = [1000, 10_000, 30_000, 60_000];
  private statusDialog: MatSnackBarRef<SnackbarComponent>;

  constructor(private snackBar: MatSnackBar) {
    this.isOffline$.pipe(filter(isOffline => !(isOffline === false && this.wasOffline === false))).subscribe({
      next: isOffline => {
        this.wasOffline = isOffline;
        this.checkRetry$?.unsubscribe();
        this.dialogCountdown$?.unsubscribe();
        this.statusDialog?.dismiss();
        if (isOffline) {
          this.delayMs = this.delayMsOptions.find(d => d > this.delayMs) ?? 60_000;
          const retrySeconds = Math.round(this.delayMs / 1000);
          this.statusDialog = this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              templateType: SnackbarTemplateType.offline,
              payload: { retrySeconds },
            },
            duration: this.delayMs,
          });

          this.dialogCountdown$ = interval(1000)
            .pipe(
              tap(count => {
                this.statusDialog.instance.data.retrySeconds = Math.max(retrySeconds - count, 0);
              })
            )
            .subscribe();
          this.checkRetry$ = this.check(this.delayMs).subscribe();
        } else {
          this.statusDialog = this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              templateType: SnackbarTemplateType.online,
              payload: null,
            },
            duration: 10_000,
          });
          this.delayMs = 1000;
        }
      },
    });
  }

  check(delayMs: number = this.delayMs): Observable<void> {
    return of(null).pipe(
      delay(delayMs),
      switchMap(() => fetch(`/favicon.png&_=${new Date().getTime()}`)),
      tap(() => this.isOffline$.next(false)),
      // eslint-disable-next-line
      catchError((e: any) => {
        this.isOffline$.next(true);
        return of(e);
      })
    );
  }
}
