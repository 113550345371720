import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { VtoLayoutModeState } from '@ninety/vto/models/vto-layout-mode-state.enum';
import { LegacyVTOGridLayoutActions } from '@ninety/vto/services/legacy-vto-grid-layout-actions.service';

// TODO this component should be merged with LayoutActionsGroupComponent during VTO 3.0
@Component({
  selector: 'ninety-grid-layout-toolbar-buttons',
  styleUrls: ['./grid-layout-toolbar-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="(gridLayoutActions.isSingleColumnView$ | async) === false">
      <button
        *ngIf="!layoutModeActive; else inLayoutMode"
        id="edit-layout-button"
        mat-button
        matTooltipClass="ninety-tooltip"
        matTooltip="Enables drag and drop as well as resizing of individual sections in the {{
          stateService.language.vto.route
        }}."
        [disabled]="layoutModeDisabled"
        (click)="gridLayoutActions.emitLayoutModeStateChange(LayoutModeState.REQUEST_ENABLE)">
        <terra-icon class="left-icon" icon="edit" />
        Edit Layout
      </button>
    </ng-container>

    <ng-template #inLayoutMode>
      <button
        mat-button
        id="reset-layout-button"
        matTooltip="Undo layout changes and exit"
        matTooltipClass="ninety-tooltip"
        (click)="gridLayoutActions.emitResetLayout()"
        [disabled]="layoutModeDisabled">
        <terra-icon class="left-icon" icon="undo" />
        Cancel
      </button>

      <button
        data-cy="grid-layout-toolbar-buttons_manage-sections-button"
        mat-stroked-button
        id="manage-sections-button"
        matTooltip="Add, hide, or remove sections from the layout"
        matTooltipClass="ninety-tooltip"
        (click)="gridLayoutActions.emitLaunchManageSections()"
        [disabled]="layoutModeDisabled">
        <terra-icon class="left-icon" icon="settings" />
        Manage Sections
      </button>

      <button
        data-cy="grid-layout-toolbar-buttons_save-and-exit-button"
        mat-raised-button
        id="save-layout-button"
        matTooltip="Save layout changes and exit"
        matTooltipClass="ninety-tooltip"
        (click)="gridLayoutActions.emitPersistLayoutChanges()"
        [disabled]="layoutModeDisabled"
        [class.white-background]="!fillSaveButtonWithColor"
        [color]="fillSaveButtonWithColor ? 'accent' : null">
        <terra-icon class="left-icon" icon="save" />
        Save and Exit
      </button>
    </ng-template>
  `,
})
export class GridLayoutToolbarButtonsComponent {
  readonly LayoutModeState = VtoLayoutModeState;

  /**
   * When true, shows the save, reset, and manage sections buttons.
   * When false, shows button to enable layout mode
   */
  @Input() layoutModeActive = false;

  /**
   * When true, layout mode button is shown, but it is disabled.
   * Prevent edits to the layout mode when VTO is loading.
   */
  @Input() layoutModeDisabled = false;

  /**
   * This component is used in places with backgrounds that are white and the accent color. This property can be used
   * to style the save button based on the background color of the parent component.
   */
  @Input() fillSaveButtonWithColor = false;

  constructor(public gridLayoutActions: LegacyVTOGridLayoutActions, public stateService: StateService) {}
}
