import { createActionGroup, props } from '@ngrx/store';

import { Vto } from '@ninety/ui/legacy/shared/models/vto/vto';

export const VtoStateActions = createActionGroup({
  source: 'VTO',
  events: {
    'Vto Updated': props<{ vtoId: string; vtoUpdate: Partial<Vto> }>(),
  },
});
