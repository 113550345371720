import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailAddressSearch',
})
export class EmailAddressSearchPipe implements PipeTransform {
  transform<T extends { EmailAddress?: string }>(values: T[], search: string): T[] {
    if (!search) return values;
    const escapedSearchRe = new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return values.filter(({ EmailAddress }) => escapedSearchRe.test(EmailAddress));
  }
}
