import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { StateService } from '../../../_core/services/state.service';
import { LocalStorageService } from '../../../_core/services/storage.service';
import { TimeService } from '../../../_core/services/time.service';
import { UserService } from '../../../_core/services/user.service';
import { User } from '../../../_shared/models/_shared/user';
import { SpinnerActions } from '../../app-global/spinner/spinner-state.actions';
import { appActions } from '../../app.actions';
import { FeatureFlagKeys } from '../feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../feature-flag/feature-flag-state.selectors';

import {
  UserPreferencesActions,
  UserSettingsActions,
  UserTeamsActions,
  UsersStateActions,
} from './users-state.actions';
import { selectCurrentUser } from './users-state.selectors';

@Injectable()
export class UsersStateEffects {
  timezonePromptFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.timezonePrompt));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private stateService: StateService,
    private timeService: TimeService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.update),
      concatMap(({ userId, update }) =>
        // api hands back entire updated user
        this.userService.update(update, userId).pipe(map(() => ({ userId, update })))
      ),
      map(UsersStateActions.updateSuccess)
    )
  );

  addUsersToTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.addUsersToTeam),
      concatMap(({ users, teamId }) => {
        const updates = users.map(u => {
          if (u.teams.some(t => t.teamId === teamId)) {
            return;
          }
          return this.userService.update({ teams: [...u.teams, { teamId }] }, u._id);
        });
        return forkJoin(updates);
      }),
      map(users => UserTeamsActions.addUsersToTeamSuccess({ users })),
      catchError((error: unknown) => of(UserTeamsActions.addUsersToTeamFailed({ error })))
    )
  );

  removeUserFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.removeUserFromTeam),
      concatMap(({ user, teamId }) =>
        this.userService.update({ teams: [...user.teams.filter(t => t.teamId !== teamId)] }, user._id)
      ),
      map(user => UserTeamsActions.removeUserFromTeamSuccess({ user })),
      catchError((error: unknown) => of(UserTeamsActions.removeUserFromTeamFailed({ error })))
    )
  );

  userUpdateStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.removeUserFromTeam, UserTeamsActions.addUsersToTeam),
      map(_ => SpinnerActions.startPrimary({ source: 'UserTeamActions' }))
    )
  );

  userUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserTeamsActions.removeUserFromTeamSuccess,
        UserTeamsActions.removeUserFromTeamFailed,
        UserTeamsActions.addUsersToTeamFailed,
        UserTeamsActions.addUsersToTeamSuccess
      ),
      map(_ => SpinnerActions.stopPrimary({ source: 'UserTeamActions' }))
    )
  );

  updateUserTutorials = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferencesActions.hideUserTutorial),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      concatMap(([{ userTutorialType }, currentUser]) =>
        this.userService.update({
          tutorialsHidden: { ...currentUser.tutorialsHidden, [userTutorialType]: true },
        })
      ),
      map(user => UserPreferencesActions.userTutorialsUpdated({ tutorialsHidden: user.tutorialsHidden }))
    )
  );

  checkTimezonePrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.checkToShowTimeZoneUpdateDialog),
      concatLatestFrom(() => [this.store.select(selectCurrentUser), this.timezonePromptFlag$]),
      filter(([_, _user, timezonePromptFlag]) => {
        const flagEnabled = !!timezonePromptFlag;
        const notLoginUrl = !this.router.url.includes('/login/');
        const dontAskToUpdateTimezoneAgain = this.localStorageService.get('dontAskToUpdateTimezoneAgain') !== 'true';

        const shouldProceed = flagEnabled && notLoginUrl && dontAskToUpdateTimezoneAgain;
        return shouldProceed;
      }),
      map(([_, user]) => {
        const browserTimezoneEntry = this.timeService.getBrowserTimezone();
        return { userTimeZoneNotFormatted: user?.settings.timezone, browserTimezoneEntry };
      }),
      switchMap(({ userTimeZoneNotFormatted, browserTimezoneEntry }) => {
        const shouldShowPromptOutsideOfLoginFlow = userTimeZoneNotFormatted !== browserTimezoneEntry.name;

        if (shouldShowPromptOutsideOfLoginFlow) {
          return this.userService
            .showTimezoneUpdateDialog(browserTimezoneEntry)
            .pipe(map((user: User) => UserSettingsActions.updateTimezone({ user })));
        } else {
          return of(appActions.noop());
        }
      })
    )
  );

  /************************************************************************************
   * Legacy code block to keep stateService up to date. To be deleted once off stateService
   *************************************************************************************/
  addUsersUpdateStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTeamsActions.addUsersToTeamSuccess),
        concatLatestFrom(() => this.store.select(selectCurrentUser)),
        map(([{ users }, currentUser]) => users.find(u => u._id === currentUser._id)),
        filter(user => !!user),
        tap(user => {
          const currentUser = this.stateService.currentCompanyUser$.value;
          currentUser.teams = user.teams;
          this.stateService.currentCompanyUser$.next(currentUser);
        })
      ),
    { dispatch: false }
  );

  removeUserUpdateStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTeamsActions.removeUserFromTeamSuccess),
        concatLatestFrom(() => this.store.select(selectCurrentUser)),
        filter(([{ user }, currentUser]) => user._id === currentUser._id),
        tap(([{ user }, _]) => {
          const currentUser = this.stateService.currentCompanyUser$.value;
          currentUser.teams = user.teams;
          this.stateService.currentCompanyUser$.next(currentUser);
        })
      ),
    { dispatch: false }
  );

  updateCurrentUserThemeStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferencesActions.updateTheme),
        tap(({ theme }) => {
          this.stateService.currentUser.settings.theme = theme;
        })
      ),
    { dispatch: false }
  );
  /************************************************************************************
   * End Legacy Code
   *************************************************************************************/
}
