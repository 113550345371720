import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Inject a unique request ID into API requests that can be used to correlate
 * requests on the frontend with requests on the backend.
 **/
@Injectable()
export class RequestIdInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    req = req.clone({
      headers: req.headers.set('x-ninety-request-id', uuidv4()),
    });

    return next.handle(req);
  }
}
