import { createReducer, on } from '@ngrx/store';

import { BreakpointActions } from './viewport.actions';
import { initialViewportState, ViewportState } from './viewport.model';

export const viewportStateReducer = createReducer<ViewportState>(
  initialViewportState,

  on(
    BreakpointActions.breakpointSet,
    (_state, { breakpointState, currentScreenSize }): ViewportState => ({
      ...breakpointState,
      currentScreenSize,
    })
  )
);
