import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Meeting } from '@ninety/ui/legacy/shared//models/meetings/meeting';
import { MeetingMessageAction } from '@ninety/ui/legacy/shared//models/meetings/meeting-message-action';
import { RealtimeMessage } from '@ninety/ui/legacy/shared//models/meetings/realtime-message';

@Component({
  selector: 'ninety-meeting-notes',
  templateUrl: './meeting-notes.component.html',
  styleUrls: ['./meeting-notes.component.scss'],
})
export class MeetingNotesComponent implements OnInit {
  expanded = true;
  subscriptions = new Subscription();
  protected updateMeetingNotes$ = new Subject<string>();
  protected updateMeetingAttachments$ = new Subject();

  constructor(
    public meetingService: MeetingService,
    public stateService: StateService,
    public createDialogService: CreateDialogService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.updateMeetingAttachments$
        .pipe(switchMap(() => this.meetingService.broadcastMessage(this.buildBroadcastMessage('attachments'))))
        .subscribe()
    );

    this.subscriptions.add(
      this.updateMeetingNotes$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(notes => this.meetingService.updateCurrentMeeting({ notes }))
        )
        .subscribe({
          next: () => this.meetingService.broadcastMessage(this.buildBroadcastMessage('notes')).subscribe(),
        })
    );

    this.subscriptions.add(
      this.createDialogService.createOpenEvent$.subscribe({
        next: () => {
          if (this.expanded) {
            this.expanded = false;
          }
        },
      })
    );
  }

  buildBroadcastMessage(prop: keyof Meeting): RealtimeMessage {
    return {
      messageType: 'meeting',
      document: {
        action: MeetingMessageAction.Update,
        meeting: { [prop]: this.meetingService.currentMeeting?.[prop] },
        emitterId: this.stateService.currentUser._id,
      },
    };
  }
}
