export class UrlSanitizer {
  constructor(private keysToFilter: string[]) {}

  createFrom(uri: string): URL {
    try {
      const url = new URL(uri);

      return url ? this.sanitize(url) : undefined;
    } catch (e) {
      /* Ignore invalid urls */
    }
  }

  sanitize(url: URL): URL {
    const keysToDelete: string[] = [];

    // Directly deleting params breaks forEach
    url.searchParams.forEach((_, key) => {
      if (this.keysToFilter.includes(key.toLowerCase())) {
        keysToDelete.push(key);
      }
    });

    keysToDelete.forEach((key: string) => url.searchParams.delete(key));

    return url;
  }
}
