import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionComponent } from './terra-description.component';

@NgModule({
  imports: [CommonModule, TerraDescriptionComponent],
  exports: [TerraDescriptionComponent],
})
export class TerraDescriptionModule {}
