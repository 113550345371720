import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { ActiveMeetingResponse } from '@ninety/ui/legacy/shared/models/meetings-v2/active-meetings-v2-response';
import { GetPastMeetingsQueryParams } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-query-params';
import { PastMeetingsV2Response } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';

@Injectable({
  providedIn: 'root',
})
export class MeetingsV2Service {
  private meetingsApi = 'api.qa1.90srv.com/Meetings';
  private meetingsV2Api = 'api.qa1.90srv.com/meetings-v2';

  http = inject(HttpClient);
  errorService = inject(ErrorService);
  store = inject(Store);

  getActiveMeetingInfo(companyId: string, teamId: string): Observable<ActiveMeetingResponse> {
    return this.http.get<ActiveMeetingResponse>(
      `${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/active-info`
    );
  }

  getPastMeetings(
    companyId: string,
    teamId: string,
    options: GetPastMeetingsQueryParams
  ): Observable<PastMeetingsV2Response> {
    return this.http.get<PastMeetingsV2Response>(
      `${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/past`,
      {
        params: QueryParamsService.build(options, true),
      }
    );
  }

  startMeeting(meetingId: string): Observable<Meeting> {
    return this.http.get<Meeting>(`${this.meetingsApi}/${meetingId}/Start`);
  }

  deleteMeeting(meetingId: string, companyId: string, teamId: string): Observable<void> {
    return this.http.delete<void>(`${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/${meetingId}`);
  }
}
