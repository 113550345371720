<mat-spinner color="accent" *ngIf="spinner"></mat-spinner>
<h1 mat-dialog-title>Create New Team</h1>
<mat-card-subtitle>Submit this form to create a new team within your company.</mat-card-subtitle>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <input
      matInput
      placeholder="Team Name"
      [(ngModel)]="name"
      required
      aria-label="Team Name"
      data-cy="new-team-dialog_team-name-input" />
  </mat-form-field>
  <terra-checkbox
    *ngIf="!stateService.currentUser.isImplementer"
    class="check-box"
    aria-label="Add yourself as a team member"
    [(ngModel)]="includeUser"
    data-cy="add-yourself">
    Add yourself as a team member
  </terra-checkbox>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button (click)="dialogRef.close()" [disabled]="spinner" data-cy="new-team-dialog_cancel-add-team">
    Cancel
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="createTeam()"
    [disabled]="spinner"
    data-cy="new-team-dialog_add-team-button">
    Add
  </button>
</mat-dialog-actions>
