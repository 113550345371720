import type { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { User, UserTutorials } from '../../../_shared/models/_shared/user';
import { UserTutorialType } from '../../../_shared/models/_shared/user-tutorial-type';
import type { InviteUserPayload } from '../../../_shared/models/directory/invite-user-payload';
import { Theme } from '../../../_shared/models/enums/theme';
import { UserNotificationSettings } from '../../../_shared/models/notifications/event-notification.models';

export const UsersStateActions = createActionGroup({
  source: 'Users',
  events: {
    'Set Current User': props<{ userId: string }>(),
    'Load All Users': props<{ users: User[] }>(),
    'Add One': props<{ user: User }>(),
    'Add Many': props<{ users: User[] }>(),
    'Remove One': props<{ _id: string }>(),
    'Update One': props<{ _id: string; changes: Partial<User> }>(),
    'Update Many': props<{ updates: Update<User>[] }>(),
    'Set Avatar url': props<{ _id: string; url: string }>(),
    'Update Sms': props<{ _id; countryCode: string; number: string }>(),
    'Check To Show TimeZone Update Dialog': emptyProps(),

    // API Actions
    Update: props<{ userId: string; update: Partial<User> }>(),
    'Update Success': props<{ userId: string; update: Partial<User> }>(),
    'Update Failed': props<{ error: unknown }>(),
  },
});

export const UserInviteActions = createActionGroup({
  source: 'Invite User Modal',
  events: {
    'Invite User': props<{ user: User }>(),
  },
});

export const UserTeamsActions = createActionGroup({
  source: 'Teams Component',
  events: {
    'Add Users To Team': props<{ users: User[]; teamId: string }>(),
    'Add Users To Team Success': props<{ users: User[] }>(),
    'Add Users To Team Failed': props<{ error: unknown }>(),

    'Remove User From Team': props<{ user: User; teamId: string }>(),
    'Remove User From Team Success': props<{ user: User }>(),
    'Remove User From Team Failed': props<{ error: unknown }>(),
  },
});

export const InviteUsersActions = createActionGroup({
  source: 'Invite Users',
  events: {
    'Invite user to company': props<{
      invitedUser: InviteUserPayload;
      sendInvite: boolean;
      existingDirectoryUser: boolean;
      previouslyInvited: boolean;
    }>(),
  },
});

export const UserPreferencesActions = createActionGroup({
  source: 'User Preferences',
  events: {
    'Update Theme': props<{ theme: Theme }>(),
    'Update Event notification': props<{ data: UserNotificationSettings[] }>(),
    'Theme Saved': props<{ theme: Theme }>(),
    'Hide User Tutorial': props<{ userTutorialType: UserTutorialType }>(),
    'User Tutorials Updated': props<{ tutorialsHidden: UserTutorials }>(),
  },
});

export const UserSettingsActions = createActionGroup({
  source: 'User Settings',
  events: {
    'Update Timezone': props<{ user: User }>(),
    'Google Task Integration Toggled': props<{ enabled: boolean }>(),
    'Microsoft Task Integration Toggled': props<{ enabled: boolean }>(),
  },
});

export const DirectoryProfileCardActions = createActionGroup({
  source: 'DirectoryProfileCardComponent',
  events: {
    'User Deleted': props<{ _id: string; changes: Partial<User> }>(),
  },
});
