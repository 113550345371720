import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { ContactCardComponent, ContactCardInput } from '../../../_components/contact-card/contact-card.component';
import { selectUserProfileById } from '../../../_state/app-entities/users/users-state.selectors';
import { ContactCardStatusModel } from '../../models/_shared/contact-card-status-model';
import { User } from '../../models/_shared/user';
import { DisplayFullNamePipe } from '../../pipes/display-full-name.pipe';
import { UserInitialsPipe } from '../../pipes/user-initials.pipe';

export type ContactCardHoverOrientation =
  | 'left'
  | 'right'
  | 'above'
  | 'below'
  | 'upper-left'
  | 'upper-right'
  | 'lower-right'
  | 'lower-left';
@Component({
  selector: 'ninety-contact-card-container',
  templateUrl: './contact-card-container.component.html',
  styleUrls: ['./contact-card-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ContactCardComponent],
  providers: [DisplayFullNamePipe, UserInitialsPipe],
})
export class ContactCardContainerComponent implements OnInit {
  @Input() userId: string;
  @Input() left = 0;
  @Input() top = 0;
  @Input() orientation: ContactCardHoverOrientation;
  @Input() statusModel: ContactCardStatusModel = null;

  public contactCardInput$: Observable<ContactCardInput>;

  constructor(
    private store: Store,
    private displayFullNamePipe: DisplayFullNamePipe,
    private userInitialsPipe: UserInitialsPipe
  ) {}

  ngOnInit() {
    this.contactCardInput$ = this.store.select(selectUserProfileById(this.userId)).pipe(
      map(
        userProfile =>
          ({
            fullUserName: this.displayFullNamePipe.transform({
              first: userProfile.firstName,
              last: userProfile.lastName,
            }),
            title: userProfile.title,
            email: userProfile.primaryEmail,
            pictureURL: userProfile.avatarUrl,
            userInitials: this.userInitialsPipe.transform({
              metadata: {
                name: {
                  first: userProfile.firstName,
                  last: userProfile.lastName,
                },
              },
            } as Pick<User, 'metadata'>),
            transparent: !userProfile.active,
            isCurrentUser: userProfile.isCurrentUser,
            showStatus: !!this.statusModel,
            statusText: this.statusModel?.statusText,
            statusIcon: this.statusModel?.statusIcon,
            statusIconVariant: this.statusModel?.statusIconVariant,
            statusColor: this.statusModel?.statusColor,
          } as ContactCardInput)
      )
    );
  }
}
