import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayFullName',
  standalone: true,
})
export class DisplayFullNamePipe implements PipeTransform {
  transform(name: { first: string; last: string }): string {
    return DisplayFullNamePipe.transform(name);
  }

  static transform(name: { first: string; last: string }): string {
    return `${name?.first || ''} ${name?.last || ''}`.trim();
  }
}
