import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError } from 'rxjs';

import { MazNextPayload, MazNextResponse } from '../../_shared/models/maz/maz-next';

import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class MazService {
  private readonly mazApi = '/api/maz/v1';

  constructor(private http: HttpClient, private errorService: ErrorService, private router: Router) {}

  getNext(): Observable<MazNextResponse | ArrayBuffer> {
    return this.http
      .post<MazNextResponse>(`${this.mazApi}/next`, {
        currentPage: this.router.url,
        lastUpdated: Date.now().toLocaleString(),
      })
      .pipe(catchError(ErrorService.handle));
  }

  submit(payload: MazNextPayload): Observable<MazNextResponse | ArrayBuffer> {
    return this.http
      .post<MazNextResponse>(`${this.mazApi}/submit`, {
        ...payload,
        currentPage: this.router.url,
        lastUpdated: Date.now().toLocaleString(),
      })
      .pipe(catchError(ErrorService.handle));
  }

  help(): Observable<MazNextResponse | ArrayBuffer> {
    return this.http
      .post<MazNextResponse>(`${this.mazApi}/help`, {
        currentPage: this.router.url,
        lastUpdated: Date.now().toLocaleString(),
      })
      .pipe(catchError(ErrorService.handle));
  }
}
