import { Injectable } from '@angular/core';

/**
 * @deprecated
 *
 * This logger is deprecated. You should transition to using the new Ngrx LoggingActions or DatadogLogger instead.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  log(...args: any[]): void {
    console.log(...args);
  }

  error(...args: any[]): void {
    console.error(...args);
  }

  debug(...args: any[]): void {
    // TODO: Implement launchDarkly?
    // if (cond) {
    //   console.log(`[Debug]:\n`, ...args);
    // }
  }
}
