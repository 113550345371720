import { CountryData } from './country';

export const Countries: CountryData[] = [
  {
    name: 'United States',
    code: 'US',
    zip_label: 'ZIP code',
    province_label: 'State/Territory',
    provinces: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Virgin Islands',
      'Armed Forces Americas',
      'Armed Forces Europe',
      'Armed Forces Pacific',
    ],
    province_codes: {
      Alabama: 'AL',
      Alaska: 'AK',
      'American Samoa': 'AS',
      Arizona: 'AZ',
      Arkansas: 'AR',
      California: 'CA',
      Colorado: 'CO',
      Connecticut: 'CT',
      Delaware: 'DE',
      'District of Columbia': 'DC',
      'Federated States of Micronesia': 'FM',
      Florida: 'FL',
      Georgia: 'GA',
      Guam: 'GU',
      Hawaii: 'HI',
      Idaho: 'ID',
      Illinois: 'IL',
      Indiana: 'IN',
      Iowa: 'IA',
      Kansas: 'KS',
      Kentucky: 'KY',
      Louisiana: 'LA',
      Maine: 'ME',
      'Marshall Islands': 'MH',
      Maryland: 'MD',
      Massachusetts: 'MA',
      Michigan: 'MI',
      Minnesota: 'MN',
      Mississippi: 'MS',
      Missouri: 'MO',
      Montana: 'MT',
      Nebraska: 'NE',
      Nevada: 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      'Northern Mariana Islands': 'MP',
      Ohio: 'OH',
      Oklahoma: 'OK',
      Oregon: 'OR',
      Palau: 'PW',
      Pennsylvania: 'PA',
      'Puerto Rico': 'PR',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      Tennessee: 'TN',
      Texas: 'TX',
      Utah: 'UT',
      Vermont: 'VT',
      Virginia: 'VA',
      Washington: 'WA',
      'West Virginia': 'WV',
      Wisconsin: 'WI',
      Wyoming: 'WY',
      'Virgin Islands': 'VI',
      'Armed Forces Americas': 'AA',
      'Armed Forces Europe': 'AE',
      'Armed Forces Pacific': 'AP',
    },
    dial_code: '+1',
  },
  {
    name: 'Canada',
    code: 'CA',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon',
    ],
    province_codes: {
      Alberta: 'AB',
      'British Columbia': 'BC',
      Manitoba: 'MB',
      'New Brunswick': 'NB',
      Newfoundland: 'NL',
      'Northwest Territories': 'NT',
      'Nova Scotia': 'NS',
      Nunavut: 'NU',
      Ontario: 'ON',
      'Prince Edward Island': 'PE',
      Quebec: 'QC',
      Saskatchewan: 'SK',
      Yukon: 'YT',
    },
    dial_code: '+1',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    zip_label: 'Postcode',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+44',
  },
  {
    name: 'Philippines',
    code: 'PH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+63',
  },
  {
    name: 'Afghanistan',
    code: 'AF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+93',
  },
  {
    name: 'Aland Islands',
    code: 'AX',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Albania',
    code: 'AL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+355',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: null,
    province_codes: {},
    dial_code: '+213',
  },
  {
    name: 'Andorra',
    code: 'AD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+376',
  },
  {
    name: 'Angola',
    code: 'AO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+244',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 264',
  },
  {
    name: 'Antigua And Barbuda',
    code: 'AG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1268',
  },
  {
    name: 'Argentina',
    code: 'AR',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Buenos Aires',
      'Catamarca',
      'Chaco',
      'Chubut',
      'Ciudad Autónoma de Buenos Aires',
      'Córdoba',
      'Corrientes',
      'Entre Ríos',
      'Formosa',
      'Jujuy',
      'La Pampa',
      'La Rioja',
      'Mendoza',
      'Misiones',
      'Neuquén',
      'Río Negro',
      'Salta',
      'San Juan',
      'San Luis',
      'Santa Cruz',
      'Santa Fe',
      'Santiago Del Estero',
      'Tierra Del Fuego',
      'Tucumán',
    ],
    province_codes: {
      'Buenos Aires': 'B',
      Catamarca: 'K',
      Chaco: 'H',
      Chubut: 'U',
      'Ciudad Autónoma de Buenos Aires': 'C',
      Córdoba: 'X',
      Corrientes: 'W',
      'Entre Ríos': 'E',
      Formosa: 'P',
      Jujuy: 'Y',
      'La Pampa': 'L',
      'La Rioja': 'F',
      Mendoza: 'M',
      Misiones: 'N',
      Neuquén: 'Q',
      'Río Negro': 'R',
      Salta: 'A',
      'San Juan': 'J',
      'San Luis': 'D',
      'Santa Cruz': 'Z',
      'Santa Fe': 'S',
      'Santiago Del Estero': 'G',
      'Tierra Del Fuego': 'V',
      Tucumán: 'T',
    },
    dial_code: '+54',
  },
  {
    name: 'Armenia',
    code: 'AM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+374',
  },
  {
    name: 'Aruba',
    code: 'AW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+297',
  },
  {
    name: 'Australia',
    code: 'AU',
    zip_label: 'Postcode',
    province_label: 'State/territory',
    provinces: [
      'Australian Capital Territory',
      'New South Wales',
      'Northern Territory',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia',
    ],
    province_codes: {
      'Australian Capital Territory': 'ACT',
      'New South Wales': 'NSW',
      'Northern Territory': 'NT',
      Queensland: 'QLD',
      'South Australia': 'SA',
      Tasmania: 'TAS',
      Victoria: 'VIC',
      'Western Australia': 'WA',
    },
    dial_code: '+61',
  },
  {
    name: 'Austria',
    code: 'AT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+43',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+994',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 242',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+973',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+880',
  },
  {
    name: 'Barbados',
    code: 'BB',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 246',
  },
  {
    name: 'Belarus',
    code: 'BY',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: null,
    province_codes: {},
    dial_code: '+375',
  },
  {
    name: 'Belgium',
    code: 'BE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+32',
  },
  {
    name: 'Belize',
    code: 'BZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+501',
  },
  {
    name: 'Benin',
    code: 'BJ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+229',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 441',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+975',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+591',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Bosnia And Herzegovina',
    code: 'BA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+387',
  },
  {
    name: 'Botswana',
    code: 'BW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+267',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Brazil',
    code: 'BR',
    zip_label: 'Postal code',
    province_label: 'State',
    provinces: [
      'Acre',
      'Alagoas',
      'Amapá',
      'Amazonas',
      'Bahia',
      'Ceará',
      'Distrito Federal',
      'Espírito Santo',
      'Goiás',
      'Maranhão',
      'Mato Grosso',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'Pará',
      'Paraíba',
      'Paraná',
      'Pernambuco',
      'Piauí',
      'Rio Grande do Norte',
      'Rio Grande do Sul',
      'Rio de Janeiro',
      'Rondônia',
      'Roraima',
      'Santa Catarina',
      'São Paulo',
      'Sergipe',
      'Tocantins',
    ],
    province_codes: {
      Acre: 'AC',
      Alagoas: 'AL',
      Amapá: 'AP',
      Amazonas: 'AM',
      Bahia: 'BA',
      Ceará: 'CE',
      'Distrito Federal': 'DF',
      'Espírito Santo': 'ES',
      Goiás: 'GO',
      Maranhão: 'MA',
      'Mato Grosso': 'MT',
      'Mato Grosso do Sul': 'MS',
      'Minas Gerais': 'MG',
      Pará: 'PA',
      Paraíba: 'PB',
      Paraná: 'PR',
      Pernambuco: 'PE',
      Piauí: 'PI',
      'Rio Grande do Norte': 'RN',
      'Rio Grande do Sul': 'RS',
      'Rio de Janeiro': 'RJ',
      Rondônia: 'RO',
      Roraima: 'RR',
      'Santa Catarina': 'SC',
      'São Paulo': 'SP',
      Sergipe: 'SE',
      Tocantins: 'TO',
    },
    dial_code: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+246',
  },
  {
    name: 'Brunei',
    code: 'BN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+673',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+359',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+226',
  },
  {
    name: 'Burundi',
    code: 'BI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+257',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+855',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+238',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+ 345',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+236',
  },
  {
    name: 'Chad',
    code: 'TD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+235',
  },
  {
    name: 'Chile',
    code: 'CL',
    zip_label: 'Postal code',
    province_label: 'State',
    provinces: null,
    province_codes: {},
    dial_code: '+56',
  },
  {
    name: 'China',
    code: 'CN',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Anhui',
      'Beijing',
      'Chongqing',
      'Fujian',
      'Gansu',
      'Guangdong',
      'Guangxi',
      'Guizhou',
      'Hainan',
      'Hebei',
      'Heilongjiang',
      'Henan',
      'Hubei',
      'Hunan',
      'Inner Mongolia',
      'Jiangsu',
      'Jiangxi',
      'Jilin',
      'Liaoning',
      'Ningxia',
      'Qinghai',
      'Shaanxi',
      'Shandong',
      'Shanghai',
      'Shanxi',
      'Sichuan',
      'Tianjin',
      'Xinjiang',
      'Xizang',
      'Yunnan',
      'Zhejiang',
    ],
    province_codes: {
      Anhui: 'AH',
      Beijing: 'BJ',
      Chongqing: 'CQ',
      Fujian: 'FJ',
      Gansu: 'GS',
      Guangdong: 'GD',
      Guangxi: 'GX',
      Guizhou: 'GZ',
      Hainan: 'HI',
      Hebei: 'HE',
      Heilongjiang: 'HL',
      Henan: 'HA',
      Hubei: 'HB',
      Hunan: 'HN',
      'Inner Mongolia': 'NM',
      Jiangsu: 'JS',
      Jiangxi: 'JX',
      Jilin: 'JL',
      Liaoning: 'LN',
      Ningxia: 'NX',
      Qinghai: 'QH',
      Shaanxi: 'SN',
      Shandong: 'SD',
      Shanghai: 'SH',
      Shanxi: 'SX',
      Sichuan: 'SC',
      Tianjin: 'TJ',
      Xinjiang: 'XJ',
      Xizang: 'YZ',
      Yunnan: 'YN',
      Zhejiang: 'ZJ',
    },
    dial_code: '+86',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+61',
  },
  {
    name: 'Colombia',
    code: 'CO',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Amazonas',
      'Antioquia',
      'Arauca',
      'Atlántico',
      'Bogotá, D.C.',
      'Bolívar',
      'Boyacá',
      'Caldas',
      'Caquetá',
      'Casanare',
      'Cauca',
      'Cesar',
      'Chocó',
      'Córdoba',
      'Cundinamarca',
      'Guainía',
      'Guaviare',
      'Huila',
      'La Guajira',
      'Magdalena',
      'Meta',
      'Nariño',
      'Norte de Santander',
      'Putumayo',
      'Quindío',
      'Risaralda',
      'San Andrés, Providencia y Santa Catalina',
      'Santander',
      'Sucre',
      'Tolima',
      'Valle del Cauca',
      'Vaupés',
      'Vichada',
    ],
    province_codes: {
      Amazonas: 'AMA',
      Antioquia: 'ANT',
      Arauca: 'ARA',
      Atlántico: 'ATL',
      'Bogotá, D.C.': 'DC',
      Bolívar: 'BOL',
      Boyacá: 'BOY',
      Caldas: 'CAL',
      Caquetá: 'CAQ',
      Casanare: 'CAS',
      Cauca: 'CAU',
      Cesar: 'CES',
      Chocó: 'CHO',
      Córdoba: 'COR',
      Cundinamarca: 'CUN',
      Guainía: 'GUA',
      Guaviare: 'GUV',
      Huila: 'HUI',
      'La Guajira': 'LAG',
      Magdalena: 'MAG',
      Meta: 'MET',
      Nariño: 'NAR',
      'Norte de Santander': 'NSA',
      Putumayo: 'PUT',
      Quindío: 'QUI',
      Risaralda: 'RIS',
      'San Andrés, Providencia y Santa Catalina': 'SAP',
      Santander: 'SAN',
      Sucre: 'SUC',
      Tolima: 'TOL',
      'Valle del Cauca': 'VAC',
      Vaupés: 'VAU',
      Vichada: 'VID',
    },
    dial_code: '+57',
  },
  {
    name: 'Comoros',
    code: 'KM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+269',
  },
  {
    name: 'Congo',
    code: 'CG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+242',
  },
  {
    name: 'Congo, The Democratic Republic Of The',
    code: 'CD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+243',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+682',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+506',
  },
  {
    name: 'Croatia',
    code: 'HR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+385',
  },
  {
    name: 'Cuba',
    code: 'CU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+53',
  },
  {
    name: 'Curaçao',
    code: 'CW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+537',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+420',
  },
  {
    name: "Côte d'Ivoire",
    code: 'CI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+225',
  },
  {
    name: 'Denmark',
    code: 'DK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+45',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+253',
  },
  {
    name: 'Dominica',
    code: 'DM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 767',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 849',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+593',
  },
  {
    name: 'Egypt',
    code: 'EG',
    zip_label: 'Postal code',
    province_label: 'Governorate',
    provinces: [
      '6th of October',
      'Al Sharqia',
      'Alexandria',
      'Aswan',
      'Asyut',
      'Beheira',
      'Beni Suef',
      'Cairo',
      'Dakahlia',
      'Damietta',
      'Faiyum',
      'Gharbia',
      'Giza',
      'Helwan',
      'Ismailia',
      'Kafr el-Sheikh',
      'Luxor',
      'Matrouh',
      'Minya',
      'Monufia',
      'New Valley',
      'North Sinai',
      'Port Said',
      'Qalyubia',
      'Qena',
      'Red Sea',
      'Sohag',
      'South Sinai',
      'Suez',
    ],
    province_codes: {
      '6th of October': 'SU',
      'Al Sharqia': 'SHR',
      Alexandria: 'ALX',
      Aswan: 'ASN',
      Asyut: 'AST',
      Beheira: 'BH',
      'Beni Suef': 'BNS',
      Cairo: 'C',
      Dakahlia: 'DK',
      Damietta: 'DT',
      Faiyum: 'FYM',
      Gharbia: 'GH',
      Giza: 'GZ',
      Helwan: 'HU',
      Ismailia: 'IS',
      'Kafr el-Sheikh': 'KFS',
      Luxor: 'LX',
      Matrouh: 'MT',
      Minya: 'MN',
      Monufia: 'MNF',
      'New Valley': 'WAD',
      'North Sinai': 'SIN',
      'Port Said': 'PTS',
      Qalyubia: 'KB',
      Qena: 'KN',
      'Red Sea': 'BA',
      Sohag: 'SHG',
      'South Sinai': 'JS',
      Suez: 'SUZ',
    },
    dial_code: '+20',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+503',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+240',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+291',
  },
  {
    name: 'Estonia',
    code: 'EE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+372',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+251',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+500',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+298',
  },
  {
    name: 'Fiji',
    code: 'FJ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+679',
  },
  {
    name: 'Finland',
    code: 'FI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+358',
  },
  {
    name: 'France',
    code: 'FR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+33',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+594',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+689',
  },
  {
    name: 'French Southern Territories',
    code: 'TF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Gabon',
    code: 'GA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+241',
  },
  {
    name: 'Gambia',
    code: 'GM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+220',
  },
  {
    name: 'Georgia',
    code: 'GE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+995',
  },
  {
    name: 'Germany',
    code: 'DE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+49',
  },
  {
    name: 'Ghana',
    code: 'GH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+233',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+350',
  },
  {
    name: 'Greece',
    code: 'GR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+30',
  },
  {
    name: 'Greenland',
    code: 'GL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+299',
  },
  {
    name: 'Grenada',
    code: 'GD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 473',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+590',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: [
      'Alta Verapaz',
      'Baja Verapaz',
      'Chimaltenango',
      'Chiquimula',
      'El Progreso',
      'Escuintla',
      'Guatemala',
      'Huehuetenango',
      'Izabal',
      'Jalapa',
      'Jutiapa',
      'Petén',
      'Quetzaltenango',
      'Quiché',
      'Retalhuleu',
      'Sacatepéquez',
      'San Marcos',
      'Santa Rosa',
      'Sololá',
      'Suchitepéquez',
      'Totonicapán',
      'Zacapa',
    ],
    province_codes: {
      'Alta Verapaz': 'AVE',
      'Baja Verapaz': 'BVE',
      Chimaltenango: 'CMT',
      Chiquimula: 'CQM',
      'El Progreso': 'EPR',
      Escuintla: 'ESC',
      Guatemala: 'GUA',
      Huehuetenango: 'HUE',
      Izabal: 'IZA',
      Jalapa: 'JAL',
      Jutiapa: 'JUT',
      Petén: 'PET',
      Quetzaltenango: 'QUE',
      Quiché: 'QUI',
      Retalhuleu: 'RET',
      Sacatepéquez: 'SAC',
      'San Marcos': 'SMA',
      'Santa Rosa': 'SRO',
      Sololá: 'SOL',
      Suchitepéquez: 'SUC',
      Totonicapán: 'TOT',
      Zacapa: 'ZAC',
    },
    dial_code: '+502',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+44',
  },
  {
    name: 'Guinea',
    code: 'GN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+224',
  },
  {
    name: 'Guinea Bissau',
    code: 'GW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+245',
  },
  {
    name: 'Guyana',
    code: 'GY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+595',
  },
  {
    name: 'Haiti',
    code: 'HT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+509',
  },
  {
    name: 'Heard Island And Mcdonald Islands',
    code: 'HM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+379',
  },
  {
    name: 'Honduras',
    code: 'HN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+504',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: ['Hong Kong Island', 'Kowloon', 'New Territories'],
    province_codes: {
      'Hong Kong Island': 'HK',
      Kowloon: 'KL',
      'New Territories': 'NT',
    },
    dial_code: '+852',
  },
  {
    name: 'Hungary',
    code: 'HU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+36',
  },
  {
    name: 'Iceland',
    code: 'IS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+354',
  },
  {
    name: 'India',
    code: 'IN',
    zip_label: 'Pincode',
    province_label: 'State',
    provinces: [
      'Andaman and Nicobar',
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chandigarh',
      'Chattisgarh',
      'Dadra and Nagar Haveli',
      'Daman and Diu',
      'Delhi',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Lakshadweep',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Orissa',
      'Puducherry',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttar Pradesh',
      'Uttarakhand',
      'West Bengal',
    ],
    province_codes: {
      'Andaman and Nicobar': 'AN',
      'Andhra Pradesh': 'AP',
      'Arunachal Pradesh': 'AR',
      Assam: 'AS',
      Bihar: 'BR',
      Chandigarh: 'CH',
      Chattisgarh: 'CG',
      'Dadra and Nagar Haveli': 'DN',
      'Daman and Diu': 'DD',
      Delhi: 'DL',
      Goa: 'GA',
      Gujarat: 'GJ',
      Haryana: 'HR',
      'Himachal Pradesh': 'HP',
      'Jammu and Kashmir': 'JK',
      Jharkhand: 'JH',
      Karnataka: 'KA',
      Kerala: 'KL',
      Lakshadweep: 'LD',
      'Madhya Pradesh': 'MP',
      Maharashtra: 'MH',
      Manipur: 'MN',
      Meghalaya: 'ML',
      Mizoram: 'MZ',
      Nagaland: 'NL',
      Orissa: 'OR',
      Puducherry: 'PY',
      Punjab: 'PB',
      Rajasthan: 'RJ',
      Sikkim: 'SK',
      'Tamil Nadu': 'TN',
      Telangana: 'TS',
      Tripura: 'TR',
      'Uttar Pradesh': 'UP',
      Uttarakhand: 'UK',
      'West Bengal': 'WB',
    },
    dial_code: '+91',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Aceh',
      'Bali',
      'Bangka Belitung',
      'Banten',
      'Bengkulu',
      'Gorontalo',
      'Jakarta',
      'Jambi',
      'Jawa Barat',
      'Jawa Tengah',
      'Jawa Timur',
      'Kalimantan Barat',
      'Kalimantan Selatan',
      'Kalimantan Tengah',
      'Kalimantan Timur',
      'Kalimantan Utara',
      'Kepulauan Riau',
      'Lampung',
      'Maluku',
      'Maluku Utara',
      'Nusa Tenggara Barat',
      'Nusa Tenggara Timur',
      'Papua',
      'Papua Barat',
      'Riau',
      'Sulawesi Barat',
      'Sulawesi Selatan',
      'Sulawesi Tengah',
      'Sulawesi Tenggara',
      'Sulawesi Utara',
      'Sumatra Barat',
      'Sumatra Selatan',
      'Sumatra Utara',
      'Yogyakarta',
    ],
    province_codes: {
      Aceh: 'AC',
      Bali: 'BA',
      'Bangka Belitung': 'BB',
      Banten: 'BT',
      Bengkulu: 'BE',
      Gorontalo: 'GO',
      Jakarta: 'JK',
      Jambi: 'JA',
      'Jawa Barat': 'JB',
      'Jawa Tengah': 'JT',
      'Jawa Timur': 'JI',
      'Kalimantan Barat': 'KB',
      'Kalimantan Selatan': 'KS',
      'Kalimantan Tengah': 'KT',
      'Kalimantan Timur': 'KI',
      'Kalimantan Utara': 'KU',
      'Kepulauan Riau': 'KR',
      Lampung: 'LA',
      Maluku: 'MA',
      'Maluku Utara': 'MU',
      'Nusa Tenggara Barat': 'NB',
      'Nusa Tenggara Timur': 'NT',
      Papua: 'PA',
      'Papua Barat': 'PB',
      Riau: 'RI',
      'Sulawesi Barat': 'SR',
      'Sulawesi Selatan': 'SN',
      'Sulawesi Tengah': 'ST',
      'Sulawesi Tenggara': 'SG',
      'Sulawesi Utara': 'SA',
      'Sumatra Barat': 'SB',
      'Sumatra Selatan': 'SS',
      'Sumatra Utara': 'SU',
      Yogyakarta: 'YO',
    },
    dial_code: '+62',
  },
  {
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+98',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+964',
  },
  {
    name: 'Ireland',
    code: 'IE',
    zip_label: 'Postal code',
    province_label: 'County',
    provinces: [
      'Carlow',
      'Cavan',
      'Clare',
      'Cork',
      'Donegal',
      'Dublin',
      'Galway',
      'Kerry',
      'Kildare',
      'Kilkenny',
      'Laois',
      'Leitrim',
      'Limerick',
      'Longford',
      'Louth',
      'Mayo',
      'Meath',
      'Monaghan',
      'Offaly',
      'Roscommon',
      'Sligo',
      'Tipperary',
      'Waterford',
      'Westmeath',
      'Wexford',
      'Wicklow',
    ],
    province_codes: {
      Carlow: 'CW',
      Cavan: 'CN',
      Clare: 'CE',
      Cork: 'CO',
      Donegal: 'DL',
      Dublin: 'D',
      Galway: 'G',
      Kerry: 'KY',
      Kildare: 'KE',
      Kilkenny: 'KK',
      Laois: 'LS',
      Leitrim: 'LM',
      Limerick: 'LK',
      Longford: 'LD',
      Louth: 'LH',
      Mayo: 'MO',
      Meath: 'MH',
      Monaghan: 'MN',
      Offaly: 'OY',
      Roscommon: 'RN',
      Sligo: 'SO',
      Tipperary: 'TA',
      Waterford: 'WD',
      Westmeath: 'WH',
      Wexford: 'WX',
      Wicklow: 'WW',
    },
    dial_code: '+353',
  },
  {
    name: 'Isle Of Man',
    code: 'IM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+44',
  },
  {
    name: 'Israel',
    code: 'IL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+972',
  },
  {
    name: 'Italy',
    code: 'IT',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Agrigento',
      'Alessandria',
      'Ancona',
      'Aosta',
      'Arezzo',
      'Ascoli Piceno',
      'Asti',
      'Avellino',
      'Bari',
      'Barletta-Andria-Trani',
      'Belluno',
      'Benevento',
      'Bergamo',
      'Biella',
      'Bologna',
      'Bolzano',
      'Brescia',
      'Brindisi',
      'Cagliari',
      'Caltanissetta',
      'Campobasso',
      'Carbonia-Iglesias',
      'Caserta',
      'Catania',
      'Catanzaro',
      'Chieti',
      'Como',
      'Cosenza',
      'Cremona',
      'Crotone',
      'Cuneo',
      'Enna',
      'Fermo',
      'Ferrara',
      'Firenze',
      'Foggia',
      'Forlì-Cesena',
      'Frosinone',
      'Genova',
      'Gorizia',
      'Grosseto',
      'Imperia',
      'Isernia',
      "L'Aquila",
      'La Spezia',
      'Latina',
      'Lecce',
      'Lecco',
      'Livorno',
      'Lodi',
      'Lucca',
      'Macerata',
      'Mantova',
      'Massa-Carrara',
      'Matera',
      'Medio Campidano',
      'Messina',
      'Milano',
      'Modena',
      'Monza e Brianza',
      'Napoli',
      'Novara',
      'Nuoro',
      'Ogliastra',
      'Olbia-Tempio',
      'Oristano',
      'Padova',
      'Palermo',
      'Parma',
      'Pavia',
      'Perugia',
      'Pesaro e Urbino',
      'Pescara',
      'Piacenza',
      'Pisa',
      'Pistoia',
      'Pordenone',
      'Potenza',
      'Prato',
      'Ragusa',
      'Ravenna',
      'Reggio Calabria',
      'Reggio Emilia',
      'Rieti',
      'Rimini',
      'Roma',
      'Rovigo',
      'Salerno',
      'Sassari',
      'Savona',
      'Siena',
      'Siracusa',
      'Sondrio',
      'Taranto',
      'Teramo',
      'Terni',
      'Torino',
      'Trapani',
      'Trento',
      'Treviso',
      'Trieste',
      'Udine',
      'Varese',
      'Venezia',
      'Verbano-Cusio-Ossola',
      'Vercelli',
      'Verona',
      'Vibo Valentia',
      'Vicenza',
      'Viterbo',
    ],
    province_codes: {
      Agrigento: 'AG',
      Alessandria: 'AL',
      Ancona: 'AN',
      Aosta: 'AO',
      Arezzo: 'AR',
      'Ascoli Piceno': 'AP',
      Asti: 'AT',
      Avellino: 'AV',
      Bari: 'BA',
      'Barletta-Andria-Trani': 'BT',
      Belluno: 'BL',
      Benevento: 'BN',
      Bergamo: 'BG',
      Biella: 'BI',
      Bologna: 'BO',
      Bolzano: 'BZ',
      Brescia: 'BS',
      Brindisi: 'BR',
      Cagliari: 'CA',
      Caltanissetta: 'CL',
      Campobasso: 'CB',
      'Carbonia-Iglesias': 'CI',
      Caserta: 'CE',
      Catania: 'CT',
      Catanzaro: 'CZ',
      Chieti: 'CH',
      Como: 'CO',
      Cosenza: 'CS',
      Cremona: 'CR',
      Crotone: 'KR',
      Cuneo: 'CN',
      Enna: 'EN',
      Fermo: 'FM',
      Ferrara: 'FE',
      Firenze: 'FI',
      Foggia: 'FG',
      'Forlì-Cesena': 'FC',
      Frosinone: 'FR',
      Genova: 'GE',
      Gorizia: 'GO',
      Grosseto: 'GR',
      Imperia: 'IM',
      Isernia: 'IS',
      "L'Aquila": 'AQ',
      'La Spezia': 'SP',
      Latina: 'LT',
      Lecce: 'LE',
      Lecco: 'LC',
      Livorno: 'LI',
      Lodi: 'LO',
      Lucca: 'LU',
      Macerata: 'MC',
      Mantova: 'MN',
      'Massa-Carrara': 'MS',
      Matera: 'MT',
      'Medio Campidano': 'VS',
      Messina: 'ME',
      Milano: 'MI',
      Modena: 'MO',
      'Monza e Brianza': 'MB',
      Napoli: 'NA',
      Novara: 'NO',
      Nuoro: 'NU',
      Ogliastra: 'OG',
      'Olbia-Tempio': 'OT',
      Oristano: 'OR',
      Padova: 'PD',
      Palermo: 'PA',
      Parma: 'PR',
      Pavia: 'PV',
      Perugia: 'PG',
      'Pesaro e Urbino': 'PU',
      Pescara: 'PE',
      Piacenza: 'PC',
      Pisa: 'PI',
      Pistoia: 'PT',
      Pordenone: 'PN',
      Potenza: 'PZ',
      Prato: 'PO',
      Ragusa: 'RG',
      Ravenna: 'RA',
      'Reggio Calabria': 'RC',
      'Reggio Emilia': 'RE',
      Rieti: 'RI',
      Rimini: 'RN',
      Roma: 'RM',
      Rovigo: 'RO',
      Salerno: 'SA',
      Sassari: 'SS',
      Savona: 'SV',
      Siena: 'SI',
      Siracusa: 'SR',
      Sondrio: 'SO',
      Taranto: 'TA',
      Teramo: 'TE',
      Terni: 'TR',
      Torino: 'TO',
      Trapani: 'TP',
      Trento: 'TN',
      Treviso: 'TV',
      Trieste: 'TS',
      Udine: 'UD',
      Varese: 'VA',
      Venezia: 'VE',
      'Verbano-Cusio-Ossola': 'VB',
      Vercelli: 'VC',
      Verona: 'VR',
      'Vibo Valentia': 'VV',
      Vicenza: 'VI',
      Viterbo: 'VT',
    },
    dial_code: '+39',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 876',
  },
  {
    name: 'Japan',
    code: 'JP',
    zip_label: 'Postal code',
    province_label: 'Prefecture',
    provinces: [
      'Hokkaidō',
      'Aomori',
      'Iwate',
      'Miyagi',
      'Akita',
      'Yamagata',
      'Fukushima',
      'Ibaraki',
      'Tochigi',
      'Gunma',
      'Saitama',
      'Chiba',
      'Tōkyō',
      'Kanagawa',
      'Niigata',
      'Toyama',
      'Ishikawa',
      'Fukui',
      'Yamanashi',
      'Nagano',
      'Gifu',
      'Shizuoka',
      'Aichi',
      'Mie',
      'Shiga',
      'Kyōto',
      'Ōsaka',
      'Hyōgo',
      'Nara',
      'Wakayama',
      'Tottori',
      'Shimane',
      'Okayama',
      'Hiroshima',
      'Yamaguchi',
      'Tokushima',
      'Kagawa',
      'Ehime',
      'Kōchi',
      'Fukuoka',
      'Saga',
      'Nagasaki',
      'Kumamoto',
      'Ōita',
      'Miyazaki',
      'Kagoshima',
      'Okinawa',
    ],
    province_codes: {
      Hokkaidō: 'JP-01',
      Aomori: 'JP-02',
      Iwate: 'JP-03',
      Miyagi: 'JP-04',
      Akita: 'JP-05',
      Yamagata: 'JP-06',
      Fukushima: 'JP-07',
      Ibaraki: 'JP-08',
      Tochigi: 'JP-09',
      Gunma: 'JP-10',
      Saitama: 'JP-11',
      Chiba: 'JP-12',
      Tōkyō: 'JP-13',
      Kanagawa: 'JP-14',
      Niigata: 'JP-15',
      Toyama: 'JP-16',
      Ishikawa: 'JP-17',
      Fukui: 'JP-18',
      Yamanashi: 'JP-19',
      Nagano: 'JP-20',
      Gifu: 'JP-21',
      Shizuoka: 'JP-22',
      Aichi: 'JP-23',
      Mie: 'JP-24',
      Shiga: 'JP-25',
      Kyōto: 'JP-26',
      Ōsaka: 'JP-27',
      Hyōgo: 'JP-28',
      Nara: 'JP-29',
      Wakayama: 'JP-30',
      Tottori: 'JP-31',
      Shimane: 'JP-32',
      Okayama: 'JP-33',
      Hiroshima: 'JP-34',
      Yamaguchi: 'JP-35',
      Tokushima: 'JP-36',
      Kagawa: 'JP-37',
      Ehime: 'JP-38',
      Kōchi: 'JP-39',
      Fukuoka: 'JP-40',
      Saga: 'JP-41',
      Nagasaki: 'JP-42',
      Kumamoto: 'JP-43',
      Ōita: 'JP-44',
      Miyazaki: 'JP-45',
      Kagoshima: 'JP-46',
      Okinawa: 'JP-47',
    },
    dial_code: '+81',
  },
  {
    name: 'Jersey',
    code: 'JE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+44',
  },
  {
    name: 'Jordan',
    code: 'JO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+962',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+7 7',
  },
  {
    name: 'Kenya',
    code: 'KE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+254',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+686',
  },
  {
    name: "Korea, Democratic People's Republic Of",
    code: 'KP',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+850',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+965',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+996',
  },
  {
    name: "Lao People's Democratic Republic",
    code: 'LA',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: null,
    province_codes: {},
    dial_code: '+856',
  },
  {
    name: 'Latvia',
    code: 'LV',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+371',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+961',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+266',
  },
  {
    name: 'Liberia',
    code: 'LR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+231',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+218',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+423',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+370',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+352',
  },
  {
    name: 'Macao',
    code: 'MO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+853',
  },
  {
    name: 'Macedonia, Republic Of',
    code: 'MK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+389',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+261',
  },
  {
    name: 'Malawi',
    code: 'MW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+265',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    zip_label: 'Postcode',
    province_label: 'State/territory',
    provinces: [
      'Johor',
      'Kedah',
      'Kelantan',
      'Kuala Lumpur',
      'Labuan',
      'Melaka',
      'Negeri Sembilan',
      'Pahang',
      'Perak',
      'Perlis',
      'Pulau Pinang',
      'Putrajaya',
      'Sabah',
      'Sarawak',
      'Selangor',
      'Terengganu',
    ],
    province_codes: {
      Johor: 'JHR',
      Kedah: 'KDH',
      Kelantan: 'KTN',
      'Kuala Lumpur': 'KUL',
      Labuan: 'LBN',
      Melaka: 'MLK',
      'Negeri Sembilan': 'NSN',
      Pahang: 'PHG',
      Perak: 'PRK',
      Perlis: 'PLS',
      'Pulau Pinang': 'PNG',
      Putrajaya: 'PJY',
      Sabah: 'SBH',
      Sarawak: 'SWK',
      Selangor: 'SGR',
      Terengganu: 'TRG',
    },
    dial_code: '+60',
  },
  {
    name: 'Mali',
    code: 'MV',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+960',
  },
  {
    name: 'Mali',
    code: 'ML',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+223',
  },
  {
    name: 'Malta',
    code: 'MT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+356',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+596',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+222',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+230',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+262',
  },
  {
    name: 'Mexico',
    code: 'MX',
    zip_label: 'Postal code',
    province_label: 'State',
    provinces: [
      'Aguascalientes',
      'Baja California',
      'Baja California Sur',
      'Campeche',
      'Chiapas',
      'Chihuahua',
      'Ciudad de México',
      'Coahuila',
      'Colima',
      'Durango',
      'Guanajuato',
      'Guerrero',
      'Hidalgo',
      'Jalisco',
      'México',
      'Michoacán',
      'Morelos',
      'Nayarit',
      'Nuevo León',
      'Oaxaca',
      'Puebla',
      'Querétaro',
      'Quintana Roo',
      'San Luis Potosí',
      'Sinaloa',
      'Sonora',
      'Tabasco',
      'Tamaulipas',
      'Tlaxcala',
      'Veracruz',
      'Yucatán',
      'Zacatecas',
    ],
    province_codes: {
      Aguascalientes: 'AGS',
      'Baja California': 'BC',
      'Baja California Sur': 'BCS',
      Campeche: 'CAMP',
      Chiapas: 'CHIS',
      Chihuahua: 'CHIH',
      'Ciudad de México': 'DF',
      Coahuila: 'COAH',
      Colima: 'COL',
      Durango: 'DGO',
      Guanajuato: 'GTO',
      Guerrero: 'GRO',
      Hidalgo: 'HGO',
      Jalisco: 'JAL',
      México: 'MEX',
      Michoacán: 'MICH',
      Morelos: 'MOR',
      Nayarit: 'NAY',
      'Nuevo León': 'NL',
      Oaxaca: 'OAX',
      Puebla: 'PUE',
      Querétaro: 'QRO',
      'Quintana Roo': 'Q ROO',
      'San Luis Potosí': 'SLP',
      Sinaloa: 'SIN',
      Sonora: 'SON',
      Tabasco: 'TAB',
      Tamaulipas: 'TAMPS',
      Tlaxcala: 'TLAX',
      Veracruz: 'VER',
      Yucatán: 'YUC',
      Zacatecas: 'ZAC',
    },
    dial_code: '+52',
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+373',
  },
  {
    name: 'Monaco',
    code: 'MC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+377',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+976',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+382',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1664',
  },
  {
    name: 'Morocco',
    code: 'MA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+212',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+258',
  },
  {
    name: 'Myanmar',
    code: 'MM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+95',
  },
  {
    name: 'Namibia',
    code: 'NA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+264',
  },
  {
    name: 'Nauru',
    code: 'NR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+674',
  },
  {
    name: 'Nepal',
    code: 'NP',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+977',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+31',
  },
  {
    name: 'Netherlands Antilles',
    code: 'AN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+599',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+687',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: [
      'Auckland',
      'Bay of Plenty',
      'Canterbury',
      'Gisborne',
      "Hawke's Bay",
      'Manawatu-Wanganui',
      'Marlborough',
      'Nelson',
      'Northland',
      'Otago',
      'Southland',
      'Taranaki',
      'Tasman',
      'Waikato',
      'Wellington',
      'West Coast',
    ],
    province_codes: {
      Auckland: 'AUK',
      'Bay of Plenty': 'BOP',
      Canterbury: 'CAN',
      Gisborne: 'GIS',
      "Hawke's Bay": 'HKB',
      'Manawatu-Wanganui': 'MWT',
      Marlborough: 'MBH',
      Nelson: 'NSN',
      Northland: 'NTL',
      Otago: 'OTA',
      Southland: 'STL',
      Taranaki: 'TKI',
      Tasman: 'TAS',
      Waikato: 'WKO',
      Wellington: 'WGN',
      'West Coast': 'WTC',
    },
    dial_code: '+64',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+505',
  },
  {
    name: 'Niger',
    code: 'NE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+227',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    zip_label: 'Postal code',
    province_label: 'State',
    provinces: [
      'Abia',
      'Abuja Federal Capital Territory',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
    ],
    province_codes: {
      Abia: 'AB',
      'Abuja Federal Capital Territory': 'FC',
      Adamawa: 'AD',
      'Akwa Ibom': 'AK',
      Anambra: 'AN',
      Bauchi: 'BA',
      Bayelsa: 'BY',
      Benue: 'BE',
      Borno: 'BO',
      'Cross River': 'CR',
      Delta: 'DE',
      Ebonyi: 'EB',
      Edo: 'ED',
      Ekiti: 'EK',
      Enugu: 'EN',
      Gombe: 'GO',
      Imo: 'IM',
      Jigawa: 'JI',
      Kaduna: 'KD',
      Kano: 'KN',
      Katsina: 'KT',
      Kebbi: 'KE',
      Kogi: 'KO',
      Kwara: 'KW',
      Lagos: 'LA',
      Nasarawa: 'NA',
      Niger: 'NI',
      Ogun: 'OG',
      Ondo: 'ON',
      Osun: 'OS',
      Oyo: 'OY',
      Plateau: 'PL',
      Rivers: 'RI',
      Sokoto: 'SO',
      Taraba: 'TA',
      Yobe: 'YO',
      Zamfara: 'ZA',
    },
    dial_code: '+234',
  },
  {
    name: 'Niue',
    code: 'NU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+683',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+672',
  },
  {
    name: 'Norway',
    code: 'NO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+47',
  },
  {
    name: 'Oman',
    code: 'OM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+968',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+92',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+970',
  },
  {
    name: 'Panama',
    code: 'PA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: [
      'Bocas del Toro',
      'Chiriquí',
      'Coclé',
      'Colón',
      'Darién',
      'Emberá',
      'Herrera',
      'Kuna Yala',
      'Los Santos',
      'Ngöbe-Buglé',
      'Panamá',
      'Panamá Oeste',
      'Veraguas',
    ],
    province_codes: {
      'Bocas del Toro': 'PA-1',
      Chiriquí: 'PA-4',
      Coclé: 'PA-2',
      Colón: 'PA-3',
      Darién: 'PA-5',
      Emberá: 'PA-EM',
      Herrera: 'PA-6',
      'Kuna Yala': 'PA-KY',
      'Los Santos': 'PA-7',
      'Ngöbe-Buglé': 'PA-NB',
      Panamá: 'PA-8',
      'Panamá Oeste': 'PA-10',
      Veraguas: 'PA-9',
    },
    dial_code: '+507',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+675',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+595',
  },
  {
    name: 'Peru',
    code: 'PE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: [
      'Amazonas',
      'Áncash',
      'Apurímac',
      'Arequipa',
      'Ayacucho',
      'Cajamarca',
      'Callao',
      'Cuzco',
      'Huancavelica',
      'Huánuco',
      'Ica',
      'Junín',
      'La Libertad',
      'Lambayeque',
      'Lima (departamento)',
      'Lima (provincia)',
      'Loreto',
      'Madre de Dios',
      'Moquegua',
      'Pasco',
      'Piura',
      'Puno',
      'San Martín',
      'Tacna',
      'Tumbes',
      'Ucayali',
    ],
    province_codes: {
      Amazonas: 'PE-AMA',
      Áncash: 'PE-ANC',
      Apurímac: 'PE-APU',
      Arequipa: 'PE-ARE',
      Ayacucho: 'PE-AYA',
      Cajamarca: 'PE-CAJ',
      Callao: 'PE-CAL',
      Cuzco: 'PE-CUS',
      Huancavelica: 'PE-HUV',
      Huánuco: 'PE-HUC',
      Ica: 'PE-ICA',
      Junín: 'PE-JUN',
      'La Libertad': 'PE-LAL',
      Lambayeque: 'PE-LAM',
      'Lima (departamento)': 'PE-LIM',
      'Lima (provincia)': 'PE-LMA',
      Loreto: 'PE-LOR',
      'Madre de Dios': 'PE-MDD',
      Moquegua: 'PE-MOQ',
      Pasco: 'PE-PAS',
      Piura: 'PE-PIU',
      Puno: 'PE-PUN',
      'San Martín': 'PE-SAM',
      Tacna: 'PE-TAC',
      Tumbes: 'PE-TUM',
      Ucayali: 'PE-UCA',
    },
    dial_code: '+51',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+872',
  },
  {
    name: 'Poland',
    code: 'PL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+48',
  },
  {
    name: 'Portugal',
    code: 'PT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: [
      'Açores',
      'Aveiro',
      'Beja',
      'Braga',
      'Bragança',
      'Castelo Branco',
      'Coimbra',
      'Évora',
      'Faro',
      'Guarda',
      'Leiria',
      'Lisboa',
      'Madeira',
      'Portalegre',
      'Porto',
      'Santarém',
      'Setúbal',
      'Viana do Castelo',
      'Vila Real',
      'Viseu',
    ],
    province_codes: {
      Açores: 'PT-20',
      Aveiro: 'PT-01',
      Beja: 'PT-02',
      Braga: 'PT-03',
      Bragança: 'PT-04',
      'Castelo Branco': 'PT-05',
      Coimbra: 'PT-06',
      Évora: 'PT-07',
      Faro: 'PT-08',
      Guarda: 'PT-09',
      Leiria: 'PT-10',
      Lisboa: 'PT-11',
      Madeira: 'PT-30',
      Portalegre: 'PT-12',
      Porto: 'PT-13',
      Santarém: 'PT-14',
      Setúbal: 'PT-15',
      'Viana do Castelo': 'PT-16',
      'Vila Real': 'PT-17',
      Viseu: 'PT-18',
    },
    dial_code: '+351',
  },
  {
    name: 'Qatar',
    code: 'QA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+974',
  },
  {
    name: 'Republic of Cameroon',
    code: 'CM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+237',
  },
  {
    name: 'Reunion',
    code: 'RE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+262',
  },
  {
    name: 'Romania',
    code: 'RO',
    zip_label: 'Postal code',
    province_label: 'County',
    provinces: [
      'Alba',
      'Arad',
      'Argeș',
      'Bacău',
      'Bihor',
      'Bistrița-Năsăud',
      'Botoșani',
      'Brașov',
      'Brăila',
      'București',
      'Buzău',
      'Călărași',
      'Caraș-Severin',
      'Cluj',
      'Constanța',
      'Covasna',
      'Dâmbovița',
      'Dolj',
      'Galați',
      'Giurgiu',
      'Gorj',
      'Harghita',
      'Hunedoara',
      'Iași',
      'Ialomița',
      'Ilfov',
      'Maramureș',
      'Mehedinți',
      'Mureș',
      'Neamț',
      'Olt',
      'Prahova',
      'Sălaj',
      'Satu Mare',
      'Sibiu',
      'Suceava',
      'Teleorman',
      'Timiș',
      'Tulcea',
      'Vâlcea',
      'Vaslui',
      'Vrancea',
    ],
    province_codes: {
      Alba: 'AB',
      Arad: 'AR',
      Argeș: 'AG',
      Bacău: 'BC',
      Bihor: 'BH',
      'Bistrița-Năsăud': 'BN',
      Botoșani: 'BT',
      Brașov: 'BV',
      Brăila: 'BR',
      București: 'B',
      Buzău: 'BZ',
      Călărași: 'CL',
      'Caraș-Severin': 'CS',
      Cluj: 'CJ',
      Constanța: 'CT',
      Covasna: 'CV',
      Dâmbovița: 'DB',
      Dolj: 'DJ',
      Galați: 'GL',
      Giurgiu: 'GR',
      Gorj: 'GJ',
      Harghita: 'HR',
      Hunedoara: 'HD',
      Iași: 'IS',
      Ialomița: 'IL',
      Ilfov: 'IF',
      Maramureș: 'MM',
      Mehedinți: 'MH',
      Mureș: 'MS',
      Neamț: 'NT',
      Olt: 'OT',
      Prahova: 'PH',
      Sălaj: 'SJ',
      'Satu Mare': 'SM',
      Sibiu: 'SB',
      Suceava: 'SV',
      Teleorman: 'TR',
      Timiș: 'TM',
      Tulcea: 'TL',
      Vâlcea: 'VL',
      Vaslui: 'VS',
      Vrancea: 'VN',
    },
    dial_code: '+40',
  },
  {
    name: 'Russia',
    code: 'RU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: [
      'Republic of Adygeya',
      'Altai Republic',
      'Altai Krai',
      'Amur Oblast',
      'Arkhangelsk Oblast',
      'Astrakhan Oblast',
      'Republic of Bashkortostan',
      'Belgorod Oblast',
      'Bryansk Oblast',
      'Republic of Buryatia',
      'Chechen Republic',
      'Chelyabinsk Oblast',
      'Chukotka Autonomous Okrug',
      'Chuvash Republic',
      'Republic of Dagestan',
      'Republic of Ingushetia',
      'Irkutsk Oblast',
      'Ivanovo Oblast',
      'Jewish Autonomous Oblast',
      'Kabardino-Balkarian Republic',
      'Kaliningrad Oblast',
      'Republic of Kalmykia',
      'Kaluga Oblast',
      'Kamchatka Krai',
      'Karachay–Cherkess Republic',
      'Republic of Karelia',
      'Kemerovo Oblast',
      'Khabarovsk Krai',
      'Republic of Khakassia',
      'Khanty-Mansi Autonomous Okrug',
      'Kirov Oblast',
      'Komi Republic',
      'Kostroma Oblast',
      'Krasnodar Krai',
      'Krasnoyarsk Krai',
      'Kurgan Oblast',
      'Kursk Oblast',
      'Leningrad Oblast',
      'Lipetsk Oblast',
      'Magadan Oblast',
      'Mari El Republic',
      'Republic of Mordovia',
      'Moscow',
      'Moscow Oblast',
      'Murmansk Oblast',
      'Nizhny Novgorod Oblast',
      'Novgorod Oblast',
      'Novosibirsk Oblast',
      'Omsk Oblast',
      'Orenburg Oblast',
      'Oryol Oblast',
      'Penza Oblast',
      'Perm Krai',
      'Primorsky Krai',
      'Pskov Oblast',
      'Rostov Oblast',
      'Ryazan Oblast',
      'Saint Petersburg',
      'Sakha Republic (Yakutia)',
      'Sakhalin Oblast',
      'Samara Oblast',
      'Saratov Oblast',
      'Republic of North Ossetia–Alania',
      'Smolensk Oblast',
      'Stavropol Krai',
      'Sverdlovsk Oblast',
      'Tambov Oblast',
      'Republic of Tatarstan',
      'Tomsk Oblast',
      'Tula Oblast',
      'Tver Oblast',
      'Tyumen Oblast',
      'Tyva Republic',
      'Udmurtia',
      'Ulyanovsk Oblast',
      'Vladimir Oblast',
      'Volgograd Oblast',
      'Vologda Oblast',
      'Voronezh Oblast',
      'Yamalo-Nenets Autonomous Okrug',
      'Yaroslavl Oblast',
      'Zabaykalsky Krai',
    ],
    province_codes: {
      'Republic of Adygeya': 'AD',
      'Altai Republic': 'AL',
      'Altai Krai': 'ALT',
      'Amur Oblast': 'AMU',
      'Arkhangelsk Oblast': 'ARK',
      'Astrakhan Oblast': 'AST',
      'Republic of Bashkortostan': 'BA',
      'Belgorod Oblast': 'BEL',
      'Bryansk Oblast': 'BRY',
      'Republic of Buryatia': 'BU',
      'Chechen Republic': 'CE',
      'Chelyabinsk Oblast': 'CHE',
      'Chukotka Autonomous Okrug': 'CHU',
      'Chuvash Republic': 'CU',
      'Republic of Dagestan': 'DA',
      'Republic of Ingushetia': 'IN',
      'Irkutsk Oblast': 'IRK',
      'Ivanovo Oblast': 'IVA',
      'Jewish Autonomous Oblast': 'YEV',
      'Kabardino-Balkarian Republic': 'KB',
      'Kaliningrad Oblast': 'KGD',
      'Republic of Kalmykia': 'KL',
      'Kaluga Oblast': 'KLU',
      'Kamchatka Krai': 'KAM',
      'Karachay–Cherkess Republic': 'KC',
      'Republic of Karelia': 'KR',
      'Kemerovo Oblast': 'KEM',
      'Khabarovsk Krai': 'KHA',
      'Republic of Khakassia': 'KK',
      'Khanty-Mansi Autonomous Okrug': 'KHM',
      'Kirov Oblast': 'KIR',
      'Komi Republic': 'KO',
      'Kostroma Oblast': 'KOS',
      'Krasnodar Krai': 'KDA',
      'Krasnoyarsk Krai': 'KYA',
      'Kurgan Oblast': 'KGN',
      'Kursk Oblast': 'KRS',
      'Leningrad Oblast': 'LEN',
      'Lipetsk Oblast': 'LIP',
      'Magadan Oblast': 'MAG',
      'Mari El Republic': 'ME',
      'Republic of Mordovia': 'MO',
      Moscow: 'MOW',
      'Moscow Oblast': 'MOS',
      'Murmansk Oblast': 'MUR',
      'Nizhny Novgorod Oblast': 'NIZ',
      'Novgorod Oblast': 'NGR',
      'Novosibirsk Oblast': 'NVS',
      'Omsk Oblast': 'OMS',
      'Orenburg Oblast': 'ORE',
      'Oryol Oblast': 'ORL',
      'Penza Oblast': 'PNZ',
      'Perm Krai': 'PER',
      'Primorsky Krai': 'PRI',
      'Pskov Oblast': 'PSK',
      'Rostov Oblast': 'ROS',
      'Ryazan Oblast': 'RYA',
      'Saint Petersburg': 'SPE',
      'Sakha Republic (Yakutia)': 'SA',
      'Sakhalin Oblast': 'SAK',
      'Samara Oblast': 'SAM',
      'Saratov Oblast': 'SAR',
      'Republic of North Ossetia–Alania': 'SE',
      'Smolensk Oblast': 'SMO',
      'Stavropol Krai': 'STA',
      'Sverdlovsk Oblast': 'SVE',
      'Tambov Oblast': 'TAM',
      'Republic of Tatarstan': 'TA',
      'Tomsk Oblast': 'TOM',
      'Tula Oblast': 'TUL',
      'Tver Oblast': 'TVE',
      'Tyumen Oblast': 'TYU',
      'Tyva Republic': 'TY',
      Udmurtia: 'UD',
      'Ulyanovsk Oblast': 'ULY',
      'Vladimir Oblast': 'VLA',
      'Volgograd Oblast': 'VGG',
      'Vologda Oblast': 'VLG',
      'Voronezh Oblast': 'VOR',
      'Yamalo-Nenets Autonomous Okrug': 'YAN',
      'Yaroslavl Oblast': 'YAR',
      'Zabaykalsky Krai': 'ZAB',
    },
    dial_code: '+7',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+250',
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+590',
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+290',
  },
  {
    name: 'Saint Kitts And Nevis',
    code: 'KN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 869',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 758',
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+590',
  },
  {
    name: 'Saint Pierre And Miquelon',
    code: 'PM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+508',
  },
  {
    name: 'Samoa',
    code: 'WS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+685',
  },
  {
    name: 'San Marino',
    code: 'SM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+378',
  },
  {
    name: 'Sao Tome And Principe',
    code: 'ST',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+239',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+966',
  },
  {
    name: 'Senegal',
    code: 'SN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+221',
  },
  {
    name: 'Serbia',
    code: 'RS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+381',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+248',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+232',
  },
  {
    name: 'Singapore',
    code: 'SG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+65',
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+421',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+386',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+677',
  },
  {
    name: 'Somalia',
    code: 'SO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+252',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'North West',
      'Northern Cape',
      'Western Cape',
    ],
    province_codes: {
      'Eastern Cape': 'EC',
      'Free State': 'FS',
      Gauteng: 'GT',
      'KwaZulu-Natal': 'NL',
      Limpopo: 'LP',
      Mpumalanga: 'MP',
      'North West': 'NW',
      'Northern Cape': 'NC',
      'Western Cape': 'WC',
    },
    dial_code: '+27',
  },
  {
    name: 'South Georgia And The South Sandwich Islands',
    code: 'GS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+500',
  },
  {
    name: 'South Korea',
    code: 'KR',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Busan',
      'Chungbuk',
      'Chungnam',
      'Daegu',
      'Daejeon',
      'Gangwon',
      'Gwangju',
      'Gyeongbuk',
      'Gyeonggi',
      'Gyeongnam',
      'Incheon',
      'Jeju',
      'Jeonbuk',
      'Jeonnam',
      'Sejong',
      'Seoul',
      'Ulsan',
    ],
    province_codes: {
      Busan: 'KR-26',
      Chungbuk: 'KR-43',
      Chungnam: 'KR-44',
      Daegu: 'KR-27',
      Daejeon: 'KR-30',
      Gangwon: 'KR-42',
      Gwangju: 'KR-29',
      Gyeongbuk: 'KR-47',
      Gyeonggi: 'KR-41',
      Gyeongnam: 'KR-48',
      Incheon: 'KR-28',
      Jeju: 'KR-49',
      Jeonbuk: 'KR-45',
      Jeonnam: 'KR-46',
      Sejong: 'KR-50',
      Seoul: 'KR-11',
      Ulsan: 'KR-31',
    },
    dial_code: '+82',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Spain',
    code: 'ES',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'A Coruña',
      'Álava',
      'Albacete',
      'Alicante',
      'Almería',
      'Asturias',
      'Ávila',
      'Badajoz',
      'Balears',
      'Barcelona',
      'Burgos',
      'Cáceres',
      'Cádiz',
      'Cantabria',
      'Castellón',
      'Ceuta',
      'Ciudad Real',
      'Córdoba',
      'Cuenca',
      'Girona',
      'Granada',
      'Guadalajara',
      'Guipúzcoa',
      'Huelva',
      'Huesca',
      'Jaén',
      'La Rioja',
      'Las Palmas',
      'León',
      'Lleida',
      'Lugo',
      'Madrid',
      'Málaga',
      'Melilla',
      'Murcia',
      'Navarra',
      'Ourense',
      'Palencia',
      'Pontevedra',
      'Salamanca',
      'Santa Cruz de Tenerife',
      'Segovia',
      'Sevilla',
      'Soria',
      'Tarragona',
      'Teruel',
      'Toledo',
      'Valencia',
      'Valladolid',
      'Vizcaya',
      'Zamora',
      'Zaragoza',
    ],
    province_codes: {
      'A Coruña': 'C',
      Álava: 'VI',
      Albacete: 'AB',
      Alicante: 'A',
      Almería: 'AL',
      Asturias: 'O',
      Ávila: 'AV',
      Badajoz: 'BA',
      Balears: 'PM',
      Barcelona: 'B',
      Burgos: 'BU',
      Cáceres: 'CC',
      Cádiz: 'CA',
      Cantabria: 'S',
      Castellón: 'CS',
      Ceuta: 'CE',
      'Ciudad Real': 'CR',
      Córdoba: 'CO',
      Cuenca: 'CU',
      Girona: 'GI',
      Granada: 'GR',
      Guadalajara: 'GU',
      Guipúzcoa: 'SS',
      Huelva: 'H',
      Huesca: 'HU',
      Jaén: 'J',
      'La Rioja': 'LO',
      'Las Palmas': 'GC',
      León: 'LE',
      Lleida: 'L',
      Lugo: 'LU',
      Madrid: 'M',
      Málaga: 'MA',
      Melilla: 'ML',
      Murcia: 'MU',
      Navarra: 'NA',
      Ourense: 'OR',
      Palencia: 'P',
      Pontevedra: 'PO',
      Salamanca: 'SA',
      'Santa Cruz de Tenerife': 'TF',
      Segovia: 'SG',
      Sevilla: 'SE',
      Soria: 'SO',
      Tarragona: 'T',
      Teruel: 'TE',
      Toledo: 'TO',
      Valencia: 'V',
      Valladolid: 'VA',
      Vizcaya: 'BI',
      Zamora: 'ZA',
      Zaragoza: 'Z',
    },
    dial_code: '+34',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+94',
  },
  {
    name: 'St. Vincent',
    code: 'VC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 784',
  },
  {
    name: 'Sudan',
    code: 'SD',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+249',
  },
  {
    name: 'Suriname',
    code: 'SR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+597',
  },
  {
    name: 'Svalbard And Jan Mayen',
    code: 'SJ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+47',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+268',
  },
  {
    name: 'Sweden',
    code: 'SE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+46',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+41',
  },
  {
    name: 'Syria',
    code: 'SY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+963',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+886',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+992',
  },
  {
    name: 'Tanzania, United Republic Of',
    code: 'TZ',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+255',
  },
  {
    name: 'Thailand',
    code: 'TH',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: [
      'Amnat Charoen',
      'Ang Thong',
      'Bangkok',
      'Bueng Kan',
      'Buriram',
      'Chachoengsao',
      'Chai Nat',
      'Chaiyaphum',
      'Chanthaburi',
      'Chiang Mai',
      'Chiang Rai',
      'Chon Buri',
      'Chumphon',
      'Kalasin',
      'Kamphaeng Phet',
      'Kanchanaburi',
      'Khon Kaen',
      'Krabi',
      'Lampang',
      'Lamphun',
      'Loei',
      'Lopburi',
      'Mae Hong Son',
      'Maha Sarakham',
      'Mukdahan',
      'Nakhon Nayok',
      'Nakhon Pathom',
      'Nakhon Phanom',
      'Nakhon Ratchasima',
      'Nakhon Sawan',
      'Nakhon Si Thammarat',
      'Nan',
      'Narathiwat',
      'Nong Bua Lam Phu',
      'Nong Khai',
      'Nonthaburi',
      'Pathum Thani',
      'Pattani',
      'Pattaya',
      'Phangnga',
      'Phatthalung',
      'Phayao',
      'Phetchabun',
      'Phetchaburi',
      'Phichit',
      'Phitsanulok',
      'Phra Nakhon Si Ayutthaya',
      'Phrae',
      'Phuket',
      'Prachin Buri',
      'Prachuap Khiri Khan',
      'Ranong',
      'Ratchaburi',
      'Rayong',
      'Roi Et',
      'Sa Kaeo',
      'Sakon Nakhon',
      'Samut Prakan',
      'Samut Sakhon',
      'Samut Songkhram',
      'Saraburi',
      'Satun',
      'Sing Buri',
      'Sisaket',
      'Songkhla',
      'Sukhothai',
      'Suphan Buri',
      'Surat Thani',
      'Surin',
      'Tak',
      'Trang',
      'Trat',
      'Ubon Ratchathani',
      'Udon Thani',
      'Uthai Thani',
      'Uttaradit',
      'Yala',
      'Yasothon',
    ],
    province_codes: {
      'Amnat Charoen': 'TH-37',
      'Ang Thong': 'TH-15',
      Bangkok: 'TH-10',
      'Bueng Kan': 'TH-38',
      Buriram: 'TH-31',
      Chachoengsao: 'TH-24',
      'Chai Nat': 'TH-18',
      Chaiyaphum: 'TH-36',
      Chanthaburi: 'TH-22',
      'Chiang Mai': 'TH-50',
      'Chiang Rai': 'TH-57',
      'Chon Buri': 'TH-20',
      Chumphon: 'TH-86',
      Kalasin: 'TH-46',
      'Kamphaeng Phet': 'TH-62',
      Kanchanaburi: 'TH-71',
      'Khon Kaen': 'TH-40',
      Krabi: 'TH-81',
      Lampang: 'TH-52',
      Lamphun: 'TH-51',
      Loei: 'TH-42',
      Lopburi: 'TH-16',
      'Mae Hong Son': 'TH-58',
      'Maha Sarakham': 'TH-44',
      Mukdahan: 'TH-49',
      'Nakhon Nayok': 'TH-26',
      'Nakhon Pathom': 'TH-73',
      'Nakhon Phanom': 'TH-48',
      'Nakhon Ratchasima': 'TH-30',
      'Nakhon Sawan': 'TH-60',
      'Nakhon Si Thammarat': 'TH-80',
      Nan: 'TH-55',
      Narathiwat: 'TH-96',
      'Nong Bua Lam Phu': 'TH-39',
      'Nong Khai': 'TH-43',
      Nonthaburi: 'TH-12',
      'Pathum Thani': 'TH-13',
      Pattani: 'TH-94',
      Pattaya: 'TH-S',
      Phangnga: 'TH-82',
      Phatthalung: 'TH-93',
      Phayao: 'TH-56',
      Phetchabun: 'TH-67',
      Phetchaburi: 'TH-76',
      Phichit: 'TH-66',
      Phitsanulok: 'TH-65',
      'Phra Nakhon Si Ayutthaya': 'TH-14',
      Phrae: 'TH-54',
      Phuket: 'TH-83',
      'Prachin Buri': 'TH-25',
      'Prachuap Khiri Khan': 'TH-77',
      Ranong: 'TH-85',
      Ratchaburi: 'TH-70',
      Rayong: 'TH-21',
      'Roi Et': 'TH-45',
      'Sa Kaeo': 'TH-27',
      'Sakon Nakhon': 'TH-47',
      'Samut Prakan': 'TH-11',
      'Samut Sakhon': 'TH-74',
      'Samut Songkhram': 'TH-75',
      Saraburi: 'TH-19',
      Satun: 'TH-91',
      'Sing Buri': 'TH-17',
      Sisaket: 'TH-33',
      Songkhla: 'TH-90',
      Sukhothai: 'TH-64',
      'Suphan Buri': 'TH-72',
      'Surat Thani': 'TH-84',
      Surin: 'TH-32',
      Tak: 'TH-63',
      Trang: 'TH-92',
      Trat: 'TH-23',
      'Ubon Ratchathani': 'TH-34',
      'Udon Thani': 'TH-41',
      'Uthai Thani': 'TH-61',
      Uttaradit: 'TH-53',
      Yala: 'TH-95',
      Yasothon: 'TH-35',
    },
    dial_code: '+66',
  },
  {
    name: 'Timor Leste',
    code: 'TL',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+670',
  },
  {
    name: 'Togo',
    code: 'TG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+228',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+690',
  },
  {
    name: 'Tonga',
    code: 'TO',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 868',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+216',
  },
  {
    name: 'Turkey',
    code: 'TR',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+90',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 649',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+688',
  },
  {
    name: 'Uganda',
    code: 'UG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+256',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+380',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    zip_label: 'Postal code',
    province_label: 'Emirate',
    provinces: ['Abu Dhabi', 'Ajman', 'Dubai', 'Fujairah', 'Ras al-Khaimah', 'Sharjah', 'Umm al-Quwain'],
    province_codes: {
      'Abu Dhabi': 'AZ',
      Ajman: 'AJ',
      Dubai: 'DU',
      Fujairah: 'FU',
      'Ras al-Khaimah': 'RK',
      Sharjah: 'SH',
      'Umm al-Quwain': 'UQ',
    },
    dial_code: '+971',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    zip_label: 'Postal code',
    province_label: 'State',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+598',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    zip_label: 'Postal code',
    province_label: 'Province',
    provinces: null,
    province_codes: {},
    dial_code: '+998',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+678',
  },
  {
    name: 'Venezuela',
    code: 'VE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+58',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+84',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+1 284',
  },
  {
    name: 'Wallis And Futuna',
    code: 'WF',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+681',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '',
  },
  {
    name: 'Yemen',
    code: 'YE',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+967',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+260',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    zip_label: 'Postal code',
    province_label: 'Region',
    provinces: null,
    province_codes: {},
    dial_code: '+263',
  },
];
