import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ungzip } from 'pako';
import { map, Observable } from 'rxjs';

import type { Team, TeamSettings } from '../../_shared/models/_shared/team';

export class CreateTeamDto {
  addCreatorToTeam?: boolean;
  name?: string;
  project: boolean;
  deleted: boolean;
  private: boolean;

  constructor(args: Partial<CreateTeamDto> = { project: false, deleted: false, private: false }) {
    Object.assign(this, args);
  }
}

export const TEAMS_API_URL = 'api.qa1.90srv.com/Teams';

@Injectable({
  providedIn: 'root',
})
export class TeamsApiService {
  constructor(private http: HttpClient) {}

  create(dto: CreateTeamDto): Observable<string> {
    return this.http.post<string>(TEAMS_API_URL, dto);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${TEAMS_API_URL}/${id}`);
  }

  update(id: string, update: Partial<Team>): Observable<void> {
    return this.http.patch<void>(`${TEAMS_API_URL}/${id}`, update);
  }

  updateSettings(teamId: string, update: Partial<TeamSettings>): Observable<void> {
    return this.http.patch<void>(`${TEAMS_API_URL}/${teamId}/Settings`, update);
  }

  getTeams(): Observable<Team[]> {
    return this.http
      .get<{ teamsBuffer: { data: Buffer } }>(`${TEAMS_API_URL}`)
      .pipe(map(({ teamsBuffer: { data } }) => JSON.parse(ungzip(new Uint8Array(data), { to: 'string' })) as Team[]));
  }

  getTeamById(id: string): Observable<Team> {
    return this.http.get<Team>(`${TEAMS_API_URL}/${id}`);
  }

  /**
   * Used for data upload
   */
  getTeamsByCompanyId(companyId: string): Observable<Team[]> {
    return this.http.get<Team[]>(`/api/v4/Companies/${companyId}/Teams`);
  }

  pushAgendaByType(teamId: string, agendaType: string): Observable<void> {
    return this.http.post<void>(`${TEAMS_API_URL}/${teamId}/pushAgenda/${agendaType}`, {});
  }

  pushCustomAgenda(teamId: string, customAgendaId: string): Observable<void> {
    return this.http.post<void>(`${TEAMS_API_URL}/${teamId}/pushCustomAgenda/${customAgendaId}`, {});
  }
}
