import { Injectable } from '@angular/core';
import { cloneDeep, merge } from 'lodash';
import { Observable, map } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { CascadedSections } from '@ninety/ui/legacy/shared/models/vto/cascaded-sections';
import { Vto } from '@ninety/ui/legacy/shared/models/vto/vto';
import { VtoCustomSectionSettings } from '@ninety/ui/legacy/shared/models/vto/vto-sections';
import { VtoNormalizerOptsFactoryService } from '@ninety/vto/services/vto-normalizer-opts-factory.service';
import { VtoNormalizerOpts, VtoNormalizerService, VtoPair } from '@ninety/vto/services/vto-normalizer.service';

export interface VtoCascaderOpts extends VtoNormalizerOpts {
  sectionsToCascade?: (keyof CascadedSections)[];
}

@Injectable({
  providedIn: 'root',
})
export class VtoCascaderService {
  private static readonly EOS_DEFAULT_VTO_CASCADED_SECTIONS: (keyof CascadedSections)[] = [
    'coreValues',
    'coreFocus',
    'tenYear',
    'purpose',
    'niche',
  ];

  constructor(
    private readonly stateService: StateService,
    private readonly vtoNormalizer: VtoNormalizerService,
    private readonly vtoNormalizerOptsFactoryService: VtoNormalizerOptsFactoryService
  ) {}

  getOptsAndCascade(pair: VtoPair, opts?: Partial<VtoCascaderOpts>): Observable<VtoPair> {
    return this.vtoNormalizerOptsFactoryService
      .getCompleteOpts(opts)
      .pipe(map(completeOpts => this.cascade(pair, completeOpts)));
  }

  cascade(pair: VtoPair, opts: VtoCascaderOpts): VtoPair {
    const { teamVto: unNormalizedTeamVto, sltVto: unNormalizedSltVto } = pair;

    const normalizedSltVto = this.vtoNormalizer.normalize(unNormalizedSltVto, opts);

    const fromSlt: Partial<Vto> = {
      customSectionSettings: this.cascadeCustomVtoSections(normalizedSltVto, unNormalizedTeamVto),
      cascadedSections: cloneDeep(normalizedSltVto.cascadedSections),
      ...this.cascadeDefaultVtoSections(normalizedSltVto, unNormalizedTeamVto, opts),
    };

    const initialMergedTeamVto = merge({}, unNormalizedTeamVto, fromSlt);
    const normalizedTeamVto = this.vtoNormalizer.normalize(initialMergedTeamVto, opts);

    return { teamVto: normalizedTeamVto, sltVto: normalizedSltVto };
  }

  private cascadeDefaultVtoSections(
    normalizedSltVto: Vto,
    unNormalizedTeamVto: Vto,
    opts: VtoCascaderOpts
  ): Pick<Vto, 'labels' | 'sectionSettings'> {
    const cascaded = opts?.sectionsToCascade ?? this.getCascadedDefaultSections(normalizedSltVto, opts.bos);
    const initial: Pick<Vto, 'labels' | 'sectionSettings' | 'coreFocus'> = {
      labels: unNormalizedTeamVto.labels,
      sectionSettings: [],
      coreFocus: { purpose: null, niche: null },
    };

    const isCoreFocusEdgeCase = opts.bos === BusinessOperatingSystem.ninetyOS && opts.usingNinetyFlag;

    const notCascaded = CascadedSections.inverseKeys(cascaded);
    const withNotCascaded = notCascaded.reduce((acc, cur) => {
      if (isCoreFocusEdgeCase && (cur === 'niche' || cur === 'purpose')) {
        acc.coreFocus[cur] = unNormalizedTeamVto.coreFocus[cur];
      } else if (!(isCoreFocusEdgeCase && cur === 'coreFocus')) {
        acc[cur] = unNormalizedTeamVto[cur];
      }

      return acc;
    }, initial);

    return cascaded.reduce((acc, cur) => {
      // Cascade section
      if (isCoreFocusEdgeCase && (cur === 'niche' || cur === 'purpose')) {
        acc.coreFocus[cur] = normalizedSltVto.coreFocus[cur];
      } else if (!(isCoreFocusEdgeCase && cur === 'coreFocus')) {
        acc[cur] = normalizedSltVto[cur];
      }

      // Cascade label
      if (cur === 'marketingStrategies') {
        // Different spelling
        acc.labels.marketingStrategy = normalizedSltVto.labels.marketingStrategy;

        // Subheadings
        acc.labels.targetMarket = normalizedSltVto.labels.targetMarket;
        acc.labels.threeUniques = normalizedSltVto.labels.threeUniques;
        acc.labels.provenProcess = normalizedSltVto.labels.provenProcess;
        acc.labels.guarantee = normalizedSltVto.labels.guarantee;
      } else if (cur === 'coreFocus' && !isCoreFocusEdgeCase) {
        acc.labels.coreFocus = normalizedSltVto.labels.coreFocus;
        acc.labels.niche = normalizedSltVto.labels.niche;
        acc.labels.purpose = normalizedSltVto.labels.purpose;
      } else {
        acc.labels[cur] = normalizedSltVto.labels[cur];
      }

      return acc;
    }, withNotCascaded);
  }

  private cascadeCustomVtoSections(normalizedSltVto: Vto, unNormalizedTeamVto: Vto): VtoCustomSectionSettings[] {
    return normalizedSltVto.customSectionSettings.map(sltVtoSection => {
      const teamVtoSection = unNormalizedTeamVto.customSectionSettings.find(s => s._id === sltVtoSection._id);

      if (teamVtoSection) {
        return VtoCascaderService.mergeCustomVtoSection(sltVtoSection, teamVtoSection);
      } else {
        return VtoCascaderService.createNewTeamCustomSectionFrom(sltVtoSection);
      }
    });
  }

  private getCascadedDefaultSections(sltVto: Vto, bos: BusinessOperatingSystem): (keyof CascadedSections)[] {
    // Custom V/TOs allow the SLT to choose which sections get cascaded
    // EOS defines for itself which sections are cascaded
    if (this.stateService.company.settings?.customVto || bos !== BusinessOperatingSystem.eos) {
      return CascadedSections.cascadedKeys(sltVto.cascadedSections);
    }

    return VtoCascaderService.EOS_DEFAULT_VTO_CASCADED_SECTIONS;
  }

  private static mergeCustomVtoSection(
    sltVtoSection: VtoCustomSectionSettings,
    teamVtoSection: VtoCustomSectionSettings
  ) {
    if (sltVtoSection.cascaded) return Object.assign({}, sltVtoSection);

    // else, copy settings from SLT section, but return team details/content
    return {
      ...sltVtoSection,
      title: teamVtoSection.title ?? '',
      details: teamVtoSection.details ?? '',
      iframeUrl: teamVtoSection.iframeUrl ?? '',
    };
  }

  private static createNewTeamCustomSectionFrom(sltVtoSection: VtoCustomSectionSettings): VtoCustomSectionSettings {
    const clone = cloneDeep(sltVtoSection);

    if (sltVtoSection.cascaded) {
      // Cascade SLT -> team
      return clone;
    } else {
      // Add custom sections from SLT that aren't in the team V/TO as empty sections
      clone.details = '';
      clone.iframeUrl = '';

      return clone;
    }
  }
}
