/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input } from '@angular/core';

import { HasErrorDirective } from '../../directives/has-error.directive';

import { InputVariantDirective } from './input-variant.directive';

export type TextInputWrapperVariant = 'outline' | 'inline';

/**
 * Directive to be used on an input element to apply Ninety styling.
 */
@Directive({
  selector: '[ninetyInput]',
  standalone: true,
  host: {
    '[class.ninety-input]': 'true',
  },
  hostDirectives: [
    {
      directive: InputVariantDirective,
      inputs: ['ninetyInputVariant: variant'],
    },
    {
      directive: HasErrorDirective,
      inputs: ['ninetyHasError: hasError'],
    },
  ],
})
export class NinetyInputDirective {
  constructor(
    /** Exposed for programmatic access to the HTML element (ex: focus, testing, ViewChild) */
    public host: ElementRef<HTMLInputElement>
  ) {}

  @Input({ transform: coerceBooleanProperty }) set disabled(value: BooleanInput) {
    this.host.nativeElement.disabled = value as boolean; // Safe cast, already coerced, just convince TS
  }

  @Input({ transform: coerceBooleanProperty }) set readonly(value: BooleanInput) {
    this.host.nativeElement.readOnly = value as boolean; // Safe cast, already coerced, just convince TS
  }

  public get value(): string {
    return this.host.nativeElement.value;
  }

  /** Focuses the input element. */
  public focus(): void {
    this.host.nativeElement.focus();
  }

  public clear(): void {
    this.host.nativeElement.value = '';
  }
}
