import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesPastMidnightToTwelveHourPipe',
})
export class MinutesPastMidnightToTwelveHourPipe implements PipeTransform {
  transform(minutesPastMidnight: number): string {
    const twelveHourOffset = minutesPastMidnight % (12 * 60);
    const hours = Math.floor(twelveHourOffset / 60);
    const minutes = twelveHourOffset % 60;

    return `${hours > 0 ? hours : 12}:${minutes.toString().padStart(2, '0')}`;
  }
}

@Pipe({
  name: 'minutesPastMidnightToTwentyFourHourPipe',
})
export class MinutesPastMidnightToTwentyFourHourPipe implements PipeTransform {
  transform(minutesPastMidnight: number): string {
    const twelveHourOffset = minutesPastMidnight % (24 * 60);
    const hours = Math.floor(twelveHourOffset / 60);
    const minutes = twelveHourOffset % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}

@Pipe({
  name: 'minutesPastMidnightToMeridiemPipe',
})
export class MinutesPastMidnightToMeridiemPipe implements PipeTransform {
  transform(minutesPastMidnight: number): Meridiems {
    return minutesPastMidnight < 12 * 60 ? Meridiems.AM : Meridiems.PM;
  }
}

export enum Days {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export enum Meridiems {
  AM = 'AM',
  PM = 'PM',
}
