export interface TopToolbarStateModel {
  toolbarHidden: boolean;
  toolbarHasShadow: boolean;
  chipMessage: string;
  pageTitle: string;
}

export const initialTopToolbarState: TopToolbarStateModel = {
  toolbarHidden: false,
  toolbarHasShadow: false,
  chipMessage: null,
  pageTitle: null,
};
