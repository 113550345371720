import type { IdSelector } from '@ngrx/entity';

/**
 * We do this to change NGRX Entity default Id of "id" to our default "_id"
 *
 * @example
 * ```ts
 * const selectId = mongoIdAdapter<Team>();
 * export const teamsStateAdapter = createEntityAdapter<Team>({ selectId });
 * ```
 */
export function mongoIdAdapter<T extends { _id: string }>(): IdSelector<T> {
  return ({ _id }: T) => _id;
}
