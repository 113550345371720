import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ResponsibilityChartSelectors } from '@ninety/accountability-chart/_state/chart/responsibility-chart.selectors';
import { QuerySeatParamsDto } from '@ninety/accountability-chart/models/query-seat-params.model';
import { CreateSeatModel, UpdateSeatModel } from '@ninety/accountability-chart/models/seat.model';
import { OrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-update';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';

import { ToggleVisionaryResponse } from '../models/toggle-visionary-response';

@Injectable({
  providedIn: 'root',
})
export class SeatApiService {
  constructor(private http: HttpClient, private store: Store) {}

  createSeatModel(dto: CreateSeatModel): Observable<SeatModel> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      map(builder => builder.seats().toUrl()),
      switchMap(url => this.http.post<SeatModel>(url, dto))
    );
  }

  updateSeatModel(update: UpdateSeatModel): Observable<void> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      map(builder => builder.seats().append(update.id).toUrl()),
      switchMap(url => this.http.patch<void>(url, update.changes))
    );
  }

  delete(seatId: string): Observable<void> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      map(builder => builder.seats().append(seatId).toUrl()),
      switchMap(url => this.http.delete<void>(url))
    );
  }

  toggleVisionary(): Observable<ToggleVisionaryResponse> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      switchMap(builder => this.http.post<ToggleVisionaryResponse>(builder.toggleVisionary().toUrl(), null))
    );
  }

  updateSeatOrdinals(updates: Update<Pick<SeatModel, 'ordinal'>>[]): Observable<void> {
    const pairs: OrdinalUpdate[] = updates.map(u => new OrdinalUpdate(u.id, u.changes.ordinal));
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      take(1),
      switchMap(builder => this.http.post<void>(builder.updateOrdinals().toUrl(), { pairs }))
    );
  }

  querySeats(params: Partial<QuerySeatParamsDto> = {}): Observable<SeatModel[]> {
    const parsedParams = new QuerySeatParamsDto(params).toHttpParams();
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForCompany).pipe(
      take(1),
      map(builder => builder.primaryChart().seats().toUrl()),
      switchMap(url => this.http.get<SeatModel[]>(url, { params: parsedParams }))
    );
  }
}
