import { Pipe, PipeTransform } from '@angular/core';

import { compareToToday } from './_utils';

export type DateComparisonResultText = 'isDueToday' | 'isOverdue' | 'isNotDueYet';
export type ItemDueStatus = 'isDueTodayOrBefore' | DateComparisonResultText;
/**
 * Tests if a due date matches the condition described by itemDueStatus.
 *
 * @see CompareToTodayPipe
 */
@Pipe({
  name: 'itemDueStatusPipe',
  standalone: true,
})
export class ItemDueStatusPipe implements PipeTransform {
  transform(dueDate: Date | string, itemDueStatus: ItemDueStatus): boolean {
    return ItemDueStatusPipe.ifStatusIs(dueDate, itemDueStatus);
  }

  static ifStatusIs(dueDate: Date | string, option: ItemDueStatus): boolean {
    const comparison = compareToToday(dueDate);

    switch (option) {
      case 'isDueTodayOrBefore':
        return comparison === 0 || comparison === -1;
      case 'isDueToday':
        return comparison === 0;
      case 'isOverdue':
        return comparison === -1;
      case 'isNotDueYet':
        return comparison === 1;
    }
  }
}
