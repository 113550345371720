/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

import { PrivacyPolicyDialogComponent } from '../../../_shared/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsConditionsDialogComponent } from '../../../_shared/components/terms-conditions-dialog/terms-conditions-dialog.component';

import { PoliciesActions } from './policies.actions';

const apiUrl = 'api.qa1.90srv.com/';

export const friendlyGetPolicyError =
  'Problem retrieving privacy policy, please visit <a target="_blank" href="https://www.ninety.io/privacy-policy">Ninety.io</a>';

@Injectable()
export class PoliciesStateEffects {
  getPrivacyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoliciesActions.getPrivacyPolicy),
      switchMap(() =>
        this.http.get<{ policy: string }>(`${apiUrl}Signup/Privacy`).pipe(
          map(response => PoliciesActions.privacyPolicyReceived({ privacyPolicy: response.policy })),
          catchError((e: unknown) => {
            // Prevent this error from firing in unit tests and dev environments
            if (environment.production) {
              console.error('Failed to load privacy policy from HubSpot -', e);
            }
            return of(PoliciesActions.privacyPolicyReceived({ privacyPolicy: friendlyGetPolicyError }));
          })
        )
      )
    )
  );

  displayPrivacyPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoliciesActions.displayPrivacyPolicy),
        tap(() =>
          this.dialog.open(PrivacyPolicyDialogComponent, {
            width: '500px',
            data: { required: false },
            autoFocus: false,
          })
        )
      ),
    { dispatch: false }
  );

  displayTermsOfService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoliciesActions.displayTermsOfService),
        tap(() => this.dialog.open(TermsConditionsDialogComponent, { width: '500px', autoFocus: false }))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private http: HttpClient, private dialog: MatDialog) {}
}
