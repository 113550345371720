import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HelpfulPermissions } from '../../_shared/models/_shared/person';
import {
  selectHasAnyHelpfulPermission,
  selectHasHelpfulPermission,
} from '../../_state/app-global/helpful-permissions/helpful-permissions.selectors';

/**
 * To use, simply add to the "canActivate" array in route definition.
 *
 * Example for checking specific permission:
 *  canActivate: [HelpfulPermissionsGuard.checkPermission('manualCharge'), IsPaddleGuard],
 *
 * Example for if any of the permissions are true (such as the Helpful link in the account menu):
 *  canActivate: [AuthGuard, HelpfulPermissionsGuard.hasAnyHelpfulPermission],
 */
@Injectable({ providedIn: 'root' })
export class HelpfulPermissionsGuard {
  constructor(private store: Store, private router: Router) {}

  static checkPermission(helpfulPermission: keyof HelpfulPermissions): CanActivateFn {
    return () => inject(HelpfulPermissionsGuard).canActivate(helpfulPermission);
  }

  static hasAnyHelpfulPermission: CanActivateFn = () => inject(HelpfulPermissionsGuard).canActivate('any');

  private canActivate(helpfulPermission: keyof HelpfulPermissions | 'any'): Observable<boolean> | UrlTree {
    const goAway = this.router.parseUrl('/home');

    if (helpfulPermission === 'any') return this.store.select(selectHasAnyHelpfulPermission) || goAway;

    return this.store.select(selectHasHelpfulPermission(helpfulPermission)) || goAway;
  }
}
