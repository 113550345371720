export enum WeeklyRangeName {
  rolling13Weeks = 'Rolling 13 Weeks',
  qtd = 'QTD',
  currentQuarter = 'Current Quarter',
  ytd = 'YTD',
  currentYear = 'Current Year',
}

export enum WeeklyRange {
  rolling13Weeks = 'rolling13Weeks',
  qtd = 'qtd',
  currentQuarter = 'currentQuarter',
  ytd = 'ytd',
  currentYear = 'currentYear',
}
