// Basic Types and Functions

/**
 * The buttons to include, organized by the groups they roll up into based on size and the 'buttonsVisible' option.
 *
 * See https://froala.com/wysiwyg-editor/docs/options/#toolbarButtons for a complete example of this object
 */
export interface ToolbarButtonOptions {
  moreText: ToolbarButtonSectionOptions;
  moreParagraph: ToolbarButtonSectionOptions;
  moreRich: ToolbarButtonSectionOptions;
  moreMisc: ToolbarButtonSectionOptions;
}

export interface ToolbarButtonSectionOptions {
  buttons: string[];
  align?: 'left' | 'right';
  buttonsVisible?: number;
}

/**
 * Aggregate object to hold the 'buttonsVisible' field for each key of ToolbarButtonOptions.
 */
export interface ToolbarButtonOptionsCounts {
  moreTextCount: number;
  moreParagraphCount: number;
  moreRichCount: number;
  moreMiscCount: number;
}

/**
 * Construct a ToolbarButtonOptions object, optionally overriding the count.
 *
 * Useful for creating viewport-aware options objects.
 *
 * @param counts 0 or more counts to override the defaults. A count becomes the 'buttonsVisible' for a given section.
 *               See ToolbarButtonOptions for more.
 * @returns ToolbarButtonOptions
 */
export function createToolbarButtons(counts: Partial<ToolbarButtonOptionsCounts> = mediumCounts): ToolbarButtonOptions {
  // Skip check for counts == defaultCounts, just assign => Big O(equality) + possible assign > Big O(assign)
  const { moreTextCount, moreParagraphCount, moreRichCount, moreMiscCount } = Object.assign({}, mediumCounts, counts);

  return {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
      ],
      align: 'left',
      buttonsVisible: moreTextCount,
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'formatUL',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'paragraphFormat',
        'paragraphStyle',
        'lineHeight',
        'outdent',
        'indent',
        'quote',
      ],
      align: 'left',
      buttonsVisible: moreParagraphCount,
    },
    moreRich: {
      buttons: ['insertLink', 'insertTable', 'selectAll', 'emoticons', 'spellChecker'],
      align: 'left',
      buttonsVisible: moreRichCount,
    },
    moreMisc: {
      buttons: ['undo', 'redo'],
      align: 'right',
      buttonsVisible: moreMiscCount,
    },
  };
}

// Responsive by Element Width Types and Functions

const MIN_WIDTH_OF_SMALL = 290;
const MIN_WIDTH_OF_MEDIUM = 570;
const MIN_WIDTH_OF_LARGE = 725;

export enum TextEditorToolbarSize {
  xs = 'XS',
  sm = 'SM',
  md = 'MD',
  lg = 'LG',
}

/**
 * [0, 289] => XS
 * [290, 569] => SM
 * [570, 724] => MD
 * [725, MAX] => LG
 */
export function elementWidthToToolbarSize(width: number): TextEditorToolbarSize {
  if (width < MIN_WIDTH_OF_SMALL) return TextEditorToolbarSize.xs;
  if (width < MIN_WIDTH_OF_MEDIUM) return TextEditorToolbarSize.sm;
  if (width < MIN_WIDTH_OF_LARGE) return TextEditorToolbarSize.md;
  return TextEditorToolbarSize.lg;
}

// Counts for various element widths
export const largeCounts: ToolbarButtonOptionsCounts = {
  moreTextCount: 3,
  moreParagraphCount: 4,
  moreRichCount: 1,
  moreMiscCount: 2,
};

export const mediumCounts: ToolbarButtonOptionsCounts = {
  ...largeCounts,
  moreRichCount: 0,
  moreMiscCount: 0,
};

export const smallCounts: Partial<ToolbarButtonOptionsCounts> = {
  moreTextCount: 2,
  moreParagraphCount: 0,
  moreRichCount: 0,
  moreMiscCount: 0,
};

export const extraSmallCounts: Partial<ToolbarButtonOptionsCounts> = {
  ...smallCounts,
  moreTextCount: 0,
};
