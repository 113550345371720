import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { debounceTime, map, tap } from 'rxjs';

import { BreakpointActions } from './viewport.actions';
import { NinetyBreakpointNames, NinetyBreakpoints } from './viewport.model';

@Injectable()
export class ViewportStateEffects {
  constructor(private actions$: Actions, private breakpointObserver: BreakpointObserver) {}

  observeBreakpoints$ = createEffect(() => {
    let breakpointNames: NinetyBreakpointNames[] | null = null;
    return this.breakpointObserver.observe(Object.values(NinetyBreakpoints)).pipe(
      debounceTime(150),
      map(breakpointState => {
        if (!breakpointNames) breakpointNames = Object.keys(NinetyBreakpoints) as NinetyBreakpointNames[];
        const matchingBreakpointIdx = breakpointState.matches
          ? Object.values(breakpointState.breakpoints).lastIndexOf(true)
          : breakpointNames.indexOf(NinetyBreakpointNames.Medium);
        const currentScreenSize = breakpointNames[matchingBreakpointIdx];
        return BreakpointActions.breakpointSet({ breakpointState, currentScreenSize });
      })
    );
  });
}
