import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { InteractionsStateActions } from './interactions.actions';
import { Interactions, initialInteractionsState } from './models/interaction';

export const interactionsStateReducer = createReducer<Interactions>(
  initialInteractionsState,
  on(InteractionsStateActions.successCreateInteraction, (state, { interaction }) => {
    const newState: Interactions = cloneDeep(state);
    newState.interactons.push(interaction);
    newState.latestInteractionId = interaction._id;
    return newState;
  }),
  on(InteractionsStateActions.successUpdateInteraction, (state, { args, submittedQuestion }) => {
    const newState: Interactions = cloneDeep(state);
    const index = newState.interactons.findIndex(i => i._id === state.latestInteractionId);
    newState.interactons[index].args = args;
    newState.submittedQuestion = submittedQuestion;
    return newState;
  }),
  on(InteractionsStateActions.updateSubmittedQuestion, (state, { submittedQuestion }) => {
    const newState: Interactions = cloneDeep(state);
    newState.submittedQuestion = submittedQuestion;
    return newState;
  })
);
