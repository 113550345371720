<h1 mat-dialog-title>PDF Print Options</h1>
<mat-dialog-content>
  <div class="content" [formGroup]="form">
    <mat-label>Page Orientation</mat-label>
    <mat-radio-group formControlName="isLandscape">
      <mat-radio-button data-cy="print-dialog_portrait" [value]="false">Portrait</mat-radio-button>
      <mat-radio-button data-cy="print-dialog_landscape" [value]="true">Landscape</mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button data-cy="print-dialog_close-button" mat-raised-button (click)="dialogRef.close()">
    <span>Close</span>
  </button>
  <button
    data-cy="print-dialog_print-button"
    mat-raised-button
    color="accent"
    (click)="dialogRef.close(form.value)"
    ninetyStopPropagation>
    Print
  </button>
</mat-dialog-actions>
