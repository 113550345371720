import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

/**
 * @description
 *   This is a layout component that works via content projection.
 *   It represents the action bar in the design system.
 *   Content can be projected to the left, center, and/or right using selectors.
 *   The action bar can contain a variety of tools that allow the user to narrow down the content on the current page, and take actions on that content.
 *
 * @link https://storybook.ninety.io/?path=/docs/organisms-navigation-action-bar--implementation
 *
 * @example
 *   <ninety-action-bar>
 *     <div left>Left</div>
 *     <div>Center</div>
 *     <div right>Right</div>
 *   </ninety-action-bar>
 */
@Component({
  selector: 'ninety-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ActionBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
