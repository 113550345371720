import { createSelector } from '@ngrx/store';
import { memoize } from 'lodash';

import { selectTodoDetailState } from '@ninety/todos/_state';
import { Follower } from '@ninety/ui/legacy/components/followers/models/follower';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { selectUserEntities } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

export namespace TodoDetailSelectors {
  export const selectedTodo = createSelector(selectTodoDetailState, state => state.selectedTodo);
  export const selectedId = createSelector(selectedTodo, todo => todo?._id);

  export const isThereASelectedTodo = createSelector(selectedTodo, todo => !!todo);
  export const isSelectedTodoPersonal = createSelector(selectedTodo, todo => todo?.isPersonal);

  export const selectTodoFollowers = createSelector(selectedTodo, selectUserEntities, (todo, userEntities) => {
    const userDetails = todo?.followers?.filter(user => user !== null).map(id => userEntities?.[id]);
    const followers = userDetails?.map(
      follower =>
        ({
          id: follower._id,
          name: UserService.userName(follower),
          avatarPictureUrl: follower?.metadata?.picture?.url || '',
          userInitials: UserService.userInitials(follower),
        } as unknown as Follower)
    );
    return followers || [];
  });

  export const selectStandaloneTodoFollowers = memoize((followerIds: string[]) =>
    createSelector(selectUserEntities, userEntities => {
      const userDetails = followerIds?.filter(user => user !== null).map(id => userEntities?.[id]);
      const followers = userDetails?.map(
        follower =>
          ({
            id: follower._id,
            name: UserService.userName(follower),
            avatarPictureUrl: follower?.metadata?.picture?.url || '',
            userInitials: UserService.userInitials(follower),
          } as unknown as Follower)
      );
      return followers || [];
    })
  );

  /**
   * Data resolver for detail view
   */
  export const detailViewData = createSelector(selectedTodo, todo => ({
    type: DetailType.todo,
    input: {
      item: todo,
      itemType: ItemType.todo,
      itemUrl: `${location.origin}/todos/${todo._id}`,
    },
  }));
}
