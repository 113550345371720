import { Injectable } from '@angular/core';
import { format } from 'date-fns';

import { environment } from '@ninety/ui/web/environments';

import { User } from '../../_shared/models/_shared/user';
import { BusinessOperatingSystem } from '../../_shared/models/company/business-operating-system.enum';
import { Company } from '../../_shared/models/company/company';
import { Rock } from '../../_shared/models/rocks/rock';
import { Todo } from '../../_shared/models/todos/todo';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  static readonly Regex = {
    email: new RegExp(
      // eslint-disable-next-line max-len
      '^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
      'i'
    ),
    /**
     * WHATWG HTML Specification:
     * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
     */
    htmlSpecEmail: new RegExp(
      // eslint-disable-next-line max-len
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      'i'
    ),
    hasLowerAlpha: new RegExp('[a-z]'),
    hasUpperAlpha: new RegExp('[A-Z]'),
    hasNumeric: new RegExp('[0-9]'),
    hasAwsPasswordSpecialChar: /[$^*.[\]{}()?"!@#%&/\\,><':;|_~`-]/,
    validAwsPassword:
      // eslint-disable-next-line max-len
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`-])(?!=^[a-zA-Z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`-]).{8,}$/,
    matchAwsInvalidPasswordChar: /([^a-zA-Z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`-])/,
    mongoId: /^[a-f\d]{24}$/i,
  };

  static sanitizeHTML(html: string): string {
    return html
      ? html
          .replace(/<br\s*[\/]?>/g, '\n')
          .replace(/&nbsp;/g, ' ')
          .replace(/<[^>]*>/g, ' ')
          .replace(/\s{2,}/g, ' ')
          .trim()
      : '';
  }

  // TODO return to this in DEV-4592, need a better approach here.
  static isSmallOrTouchScreen(): boolean {
    return (
      (window.innerWidth || document.body.clientWidth) < 600 ||
      !!navigator.maxTouchPoints ||
      navigator.userAgent.match(/iPhone|iPad|iPod/i) != null ||
      navigator.userAgent.match(/Android/i) != null
    );
  }

  static isiPad = () => navigator.userAgent.match(/iPad/i) != null;

  static isTouchScreen = () => !!navigator.maxTouchPoints;

  android = () => navigator.userAgent.match(/Android/i);

  blackberry = () => navigator.userAgent.match(/BlackBerry/i);

  ios = () => navigator.userAgent.match(/iPhone|iPad|iPod/i);

  opera = () => navigator.userAgent.match(/Opera Mini/i);

  windows = () => navigator.userAgent.match(/IEMobile/i);

  constructor() {}

  static fixDateForDb(date: string | Date): string {
    return typeof date === 'string'
      ? date.substring(0, 10)
      : `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
          .getDate()
          .toString()
          .padStart(2, '0')}`;
  }

  static formatDate(date: string | number | Date, dateFormat = 'MM/dd/yyyy'): string {
    if (!date) return;
    try {
      return format(new Date(date), dateFormat);
    } catch (e) {
      return;
    }
  }

  sortByOrdinal(a: Rock, b: Rock): number {
    return a.ordinal - b.ordinal;
  }

  sortByUserOrdinal(a: Rock | Todo, b: Rock | Todo): number {
    return a.userOrdinal - b.userOrdinal;
  }

  isMobile(): boolean {
    return (this.android() || this.blackberry() || this.ios() || this.opera() || this.windows()) !== null;
  }

  isTablet(): boolean {
    // Safari/Firefox workaround for iPad iOS 13+ devices reporting 'Macintosh' user agent
    const isIpadPro = /Macintosh/i.test(navigator.userAgent) && HelperService.isTouchScreen();

    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return isIpadPro || regex.test(navigator.userAgent.toLowerCase());
  }

  getCreatedDate(objectIdString: string): Date {
    return new Date(parseInt(objectIdString.toString().substring(0, 8), 16) * 1000);
  }

  emailRegex(): RegExp {
    return new RegExp(
      // eslint-disable-next-line max-len
      '^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
      'i'
    );
  }

  timeMeasurableRegexp(): RegExp {
    return new RegExp(String.raw`^(?:\d?\d):([0-5]\d):?([0-5]\d)$`);
  }

  mongoIdRegexp(): RegExp {
    return HelperService.Regex.mongoId;
  }

  timeToSeconds(hhmmss: string): number {
    if (!hhmmss) return null;
    const [hh, mm, ss] = hhmmss.split(':');
    return parseInt(hh, 10) * (60 * 60) + parseInt(mm, 10) * 60 + parseInt(ss, 10);
  }

  buildInviteLink(user: User, email: string, company: Company): string {
    let domain: string;

    switch (company.bos) {
      case BusinessOperatingSystem.ninetyOS:
        domain = environment.production ? 'app.ninety.io' : 'new-staging.app.ninety.io';
        break;
      case BusinessOperatingSystem.eos:
        domain = environment.production ? 'eos.ninety.io' : 'eos.app.ninety.io';
        break;
      case BusinessOperatingSystem.empire:
        domain = environment.production ? 'empire.ninety.io' : 'empire.app.ninety.io';
        break;
      case BusinessOperatingSystem.fireproof:
        domain = environment.production ? 'fireproof.ninety.io' : 'fireproof.app.ninety.io';
        break;
      case BusinessOperatingSystem.pinnacle:
        domain = environment.production ? 'pinnacle.ninety.io' : 'pinnacle.app.ninety.io';
        break;
      default:
        domain = 'app.ninety.io';
        break;
    }

    const firstName = user.metadata?.name?.first?.trim() ?? '';
    const lastName = user.metadata?.name?.last?.trim() ?? '';
    email = email.trim();

    // Handles calling encodeURIComponent on values and omits keys with empty strings
    const queryString = `?${new URLSearchParams({ firstName, lastName, email })}`;

    return `https://${domain}/login/accept-invite/${queryString}`;
  }

  isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }
}
