import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';

import { CascadingMessageStateKey } from '@ninety/headlines/_state/cascading-messages/cascading-messages-state.model';
import { HeadlineStateKey } from '@ninety/headlines/_state/headlines/headlines-state.model';
import { TerraCheckboxModule, TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { TeamTodosChildStateKey, TodoRootStateKey } from '@ninety/todos/_state';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { PrintMeetingAgendaSortOptions } from '@ninety/ui/legacy/core/services/print.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { CascadingMessagesSort } from '@ninety/ui/legacy/shared/models/cascading-messages/cascading-message-sort';
import { HeadlinesSort } from '@ninety/ui/legacy/shared/models/headlines/headline-sort';
import { IssuesSort } from '@ninety/ui/legacy/shared/models/issues/issue-sort';
import { MeetingAgendaType } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda-type.enum';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { TodoSort } from '@ninety/ui/legacy/shared/models/todos/todo-sort';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { MeetingDialogActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';

@Component({
  selector: 'ninety-print-agendas-dialog',
  templateUrl: './print-meeting-agenda.component.html',
  styleUrls: ['./print-meeting-agenda.component.scss'],
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 16px 8px 16px;
        min-width: 600px;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    MaterialMdcModule,
    FormsModule,
    ButtonComponent,
    TerraDividerModule,
    TerraIconModule,
    LetDirective,
    TerraCheckboxModule,
  ],
})
export class PrintAgendasDialogComponent {
  MeetingAgendaType = MeetingAgendaType;

  private store = inject(Store);
  protected dialogRef = inject(MatDialogRef<PrintAgendasDialogComponent>);
  private localStorageService = inject(LocalStorageService);

  language$ = this.store.select(selectLanguage);
  readonly customMeetingSettings$ = this.store.select(MeetingsStateSelectors.selectCustomMeetingSettings);

  selectedMeetingType: MeetingAgendaType;

  selectedCustomAgendaId: string | null;

  print(): void {
    const meetingType = this.getMeetingTypeFromAgendaType();
    this.store.dispatch(
      MeetingDialogActions.printMeetingAgenda({
        meetingType,
        sortOptions: this.getPrintMeetingAgendaSortOptions(),
        ...(this.selectedCustomAgendaId ? { customAgendaId: this.selectedCustomAgendaId } : {}),
      })
    );
  }

  updateSelectedMeetingType(checked: boolean, meetingType: MeetingAgendaType, customAgendaId?: string): void {
    if (checked) {
      this.selectedMeetingType = meetingType;
      this.selectedCustomAgendaId = customAgendaId;
    } else {
      this.selectedMeetingType = null;
      this.selectedCustomAgendaId = null;
    }
  }

  private getMeetingTypeFromAgendaType() {
    switch (this.selectedMeetingType) {
      case MeetingAgendaType.weekly:
        return MeetingType.weekly;
      case MeetingAgendaType.quarterly:
        return MeetingType.quarterly;
      case MeetingAgendaType.annualDayOne:
        return MeetingType.annualDayOne;
      case MeetingAgendaType.custom:
        return MeetingType.custom;
      default:
        return null;
    }
  }

  private getPrintMeetingAgendaSortOptions(): PrintMeetingAgendaSortOptions | null {
    let sortOptions: PrintMeetingAgendaSortOptions = {};

    const sortHeadlines: HeadlinesSort = this.localStorageService.getAndParse<HeadlinesSort>(
      `${HeadlineStateKey}.sort`
    );
    const sortCascadingMessages = this.localStorageService.getAndParse<CascadingMessagesSort>(
      `${CascadingMessageStateKey}.sort`
    );
    const sortTodos = this.localStorageService.getAndParse<TodoSort>(
      `${TodoRootStateKey}.${TeamTodosChildStateKey}.sort`
    );
    const sortIssues = this.localStorageService.getAndParse<IssuesSort>(`issues.sort`); //Issues not in state yet

    sortOptions = {
      ...(sortHeadlines ? { headlines: sortHeadlines } : null),
      ...(sortCascadingMessages ? { cascadingMessages: sortCascadingMessages } : null),
      ...(sortTodos ? { todos: sortTodos } : null),
      ...(sortIssues ? { issues: sortIssues } : null),
    };

    return isEmpty(sortOptions) ? null : sortOptions;
  }
}
