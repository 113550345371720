import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, exhaustMap, filter, switchMap, tap } from 'rxjs';

import { WhatNextModel } from '@ninety/layouts/components/what-next/_state/what-next.model';
import { MazService } from '@ninety/ui/legacy/core/services/maz.service';
import { PersonService } from '@ninety/ui/legacy/core/services/person.service';
import { MazNextResponse } from '@ninety/ui/legacy/shared/models/maz/maz-next';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentPerson } from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';
import { InteractionsStateActions } from '@ninety/ui/legacy/state/app-global/interactions/interactions.actions';
import { selectLatestInteraction } from '@ninety/ui/legacy/state/app-global/interactions/interactions.selectors';
import { Interaction } from '@ninety/ui/legacy/state/app-global/interactions/models/interaction';
import { InteractionType } from '@ninety/ui/legacy/state/app-global/interactions/models/interaction-type.enum';
import { NavMenuIntercomActions } from '@ninety/ui/legacy/state/app-global/intercom/intercom-state.actions';

@Injectable({
  providedIn: 'root',
})
export class WhatNextStore extends ComponentStore<WhatNextModel> {
  MAX_DISMISS_CLICK_TIMES = 3;
  canViewButton$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.whatNextButton));
  hideWhatNextButton$ = this.select(state => state.hideWhatNextButton);
  buttonLabel$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.whatNextButtonABLabel));

  currentPerson$ = this.store.select(selectCurrentPerson);
  readonly showWhatNext$ = this.select(state => state.showWhatNext);
  readonly showLastAction$ = this.select(state => state.showLastAction);
  readonly needingMoreHelp$ = this.select(state => state.needingMoreHelp);
  readonly buttonText$ = this.select(state => state.buttonText);
  mazNextResponse$ = this.select(state => state.mazNextResponse);
  readonly userQuestion$ = this.select(state => state.userQuestion);
  private buttonTimeoutId: ReturnType<typeof setTimeout> | null = null;

  readonly clickWhatNext = this.effect((empty$: Observable<void>) =>
    empty$.pipe(
      exhaustMap(() =>
        this.mazService.getNext().pipe(
          tap((response: MazNextResponse) => {
            this.mazNextResponse({ value: response });
          })
        )
      ),
      concatLatestFrom(() => [this.buttonLabel$, this.mazNextResponse$]),
      tap(([_, buttonText, mazResponse]) => {
        this.store.dispatch(
          InteractionsStateActions.createInteraction({
            interactionType: InteractionType.WhatNextButtonClicked,
            description: 'What Next button clicked',
            args: {
              buttonText: buttonText,
              mazResponse: mazResponse,
            },
          })
        );
        this.showWhatNext({ value: true });
      })
    )
  );

  readonly hideWhatNext = this.effect((empty$: Observable<void>) =>
    empty$.pipe(
      concatLatestFrom(() => this.currentPerson$),
      switchMap(([_, currentPerson]) => {
        this.store.dispatch(InteractionsStateActions.hideWhatNextButton());
        this.hideWhatNextButton({ value: true });

        return this.personService
          .updatePersonNoSpinner({
            mazDismissClickTimes: (currentPerson.mazDismissClickTimes || []).concat(Date.now()),
          })
          .pipe(
            tap(() => {
              if ((currentPerson.mazDismissClickTimes || []).length < this.MAX_DISMISS_CLICK_TIMES) {
                const now = new Date();
                const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
                let delayInMilliseconds = midnight.getTime() - now.getTime();

                // 7200000 is 2 hours in milliseconds.
                // if user clicks after 10PM we add a day and turn button back on tomorrow at midnight
                if (delayInMilliseconds <= 7_200_000) {
                  const midnightTomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2, 0, 0, 0);
                  delayInMilliseconds = midnightTomorrow.getTime() - now.getTime();
                }

                if (this.buttonTimeoutId !== null) {
                  clearTimeout(this.buttonTimeoutId);
                }

                this.buttonTimeoutId = setTimeout(() => {
                  this.hideWhatNextButton({ value: false });
                }, delayInMilliseconds);
              }
            })
          );
      })
    )
  );

  readonly launchIntercom = this.effect((empty$: Observable<void>) =>
    empty$.pipe(
      concatLatestFrom(() => [this.store.select(selectLatestInteraction)]),
      exhaustMap(([_, latestInteraction]) => {
        this.store.dispatch(
          InteractionsStateActions.updateInteraction({
            args: {
              ...latestInteraction?.args,
              userClickedChat: true,
            },
          })
        );
        return this.mazService.help();
      }),
      tap(() => {
        this.resetState();
        return this.store.dispatch(NavMenuIntercomActions.showMessenger());
      })
    )
  );

  readonly userQuestionChange$ = this.effect((userQuestion$: Observable<string>) =>
    userQuestion$.pipe(tap((userQuestion: string) => this.userQuestion({ value: userQuestion })))
  );

  readonly allDoneClicked = this.effect((empty$: Observable<void>) =>
    empty$.pipe(
      tap(() => {
        this.resetState(false);
        this.store.dispatch(InteractionsStateActions.updateSubmittedQuestion({ submittedQuestion: false }));
      })
    )
  );
  readonly mazQuestionSubmit = this.effect((settings$: Observable<string>) =>
    settings$.pipe(exhaustMap(userQuestion => this.mazService.submit({ userQuestion: userQuestion })))
  );
  public openNewTab(url: string) {
    window.open(url, '_blank');
    this.showWhatNext({ value: false });
    this.showLastAction({ value: true });
  }

  footerButtonClicked = this.effect(
    (
      settings$: Observable<{
        showLastAction: boolean;
        needingMoreHelp: boolean;
      }>
    ) =>
      settings$.pipe(
        concatLatestFrom(() => [this.store.select(selectLatestInteraction), this.buttonLabel$, this.userQuestion$]),
        tap(([settings, interaction, buttonLabel, userQuestion]) => {
          this.footerButtonClickedAction(
            settings.showLastAction,
            settings.needingMoreHelp,
            userQuestion,
            buttonLabel,
            interaction
          );
        })
      )
  );
  private footerButtonClickedAction(
    showLastAction: boolean,
    needingMoreHelp: boolean,
    userQuestion?: string,
    buttonText?: string,
    interaction?: Interaction
  ) {
    if (showLastAction) {
      this.showLastAction({ value: false });
      this.needingMoreHelp({ value: true });
      this.updateButtonText({ value: 'Continue' });
    } else if (needingMoreHelp) {
      this.needingMoreHelp({ value: false });
      this.updateButtonText({ value: 'All Done' });
      this.store.dispatch(
        InteractionsStateActions.updateInteraction({
          args: {
            ...interaction?.args,
            buttonText: buttonText,
            userSubmittedQuestion: userQuestion?.length > 0 ? true : false,
            userQuestion: userQuestion,
            userClickedChat: false,
          },
        })
      );
      if (userQuestion?.length > 0) {
        this.mazQuestionSubmit(userQuestion);
      }
      this.userQuestion({ value: '' });
    } else {
      this.resetState();
      this.store.dispatch(InteractionsStateActions.updateSubmittedQuestion({ submittedQuestion: false }));
    }
  }
  constructor(private mazService: MazService, private store: Store, private personService: PersonService) {
    super({
      hideWhatNextButton: false,
      showWhatNext: false,
      showLastAction: true,
      needingMoreHelp: false,
      buttonText: 'Need more help?',
      mazNextResponse: null,
      userQuestion: '',
    });
    this.initialize();
  }

  readonly initialize = this.effect((empty$: Observable<void>) =>
    empty$.pipe(
      concatLatestFrom(() => this.currentPerson$),
      filter(([_, currentPerson]) => !!currentPerson),
      tap(([_, currentPerson]) => {
        const clickTimes = currentPerson.mazDismissClickTimes;

        if (clickTimes?.length >= this.MAX_DISMISS_CLICK_TIMES) {
          this.hideWhatNextButton({ value: true });
        } else {
          const mostRecentClickTime = new Date(clickTimes?.[clickTimes.length - 1]);
          const now = new Date();

          if (
            mostRecentClickTime.getDate() === now.getDate() &&
            mostRecentClickTime.getMonth() === now.getMonth() &&
            mostRecentClickTime.getFullYear() === now.getFullYear()
          ) {
            // The most recent dismiss click happened today
            this.hideWhatNextButton({ value: true });
          }
        }
      })
    )
  );

  readonly hideWhatNextButton = this.updater(
    (state, props: { value: boolean }): WhatNextModel => ({
      ...state,
      hideWhatNextButton: props.value,
    })
  );

  readonly showWhatNext = this.updater(
    (state, props: { value: boolean }): WhatNextModel => ({
      ...state,
      showWhatNext: props.value,
    })
  );

  readonly updateButtonText = this.updater(
    (state, props: { value: string }): WhatNextModel => ({
      ...state,
      buttonText: props.value,
    })
  );

  readonly showLastAction = this.updater(
    (state, props: { value: boolean }): WhatNextModel => ({
      ...state,
      showLastAction: props.value,
    })
  );

  readonly needingMoreHelp = this.updater(
    (state, props: { value: boolean }): WhatNextModel => ({
      ...state,
      needingMoreHelp: props.value,
    })
  );

  readonly mazNextResponse = this.updater(
    (state, props: { value: MazNextResponse }): WhatNextModel => ({
      ...state,
      mazNextResponse: props.value,
    })
  );

  readonly userQuestion = this.updater(
    (state, props: { value: string }): WhatNextModel => ({
      ...state,
      userQuestion: props.value,
    })
  );

  resetState(resetUserQuestion = true) {
    this.showWhatNext({ value: false });
    this.showLastAction({ value: true });
    if (resetUserQuestion) this.userQuestion({ value: '' });
    this.updateButtonText({ value: 'Need more help?' });
  }
}
