<button
  class="terra-option"
  role="option"
  tabindex="-1"
  [disabled]="disabled"
  [attr.aria-selected]="_selected"
  [attr.aria-labelledby]="optionId + '-label'"
  [attr.aria-describedby]="optionId + '-description'"
  [attr.id]="optionId"
  (click)="_onClick($event)"
  [ngClass]="{
    'terra-option--selected': _selected || _highlighted,
  }"
  (cdkObserveContent)="_projectedContentChanged()">
  <terra-checkbox *ngIf="_checkbox" [formControl]="_formControl" aria-hidden="true" />
  <div class="terra-option__prefix" *ngIf="_prefixTemplate">
    <ng-container [ngTemplateOutlet]="_prefixTemplate" />
  </div>
  <div class="terra-option__content">
    <div class="terra-option__label" [id]="optionId + '-label'" #label>
      <ng-content />
    </div>
    <div [id]="optionId + '-description'" class="terra-option__description">
      <ng-content select="terra-description" />
    </div>
  </div>
  <div class="terra-option__suffix" *ngIf="_suffixTemplate">
    <ng-container [ngTemplateOutlet]="_suffixTemplate" />
  </div>
</button>
