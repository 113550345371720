import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ExcelExportType } from './excel-export-types.enum';

export const FilterServiceActions = createActionGroup({
  source: 'Filter Service',
  events: {
    Refreshed: emptyProps(),

    /* use with distinctUntilChanged((prev, curr) => prev.searchText === curr.searchText) if needed*/
    'Search Text Updated': props<{ searchText: string }>(),
    'Team Id Changed': props<{ selectedTeamId: string }>(),
    'Selected User Changed': props<{ selectedUserId: string }>(),
    'Show Completed Conversations Toggled': emptyProps(),
    'Is Fetching Set': props<{ isFetchingData: boolean }>(),
    'Export To Excel': props<{ exportType: ExcelExportType }>(),
  },
});
